import { FC } from 'react';
import { Route } from 'react-router-dom';
import { RouteConfig } from '../routing';

const SentryRoute: FC<RouteConfig> = ({ component, ...rest }) => {
  if (!component) {
    return null;
  }

  const Component = component;

  return (
    <Route {...rest}>
      <Component />
    </Route>
  );
};

export default SentryRoute;
