import {
  RequiredAny,
  deleteUndefinedFields,
  formatDateTime,
  formatFullName,
  formatInitials,
  toDate,
  toMillis,
  validateRequiredNumber,
  validateRequiredString,
} from '@atogear/arion-utils';
import {
  addDays,
  isToday,
  setDate,
  setHours,
  setMinutes,
  setSeconds,
  subDays,
} from 'date-fns';

export interface Reminder {
  reminderId: string;
  title: string;
  content: string;
  userId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  triggerOn: number;
  createdAt: number;
}

export const fields = {
  ID: 'reminderId',
  TITLE: 'title',
  CONTENT: 'content',
  USER_ID: 'userId',
  USER_EMAIL: 'userEmail',
  USER_FIRST_NAME: 'userFirstName',
  USER_LAST_NAME: 'userLastName',
  TRIGGER_ON: 'triggerOn',
  CREATED_AT: 'createdAt',
};

export const checkDate = (date: Date) => {
  const cleanDate = setSeconds(date, 0);

  if (cleanDate.getMinutes() === 30) {
    return cleanDate;
  }

  if (cleanDate.getMinutes() > 30) {
    const validated =
      cleanDate.getHours() > 23
        ? setDate(
            setHours(cleanDate, cleanDate.getHours() + 1),
            cleanDate.getDate() + 1,
          )
        : setHours(setMinutes(cleanDate, 0), cleanDate.getHours() + 1);

    return validated;
  }

  if (cleanDate.getMinutes() < 30) {
    return setMinutes(cleanDate, 30);
  }

  return cleanDate;
};

export const defaults: Reminder = {
  reminderId: '',
  title: '',
  content: '',
  userId: '',
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  triggerOn: checkDate(new Date()).getTime(),
  createdAt: Date.now(),
};

export type CreateReminderData = Pick<
  Reminder,
  | 'title'
  | 'content'
  | 'userId'
  | 'userEmail'
  | 'userFirstName'
  | 'userLastName'
  | 'triggerOn'
>;
export interface ExternalReminder
  extends Omit<Reminder, 'triggerOn' | 'createdAt'> {
  triggerOn: Date;
  createdAt: Date;
}

export interface NotificationList {
  past: Reminder[];
  present: Reminder[];
  future: Reminder[];
}

export const getId = (reminder: Reminder) => (reminder || defaults).reminderId;

export const getTitle = (reminder: Reminder) => (reminder || defaults).title;

export const getContent = (reminder: Reminder) =>
  (reminder || defaults).content;

export const getUserId = (reminder: Reminder) => (reminder || defaults).userId;

export const getUserFirstName = (reminder: Reminder) =>
  (reminder || defaults).userFirstName;

export const getUserLastName = (reminder: Reminder) =>
  (reminder || defaults).userLastName;

export const getUserName = (reminder: Reminder) =>
  formatFullName(getUserFirstName(reminder), getUserLastName(reminder));

export const getUserInitials = (reminder: Reminder) =>
  formatInitials(
    getUserFirstName(reminder) || '',
    getUserLastName(reminder) || '',
  );

export const getUserEmail = (reminder: Reminder) =>
  (reminder || defaults).userEmail?.toLowerCase();

export const getTriggerOn = (reminder: Reminder) =>
  (reminder || defaults).triggerOn;

export const getFormattedTriggerOn = (reminder: Reminder): string =>
  formatDateTime(getTriggerOn(reminder));

export const getCreatedAt = (reminder: Reminder) =>
  (reminder || defaults).createdAt;

export const getFormattedCreatedAt = (reminder: Reminder): string =>
  formatDateTime(getCreatedAt(reminder));

// Utils
export const transformToNotifications = (
  reminders: Reminder[],
): NotificationList => {
  const currentDate = new Date().getTime();
  const notificationList: NotificationList = {
    present: [],
    future: [],
    past: [],
  };

  reminders.forEach((reminder) => {
    if (
      reminder.triggerOn > subDays(currentDate, 2).getTime() &&
      reminder.triggerOn < addDays(currentDate, 5).getTime()
    ) {
      if (isToday(reminder.triggerOn)) {
        notificationList.present.push(reminder);
      } else if (reminder.triggerOn > currentDate) {
        notificationList.future.push(reminder);
      } else {
        notificationList.past.push(reminder);
      }
    }
  });

  return notificationList;
};

export const createReminder = (data?: Partial<Reminder>): Reminder => ({
  ...defaults,
  ...deleteUndefinedFields(data || {}),
  triggerOn: data?.triggerOn
    ? checkDate(new Date(data?.triggerOn)).getTime()
    : checkDate(new Date()).getTime(),
  createdAt: Date.now(),
});

export const serialize = (data: RequiredAny): Reminder => {
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid data provided');
  }

  return deleteUndefinedFields({
    reminderId: validateRequiredString(data[fields.ID], fields.ID),
    title: validateRequiredString(data[fields.TITLE], fields.TITLE),
    content: validateRequiredString(data[fields.CONTENT], fields.CONTENT),
    userId: validateRequiredString(data[fields.USER_ID], fields.USER_ID),
    userEmail: validateRequiredString(
      data[fields.USER_EMAIL],
      fields.USER_EMAIL,
    ),
    userFirstName: validateRequiredString(
      data[fields.USER_FIRST_NAME],
      fields.USER_FIRST_NAME,
    ),
    userLastName: validateRequiredString(
      data[fields.USER_LAST_NAME],
      fields.USER_LAST_NAME,
    ),
    triggerOn: validateRequiredNumber(
      toMillis(data[fields.TRIGGER_ON]) || undefined,
      fields.TRIGGER_ON,
    ),
    createdAt: validateRequiredNumber(
      toMillis(data[fields.CREATED_AT]),
      fields.CREATED_AT,
    ),
  });
};

export const deserialize = (
  data: Partial<Reminder>,
): Partial<ExternalReminder> =>
  deleteUndefinedFields({
    reminderId: data.reminderId,
    userId: data.userId,
    title: data.title,
    content: data.content,
    userEmail: data.userEmail,
    userFirstName: data.userFirstName,
    userLastName: data.userLastName,
    triggerOn: toDate(data.triggerOn),
    createdAt: toDate(data.createdAt),
  });
