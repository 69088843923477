import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { MouseTooltip, Text } from '../../../../../components';
import { ColorsType, TypographyStylesType } from '../../../../../theme/types';
import {
  translatedFontCases,
  translatedFontStyles,
  translatedFontWeights,
} from '../../../../../translations';

const Wrapper = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  margin-left: 32px;
`;

const FontOverviewSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 24px;
`;

interface ColorProps {
  $background?: string;
  $typography?: TypographyStylesType;
}

const Font = styled.div<ColorProps>`
  display: flex;
  width: 250px;
  height: 48px;
  border: 1px solid ${(props) => props.theme.colors.grayOpaque};
  background-color: ${(props) => props.$background};
  ${(props) => props.$typography}
  justify-content: flex-start;
  align-items: center;
  margin-right: 4px;
  padding-left: 24px;
`;

const StyledText = styled(Text)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
`;

const StyledColorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

interface ColorProps {
  $color?: string;
}

const FontColor = styled.div<ColorProps>`
  width: 14px;
  height: 14px;
  border: 1px solid ${(props) => props.theme.colors.grayOpaque};
  border-radius: 3px;
  background-color: ${(props) => props.$color || 'transparent'};
  margin-left: 4px;
`;
interface Props {
  name: string;
  typography?: TypographyStylesType;
  colors: ColorsType;
}

const AdvancedFontTile: FC<Props> = ({ name, typography, colors }) => {
  const {
    color,
    fontSize,
    fontStyle,
    fontWeight,
    letterSpacing,
    lineHeight,
    textTransform,
  } = typography || {};

  const formattedFontWeight = useMemo(() => {
    return translatedFontWeights[fontWeight ?? '400'];
  }, [fontWeight]);

  const formattedFontCase = useMemo(() => {
    return translatedFontCases[textTransform ?? 'none'];
  }, [textTransform]);

  const formattedFontStyle = useMemo(() => {
    return translatedFontStyles[fontStyle ?? 'normal'];
  }, [fontStyle]);

  const colorName = useMemo(() => {
    return Object.keys(colors).find((key) => colors[key] === color);
  }, [colors, color]);

  return (
    <Wrapper>
      <Font $typography={typography} $background={colors?.background}>
        {name}
      </Font>
      <TextWrapper>
        <FontOverviewSection>
          <Text variant="body3">Font Family 1 </Text>
          <Text variant="body3">
            {formattedFontWeight} {fontWeight} {formattedFontStyle}
          </Text>
          <Text variant="body3">{formattedFontCase} </Text>
        </FontOverviewSection>
        <FontOverviewSection>
          <Text variant="body3">Size: {fontSize} </Text>
          <Text variant="body3">Line height: {lineHeight}</Text>
          <Text variant="body3">Letter spacing: {letterSpacing} </Text>
          <StyledColorWrapper>
            <StyledText variant="body3">{`Color: "${colorName}"`}</StyledText>
            <MouseTooltip tooltip={color}>
              <FontColor $color={color} />
            </MouseTooltip>
          </StyledColorWrapper>
        </FontOverviewSection>
      </TextWrapper>
    </Wrapper>
  );
};

export default AdvancedFontTile;
