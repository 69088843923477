import { Draft, PayloadAction, SerializedError } from '@reduxjs/toolkit';

export const setFetchingState = (prop: string) => (state: Draft<any>) => {
  state[prop].fetching = true;
  state[prop].updating = false;
  state[prop].error = undefined;
};

export const setUpdatingState = (prop: string) => (state: Draft<any>) => {
  state[prop].fetching = false;
  state[prop].updating = true;
  state[prop].error = undefined;
};

export const setFulfilledState = (prop: string) => (state: Draft<any>) => {
  state[prop].fetching = false;
  state[prop].updating = false;
  state[prop].error = undefined;
};

export const setRejectedState =
  (prop: string) =>
  (
    state: Draft<any>,
    action: PayloadAction<any, any, any, SerializedError>,
  ) => {
    state[prop].fetching = false;
    state[prop].updating = false;
    state[prop].error = action.error;
  };

export const merge = (array?: Array<any>, items?: Array<any>) => {
  if (!items) {
    return array;
  }

  const newItems = array && Array.isArray(array) ? [...array] : [];

  items.forEach((newItem: any) => {
    const itemIndex = newItems.findIndex(
      (existingItem: any) => existingItem.id === newItem.id,
    );

    if (itemIndex > -1) {
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        ...newItem,
      };
    } else {
      newItems.push(newItem);
    }
  });

  return newItems;
};
