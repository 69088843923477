import { clamp } from 'ramda';

export const alphaToHex = (decimal: number) =>
  Math.round(clamp(0, 1, decimal) * 255).toString(16);

export const getAlphaHex = (disabled?: boolean) =>
  alphaToHex(disabled ? 0.5 : 1);

export const PALLETTE1 = [
  '#FF6C00',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#E2B37D',
];
export const PALLETTE2 = [
  '#EF4136',
  '#2B70A7',
  '#006838',
  '#E2B37D',
  '#28AAE1',
  '#68499E',
  '#F15A2B',
  '#EE73C4',
];

// export const PALLETTE3 = [
//   '#4472c4',
//   '#1cad77',
//   '#01579b',
//   '#46d39a',
//   '#7a61ba',
//   '#d8b655',
//   '#fc9d5a',
//   '#e55759',
//   '#90dcd0',
//   '#9dd866',
//   '#b6a8eb',
//   '#60ae48',
//   '#944949',
//   '#e890c6',
//   '#506e70',
//   '#739e45',
//   '#b92949',
//   '#3c48b0',
//   '#333333',
//   '#d2d2d2',
//   '#000000',
// ];

export const PALLETTE3 = [
  '#0a4268',
  '#185d8b',
  '#2280bf',
  '#3498db',
  '#63b0e3',
  '#97caed',
  '#cbe5f6',
  '#eef6fc',
  '#ffffff',
];

const padZero = (str: string, len?: number) => {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

export const invertColor = (hex: string, bw?: boolean) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);
  if (bw === true) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = 255 - r;
  g = 255 - g;
  b = 255 - b;
  // pad each with zeros and return
  return (
    '#' +
    padZero(r.toString(16)) +
    padZero(g.toString(16)) +
    padZero(b.toString(16))
  );
};

export const COLORS = [PALLETTE1, PALLETTE2];
