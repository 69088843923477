import { FC } from 'react';
import styled from 'styled-components';
import { Theme } from '../../theme/types';

const ButtonWrapper = styled.div<Props>`
  background-color: ${(props) => props.previewTheme.colors.primary};
  ${(props) => props.previewTheme.components.buttons.primary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 56px;
  width: 200px;
`;

const ButtonText = styled.div<Props>`
  ${(props) => props.previewTheme.typography.h2}
  ${(props) => props.previewTheme.components.buttons.primary};
`;

const StyledView = styled.div`
  padding: 0px 16px;
`;

interface Props {
  previewTheme: Theme;
}

const AppPrimaryButton: FC<Props> = ({ previewTheme }) => {
  return (
    <ButtonWrapper previewTheme={previewTheme}>
      <StyledView>
        <ButtonText previewTheme={previewTheme}>Learn more</ButtonText>
      </StyledView>
    </ButtonWrapper>
  );
};

export default AppPrimaryButton;
