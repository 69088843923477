import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import Loader from './Loader';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100;
`;

interface ContentProps {
  $loading?: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  pointer-events: ${(props) => (props.$loading ? 'none' : 'all')};
  opacity: ${(props) => (props.$loading ? 0.5 : 1)};
`;

interface Props {
  children?: ReactNode;
  loading?: boolean;
}

const LoaderOverlay: FC<Props> = ({ children, loading }) => (
  <Wrapper>
    {loading && (
      <Overlay>
        <Loader />
      </Overlay>
    )}
    <Content $loading={loading}>{children}</Content>
  </Wrapper>
);

export default LoaderOverlay;
