import { FC, Suspense, useMemo } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { apply, userModel } from '@atogear/arion-utils';

import { useSelector } from '../../store';
import { AuthSelectors, SettingsSelectors } from '../../store/selectors';

import { withProspects, withStats } from '../../models/appSettingsModel';

import { appRoutes, getAccessibleRoutes } from '../../routing';

import { SentryRoute } from '../../components';
import SplashScreen from './SplashScreen';

const AppRouter: FC = () => {
  const user = useSelector(AuthSelectors.selectUser);
  const fetching = useSelector(AuthSelectors.selectUserFetching);
  const appSettings = useSelector(SettingsSelectors.selectAppSettings);

  const location = useLocation();

  const isFetched = useMemo(() => {
    return !!user && !fetching;
  }, [fetching, user]);

  // Admin, Back Office, Internal Sales, External Sales, Distributor or Owner
  const isAuthorized = useMemo(() => {
    return isFetched && user && userModel.isDashboardUser(user);
  }, [isFetched, user]);

  // Routes filtered by user's role
  const routes = useMemo(() => {
    if (!isFetched) {
      return [];
    }

    const filters = [];

    const isAdmin = apply(userModel.isPowerUser, user);

    if (isAdmin) return getAccessibleRoutes(user!, appRoutes);

    if (!withStats(appSettings)) {
      // Remove stats (dashboard) route
      // This can be removed once the feature is fully released
      filters.push(appRoutes.dashboard.key);
    }

    if (!withProspects(appSettings)) {
      // Remove prospects (dashboard) route
      filters.push(appRoutes.prospects.key);
    }

    return getAccessibleRoutes(user!, appRoutes, filters);
  }, [appSettings, isFetched, user]);

  if (
    // User fetched
    isFetched &&
    // User has temporary password
    userModel.hasTempPassword(user!) &&
    // Not on first login page
    !location.pathname.includes(appRoutes.firstLogin.path)
  ) {
    // Using temporary password, redirect to first login to set new password
    return (
      <Redirect
        to={{
          pathname: appRoutes.firstLogin.path,
          state: {
            referrer: location.pathname,
          },
        }}
      />
    );
  }

  return (
    <Suspense fallback={<SplashScreen />}>
      <Switch>
        {/* User should not have access to the dashboard */}
        {isFetched && !isAuthorized && <SentryRoute {...appRoutes.noAccess} />}
        {/* App routes */}
        {routes.map((route) => (
          <SentryRoute {...route} />
        ))}
        {/* Fallback. We only want to render this after the user has loaded in prevent unnecessary redirects */}
        {!!user && <Redirect to={appRoutes.stores.path} />}
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
