import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { apply, isNilOrWhitespace } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../../store';
import { ContactActions } from '../../../../store/actions';
import {
  ContactSelectors,
  ProspectSelectors,
} from '../../../../store/selectors';

import { isValidEmail } from '../../../../utils/validators';

import { contactModel, prospectModel } from '../../../../models';

import {
  Button,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  FlagIcon,
  Option,
  Text,
} from '../../../../components';

import countries from '../../../../assets/data/countries.json';

const countryOptions = countries
  .sort((a, b) => -b.callingCode.localeCompare(a.callingCode))
  .map(({ alpha2, callingCode }) => ({
    adornment: <FlagIcon country={alpha2.toLowerCase()} />,
    key: alpha2,
    label: callingCode,
    value: alpha2,
  }));

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

const Row = styled.div`
  display: flex;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

const StyledDialog = styled(Dialog)`
  min-width: 400px;
`;

const ErrorMessage = styled(Text)`
  color: ${({ theme }) => theme.colors.danger};
  margin-top: 4px;
`;

const CountrySelect = styled(DialogSelect)`
  width: 120px;
  margin-right: 8px;
`;

const PhoneInput = styled(DialogInput)`
  width: calc(100% - 120px - 8px);
`;

interface Props {
  open: boolean;
  contactIndex?: number;
  onClose?: () => void;
}

const EditContactDialog: FC<Props> = (props) => {
  const { open, contactIndex, onClose } = props;

  const dispatch = useDispatch();

  const prospect = useSelector(ProspectSelectors.selectProspectPreview);
  const contacts = useSelector(ContactSelectors.selectContactsPreview);

  const [name, setName] = useState('');
  const [contact, setContact] = useState(contactModel.create());

  const [errors, setErrors] = useState({
    email: false,
    name: false,
    role: false,
    phoneNumber: false,
  });

  const [loading, setLoading] = useState(false);

  const callingCode = useMemo(() => {
    return (
      contactModel.getCallingCode(contact) ||
      apply(prospectModel.getCountryCode, prospect, '')
    );
  }, [contact, prospect]);

  useEffect(() => {
    if (open === true && contacts && contactIndex !== undefined) {
      const contact =
        contacts && !isNaN(contactIndex)
          ? contacts[contactIndex]
          : contactModel.create();

      setContact(contact);

      setName(contactModel.getFullName(contact));

      setErrors({
        email: false,
        name: false,
        role: false,
        phoneNumber: false,
      });
    }
  }, [open, contactIndex, contacts]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      if (
        !validateEmail(contactModel.getEmail(contact)) ||
        !validateName(contactModel.getFullName(contact) || '') ||
        !validateRole(contactModel.getRole(contact)) ||
        !validatePhone(contactModel.getPhoneNumber(contact)) ||
        contactIndex === undefined
      ) {
        return;
      }

      setLoading(true);

      dispatch(ContactActions.contactUpdated({ index: contactIndex, contact }));

      if (onClose) {
        onClose();
      }
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem editing the contact. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeEmail: ChangeEventHandler<HTMLInputElement> = (e) =>
    setContact((prev) => ({
      ...prev,
      email: e.target.value.toLowerCase(),
    }));

  const validateEmail = (value: string) => {
    const valid = isValidEmail(value);

    setErrors((prev) => ({
      ...prev,
      email: !valid,
    }));

    return valid;
  };

  const validateName = (value: string) => {
    const valid = !isNilOrWhitespace(value);

    setErrors((prev) => ({
      ...prev,
      name: !valid,
    }));

    return valid;
  };

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (e) => {
    const [firstName, ...rest] = (e.target.value || '').split(' ');

    setName(e.target.value);

    setContact((prev) => ({
      ...prev,
      firstName,
      lastName: rest.join(' '),
    }));
  };

  const validateRole = (value: string) => {
    const valid = !isNilOrWhitespace(value);

    setErrors((prev) => ({
      ...prev,
      role: !valid,
    }));

    return valid;
  };

  const handleChangeRole: ChangeEventHandler<HTMLInputElement> = (e) =>
    setContact((prev) => ({
      ...prev,
      role: e.target.value,
    }));

  const handleChangeCallingCode = (option: Option | null) =>
    setContact((prev) => ({
      ...prev,
      callingCode: option?.value || contactModel.getCallingCode(contact),
    }));

  const validatePhone = (value: string) => {
    const valid = !isNilOrWhitespace(value);

    setErrors((prev) => ({
      ...prev,
      phoneNumber: !valid,
    }));

    return valid;
  };

  const handleChangePhone: ChangeEventHandler<HTMLInputElement> = (e) =>
    setContact((prev) => ({
      ...prev,
      phoneNumber: e.target.value,
    }));

  return (
    <StyledDialog
      open={open === true && contactIndex !== undefined}
      loading={loading}
      title="Edit contact"
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Wrapper>
          <Group>
            <StyledInputLabel htmlFor="name">Name</StyledInputLabel>
            <DialogInput
              id="name"
              placeholder="Name"
              value={name}
              validate={validateName}
              onChange={handleChangeName}
            />
            {errors.name ? (
              <ErrorMessage variant="body3">
                Please input a valid name.
              </ErrorMessage>
            ) : null}
          </Group>
          <Group>
            <StyledInputLabel htmlFor="role">Job title</StyledInputLabel>
            <DialogInput
              id="role"
              placeholder="Job title"
              required
              value={contactModel.getRole(contact)}
              validate={validateRole}
              onChange={handleChangeRole}
            />
            {errors.role ? (
              <ErrorMessage variant="body3">
                Please input a valid job title.
              </ErrorMessage>
            ) : null}
          </Group>
          <Group>
            <StyledInputLabel htmlFor="email">Email</StyledInputLabel>
            <DialogInput
              id="email"
              placeholder="Email"
              required
              type="email"
              value={contactModel.getEmail(contact)}
              validate={validateEmail}
              onChange={handleChangeEmail}
            />
            {errors.email ? (
              <ErrorMessage variant="body3">
                Please input a valid email.
              </ErrorMessage>
            ) : null}
          </Group>
          <Group>
            <StyledInputLabel htmlFor="phone-number">
              Phone Number
            </StyledInputLabel>
            <Row>
              <CountrySelect
                id={'calling-code'}
                options={countryOptions}
                startAdornment={<FlagIcon country={callingCode} />}
                value={callingCode}
                onChange={handleChangeCallingCode}
              />
              <PhoneInput
                id="phone-number"
                placeholder="Phone Number"
                required
                type="tel"
                value={contactModel.getPhoneNumber(contact)}
                validate={validatePhone}
                onChange={handleChangePhone}
              />
            </Row>
            {errors.phoneNumber ? (
              <ErrorMessage variant="body3">
                Please input a valid phone.
              </ErrorMessage>
            ) : null}
          </Group>
        </Wrapper>
        <DialogActions>
          <CancelButton
            size="small"
            type="button"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </CancelButton>
          <Button
            color="primary"
            disabled={Object.values(errors).some((value) => value === true)}
            size="small"
            type="submit"
            variant="contained"
          >
            Edit contact
          </Button>
        </DialogActions>
      </Form>
    </StyledDialog>
  );
};

export default EditContactDialog;
