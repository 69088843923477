import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  apply,
  formatDate,
  Store,
  storeModel,
  StoreModule,
} from '@atogear/arion-utils';
import { addYears, endOfDay, startOfDay } from 'date-fns';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreActions } from '../../../store/actions';
import { SettingsSelectors, StoreSelectors } from '../../../store/selectors';

import { getPath, notSignedStoreRoutes } from '../../../routing';

import { getMaxSubDuration } from '../../../models/appSettingsModel';

import { translatedModules } from '../../../translations';

import { formatYears } from '../../../utils/formatters';

import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  Icon,
  ModuleChip,
  Option,
} from '../../../components';

import { StoreRouteParams } from '../types';

const StyledDialog = styled(Dialog)`
  width: clamp(500px, 35%, 50%);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

interface GroupProps {
  $row?: boolean;
}

const Group = styled.div<GroupProps>`
  display: flex;
  flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
  justify-content: ${({ $row }) => ($row ? 'flex-start' : 'center')};
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

interface FieldProps {
  $right?: boolean;
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ $right }) => `margin-${$right ? 'left' : 'right'}: 4px;`}
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const ModulesContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
`;

const SecondaryButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
`;

const PrimaryButton = styled(Button)`
  margin-left: 8px;
`;

interface Props {
  open?: boolean;
  store?: Store;
  onClose?: () => void;
}

const SubscriptionDialog: FC<Props> = ({ store, open, onClose }) => {
  const dispatch = useDispatch();

  const appSettings = useSelector(SettingsSelectors.selectAppSettings);
  const loading = useSelector(StoreSelectors.selectStoreUpdating);

  const { storeId } = useParams<StoreRouteParams>();

  const history = useHistory();

  const initialValues = useMemo(
    () => ({
      modules: apply(
        storeModel.getModules,
        store,
        storeModel.getModules(storeModel.defaults),
      ),
      subStartDate: apply(
        storeModel.getSubscriptionStartDate,
        store,
        Date.now(),
      ),
      subDuration: apply(storeModel.getSubscriptionPeriod, store, 2),
      userSlots: apply(
        storeModel.getUserSlots,
        store,
        storeModel.getUserSlots(storeModel.defaults),
      ),
    }),
    [store],
  );

  const [modules, setModules] = useState(initialValues.modules);
  const [subStartDate, setSubStartDate] = useState(initialValues.subStartDate);
  const [subDuration, setSubDuration] = useState(initialValues.subDuration);
  const [userSlots, setUserSlots] = useState(initialValues.userSlots);

  const subEndDate = useMemo(() => {
    return formatDate(endOfDay(addYears(subStartDate, subDuration)));
  }, [subDuration, subStartDate]);

  const maxSubDuration = getMaxSubDuration(appSettings);

  const durationOptions = useMemo(() => {
    return [...new Array(maxSubDuration)].map((_, i) => ({
      key: `${i + 1}-year`,
      label: formatYears(i + 1),
      value: i + 1,
    }));
  }, [maxSubDuration]);

  const isDistributorSubscription = apply(
    storeModel.isDistributorSubscription,
    store,
    false,
  );

  useEffect(() => {
    if (open && store) {
      setModules(storeModel.getModules(store));
      setSubStartDate(
        (prev) => storeModel.getSubscriptionStartDate(store) ?? prev,
      );
      setSubDuration((prev) => storeModel.getSubscriptionPeriod(store) ?? prev);
      setUserSlots(storeModel.getUserSlots(store));
    }
  }, [open, store]);

  const validSlots = useMemo(() => {
    return userSlots >= storeModel.MIN_USER_SLOTS;
  }, [userSlots]);

  const handleUpdate = async () => {
    try {
      if (!storeId) {
        return;
      }

      await dispatch(
        StoreActions.updateStore({
          storeId,
          data: {
            activeModules: modules,
            subscriptionCreatedAt: Date.now(),
            subscriptionStartDate: subStartDate,
            subscriptionEndDate: endOfDay(
              addYears(subStartDate, subDuration),
            ).getTime(),
            subscriptionPeriod: subDuration,
            userSlots,
          },
        }),
      ).unwrap();

      toast('Successfully updated store Subscription!', {
        type: 'success',
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem updating the store subscription settings. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      if (!store) {
        return;
      }

      e.preventDefault();

      await handleUpdate();

      history.push(
        getPath(notSignedStoreRoutes.payments.path, {
          storeId: storeModel.getId(store),
        }),
      );
    } catch {}
  };

  const handleDurationChange = (option: Option | null) =>
    setSubDuration(option?.value || initialValues.subDuration);

  const handleStartDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    setSubStartDate(startOfDay(date).getTime());
  };

  const handleUserSlotsChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setUserSlots(Number.parseInt(e.target.value, 10));

  const handleToggleModule = (module: StoreModule) => () => {
    if (!store) {
      return;
    }

    setModules(
      storeModel.getModules(
        storeModel.toggleModule(module, {
          ...store,
          activeModules: modules,
        }),
      ),
    );
  };

  const id = useId();
  const durationId = `${id}-duration`;
  const startDateId = `${id}-start-date`;
  const endDateId = `${id}-end-date`;
  const userSlotsId = `${id}-user-slots`;

  return (
    <StyledDialog
      open={open}
      loading={loading}
      title="Subscription settings"
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <Group>
            <StyledInputLabel htmlFor={durationId}>Duration</StyledInputLabel>
            <DialogSelect
              id={durationId}
              value={subDuration}
              options={durationOptions}
              onChange={handleDurationChange}
            />
          </Group>
          <Group $row>
            <Field>
              <StyledInputLabel htmlFor={startDateId}>
                Start date
              </StyledInputLabel>
              <DatePicker
                customInput={<DialogInput />}
                id={startDateId}
                selected={new Date(subStartDate)}
                onChange={handleStartDateChange}
              />
            </Field>
            <Field $right>
              <StyledInputLabel htmlFor={endDateId}>End date</StyledInputLabel>
              <DialogInput id={endDateId} disabled value={subEndDate} />
            </Field>
          </Group>
          <Group>
            <StyledInputLabel htmlFor={userSlotsId}>Team size</StyledInputLabel>
            <DialogInput
              id={userSlotsId}
              error={!validSlots}
              placeholder="Team size"
              min={storeModel.MIN_USER_SLOTS}
              type="number"
              value={userSlots}
              onChange={handleUserSlotsChange}
            />
          </Group>
          <Group>
            <StyledInputLabel>Modules</StyledInputLabel>
            <ModulesContainer>
              {Object.keys(modules).map((key) => {
                const name = key as StoreModule;

                const { title, icon } = translatedModules[name];

                return (
                  <ModuleChip
                    key={`module-${key}`}
                    avatar={<Icon name={icon} />}
                    dark
                    label={title}
                    selected={modules[name]}
                    onClick={handleToggleModule(name)}
                  />
                );
              })}
            </ModulesContainer>
          </Group>
        </Content>
        <DialogActions>
          <StyledActions>
            <SecondaryButton
              disabled={!validSlots}
              size="small"
              type="button"
              variant={isDistributorSubscription ? 'contained' : 'outlined'}
              onClick={handleUpdate}
            >
              Save
            </SecondaryButton>
            {!isDistributorSubscription && (
              <PrimaryButton
                color="primary"
                disabled={!validSlots}
                size="small"
                type="submit"
                variant="contained"
              >
                Proceed to payments
              </PrimaryButton>
            )}
          </StyledActions>
        </DialogActions>
      </Form>
    </StyledDialog>
  );
};

export default SubscriptionDialog;
