import { FC } from 'react';
import styled from 'styled-components';

import { alphaToHex } from '../utils/colorUtils';

import Loader from './Loader';

interface MaskProps {
  $visible?: boolean;
}

const Background = styled.div<MaskProps>`
  position: fixed;
  z-index: 1400;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  transition: 0.1s ease-in-out opacity, 0.1s ease-in-out visibility;
  background-color: ${(props) =>
    `${props.theme.colors.dark}${alphaToHex(0.6)}`};
  border-radius: 4px;
`;

interface Props {
  loading?: boolean;
}

const LoadingMask: FC<Props> = ({ loading, ...rest }) => (
  <Background $visible={loading} {...rest}>
    <Loader />
  </Background>
);

export default LoadingMask;
