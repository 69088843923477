import {
  RequiredAny,
  validateOptionalNumber,
  validateOptionalString,
  validateRequiredArray,
  validateRequiredNumber,
  validateRequiredObject,
  validateRequiredString,
} from '@atogear/arion-utils';

import { TimeFrameId } from '../types/stats';

import { validateTimeFrameId } from '../utils/apiUtils';

// Types
export interface SessionDemographics {
  age: {
    '0to20': number;
    '20to25': number;
    '25to30': number;
    '30to35': number;
    '35to40': number;
    '40to50': number;
    '50to100': number;
    unknown: number;
  };
  gender: {
    female: number;
    male: number;
    other: number;
    unknown: number;
  };
}

export interface Derivatives {
  runnerType: {
    rearFoot: number;
    midFoot: number;
    foreFoot: number;
    unknown: number;
  };
  sessionType: {
    running: number;
    walking: number;
    unknown: number;
  };
  cadence: {
    low: number;
    mid: number;
    high: number;
    unknown: number;
  };
  runnerEfficiency: {
    recreational: number;
    experienced: number;
    elite: number;
  };
}

export interface Injuries {
  lowerBack: number;
  leftUpperLeg: number;
  rightUpperLeg: number;
  leftKnee: number;
  rightKnee: number;
  leftLowerLeg: number;
  rightLowerLeg: number;
  leftAnkle: number;
  rightAnkle: number;
  leftAchilles: number;
  rightAchilles: number;
  leftFoot: number;
  rightFoot: number;
  notInjured?: number;
}

export interface Origin {
  kiosk: number;
  ipad: number;
}

export interface ShoeModelScanned {
  scanned: number;
  unscanned: number;
}

export interface ApiSession {
  entries: number;
  store: string;
  storeName: string;
  storeCountry: string;
  numberOfSteps: number;
  demographics: SessionDemographics;
  origin: Origin;
  shoeModel: ShoeModelScanned;
  derivatives: Derivatives;
  injuries: Injuries;
}

export interface SessionData {
  _id: TimeFrameId;
  sessions: ApiSession[];
}

export interface SessionsDay {
  sessions: ApiSession[];
  fullDate: string;
  date: string;
}

// Validation
const validateDemographics = (data: RequiredAny): SessionDemographics => {
  validateRequiredObject(data, 'demographics');

  const { age, gender } = data;

  validateRequiredObject(age, 'age');
  validateRequiredObject(gender, 'gender');

  return {
    age: {
      '0to20': validateRequiredNumber(age['0to20'], '0to20'),
      '20to25': validateRequiredNumber(age['20to25'], '20to25'),
      '25to30': validateRequiredNumber(age['25to30'], '25to30'),
      '30to35': validateRequiredNumber(age['30to35'], '30to35'),
      '35to40': validateRequiredNumber(age['35to40'], '35to40'),
      '40to50': validateRequiredNumber(age['40to50'], '40to50'),
      '50to100': validateRequiredNumber(age['50to100'], '50to100'),
      unknown: validateRequiredNumber(age.unknown, 'unknown'),
    },
    gender: {
      female: validateRequiredNumber(gender.female, 'female'),
      male: validateRequiredNumber(gender.male, 'male'),
      other: validateRequiredNumber(gender.other, 'other'),
      unknown: validateRequiredNumber(gender.unknown, 'unknown'),
    },
  };
};

const validateOrigin = (data: RequiredAny): Origin => {
  validateRequiredObject(data, 'origin');

  return {
    kiosk: validateRequiredNumber(data.kiosk, 'kiosk'),
    ipad: validateRequiredNumber(data.ipad, 'ipad'),
  };
};

const validateShoeModel = (data: RequiredAny): ShoeModelScanned => {
  validateRequiredObject(data, 'shoeModel');

  return {
    scanned: validateRequiredNumber(data.scanned, 'scanned'),
    unscanned: validateRequiredNumber(data.unscanned, 'unscanned'),
  };
};

const validateDerivatives = (data: RequiredAny): Derivatives => {
  validateRequiredObject(data, 'runnerType');
  validateRequiredObject(data, 'sessionType');
  validateRequiredObject(data, 'cadence');

  const { runnerType, sessionType, cadence, runnerEfficiency } = data;

  return {
    runnerType: {
      rearFoot: validateRequiredNumber(runnerType['rearFoot'], 'rearFoot'),
      midFoot: validateRequiredNumber(runnerType['midFoot'], 'midFoot'),
      foreFoot: validateRequiredNumber(runnerType['foreFoot'], 'foreFoot'),
      unknown: validateRequiredNumber(runnerType['unknown'], 'unknown'),
    },
    sessionType: {
      running: validateRequiredNumber(sessionType['running'], 'running'),
      walking: validateRequiredNumber(sessionType['walking'], 'walking'),
      unknown: validateRequiredNumber(sessionType['unknown'], 'unknown'),
    },
    cadence: {
      low: validateRequiredNumber(cadence['low'], 'low'),
      mid: validateRequiredNumber(cadence['mid'], 'mid'),
      high: validateRequiredNumber(cadence['high'], 'high'),
      unknown: validateRequiredNumber(cadence['unknown'], 'unknown'),
    },
    runnerEfficiency: {
      recreational: validateRequiredNumber(
        runnerEfficiency['recreational'],
        'recreational',
      ),
      experienced: validateRequiredNumber(
        runnerEfficiency['experienced'],
        'experienced',
      ),
      elite: validateRequiredNumber(runnerEfficiency['elite'], 'elite'),
    },
  };
};

const validateInjuries = (data: RequiredAny): Injuries => {
  return {
    lowerBack: validateRequiredNumber(data['lowerBack'], 'lowerBack'),
    leftUpperLeg: validateRequiredNumber(data['leftUpperLeg'], 'leftUpperLeg'),
    rightUpperLeg: validateRequiredNumber(
      data['rightUpperLeg'],
      'rightUpperLeg',
    ),
    leftKnee: validateRequiredNumber(data['leftKnee'], 'leftKnee'),
    rightKnee: validateRequiredNumber(data['rightKnee'], 'rightKnee'),
    leftLowerLeg: validateRequiredNumber(data['leftLowerLeg'], 'leftLowerLeg'),
    rightLowerLeg: validateRequiredNumber(
      data['rightLowerLeg'],
      'rightLowerLeg',
    ),
    leftAnkle: validateRequiredNumber(data['leftAnkle'], 'leftAnkle'),
    rightAnkle: validateRequiredNumber(data['rightAnkle'], 'rightAnkle'),
    leftAchilles: validateRequiredNumber(data['leftAchilles'], 'leftAchilles'),
    rightAchilles: validateRequiredNumber(
      data['rightAchilles'],
      'rightAchilles',
    ),
    leftFoot: validateRequiredNumber(data['leftFoot'], 'leftFoot'),
    rightFoot: validateRequiredNumber(data['rightFoot'], 'rightFoot'),
    notInjured: validateOptionalNumber(data['notInjured'], 'notInjured'),
  };
};

const validateSession = (data: RequiredAny): ApiSession => {
  validateRequiredObject(data, 'session');

  return {
    entries: validateRequiredNumber(data.entries, 'entries'),
    store: validateRequiredString(data.store, 'store'),
    storeName: validateRequiredString(data.storeName, 'storeName'),
    // TODO: Check undefined
    storeCountry:
      validateOptionalString(data.storeCountry, 'storeCountry') || 'unknown',
    numberOfSteps: validateRequiredNumber(data.numberOfSteps, 'numberOfSteps'),
    demographics: validateDemographics(data.demographics),
    origin: validateOrigin(data.origin),
    shoeModel: validateShoeModel(data.shoeModel),
    derivatives: validateDerivatives(data.derivatives),
    injuries: validateInjuries(data.injuries),
  };
};

// Serialization
export const serialize = (data: RequiredAny): SessionData => {
  validateRequiredObject(data, 'data');

  return {
    _id: validateTimeFrameId(data._id),
    sessions: validateRequiredArray(data.sessions, 'sessions').map(
      validateSession,
    ),
  };
};
