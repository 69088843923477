import { FC } from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  country: string;
}

const FlagIcon: FC<Props> = ({ className, country }) => (
  <span className={clsx(`fi fi-${country.toLowerCase()}`, className)} />
);

export default FlagIcon;
