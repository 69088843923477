import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apply, isNilOrWhitespace, storeModel } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreActions } from '../../../store/actions';
import { StoreSelectors } from '../../../store/selectors';

import {
  Button,
  Checkbox,
  Heading,
  Input,
  InputLabel,
  Text,
} from '../../../components';

import { StoreRouteParams } from '../types';

const Wrapper = styled.div``;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const StyledSectionHeading = styled(Heading)`
  margin-top: 32px;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

const Row = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px;
  margin-bottom: 16px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

const StyledInput = styled(Input)`
  width: 200px;
`;

const Permissions: FC = () => {
  const dispatch = useDispatch();

  const store = useSelector(StoreSelectors.selectStore);
  const updating = useSelector(StoreSelectors.selectStoreUpdating);

  const { storeId } = useParams<StoreRouteParams>();

  const initialValues = useMemo(
    () => ({
      isSelfManaged: apply(
        storeModel.getIsSelfManaged,
        store,
        storeModel.getIsSelfManaged(storeModel.defaults),
      ),
      isSharing: apply(
        storeModel.getIsSharing,
        store,
        storeModel.getIsSharing(storeModel.defaults),
      ),
      userSlots: apply(
        storeModel.getUserSlots,
        store,
        storeModel.getUserSlots(storeModel.defaults),
      ),
    }),
    [store],
  );

  const [isSelfManaged, setIsSelfManaged] = useState(
    initialValues.isSelfManaged,
  );
  const [isSharing, setIsSharing] = useState(initialValues.isSharing);
  const [userSlots, setUserSlots] = useState(initialValues.userSlots);

  const [error, setError] = useState(false);

  useEffect(() => {
    if (store) {
      setIsSelfManaged(storeModel.getIsSelfManaged(store));
      setIsSharing(storeModel.getIsSharing(store));
      setUserSlots(storeModel.getUserSlots(store));
    }
  }, [store]);

  const handleUpdate = async () => {
    try {
      if (!storeId) {
        toast('Failed to update store permissions!', {
          type: 'error',
        });

        return;
      }

      await dispatch(
        StoreActions.updateStore({
          storeId,
          data: {
            isSelfManaged,
            isSharing,
            userSlots,
          },
        }),
      ).unwrap();

      toast('Successfully updated store permissions!', {
        type: 'success',
      });
    } catch {
      toast('Failed to update store permissions!', {
        type: 'error',
      });
    }
  };

  const validateSlots = (value: string) => {
    const valid =
      !isNilOrWhitespace(value) && parseInt(value) >= storeModel.MIN_USER_SLOTS;

    setError(!valid);

    return valid;
  };

  const dirty =
    isSelfManaged !== initialValues.isSelfManaged ||
    isSharing !== initialValues.isSharing ||
    userSlots !== initialValues.userSlots;

  const disabled = !dirty || updating || error;

  return (
    <Wrapper>
      <StyledHeading variant="h1">Permissions</StyledHeading>
      <StyledSectionHeading variant="h2">User permissions</StyledSectionHeading>
      <StyledText variant="body2">
        Manage the store permissions users will have for ARIONHUB.
      </StyledText>

      <Row htmlFor={'is-self-managed'}>
        <StyledCheckbox
          id={'is-self-managed'}
          checked={isSelfManaged}
          onChange={() => setIsSelfManaged((prev) => !prev)}
        />
        <Text variant="body1">Can manage own permissions</Text>
      </Row>
      <Row htmlFor={'is-sharing'}>
        <StyledCheckbox
          id={'is-sharing'}
          checked={isSharing}
          onChange={() => setIsSharing((prev) => !prev)}
        />
        <Text variant="body1">Automatically share sessions with group</Text>
      </Row>
      <StyledSectionHeading variant="h2">
        Store permissions
      </StyledSectionHeading>
      <StyledText variant="body2">
        Manage the store permissions for ARIONHUB.
      </StyledText>
      <Group>
        <StyledInputLabel htmlFor="user-slots">User slots</StyledInputLabel>
        <StyledInput
          id="user-slots"
          error={error}
          min={storeModel.MIN_USER_SLOTS}
          placeholder="slots"
          type="number"
          value={Number.isFinite(userSlots) ? userSlots : ''}
          validate={validateSlots}
          onChange={(e) => setUserSlots(Number.parseInt(e.target.value, 10))}
        />
      </Group>
      <Button
        color="primary"
        size="small"
        variant="contained"
        disabled={disabled}
        onClick={handleUpdate}
      >
        {updating ? 'Updating...' : 'Apply changes'}
      </Button>
    </Wrapper>
  );
};

export default Permissions;
