import { ChangeEventHandler, FC, ReactNode } from 'react';
import styled from 'styled-components';

import { hasKey } from '../utils/tableUtils';

import Checkbox from './Checkbox';

const Wrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StyledLabel = styled.label`
  ${({ theme }) => theme.typography.h3};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

interface Option {
  key: string;
  label?: ReactNode;
}

interface Props {
  options: Option[];
  selected: Option[];
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const CheckBoxList: FC<Props> = ({ options, selected, onChange }) => (
  <Wrapper>
    {options.map(({ key, label }) => (
      <Row key={key}>
        <Checkbox
          id={key}
          checked={hasKey(selected, key)}
          onChange={onChange}
        />
        <StyledLabel htmlFor={key}>{label || key}</StyledLabel>
      </Row>
    ))}
  </Wrapper>
);

export default CheckBoxList;
