import { FC, useEffect, useState } from 'react';
import { storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ThemeActions } from '../../../store/actions';
import { StoreSelectors, ThemeSelectors } from '../../../store/selectors';

import { tabOptions } from '../../../tables/brandingTabs';

import { alphaToHex } from '../../../utils/colorUtils';

import { Heading, NavLink, Tabs, Text } from '../../../components';
import Images from './Images/Images';
import Logos from './Logos/Logos';
import Themes from './Themes/Themes';
import { storeRoutes } from '../../../routing';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BrandingTabs = styled(Tabs)`
  height: 48px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const Container = styled.div`
  margin-top: 32px;
`;

const Content = styled.div`
  border-top: 1px solid
    ${(props) => `${props.theme.colors.textTwo}${alphaToHex(0.3)}`};
  padding-top: 32px;
`;
const StyledNavLink = styled(NavLink)`
  text-decoration: underline;
  margin-top: 16px;
`;

const tabBodies = [Logos, Themes, Images];

const Branding: FC = () => {
  const dispatch = useDispatch();

  const themes = useSelector(ThemeSelectors.selectThemes);
  const store = useSelector(StoreSelectors.selectStore);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (store) {
      dispatch(ThemeActions.getGroupThemes(storeModel.getGroupId(store)));
    }
  }, [dispatch, store]);

  useEffect(() => {
    if (store && themes && themes.length > 0) {
      dispatch(ThemeActions.setThemeDarkById(storeModel.getDarkThemeId(store)));
      dispatch(
        ThemeActions.setThemeLightById(storeModel.getLightThemeId(store)),
      );
    }
  }, [dispatch, store, themes]);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const getTabContent = () => {
    const Component = tabBodies[tabIndex];
    return <Component />;
  };

  return (
    <Wrapper>
      <StyledHeading variant="h1">Branding</StyledHeading>
      <Text variant="body2">
        Customise your ARIONHUB experience by selecting a custom theme for your
        store. Changes will be applied after the app restart. If you would like
        to update the theme details contact ATOGEAR support or sales
        representative from the{' '}
        <StyledNavLink to={storeRoutes.team.path}>Team</StyledNavLink> section.
      </Text>
      <Container>
        <TabsContainer>
          <BrandingTabs
            tabs={tabOptions}
            tabWidth={200}
            onTabChange={handleTabChange}
          />
        </TabsContainer>
        <Content>{getTabContent()}</Content>
      </Container>
    </Wrapper>
  );
};

export default Branding;
