import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRoles } from '@atogear/arion-utils';

import { CreateOwnerInviteResult, getOnboardingService } from '../../services';

import { Invite } from '../../models/inviteModel';

import { validateNewPassword } from '../../utils/validators';

export const getInvite = createAsyncThunk(
  'onboarding/getInvite',
  /**
   * Get Store Account Invite.
   *
   * @param id Store Account Invite's id.
   * @returns Promise that resolves to Store Account Invite.
   */
  async (id: string): Promise<Invite> => {
    const result = await getOnboardingService().getInvite(id);

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.invitation;
  },
);

interface SendInviteParams {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRoles;
  storeId: string;
}

export const sendInvite = createAsyncThunk(
  'onboarding/sendInvite',
  /**
   * Send Store Account Invite.
   *
   * @param params Store Account's data.
   * @returns Promise that resolves when Store Account Invite has been sent successfully.
   */
  async (params: SendInviteParams): Promise<void> => {
    const { firstName, lastName, email, role, storeId } = params;

    const service = getOnboardingService();

    const createInvResult = await service.createInvite(
      firstName,
      lastName,
      email,
      role,
      storeId,
    );

    if (!createInvResult.data.success) {
      throw createInvResult.data.error;
    }

    const sendEmailResult = await service.sendInvite(
      createInvResult.data.invitationId,
    );

    if (!sendEmailResult.data.success) {
      throw sendEmailResult.data.error;
    }
  },
);

interface SendOwnerInviteParams {
  firstName: string;
  lastName: string;
  email: string;
  storeId: string;
}

export const sendOwnerInvite = createAsyncThunk(
  'onboarding/sendOwnerInvite',
  /**
   * Send Store Account Invite.
   *
   * @param params Store Account's data.
   * @returns Promise that resolves when Store Account Invite has been sent successfully.
   */
  async (
    params: SendOwnerInviteParams,
  ): Promise<CreateOwnerInviteResult['data']> => {
    const { firstName, lastName, email, storeId } = params;

    const service = getOnboardingService();

    const createOwnerInviteResult = await service.createOwnerInvite(
      firstName,
      lastName,
      email,
      storeId,
    );

    if (!createOwnerInviteResult.data.success) {
      throw createOwnerInviteResult.data.error;
    }

    return createOwnerInviteResult.data;
  },
);

interface CreateStoreAccountParams {
  password: string;
  confirmPassword: string;
  inviteId: string;
}

export const createStoreAccount = createAsyncThunk(
  'onboarding/createStoreAccount',
  /**
   * Create Store Account.
   *
   * @param params Store Account's data.
   * @returns Promise that resolves when Store Account has been created successfully.
   */
  async (params: CreateStoreAccountParams): Promise<void> => {
    const { password, confirmPassword, inviteId } = params;

    validateNewPassword(password, confirmPassword);

    const result = await getOnboardingService().createAccount(
      password,
      inviteId,
    );

    if (!result.data.success) {
      throw result.data.error;
    }
  },
);
