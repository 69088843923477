import {
  FC,
  MouseEventHandler,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { DefaultTheme } from 'styled-components';

interface TooltipProps {
  withStyles: boolean;
  $showTooltip: boolean;
}

const defaultTooltipStyles = (props: { theme: DefaultTheme }) => ({
  padding: '4px 8px',
  backgroundColor: props.theme.colors.light,
  color: props.theme.colors.dark,
  border: `1px solid ${props.theme.colors.grayOpaque}`,
  borderRadius: '6px',
  boxShadow: '0px 3px 6px #00000029',
  opacity: 1,
  zIndex: 1002,
  maxWidth: 250,
});

const Wrapper = styled.div`
  cursor: default;
`;

const StyledTooltip = styled.span<TooltipProps>`
  z-index: 1;
  position: fixed;
  ${(props) => props.theme.typography.body3};
  display: ${(props) => (props.$showTooltip ? 'block' : 'none')};
  ${(props) => (props.withStyles ? defaultTooltipStyles(props) : {})}
`;

interface Props {
  children: ReactNode | ReactNode[];
  tooltip: ReactNode;
}

const MouseTooltip: FC<Props> = ({ children, tooltip, ...rest }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLInputElement>(null);
  const tooltipRoot = document.getElementById('tooltip-root');

  const shouldUseDefaultStyles = useMemo(
    () => typeof tooltip === 'string',
    [tooltip],
  );

  const handleOnHover: MouseEventHandler<HTMLDivElement> = (e) => {
    const x = e.clientX;
    const y = e.clientY;

    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.style.top = y + 10 + 'px';
      tooltipRef.current.style.left = x + 10 + 'px';
    }
  };

  const handleLeave = () => {
    setShowTooltip(false);
  };

  const handleEnter = () => {
    setShowTooltip(true);
  };

  return (
    <Wrapper
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      onMouseMove={handleOnHover}
      {...rest}
    >
      {children}
      {tooltipRoot
        ? createPortal(
            <StyledTooltip
              ref={tooltipRef}
              $showTooltip={showTooltip}
              withStyles={shouldUseDefaultStyles}
            >
              {tooltip}
            </StyledTooltip>,
            tooltipRoot,
          )
        : null}
    </Wrapper>
  );
};

export default MouseTooltip;
