import { RequiredAny } from '@atogear/arion-utils';

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.display = 'none';
  // textArea.style.position = "fixed";
  // textArea.style.top = "-999px";
  // textArea.style.left = "-999px";

  document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();

  let result = false;

  try {
    result = document.execCommand('copy');
  } catch (err) {
    result = false;
  }

  document.body.removeChild(textArea);

  return result;
};

export const copyTextToClipboard = async (text: string) => {
  if (!navigator.clipboard) {
    const res = fallbackCopyTextToClipboard(text);

    if (res) {
      return Promise.resolve();
    }

    return Promise.reject('copy/fail');
  }

  navigator.clipboard.writeText(text).then(
    () => {
      return Promise.resolve();
    },
    () => {
      return Promise.reject('copy/fail');
    },
  );
};

// Sums the properties of 2 objects recursively
export const deepMergeSum = <T extends RequiredAny>(obj1: T, obj2: T) => {
  return Object.keys(obj1).reduce((acc, key) => {
    if (typeof obj2[key] === 'object') {
      acc[key as keyof T] = deepMergeSum(obj1[key], obj2[key]);
    } else if (obj2.hasOwnProperty(key) && !isNaN(parseFloat(obj2[key]))) {
      acc[key as keyof T] = obj1[key] + obj2[key];
    }
    return acc;
  }, {} as T);
};
