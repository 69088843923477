import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ReminderActions } from '../../../store/actions';
import { AuthSelectors, ReminderSelectors } from '../../../store/selectors';

import { AddTile, DeleteDialog, Heading } from '../../../components';
import { Reminder } from '../../../models/reminderModel';
import ReminderInfo from './ReminderInfo';
import ReminderDialog from './ReminderDialog/ReminderDialog';

const Wrapper = styled.div``;

const StyledAddTile = styled(AddTile)`
  margin-bottom: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const Reminders: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const reminders = useSelector(ReminderSelectors.selectReminders);
  const loading = useSelector(ReminderSelectors.selectRemindersFetching);

  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentReminder, setCurrentReminder] = useState<Reminder | undefined>(
    undefined,
  );

  const getUserReminders = useCallback(async () => {
    try {
      if (user?.userId) {
        await dispatch(
          ReminderActions.getUserReminders({
            userId: user.userId || '',
          }),
        ).unwrap();
      }
    } catch {
      toast('Failed to retrieve reminders!', {
        type: 'error',
      });
    }
  }, [dispatch, user]);

  useEffect(() => {
    getUserReminders();
  }, [getUserReminders]);

  const openReminderDialog = (reminder?: Reminder) => {
    setCurrentReminder(reminder);
    setReminderDialogOpen(true);
  };

  const closeReminderDialog = () => {
    setReminderDialogOpen(false);
    setCurrentReminder(undefined);
  };

  const openDeleteDialog = (reminder: Reminder) => {
    setDeleteDialogOpen(true);
    setCurrentReminder(reminder);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCurrentReminder(undefined);
  };

  const handleDelete = async () => {
    try {
      if (!currentReminder) {
        return;
      }

      await dispatch(
        ReminderActions.deleteReminder({
          reminderId: currentReminder.reminderId,
        }),
      ).unwrap();

      toast(
        'Successfully deleted reminder!',

        {
          type: 'success',
        },
      );

      closeDeleteDialog();
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem deleting the reminder. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleAddReminder = () => {
    openReminderDialog();
  };

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Reminders</ContentHeading>
        </Info>
      </Header>
      <StyledAddTile text={'reminder'} onClick={handleAddReminder} />
      {reminders.map((reminder: Reminder, index) => (
        <ReminderInfo
          key={reminder.reminderId}
          reminder={reminder}
          onEdit={openReminderDialog}
          onDelete={openDeleteDialog}
        ></ReminderInfo>
      ))}
      <ReminderDialog
        reminder={currentReminder}
        open={reminderDialogOpen}
        onClose={closeReminderDialog}
      />
      <DeleteDialog
        text="reminder"
        content="This will permanently delete this reminder."
        loading={loading}
        open={deleteDialogOpen && Boolean(currentReminder)}
        onClose={closeDeleteDialog}
        onConfirm={handleDelete}
      />
    </Wrapper>
  );
};

export default Reminders;
