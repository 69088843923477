import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

type Size = 'large' | 'normal' | 'small';

const getBorderSize = (size?: Size) => {
  switch (size) {
    case 'large':
      return 6;
    case 'small':
      return 2;
    case 'normal':
    default:
      return 4;
  }
};

const getSize = (size?: Size) => {
  switch (size) {
    case 'large':
      return 64;
    case 'small':
      return 16;
    case 'normal':
    default:
      return 40;
  }
};

interface WrapperProps {
  $size?: Size;
}

const Wrapper = styled.div<WrapperProps>`
  border: ${({ $size, theme }) =>
    `${getBorderSize($size)}px solid ${theme.colors.highlight}`};
  border-top: ${({ $size, theme }) =>
    `${getBorderSize($size)}px solid ${theme.colors.primary}`};
  border-radius: 50%;
  height: ${({ $size }) => `${getSize($size)}px`};
  width: ${({ $size }) => `${getSize($size)}px`};
  animation: ${loaderAnimation} 1s linear infinite;
`;

interface Props {
  size?: Size;
}

const Loader: FC<Props> = ({ size }) => <Wrapper $size={size} />;

export default Loader;
