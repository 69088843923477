import { FC } from 'react';
import styled from 'styled-components';

import { Tile, TileProps } from '../../../../components';

const Wrapper = styled(Tile)`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

interface Props extends TileProps {}

const MediaTile: FC<Props> = (props) => <Wrapper {...props} />;

export default MediaTile;
