import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apply } from '@atogear/arion-utils';
import { clamp } from 'ramda';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ContactActions, ProspectActions } from '../../../store/actions';
import { ContactSelectors, ProspectSelectors } from '../../../store/selectors';

import { appRoutes, getPath } from '../../../routing';

import { alphaToHex } from '../../../utils/colorUtils';

import {
  BackButton,
  Footer,
  Heading,
  Icon,
  LoadingMask,
} from '../../../components';
import Address from './Address';
import Contacts from './Contacts';
import General from './General';
import Notes from './Notes';

import { prospectModel } from '../../../models';
import { prospectRoutes } from '../../../routing/routes/prospectRoutes';
import { Prospect } from '../../../models/prospectModel';

const TOTAL_STEPS = 4;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  display: flex;
  flex-direction: column;
  height: 120px;
  padding: 0 60px;
`;

const Nav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProspectIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  margin-right: 12px;
`;

const ProgressBar = styled.div`
  background-color: ${({ theme }) => theme.colors.textTwo + alphaToHex(0.3)};
  height: 8px;
`;

interface ProgressProps {
  $progress?: number;
}

const Progress = styled.div<ProgressProps>`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100%;
  min-width: 16px;
  width: ${({ $progress }) => `${($progress || 0) * 100}%`};
  transition: width 0.2s ease;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 60px;
`;

const CreateProspect: FC = () => {
  const dispatch = useDispatch();

  const prospect = useSelector(ProspectSelectors.selectProspectPreview);
  const updating = useSelector(ProspectSelectors.selectProspectUpdating);
  const contacts = useSelector(ContactSelectors.selectContactsPreview);

  const prospectName = apply(prospectModel.getName, prospect, 'New prospect');

  const history = useHistory();

  const [step, setStep] = useState(1);

  useEffect(() => {
    dispatch(ContactActions.reset());
  }, [dispatch]);

  const updateStep = (diff: number) =>
    setStep((prev) => clamp(1, TOTAL_STEPS, prev + diff));

  const handlePrevClick = () => updateStep(-1);

  const handleNextClick = () => updateStep(1);

  const handleSubmit = async (prospect: Prospect) => {
    try {
      const prospectId = await dispatch(
        ProspectActions.createProspect({
          prospect: {
            // Prospect Info
            name: prospectModel.getName(prospect),
            type: prospectModel.getType(prospect),
            products: prospectModel.getProducts(prospect),
            priority: prospectModel.hasPriority(prospect),
            notes: prospectModel.getNotes(prospect),
            preferredLanguage: prospectModel.getPreferredLanguage(prospect),
            // Address
            address: prospectModel.getStreet(prospect),
            zipCode: prospectModel.getZipCode(prospect),
            city: prospectModel.getCity(prospect),
            country: prospectModel.getCountryCode(prospect),
            // Contact Info
            email: prospectModel.getEmail(prospect),
            website: prospectModel.getWebsite(prospect),
            callingCode: prospectModel.getCallingCode(prospect),
            phoneNumber: prospectModel.getPhoneNumber(prospect),
          },
          contacts,
        }),
      ).unwrap();

      toast('Prospect added!', {
        type: 'success',
      });

      history.push(
        getPath(prospectRoutes.about.path, {
          prospectId,
        }),
      );
    } catch (error) {
      toast((error as Error).message || 'Failed to create prospect!', {
        type: 'error',
      });
    }
  };

  return (
    <Wrapper>
      <LoadingMask loading={updating} />
      <Header>
        <Nav>
          <BackButton label="Back to Prospects" to={appRoutes.prospects.path} />
        </Nav>
        <Bottom>
          <Info>
            <ProspectIcon name="prospects" />
            <Heading variant="h2">{prospectName}</Heading>
          </Info>
          <Heading variant="h2">
            {step}/{TOTAL_STEPS}
          </Heading>
        </Bottom>
      </Header>
      <ProgressBar>
        <Progress $progress={(step - 1) / (TOTAL_STEPS - 1)} />
      </ProgressBar>
      <Content>
        {step === 1 && <General onNext={handleNextClick} />}
        {step === 2 && (
          <Address onPrev={handlePrevClick} onNext={handleNextClick} />
        )}
        {step === 3 && (
          <Contacts onPrev={handlePrevClick} onNext={handleNextClick} />
        )}
        {step === 4 && (
          <Notes onPrev={handlePrevClick} onSubmit={handleSubmit} />
        )}
      </Content>
      <Footer placement="middle" />
    </Wrapper>
  );
};

export default CreateProspect;
