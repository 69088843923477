import { ChangeEvent, FC, useState } from 'react';
import { formatDate } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import DocumentSignatureCanvas from './DocumentSignatureCanvas';
import Heading from '../Heading';
import Input from '../Input';

const Wrapper = styled.div`
  width: 400px;
  max-height: 100%;
  display: grid;
  grid-gap: 8px;
  margin: 0px 24px;
`;

const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const RoleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const PlaceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const DateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledInput = styled(Input)`
  width: 300px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const StyledSignatureCanvas = styled(DocumentSignatureCanvas)`
  background: linear-gradient(
        90deg,
        ${(props) => props.theme.colors.surfaceOne} 20px,
        transparent 1%
      )
      center,
    linear-gradient(
        ${(props) => props.theme.colors.surfaceOne} 20px,
        transparent 1%
      )
      center,
    ${(props) => props.theme.colors.text};
  background-size: 22px 22px;
  ${(props) => props.theme.components.tiles.default}
  border: 1px solid #d2d3d4;
`;

interface Props {
  title: string;
  identifier: string;
  onChange?: (key: string, data: string) => void;
}

const DocumentSignature: FC<Props> = ({
  title,
  identifier,
  onChange,
  ...rest
}) => {
  const [signedOn, setSignedOn] = useState<string | null>(null);

  const handleFieldChange = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(`${identifier}${key}`, e.target.value);
    }
  };

  const onSignatureChange = (data: string) => {
    if (onChange) {
      onChange(`${identifier}Signature`, data);
      const date = formatDate(new Date());
      onChange(`${identifier}SignDate`, date);
      setSignedOn(date);
      toast(`${title} signed the document!`, {
        type: 'success',
      });
    }
  };

  const onSignatureClear = () => {
    if (onChange) {
      onChange(`${identifier}Signature`, '');
      onChange(`${identifier}SignDate`, '');
      setSignedOn(null);
      toast(`${title} removed their signature!`, {
        type: 'warning',
      });
    }
  };

  return (
    <Wrapper {...rest}>
      <StyledHeading variant="h2">{title}</StyledHeading>
      <NameWrapper>
        <Heading variant="h3">Name</Heading>
        <StyledInput onChange={(e) => handleFieldChange(`SignName`, e)} />
      </NameWrapper>
      <RoleWrapper>
        <Heading variant="h3">Role</Heading>
        <StyledInput onChange={(e) => handleFieldChange(`SignRole`, e)} />
      </RoleWrapper>
      <StyledSignatureCanvas
        width={400}
        height={150}
        onChange={onSignatureChange}
        onClear={onSignatureClear}
      />
      <PlaceWrapper>
        <Heading variant="h3">Place</Heading>
        <StyledInput onChange={(e) => handleFieldChange(`SignLocation`, e)} />
      </PlaceWrapper>
      <DateWrapper>
        <Heading variant="h3">Date</Heading>
        {/* TODO: Require the signature of the user before displaying the date */}
        <StyledInput disabled value={signedOn || ''} />
      </DateWrapper>
    </Wrapper>
  );
};

export default DocumentSignature;
