import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formatInitials, storeModel } from '@atogear/arion-utils';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../../store';
import {
  PromotionActions,
  StoreActions,
  ThemeActions,
} from '../../../../store/actions';
import { StoreSelectors, ThemeSelectors } from '../../../../store/selectors';

import {
  getContent,
  getFormattedRedirectButtonUrl,
  getHeader,
  getRedirectButtonText,
  getSender,
  getSignature,
  getSubject,
  hasCoverImage,
  hasFlyer,
  hasPromo,
  Lead,
} from '../../../../models/leadModel';

import {
  Avatar,
  Button,
  Heading,
  Initials,
  Link,
  Text,
} from '../../../../components';
import Promo from './Promo/Promo';

import { StoreLeadParams } from './types';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  border: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const Sender = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImpressionImage = styled.img`
  background-color: ${({ theme }) => theme.colors.textTwo};
  object-fit: cover;
  height: 185px;
  width: 100%;
  margin: 12px 0;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
`;

const LogoImage = styled.img`
  object-fit: cover;
  height: 50px;
  width: 250px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
`;

const Message = styled(Text)`
  overflow-wrap: break-word;
  margin: 12px 0;
`;

const CallToActionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
`;

const SessionLink = styled.span`
  ${({ theme }) => theme.typography.body2};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 0;
`;

const Signature = styled(Text)`
  margin-top: 12px;
`;

interface Props {
  lead: Lead;
}

const Preview: FC<Props> = ({ lead }) => {
  const dispatch = useDispatch();

  const store = useSelector(StoreSelectors.selectStore);
  // TODO: Give the option to swap between both themes?
  const theme = useSelector(ThemeSelectors.selectLightTheme);

  const { storeId } = useParams<StoreLeadParams>();

  useEffect(() => {
    if (storeId) {
      dispatch(StoreActions.getStore(storeId));
      dispatch(PromotionActions.getDefaultPromotion(storeId));
    }
  }, [dispatch, storeId]);

  useEffect(() => {
    if (store) {
      dispatch(
        ThemeActions.getStoreThemes({
          groupId: storeModel.getGroupId(store),
          darkThemeId: storeModel.getDarkThemeId(store),
        }),
      );
    }
  }, [dispatch, store]);

  const sender = getSender(lead);

  return (
    <Wrapper>
      <Header>
        <Avatar size="large">
          <Initials>{formatInitials(sender)}</Initials>
        </Avatar>
        <Sender>
          <Text variant="body2">{sender}</Text>
          <Heading variant="h4">{getSubject(lead)}</Heading>
        </Sender>
      </Header>
      <Content>
        <AnimateHeight duration={250} height={hasCoverImage(lead) ? 'auto' : 0}>
          <ImpressionImage
            src={theme?.media.emailHeaderURL}
            alt="email-header"
          />
        </AnimateHeight>
        <Title>
          <Heading variant="h2">{getHeader(lead)}</Heading>
          <LogoImage src={theme?.media.fullLogoURL} alt="mini-logo" />
        </Title>
        <Body>
          <Message variant="body2">{getContent(lead)}</Message>
          <CallToActionSection>
            <Link href={getFormattedRedirectButtonUrl(lead)}>
              <Button color="primary" size="small" variant="contained">
                {getRedirectButtonText(lead)}
              </Button>
            </Link>
          </CallToActionSection>
          <AnimateHeight duration={250} height={hasFlyer(lead) ? 'auto' : 0}>
            <SessionLink>
              You have received this email based on&nbsp;
              <Link href={process.env.REACT_APP_FLYER_URL}>
                your last session
              </Link>
              &nbsp;in our store.
            </SessionLink>
          </AnimateHeight>
          <AnimateHeight duration={250} height={hasPromo(lead) ? 'auto' : 0}>
            <Promo />
          </AnimateHeight>
          <Signature variant="body2">{getSignature(lead)}</Signature>
        </Body>
      </Content>
    </Wrapper>
  );
};

export default Preview;
