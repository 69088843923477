import {
  deleteUndefinedFields,
  formatFullName,
  formatInitials,
  RequiredAny,
  storeModel,
  validateOptionalString,
  ExternalStore,
  Store,
} from '@atogear/arion-utils';

import { formatEquipment } from '../utils/formatters';

export interface ProspectStore extends Store {
  ownerFirstName?: string;
  ownerLastName?: string;
  ownerEmail?: string;
}

export type CreateProspectStoreData = Pick<
  ProspectStore,
  | 'storeName'
  | 'address'
  | 'zipCode'
  | 'city'
  | 'country'
  | 'email'
  | 'website'
  | 'callingCode'
  | 'phoneNumber'
  | 'treadmill'
  | 'track'
  | 'ownerFirstName'
  | 'ownerLastName'
  | 'ownerEmail'
>;

export interface ExternalProspectStore extends ExternalStore {
  ownerFirstName?: string;
  ownerLastName?: string;
  ownerEmail?: string;
}

// Fields
export const fields = {
  ...storeModel.fields,
  OWNER_FIRST_NAME: 'ownerFirstName',
  OWNER_LAST_NAME: 'ownerLastName',
  OWNER_EMAIL: 'ownerEmail',
};

// Defaults
export const defaults: ProspectStore = {
  // Prospect Info
  ...storeModel.defaults,
  ownerFirstName: '',
  ownerLastName: '',
  ownerEmail: '',
};

// Dummy
const dummyProspectStore: ProspectStore = {
  // Prospect Info
  ...storeModel.getDummy(),
  ownerFirstName: '',
  ownerLastName: '',
  ownerEmail: '',
};

export const getEquipment = (prospectStore: ProspectStore) =>
  formatEquipment(
    storeModel.hasTrack(prospectStore),
    storeModel.hasTreadmill(prospectStore),
  );

export const getOwnerFirstName = (prospectStore: ProspectStore) =>
  prospectStore.ownerFirstName;

export const getOwnerLastName = (prospectStore: ProspectStore) =>
  prospectStore.ownerLastName;

export const getOwnerName = (prospectStore: ProspectStore) =>
  formatFullName(
    getOwnerFirstName(prospectStore),
    getOwnerLastName(prospectStore),
  );

export const getOwnerInitials = (prospectStore: ProspectStore) =>
  formatInitials(
    getOwnerFirstName(prospectStore) || '',
    getOwnerLastName(prospectStore) || '',
  );

export const getOwnerEmail = (prospectStore: ProspectStore) =>
  prospectStore.ownerEmail?.toLowerCase();

export const getOwnerInfo = (prospectStore: ProspectStore) => {
  const ownerName = getOwnerName(prospectStore);
  const ownerEmail = getOwnerEmail(prospectStore);

  if (ownerName && ownerEmail) {
    return `${getOwnerName(prospectStore)}, ${getOwnerEmail(prospectStore)}`;
  }

  if (ownerName) {
    return `${getOwnerName(prospectStore)}`;
  }

  if (ownerEmail) {
    return `${getOwnerEmail(prospectStore)}`;
  }

  return '';
};

// Utils
export const create = (data?: Partial<ProspectStore>): ProspectStore => ({
  ...defaults,
  ...data,
});

export const serialize = (data: RequiredAny): ProspectStore => {
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid prospect store data provided');
  }

  const store = storeModel.serialize(data);

  return deleteUndefinedFields({
    ...store,
    // Owner
    ownerFirstName: validateOptionalString(
      data.ownerFirstName,
      fields.OWNER_FIRST_NAME,
    ),
    ownerLastName: validateOptionalString(
      data.ownerLastName,
      fields.OWNER_LAST_NAME,
    ),
    ownerEmail: validateOptionalString(data.ownerEmail, fields.OWNER_EMAIL),
  });
};

export const deserialize = (
  data: Partial<ProspectStore>,
): Partial<ExternalStore> => {
  const store = storeModel.deserialize(data);

  return deleteUndefinedFields({
    ...store,
    // Owner
    ownerFirstName: data.ownerFirstName,
    ownerLastName: data.ownerLastName,
    ownerEmail: data.ownerEmail?.toLowerCase(),
  });
};

export const getDummy = () => dummyProspectStore;
