import { FC } from 'react';
import { Store } from '@atogear/arion-utils';
import styled from 'styled-components';

import { Theme } from '../../theme/types';

import Icon from '../Icon';
import MouseTooltip from '../MouseTooltip';

const Wrapper = styled.div<StyleProps>`
  background-color: ${(props) => props.previewTheme.colors.surfaceOne};
  display: flex;
  height: 150px;
  width: 100%;
  ${(props) => props.previewTheme.components.tiles.session};
  box-shadow: 0px 1px 3px ${(props) => props.previewTheme.colors.grayOpaque};
  padding: 24px 24px;
`;

const TileContent = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: calc(100% - 90px);
  height: 90px;
  margin-left: 24px;
`;

const StyledView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img<StyleProps>`
  background-color: ${(props) => props.previewTheme.colors.surfaceTwo};
  object-fit: cover;
  width: 90px;
  height: 90px;
`;

const StyledName = styled.div<StyleProps>`
  ${(props) => props.previewTheme.typography.h2}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledAddressText = styled.div<StyleProps>`
  ${(props) => props.previewTheme.typography.body1}
  margin-left: 8px;
`;

const StyledAddress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSocials = styled.div<StyleProps>`
  ${(props) => props.previewTheme.typography.a}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-left: 14px;
`;

const StyledAddressIcon = styled(Icon)<StyleProps>`
  color: ${(props) => props.previewTheme.colors.primary};
`;

interface StyleProps {
  previewTheme: Theme;
}

interface Props {
  previewTheme: Theme;
  store?: Partial<Store>;
}

const AppTile: FC<Props> = ({ previewTheme, store }) => {
  return (
    <Wrapper previewTheme={previewTheme}>
      <StyledView>
        <MouseTooltip tooltip={'Logo (avatar)'}>
          <Logo
            previewTheme={previewTheme}
            src={previewTheme.media.miniLogoURL}
          />
        </MouseTooltip>
        <TileContent previewTheme={previewTheme}>
          <MouseTooltip tooltip={'Heading 2'}>
            <StyledName previewTheme={previewTheme}>
              {store?.storeName}
            </StyledName>
          </MouseTooltip>
          <MouseTooltip tooltip={'Body 1'}>
            <StyledAddress>
              <StyledAddressIcon previewTheme={previewTheme} name="location" />
              <StyledAddressText previewTheme={previewTheme}>
                {`${store?.address}, ${store?.zipCode} ${store?.city}`}
              </StyledAddressText>
            </StyledAddress>
          </MouseTooltip>
          <MouseTooltip tooltip={'Link'}>
            <StyledSocials previewTheme={previewTheme}>
              {store?.website}
              <StyledIcon name="facebook" />
              <StyledIcon name="instagram" />
            </StyledSocials>
          </MouseTooltip>
        </TileContent>
      </StyledView>
    </Wrapper>
  );
};

export default AppTile;
