import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEventHandler,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ascend, sort } from 'ramda';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { LeadActions } from '../../../store/actions';
import { LeadSelectors } from '../../../store/selectors';

import { LeadTypes } from '../../../enums';
import { getId, getType, isReadonly } from '../../../models/leadModel';

import { translatedLeadTypes } from '../../../translations';
import { defaults } from '../../../models/leadModel';

import { Heading, Tabs, Text } from '../../../components';
import Instant from './Instant';
import Recurring from './Recurring';
import Scheduled from './Scheduled';

import { StoreRouteParams } from '../types';
import { appRoutes, getPath } from '../../../routing';
import AddLeadTile from './AddLeadTile';

const leadTypes = Object.values(LeadTypes);

const tabs = leadTypes.map((value) => ({
  label: translatedLeadTypes[value],
}));

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeadTabs = styled(Tabs)`
  height: 48px;
`;

const TabContent = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.grayOpaque};
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`;

const TabHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const TabDescription = styled(Text)`
  margin-bottom: 16px;
`;

const Content: FC = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const leads = useSelector(LeadSelectors.selectLeads);

  const { storeId } = useParams<StoreRouteParams>();

  const [tabIndex, setTabIndex] = useState(0);

  const [leadType, setLeadType] = useState(LeadTypes.SCHEDULED);

  // Sort leads so readonly (ended) leads are at the bottom
  const sorted = useMemo(() => {
    return sort(ascend(isReadonly), leads);
  }, [leads]);

  // Filter leads by type selected in tab
  const data = useMemo(() => {
    return sorted.filter((lead) => leadTypes[tabIndex] === getType(lead));
  }, [sorted, tabIndex]);

  const getLeads = useCallback(async () => {
    try {
      if (storeId) {
        await dispatch(LeadActions.getLeads(storeId)).unwrap();
      }
    } catch {
      toast('Failed to retrieve lead activations!', {
        type: 'error',
      });
    }
  }, [dispatch, storeId]);

  useEffect(() => {
    getLeads();
  }, [getLeads]);

  const { title, description, Component } = useMemo(() => {
    switch (tabIndex) {
      case 0:
        setLeadType(LeadTypes.SCHEDULED);
        return {
          title: 'Scheduled emails',
          description:
            'Scheduled emails are sent once a certain amount of time has passed since your customer last used your ARIONHUB system.',
          Component: Scheduled,
        };
      case 1:
        setLeadType(LeadTypes.INSTANT);
        return {
          title: 'Instant emails',
          description:
            'Instant emails are sent only once at the specified date and time. You cannot schedule multiple one-time emails for the same date.',
          Component: Instant,
        };
      case 2:
        setLeadType(LeadTypes.RECURRING);
        return {
          title: 'Recurring emails',
          description:
            'Schedule recurring activation emails on a specific timeline of your choice [example: monthly, quarterly, biyearly].',
          Component: Recurring,
        };
      default:
        setLeadType(LeadTypes.SCHEDULED);
        return {
          title: '',
          description: '',
          Component: Fragment,
        };
    }
  }, [tabIndex]);

  const handleAddTemplateClick: MouseEventHandler<HTMLSpanElement> = async (
    e,
  ) => {
    e.stopPropagation();

    if (!storeId) {
      return;
    }

    dispatch(
      LeadActions.leadPreviewUpdated({
        ...defaults,
        type: leadType || LeadTypes.SCHEDULED,
        name: 'New lead activation',
        sender: 'Sender',
        subject: 'Subject',
        header: 'Headline',
        content: 'Message',
        redirectButtonText: 'Button text',
        signature: 'Closing',
        redirectButtonUrl: 'https://www.arion.run/hub/',
      }),
    );

    history.push(
      getPath(appRoutes.leadEditor.path, {
        storeId,
        leadId: 'new',
      }),
    );
  };

  return (
    <Wrapper>
      <TabsContainer>
        <LeadTabs tabs={tabs} tabWidth={200} onTabChange={setTabIndex} />
      </TabsContainer>
      <TabContent>
        <TabHeading variant="h2">{title}</TabHeading>
        <TabDescription variant="body2">{description}</TabDescription>
        {data.map((lead) => (
          <Component key={getId(lead)} lead={lead} />
        ))}
        <AddLeadTile onClick={handleAddTemplateClick} />
      </TabContent>
    </Wrapper>
  );
};

export default Content;
