import { FC } from 'react';
import styled from 'styled-components';

import Text from '../Text';

import { TableColumn } from './types';

interface WrapperProps {
  $clickable: boolean;
}
const Wrapper = styled.tr<WrapperProps>`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  display: flex;
  flex-direction: row;
  height: 70px;
  margin-top: 2px;
  padding: 0 32px;
  transition: background-color ease 0.2s;

  :hover {
    background-color: ${({ theme }) => theme.colors.highlight};
    cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
  }
`;

interface CellProps {
  $flex?: number;
}

const TableBodyRowCell = styled.td<CellProps>`
  display: flex;
  flex: ${({ $flex }) => $flex ?? 1};
  flex-direction: row;
  align-items: center;
  height: 70px;
`;

interface Props {
  columns: TableColumn[];
  row: any;
  onClick?: (data: any) => void;
}

const TableBodyRow: FC<Props> = ({ columns, row, onClick }) => {
  const handleClick = () => onClick && onClick(row);

  return (
    <Wrapper onClick={handleClick} $clickable={Boolean(onClick)}>
      {columns.map(({ key, flex, getValue, renderBody }) => (
        <TableBodyRowCell key={key} $flex={flex}>
          {renderBody ? (
            renderBody(row)
          ) : (
            <Text variant="body2">{getValue(row)}</Text>
          )}
        </TableBodyRowCell>
      ))}
    </Wrapper>
  );
};

export default TableBodyRow;
