import {
  deleteUndefinedFields,
  roundTo,
  Store,
  toMillis,
  validateOptionalNumber,
  validateRequiredNumber,
  validateRequiredObject,
  validateRequiredString,
} from '@atogear/arion-utils';
import { differenceInDays, differenceInWeeks } from 'date-fns';

import { RequiredAny } from './types';

// Types
export type UserType = 'Power' | 'Casual' | 'Sleeping' | 'Dormant';

export interface ApiStore
  extends Pick<
    Store,
    | 'storeId'
    | 'storeName'
    | 'subscriptionStartDate'
    | 'subscriptionEndDate'
    | 'sessionCount'
    | 'lastSessionDate'
    | 'subscriptionStatus'
  > {
  daysActive: number;
  daysLeft: number;
  daysSinceLastSession?: number;
  sessionsPerWeek: number;
  sessionsLastWeek?: number;
  userType: UserType;
  isSelected?: boolean;
}

// Functions
export const getStoreName = (store: ApiStore) => store.storeName;

// Serialization
const calcDaysActive = (subscriptionStartDate?: number) =>
  subscriptionStartDate
    ? Math.max(differenceInDays(Date.now(), subscriptionStartDate), 0)
    : 0;

const calcDaysLeft = (subscriptionEndDate?: number) =>
  subscriptionEndDate
    ? Math.max(differenceInDays(subscriptionEndDate, Date.now()), 0)
    : 0;

const calcDaysSinceLastSession = (lastSessionDate?: number) =>
  lastSessionDate
    ? Math.max(differenceInDays(Date.now(), lastSessionDate), 0)
    : undefined;

const calcSessionsPerWeek = (
  sessionCount: number,
  subscriptionStartDate?: number,
) => {
  const weeksActive = subscriptionStartDate
    ? differenceInWeeks(Date.now(), subscriptionStartDate)
    : 0;

  return weeksActive ? roundTo(2, sessionCount / weeksActive) : 0;
};

const calcUserType = (sessionCount: number): UserType => {
  if (sessionCount >= 6) {
    return 'Power';
  }

  if (sessionCount >= 1) {
    return 'Casual';
  }

  if (sessionCount === 0) {
    return 'Sleeping';
  }

  return 'Dormant';
};

export const serialize = (data: RequiredAny): ApiStore => {
  validateRequiredObject(data, 'apiStore');

  const subscriptionStartDate = validateOptionalNumber(
    toMillis(data.subscriptionStartDate) || undefined,
    'apiStore.subscriptionStartDate',
  );

  const subscriptionEndDate = validateOptionalNumber(
    toMillis(data.subscriptionEndDate) || undefined,
    'apiStore.subscriptionEndDate',
  );

  const sessionCount = validateRequiredNumber(
    data.sessionCount,
    'apiStore.sessionCount',
  );

  const lastSessionDate = validateOptionalNumber(
    toMillis(data.lastSessionDate) || undefined,
    'apiStore.lastSessionDate',
  );

  return deleteUndefinedFields({
    storeId: validateRequiredString(data.storeId, 'apiStore.storeId'),
    storeName: validateRequiredString(data.storeName, 'apiStore.storeName'),
    subscriptionStatus: data.subscriptionStatus,
    subscriptionStartDate,
    subscriptionEndDate,
    daysActive: calcDaysActive(subscriptionStartDate),
    daysLeft: calcDaysLeft(subscriptionEndDate),
    sessionCount,
    lastSessionDate,
    daysSinceLastSession: calcDaysSinceLastSession(lastSessionDate),
    sessionsLastWeek: validateOptionalNumber(
      data.sessionsLastWeek,
      'apiStore.sessionsLastWeek',
    ),
    sessionsPerWeek: calcSessionsPerWeek(sessionCount, subscriptionStartDate),
    userType: calcUserType(sessionCount),
  });
};
