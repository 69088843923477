import {
  Group,
  RequiredAny,
  validateRequiredArray,
  validateRequiredObject,
  validateRequiredString,
} from '@atogear/arion-utils';

import { ApiStore, serialize as serializeStore } from './apiStoreModel';

// Types
export interface ApiGroup extends Pick<Group, 'name' | 'uuid'> {
  stores: ApiStore[];
}

// Functions
export const getGroupName = (data: ApiGroup) => data.name;

// Serialization
export const serialize = (data: RequiredAny): ApiGroup => {
  validateRequiredObject(data, 'apiGroup');

  return {
    name: validateRequiredString(data.name, 'apiGroup.name'),
    uuid: validateRequiredString(data.uuid, 'apiGroup.uuid'),
    stores: validateRequiredArray(data.stores, 'apiGroup.stores').map(
      serializeStore,
    ),
  };
};
