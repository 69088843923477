import { MediaType } from './types';

const media: MediaType = {
  appBackgroundURL:
    'https://firebasestorage.googleapis.com/v0/b/runlab-prod.appspot.com/o/atogear-branding%2FappBackground.png?alt=media&token=1cf6ded9-cbcf-44de-b747-b058bec29d9b',
  emailHeaderURL:
    'https://firebasestorage.googleapis.com/v0/b/runlab-prod.appspot.com/o/atogear-branding%2FemailHeader.jpeg?alt=media&token=013ed083-d5e9-4ab4-962a-0b4bab8ea862',
  flyerHeaderURL:
    'https://firebasestorage.googleapis.com/v0/b/runlab-prod.appspot.com/o/atogear-branding%2FflyerHeader.jpeg?alt=media&token=d1271a26-605e-48cc-81be-37e0853c9f77',
  fullLogoURL:
    'https://firebasestorage.googleapis.com/v0/b/runlab-prod.appspot.com/o/atogear-branding%2FfullLogoDark.png?alt=media&token=b473adf2-04cc-4d5d-ba32-b61bd373d520',
  kioskVideoURL:
    'https://firebasestorage.googleapis.com/v0/b/runlab-prod.appspot.com/o/atogear-branding%2FkioskVideo.mp4?alt=media&token=347e5ad0-51b3-4500-9bcd-891b8a447a85',
  miniLogoURL:
    'https://firebasestorage.googleapis.com/v0/b/runlab-prod.appspot.com/o/atogear-branding%2FminiLogoDark.png?alt=media&token=e75b3b1e-d297-4eff-bed9-cf16d2d5b0d5',
  settingsHeaderURL:
    'https://firebasestorage.googleapis.com/v0/b/runlab-prod.appspot.com/o/atogear-branding%2FsettingsHeader.jpg?alt=media&token=247f38c5-3cb7-43a0-88e3-40a91fbbbc57',
};

export default media;
