import { createAsyncThunk } from '@reduxjs/toolkit';

import getProspectStoreService from '../../services/prospectStores';
import {
  CreateProspectStoreData,
  ProspectStore,
} from '../../models/prospectStoreModel';

interface GetProspectStoresParams {
  prospectId: string;
}

export const getProspectStores = createAsyncThunk(
  /**
   * Get all prospect's stores.
   * @param params prospect's id
   * @returns Promise that resolves to all stores of a prospect.
   */
  'prospectStores/getProspectStores',
  async (params: GetProspectStoresParams): Promise<ProspectStore[]> => {
    const { prospectId } = params;

    const prospectStores = await getProspectStoreService().getProspectStores(
      prospectId,
    );

    return prospectStores;
  },
);

interface GetProspectStoreParams {
  prospectId: string;
  prospectStoreId: string;
}

export const getProspectStore = createAsyncThunk(
  /**
   * Get prospect's store.
   * @param params prospect's id and prospect's store's id
   * @returns Promise that resolves to a store of a prospect.
   */
  'prospectStores/getProspectStore',
  async (params: GetProspectStoreParams): Promise<ProspectStore> => {
    const { prospectId, prospectStoreId } = params;

    const prospectStore = await getProspectStoreService().getProspectStore(
      prospectId,
      prospectStoreId,
    );

    return prospectStore;
  },
);

interface CreateProspectStoreParams {
  prospectId: string;
  prospectStore: CreateProspectStoreData;
}

export const createProspectStore = createAsyncThunk(
  /**
   * Create prospect's store.
   *
   * @param params prospect's id and prospect's store's data.
   * @returns Promise that resolves to created prospect's stores's id.
   */
  'prospectStores/createProspectStore',
  async (params: CreateProspectStoreParams): Promise<ProspectStore> => {
    const { prospectId, prospectStore } = params;

    const createdProspectStoreId =
      await getProspectStoreService().createProspectStore(
        prospectId,
        prospectStore,
      );

    const createdProspectStore =
      await getProspectStoreService().getProspectStore(
        prospectId,
        createdProspectStoreId,
      );

    return createdProspectStore;
  },
);

interface UpdateProspectStoreParams {
  prospectId: string;
  prospectStoreId: string;
  prospectStore: ProspectStore;
}

export const updateProspectStore = createAsyncThunk(
  /**
   * Update prospect's store.
   *
   * @param params prospect's id, prospect's store's id and prospect's store's data.
   * @returns Promise that resolves to updated prospect's store.
   */
  'prospectStores/updateProspectStore',
  async (params: UpdateProspectStoreParams): Promise<ProspectStore> => {
    const { prospectId, prospectStoreId, prospectStore } = params;

    const updatedProspectStore =
      await getProspectStoreService().updateProspectStore(
        prospectId,
        prospectStoreId,
        prospectStore,
      );

    return updatedProspectStore;
  },
);

export const deleteProspectStore = createAsyncThunk(
  /**
   * Delete prospect's store.
   *
   * @param params prospect's id and prospect's store's id.
   * @returns Promise that resolves when prospect's store has been successfully deleted.
   */
  'prospectStores/deleteProspectStore',
  async (params: GetProspectStoreParams): Promise<string | undefined> => {
    const { prospectId, prospectStoreId } = params;

    const result = await getProspectStoreService().deleteProspectStore(
      prospectId,
      prospectStoreId,
    );

    return result ? prospectStoreId : undefined;
  },
);
