import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { CreateStoreData, Store, User, userModel } from '@atogear/arion-utils';
import { isEmpty } from 'ramda';

import {
  getInvoiceService,
  getStoreService,
  getSubscriptionService,
} from '../../services';

import { withWooCommerce } from '../../models/appSettingsModel';
import { Contact } from '../../models/contactModel';
import { Invoice } from '../../models/invoiceModel';

import { reset as contactsReset } from '../contacts/actions';

import { RootState } from '../types';
import { StoreOwner } from '../../models/types';

export const getAllStores = createAsyncThunk(
  'stores/getAllStores',
  /**
   * Get all Stores.
   *
   * @returns Promise that resolves to all Stores.
   */
  async (): Promise<Store[]> => {
    const stores = await getStoreService().getStores();

    return stores;
  },
);

export const getStores = createAsyncThunk(
  'stores/getStores',
  /**
   * Get User's Stores.
   *
   * @param user User.
   * @returns Promise that resolves to User's Stores.
   */
  async (user: User): Promise<Store[]> => {
    const service = getStoreService();

    let stores = [];

    // Admin and Back Office can see all stores
    if (
      userModel.isPowerUser(user) ||
      userModel.isDataViewer(user) ||
      userModel.isInternalDesigner(user)
    ) {
      stores = await service.getStores();
    }
    // Sales, Distributors and Owners can see their own stores
    else if (
      userModel.isSales(user) ||
      userModel.isDistributor(user) ||
      userModel.isGroupManager(user) ||
      userModel.isOwner(user)
    ) {
      stores = await service.getUserStores(user);
    }
    // Invalid role (e.g. Manager or Employee)
    else {
      throw new Error('Unauthorized role');
    }

    return stores;
  },
);

export const getStore = createAsyncThunk(
  'stores/getStore',
  /**
   * Get Store.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Store.
   */
  async (storeId: string): Promise<Store> => {
    const store = await getStoreService().getStore(storeId);

    return store;
  },
);

interface CreateStoreParams {
  store: CreateStoreData;
  contacts: Contact[];
  owner: StoreOwner;
}

export const createStore = createAsyncThunk(
  'stores/createStore',
  /**
   * Create Store.
   *
   * @param params Store's data and contacts.
   * @returns Promise that resolves to created Store's id.
   */
  async (params: CreateStoreParams, { dispatch }): Promise<string> => {
    const { store, contacts, owner } = params;

    const storeId = await getStoreService().createStore(store, contacts, owner);

    dispatch(contactsReset());

    return storeId;
  },
);

interface UpdateStoreParams {
  storeId: string;
  data: Partial<Store>;
}

export const updateStore: AsyncThunk<Store, UpdateStoreParams, Config> =
  createAsyncThunk<Store, UpdateStoreParams, Config>(
    'stores/updateStore',
    /**
     * Update Store.
     *
     * @param params Store's id and data.
     * @returns Promise that resolves to updated Store.
     */
    async (params: UpdateStoreParams, { getState }): Promise<Store> => {
      const { storeId, data } = params;

      const service = getStoreService();

      const user = getState().auth.user.data;

      if (!user) {
        throw new Error('No user signed in!');
      }

      const updateFn = userModel.isPowerUser(user)
        ? service.updateStoreDoc
        : service.updateStoreFn;

      const store = await updateFn(storeId, data);

      return store;
    },
  );

export const upgradeStore = createAsyncThunk(
  'stores/upgradeStore',
  /**
   * Upgrade Store.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to upgraded Store.
   */
  async (storeId: string, { dispatch }): Promise<Store> => {
    await getStoreService().upgradeStore(storeId);

    const store = await dispatch(getStore(storeId)).unwrap();

    return store;
  },
);

export const deleteStore = createAsyncThunk(
  'stores/deleteStore',
  /**
   * Delete Store.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves when Store has been deleted successfully.
   */
  async (storeId: string): Promise<boolean> => {
    const success = await getStoreService().deleteStore(storeId);

    return success;
  },
);

interface Config {
  state: RootState;
}

export const cancelSubscription: AsyncThunk<Store, string, Config> =
  createAsyncThunk<Store, string, Config>(
    'stores/cancelSubscription',
    /**
     * Cancel Subscription.
     *
     * @param storeId Store's id.
     * @returns Promise that resolves to Store if it has been cancelled successfully.
     */
    async (storeId: string, { dispatch, getState }): Promise<Store> => {
      const { auth, settings } = getState();

      const user = auth.user.data;

      if (!user) {
        throw new Error('No user signed in!');
      }

      const useWoocommerce = withWooCommerce(settings.appSettings.data);

      const result = await getSubscriptionService().cancelSubscription(
        storeId,
        useWoocommerce,
      );

      if (!result.data.success) {
        throw result.data.error;
      }

      return dispatch(getStore(storeId)).unwrap();
    },
  );

export const reactivateSubscription: AsyncThunk<Store, string, Config> =
  createAsyncThunk<Store, string, Config>(
    'stores/reactivateSubscription',
    /**
     * Reactivate Subscription.
     *
     * @param storeId Store's id.
     * @returns Promise that resolves to Store if it has been reactivated successfully.
     */
    async (storeId: string, { dispatch, getState }): Promise<Store> => {
      const { auth, settings } = getState();

      const user = auth.user.data;

      if (!user) {
        throw new Error('No user signed in!');
      }

      const useWoocommerce = withWooCommerce(settings.appSettings.data);

      const result = await getSubscriptionService().reactivateSubscription(
        storeId,
        useWoocommerce,
      );

      if (!result.data.success) {
        throw result.data.error;
      }

      return dispatch(getStore(storeId)).unwrap();
    },
  );

interface ExtendTrialParams {
  storeId: string;
  days: number;
}

export const extendTrial: AsyncThunk<Store, ExtendTrialParams, Config> =
  createAsyncThunk<Store, ExtendTrialParams, Config>(
    'stores/extendTrial',
    /**
     * Extend Store's Trial.
     *
     * @param params Extend Trial's data.
     * @returns Promise that resolves to Store if it's Trial has been extended successfully.
     */
    async (params: ExtendTrialParams, { dispatch, getState }) => {
      const { storeId, days } = params;

      const { auth, settings } = getState();

      const user = auth.user.data;

      if (!user) {
        throw new Error('No user signed in!');
      }

      const useWoocommerce = withWooCommerce(settings.appSettings.data);

      const response = await getSubscriptionService().extendTrial(
        storeId,
        days,
        useWoocommerce,
      );

      if (!response.data.success) {
        throw response.data.error;
      }

      return dispatch(getStore(storeId)).unwrap();
    },
  );

export const getInvoices = createAsyncThunk(
  /**
   * Get Store's Invoices.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Store's Invoices.
   */
  'stores/getInvoices',
  async (storeId: string): Promise<Invoice[]> => {
    const invoices = await getInvoiceService().getInvoices(storeId);

    return invoices;
  },
);

interface UploadAgreementParams {
  storeId: string;
  data: Blob | ArrayBuffer | Uint8Array;
}

export const uploadAgreement: AsyncThunk<Store, UploadAgreementParams, Config> =
  createAsyncThunk<Store, UploadAgreementParams, Config>(
    'stores/uploadAgreement',
    /**
     * Upload Store's Agreement.
     *
     * @param params Store's id and file to upload.
     * @returns Promise that resolves when Agreement has been uploaded successfully.
     */
    async (params: UploadAgreementParams, { dispatch }): Promise<Store> => {
      const { storeId, data } = params;

      const agreementPath = await getSubscriptionService().uploadAgreement(
        storeId,
        data,
      );

      const updatedStore = await dispatch(
        updateStore({
          storeId,
          data: {
            agreementPath,
          },
        }),
      ).unwrap();

      return updatedStore;
    },
  );

interface DownloadAgreementParams {
  storeId: string;
  agreementPath: string;
}

export const downloadAgreement = createAsyncThunk(
  'stores/downloadAgreement',
  /**
   * Download Store's Agreement.
   *
   * @param params Store's id and path to agreement in storage.
   * @returns Promise that resolves to Agreement's download url.
   */
  async (params: DownloadAgreementParams): Promise<string> => {
    const { storeId, agreementPath } = params;

    const result = await getSubscriptionService().downloadAgreement(
      storeId,
      agreementPath,
    );

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.url;
  },
);

interface SendAgreementParams {
  storeId: string;
  contacts: object[];
}

export const sendAgreementToContacts = createAsyncThunk(
  'stores/sendAgreementToContacts',
  /**
   * Upload Store's Agreement.
   *
   * @param params Store's id and file to upload.
   * @returns Promise that resolves when Agreement has been uploaded successfully.
   */
  async (params: SendAgreementParams): Promise<void> => {
    const { storeId, contacts } = params;

    if (isEmpty(contacts)) return;

    await getSubscriptionService().sendAgreementToContacts(storeId, contacts);
  },
);
