import { FC } from 'react';
import styled from 'styled-components';

import { useSelector } from '../../../../../store';
import { selectDefaultPromotion } from '../../../../../store/promotions/selectors';

import { PromotionItem } from '../../../../../models/promotionItemModel';
import {
  getButtonText,
  getDefaultImageUrl,
  getDescription,
  getFormattedButtonUrl,
  getImageUrl,
  getPromotionItems,
  getTitle,
  hasPromotionButton,
  hasPromotionItems,
} from '../../../../../models/promotionModel';

import {
  Button,
  DialogHeading,
  DialogText,
  Link,
} from '../../../../../components';
import PromoTile from './PromoTile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 275px;
  width: 550px;
  margin: 12px 0;
`;

const CallToAction = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 24px 12px;
  width: 50%;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 12px;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
`;

interface RowProps {
  $top?: boolean;
}

const Row = styled.div<RowProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 12px 0;
`;

const Promo: FC = () => {
  const promotion = useSelector(selectDefaultPromotion);

  const image = getImageUrl(promotion) || getDefaultImageUrl(promotion);
  const title = getTitle(promotion);
  const text = getDescription(promotion);
  const showPromoButton = hasPromotionButton(promotion);
  const showPromotions = hasPromotionItems(promotion);
  const promotions = getPromotionItems(promotion);

  const renderTile = (promotion?: PromotionItem) =>
    !!promotion && <PromoTile promotion={promotion} />;

  const renderFirstRow = showPromotions && (
    <Row $top>
      {renderTile(promotions[0])}
      {renderTile(promotions[1])}
    </Row>
  );

  const renderSecondRow = showPromotions && promotions.length > 2 && (
    <Row>
      {renderTile(promotions[2])}
      {renderTile(promotions[3])}
    </Row>
  );

  return (
    <Wrapper>
      <CallToAction
        style={{
          backgroundImage: `url(${image}`,
        }}
      >
        <TopSection>
          <StyledHeading variant="h2">{title}</StyledHeading>
          <DialogText variant="body3">{text}</DialogText>
        </TopSection>
        {showPromoButton && (
          <BottomSection>
            <Link href={getFormattedButtonUrl(promotion)}>
              <Button color="primary" size="small" variant="contained">
                {getButtonText(promotion)}
              </Button>
            </Link>
          </BottomSection>
        )}
      </CallToAction>
      <Tiles>
        {renderFirstRow}
        {renderSecondRow}
      </Tiles>
    </Wrapper>
  );
};

export default Promo;
