import { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

import { DefaultThemeIds } from '../../../../enums';

import { Theme } from '../../../../theme/types';

import { Button, Heading, Tag } from '../../../../components';
import ColorPreview from './ColorPreview';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr 1fr 10% 8%;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTag = styled(Tag)`
  margin-left: 12px;
`;

const StyledButton = styled(Button)`
  width: 150px;
  height: 44px;
`;

interface Props {
  theme: Theme;
  type: string;
  isCurrent?: boolean;
  onSetTheme?: (isDark: boolean, themeId: string) => void;
}

const ThemeTileHeader: FC<Props> = ({
  theme,
  type,
  isCurrent = false,
  onSetTheme,
}) => {
  const handleButtonPress: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!onSetTheme) return;

    onSetTheme(theme.isDark, theme.id);
  };

  const handleResetPress: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    if (!onSetTheme) return;

    const themeId = theme.isDark
      ? DefaultThemeIds.ARION_DARK
      : DefaultThemeIds.ARION_LIGHT;

    onSetTheme(theme.isDark, themeId);
  };

  return (
    <Wrapper>
      <NameWrapper>
        <Heading variant="h3">{theme.themeName}</Heading>
        <StyledTag label={theme.isDark ? 'dark' : 'light'} />
      </NameWrapper>
      <Heading variant="h4">{type} theme</Heading>
      <ColorPreview theme={theme} />
      {isCurrent && !theme.isDefault ? (
        <StyledButton variant="outlined" onClick={handleResetPress}>
          Reset to Default
        </StyledButton>
      ) : null}
      {!isCurrent ? (
        <StyledButton size="medium" onClick={handleButtonPress}>
          Use as {theme.isDark ? 'Dark' : 'Light'}
        </StyledButton>
      ) : null}
      {/* Add button */}
    </Wrapper>
  );
};

export default ThemeTileHeader;
