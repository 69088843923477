import { MouseEventHandler, useState } from 'react';
import { Button, Tooltip } from '../../components';
import { getSurveysService } from '../../services';
import { convertSurveyResponsesToCsv } from '../../utils/csvUtils';
import { toast } from 'react-toastify';

interface Props {
  surveyId: string;
  numberOfResponses: number;
}

const ExportSurveyResponsesButton = ({
  surveyId,
  numberOfResponses,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleDownload: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();

    if (numberOfResponses === 0) {
      return;
    }

    setLoading(true);

    const surveyResponses = getSurveysService().getSurveyResponses(surveyId);

    await toast.promise(
      surveyResponses
        .then((responses) => {
          const responsesAsCsv = convertSurveyResponsesToCsv(responses);

          const csvContent =
            'data:text/csv;charset=utf-8,' +
            responsesAsCsv.map((r) => r.join(',')).join('\n');

          const csv = encodeURI(csvContent);

          const link = document.createElement('a');
          link.download = `survey-responses-${surveyId}.csv`;
          link.href = csv;

          link.click();

          return;
        })
        .finally(() => setLoading(false)),
      {
        pending: 'generating csv...',
        success: 'Successfully generated csv',
        error: 'Failed to generate csv',
      },
    );
  };

  return (
    <Tooltip
      title={numberOfResponses === 0 ? 'There are no responses to export' : ''}
    >
      <Button
        // manually setting the opacity since if there are no responses we want to
        // display the tooltip to indicate why the button is disabled and if we
        // use the disabled prop the tooltip will not be triggered since the
        // button has pointer-events: none when disabled
        style={{ opacity: numberOfResponses === 0 ? '0.3' : '' }}
        disabled={loading}
        onClick={handleDownload}
      >
        {loading ? 'Generating...' : 'Export'}
      </Button>
    </Tooltip>
  );
};

export default ExportSurveyResponsesButton;
