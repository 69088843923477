import { FC } from 'react';
import styled from 'styled-components';
import Text, { TextProps } from '../Text';

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;

const DialogText: FC<TextProps> = (props) => <StyledText {...props} />;

export default DialogText;
