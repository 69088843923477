import ForgotPassword from '../../views/Auth/ForgotPassword';
import Login from '../../views/Auth/Login';
import Onboarding from '../../views/Auth/Onboarding';
import OnboardingComplete from '../../views/Auth/OnboardingComplete';

export const unauthenticatedRoutes = {
  login: {
    key: 'login',
    label: 'Login',
    path: '/login',
    accessibleBy: [],
    component: Login,
  },
  forgotPassword: {
    key: 'forgot-password',
    label: 'Forgot password',
    path: '/forgot-password',
    accessibleBy: [],
    component: ForgotPassword,
  },
  onboarding: {
    key: 'onboarding',
    label: 'Onboarding',
    path: '/onboarding/account/:token',
    accessibleBy: [],
    component: Onboarding,
  },
  onboardingComplete: {
    key: 'onboarding-complete',
    label: 'Onboarding complete',
    path: '/onboarding/complete',
    accessibleBy: [],
    component: OnboardingComplete,
  },
};
