import { appRoutes } from './appRoutes';

export const navigationRoutes = {
  // advisors: appRoutes.advisors,
  dashboard: appRoutes.dashboard,
  themes: appRoutes.themes,
  groups: appRoutes.groups,
  stores: appRoutes.stores,
  accounts: appRoutes.accounts,
  prospects: appRoutes.prospects,
  surveys: appRoutes.surveys,
};
