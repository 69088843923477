import { CSSObject } from 'styled-components';
import colors from './colors';
import fonts from './fonts';
import { TypographyStylesType } from './types';

const h1: TypographyStylesType = {
  color: colors.text,
  fontFamily: fonts.primary,
  fontSize: 36, //34
  fontStyle: 'normal',
  fontWeight: '500', //'700'
  letterSpacing: 0,
  textTransform: 'none',
};

const h2: TypographyStylesType = {
  color: colors.text,
  fontFamily: fonts.primary,
  fontSize: 24,
  fontStyle: 'normal',
  fontWeight: '700', //'500'
  letterSpacing: 0,
  textTransform: 'none',
};

const h3: TypographyStylesType = {
  color: colors.text,
  fontFamily: fonts.primary,
  fontSize: 14, //16
  fontStyle: 'normal',
  fontWeight: '500',
  letterSpacing: 0,
  textTransform: 'none',
};

const h4: TypographyStylesType = {
  color: colors.text,
  fontFamily: fonts.primary,
  fontSize: 14, //16
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: 0,
  textTransform: 'none',
};

const body1: TypographyStylesType = {
  color: colors.text,
  fontFamily: fonts.secondary,
  fontSize: 14, //16
  fontStyle: 'normal',
  fontWeight: '600',
  letterSpacing: 0,
  textTransform: 'none',
};

const body2: TypographyStylesType = {
  color: colors.text,
  fontFamily: fonts.secondary,
  fontSize: 14, //16
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: 0,
  textTransform: 'none',
};

const body3: TypographyStylesType = {
  color: colors.text,
  fontFamily: fonts.secondary,
  fontSize: 12, //14
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: 0,
  textTransform: 'none',
};

const caption: TypographyStylesType = {
  color: colors.text,
  fontFamily: fonts.primary,
  fontSize: 13,
  fontStyle: 'normal',
  fontWeight: '500',
  letterSpacing: 0,
  textTransform: 'none',
};

const a: CSSObject = {
  color: colors.primary,
  fontFamily: fonts.secondary,
  cursor: 'pointer',
  fontStyle: 'normal',
  fontSize: 14, //16
  fontWeight: '600', //'400'
  letterSpacing: 0,
  textTransform: 'lowercase',
  '&:hover': {
    textDecoration: 'underline',
  },
};

const typography = {
  h1,
  h2,
  h3,
  h4,
  body1,
  body2,
  body3,
  caption,
  a,
};

export default typography;
