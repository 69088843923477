import { RootState } from '../types';

export const selectGroups = (state: RootState) => state.groups.groups.data;

export const selectGroupsFetching = (state: RootState) =>
  state.groups.groups.fetching;

export const selectGroup = (state: RootState) => state.groups.group.data;

export const selectGroupFetching = (state: RootState) =>
  state.groups.group.fetching;

export const selectGroupUpdating = (state: RootState) =>
  state.groups.group.updating;

export const selectGroupStores = (state: RootState) => state.groups.stores.data;

export const selectGroupStoresFetching = (state: RootState) =>
  state.groups.stores.fetching;

export const selectGroupsLoading = (state: RootState) =>
  state.groups.group.fetching || state.groups.stores.fetching;
