import { UserRoles } from '@atogear/arion-utils';

import About from '../../views/Stores/About/About';
import Activity from '../../views/Stores/Activity/Activity';
import Branding from '../../views/Stores/Branding/Branding';
import Leads from '../../views/Stores/Leads/Leads';
import Modules from '../../views/Stores/Modules/Modules';
import Payments from '../../views/Stores/Payments/Payments';
import Permissions from '../../views/Stores/Permissions/Permissions';
import Promotions from '../../views/Stores/Promotions/Promotions';
import Shoes from '../../views/Stores/Shoes/Shoes';
import Subscriptions from '../../views/Stores/Subscriptions/Subscriptions';
import Team from '../../views/Stores/Team/Team';

const storePath = `/stores/:storeId`;

export const storeRoutes = {
  about: {
    key: 'about',
    label: 'About',
    path: `${storePath}/about`,
    accessibleBy: [],
    component: About,
  },
  team: {
    key: 'team',
    label: 'Team',
    path: `${storePath}/team`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Team,
  },
  subscription: {
    key: 'subscriptions',
    label: 'Subscription',
    path: `${storePath}/subscription`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.DISTRIBUTOR,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Subscriptions,
  },
  payments: {
    key: 'payments',
    label: 'Payments',
    path: `${storePath}/payments`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Payments,
  },
  activity: {
    key: 'activity',
    label: 'Recent activity',
    path: `${storePath}/activity`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Activity,
  },
  leads: {
    key: 'leads',
    label: 'Lead activations',
    path: `${storePath}/leads`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Leads,
  },
  branding: {
    key: 'branding',
    label: 'Branding',
    path: `${storePath}/branding`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.INTERNAL_DESIGNER,
      UserRoles.BACK_OFFICE,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Branding,
  },
  promo: {
    key: 'promo',
    label: 'Promotions',
    path: `${storePath}/promo`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.INTERNAL_DESIGNER,
      UserRoles.BACK_OFFICE,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Promotions,
  },
  shoes: {
    key: 'shoes',
    label: 'Upload shoes',
    path: `${storePath}/shoes`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Shoes,
  },
  modules: {
    key: 'modules',
    label: 'Modules',
    path: `${storePath}/modules`,
    accessibleBy: [UserRoles.DATA_VIEWER, UserRoles.BACK_OFFICE],
    component: Modules,
  },
  permissions: {
    key: 'permissions',
    label: 'Permissions',
    path: `${storePath}/permissions`,
    accessibleBy: [UserRoles.DATA_VIEWER, UserRoles.BACK_OFFICE],
    component: Permissions,
  },
};
