import { FC } from 'react';
import SwitchUnstyled, {
  switchUnstyledClasses,
  SwitchUnstyledProps,
} from '@mui/base/SwitchUnstyled';
import styled from 'styled-components';

const StyledSwitch = styled(SwitchUnstyled)`
  position: relative;
  cursor: pointer;
  display: inline-block;
  height: 14px;
  width: 26px;

  &.${switchUnstyledClasses.disabled} {
    cursor: not-allowed;
    opacity: 0.5;
  }

  & .${switchUnstyledClasses.track} {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.textTwo};
    border-radius: 10px;
    height: 100%;
    width: 100%;
    opacity: 1;
  }

  & .${switchUnstyledClasses.thumb} {
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 50%;
    display: block;
    top: 2px;
    left: 2px;
    height: 10px;
    width: 10px;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.1);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.track} {
      background: ${({ theme }) => theme.colors.primary};
    }

    .${switchUnstyledClasses.thumb} {
      left: 14px;
      background-color: ${({ theme }) => theme.colors.surfaceTwo};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
  }
`;

interface Props extends SwitchUnstyledProps {}

const Switch: FC<Props> = (props) => <StyledSwitch {...props} />;

export default Switch;
