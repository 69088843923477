import { parse, Parser, ParseResult, ParseStepResult } from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import { SurveyResponse } from '@atogear/arion-utils';

import { serialize, UploadedShoe } from '../models/shoeModel';

interface InternalError {
  name?: string;
  message: string;
  stack?: string;
}
export interface ParserError {
  message: string;
  data: any;
  error?: InternalError;
  line: number;
}

interface ParserResultStats {
  total: number;
  parsed: number;
  skipped: number;
}
export interface ParserResult {
  json: UploadedShoe[];
  stats: ParserResultStats;
}

const parsers: any = {};

let currentIndex: number = 0;

const getIndex = () => currentIndex;

const incIndex = () => currentIndex++;

let numberOfErrors: number = 0;

const getNumberOfErrors = () => numberOfErrors;

const incNumberOfErrors = () => numberOfErrors++;

const chunkSize = 200;

const addParser = (id: string, parser: Parser) => {
  if (!parsers[id]) {
    parsers[id] = parser;
  }
};

const abortAllParsers = () => {
  Object.keys(parsers).forEach((parserKey: string) => {
    parsers[parserKey].abort();

    delete parsers[parserKey];
  });
};

export const readFile = async (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.readAsText(file, 'UTF-8');

      reader.onload = (e) => {
        if (!e.target || typeof e.target.result !== 'string') {
          return resolve('');
        }

        const content = e.target.result.replace('EAN', 'ean');

        return resolve(content);
      };
    } catch (error) {
      return reject(error);
    }
  });
};

const prepareData = (text: string) => {
  const rows = text.trim().split('\n');

  const columnNames = rows[0];

  const chunks = [];

  // remove the header
  rows.shift();

  const numberOfRows = rows.length;

  for (let i = 0; i < rows.length; i += chunkSize) {
    const chunk = rows.slice(i, i + chunkSize);
    // do whatever
    chunks.push([columnNames, ...chunk].join('\n'));
  }

  return {
    numberOfRows,
    chunks,
    text,
  };
};

export const parseShoeCsvToJson = (
  text: string,
  stepCallback: (
    row: ParseStepResult<Partial<UploadedShoe>>,
    cancel: () => void,
    stats: {
      total: number;
      current: number;
      progress: number;
    },
  ) => void,
  errorCallback: (error: ParserError) => void,
): Promise<ParserResult> => {
  return new Promise(async (resolve, reject) => {
    try {
      currentIndex = 0;

      numberOfErrors = 0;

      const { numberOfRows, chunks } = prepareData(text);

      const results = await Promise.all(
        chunks.map((chunk, index) =>
          praseAsPromise(
            chunk,
            index,
            numberOfRows,
            stepCallback,
            errorCallback,
          ),
        ),
      );

      abortAllParsers();

      resolve({
        json: (flattenArray(results) as UploadedShoe[]) || [],
        stats: {
          total: numberOfRows,
          parsed: getIndex() - getNumberOfErrors(),
          skipped: getNumberOfErrors(),
        },
      });
    } catch (error: any) {
      return reject(error);
    }
  });
};

const praseAsPromise = (
  text: string,
  chunkIndex: number,
  numberOfRows: number,
  stepCallback: (
    row: ParseStepResult<Partial<UploadedShoe>>,
    cancel: () => void,
    stats: {
      total: number;
      current: number;
      progress: number;
    },
  ) => void,
  errorCallback: (error: ParserError) => void,
): Promise<UploadedShoe[]> => {
  return new Promise((resolve, reject) => {
    try {
      // needs the json array because if using steps callback the parser
      // does not return anything on complete
      const json: UploadedShoe[] = [];

      const id = uuidv4();

      // 2 because we increment at the end and because the first line(0 index) is always the header
      let line = 2;

      parse(text, {
        // worker: true,
        header: true,
        skipEmptyLines: true,
        transformHeader: (header: string) => header.toLowerCase(),
        beforeFirstChunk: (chunk: string) => {
          const firstRow = chunk.split('\n')[0].toLowerCase().trim().split(',');

          if (firstRow.length !== 3) {
            reject({
              message: 'CSV needs to have exactly 3 columns: Brand,Model,EAN',
            });
          } else if (firstRow[0] !== 'brand') {
            reject({
              message: 'CSV first column needs to be "brand" column.',
            });
          } else if (firstRow[1] !== 'model') {
            reject({
              message: 'CSV second column needs to be "model" column.',
            });
          } else if (firstRow[2] !== 'ean') {
            reject({
              message: 'CSV third column needs to be "EAN" column.',
            });
          }
        },
        // eslint-disable-next-line no-loop-func
        step: (row: ParseStepResult<Partial<UploadedShoe>>, parser: Parser) => {
          parser.pause();

          addParser(id, parser);

          const rowData = row.data;

          let shouldAddRow = true;

          rowData.ean = rowData.ean?.replace('\r', '').replace('\n', '');

          if (
            row.errors.length === 0 &&
            (typeof rowData.ean !== 'string' ||
              rowData.ean.length < 7 ||
              rowData.ean.length > 14)
          ) {
            // if we abort and then reject the promise, for some reason the reject does not get executed
            errorCallback({
              message:
                'Wrong EAN format. EAN should be a number, at least 7 digit long (EAN 8) and at most 13 digit long (EAN 13).',
              data: rowData,
              line: line + chunkIndex * chunkSize,
            });

            shouldAddRow = false;

            incNumberOfErrors();
          }

          if (row.errors.length > 0) {
            // if we abort and then reject the promise, for some reason the reject does not get executed
            errorCallback({
              message: row.errors[0].message,
              data: rowData,
              error: row.errors[0],
              line: line + chunkIndex * chunkSize,
            });

            shouldAddRow = false;

            incNumberOfErrors();
          }

          if (shouldAddRow) {
            const uploadedShoe = serialize(rowData);
            json.push(uploadedShoe);

            incIndex();
          }

          stepCallback(row, () => abortAllParsers(), {
            total: numberOfRows,
            current: getIndex(),
            progress: ((getIndex() + getNumberOfErrors()) / numberOfRows) * 100,
          });

          line++;

          parser.resume();
        },
        error: (error: Error) => {
          // executed if an error occurs while loading the file,
          // or if before callback aborted for some reason
          reject(error);
        },
        complete: (results: ParseResult<UploadedShoe>) => {
          if (results.errors.length > 0) {
            return reject(results.errors[0]);
          }

          resolve(json);
        },
      });
    } catch (error: any) {
      const errorMessage =
        typeof error === 'object' && 'error' in error
          ? error.error.message
          : error.toString();

      return reject(errorMessage);
    }
  });
};

const flattenArray = (arr: Array<any>): Array<any> => {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten,
    );
  }, []);
};

export const convertSurveyResponsesToCsv = (
  surveyResponses: SurveyResponse[],
) => {
  const headers = [
    'Store ID',
    'Store Name',
    'Country',
    'NPS Score',
    'Customer Service Score',
    'Notes',
  ];
  const responses = surveyResponses.map((r) => [
    r.storeId,
    r.storeName,
    r.storeCountry,
    r.results.recommended,
    r.results.customerService,
    r.results.notes,
  ]);
  return [headers, ...responses];
};
