import { FC } from 'react';
import styled from 'styled-components';

import { Theme } from '../../../../theme/types';

import { MediaPreview, MediaTile } from '../Components';

interface VideoProps {
  $background?: string;
}

const Video = styled.video<VideoProps>`
  background-color: ${({ $background }) => $background};
  border-radius: 4px;
`;

interface Props {
  lightTheme?: Theme;
  darkTheme?: Theme;
}

const KioskSection: FC<Props> = ({ lightTheme, darkTheme }) => (
  <MediaTile>
    <MediaPreview
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      mediaKey="kioskVideoURL"
      title="Kiosk video"
      description="The preview video show on the home screen of your Kiosk."
      component={Video}
      componentProps={{
        $background: lightTheme?.colors.background,
        controls: true,
        loop: true,
        muted: true,
        preload: 'metadata',
        height: 300,
        width: 538,
      }}
    />
  </MediaTile>
);

export default KioskSection;
