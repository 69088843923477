import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { getId, Promotion } from '../../models/promotionModel';
import {
  merge,
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';
import { reset } from './actions';
import {
  addPromotions,
  copyPromotions,
  getDefaultPromotion,
  getPromotions,
  removePromotion,
  setDefaultPromotion,
  updatePromotion,
} from './thunks';

interface State {
  promotions: {
    data?: Promotion[];
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  default: {
    data?: Promotion;
    fetching: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  promotions: {
    data: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
  default: {
    data: undefined,
    fetching: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Promotions
    .addCase(getPromotions.pending, setFetchingState('promotions'))
    .addCase(getPromotions.fulfilled, (state, { payload }) => {
      state.promotions.data = payload;
      setFulfilledState('promotions')(state);
    })
    .addCase(getPromotions.rejected, setRejectedState('promotions'))
    // Get default Promotion
    .addCase(getDefaultPromotion.pending, setFetchingState('default'))
    .addCase(getDefaultPromotion.fulfilled, (state, { payload }) => {
      state.default.data = payload;
      setFulfilledState('default')(state);
    })
    .addCase(getDefaultPromotion.rejected, setRejectedState('default'))
    // Add promotion
    .addCase(addPromotions.pending, setUpdatingState('promotions'))
    .addCase(addPromotions.fulfilled, (state, { payload }) => {
      if (state.promotions.data) {
        state.promotions.data.push(payload);
      } else {
        state.promotions.data = [payload];
      }
      setFulfilledState('promotions')(state);
    })
    // Copy promotion
    .addCase(copyPromotions.pending, setUpdatingState('promotions'))
    .addCase(copyPromotions.fulfilled, (state, { payload }) => {
      if (state.promotions.data) {
        state.promotions.data.push(payload);
      } else {
        state.promotions.data = [payload];
      }
      setFulfilledState('promotions')(state);
    })
    .addCase(copyPromotions.rejected, setRejectedState('promotions'))
    // Remove promotion
    .addCase(removePromotion.pending, setUpdatingState('promotions'))
    .addCase(removePromotion.fulfilled, (state, { payload }) => {
      if (state.promotions.data) {
        const newItems = [...state.promotions.data]
          .map((p) => p)
          .filter((p) => {
            return getId(p) !== getId(payload);
          });

        state.promotions.data = newItems;
      }

      setFulfilledState('promotions')(state);
    })
    .addCase(removePromotion.rejected, setRejectedState('promotions'))
    // Set Default Promotion
    .addCase(setDefaultPromotion.pending, setUpdatingState('promotions'))
    .addCase(setDefaultPromotion.fulfilled, (state, { payload }) => {
      state.promotions.data = merge(state.promotions.data, payload);
      setFulfilledState('promotions')(state);
    })
    .addCase(setDefaultPromotion.rejected, setRejectedState('promotions'))
    // Update Promotion
    .addCase(updatePromotion.pending, setUpdatingState('promotions'))
    .addCase(updatePromotion.fulfilled, (state, { payload }) => {
      state.promotions.data = merge(state.promotions.data, payload);
      setFulfilledState('promotions')(state);
    })
    .addCase(updatePromotion.rejected, setRejectedState('promotions'))
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
