import { FC } from 'react';
import styled from 'styled-components';
import { Heading, MouseTooltip } from '../../../../../components';
import { ButtonStyleType } from '../../../../../theme/types';

const Wrapper = styled.div`
  width: 250px;
  height: 48px;
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Name = styled(Heading)``;

const Value = styled.div`
  ${(props) => props.theme.typography.body3};
  width: calc(100% - 48px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: not-allowed;
`;

interface ColorProps {
  buttonStyles: ButtonStyleType;
  fallbackColor: string;
  $font: string;
}

const Color = styled.div<ColorProps>`
  font-style: ${(props) => props.$font};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid ${(props) => props.theme.colors.grayOpaque};
  border-radius: 4px;
  background-color: ${(props) => props.fallbackColor};
  ${(props) => props.buttonStyles};
  margin-right: 4px;
`;

interface Props {
  name: string;
  font: string;
  fallbackColor: string;
  buttonStyles: ButtonStyleType;
}

const ComponentButton: FC<Props> = ({
  name,
  fallbackColor,
  font,
  buttonStyles,
}) => {
  return (
    <MouseTooltip tooltip="Preview only">
      <Wrapper>
        <Color
          $font={font}
          buttonStyles={buttonStyles}
          fallbackColor={fallbackColor}
        >
          Aa
        </Color>
        <TextWrapper>
          <Name variant="h3">{name}</Name>
          <Value>{buttonStyles.color || fallbackColor}</Value>
        </TextWrapper>
      </Wrapper>
    </MouseTooltip>
  );
};

export default ComponentButton;
