import { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as Logo } from '../../assets/images/logo-loading.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const StyledLogo = styled(Logo)`
  height: 100px;
  width: 100px;
`;

const SplashScreen: FC = () => (
  <Wrapper>
    <StyledLogo />
  </Wrapper>
);

export default SplashScreen;
