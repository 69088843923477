import { Injuries } from '../models/apiSessionModel';
import { ApiVisit, hasHealthData } from '../models/apiVisitModel';

export const sumVisitToInjuries = (prev: Injuries, current: ApiVisit) => {
  const { injuries } = current;
  // If there is no health data then there is no need to do anything with the visit
  if (!hasHealthData(current) || prev.notInjured === undefined) return prev;

  if (injuries.unknown > 0)
    return {
      ...prev,
      notInjured: prev.notInjured + 1,
    };

  const numberOfInjuries = Object.values(injuries).reduce((a, b) => a + b, 0);

  if (numberOfInjuries === 0) {
    return {
      ...prev,
      notInjured: prev.notInjured + 1,
    };
  }

  return {
    lowerBack: prev.lowerBack + current.injuries.lowerBack,
    leftUpperLeg: prev.leftUpperLeg + current.injuries.leftUpperLeg,
    rightUpperLeg: prev.rightUpperLeg + current.injuries.rightUpperLeg,
    leftKnee: prev.leftKnee + current.injuries.leftKnee,
    rightKnee: prev.rightKnee + current.injuries.rightKnee,
    leftLowerLeg: prev.leftLowerLeg + current.injuries.leftLowerLeg,
    rightLowerLeg: prev.rightLowerLeg + current.injuries.rightLowerLeg,
    leftAnkle: prev.leftAnkle + current.injuries.leftAnkle,
    rightAnkle: prev.rightAnkle + current.injuries.rightAnkle,
    leftAchilles: prev.leftAchilles + current.injuries.leftAchilles,
    rightAchilles: prev.rightAchilles + current.injuries.rightAchilles,
    leftFoot: prev.leftFoot + current.injuries.leftFoot,
    rightFoot: prev.rightFoot + current.injuries.rightFoot,
    notInjured: prev.notInjured,
  };
};

export const formatInjuries = (data: Injuries) => [
  {
    category: 'upper leg',
    left: data.leftUpperLeg,
    right: data.rightUpperLeg,
  },
  {
    category: 'knee',
    left: data.leftKnee,
    right: data.rightKnee,
  },
  {
    category: 'lower leg',
    left: data.leftLowerLeg,
    right: data.rightLowerLeg,
  },
  {
    category: 'ankle',
    left: data.leftAnkle,
    right: data.rightAnkle,
  },
  {
    category: 'achilles',
    left: data.leftAchilles,
    right: data.rightAchilles,
  },
  {
    category: 'foot',
    left: data.leftFoot,
    right: data.rightFoot,
  },
  {
    category: 'lower back',
    other: data.lowerBack,
  },
];

export const defaultInjuries: Injuries = {
  lowerBack: 0,
  leftUpperLeg: 0,
  rightUpperLeg: 0,
  leftKnee: 0,
  rightKnee: 0,
  leftLowerLeg: 0,
  rightLowerLeg: 0,
  leftAnkle: 0,
  rightAnkle: 0,
  leftAchilles: 0,
  rightAchilles: 0,
  leftFoot: 0,
  rightFoot: 0,
  notInjured: 0,
};
