import { ChangeEventHandler, FC, useEffect, useId, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { startOfDay } from 'date-fns';
import { apply, isNilOrWhitespace } from '@atogear/arion-utils';

import { getLanguageCountryCode } from '../../../../utils/i18nUtils';

import {
  Button,
  Checkbox,
  DatePicker,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  FlagIcon,
  Icon,
  Option,
} from '../../../../components';
import InformationIcon from '../../../../components/InformationIcon';

import { ExtraProspectData } from '../../types';
import { prospectModel } from '../../../../models';

import { Languages } from '../../../../enums';
import { Prospect } from '../../../../models/prospectModel';

const LANGUAGE_OPTIONS = Object.keys(Languages).map((language) => ({
  adornment: <FlagIcon country={getLanguageCountryCode(language)} />,
  key: language,
  label: language,
  value: language,
}));

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledRow = styled(Row)`
  margin-top: 6px;
  margin-bottom: 7px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledWrapper = styled(Wrapper)`
  margin-left: 32px;
`;

interface FieldProps {
  $last?: boolean;
  $direction?: 'column' | 'row';
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'row'};
  ${({ $last }) => `margin-${$last ? 'top' : 'bottom'}: 16px;`};
`;

interface ColumnProps {
  $right?: boolean;
  $quantity?: boolean;
}

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  ${({ $right }) => (!$right ? 'width: 32px' : 'flex: 1')};
  ${({ $right }) => `margin-${$right ? 'right' : 'left'}: 4px`};
  ${({ $quantity }) => ($quantity ? 'width: 60px' : '')};
`;

const CapitalizedDialogSelect = styled(DialogSelect)`
  input,
  li {
    text-transform: capitalize;
  }
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const StyledInfoIcon = styled(InformationIcon)`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 6px;
`;

const StyledButton = styled(Button)`
  height: 30px;
`;

const StyledCheckboxLabel = styled(DialogInputLabel)`
  cursor: pointer;
  margin-left: 6px;
`;

interface Props {
  prospect: Prospect;
  onChange: (value: ExtraProspectData) => void;
}

const Extra: FC<Props> = ({ prospect, onChange }) => {
  const [priority, setPriority] = useState(false);
  const [expectedCloseDate, setExpectedCloseDate] = useState<number | null>(
    null,
  );
  const [preferredLanguage, setPreferredLanguage] = useState('');
  const [hardwareSetQuantity, setHardwareSetQuantity] = useState(0);
  const [extras, setExtras] = useState('');

  useEffect(() => {
    setPriority(prospectModel.hasPriority(prospect));
    setExpectedCloseDate(
      apply(prospectModel.getExpectedCloseDate, prospect, null),
    );
    setPreferredLanguage(prospectModel.getPreferredLanguage(prospect));
    setHardwareSetQuantity(
      apply(prospectModel.getHardwareSetQuantity, prospect, 0),
    );
    setExtras(apply(prospectModel.getExtras, prospect, ''));
  }, [prospect]);

  const handlePriorityChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked } = e.target;

    setPriority(checked);

    onChange({
      priority: checked,
    });
  };

  const handleExpectedCloseDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    const value = startOfDay(date).getTime();

    setExpectedCloseDate(value);

    onChange({
      expectedCloseDate: value,
    });
  };

  const handleHardwareSetQuantityChange: ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const value = Number.parseFloat(e.target.value);
    setHardwareSetQuantity(Math.max(value, 0));

    onChange({
      hardwareSetQuantity: value,
    });
  };

  const handleExpectedCloseDateDelete = () => {
    setExpectedCloseDate(null);

    onChange({
      expectedCloseDate: null,
    });
  };

  const handleCountryChange = (option: Option | null) => {
    const value = option?.value || preferredLanguage;

    setPreferredLanguage(value);

    // NOTE: Not debounced since it is selected from dropdown
    onChange({
      preferredLanguage: value,
    });
  };

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleExtrasChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setExtras(value);

    debouncedOnChange({
      extras: value,
    });
  };

  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const id = useId();
  const priorityId = `${id}-priority`;
  const expectedCloseDateId = `${id}-expectedCloseDate`;
  const langId = `${id}-language`;
  const quantityId = `${id}-quantity`;
  const extrasId = `${id}-extras`;

  return (
    <>
      <Row>
        <Wrapper>
          <Field $direction="column">
            <StyledInputLabel variant="h3">
              Expected Close Date
            </StyledInputLabel>
            <Row>
              <Column $right>
                <DatePicker
                  customInput={<DialogInput />}
                  id={expectedCloseDateId}
                  selected={
                    expectedCloseDate ? new Date(expectedCloseDate) : null
                  }
                  onChange={handleExpectedCloseDateChange}
                />
              </Column>
              <Column>
                <StyledButton
                  type="button"
                  size="small"
                  onClick={handleExpectedCloseDateDelete}
                >
                  <Icon name="delete" />
                </StyledButton>
              </Column>
            </Row>
          </Field>
          <Field $direction="column">
            <Row>
              <StyledInputLabel variant="h3">
                Preferred language
              </StyledInputLabel>
              <StyledInfoIcon tooltip="Will be applied as default language to the HUB app, email letters and digital flyer." />
            </Row>
            <CapitalizedDialogSelect
              id={langId}
              options={LANGUAGE_OPTIONS}
              startAdornment={
                <FlagIcon country={getLanguageCountryCode(preferredLanguage)} />
              }
              value={preferredLanguage}
              onChange={handleCountryChange}
            />
          </Field>
        </Wrapper>
        <StyledWrapper>
          <Field $direction="column">
            <StyledInputLabel variant="h3">Priority</StyledInputLabel>
            <StyledRow>
              <Checkbox
                id={priorityId}
                checked={priority}
                onChange={handlePriorityChange}
              />
              <StyledCheckboxLabel htmlFor={priorityId}>
                {priority ? 'Yes' : 'No'}
              </StyledCheckboxLabel>
            </StyledRow>
          </Field>
          <Field $direction="column">
            <StyledInputLabel variant="h3">
              Hardware Set Quantity
            </StyledInputLabel>
            <Column $quantity>
              <DialogInput
                id={quantityId}
                type="number"
                min="1"
                value={hardwareSetQuantity}
                onChange={handleHardwareSetQuantityChange}
              />
            </Column>
          </Field>
        </StyledWrapper>
      </Row>
      <Row>
        <Wrapper>
          <StyledInputLabel htmlFor="name">Extras</StyledInputLabel>
          <DialogInput
            id={extrasId}
            placeholder="Extras"
            value={extras}
            validate={handleValidate}
            onChange={handleExtrasChange}
          />
        </Wrapper>
      </Row>
    </>
  );
};

export default Extra;
