import { getFirestorePipe } from '@atogear/ato-broker';

import { fields, Invite, serialize } from '../models/inviteModel';

const COLL_PATH = 'invitations';

const getInviteService = () => {
  /**
   * Get Store Invites.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Store Invites.
   */
  const getInvites = async (storeId: string): Promise<Invite[]> => {
    const invites = await getFirestorePipe().getDocuments(COLL_PATH, {
      where: {
        fieldName: fields.STORE_ID,
        operator: '==',
        fieldValue: storeId,
      },
    });

    return (invites as Invite[]).map(serialize);
  };

  return {
    getInvites,
  };
};

export default getInviteService;
