import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { isNilOrWhitespace, Store, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import { DialogInput, DialogInputLabel } from '../../../../components';

import { GeneralStoreData } from '../../types';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 32px;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

interface Props {
  store: Store;
  onChange: (value: GeneralStoreData) => void;
}

const General: FC<Props> = ({ store, onChange }) => {
  const [name, setName] = useState(storeModel.getName(store));

  useEffect(() => {
    setName(storeModel.getName(store));
  }, [store]);

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setName(value);

    debouncedOnChange({
      storeName: value,
    });
  };

  // TODO: Validate on more than just empty strings
  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  return (
    <Wrapper>
      <StyledInputLabel htmlFor="name">Name</StyledInputLabel>
      <DialogInput
        id="name"
        placeholder="Name"
        value={name}
        validate={handleValidate}
        onChange={handleChange}
      />
    </Wrapper>
  );
};

export default General;
