import { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apply, userModel } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { AuthActions } from '../../../store/actions';
import { AuthSelectors } from '../../../store/selectors';

import { unauthenticatedRoutes, userRoutes } from '../../../routing';

import { alphaToHex } from '../../../utils/colorUtils';

import { Avatar, Initials, Popper } from '../../../components';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 64px;

  @media (max-width: 1400px) {
    margin-right: 24px;
    margin-left: 24px;
  }
`;

const Menu = styled.ul`
  list-style: none;
  outline: none;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  padding: 8px 0px;
`;

const MenuItem = styled.li`
  ${({ theme }) => theme.typography.body2};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  transition: background-color ease 0.2s;

  &:hover,
  &:focus,
  &[aria-selected='true'] {
    background-color: ${({ theme }) =>
      theme.colors.secondary + alphaToHex(0.3)};
  }
`;

const UserMenu: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);
  const isBackOffice = apply(userModel.isBackOffice, user, false);

  const canViewReminders = isAdmin || isSales || isBackOffice;

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const anchorEl = useRef<HTMLDivElement | null>(null);

  const toggleOpen = () => setOpen((prev) => !prev);

  const handleClose = () => setOpen(false);

  const handleUserSettings = () => {
    handleClose();

    history.push(userRoutes.settings.path);
  };

  const handleUserReminders = () => {
    handleClose();

    history.push(userRoutes.reminders.path);
  };

  const handleSignOut = async () => {
    try {
      await dispatch(AuthActions.signOut()).unwrap();

      handleClose();

      history.push(unauthenticatedRoutes.login.path);
    } catch (error) {
      toast((error as Error)?.message || 'Failed to sign out!', {
        type: 'error',
      });
    }
  };

  const initials = apply(userModel.getInitials, user, '');

  return (
    <Wrapper onClick={toggleOpen}>
      <Avatar ref={anchorEl}>
        <Initials>{initials}</Initials>
      </Avatar>
      <Popper anchorEl={anchorEl.current} open={open} onDismiss={handleClose}>
        <Menu>
          <MenuItem onClick={handleUserSettings}>Settings</MenuItem>
          {canViewReminders && (
            <MenuItem onClick={handleUserReminders}>Reminders</MenuItem>
          )}
          <MenuItem onClick={handleSignOut}>Log out</MenuItem>
        </Menu>
      </Popper>
    </Wrapper>
  );
};

export default UserMenu;
