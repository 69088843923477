import { formatFullName, formatInitials } from '@atogear/arion-utils';
import { mergeRight } from 'ramda';
import { ATOContactRoles } from '../enums';
import { translatedATOContactRoles } from '../translations';

export interface ATOContact {
  firstName: string;
  lastName: string;
  role: ATOContactRoles;
  email: string;
  phoneNumber: string;
}

export const fields = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ROLE: 'role',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
};

export const defaults: ATOContact = {
  firstName: '',
  lastName: '',
  role: ATOContactRoles.SALES,
  email: '',
  phoneNumber: '',
};

// User Info
export const getFirstName = (contact?: ATOContact) =>
  (contact || defaults).firstName;

export const getLastName = (contact?: ATOContact) =>
  (contact || defaults).lastName;

export const getFullName = (contact?: ATOContact) =>
  formatFullName(getFirstName(contact), getLastName(contact));

export const getInitials = (contact?: ATOContact) =>
  formatInitials(getFirstName(contact), getLastName(contact));

export const getEmail = (contact?: ATOContact) => (contact || defaults).email;

export const getPhoneNumber = (contact?: ATOContact) =>
  (contact || defaults).phoneNumber;

// Role
export const getRole = (contact?: ATOContact) => (contact || defaults).role;

export const getFormattedRole = (user?: ATOContact) =>
  translatedATOContactRoles[getRole(user)];

export const isSales = (contact?: ATOContact) =>
  getRole(contact) === ATOContactRoles.SALES;

// Utils
export const create = (contact?: ATOContact): ATOContact =>
  mergeRight(defaults, contact || defaults);
