import { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as Image } from '../assets/images/powered-by-atogear.svg';

type Placement = 'start' | 'middle' | 'end';

interface WrapperProps {
  $placement: Placement;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  bottom: 0;
  width: calc(100vw - 64px);
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: ${({ $placement }) => {
    switch ($placement) {
      case 'middle':
        return 'center';
      case 'end':
        return 'flex-end';
      case 'start':
      default:
        return 'flex-start';
    }
  }};
  margin: 0 32px 24px;
`;

interface ImageProps {
  $dark: boolean;
}

const StyledImage = styled(Image)<ImageProps>`
  & > path {
    fill: ${({ $dark, theme }) => ($dark ? '#fff' : theme.colors.grayOpaque)};
  }
`;

interface Props {
  placement?: Placement;
  dark?: boolean;
}

const Footer: FC<Props> = ({ placement = 'start', dark = false }) => (
  <Wrapper $placement={placement}>
    <StyledImage $dark={dark} />
  </Wrapper>
);

export default Footer;
