import { useCallback, useState } from 'react';

type useToggleType = (defaultValue: boolean) => [boolean, () => void];

/**
 * A hook to use alongside boolean values that are to be toggled on or off
 * @example
 const [selected, toggleSelected] = useToggle(false) // Initialise selected at false
 toggleSelected() // If selected used to be false, it is switched to true
 */
const useToggle: useToggleType = (defaultValue) => {
  const [value, setValue] = useState(defaultValue);

  const toggleValue = useCallback(() => setValue((prev) => !prev), []);

  return [value, toggleValue];
};

export default useToggle;
