import { AnchorHTMLAttributes, FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  ${({ theme }) => theme.typography.a};
`;

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {}

const Link: FC<Props> = ({ children, ...rest }) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) =>
    e.stopPropagation();

  return (
    <Wrapper
      rel="noopener noreferrer"
      target="_blank"
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default Link;
