import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apply, userModel } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import {
  AuthSelectors,
  ContactSelectors,
  ProspectSelectors,
} from '../../../store/selectors';
import { ContactActions, ProspectActions } from '../../../store/actions';

import { getLanguageCountryCode } from '../../../utils/i18nUtils';

import {
  Button,
  ContactChip,
  ContactDialog,
  FlagIcon,
  Heading,
  Icon,
  Link,
  Text,
} from '../../../components';
import Notes from './Notes';
import ProspectDialog from './ProspectDialog/ProspectDialog';

import { ProspectRouteParams } from '../types';
import { prospectModel } from '../../../models';
import {
  Contact,
  defaults,
  getId as getContactId,
} from '../../../models/contactModel';

import {
  translatedGroupType,
  translatedProduct,
  translatedProspectStatus,
} from '../../../translations';

import { ProspectStatus } from '../../../enums';
import { Prospect } from '../../../models/prospectModel';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const EditIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 17px;
  margin-right: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  padding: 0 40px;
  margin-top: 2px;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  flex: 2;
`;

interface ValueProps {
  scrollable?: boolean;
}

const Value = styled.div<ValueProps>`
  display: flex;
  flex: 3;
  flex-direction: row;
  overflow-x: ${(props) => (props.scrollable ? 'auto' : 'unset')};
`;

const TypeText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
`;

const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`;

const PhoneNumber = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const StyledFlagIcon = styled(FlagIcon)`
  margin-right: 8px;
`;

const AddContact = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px 0 8px;
  border-radius: 24px;
  transition: background-color ease 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
  }
`;

const AddContactIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  margin-right: 8px;
`;

const AddContactText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
`;

const About: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const prospect = useSelector(ProspectSelectors.selectProspect);
  const contacts = useSelector(ContactSelectors.selectContacts);
  const updatingContact = useSelector(ContactSelectors.selectContactUpdating);

  const { prospectId } = useParams<ProspectRouteParams>();

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);

  const name = apply(prospectModel.getName, prospect, '');
  const type = apply(
    prospectModel.getType,
    prospect,
    prospectModel.getType(prospectModel.defaults),
  );
  const products = apply(
    prospectModel.getProducts,
    prospect,
    prospectModel.getProducts(prospectModel.defaults),
  );
  const priority = apply(
    prospectModel.hasPriority,
    prospect,
    prospectModel.hasPriority(prospectModel.defaults),
  );
  const status = apply(
    prospectModel.getStatus,
    prospect,
    prospectModel.getStatus(prospectModel.defaults),
  );
  const expectedCloseDate = apply(
    prospectModel.getFormattedExpectedCloseDate,
    prospect,
    prospectModel.getFormattedExpectedCloseDate(prospectModel.defaults),
  );
  const address = apply(prospectModel.getAddress, prospect, '');
  const language = apply(
    prospectModel.getPreferredLanguage,
    prospect,
    prospectModel.getPreferredLanguage(prospectModel.defaults),
  );
  const email = apply(prospectModel.getEmail, prospect, '');
  const callingCode = apply(prospectModel.getCallingCode, prospect, '');
  const phoneNumber = apply(
    prospectModel.getFormattedPhoneNumber,
    prospect,
    '',
  );
  const websiteUrl = apply(prospectModel.getFormattedWebsite, prospect, '');
  const website = apply(prospectModel.getWebsite, prospect, '');
  const hardwareSetQuantity = apply(
    prospectModel.getHardwareSetQuantity,
    prospect,
    0,
  );
  const extras = apply(prospectModel.getExtras, prospect, '');

  const [prospectDialogOpen, setProspectDialogOpen] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const [contact, setContact] = useState<Contact | undefined>(undefined);

  const getProspectContacts = useCallback(() => {
    if (prospectId) {
      dispatch(
        ContactActions.getContacts({
          parentId: prospectId,
          options: { isProspect: true },
        }),
      );
    }
  }, [dispatch, prospectId]);

  useEffect(() => {
    getProspectContacts();
  }, [getProspectContacts]);

  const openProspectDialog = () => setProspectDialogOpen(true);

  const closeProspectDialog = () => setProspectDialogOpen(false);

  const openContactDialog = () => setContactDialogOpen(true);

  const closeContactDialog = () => setContactDialogOpen(false);

  const handleProspectConfirm = async (data: Partial<Prospect>) => {
    try {
      if (!prospectId) {
        return;
      }
      await dispatch(
        ProspectActions.updateProspect({
          prospectId,
          data,
        }),
      ).unwrap();
      closeProspectDialog();
      toast('Prospect updated successfully!', {
        type: 'success',
      });
    } catch (error) {
      toast('Failed to update Prospect!', {
        type: 'error',
      });
    }
  };

  const handleContactAdd = () => {
    setContact(defaults);

    openContactDialog();
  };

  const handleContactEdit = (_: number, contact: Contact) => {
    setContact(contact);

    openContactDialog();
  };

  const handleContactDismiss = () => {
    setContact(undefined);

    closeContactDialog();
  };

  const refreshContacts = () => {
    handleContactDismiss();

    getProspectContacts();
  };

  const handleContactDelete = async (_: number, contact: Contact) => {
    try {
      const contactId = getContactId(contact);

      if (!prospectId || !contactId) {
        return;
      }

      await dispatch(
        ContactActions.deleteContact({
          parentId: prospectId,
          contactId,
          options: { isProspect: true },
        }),
      ).unwrap();

      refreshContacts();

      toast('Successfully deleted contact!', {
        type: 'success',
      });
    } catch {
      toast('Failed to delete contact!', {
        type: 'error',
      });
    }
  };

  const handleContactCreate = async (prospectId: string, data: Contact) => {
    try {
      await dispatch(
        ContactActions.createContact({
          parentId: prospectId,
          contact: data,
          options: { isProspect: true },
        }),
      ).unwrap();

      refreshContacts();

      toast('Successfully created contact!', {
        type: 'success',
      });
    } catch {
      toast('Failed to create contact!', {
        type: 'error',
      });
    }
  };

  const handleContactUpdate = async (
    prospectId: string,
    contactId: string,
    data: Partial<Contact>,
  ) => {
    try {
      await dispatch(
        ContactActions.updateContact({
          parentId: prospectId,
          contactId,
          contact: data,
          options: { isProspect: true },
        }),
      ).unwrap();

      refreshContacts();

      toast('Successfully updated contact!', {
        type: 'success',
      });
    } catch {
      toast('Failed to update contact!', {
        type: 'error',
      });
    }
  };

  const handleContactConfirm = async (data: Contact) => {
    if (!prospectId) {
      return;
    }

    const contactId = getContactId(data);

    if (contactId) {
      handleContactUpdate(prospectId, contactId, data);
    } else {
      handleContactCreate(prospectId, data);
    }
  };

  // Edit only for Admin or Sales
  const canEdit = isAdmin || isSales;

  const isTransformed = status === ProspectStatus.WON;

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">About</ContentHeading>
          {canEdit && !isTransformed ? (
            <Text variant="body2">View and edit the prospect profile</Text>
          ) : (
            <Text variant="body2">View the prospect profile</Text>
          )}
        </Info>
        {canEdit && !isTransformed && (
          <Button variant="outlined" onClick={openProspectDialog}>
            <EditIcon name="edit" />
            Edit prospect profile
          </Button>
        )}
      </Header>
      <Content>
        <Notes prospect={prospect} confirmEdit={handleProspectConfirm} />
        <Row>
          <StyledHeading variant="h3">Name</StyledHeading>
          <Value>
            <Text variant="body2">{name}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Type</StyledHeading>
          <Value>
            <TypeText variant="body1">{translatedGroupType[type]}</TypeText>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Products</StyledHeading>
          <Value>
            <TypeText variant="body1">
              {products.map((product) => translatedProduct[product]).join(', ')}
            </TypeText>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Priority</StyledHeading>
          <Value>
            <Text variant="body2">{priority ? 'Yes' : 'No'}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Status</StyledHeading>
          <Value>
            <Text variant="body2">{translatedProspectStatus[status]}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Expected Close Date</StyledHeading>
          <Value>
            <Text variant="body2">{expectedCloseDate}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Address</StyledHeading>
          <Value>
            <Text variant="body2">{address}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Contacts</StyledHeading>
          <Value scrollable>
            {contacts.map((contact, index) => (
              <ContactChip
                key={getContactId(contact)}
                contact={contact}
                index={index}
                onDismiss={handleContactDismiss}
                onEdit={handleContactEdit}
                onDelete={handleContactDelete}
                permission={canEdit && !isTransformed}
              />
            ))}
            {canEdit && !isTransformed && (
              <AddContact onClick={handleContactAdd}>
                <AddContactIcon name="add-circle" />
                <AddContactText variant="body2">Add contact</AddContactText>
              </AddContact>
            )}
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Email</StyledHeading>
          <Value>
            <Text variant="body2">{email}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Phone</StyledHeading>
          <Value>
            <PhoneNumber>
              <StyledFlagIcon country={callingCode} />
              <Text variant="body2">{phoneNumber}</Text>
            </PhoneNumber>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Website</StyledHeading>
          <Value>
            <Link href={websiteUrl}>{website}</Link>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Preferred language</StyledHeading>
          <Value>
            <StyledFlagIcon country={getLanguageCountryCode(language)} />
            <CapitalizedText variant="body2">{language}</CapitalizedText>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Hardware Set Quantity</StyledHeading>
          <Value>
            <Text variant="body2">{hardwareSetQuantity}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Extras</StyledHeading>
          <Value>
            <Text variant="body2">{extras}</Text>
          </Value>
        </Row>
      </Content>
      <ProspectDialog
        open={prospectDialogOpen}
        prospect={prospect}
        onClose={closeProspectDialog}
        onCancel={closeProspectDialog}
        onConfirm={handleProspectConfirm}
      />
      <ContactDialog
        contact={contact}
        loading={updatingContact}
        open={contactDialogOpen}
        onCancel={closeContactDialog}
        onConfirm={handleContactConfirm}
      />
    </Wrapper>
  );
};

export default About;
