import { FC, Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import { PromotionSelectors } from '../../../store/selectors';
import { useSelector } from '../../../store';

import { getId as getPromotionId } from '../../../models/promotionModel';

import { alphaToHex } from '../../../utils/colorUtils';

import { Heading, Icon, Text } from '../../../components';
import PromotionTile from './Promotion/PromotionTile';

const StyledPromoHeading = styled(Heading)`
  margin-top: 32px;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

interface DropContainerProps {
  $isOver: boolean;
}

const DropContainer = styled.div<DropContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-height: 64px;
  transition: all 0.1s ease-in-out;
  background-color: ${({ theme }) => theme.colors.surfaceOne};
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  :active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  & > div > div {
    margin-bottom: 0;
  }
`;
interface OverlayProps {
  $visible: boolean;
  $isDragging: boolean;
  $border: boolean;
}

const pulseAnimation = (props: any) =>
  keyframes`
   0% { 
    box-shadow: 0px 0px 0px 2px ${props.theme.colors.surfaceOne};
   }
   50% { 
    box-shadow: 0px 0px 0px 2px ${props.theme.colors.primary};
   }
   100% { 
    box-shadow: 0px 0px 0px 2px ${props.theme.colors.surfaceOne};
   }
`;

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  border: ${({ theme, $border }) =>
    $border ? `1px dashed ${theme.colors.grayOpaque}` : 'unset'};
  animation: ${(props) =>
    props.$isDragging
      ? css`
          ${pulseAnimation(props)} 2s ease-in-out infinite alternate
        `
      : 'unset'};
  background-color: ${({ theme, $border }) =>
    $border ? theme.colors.light : theme.colors.surfaceTwo + alphaToHex(0.8)};
  & p {
    font-weight: 800;
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

interface AlertIconProps {
  $danger: boolean;
}

const AlertIcon = styled(Icon)<AlertIconProps>`
  color: ${(props) =>
    props.$danger ? props.theme.colors.danger : props.theme.colors.primary};
  margin-right: 4px;
`;

const PlaceholderWrapper = styled.div`
  display: none !important;
  & > div {
    display: none !important;
  }
`;

interface Props {
  isDragging: boolean;
}

const DefaultPromotion: FC<Props> = ({ isDragging }) => {
  const promotions = useSelector(PromotionSelectors.selectPromotions);

  const defaultPromotion = promotions
    ? promotions.find((promo) => getPromotionId(promo) === 'metadata')
    : undefined;

  return (
    <Fragment>
      <StyledPromoHeading variant="h2">Current promotion</StyledPromoHeading>
      <StyledText variant="body2">
        Currently selected promotional configuration. Used in your emails,
        digital flyer and lead activations. Drag a promotion from the list below
        to apply it to your store!
      </StyledText>
      <Droppable droppableId={'default-promotion-container'}>
        {(provided, snap) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <DropContainer $isOver={snap.isDraggingOver}>
              {defaultPromotion ? (
                <PromotionTile promotion={defaultPromotion} disabled={true} />
              ) : null}
              <Overlay
                $visible={isDragging || defaultPromotion === undefined}
                $isDragging={isDragging}
                $border={defaultPromotion === undefined}
              >
                <>
                  <AlertIcon
                    $danger={defaultPromotion === undefined}
                    name="alert"
                  ></AlertIcon>
                  <Text variant="body2">
                    {defaultPromotion
                      ? 'Drop here to set as default.'
                      : 'Promotion not set! Drag and drop a promotion from the list below.'}
                  </Text>
                </>
              </Overlay>
            </DropContainer>
            {/* wrap the placeholder as we do not want the default behavior(if not rendered dnd is going to complain) */}
            <PlaceholderWrapper>{provided.placeholder}</PlaceholderWrapper>
          </div>
        )}
      </Droppable>
    </Fragment>
  );
};

export default DefaultPromotion;
