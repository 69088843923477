import {
  apply,
  BillingCycles,
  Store,
  storeModel,
  StoreModule,
} from '@atogear/arion-utils';
import { last } from 'ramda';
import { PDFArray, PDFBool, PDFDocument, PDFName, PDFNumber } from 'pdf-lib';
// @ts-ignore
import fontkit from '@pdf-lib/fontkit';

import { format } from 'date-fns';
import { formatYears } from './formatters';

import pdfFont from '../assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf';
import agreementPdf from '../assets/pdf/ARIONHUB-Agreement.pdf';
import quotePdf from '../assets/pdf/ARIONHUB-Quote.pdf';

import { prospectModel } from '../models';

import { translatedBillingCycles, translatedModules } from '../translations';
import { Prospect } from '../models/prospectModel';
import { SUBSCRIPTION_CURRENCY_OPTIONS } from './constants';

const MAX_SIGNATURE_WIDTH = 120;
const MAX_SIGNATURE_HEIGHT = 120;
const SIGNATURE_Y = 175;

export interface SignPDFMetadata {
  atoSignature?: string;
  atoSignDate?: string;
  atoSignName?: string;
  atoSignRole?: string;
  atoSignLocation?: string;
  customerSignature?: string;
  customerSignDate?: string;
  customerSignName?: string;
  customerSignRole?: string;
  customerSignLocation?: string;
}

export const defaultMetadata: SignPDFMetadata = {
  atoSignature: '',
  atoSignDate: '',
  atoSignName: '',
  atoSignRole: '',
  atoSignLocation: '',
  customerSignature: '',
  customerSignDate: '',
  customerSignName: '',
  customerSignRole: '',
  customerSignLocation: '',
};

export const formatCurrencyName = (price: string, currency = 'EUR') => {
  const currencyOption = SUBSCRIPTION_CURRENCY_OPTIONS.find((option) => {
    return option.value === currency;
  });
  let currencyFullName = currencyOption?.label || 'Euro';

  const subFeeLength = `${price} ${currencyFullName} (ex. VAT) per cycle`
    .length;

  if (subFeeLength > 51) currencyFullName = currencyOption?.value || 'Euro';

  return currencyFullName;
};

export const fillPdf = async (store: Store, basePrice?: number) => {
  // Load agreement pdf
  const pdfResponse = await fetch(agreementPdf);
  const pdfBytes = await pdfResponse.arrayBuffer();
  const pdfDoc = await PDFDocument.load(pdfBytes);

  // Register fontkit
  pdfDoc.registerFontkit(fontkit);

  // Load font
  const fontResponse = await fetch(pdfFont);
  const fontBytes = await fontResponse.arrayBuffer();

  // Embed font
  const font = await pdfDoc.embedFont(fontBytes);

  // Get form
  const form = pdfDoc.getForm();

  // Store Info
  const companyNameField = form.getTextField('Company name');
  const streetField = form.getTextField('Address');
  const zipCodeCityField = form.getTextField('Postal code & City');
  const countryField = form.getTextField('Country');

  // These fields in the PDF document are multiline, but we want to keep them on a single line
  companyNameField.disableMultiline();
  zipCodeCityField.disableMultiline();
  countryField.disableMultiline();

  // Agreement Info
  const subscriptionPeriodField = form.getTextField('Subscription period');
  const numberOfLicences = form.getTextField('Number of ARIONHUB licences');
  const subscriptionFeeField = form.getTextField(
    'Subscription fee per ARIONHUB licence',
  );
  const totalPriceField = form.getTextField('Total price');
  const billingCycleField = form.getTextField('Billing cycle');
  const agreementStartDate = form.getTextField('Agreement start date');

  // Location Info
  const storeNameField = form.getTextField('Name');
  const storeAddressField = form.getTextField('Store Address');

  // Store Info
  const zipCodeCity = `${storeModel.getZipCode(store)} ${storeModel.getCity(
    store,
  )}`;

  // Agreement Info
  const { perCycle, total } = storeModel.getFormattedSubscriptionPrice(
    store,
    basePrice,
  );

  // Store Info
  companyNameField.setText(storeModel.getName(store));
  streetField.setText(storeModel.getStreet(store) || '');
  zipCodeCityField.setText(zipCodeCity);
  countryField.setText(storeModel.getCountryName(store));

  // Agreement Info
  const subPeriod = apply(storeModel.getSubscriptionPeriod, store, 0);
  const billingCycle = apply(
    storeModel.getBillingCycle,
    store,
    BillingCycles.MONTHLY,
  );
  const subscriptionStart = apply(
    storeModel.getFormattedSubscriptionStartDate,
    store,
  );

  if (!subscriptionStart) {
    throw new Error(
      'Failed to sign pdf: Could not find valid subscription plan!',
    );
  }

  const paymentTerm = apply(
    storeModel.getFormattedPaymentDate,
    store,
    subscriptionStart,
  );

  let currencyFullName = formatCurrencyName(
    perCycle,
    store.subscriptionCurrency,
  );

  subscriptionPeriodField.setText(formatYears(subPeriod));
  numberOfLicences.setText('1');
  subscriptionFeeField.setText(
    `${perCycle} ${currencyFullName} (ex. VAT) per cycle`,
  );
  totalPriceField.setText(`${total} ${currencyFullName} (ex. VAT)`);
  billingCycleField.setText(
    `First payment: ${paymentTerm} then ${translatedBillingCycles[billingCycle]}`,
  );
  agreementStartDate.setText(subscriptionStart);

  // Location Info
  storeNameField.setText(storeModel.getName(store));
  storeAddressField.setText(storeModel.getAddress(store));

  const pageCount = pdfDoc.getPageCount();

  // Set form fields font
  form.updateFieldAppearances(font);

  // Save changes
  const filledPdfBytes = await pdfDoc.save();

  return {
    pdfBytes: filledPdfBytes,
    pageCount,
  };
};

export const fillPdfProspect = async (
  prospect: Prospect,
  basePrice?: number,
) => {
  // Load agreement pdf
  const pdfResponse = await fetch(agreementPdf);
  const pdfBytes = await pdfResponse.arrayBuffer();
  const pdfDoc = await PDFDocument.load(pdfBytes);

  // Register fontkit
  pdfDoc.registerFontkit(fontkit);

  // Load font
  const fontResponse = await fetch(pdfFont);
  const fontBytes = await fontResponse.arrayBuffer();

  // Embed font
  const font = await pdfDoc.embedFont(fontBytes);

  // Get form
  const form = pdfDoc.getForm();

  // Prospect Info
  const companyNameField = form.getTextField('Company name');
  const streetField = form.getTextField('Address');
  const zipCodeCityField = form.getTextField('Postal code & City');
  const countryField = form.getTextField('Country');

  // These fields in the PDF document are multiline, but we want to keep them on a single line
  companyNameField.disableMultiline();
  zipCodeCityField.disableMultiline();
  countryField.disableMultiline();

  // Agreement Info
  const subscriptionPeriodField = form.getTextField('Subscription period');
  const numberOfLicences = form.getTextField('Number of ARIONHUB licences');
  const subscriptionFeeField = form.getTextField(
    'Subscription fee per ARIONHUB licence',
  );
  const totalPriceField = form.getTextField('Total price');
  const billingCycleField = form.getTextField('Billing cycle');
  const agreementStartDate = form.getTextField('Agreement start date');

  // Location Info
  const prospectNameField = form.getTextField('Name');
  const prospectAddressField = form.getTextField('Store Address');

  // Prospect Info
  const zipCodeCity = `${prospectModel.getZipCode(
    prospect,
  )} ${prospectModel.getCity(prospect)}`;

  // Agreement Info
  const { perCycle, total } = prospectModel.getFormattedSubscriptionPrice(
    prospect,
    basePrice,
  );

  // Store Info
  companyNameField.setText(prospectModel.getName(prospect));
  streetField.setText(prospectModel.getStreet(prospect) || '');
  zipCodeCityField.setText(zipCodeCity);
  countryField.setText(prospectModel.getCountryName(prospect));

  // Agreement Info
  const subPeriod = apply(prospectModel.getSubscriptionPeriod, prospect, 0);
  const billingCycle = apply(
    prospectModel.getBillingCycle,
    prospect,
    BillingCycles.MONTHLY,
  );
  const subscriptionStart = apply(
    prospectModel.getFormattedSubscriptionStartDate,
    prospect,
  );

  if (!subscriptionStart) {
    throw new Error(
      'Failed to sign pdf: Could not find valid subscription plan!',
    );
  }

  const paymentTerm = apply(
    prospectModel.getFormattedPaymentDate,
    prospect,
    subscriptionStart,
  );

  const currencyName = formatCurrencyName(
    perCycle,
    prospect.subscriptionCurrency,
  );

  subscriptionPeriodField.setText(formatYears(subPeriod));
  numberOfLicences.setText('1');
  subscriptionFeeField.setText(
    `${perCycle} ${currencyName} (ex. VAT) per cycle`,
  );
  totalPriceField.setText(`${total} ${currencyName} (ex. VAT)`);
  billingCycleField.setText(
    `First payment: ${paymentTerm} then ${translatedBillingCycles[billingCycle]}`,
  );
  agreementStartDate.setText(subscriptionStart);

  // Location Info
  prospectNameField.setText(prospectModel.getName(prospect));
  prospectAddressField.setText(prospectModel.getAddress(prospect));

  const pageCount = pdfDoc.getPageCount();

  // Set form fields font
  form.updateFieldAppearances(font);

  // Save changes
  const filledPdfBytes = await pdfDoc.save();

  return {
    pdfBytes: filledPdfBytes,
    pageCount,
  };
};

export const fillQuotePdf = async (prospect: Prospect, basePrice?: number) => {
  // Load quote pdf
  const pdfResponse = await fetch(quotePdf);
  const pdfBytes = await pdfResponse.arrayBuffer();
  const pdfDoc = await PDFDocument.load(pdfBytes);

  // Register fontkit
  pdfDoc.registerFontkit(fontkit);

  // Load font
  const fontResponse = await fetch(pdfFont);
  const fontBytes = await fontResponse.arrayBuffer();

  // Embed font
  const font = await pdfDoc.embedFont(fontBytes);

  // Get form
  const form = pdfDoc.getForm();

  // Quote info
  const quoteDateField = form.getTextField('Quote date');

  // Prospect Info
  const billingPersonField = form.getTextField('Billing person');
  const prospectNameField = form.getTextField('Prospect name');
  const prospectStreetField = form.getTextField('Prospect address');
  const prospectZipCodeCityField = form.getTextField('Prospect city');
  const prospectCountryField = form.getTextField('Prospect country');
  const prospectEmailField = form.getTextField('Prospect email');
  const prospectPhoneNumberField = form.getTextField('Prospect phone number');

  // These fields in the PDF document are multiline, but we want to keep them on a single line
  prospectNameField.disableMultiline();
  prospectStreetField.disableMultiline();
  prospectZipCodeCityField.disableMultiline();

  const quantityField = form.getTextField('Quantity');
  const durationField = form.getTextField('Duration');
  const modulesField = form.getTextField('Modules');
  const extrasField = form.getTextField('Extras');
  const priceField = form.getTextField('Price per billing cycle');
  const subtotalField = form.getTextField('Price per billing cycle 2');
  const totalPriceField = form.getTextField('Total');

  quoteDateField.setText(format(Date.now(), 'dd/MM/yyyy'));

  // Prospect Info
  const zipCodeCity = `${prospectModel.getZipCode(
    prospect,
  )} ${prospectModel.getCity(prospect)}`;

  const { total, perCycle } = prospectModel.getFormattedSubscriptionPrice(
    prospect,
    basePrice,
  );
  const currencyName = formatCurrencyName(
    perCycle,
    prospect.subscriptionCurrency,
  );
  const rawModules = prospectModel.getModules(prospect);
  const modules = Object.keys(rawModules)
    .filter((key) => rawModules[key as StoreModule])
    .sort() as StoreModule[];

  const formattedModules = !modules.length
    ? '-'
    : modules.map((key) => translatedModules[key].title).join(', ');

  billingPersonField.setText(prospectModel.getBillingName(prospect) || '');
  prospectNameField.setText(prospectModel.getName(prospect) || '');
  prospectStreetField.setText(prospectModel.getStreet(prospect) || '');
  prospectZipCodeCityField.setText(zipCodeCity || '');
  prospectCountryField.setText(prospectModel.getCountryName(prospect) || '');
  prospectEmailField.setText(prospectModel.getEmail(prospect) || '');
  prospectPhoneNumberField.setText(
    prospectModel.getFormattedPhoneNumber(prospect),
  );

  const formattedQuantity =
    prospectModel.getFormattedHardwareSetQuantity(prospect);

  quantityField.setText(`x ${formattedQuantity}`);

  durationField.setText(
    `(Duration: ${prospectModel.getDuration(prospect).toString()} months)`,
  );
  modulesField.setText(formattedModules);
  extrasField.setText(prospectModel.getExtras(prospect));

  subtotalField.setText(`${perCycle} ${currencyName} (ex. VAT)`);
  priceField.setText(`${perCycle} ${currencyName} (ex. VAT)`);
  totalPriceField.setText(`${total} ${currencyName} (ex. VAT)`);

  // Set form fields font
  form.updateFieldAppearances(font);

  // Save changes
  const pdfString = await pdfDoc.saveAsBase64();

  // Load signed pdf
  const filledPdfDoc = await PDFDocument.load(pdfString);
  // Lock fields
  // Get form
  const acroForm = filledPdfDoc.catalog.AcroForm();

  if (!acroForm) {
    throw new Error('Error: no acro form');
  }

  // Include fields that are not touched/filled in
  acroForm.set(PDFName.of('NeedAppearances'), PDFBool.True);

  // Get form fields
  const fieldRefs = acroForm.lookup(PDFName.of('Fields'), PDFArray);

  const fieldCount = fieldRefs.size();

  const fields = new Array(fieldCount);

  for (let i = 0; i < fieldCount; i++) {
    fields[i] = fieldRefs.lookup(i);
  }

  // Set all form fields to read-only
  fields.forEach((f) =>
    f.set(PDFName.of('Ff'), PDFNumber.of(1 << 0 /* Read Only */)),
  );

  // Save changes
  const lockedPdf = await filledPdfDoc.save();

  return lockedPdf;
};

export const signPdf = async (
  initialPdf: string | Uint8Array | ArrayBuffer,
  metadata: SignPDFMetadata,
) => {
  // Load initial pdf
  const pdfDoc = await PDFDocument.load(initialPdf);

  // Register fontkit
  pdfDoc.registerFontkit(fontkit);

  // Load font
  const fontResponse = await fetch(pdfFont);
  const fontBytes = await fontResponse.arrayBuffer();

  // Embed font
  const font = await pdfDoc.embedFont(fontBytes);

  const {
    atoSignature,
    atoSignDate,
    atoSignName,
    atoSignRole,
    atoSignLocation,
    customerSignature,
    customerSignDate,
    customerSignName,
    customerSignRole,
    customerSignLocation,
  } = metadata;

  // Get form
  const form = pdfDoc.getForm();

  // ATOGEAR Info
  const atoNameField = form.getTextField('Name ATOGEAR');
  const atoRoleField = form.getTextField('Role ATOGEAR');
  const atoSignDateField = form.getTextField('Date ATOGEAR');
  const atoSignLocationField = form.getTextField('Place ATOGEAR');

  // Customer Info
  const custNameField = form.getTextField('Name Customer');
  const custRoleField = form.getTextField('Role Customer');
  const custSignDateField = form.getTextField('Date Customer');
  const custSignLocationField = form.getTextField('Place Customer');

  // ATOGEAR Info
  atoNameField.setText(atoSignName);
  atoRoleField.setText(atoSignRole);
  atoSignDateField.setText(atoSignDate);
  atoSignLocationField.setText(atoSignLocation);

  // Customer Info
  custNameField.setText(customerSignName);
  custRoleField.setText(customerSignRole);
  custSignDateField.setText(customerSignDate);
  custSignLocationField.setText(customerSignLocation);

  // Set form fields font
  form.updateFieldAppearances(font);

  // Get last page / sign page
  const signPage = last(pdfDoc.getPages());

  if (!signPage) {
    throw new Error('Failed to sign pdf: no sign page');
  }

  // Embed signatures as images
  let atoSignatureImg;
  let customerSignatureImg;

  if (atoSignature) {
    atoSignatureImg = await pdfDoc.embedPng(atoSignature);

    // Lock image size
    const atoSignatureDimensions = atoSignatureImg.scaleToFit(
      MAX_SIGNATURE_WIDTH,
      MAX_SIGNATURE_HEIGHT,
    );

    signPage.drawImage(atoSignatureImg, {
      x: 100,
      y: SIGNATURE_Y,
      width: atoSignatureDimensions.width,
      height: atoSignatureDimensions.height,
    });
  }

  if (customerSignature) {
    customerSignatureImg = await pdfDoc.embedPng(customerSignature);

    const customerSignatureDimensions = customerSignatureImg.scaleToFit(
      MAX_SIGNATURE_WIDTH,
      MAX_SIGNATURE_HEIGHT,
    );

    signPage.drawImage(customerSignatureImg, {
      x: signPage.getWidth() / 2 + 40,
      y: SIGNATURE_Y,
      width: customerSignatureDimensions.width,
      height: customerSignatureDimensions.height,
    });
  }

  // Save changes
  const pdfString = await pdfDoc.saveAsBase64();

  // Load signed pdf
  const signedPdfDoc = await PDFDocument.load(pdfString);

  // Lock fields
  // Get form
  const acroForm = signedPdfDoc.catalog.AcroForm();

  if (!acroForm) {
    throw new Error('Failed to sign pdf: no acro form');
  }

  // Include fields that are not touched/filled in
  acroForm.set(PDFName.of('NeedAppearances'), PDFBool.True);

  // Get form fields
  const fieldRefs = acroForm.lookup(PDFName.of('Fields'), PDFArray);

  const fieldCount = fieldRefs.size();

  const fields = new Array(fieldCount);

  for (let i = 0; i < fieldCount; i++) {
    fields[i] = fieldRefs.lookup(i);
  }

  // Set all form fields to read-only
  fields.forEach((f) =>
    f.set(PDFName.of('Ff'), PDFNumber.of(1 << 0 /* Read Only */)),
  );

  // Save changes
  const lockedPdf = await signedPdfDoc.save();

  return lockedPdf;
};
