import { createAsyncThunk } from '@reduxjs/toolkit';
import { Store, User, userModel } from '@atogear/arion-utils';

import {
  getInviteService,
  getStoreAccountService,
  UpdateStoreAccountData,
} from '../../services';

import { Invite, isCompleted, isExpired } from '../../models/inviteModel';

export const getStoreAccounts = createAsyncThunk(
  'storeAccounts/getStoreAccounts',
  /**
   * Get Store Accounts.
   *
   * @param store Store's id.
   * @returns Promise that resolves to Store Accounts.
   */
  async (store: Store): Promise<User[]> => {
    const storeAccounts = await getStoreAccountService().getStoreAccounts(
      store,
    );

    return storeAccounts.sort(
      (a, b) => userModel.getRoleIndex(a) - userModel.getRoleIndex(b),
    );
  },
);

export const getStoreAccount = createAsyncThunk(
  'storeAccounts/getStoreAccount',
  /**
   * Get Store Account.
   *
   * @param userId Store Account's id.
   * @returns Promise that resolves to Store Account.
   */
  async (userId: string): Promise<User> => {
    const storeAccount = await getStoreAccountService().getStoreAccount(userId);

    return storeAccount;
  },
);

interface UpdateStoreAccountParams {
  userId: string;
  data: UpdateStoreAccountData;
}

export const updateStoreAccount = createAsyncThunk(
  'storeAccounts/updateStoreAccount',
  /**
   * Update Store Account.
   *
   * @param params Store Account's data.
   * @returns Promise that resolves to updated Store Account.
   */
  async (params: UpdateStoreAccountParams): Promise<User> => {
    const { userId, data } = params;

    const result = await getStoreAccountService().updateStoreAccount(
      userId,
      data,
    );

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.user;
  },
);

export const removeStoreAccount = createAsyncThunk(
  'storeAccounts/removeStoreAccount',
  /**
   * Delete Store Account.
   *
   * @param userId Store Account's id.
   * @returns Promise that resolves when Store Account has been successfully deleted.
   */
  async (userId: string) => {
    const result = await getStoreAccountService().removeStoreAccount(userId);

    if (!result.data.success) {
      throw result.data.error;
    }

    return {
      id: userId,
    };
  },
);

export const removeStoreOwnerAccount = createAsyncThunk(
  'storeAccounts/removeStoreOwnerAccount',
  /**
   * Delete Store Account.
   *
   * @param userId Store Account's id.
   * @returns Promise that resolves when Store Account has been successfully deleted.
   */
  async (storeId: string) => {
    const result = await getStoreAccountService().removeStoreOwner(storeId);

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data;
  },
);

export const getInvites = createAsyncThunk(
  'stores/getInvites',
  /**
   * Get Store's Invites.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Store's Invites.
   */
  async (storeId: string): Promise<Invite[]> => {
    const invites = await getInviteService().getInvites(storeId);

    // Filter out expired and completed invites
    return invites.filter(
      (invite) => !(isExpired(invite) || isCompleted(invite)),
    );
  },
);
