import { ChangeEventHandler, FC, MouseEventHandler } from 'react';
import { mergeRight } from 'ramda';
import styled from 'styled-components';

import Checkbox from '../Checkbox';
import Text from '../Text';

import { Filter } from './types';

const Wrapper = styled.div`
  display: flex;
`;

const Row = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

interface Props {
  filters: Filter;
  onChange?: (filter: Filter) => void;
}

const TableFilters: FC<Props> = ({ filters, onChange }) => {
  const labels = Array.from(filters.labels);
  const options = Array.from(filters.options);

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) =>
    e.stopPropagation();

  const handleChange =
    (index: number): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      const newFiltersSet = new Set(filters.values);

      // If selected, add to filters
      if (e.target.checked) {
        newFiltersSet.add(options[index]);
      }
      // If not, remove from filters
      else {
        newFiltersSet.delete(options[index]);
      }
      const newFilters = Array.from(newFiltersSet);

      onChange &&
        onChange(
          mergeRight(filters, {
            values: newFilters,
          }),
        );
    };

  return (
    <Wrapper onClick={handleClick}>
      {options.map((option, index) => {
        const id = `filter-checkbox-${index}`;

        return (
          <Row key={index} htmlFor={id}>
            <StyledCheckbox
              id={id}
              checked={filters.values.includes(option)}
              onChange={handleChange(index)}
            />
            <Text variant="body1">{labels[index]}</Text>
          </Row>
        );
      })}
    </Wrapper>
  );
};

export default TableFilters;
