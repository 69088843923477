import { ChangeEventHandler, FC, useEffect, useId, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import {
  Checkbox,
  DialogHeading,
  DialogInput,
  DialogInputLabel,
} from '../../../../components';

import { ExtraProspectStoreData } from '../../types';
import { prospectStoreModel } from '../../../../models';

import { apply, isNilOrWhitespace, storeModel } from '@atogear/arion-utils';
import InformationIcon from '../../../../components/InformationIcon';
import { ProspectStore } from '../../../../models/prospectStoreModel';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledWrapper = styled(Wrapper)`
  margin-left: 32px;
`;

interface FieldProps {
  $last?: boolean;
  $direction?: 'column' | 'row';
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  ${({ $last }) => `margin-${$last ? 'top' : 'bottom'}: 10px;`};
`;

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 24px;
`;

const StyledInfoIcon = styled(InformationIcon)`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 6px;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const StyledCheckboxLabel = styled(StyledInputLabel)`
  cursor: pointer;
  margin-left: 10px;
`;

interface ColumnProps {
  $left?: boolean;
}

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  ${({ $left }) => (!$left ? 'flex: 1' : 'width: 120px')};
  ${({ $left }) => `margin-${$left ? 'right' : 'left'}: 4px`};
`;

interface Props {
  store?: ProspectStore;
  onChange: (value: ExtraProspectStoreData) => void;
}

const Extra: FC<Props> = ({ store, onChange }) => {
  const [ownerFirstName, setOwnerFirstName] = useState('');
  const [ownerLastName, setOwnerLastName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [treadmill, setTreadmill] = useState(false);
  const [track, setTrack] = useState(false);

  useEffect(() => {
    setOwnerFirstName(apply(prospectStoreModel.getOwnerFirstName, store, ''));
    setOwnerLastName(apply(prospectStoreModel.getOwnerLastName, store, ''));
    setOwnerEmail(apply(prospectStoreModel.getOwnerEmail, store, ''));
    setTreadmill(
      apply(
        storeModel.hasTreadmill,
        store,
        storeModel.hasTreadmill(storeModel.defaults),
      ),
    );
    setTrack(
      apply(
        storeModel.hasTrack,
        store,
        storeModel.hasTrack(storeModel.defaults),
      ),
    );
  }, [store]);

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleOwnerFirstNameChange: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value } = e.target;

    setOwnerFirstName(value);

    debouncedOnChange({
      ownerFirstName: value,
    });
  };

  const handleOwnerLastNameChange: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value } = e.target;

    setOwnerLastName(value);

    debouncedOnChange({
      ownerLastName: value,
    });
  };

  const handleOwnerEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setOwnerEmail(value);

    debouncedOnChange({
      ownerEmail: value,
    });
  };

  const handleTreadmillChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked } = e.target;

    setTreadmill(checked);

    onChange({
      treadmill: checked,
    });
  };

  const handleTrackChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked } = e.target;

    setTrack(checked);

    onChange({
      track: checked,
    });
  };

  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const id = useId();
  const treadmillId = `${id}-treadmill`;
  const trackId = `${id}-track`;
  const ownerEmailId = `${id}-owner-email`;
  const ownerFirstNameId = `${id}-owner-first-name`;
  const ownerLastNameId = `${id}-owner-last-name`;

  return (
    <Row>
      <Wrapper>
        <Row>
          <StyledHeading variant="h3">Owner</StyledHeading>
          <StyledInfoIcon tooltip="If an owner is not set for the store, the billing person for the prospect will be automatically be assigned." />
        </Row>

        <Field $direction="row">
          <Column $left>
            <StyledInputLabel htmlFor={ownerFirstNameId}>
              First name
            </StyledInputLabel>
            <DialogInput
              id={ownerFirstNameId}
              placeholder="First Name"
              value={ownerFirstName}
              validate={handleValidate}
              onChange={handleOwnerFirstNameChange}
            />
          </Column>
          <Column>
            <StyledInputLabel htmlFor={ownerLastNameId}>
              Last name
            </StyledInputLabel>
            <DialogInput
              id={ownerLastNameId}
              placeholder="Last Name"
              value={ownerLastName}
              validate={handleValidate}
              onChange={handleOwnerLastNameChange}
            />
          </Column>
        </Field>
        <Field>
          <Column>
            <StyledInputLabel htmlFor={ownerEmailId}>Email</StyledInputLabel>
            <DialogInput
              id={ownerEmailId}
              placeholder="Email"
              type="email"
              value={ownerEmail}
              validate={handleValidate}
              onChange={handleOwnerEmailChange}
            />
          </Column>
        </Field>
      </Wrapper>
      <StyledWrapper>
        <StyledHeading variant="h3">Equipment</StyledHeading>
        <Field $direction="row">
          <Checkbox
            id={treadmillId}
            checked={treadmill}
            onChange={handleTreadmillChange}
          />
          <StyledCheckboxLabel htmlFor={treadmillId} variant="h4">
            Treadmill
          </StyledCheckboxLabel>
        </Field>
        <Field $direction="row">
          <Checkbox id={trackId} checked={track} onChange={handleTrackChange} />
          <StyledCheckboxLabel htmlFor={trackId} variant="h4">
            Running Track
          </StyledCheckboxLabel>
        </Field>
      </StyledWrapper>
    </Row>
  );
};

export default Extra;
