import { formatTime } from '@atogear/arion-utils';
import {
  differenceInDays,
  eachDayOfInterval,
  eachHourOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYear,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  isToday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

import { DateRange, DateType, TimeFrame } from '../types/stats';

export const getDateTypeFromRange = ({ from, to }: DateRange): DateType => {
  if (!from) {
    return 'day';
  }

  if (!to) {
    return 'hour';
  }

  const diffInDays = differenceInDays(to, from);

  if (diffInDays < 3) {
    return 'hour';
  }

  if (diffInDays < 50) {
    return 'day';
  }

  if (diffInDays < 200) {
    return 'week';
  }

  return 'month';
};

export const getDatesOfInterval = (
  dateType: DateType,
  interval: {
    start: Date;
    end: Date;
  },
) => {
  switch (dateType) {
    case 'hour':
      return eachHourOfInterval(interval);
    case 'day':
      return eachDayOfInterval(interval);
    case 'week':
      return eachWeekOfInterval(interval, {
        weekStartsOn: 1,
      }).map((date) => startOfWeek(date));
    case 'month':
      return eachMonthOfInterval(interval).map(startOfMonth);
    default:
      return [];
  }
};

export const formatDateByType = (dateType: DateType, date: Date) => {
  switch (dateType) {
    case 'hour':
      return formatTime(date);
    case 'week':
      return format(date, "'Week 'ww (dd-LLL)");
    case 'month':
      return format(date, 'MMMM');
    case 'day':
    default:
      return format(date, 'dd-LLL');
  }
};

export const timeFrameToDateRange = (timeFrame: TimeFrame): DateRange => {
  const now = Date.now();

  switch (timeFrame) {
    case 'This week':
      return {
        from: startOfWeek(now).getTime(),
        to: endOfWeek(now).getTime(),
      };
    case 'Last week':
      const lastWeek = subWeeks(now, 1);

      return {
        from: startOfWeek(lastWeek).getTime(),
        to: endOfWeek(lastWeek).getTime(),
      };
    case 'Last 7 days':
      return {
        from: subDays(now, 7).getTime(),
        to: endOfToday().getTime(),
      };
    case 'This month':
      return {
        from: startOfMonth(now).getTime(),
        to: endOfMonth(now).getTime(),
      };
    case 'Last month':
      const lastMonth = subMonths(now, 1);

      return {
        from: startOfMonth(lastMonth).getTime(),
        to: endOfMonth(lastMonth).getTime(),
      };
    case 'This year':
      return {
        from: startOfYear(now).getTime(),
        to: endOfYear(now).getTime(),
      };
    case 'Last year':
      const lastYear = subYears(now, 1);

      return {
        from: startOfYear(lastYear).getTime(),
        to: endOfYear(lastYear).getTime(),
      };
    case 'Today':
    default:
      return {
        from: startOfToday().getTime(),
        to: endOfToday().getTime(),
      };
  }
};

export const formatDateRange = (dateRange: DateRange) => {
  const { from, to } = dateRange;

  if (!from || !to) {
    return '-';
  }

  if (isSameDay(from, to)) {
    return 'Today';
  }

  if (isToday(to) && differenceInDays(to, from) === 1) {
    return 'Yesterday';
  }

  const formattedTo = format(to, 'dd MMM yyyy');

  if (!isSameYear(from, to)) {
    return `${format(from, 'dd MMM yyyy')} - ${formattedTo}`;
  }

  if (!isSameMonth(from, to)) {
    return `${format(from, 'dd MMM')} - ${formattedTo}`;
  }

  return `${format(from, 'dd')} - ${formattedTo}`;
};
