import { Filter, SortBy, TableColumn } from './types';

export const getFilters = (columns: TableColumn[], data: any[]) => {
  const filters: Filter[] = [];

  columns.forEach((column) => {
    // Skip columns that do not have filtering enabled
    if (!column.filter) {
      return;
    }

    // Unique values to filter on
    const options: Set<string> = new Set();

    // Labels to show
    const labels: Set<string> = new Set();

    data.forEach((row) => {
      const value = column.getValue(row);

      options.add(value);

      if (column.getLabel) {
        labels.add(column.getLabel(row));
      } else {
        labels.add(value);
      }
    });

    // Values to include
    let values = new Set(options);

    // Check if this column already has a value in the session storage
    const sessionStorageFilter = sessionStorage.getItem(column.key);

    if (sessionStorageFilter) {
      const savedValues: string[] = JSON.parse(sessionStorageFilter);
      values = new Set(savedValues);
    }

    filters.push({
      key: column.key,
      labels: Array.from(labels),
      options: Array.from(options),
      values: Array.from(values),
    });
  });

  return filters;
};

export const getSortBy = (key: string, desc: boolean): SortBy => ({
  key,
  desc,
});
