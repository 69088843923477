import { createReducer, SerializedError } from '@reduxjs/toolkit';

import { Contact } from '../../models/contactModel';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import { contactAdded, contactRemoved, contactUpdated, reset } from './actions';
import {
  createContact,
  deleteContact,
  getContact,
  getContacts,
  updateContact,
} from './thunks';

interface State {
  contacts: {
    data: Contact[];
    preview: Contact[];
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  contact: {
    data?: Contact;
    preview?: Contact;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  contacts: {
    data: [],
    preview: [],
    fetching: false,
    updating: false,
    error: undefined,
  },
  contact: {
    data: undefined,
    preview: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Contacts
    .addCase(getContacts.pending, setFetchingState('contacts'))
    .addCase(getContacts.fulfilled, (state, { payload }) => {
      state.contacts.data = payload;
      state.contacts.preview = payload;
      setFulfilledState('contacts')(state);
    })
    .addCase(getContacts.rejected, setRejectedState('contacts'))
    // Get Contact
    .addCase(getContact.pending, setFetchingState('contact'))
    .addCase(getContact.fulfilled, (state, { payload }) => {
      state.contact.data = payload;
      state.contact.preview = payload;
      setFulfilledState('contact')(state);
    })
    .addCase(getContact.rejected, setRejectedState('contact'))
    // Create Contact
    .addCase(createContact.pending, setUpdatingState('contact'))
    .addCase(createContact.fulfilled, setFulfilledState('contact'))
    .addCase(createContact.rejected, setRejectedState('contact'))
    // Update Contact
    .addCase(updateContact.pending, setUpdatingState('contact'))
    .addCase(updateContact.fulfilled, (state, { payload }) => {
      state.contact.data = payload;
      state.contact.preview = payload;
      setFulfilledState('contact')(state);
    })
    .addCase(updateContact.rejected, setRejectedState('contact'))
    // Delete Contact
    .addCase(deleteContact.pending, setUpdatingState('contact'))
    .addCase(deleteContact.fulfilled, (state) => {
      state.contact.data = undefined;
      state.contact.preview = undefined;
      setFulfilledState('contact')(state);
    })
    .addCase(deleteContact.rejected, setRejectedState('contact'))
    // Local
    .addCase(contactAdded, ({ contacts }, { payload }) => {
      contacts.preview.push(payload);
    })
    .addCase(contactUpdated, ({ contacts }, { payload }) => {
      const { index, contact } = payload;

      const prev = contacts.preview[index];

      // Check if contact in list
      if (prev) {
        // If so, update contact info
        contacts.preview[index] = contact;
      }
    })
    .addCase(contactRemoved, ({ contacts }, { payload }) => {
      const prev = contacts.preview[payload];

      // Check if contact in list
      if (prev) {
        // If so, delete contact info
        contacts.preview.splice(payload, 1);
      }
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
