import { RootState } from '../types';

export const selectReminders = (state: RootState) =>
  state.reminders.reminders.data;

export const selectRemindersFetching = (state: RootState) =>
  state.reminders.reminders.fetching;

export const selectReminder = (state: RootState) =>
  state.reminders.reminder.data;

export const selectReminderFetching = (state: RootState) =>
  state.reminders.reminder.fetching;

export const selectReminderUpdating = (state: RootState) =>
  state.reminders.reminder.updating;
