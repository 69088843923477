import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { apply, Store, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import useToggle from '../../../hooks/useToggle';

import { Heading, Icon, Text, TextArea } from '../../../components';

const NotesContainer = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.grayOpaque};
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 16px;
`;

const NotesHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const NotesText = styled(Text)`
  margin-right: 8px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const NotesEditableText = styled(TextArea)`
  white-space: pre-wrap;
  margin-right: 8px;
  ${({ theme }) => theme.typography.body2}
`;

const EditNotesButton = styled.button`
  margin: 1rem auto 0 1rem;
  background: none;
  border: none;
`;

const EditNotesIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTwo};
  cursor: pointer;
`;

const EditButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <EditNotesButton onClick={onClick}>
    <EditNotesIcon name="edit" />
  </EditNotesButton>
);

interface Props {
  store?: Store;
  confirmEdit: (data: Partial<Store>) => Promise<void>;
}

const Notes: FC<Props> = ({ store, confirmEdit }) => {
  const initialValue = useMemo(() => {
    return apply(storeModel.getNotes, store, '');
  }, [store]);

  const [editing, toggleEditing] = useToggle(false);

  const [notes, setNotes] = useState(initialValue);

  useEffect(() => {
    setNotes(initialValue);
  }, [initialValue]);

  const handleConfirmEdit = async () => {
    await confirmEdit({
      notes: notes,
    });

    toggleEditing();
  };

  return (
    <NotesContainer>
      <NotesHeading variant="h3">Notes</NotesHeading>
      {editing ? (
        <Fragment>
          <NotesEditableText
            defaultValue={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <EditButton onClick={handleConfirmEdit} />
        </Fragment>
      ) : (
        <NotesText variant="body2">
          {notes}
          <EditButton onClick={toggleEditing} />
        </NotesText>
      )}
    </NotesContainer>
  );
};

export default Notes;
