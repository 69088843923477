import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import {
  Heading,
  Icon,
  Text,
  Collapsible,
  Button,
} from '../../../../components';
import AddPromotionItemTile from './AddPromotionItemTile';
import CallToAction from './CallToAction';
import PromotionItemTile from './PromotionItemTile';

import { create, PromotionItem } from '../../../../models/promotionItemModel';
import {
  Promotion,
  getTitle,
  getDescription,
  getPromotionItems,
  updatePromotionItem,
  addPromotionItem,
  removePromotionItem,
  compare,
  getId,
} from '../../../../models/promotionModel';

import { alphaToHex } from '../../../../utils/colorUtils';

const StyledCollapsible = styled(Collapsible)`
  margin-bottom: 16px;
  min-width: 850px;
`;

const PlaceholderStyledCollapsibleWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0px 0px 0px 1px
    ${({ theme }) => theme.colors.primary + alphaToHex(0.5)};
`;

interface DragHandlerProps {
  $isDragging: boolean;
}
const DragHandler = styled.div<DragHandlerProps>`
  width: 100%;
  ${(props) =>
    !props.$isDragging ? 'transform: translateX(0) !important' : undefined}
`;

interface PromoHeadProps {
  $isDragging: boolean;
  $canDrag: boolean;
}

const PromoHead = styled.div<PromoHeadProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  cursor: ${({ $isDragging, $canDrag }) =>
    $canDrag ? ($isDragging ? 'grabbing' : 'grab') : 'unset'};
`;

const PromoHeading = styled(Heading)`
  display: flex;
  align-items: center;
  width: 35%;
`;

const PromoDescription = styled(Text)`
  display: flex;
  align-items: center;
  width: 65%;
`;

const PromoHeadingWrapper = styled.div`
  display: flex;
  width: 75%;
`;

const PromoActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  padding: 8px;
  cursor: pointer;
  border-radius: ${(props) =>
    props.theme.components.buttons.primary.borderRadius || 6}px;
  transition: 0.15s ease-in-out background-color;
  &:hover {
    background-color: ${(props) => props.theme.colors.grayOpaque};
  }
  margin-left: 16px;
  &:last-child {
    margin-right: 8px;
  }
`;

const RemoveIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.danger};
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.textTwo};
`;

const Divider = styled.div`
  content: '';
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.highlight};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  & button {
    margin-left: 8px;
  }
`;

const StyledPreviewButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.secondary};
`;

interface Props {
  promotion: Promotion;
  onUpdate?: (promotion: Promotion) => void;
  onRemove?: (promotion: Promotion) => void;
  onCopy?: (promotion: Promotion) => void;
  onPreview?: (promotion: Promotion) => void;
  disabled?: boolean;
  index?: number;
}

const PromotionTile: FC<Props> = (props) => {
  const {
    promotion,
    onUpdate,
    onRemove,
    onCopy,
    onPreview,
    index = 0,
    disabled = false,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [local, setLocal] = useState<Promotion>(promotion);

  useEffect(() => {
    if (promotion) {
      setLocal(promotion);
    }
  }, [promotion]);

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOnError = (object: any) => {
    setErrors((prev) => ({ ...prev, ...object }));
  };

  const handleCancel = () => {
    setLocal(promotion);
    setIsEditing(false);
  };

  const handleApply = async () => {
    if (onUpdate) await onUpdate(local);

    setIsEditing(false);
  };

  const handleDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (onRemove) onRemove(promotion);
  };

  const handleCopy: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (onCopy) onCopy(promotion);
  };

  const handleEdit: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    setIsEditing((prev) => !prev);
  };

  const hasErrors = Object.values(errors).some((value) => value === true);

  const promotions = getPromotionItems(local);

  return (
    <Draggable
      // allow dragging only if the item is closed, otherwise when hovering the item over the default drop
      // container it will grow it to the open item state and when dropping it the content will jump
      isDragDisabled={open || disabled}
      key={getId(promotion) || `draggable-${index}`}
      draggableId={getId(promotion) || `draggable-${index}`}
      index={index}
    >
      {(provided, snap) => (
        <>
          <DragHandler
            $isDragging={snap.isDragging}
            ref={provided.innerRef}
            {...provided.draggableProps}
            // dragHandleProps -> what to be used to be able to drag the component
            {...provided.dragHandleProps}
          >
            <StyledCollapsible
              onStateChange={setOpen}
              disabled={snap.isDragging}
            >
              <PromoHead
                // to compensate for the hiding of the arrow for opening and closing
                // and prevent jumping of the content
                style={snap.isDragging ? { paddingRight: 32 } : {}}
                $canDrag={!open && !disabled}
                $isDragging={snap.isDragging}
              >
                <PromoHeadingWrapper>
                  <PromoHeading variant="h4">{getTitle(local)}</PromoHeading>
                  {!open ? (
                    <PromoDescription variant="body2">
                      {getDescription(local)}
                    </PromoDescription>
                  ) : null}
                </PromoHeadingWrapper>
                <PromoActions>
                  {open && !disabled ? (
                    <>
                      <IconButton onClick={handleDelete}>
                        <RemoveIcon name="delete" />
                      </IconButton>
                      <IconButton onClick={handleCopy}>
                        <StyledIcon name="copy" />
                      </IconButton>
                      <IconButton onClick={handleEdit}>
                        <StyledIcon name="edit" />
                      </IconButton>
                    </>
                  ) : null}
                </PromoActions>
              </PromoHead>

              <CallToAction
                promotion={local}
                onError={handleOnError}
                onChange={setLocal}
                disabled={!isEditing}
              />

              <Divider />

              <Heading variant="h4">Promo tiles</Heading>
              <Text variant="body1" style={{ marginTop: 8 }}>
                Add up to 4 promotional tiles leading to your products selection
              </Text>

              {[...Array(4)].map((_p, index) => {
                return promotions[index] ? (
                  <PromotionItemTile
                    key={`promotion-item-index-${index}`}
                    id={`promotion-item-index-${index}`}
                    promotionItem={promotions[index]}
                    disabled={!isEditing}
                    onChange={(promotionItem: PromotionItem) => {
                      setLocal(
                        updatePromotionItem(index, promotionItem, local),
                      );
                    }}
                    onError={handleOnError}
                    onRemove={() => {
                      setLocal(removePromotionItem(index, local));
                    }}
                  ></PromotionItemTile>
                ) : isEditing ? (
                  <AddPromotionItemTile
                    key={`add-promo-index-${index}`}
                    onClick={() => setLocal(addPromotionItem(create(), local))}
                  />
                ) : null;
              })}

              <Actions>
                {onPreview ? (
                  <StyledPreviewButton
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => onPreview(local)}
                  >
                    Preview
                  </StyledPreviewButton>
                ) : null}

                {isEditing ? (
                  <>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      disabled={hasErrors || !compare(promotion, local)}
                      onClick={handleApply}
                    >
                      Apply
                    </Button>
                  </>
                ) : null}
              </Actions>
            </StyledCollapsible>
          </DragHandler>
          {/* when the item is dragged show a placeholder to simulate "copying" */}
          {snap.isDragging ? (
            <PlaceholderStyledCollapsibleWrapper>
              <StyledCollapsible disabled={true}>
                <PromoHead
                  // to compensate for the hiding of the arrow for opening and closing
                  // and prevent jumping of the content
                  style={{ paddingRight: 32 }}
                  $canDrag={false}
                  $isDragging={true}
                >
                  <PromoHeadingWrapper>
                    <PromoHeading variant="h4">{getTitle(local)}</PromoHeading>
                    <PromoDescription variant="body2">
                      {getDescription(local)}
                    </PromoDescription>
                  </PromoHeadingWrapper>
                </PromoHead>
                <div></div>
              </StyledCollapsible>
            </PlaceholderStyledCollapsibleWrapper>
          ) : null}
        </>
      )}
    </Draggable>
  );
};

export default PromotionTile;
