import {
  getFirebaseFunctionsPipe,
  getFirebaseStoragePipe,
  getFirestorePipe,
} from '@atogear/ato-broker';
import { clone, isEmpty } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';

import { copy, fields, MediaOptions, serialize } from '../models/themeModel';

import { Theme } from '../theme/types';

import { compressImage } from '../utils/mediaUtils';
import { mediaOptions } from '../utils/themeUtils';
import { UpdateThemeResult } from './types';
import { deleteUndefinedFieldsRecursively } from '@atogear/arion-utils';

const THEME_COLLECTION_PATH = 'themes';

const getGroupThemesPath = (groupId: string) =>
  `groups/${groupId}/${THEME_COLLECTION_PATH}`;

const getThemeService = () => {
  const firestore = getFirestorePipe();
  const storage = getFirebaseStoragePipe();

  // Get All Themes
  const getAllThemes = async (): Promise<Theme[]> => {
    const themes = await firestore.getDocuments(THEME_COLLECTION_PATH, {
      collectionGroup: true,
    });

    return themes.map(serialize);
  };

  // Get Themes
  const getThemes = async (): Promise<Theme[]> => {
    const themes = await firestore.getDocuments(THEME_COLLECTION_PATH, {
      collectionGroup: true,
      where: {
        fieldName: fields.IS_DEFAULT,
        operator: '!=',
        fieldValue: true,
      },
    });

    return themes.map(serialize);
  };

  // Get Default Themes
  const getDefaultThemes = async (): Promise<Theme[]> => {
    const themes = await firestore.getDocuments(THEME_COLLECTION_PATH, {
      collectionGroup: true,
      where: [
        {
          fieldName: fields.IS_DEFAULT,
          operator: '==',
          fieldValue: true,
        },
        {
          fieldName: 'TEMP_isNewTheme',
          operator: '==',
          fieldValue: true,
        },
      ],
    });

    return themes.map(serialize);
  };

  // Get Any Theme
  const getGroupTheme = async (themeId: string): Promise<Theme | undefined> => {
    const themes = await firestore.getDocuments(THEME_COLLECTION_PATH, {
      collectionGroup: true,
      where: {
        fieldName: fields.ID,
        operator: '==',
        fieldValue: themeId,
      },
    });

    return !isEmpty(themes) ? serialize(themes[0]) : undefined;
  };

  // Get Group Themes
  const getGroupThemes = async (groupId: string): Promise<Theme[]> => {
    const themes = await firestore.getDocuments(
      getGroupThemesPath(groupId),
      {},
    );

    return themes.map(serialize);
  };

  // Get Group Theme
  const getTheme = async (
    groupId: string,
    themeId: string,
  ): Promise<Theme | undefined> => {
    const theme = await firestore.getDocument(
      getGroupThemesPath(groupId),
      themeId,
    );

    if (theme) {
      return serialize(theme);
    }
  };

  const saveMedia = async <T extends Theme | Partial<Theme>>(
    theme: T,
  ): Promise<T> => {
    if (!theme.media || !theme.id) return theme;

    let newTheme = clone(theme);

    await Promise.all(
      Object.keys(mediaOptions).map(async (key) => {
        if (!newTheme.media || !newTheme.id) return;

        const mediaKey = key as keyof MediaOptions;

        const mediaUrl = newTheme.media![mediaKey];

        if (mediaUrl && mediaUrl.includes('blob:')) {
          const { preferredSize, type } = mediaOptions[mediaKey];

          const response = await fetch(mediaUrl);
          const blob = await response.blob();

          const file = new File([blob], mediaKey, {
            type: blob.type,
          });

          const shouldCompress =
            type === 'image' && blob.type !== 'image/svg+xml';

          const blobToUpload = shouldCompress
            ? await compressImage(file, {
                resize: 'cover',
                width: preferredSize.width,
                height: preferredSize.height,
              })
            : file;

          const path = `themes/${newTheme.id}/${type}s/${mediaKey}`;

          await storage.upload(path, blobToUpload as Blob);

          const publicUrl = await storage.getLinkForFile(path);

          newTheme.media[mediaKey] = publicUrl;
        }
      }),
    ).catch((e) => {
      Sentry.captureMessage(`Error saving media files: ${e}`);
    });

    return newTheme;
  };

  // Create Group Theme
  const createGroupTheme = async (
    groupId: string,
    theme: Theme,
  ): Promise<Theme> => {
    const id = uuidv4();

    const themeWithId = {
      ...theme,
      id,
    };

    const cleanedTheme = deleteUndefinedFieldsRecursively(themeWithId);

    const preppedTheme = await saveMedia(cleanedTheme);

    const createdTheme = await firestore.addDocumentWithId(
      getGroupThemesPath(groupId),
      id,
      preppedTheme,
    );

    return serialize(createdTheme);
  };

  // Copy Group Theme
  const copyGroupTheme = async (groupId: string, themeId: string) => {
    const theme = await getTheme(groupId, themeId);

    if (!theme) {
      throw new Error('Theme you are trying to copy is not found!');
    }

    const copiedTheme = await createGroupTheme(groupId, copy(theme));

    return copiedTheme;
  };

  // Update Group Theme
  const updateTheme = async (
    groupId: string,
    themeId: string,
    theme: Partial<Theme>,
    newGroupId?: string,
  ): Promise<Theme> => {
    const preppedTheme = await saveMedia(theme);

    const cleanedTheme = deleteUndefinedFieldsRecursively(preppedTheme);

    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-updateTheme',
      {
        groupId,
        themeId,
        theme: {
          ...cleanedTheme,
          groupId: newGroupId ?? groupId,
        },
        newGroupId,
      },
    )) as UpdateThemeResult;

    if (!result.data.success) {
      console.log(result.data.error);
      throw result.data.error;
    }

    return serialize(result.data.theme);
  };

  // Delete Group Theme
  const deleteGroupTheme = async (
    groupId: string,
    themeId: string,
  ): Promise<boolean> => {
    const result = await getFirestorePipe().deleteDocument(
      getGroupThemesPath(groupId),
      themeId,
    );

    return result;
  };

  return {
    getAllThemes,
    getThemes,
    getDefaultThemes,
    getTheme,
    getGroupThemes,
    getGroupTheme,
    createGroupTheme,
    copyGroupTheme,
    updateTheme,
    deleteGroupTheme,
  };
};

export default getThemeService;
