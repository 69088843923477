import { matchPath } from 'react-router-dom';
import { User, userModel, UserRoles } from '@atogear/arion-utils';
import { isEmpty } from 'ramda';
import { PathParams, PathQuery, RouteConfig, Routes } from './types';

export const getAccessibleRoutes = (
  user: User,
  routes: Routes,
  filters: string[] = [],
) =>
  Object.values(routes).filter(
    ({ accessibleBy, key }) =>
      !filters.includes(key) &&
      ((userModel.isAdmin(user) && key !== 'no-access') ||
        isEmpty(accessibleBy) ||
        accessibleBy.includes(userModel.getRole(user))),
  );

export const isActiveRoute = (
  pathname: string,
  route: RouteConfig,
  exact?: boolean,
) =>
  !!matchPath(pathname, {
    path: route.path,
    exact: exact ?? route.exact,
    strict: false,
  });

export const isPowerUserOnlyRoute = (route: RouteConfig) =>
  route.accessibleBy.length < 3 &&
  (route.accessibleBy.includes(UserRoles.BACK_OFFICE) ||
    route.accessibleBy.includes(UserRoles.DATA_VIEWER));

export const getPath = (
  path: string,
  params?: PathParams,
  query?: PathQuery,
) => {
  let newPath = path;

  if (params) {
    Object.keys(params).forEach(
      (param) => (newPath = newPath.replace(`:${param}`, params[param])),
    );
  }

  if (query) {
    Object.keys(query).forEach(
      (param, index) =>
        (newPath += `${index === 0 ? '?' : '&'}${param}=${query[param]}`),
    );
  }

  return newPath;
};
