import { formatFullName, toMillis, UserRoles } from '@atogear/arion-utils';
import { addDays } from 'date-fns';
import { mergeRight } from 'ramda';
import { InviteStatus } from '../enums';
import { translatedUserRoles } from '../translations';

export interface Invite {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
  storeId: string;
  expiresOn: number;
  sentOn: number;
  progress: number;
  status: InviteStatus;
  type: string;
}

const now = Date.now();

export const fields = {
  ID: 'id',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ROLE: 'role',
  STORE_ID: 'storeId',
  EXPIRES_ON: 'expiresOn',
  SENT_ON: 'sentOn',
  PROGRESS: 'progress',
  STATUS: 'status',
  TYPE: 'type',
};

export const defaults: Invite = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  role: UserRoles.EMPLOYEE,
  storeId: '',
  expiresOn: addDays(now, 7).getTime(),
  sentOn: now,
  progress: 0,
  status: InviteStatus.PENDING,
  type: 'Invite',
};

export const getId = (invite?: Invite) => (invite || defaults).id;

export const getEmail = (invite?: Invite) => (invite || defaults).email;

export const getFirstName = (invite?: Invite) => (invite || defaults).firstName;

export const getLastName = (invite?: Invite) => (invite || defaults).lastName;

export const getFullName = (invite?: Invite) =>
  formatFullName(getFirstName(invite), getLastName(invite));

export const getRole = (invite?: Invite) => (invite || defaults).role;

export const getFormattedRole = (invite?: Invite) =>
  translatedUserRoles[getRole(invite)];

export const getType = (invite?: Invite) => (invite || defaults).type;

export const getStoreId = (invite?: Invite) => (invite || defaults).storeId;

export const getExpiresOn = (invite?: Invite) => (invite || defaults).expiresOn;

export const isExpired = (invite?: Invite) => getExpiresOn(invite) < Date.now();

export const getSentOn = (invite?: Invite) => (invite || defaults).sentOn;

export const getProgress = (invite?: Invite) => (invite || defaults).progress;

export const getStatus = (invite?: Invite) => (invite || defaults).status;

export const isCompleted = (invite?: Invite) =>
  getStatus(invite) === InviteStatus.COMPLETE;

// Utils
export const serialize = (data?: Invite): Invite => {
  const invite = mergeRight(defaults, data || {});

  invite.expiresOn = toMillis(invite.expiresOn);
  invite.sentOn = toMillis(invite.sentOn);

  return invite;
};
