import { FC, MouseEventHandler, useState } from 'react';
import { apply, storeModel, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useSelector } from '../../../store';
import { AuthSelectors, ProspectSelectors } from '../../../store/selectors';

import { Collapsible, Heading, Icon, Text } from '../../../components';

import { prospectModel, prospectStoreModel } from '../../../models';
import { ProspectStatus } from '../../../enums';
import { ProspectStore } from '../../../models/prospectStoreModel';

const storeInfo = [
  {
    key: 'address',
    label: 'Address',
    getValue: (prospectStore?: ProspectStore) =>
      apply(storeModel.getAddress, prospectStore, ''),
  },
  {
    key: prospectStoreModel.fields.EMAIL,
    label: 'Email',
    getValue: (prospectStore?: ProspectStore) =>
      apply(storeModel.getEmail, prospectStore, ''),
  },
  {
    key: prospectStoreModel.fields.PHONE_NUMBER,
    label: 'Phone Number',
    getValue: (prospectStore?: ProspectStore) =>
      apply(storeModel.getFormattedPhoneNumber, prospectStore, ''),
  },
  {
    key: prospectStoreModel.fields.WEBSITE,
    label: 'Website',
    getValue: (prospectStore?: ProspectStore) =>
      apply(storeModel.getFormattedWebsite, prospectStore, ''),
  },
  {
    key: 'owner',
    label: 'Owner',
    getValue: (prospectStore?: ProspectStore) =>
      apply(prospectStoreModel.getOwnerInfo, prospectStore, ''),
  },
  {
    key: 'equipment',
    label: 'Equipment',
    getValue: (prospectStore?: ProspectStore) =>
      apply(prospectStoreModel.getEquipment, prospectStore, ''),
  },
];

const Wrapper = styled(Collapsible)`
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const SubType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-right: 24px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const Label = styled(Heading)`
  display: flex;
  flex: 1;
  margin-right: 18px;
`;

const Value = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
`;

const Actions = styled.div`
  display: flex;
  position: absolute;
  right: 0;
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  padding: 8px;
  cursor: pointer;
  border-radius: ${(props) =>
    props.theme.components.buttons.primary.borderRadius || 6}px;
  transition: 0.15s ease-in-out background-color;
  &:hover {
    background-color: ${(props) => props.theme.colors.grayOpaque};
  }
  margin-left: 16px;
  &:last-child {
    margin-right: 8px;
  }
`;

interface ItemProps {
  $first?: boolean;
  $last?: boolean;
}

const Row = styled(Header)<ItemProps>`
  align-items: center;
  justify-content: ${({ $last }) => ($last ? 'flex-end' : 'flex-start')};
  min-height: 56px;
  margin-top: ${({ $first }) => ($first ? '16px' : 0)};
  margin-right: 32px;
`;

interface Props {
  store: ProspectStore;
  onEdit?: (store: ProspectStore) => void;
  onDelete?: (store: ProspectStore) => void;
}

const StoreInfo: FC<Props> = (props) => {
  const user = useSelector(AuthSelectors.selectUser);
  const prospect = useSelector(ProspectSelectors.selectProspect);

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);
  const status = apply(
    prospectModel.getStatus,
    prospect,
    prospectModel.defaults.prospectStatus,
  );

  const { store, onEdit, onDelete } = props;
  const [open, setOpen] = useState(false);

  const handleEdit: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (onEdit) onEdit(store);
  };

  const handleDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (onDelete) onDelete(store);
  };

  const canEdit = isAdmin || isSales;
  const isTransformed = status === ProspectStatus.WON;

  return (
    <div>
      <Wrapper onStateChange={setOpen}>
        <Header>
          <SubType>
            <StyledIcon name="store" />
            <Text variant="body2">Prospect Store</Text>
          </SubType>
          <Label variant="h3">Name</Label>
          <Value>
            <Text variant="body2">{store?.storeName}</Text>
          </Value>
          <Actions>
            {open && canEdit && !isTransformed ? (
              <>
                <IconButton onClick={handleDelete}>
                  <Icon name="delete" />
                </IconButton>
                <IconButton onClick={handleEdit}>
                  <Icon name="edit" />
                </IconButton>
              </>
            ) : null}
          </Actions>
        </Header>
        {storeInfo.map(({ key, label, getValue }, index) => (
          <Row key={`${key}-${index}`} $first={index === 0}>
            <SubType />
            <Label variant="h3">{label}</Label>
            <Value>
              <Text variant="body2">{getValue(store)}</Text>
            </Value>
          </Row>
        ))}
      </Wrapper>
    </div>
  );
};

export default StoreInfo;
