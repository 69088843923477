import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apply, formatDate } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ContactActions, ProspectActions } from '../../../store/actions';
import { ProspectSelectors } from '../../../store/selectors';

import {
  defaultMetadata,
  fillPdfProspect,
  signPdf,
  SignPDFMetadata,
} from '../../../utils/pdfUtils';

import LoadingMask from '../../LoadingMask';
import Button from '../../Button';
import Icon from '../../Icon';
import IconButton from '../../IconButton';
import DocumentPagePreview from '../DocumentPagePreview';
import DocumentSignatures from '../DocumentSignatures';

import { DocumentContact } from '../types';
import { ProspectRouteParams } from '../../../views/Prospects/types';
import { prospectModel } from '../../../models';
import DocumentDialog from './DocumentDialog';
import DocumentPreview from '../DocumentPreview';

import agreementPDF from '../../../assets/pdf/ARIONHUB-Agreement.pdf';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
`;

const StyledDocumentPagePreview = styled(DocumentPagePreview)`
  z-index: 2;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const BottomButtonsWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 16px;
  right: 65px;
`;

const StyledPrintButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-right: 16px;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.shadow};
`;

const StyledOpenButton = styled(Button)`
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.shadow};
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.textTwo};
  cursor: pointer;
`;

const StyledLink = styled.a`
  display: none;
`;

interface SignBoxProps {
  isOpen: boolean;
}

const StyledDocumentSignatures = styled(DocumentSignatures)<SignBoxProps>`
  z-index: 1;
  height: ${(props) => (props.isOpen ? '425px' : 0)};
  transition: height 0.2s ease-out;
  overflow: hidden;
  @media (max-width: 1700px) {
    height: ${(props) => (props.isOpen ? '445px' : 0)};
  }
  & :nth-child(n) {
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    transition: opacity 0.2s ease-out;
  }
`;

interface BackButtonProps {
  $signBoxOpened: boolean;
}

const StyledBackButton = styled(Button)<BackButtonProps>`
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  color: ${(props) => props.theme.colors.text};
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: ${(props) => (props.$signBoxOpened ? '440px' : '16px')};
  @media (max-width: 1700px) {
    bottom: ${(props) => (props.$signBoxOpened ? '460px' : '16px')};
  }
  transition: bottom 0.2s ease-out;
  left: 250px;
`;

const StyledBackIcon = styled(Icon)`
  margin-right: 8px;
  color: ${(props) => props.theme.colors.primary};
`;

const Agreement: FC = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { prospectId } = useParams<ProspectRouteParams>();

  const prospect = useSelector(ProspectSelectors.selectProspect);
  const agreementLoading = useSelector(
    ProspectSelectors.selectAgreementLoading,
  );

  const agreementPath = apply(prospectModel.getAgreementPath, prospect, '');

  const [focusedPage, setFocusedPage] = useState(0);
  const [signBoxOpened, setSignBoxOpened] = useState(false);
  const [metadata, setMetadata] = useState(defaultMetadata);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);

  const [pdf, setPdf] = useState<{ data: Uint8Array } | null>(null);

  const downloadRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (prospect) {
      fillPdfProspect(prospect).then((pdf) => {
        setPdf({ data: pdf.pdfBytes });
      });
    }
  }, [prospect]);

  const getStoreContacts = useCallback(() => {
    if (prospectId) {
      dispatch(
        ContactActions.getContacts({
          parentId: prospectId,
          options: { isProspect: true },
        }),
      );
    }
  }, [dispatch, prospectId]);

  useEffect(() => {
    getStoreContacts();
  }, [getStoreContacts]);

  const handleDownload = async () => {
    if (!pdf) return;
    const signedPdf = await signPdf(pdf.data, metadata);
    if (downloadRef !== null && signedPdf !== null) {
      const blob = new Blob([signedPdf], { type: 'application/pdf' });
      const link = downloadRef.current;

      if (!link) return;

      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  };

  const handleSignatureMetadataChange = (key: string, data: string) => {
    const newMetadata = metadata;

    newMetadata[key as keyof SignPDFMetadata] = data;

    setMetadata(newMetadata);
  };

  const getAgreementName = useMemo(() => {
    const today = new Date();

    if (prospect) {
      const prospectName = prospectModel.getName(prospect);
      return `ARIONHUB Agreement ${prospectName} ${formatDate(today)}`;
    }
    return `ARIONHUB Agreement ${formatDate(today)}`;
  }, [prospect]);

  const handleAgreementSend = async (contacts: DocumentContact[]) => {
    if (!prospectId || !prospect) {
      return;
    }

    if (!agreementPath) {
      toast('Please save the agreement before trying to send it!', {
        type: 'error',
      });
      return;
    }

    dispatch(
      ProspectActions.sendAgreementToContactsProspect({
        prospectId,
        contacts,
      }),
    )
      .unwrap()
      .then(() => {
        toast('Agreement sent!', {
          type: 'success',
        });

        setSendDialogOpen(false);
      })
      .catch(() => {
        toast('Agreement failed to send!', {
          type: 'error',
        });

        setSendDialogOpen(false);
      });
  };

  const handleSave = async () => {
    if (!prospectId || !pdf) return;
    const signedPdf = await signPdf(pdf.data, metadata);

    await dispatch(
      ProspectActions.uploadAgreement({
        prospectId,
        data: signedPdf,
      }),
    )
      .unwrap()
      .then(() => {
        toast('Agreement saved!', {
          type: 'success',
        });
      });
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const handleSendClick = () => {
    if (!agreementPath) {
      toast('Please save the agreement before trying to send it!', {
        type: 'error',
      });
      return;
    }

    setSendDialogOpen(true);
  };

  return (
    <Wrapper>
      <LoadingMask loading={agreementLoading} />
      <StyledDocumentPagePreview
        onPageClick={setFocusedPage}
        file={agreementPDF}
      />
      <Content>
        <DocumentPreview
          templateFile={agreementPDF}
          pdf={pdf || undefined}
          focusedPage={focusedPage}
          signBoxOpened={signBoxOpened}
        />
        {!signBoxOpened && (
          <BottomButtonsWrapper>
            <StyledPrintButton color="secondary" onClick={handleDownload}>
              <StyledIcon name="download" />
            </StyledPrintButton>
            <StyledOpenButton onClick={() => setSignBoxOpened(true)}>
              Open Sign Box
            </StyledOpenButton>
          </BottomButtonsWrapper>
        )}
        <StyledBackButton
          variant="outlined"
          onClick={handleBackClick}
          $signBoxOpened={signBoxOpened}
        >
          <StyledBackIcon name={'chevron-back'} />
          Back
        </StyledBackButton>
        <StyledDocumentSignatures
          onChange={handleSignatureMetadataChange}
          onClose={() => setSignBoxOpened(false)}
          onSave={handleSave}
          onSend={handleSendClick}
          isOpen={signBoxOpened}
        />
      </Content>
      <StyledLink ref={downloadRef} download={getAgreementName} />
      <DocumentDialog
        open={sendDialogOpen}
        onConfirm={handleAgreementSend}
        onCancel={() => setSendDialogOpen(false)}
      />
    </Wrapper>
  );
};

export default Agreement;
