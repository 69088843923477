import { deleteUndefinedFields } from '@atogear/arion-utils';
import axios from 'axios';
import { isSameDay, isSameHour } from 'date-fns';

import {
  StaffData,
  serialize as serializeEmployeeData,
} from '../models/apiEmployeeInfoModel';
import {
  ApiGroup,
  serialize as serializeApiGroup,
} from '../models/apiGroupModel';
import {
  ApiSession,
  serialize as serializeSessionData,
} from '../models/apiSessionModel';
import {
  ApiVisit,
  serialize as serializeVisitData,
} from '../models/apiVisitModel';
import {
  ApiPerformance,
  serialize as serializeSessionPerformanceData,
} from '../models/apiPerformanceModel';

import { getDateByTimeFrameId } from '../utils/apiUtils';
import {
  formatDateByType,
  getDatesOfInterval,
  getDateTypeFromRange,
} from '../utils/dateUtils';

import {
  PerformanceStats,
  SessionStats,
  StatFilters,
  VisitStats,
} from '../types/stats';

import getAuthService from './auth';

interface APIResponse {
  data: any[];
  error?: Error;
}

const getAPIUrl = (path: string) =>
  `${process.env.REACT_APP_HUB_API}/admin/${path}`;

const getStatsService = () => {
  const getHeaders = async () => {
    const idToken = await getAuthService().getIdToken();

    return {
      Authorization: `Bearer ${idToken}`,
    };
  };

  const getStores = async (): Promise<ApiGroup[]> => {
    const headers = await getHeaders();

    const response = await axios.get<APIResponse>(getAPIUrl('getStores'), {
      headers,
    });

    if (response.data.error) {
      throw response.data.error;
    }

    return response.data.data.map(serializeApiGroup);
  };

  interface Options {
    noDemographics?: boolean;
    noSessionDetails?: boolean;
  }

  const getSessionStats = async (
    filters: StatFilters,
    options?: Options,
  ): Promise<SessionStats[]> => {
    const { storeIds, dateRange } = filters;

    const { from, to } = dateRange;

    if (!from || !to) {
      return [];
    }

    const dateType = getDateTypeFromRange({
      from,
      to,
    });

    const data = deleteUndefinedFields({
      startDate: from,
      endDate: to,
      dateType,
      stores: storeIds.length ? storeIds : undefined,
      options,
    });

    const headers = await getHeaders();

    const response = await axios.post<APIResponse>(
      getAPIUrl('getSessions'),
      data,
      {
        headers,
      },
    );

    if (response.data.error) {
      throw response.data.error;
    }

    const serializedSessions = response.data.data.map(serializeSessionData);

    const compareFn = dateType === 'hour' ? isSameHour : isSameDay;

    return getDatesOfInterval(dateType, {
      start: new Date(from),
      end: new Date(to),
    }).map((date) => {
      let sessionCount = 0;
      let stepCount = 0;

      const dateSessions: ApiSession[] = [];

      serializedSessions
        .filter(({ _id }) =>
          compareFn(getDateByTimeFrameId(dateType, _id), date),
        )
        .forEach(({ sessions }) =>
          sessions.forEach((session) => {
            sessionCount += session.entries;
            stepCount += session.numberOfSteps;

            dateSessions.push(session);
          }),
        );

      return {
        date: date.getTime(),
        formattedDate: formatDateByType(dateType, date),
        dateType,
        sessions: dateSessions.sort((a, b) => b.entries - a.entries),
        sessionCount,
        stepCount,
      };
    });
  };

  const getVisitStats = async (filters: StatFilters): Promise<VisitStats[]> => {
    const { storeIds, dateRange } = filters;

    const { from, to } = dateRange;

    if (!from || !to) {
      return [];
    }

    const dateType = getDateTypeFromRange({
      from,
      to,
    });

    const data = deleteUndefinedFields({
      startDate: from,
      endDate: to,
      dateType,
      stores: storeIds.length ? storeIds : undefined,
    });

    const headers = await getHeaders();

    const response = await axios.post<APIResponse>(
      getAPIUrl('getVisits'),
      data,
      {
        headers,
      },
    );

    if (response.data.error) {
      throw response.data.error;
    }

    const serializedVisits = response.data.data.map(serializeVisitData);

    const compareFn = dateType === 'hour' ? isSameHour : isSameDay;

    return getDatesOfInterval(dateType, {
      start: new Date(from),
      end: new Date(to),
    }).map((date) => {
      let dateAvg = {};

      let dateTotals = {
        shoesSold: 0,
        shoesTried: 0,
        visits: 0,
        visitsShared: 0,
      };

      let numberOfSessions = 0;

      const dateVisits: ApiVisit[] = [];

      serializedVisits
        .filter(({ _id }) =>
          compareFn(getDateByTimeFrameId(dateType, _id), date),
        )
        .forEach(({ avg, totals, visits }) => {
          visits.forEach((visit) => {
            dateAvg = avg;
            dateTotals = totals;

            numberOfSessions += visit.numberOfSessions;

            dateVisits.push(visit);
          });
        });

      return {
        date: date.getTime(),
        formattedDate: formatDateByType(dateType, date),
        dateType,
        avg: dateAvg,
        totals: dateTotals,
        numberOfSessions,
        numberOfVisits: dateVisits.length,
        visits: dateVisits,
      };
    });
  };

  const getStaffStats = async (filters: StatFilters): Promise<StaffData[]> => {
    const { storeIds, dateRange } = filters;

    const { from, to } = dateRange;

    if (!from || !to) {
      return [];
    }

    const dateType = getDateTypeFromRange({
      from,
      to,
    });

    const data = deleteUndefinedFields({
      startDate: from,
      endDate: to,
      dateType,
      stores: storeIds.length ? storeIds : undefined,
    });

    const headers = await getHeaders();

    const response = await axios.post<APIResponse>(
      getAPIUrl('getEmployeeInfo'),
      data,
      {
        headers,
      },
    );

    if (response.data.error) {
      throw response.data.error;
    }

    const serializedEmployeeInfo = response.data.data.map(
      serializeEmployeeData,
    );

    return serializedEmployeeInfo;
  };

  interface PerformanceOptions {
    sessionType?: 'running' | 'walking';
  }

  const getPerformanceStats = async (
    filters: StatFilters,
    options?: PerformanceOptions,
  ): Promise<PerformanceStats[]> => {
    const { storeIds, dateRange } = filters;

    const { from, to } = dateRange;

    if (!from || !to) {
      return [];
    }

    const dateType = getDateTypeFromRange({
      from,
      to,
    });

    const data = deleteUndefinedFields({
      startDate: from,
      endDate: to,
      dateType,
      stores: storeIds.length ? storeIds : undefined,
      options,
    });

    const headers = await getHeaders();

    const response = await axios.post<APIResponse>(
      getAPIUrl('getPerformance'),
      data,
      {
        headers,
      },
    );

    if (response.data.error) {
      throw response.data.error;
    }

    const serializedPerformances = response.data.data.map(
      serializeSessionPerformanceData,
    );

    const compareFn = dateType === 'hour' ? isSameHour : isSameDay;

    return getDatesOfInterval(dateType, {
      start: new Date(from),
      end: new Date(to),
    }).map((date) => {
      let sessionCount = 0;

      const dateSessionsPerformance: ApiPerformance[] = [];

      serializedPerformances
        .filter(({ _id }) =>
          compareFn(getDateByTimeFrameId(dateType, _id), date),
        )
        .forEach(({ sessions }) =>
          sessions.forEach((session) => {
            sessionCount += session.numberOfSessions;

            dateSessionsPerformance.push(session);
          }),
        );

      return {
        date: date.getTime(),
        formattedDate: formatDateByType(dateType, date),
        dateType,
        sessions: dateSessionsPerformance.sort(
          (a, b) => b.numberOfSessions - a.numberOfSessions,
        ),
        sessionCount,
      };
    });
  };

  return {
    getStores,
    getSessionStats,
    getVisitStats,
    getStaffStats,
    getPerformanceStats,
  };
};

export default getStatsService;
