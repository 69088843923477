import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  isNilOrWhitespace,
  User,
  userModel,
  UserRoles,
} from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch } from '../../../../store';
import { StoreAccountActions } from '../../../../store/actions';

import { translatedUserRoles } from '../../../../translations';

import {
  Button,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
  DialogProps,
  DialogSelect,
  Icon,
  Option,
} from '../../../../components';

import { StoreRouteParams } from '../../types';

const getRoleOptions = (member: User) => {
  const roleOptions = [
    {
      key: UserRoles.MANAGER,
      label: translatedUserRoles[UserRoles.MANAGER],
      value: UserRoles.MANAGER,
    },
    {
      key: UserRoles.EMPLOYEE,
      label: translatedUserRoles[UserRoles.EMPLOYEE],
      value: UserRoles.EMPLOYEE,
    },
  ];

  if (userModel.isOwner(member)) {
    roleOptions.push({
      key: UserRoles.OWNER,
      label: translatedUserRoles[UserRoles.OWNER],
      value: UserRoles.OWNER,
    });
  }

  if (
    member &&
    userModel.hasRole(
      [UserRoles.ADMIN, UserRoles.BACK_OFFICE, UserRoles.GROUP_MANAGER],
      member,
    )
  ) {
    roleOptions.unshift({
      key: UserRoles.GROUP_MANAGER,
      label: translatedUserRoles[UserRoles.GROUP_MANAGER],
      value: UserRoles.GROUP_MANAGER,
    });
  }

  return roleOptions;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const SecondaryButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

const StyledDialog = styled(Dialog)`
  min-width: 400px;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.danger};
  margin-right: 4px;
`;

interface Props extends DialogProps {
  member?: User;
  isUser?: boolean;
  isAllowedToEdit?: boolean;
  onDelete?: () => void;
}

const EditMemberDialog: FC<Props> = ({
  loading,
  open,
  member: propMember,
  isUser,
  onClose,
  onDelete,
}) => {
  const dispatch = useDispatch();

  const { storeId } = useParams<StoreRouteParams>();

  const [member, setMember] = useState(propMember || userModel.create());

  const [errors, setErrors] = useState({
    email: false,
    firstName: false,
    lastName: false,
  });

  useEffect(() => {
    if (open && propMember) {
      setMember(propMember);

      setErrors({
        email: false,
        firstName: false,
        lastName: false,
      });
    }
  }, [open, propMember]);

  const handleUpdate: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      if (!storeId) {
        return;
      }

      await dispatch(
        StoreAccountActions.updateStoreAccount({
          userId: userModel.getId(member),
          data: {
            firstName: userModel.getFirstName(member),
            lastName: userModel.getLastName(member),
            role: userModel.getRole(member),
          },
        }),
      ).unwrap();

      toast('Successfully updated member!', {
        type: 'success',
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem updating the member. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  const validate = (key: string) => (value: string) => {
    const valid = !isNilOrWhitespace(value);

    setErrors((prev) => ({
      ...prev,
      [key]: !valid,
    }));

    return valid;
  };

  const handleChange =
    (key: string): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      setMember((prev) => ({
        ...prev,
        [key]: e.target.value,
      }));
    };

  const handleChangeRole = (option: Option | null) => {
    setMember((prev) => ({
      ...prev,
      role: option?.value || UserRoles.EMPLOYEE,
    }));
  };

  return (
    <StyledDialog
      open={open}
      loading={loading}
      title="Edit team member"
      onClose={onClose}
    >
      <Form onSubmit={handleUpdate}>
        <Wrapper>
          <Group>
            <StyledInputLabel htmlFor="email">Email</StyledInputLabel>
            <DialogInput
              disabled={true}
              id="email"
              placeholder="Email"
              value={userModel.getEmail(member)}
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor="first-name">First name</StyledInputLabel>
            <DialogInput
              id="first-name"
              placeholder="First name"
              value={userModel.getFirstName(member)}
              validate={validate(userModel.fields.FIRST_NAME)}
              onChange={handleChange(userModel.fields.FIRST_NAME)}
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor="last-name">Last name</StyledInputLabel>
            <DialogInput
              id="last-name"
              placeholder="Last name"
              value={userModel.getLastName(member)}
              validate={validate(userModel.fields.LAST_NAME)}
              onChange={handleChange(userModel.fields.LAST_NAME)}
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor="role">Role</StyledInputLabel>
            <DialogSelect
              id="role"
              disabled={
                userModel.isOwner(member) || userModel.isGroupManager(member)
              }
              options={getRoleOptions(member)}
              value={userModel.getRole(member)}
              onChange={handleChangeRole}
            />
          </Group>
        </Wrapper>
        <DialogActions>
          <StyledActions>
            {onDelete && !isUser && (
              <SecondaryButton
                size="small"
                type="button"
                variant="outlined"
                onClick={onDelete}
              >
                <StyledIcon name="delete" />
                Delete
              </SecondaryButton>
            )}
            <ActionContainer>
              <SecondaryButton
                size="small"
                type="button"
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </SecondaryButton>
              <Button
                color="primary"
                disabled={Object.values(errors).some((value) => value === true)}
                size="small"
                variant="contained"
                type="submit"
              >
                Update
              </Button>
            </ActionContainer>
          </StyledActions>
        </DialogActions>
      </Form>
    </StyledDialog>
  );
};

export default EditMemberDialog;
