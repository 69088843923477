import { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ImageProps {
  $background?: string;
}

const Image = styled.img<ImageProps>`
  background-color: ${({ $background }) => $background};
  border-radius: 4px;
  object-fit: cover;
`;

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  background?: string;
}

const ImagePreview: FC<Props> = ({ background, ...rest }) => (
  <Image $background={background} {...rest} />
);

export default ImagePreview;
