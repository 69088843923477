import { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

interface WrapperProps {
  $color?: string;
  $fontSize?: number;
}

const Wrapper = styled.span<WrapperProps>`
  font-size: ${(props) =>
    props.$fontSize ? `${props.$fontSize}px` : 'inherit'};

  &::before {
    color: ${(props) => props.$color || 'inherit'};
  }
`;

interface Props extends HTMLAttributes<HTMLSpanElement> {
  name: string;
  fontSize?: number;
}

const Icon = forwardRef<HTMLSpanElement, Props>(
  ({ className, color, name, fontSize, ...rest }, ref) => (
    <Wrapper
      className={clsx(`icon-${name}`, className)}
      ref={ref}
      $color={color}
      $fontSize={fontSize}
      {...rest}
    />
  ),
);

export default Icon;
