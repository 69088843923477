import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch } from '../../../store';
import { EventActions } from '../../../store/actions';
import { EventSelectors, ProspectSelectors } from '../../../store/selectors';

import { prospectActivityColumns } from '../../../tables/activityTable';
import { composeColumns } from '../../../utils/tableUtils';

import { Heading, Table, Text } from '../../../components';
import { TableCellData } from '../../../components/Table/types';

import { prospectModel } from '../../../models';
import {
  Event,
  fields,
  getFormattedDateString,
  getUserName,
} from '../../../models/eventModel';
import EventCell from '../../Stores/Activity/EventCell';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledTable = styled(Table)`
  max-height: calc(100vh - 400px);
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const cells: TableCellData<Event>[] = [
  {
    key: fields.ID,
    renderBody: (event) => <EventCell event={event} />,
  },
  {
    key: fields.DATE,
    renderBody: (event) => (
      <Text variant="body2">{getFormattedDateString(event)}</Text>
    ),
  },
  {
    key: fields.USER_NAME,
    renderBody: (event) => <Text variant="body2">{getUserName(event)}</Text>,
  },
];

const columns = composeColumns(prospectActivityColumns, cells);

const Activity: FC = () => {
  const dispatch = useDispatch();

  const prospect = useSelector(ProspectSelectors.selectProspect);
  const events = useSelector(EventSelectors.selectEvents);
  const fetching = useSelector(EventSelectors.selectEventsFetching);

  const getEvents = useCallback(async () => {
    try {
      if (prospect) {
        await dispatch(
          EventActions.getEvents({
            parentId: prospectModel.getId(prospect),
            options: { isProspect: true },
          }),
        ).unwrap();
      }
    } catch {
      toast('Failed to retrieve recent activity!', {
        type: 'error',
      });
    }
  }, [dispatch, prospect]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Recent activity</ContentHeading>
          <Text variant="body2">Track changes made to your prospect.</Text>
        </Info>
      </Header>
      <Content>
        <StyledTable
          columns={columns}
          data={events}
          loading={fetching}
          initialSortBy={{
            key: fields.DATE,
            desc: true,
          }}
        />
      </Content>
    </Wrapper>
  );
};

export default Activity;
