import { FC } from 'react';
import styled from 'styled-components';

import { Text } from '../../components';
import Auth from './Auth';

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

const OnboardingComplete: FC = () => (
  <Auth title="Account creation completed">
    <StyledText variant="body2">
      Thank you for completing your ARIONHUB account.
    </StyledText>
    <Text variant="body2">
      Now you can start using the ARIONHUB app with many features available to
      create an unforgettable in-store experience for your customer.
    </Text>
  </Auth>
);

export default OnboardingComplete;
