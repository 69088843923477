import { ChangeEventHandler, FC, useEffect, useId, useState } from 'react';
import { apply, isNilOrWhitespace } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { GroupActions, ProspectActions } from '../../../store/actions';
import { ProspectSelectors } from '../../../store/selectors';

import {
  Button,
  Heading,
  Icon,
  Input,
  InputLabel,
  ModuleChip,
  Option,
  Select,
  Switch,
  Text,
} from '../../../components';

import { prospectModel } from '../../../models';

import { translatedGroupType, translatedProduct } from '../../../translations';
import { GroupType, Product } from '../../../enums';
import { toggleProduct } from '../../../models/prospectModel';

const TYPE_OPTIONS = Object.entries(translatedGroupType).map((type) => ({
  key: type[0],
  label: type[1],
  value: type[0],
}));

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 48px;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 16px;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 22px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 16px;
`;

const ProductsRow = styled(Row)`
  justify-content: flex-start;
`;

const SearchIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTwo};
  font-size: 14px;
`;

const SwitchInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SwitchLabel = styled(Heading)`
  width: 26px;
  margin-left: 10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 60px 48px 60px;
`;

interface Props {
  onNext: () => void;
}

const General: FC<Props> = ({ onNext }) => {
  const dispatch = useDispatch();

  const prospect = useSelector(ProspectSelectors.selectProspectPreview);

  const [name, setName] = useState('');
  const [type, setType] = useState(GroupType.BRAND);
  const [products, setProducts] = useState<Product[]>([Product.HUB]);
  const [priority, setPriority] = useState(false);

  useEffect(() => {
    setName(apply(prospectModel.getName, prospect, ''));
    setType(
      apply(
        prospectModel.getType,
        prospect,
        prospectModel.getType(prospectModel.defaults),
      ),
    );
    setProducts(
      apply(
        prospectModel.getProducts,
        prospect,
        prospectModel.getProducts(prospectModel.defaults),
      ),
    );
    setPriority(
      apply(
        prospectModel.hasPriority,
        prospect,
        prospectModel.hasPriority(prospectModel.defaults),
      ),
    );
  }, [prospect]);

  useEffect(() => {
    dispatch(GroupActions.getAccessibleGroups());
  }, [dispatch]);

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setName(e.target.value);

  const handleTypeChange = (option: Option | null) => {
    const value = option?.value || type;
    setType(value);
  };

  const handleProductChange = (product: Product) => {
    const value = toggleProduct(product, products);

    setProducts(value);
  };

  const handlePriorityChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setPriority(e.target.checked);

  const handleNextClick = () => {
    dispatch(
      ProspectActions.prospectPreviewUpdated({
        name,
        type,
        products,
        priority,
      }),
    );

    onNext();
  };

  // TODO: Validate on more than just empty strings
  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const id = useId();
  const nameId = `${id}-name`;
  const typeId = `${id}-type`;
  const productId = `${id}-product`;
  const priorityId = `${id}-priority`;

  const disabled = ![name, type].every(handleValidate) || products.length < 1;

  return (
    <Wrapper>
      <Content>
        <Form>
          <StyledHeading variant="h1">Which prospect is it?</StyledHeading>
          <StyledText variant="body2">
            Input the name of your prospect, its type, product offered and
            priority.
          </StyledText>
          <StyledInputLabel htmlFor={nameId} variant="h3">
            Name
          </StyledInputLabel>
          <StyledInput
            id={nameId}
            required
            validate={handleValidate}
            value={name}
            onChange={handleNameChange}
          />
          <StyledInputLabel htmlFor={typeId} variant="h3">
            Type
          </StyledInputLabel>
          <StyledSelect
            id={typeId}
            startAdornment={<SearchIcon name="search" />}
            options={TYPE_OPTIONS}
            value={type}
            onChange={handleTypeChange}
          />
          <StyledInputLabel htmlFor={productId} variant="h3">
            Product/s
          </StyledInputLabel>
          <ProductsRow>
            {Object.values(Product).map((product) => (
              <ModuleChip
                label={translatedProduct[product]}
                key={`product-${product}`}
                selected={products.includes(product)}
                onClick={() => handleProductChange(product)}
              />
            ))}
          </ProductsRow>

          <Row>
            <InputLabel htmlFor={priorityId} variant="h3">
              Priority
            </InputLabel>
            <SwitchInput>
              <Switch
                checked={priority}
                componentsProps={{
                  input: {
                    id: priorityId,
                  },
                }}
                onChange={handlePriorityChange}
              />
              <SwitchLabel variant="h4">{priority ? 'Yes' : 'No'}</SwitchLabel>
            </SwitchInput>
          </Row>
        </Form>
      </Content>
      <Buttons>
        <Button disabled={disabled} onClick={handleNextClick}>
          Next
        </Button>
      </Buttons>
    </Wrapper>
  );
};

export default General;
