import { FC, useState } from 'react';
import { apply, storeModel, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useSelector } from '../../../store';
import { AuthSelectors, StoreSelectors } from '../../../store/selectors';

import { Button, Heading, Icon, Text } from '../../../components';
import PaymentDialog from './PaymentDialog';
import PaymentInfo from './PaymentInfo';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`;

const ErrorMessage = styled(Heading)`
  color: ${(props) => props.theme.colors.danger};
  text-decoration: underline;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const ButtonIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 17px;
  margin-right: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Payment: FC = () => {
  const user = useSelector(AuthSelectors.selectUser);
  const store = useSelector(StoreSelectors.selectStore);

  const hasBillingInfo = apply(
    storeModel.hasBillingInfo,
    store,
    storeModel.hasBillingInfo(storeModel.defaults),
  );
  const isInactive = apply(
    storeModel.isInactive,
    store,
    storeModel.isInactive(storeModel.defaults),
  );
  const isDistributorSubscription = apply(
    storeModel.isDistributorSubscription,
    store,
    false,
  );

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isPowerUser = apply(userModel.isPowerUser, user, false);
  const isOwner = apply(userModel.isOwner, user, false);
  const isGroupManager = apply(userModel.isGroupManager, user, true);

  const isSalesRep =
    !!user && !!store ? storeModel.isSalesRep(user, store) : false;

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const openEditDialog = () => setEditDialogOpen(true);

  const closeEditDialog = () => setEditDialogOpen(false);

  const canAddPaymentInfo = isPowerUser || isSalesRep;

  const canEditPaymentInfo =
    canAddPaymentInfo &&
    isInactive &&
    hasBillingInfo &&
    !isDistributorSubscription;

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Payments</ContentHeading>
          <Text variant="body2">
            Preview your payment details and agreement. If you wish to modify
            your current payment plan, please contact your ATOGEAR sales
            representative.
          </Text>
        </Info>
        {canEditPaymentInfo && (
          <EditButton>
            <Button variant="outlined" onClick={openEditDialog}>
              <ButtonIcon name="edit" />
              Edit payment plan
            </Button>
          </EditButton>
        )}
      </Header>
      <Content>
        {isDistributorSubscription ? (
          <ErrorMessage variant="h3">
            {isAdmin
              ? 'This store was created by a distributor and cannot be assigned payment information!'
              : isOwner || isGroupManager
              ? 'Your payment information is handled by your distributor'
              : ''}
          </ErrorMessage>
        ) : !hasBillingInfo ? (
          canAddPaymentInfo && (
            <div>
              <Button
                variant="outlined"
                onClick={openEditDialog}
                disabled={isDistributorSubscription}
              >
                <ButtonIcon name="edit" />
                Create payment plan
              </Button>
            </div>
          )
        ) : (
          <PaymentInfo open />
        )}
      </Content>
      {(canAddPaymentInfo || canEditPaymentInfo) && (
        <PaymentDialog
          store={store}
          open={editDialogOpen}
          onClose={closeEditDialog}
        />
      )}
    </Wrapper>
  );
};

export default Payment;
