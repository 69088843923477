import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { Store, storeModel } from '@atogear/arion-utils';

import { Invoice } from '../../models/invoiceModel';
import { sendOwnerInvite } from '../onboarding/thunks';
import { removeStoreOwnerAccount } from '../storeAccounts/thunks';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import { reset, storePreviewReset, storePreviewUpdated } from './actions';
import {
  cancelSubscription,
  createStore,
  deleteStore,
  downloadAgreement,
  getAllStores,
  getInvoices,
  getStore,
  getStores,
  reactivateSubscription,
  sendAgreementToContacts,
  updateStore,
  upgradeStore,
  uploadAgreement,
} from './thunks';

interface State {
  stores: {
    data: Store[];
    fetching: boolean;
    error?: SerializedError;
  };
  store: {
    data?: Store;
    preview?: Store;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  invoices: {
    data: Invoice[];
    fetching: boolean;
    error?: SerializedError;
  };
  agreement: {
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  stores: {
    data: [],
    fetching: false,
    error: undefined,
  },
  store: {
    data: undefined,
    preview: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
  invoices: {
    data: [],
    fetching: false,
    error: undefined,
  },
  agreement: {
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get All Stores
    .addCase(getAllStores.pending, setFetchingState('stores'))
    .addCase(getAllStores.fulfilled, (state, { payload }) => {
      state.stores.data = payload;
      setFulfilledState('stores')(state);
    })
    .addCase(getAllStores.rejected, setRejectedState('stores'))
    // Get Stores
    .addCase(getStores.pending, setFetchingState('stores'))
    .addCase(getStores.fulfilled, (state, { payload }) => {
      state.stores.data = payload;
      setFulfilledState('stores')(state);
    })
    .addCase(getStores.rejected, setRejectedState('stores'))
    // Get Store
    .addCase(getStore.pending, setFetchingState('store'))
    .addCase(getStore.fulfilled, (state, { payload }) => {
      state.store.data = payload;
      state.store.preview = payload;
      setFulfilledState('store')(state);
    })
    .addCase(getStore.rejected, setRejectedState('store'))
    // Update Store
    .addCase(updateStore.pending, setUpdatingState('store'))
    .addCase(updateStore.fulfilled, (state, { payload }) => {
      state.store.data = payload;
      state.store.preview = payload;
      setFulfilledState('store')(state);
    })
    .addCase(updateStore.rejected, setRejectedState('store'))
    // Upgrade Store
    .addCase(upgradeStore.pending, setUpdatingState('store'))
    .addCase(upgradeStore.fulfilled, (state, { payload }) => {
      state.store.data = payload;
      state.store.preview = payload;
      setFulfilledState('store')(state);
    })
    .addCase(upgradeStore.rejected, setRejectedState('store'))
    // Delete Store
    .addCase(deleteStore.pending, setUpdatingState('store'))
    .addCase(deleteStore.fulfilled, (state) => {
      state.store.data = undefined;
      state.store.preview = undefined;
      setFulfilledState('store')(state);
    })
    .addCase(deleteStore.rejected, setRejectedState('store'))
    // Create Store
    .addCase(createStore.pending, setUpdatingState('store'))
    .addCase(createStore.fulfilled, (state) => {
      state.store.preview = undefined;
      setFulfilledState('store')(state);
    })
    .addCase(createStore.rejected, setRejectedState('store'))
    // Cancel Subscription
    .addCase(cancelSubscription.pending, setUpdatingState('store'))
    .addCase(cancelSubscription.fulfilled, (state, { payload }) => {
      state.store.data = payload;
      state.store.preview = payload;
      setFulfilledState('store')(state);
    })
    .addCase(cancelSubscription.rejected, setRejectedState('store'))
    // Reactivate Subscription
    .addCase(reactivateSubscription.pending, setUpdatingState('store'))
    .addCase(reactivateSubscription.fulfilled, (state, { payload }) => {
      state.store.data = payload;
      state.store.preview = payload;
      setFulfilledState('store')(state);
    })
    .addCase(reactivateSubscription.rejected, setRejectedState('store'))
    // Get Invoices
    .addCase(getInvoices.pending, setFetchingState('invoices'))
    .addCase(getInvoices.fulfilled, (state, { payload }) => {
      state.invoices.data = payload;
      setFulfilledState('invoices')(state);
    })
    .addCase(getInvoices.rejected, setRejectedState('invoices'))
    // Upload Agreement
    .addCase(uploadAgreement.pending, setUpdatingState('agreement'))
    .addCase(uploadAgreement.fulfilled, setFulfilledState('agreement'))
    .addCase(uploadAgreement.rejected, setRejectedState('agreement'))
    // Download Agreement
    .addCase(downloadAgreement.pending, setFetchingState('agreement'))
    .addCase(downloadAgreement.fulfilled, setFulfilledState('agreement'))
    .addCase(downloadAgreement.rejected, setRejectedState('agreement'))
    // Send Agreement
    .addCase(sendAgreementToContacts.pending, setFetchingState('agreement'))
    .addCase(sendAgreementToContacts.fulfilled, setFulfilledState('agreement'))
    .addCase(sendAgreementToContacts.rejected, setRejectedState('agreement'))
    // Local
    .addCase(storePreviewUpdated, ({ store }, { payload }) => {
      store.preview = storeModel.create({
        ...store.preview,
        ...payload,
      });
    })
    .addCase(storePreviewReset, ({ store }) => {
      store.preview = undefined;
    })
    // Remote Slice updates
    // send Owner Invite
    .addCase(sendOwnerInvite.fulfilled, (state, { payload }) => {
      if (state.store.data)
        state.store.data = {
          ...state.store.data,
          ownerId: payload.ownerId,
        };

      if (state.store.preview)
        state.store.preview = {
          ...state.store.preview,
          ownerId: payload.ownerId,
        };
    })
    // remove Owner
    .addCase(removeStoreOwnerAccount.fulfilled, (state, { payload }) => {
      if (state.store.data)
        state.store.data = {
          ...state.store.data,
          ownerId: payload.ownerId,
        };

      if (state.store.preview)
        state.store.preview = {
          ...state.store.preview,
          ownerId: payload.ownerId,
        };
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
