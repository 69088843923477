import { Store, storeModel, User, userModel } from '@atogear/arion-utils';
import {
  getFirebaseFunctionsPipe,
  getFirestorePipe,
} from '@atogear/ato-broker';

import {
  HttpsCallableResult,
  RemoveStoreOwnerResult,
  StoreAccountResult,
  UpdateStoreAccountData,
} from './types';

const COLL_PATH = 'users';

const getStoreAccountService = () => {
  /**
   * Get Store Accounts.
   *
   * @param store Store's data.
   * @returns Promise that resolves to Store Accounts.
   */
  const getStoreAccounts = async (store: Store): Promise<User[]> => {
    const firestore = getFirestorePipe();

    const docs = await firestore.getDocuments(COLL_PATH, {
      where: {
        fieldName: userModel.fields.STORE_ID,
        operator: '==',
        fieldValue: storeModel.getId(store),
      },
    });

    const storeAccounts = docs.map(userModel.serialize);

    const ownerId = storeModel.getOwnerId(store);

    if (!ownerId || storeAccounts.some((u) => userModel.getId(u) === ownerId)) {
      return storeAccounts;
    }

    const owner = await firestore.getDocument(COLL_PATH, ownerId);

    if (owner) {
      storeAccounts.push(userModel.serialize(owner));
    }

    return storeAccounts;
  };

  /**
   * Get Store Account.
   *
   * @param userId Store Account's id.
   * @returns Promise that resolves to Store Account.
   */
  const getStoreAccount = async (userId: string): Promise<User> => {
    const storeAccount = await getFirestorePipe().getDocument(
      COLL_PATH,
      userId,
    );

    if (!storeAccount) {
      throw new Error('User not found!');
    }

    return userModel.serialize(storeAccount);
  };

  /**
   * Update Store Account.
   *
   * @param userId Store Account's id.
   * @param data Store Account's data.
   * @returns Promise that resolves to updated Store Account.
   */
  const updateStoreAccount = (
    userId: string,
    data: UpdateStoreAccountData,
  ): Promise<StoreAccountResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-updateUser', {
      employeeId: userId,
      employeeData: data,
    });

  /**
   * Remove Store Account.
   *
   * @param userId Store Account's id.
   * @returns Promise that resolves if Store Account has been removed successfully.
   */
  const removeStoreAccount = (userId: string): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-removeEmployee', {
      employeeId: userId,
    });

  /**
   * Remove Store Owner.
   *
   * @param storeId Store id.
   * @returns Promise that resolves if Store Owner has been removed successfully.
   */
  const removeStoreOwner = (storeId: string): Promise<RemoveStoreOwnerResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-removeStoreOwner', {
      storeId,
    });

  return {
    getStoreAccounts,
    getStoreAccount,
    updateStoreAccount,
    removeStoreAccount,
    removeStoreOwner,
  };
};

export default getStoreAccountService;
