import { FC } from 'react';

import { getFormattedDate, Lead } from '../../../models/leadModel';

import LeadWrapper from './Lead';

interface Props {
  lead: Lead;
}

const Instant: FC<Props> = ({ lead }) => (
  <LeadWrapper lead={lead}>{getFormattedDate(lead)}</LeadWrapper>
);

export default Instant;
