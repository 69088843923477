import {
  TableCellData,
  TableColumn,
  TableColumnData,
} from '../components/Table/types';

interface WithKey {
  key: string;
}

const byKey =
  (key: string) =>
  <T extends WithKey>(el: T) =>
    el.key === key;

export const findByKey = <T extends WithKey>(arr: T[], key: string) =>
  arr.find(byKey(key));

export const findIndexByKey = <T extends WithKey>(arr: T[], key: string) =>
  arr.findIndex(byKey(key));

export const filterByKey = <T extends WithKey>(arr: T[], key: string) =>
  arr.filter((el) => el.key !== key);

export const filterByKeys = <T extends WithKey>(arr: T[], keys: string[]) =>
  arr.filter((el) => !keys.includes(el.key));

export const hasKey = <T extends WithKey>(arr: T[], key: string) =>
  arr.some(byKey(key));

export const allHaveKey = <T extends WithKey>(arr: T[], key: string) =>
  arr.every(byKey(key));
export const sortColumns =
  <T>(tableColumns: TableColumn<T>[]) =>
  (a: TableColumn<T>, b: TableColumn<T>) =>
    findIndexByKey(tableColumns, a.key) - findIndexByKey(tableColumns, b.key);

export const composeColumns = (
  columns: TableColumnData[],
  cells: TableCellData[],
): TableColumn[] => {
  return columns.map((column) => {
    const cell = cells.find((c) => c.key === column.key);

    return cell
      ? {
          ...column,
          renderBody: cell.renderBody,
        }
      : column;
  });
};
