import { RootState } from '../types';

export const selectThemes = (state: RootState) => state.themes.themes.data;

export const selectThemesFetching = (state: RootState) =>
  state.themes.themes.fetching;

export const selectTheme = (state: RootState) => state.themes.theme.data;

export const selectThemeFetching = (state: RootState) =>
  state.themes.theme.fetching;

export const selectThemeUpdating = (state: RootState) =>
  state.themes.theme.updating;

export const selectThemePreview = (state: RootState) =>
  state.themes.theme.preview;

export const selectThemeEditorState = (state: RootState) =>
  state.themes.editorState;

export const selectDarkTheme = (state: RootState) =>
  state.themes.darkTheme.data;

export const selectLightTheme = (state: RootState) =>
  state.themes.lightTheme.data;

export const selectCurrentThemes = (state: RootState) => {
  const currentThemes = [];
  const light = state.themes.lightTheme.data;
  const dark = state.themes.darkTheme.data;

  if (light) currentThemes.push(light);
  if (dark) currentThemes.push(dark);
  return currentThemes;
};
