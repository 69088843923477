import { getFirestorePipe } from '@atogear/ato-broker';

import { DefaultThemeIds } from '../enums';
import { serialize } from '../models/themeModel';

import { Theme } from '../theme/types';

const THEME_COLLECTION_PATH = 'themes';

interface StoreThemesType {
  darkTheme?: Theme;
  lightTheme?: Theme;
}

const getGlobalThemeService = () => {
  const getGlobalThemes = async (): Promise<StoreThemesType> => {
    const darkTheme = await getFirestorePipe().getDocument(
      THEME_COLLECTION_PATH,
      DefaultThemeIds.ARION_DARK,
    );

    const lightTheme = await getFirestorePipe().getDocument(
      THEME_COLLECTION_PATH,
      DefaultThemeIds.ARION_LIGHT,
    );

    const defaultThemes: StoreThemesType = {
      darkTheme: undefined,
      lightTheme: undefined,
    };

    if (darkTheme) {
      defaultThemes.darkTheme = serialize(darkTheme);
    }

    if (lightTheme) {
      defaultThemes.lightTheme = serialize(lightTheme);
    }

    return defaultThemes;
  };

  const updateGlobalTheme = (themeId: string, data: Partial<Theme>) =>
    getFirestorePipe().editDocument(THEME_COLLECTION_PATH, themeId, data);

  return {
    getGlobalThemes,
    updateGlobalTheme,
  };
};

export default getGlobalThemeService;
