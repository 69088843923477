import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../store';
import { OnboardingActions } from '../../store/actions';
import { OnboardingSelectors } from '../../store/selectors';

import { isCompleted } from '../../models/inviteModel';

import { unauthenticatedRoutes } from '../../routing';

import { Button, Input, InputLabel, Text } from '../../components';
import Auth from './Auth';

interface RouteParams {
  token?: string;
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

const HiddenInput = styled(Input)`
  display: none;
`;

const StyledInput = styled(Input)`
  margin-bottom: 32px;
`;

const SubmitButton = styled(Button)`
  width: 120px;
`;

const Onboarding: FC = () => {
  const dispatch = useDispatch();

  const invite = useSelector(OnboardingSelectors.selectInvite);
  const fetching = useSelector(OnboardingSelectors.selectInviteFetching);
  const updating = useSelector(OnboardingSelectors.selectInviteUpdating);

  const history = useHistory();

  const { token } = useParams<RouteParams>();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const init = useCallback(async () => {
    try {
      if (token) {
        await dispatch(OnboardingActions.getInvite(token)).unwrap();
      }
    } catch (error) {
      toast((error as Error)?.message || 'Invalid invite!', {
        type: 'error',
      });
    }
  }, [dispatch, token]);

  useEffect(() => {
    init();
  }, [init]);

  const goToNextStep = useCallback(() => {
    history.push(unauthenticatedRoutes.onboardingComplete.path);
  }, [history]);

  useEffect(() => {
    if (invite && isCompleted(invite)) {
      goToNextStep();
    }
  }, [goToNextStep, invite]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      if (!token) {
        return;
      }

      await dispatch(
        OnboardingActions.createStoreAccount({
          password,
          confirmPassword,
          inviteId: token,
        }),
      ).unwrap();

      toast('Successfully created account!', {
        type: 'success',
      });

      goToNextStep();
    } catch (error) {
      toast((error as Error)?.message || 'Failed to create account!', {
        type: 'error',
      });
    }
  };

  const handleNewPasswordChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setPassword(e.target.value);

  const handleConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => setConfirmPassword(e.target.value);

  const disabled = !invite;

  return (
    <Auth loading={fetching || updating} title="Complete account">
      <Text variant="body2">
        You have been invited to create an account for the ARIONHUB application.
      </Text>
      <Text variant="body2">
        To start using the app please complete your account with a new password
        below.
      </Text>
      <Form onSubmit={handleSubmit}>
        <Inputs>
          <HiddenInput autoComplete="username" id="username" type="text" />
          <StyledInputLabel htmlFor="new-password" variant="h3">
            Password
          </StyledInputLabel>
          <StyledInput
            autoComplete="new-password"
            disabled={disabled}
            id="new-password"
            maxLength={50}
            required
            type="password"
            value={password}
            onChange={handleNewPasswordChange}
          />
          <StyledInputLabel htmlFor="confirm-password" variant="h3">
            Confirm Password
          </StyledInputLabel>
          <Input
            autoComplete="new-password"
            disabled={disabled}
            id="confirm-password"
            maxLength={50}
            required
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </Inputs>
        <SubmitButton disabled={disabled} type="submit">
          Complete
        </SubmitButton>
      </Form>
    </Auth>
  );
};

export default Onboarding;
