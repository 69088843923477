import { RootState } from '../types';

export const selectInitializing = (state: RootState) => state.auth.initializing;

export const selectAuthUser = (state: RootState) => state.auth.authUser.data;

export const selectAuthUserFetching = (state: RootState) =>
  state.auth.authUser.fetching;

export const selectAuthUserError = (state: RootState) =>
  state.auth.authUser.error;

export const selectUser = (state: RootState) => state.auth.user.data;

export const selectUserFetching = (state: RootState) =>
  state.auth.user.fetching;

export const selectUserUpdating = (state: RootState) =>
  state.auth.user.updating;

export const selectEmail = (state: RootState) => state.auth.email;

export const selectLoading = (state: RootState) => state.auth.loading;
