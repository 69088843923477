import { FC } from 'react';
import { isNilOrWhitespace } from '@atogear/arion-utils';
import styled from 'styled-components';

import {
  Promotion,
  fields,
  getTitle,
  setTitle,
  getDescription,
  setDescription,
  getButtonText,
  setButtonText,
  getButtonUrl,
  setButtonUrl,
  getImageUrl,
  getDefaultImageUrl,
  setImageUrl,
} from '../../../../models/promotionModel';

import { Heading, Input, InputLabel, Text } from '../../../../components';

import {
  getMediaAspectRatio,
  promoMediaOptions,
} from '../../../../utils/promoUtils';
import MediaInput from '../../../Themes/Theme/Editor/Media/MediaInput';

const Wrapper = styled.div`
  margin-top: 16px;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 32px;
  width: calc(65% - 32px);
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

const StyledSubtitle = styled(Text)`
  margin-top: 8px;
`;
interface Props {
  promotion: Promotion;
  disabled: boolean;
  onChange: (change: any) => void;
  onError: (change: any) => void;
}

const CallToAction: FC<Props> = (props) => {
  const { disabled = false, promotion, onChange, onError } = props;

  const handleValidate = (key: string) => (value: string) => {
    const valid = !isNilOrWhitespace(value);

    onError({ [key]: !valid });

    return valid;
  };

  return (
    <Wrapper>
      <Heading variant="h4">Call to action</Heading>
      <StyledSubtitle variant="body2">
        The main message of your promotion.
      </StyledSubtitle>
      <ActionContainer>
        <InputContainer>
          <Group>
            <StyledInputLabel htmlFor={fields.TITLE}>Title</StyledInputLabel>
            <Input
              id={fields.TITLE}
              disabled={disabled}
              placeholder="Title"
              value={getTitle(promotion)}
              validate={handleValidate(fields.TITLE)}
              onChange={(e) => onChange(setTitle(e.target.value, promotion))}
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor={fields.DESCRIPTION}>
              Subtitle
            </StyledInputLabel>
            <Input
              id={fields.DESCRIPTION}
              disabled={disabled}
              placeholder="Subtitle"
              value={getDescription(promotion)}
              validate={handleValidate(fields.DESCRIPTION)}
              onChange={(e) =>
                onChange(setDescription(e.target.value, promotion))
              }
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor={fields.BUTTON_TEXT}>
              Button caption
            </StyledInputLabel>
            <Input
              id={fields.BUTTON_TEXT}
              disabled={disabled}
              placeholder="Button caption"
              value={getButtonText(promotion)}
              validate={handleValidate(fields.BUTTON_TEXT)}
              onChange={(e) =>
                onChange(setButtonText(e.target.value, promotion))
              }
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor={fields.BUTTON_URL}>
              Button link address
            </StyledInputLabel>
            <Input
              id={fields.BUTTON_URL}
              disabled={disabled}
              placeholder="Button link address"
              value={getButtonUrl(promotion)}
              validate={handleValidate(fields.BUTTON_URL)}
              onChange={(e) =>
                onChange(setButtonUrl(e.target.value, promotion))
              }
            />
          </Group>
        </InputContainer>
        <ImageContainer>
          <div
            style={{
              height: 285,
              width: 285 * getMediaAspectRatio(promoMediaOptions.miniPromo),
            }}
          >
            <MediaInput
              src={
                getImageUrl(promotion) !== undefined
                  ? getImageUrl(promotion)
                  : getDefaultImageUrl(promotion)
              }
              mediaOption={promoMediaOptions.mainPromo}
              InputProps={{
                readOnly: disabled,
              }}
              onFileChange={(file) => onChange(setImageUrl(file, promotion))}
              onDelete={() => onChange(setImageUrl('', promotion))}
              onRevert={(file) => onChange(setImageUrl(file, promotion))}
            />
          </div>
        </ImageContainer>
      </ActionContainer>
    </Wrapper>
  );
};

export default CallToAction;
