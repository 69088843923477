import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import tinycolor from 'tinycolor2';
import Navigation from './Navigation';
import UserMenu from './UserMenu';
import logoDark from '../../../assets/images/logo-dark.png';
import logoLight from '../../../assets/images/logo-light.png';
import Notifications from './Notifications';
import { useSelector } from '../../../store';
import { AuthSelectors } from '../../../store/selectors';
import { apply, userModel } from '@atogear/arion-utils';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow};
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 70px;
  width: 100vw;
  z-index: 10;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-right: 100px;
  max-width: 250px;

  @media (max-width: 1400px) {
    margin-right: 0px;
  }
`;

const Image = styled.img`
  height: 60%;
`;

const Header: FC = () => {
  const theme = useTheme();

  const logo = useMemo(() => {
    return tinycolor(theme.colors.dark).isDark() ? logoLight : logoDark;
  }, [theme.colors.dark]);

  const user = useSelector(AuthSelectors.selectUser);

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);
  const isBackOffice = apply(userModel.isBackOffice, user, false);

  const canViewNotifications = isAdmin || isSales || isBackOffice;

  return (
    <Wrapper>
      <Logo to="/">
        <Image alt="ARIONHUB Admin Dashboard" src={logo} />
      </Logo>
      <Navigation />
      {canViewNotifications && <Notifications />}
      <UserMenu />
    </Wrapper>
  );
};

export default Header;
