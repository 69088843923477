import { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';

import CheckBoxList from '../CheckBoxList';
import Heading from '../Heading';
import Popper from '../Popper';
import { TableColumn } from '../Table/types';

const StyledPopper = styled(Popper)`
  padding: 12px;
  background-color: ${(props) => props.theme.colors.dark};
`;

const StyledHeading = styled(Heading)`
  padding-bottom: 12px;
  ${({ theme }) => theme.typography.h3};
  color: ${(props) => props.theme.colors.white};
`;

interface Props {
  open?: boolean;
  anchorEl: HTMLElement | null;
  columns: TableColumn[];
  selectedColumns: TableColumn[];
  onColumnChange: ChangeEventHandler<HTMLInputElement>;
  onDismiss: () => any;
}

const ViewColumnsModal: FC<Props> = ({
  anchorEl,
  columns,
  selectedColumns,
  onColumnChange,
  onDismiss,
}) => (
  <StyledPopper
    open={Boolean(anchorEl)}
    onDismiss={onDismiss}
    anchorEl={anchorEl}
  >
    <StyledHeading>Show Columns</StyledHeading>
    <CheckBoxList
      options={columns}
      selected={selectedColumns}
      onChange={onColumnChange}
    />
  </StyledPopper>
);

export default ViewColumnsModal;
