import { Store } from '@atogear/arion-utils';
import axios, { Method } from 'axios';
import { getFirebaseStoragePipe } from '@atogear/ato-broker';
import { format } from 'date-fns';

import { UploadedShoe } from '../models/shoeModel';

import config from '../config';

const BASE_URL = process.env.REACT_APP_SHOE_SERVICE_API;

let token: string | undefined;

interface CallParams {
  token: string;
  body?: any;
}

const call = async (
  path: string,
  method: Method = 'get',
  params: CallParams,
): Promise<any> => {
  try {
    const { token, body } = params;

    const url = path.startsWith('/')
      ? `${BASE_URL}${path}`
      : `${BASE_URL}/${path}`;

    const response = await axios({
      method,
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
    });

    if (response.data.errors) {
      if (Array.isArray(response.data.errors)) {
        throw response.data.errors[0].message;
      } else {
        if (response.data.errors && response.data.errors.message) {
          throw response.data.errors.message;
        } else {
          throw response.data.errors;
        }
      }
    }

    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw error.request;
    } else {
      throw error.message;
    }
  }
};

const getShoeService = () => {
  /**
   * Executes a request to the /authorize end-point with the jwt token from
   * firebase and the projectId in the shoe service to get a valid access
   * token for it.
   *
   * @param firebaseToken jwt token from firebase
   * @returns JWT which can be used with APEX (for now only with the shoe service)
   */
  const getToken = async (firebaseToken: string): Promise<string> => {
    const response = await call(
      `/authorize?firebaseProjectId=${config.firebase.projectId}`,
      'get',
      {
        token: firebaseToken,
      },
    );

    return response.scsToken;
  };

  interface UploadShoesParams {
    /* Origin of the request. */
    origin: string;
    /* Shoes to upload. */
    shoes: UploadedShoe[];
  }

  /**
   * Upload shoes in the closet.
   *
   * @param {string} firebaseToken The Firebase Auth JWT.
   * @param {UploadShoesParams} body The request body.
   */
  const upload = async (firebaseToken: string, body: UploadShoesParams) => {
    if (!token) {
      token = await getToken(firebaseToken);
    }

    return call('/import-lite-shoes', 'post', {
      token,
      body,
    });
  };

  /**
   * Upload the file for manual review if the upload fails.
   *
   * @param {File} file The file that failed to parse.
   */
  const saveFailedUpload = async (store: Store, file: File) => {
    const storage = getFirebaseStoragePipe();

    const date = format(new Date(), 'PPpp');

    const fileName = `${store.storeId}_${date}_${file.name}`;

    return storage.upload(`failed_shoe_uploads/${fileName}`, file);
  };

  return {
    upload,
    saveFailedUpload,
  };
};

export default getShoeService;
