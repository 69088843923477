import { getFirebaseStoragePipe, getFirestorePipe } from '@atogear/ato-broker';

import { promotionItemModel, promotionModel } from '../models';
import { Promotion } from '../models/promotionModel';

import { compressImage } from '../utils/mediaUtils';
import { promoMediaOptions } from '../utils/promoUtils';

/**
 * @typedef {import('../models/promotionModel').Promotion} Promotion
 */

const STORE_COLLECTION_PATH = 'stores';
const USER_COLLECTION_PATH = 'users';
const PROMOTIONS_COLLECTION_PATH = 'promotions';

/**
 * Get Store's path.
 *
 * @param {string} storeId Store's id.
 * @returns {string} Store's path.
 */
const getStorePath = (storeId: string): string =>
  `${STORE_COLLECTION_PATH}/${storeId}`;

/**
 * Get Store's Promotions path.
 *
 * @param {string} storeId Store's id.
 * @returns {string} Store's Promotion path.
 */
const getPromotionsPath = (storeId: string): string =>
  `${getStorePath(storeId)}/${PROMOTIONS_COLLECTION_PATH}`;

/**
 * Get File extension.
 *
 * @param {string} fileName File name.
 * @returns {string} File extension.
 */
const getFileExtension = (fileName: string): string | undefined =>
  fileName ? fileName.split('.').pop() : '';

/**
 * Get Image's path.
 *
 * @param {string} name Image name.
 */
const getImagePath =
  (name: string) =>
  /**
   * Get path to file in storage.
   *
   * ```
   * users/{ownerId}/stores/{storeId}/images/{name}.{extension}
   * ```
   *
   * @param {string} ownerId Owner's id.
   * @param {string} storeId Store's id.
   * @param {string} promotionId Promotions's id.
   * @param {string} fileName File's name.
   * @returns {string} Path to file in storage.
   */
  (
    ownerId: string,
    storeId: string,
    promotionId: string,
    fileName: string,
  ): string =>
    `${USER_COLLECTION_PATH}/${ownerId}/${getStorePath(
      storeId,
    )}/images/${promotionId}/${name}.${getFileExtension(fileName)}`;

const getPromotionImagePath = getImagePath('Image');

const getPromotionItemImagePath = (index: number) =>
  getImagePath(`PromoImage${index}`);

const getPromotionService = () => {
  /**
   * Get Store Promotions.
   *
   * @param {string} storeId Store's id.
   * @returns {Promise<Promotion[]>} Promise that resolves to Store Promotion.
   */
  const getPromotions = async (storeId: string): Promise<Promotion[]> => {
    const promotions = await getFirestorePipe().getDocuments(
      getPromotionsPath(storeId),
    );

    return promotions as Promotion[];
  };

  /**
   * Get Store Promotion.
   *
   * @param {string} storeId Store's id.
   * @param {string} promotionId Promotion's id.
   * @returns {Promise<Promotion>} Promise that resolves to Store Promotion.
   */
  const getPromotion = async (
    storeId: string,
    promotionId: string,
  ): Promise<Promotion> => {
    const promotion = await getFirestorePipe().getDocument(
      getPromotionsPath(storeId),
      promotionId,
    );

    return promotion as Promotion;
  };

  /**
   * Get Store's default Promotion.
   *
   * @param {string} storeId Store's id.
   * @returns {Promise<Promotion>} Promise that resolves to Store's default Promotion.
   */
  const getDefaultPromotion = (storeId: string): Promise<Promotion> =>
    getPromotion(storeId, 'metadata');

  /**
   * Add Store Promotion.
   *
   * @param {string} storeId Store's id.
   * @param {Promotion} promotion promotion's data.
   * @returns {Promise<Promotion>} Promise that resolves to the new Store Promotion.
   */
  const addPromotion = async (
    storeId: string,
    promotion: Promotion,
  ): Promise<Promotion> => {
    const defaultPromoImageUrl = await getFirebaseStoragePipe().getLinkForFile(
      'atogear-branding/PromotionImage.jpeg',
    );

    const newPromotion = promotionModel.setDefaultImageUrl(
      defaultPromoImageUrl,
      promotion,
    );

    const id = await getFirestorePipe().addDocument(
      getPromotionsPath(storeId),
      newPromotion,
    );

    return { ...newPromotion, id } as Promotion;
  };

  /**
   * Copy Store Promotion.
   *
   * @param {string} storeId Store's id.
   * @param {Promotion} promotion promotion's data.
   * @returns {Promise<Promotion>} Promise that resolves to the new copied Store Promotion.
   */
  const copyPromotion = async (
    storeId: string,
    promotion: Promotion,
  ): Promise<Promotion> => {
    // TODO ask if we should copy over the promotion files(images) in the storage and replace the urls in the doc

    const id = await getFirestorePipe().addDocument(
      getPromotionsPath(storeId),
      promotion,
    );

    return { ...promotion, id } as Promotion;
  };

  /**
   * Set default Store Promotion.
   *
   * @param {string} storeId Store's id.
   * @param {Promotion[]} restPromotions restPromotions's data.
   * @param {Promotion} newDefaultPromotion newDefaultPromotion's data.
   * @returns {Promise<Promotion[]>} Promise that resolves to the set Store Promotion.
   */
  const setDefaultPromotion = async (
    storeId: string,
    restPromotions: Promotion[],
    newDefaultPromotion: Promotion,
  ): Promise<Promotion[]> => {
    const updatedPromotion = promotionModel.setIsDefault(
      true,
      newDefaultPromotion,
    );

    await Promise.all([
      restPromotions && restPromotions.length > 0
        ? restPromotions.map((promo) => {
            if (promo && promotionModel.getIsDefault(promo)) {
              return getFirestorePipe().setDocument(
                getPromotionsPath(storeId),
                promotionModel.getId(promo) || '',
                promotionModel.prepare(
                  promotionModel.setIsDefault(false, promo),
                ),
              );
            }
            return null;
          })
        : null,
      getFirestorePipe().setDocument(
        getPromotionsPath(storeId),
        promotionModel.getId(newDefaultPromotion) || '',
        promotionModel.prepare(updatedPromotion),
      ),
      getFirestorePipe().setDocument(
        getPromotionsPath(storeId),
        'metadata',
        promotionModel.prepare(updatedPromotion),
      ),
    ]);

    return [
      ...restPromotions.map((promo) =>
        promotionModel.setIsDefault(false, promo),
      ),
      updatedPromotion,
      { ...updatedPromotion, id: 'metadata' },
    ] as Promotion[];
  };

  /**
   * Update Store Promotion.
   *
   * @param {string} ownerId Owner's id.
   * @param {string} storeId Store's id.
   * @param {Promotion} promotion Promotion's data.
   * @returns {Promise<Promotion[]>} Promise that resolves to updated Store Promotion.
   */
  const updatePromotion = async (
    ownerId: string,
    storeId: string,
    promotion: Promotion,
  ): Promise<Promotion[]> => {
    const storage = getFirebaseStoragePipe();

    let newPromotion = { ...promotion };

    const promotionId = promotionModel.getId(newPromotion);

    // upload image if its a file
    const imageUrl = promotionModel.getImageUrl(newPromotion);

    if (imageUrl && imageUrl.includes('blob:')) {
      const { preferredSize } = promoMediaOptions.mainPromo;

      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const fileName = `promoImageUrl.${blob.type
        .replace('image/', '')
        .replace('+xml', '')}`;
      const file = new File([blob], fileName, {
        type: blob.type,
      });

      const blobToUpload = await compressImage(file, {
        resize: 'cover',
        width: preferredSize.width,
        height: preferredSize.height,
      });

      const imagePath = getPromotionImagePath(
        ownerId,
        storeId,
        promotionId || '',
        fileName,
      );

      await storage.upload(imagePath, blobToUpload);

      const publicUrl = await getFirebaseStoragePipe().getLinkForFile(
        imagePath,
      );

      newPromotion = promotionModel.setMedia(
        publicUrl,
        imagePath,
        newPromotion,
      );
    }

    // upload promotion images
    if (promotionModel.hasPromotionItems(promotion)) {
      const promotionItems = promotionModel.getPromotionItems(promotion);

      // Compress images
      const newPromoItems = await Promise.all(
        promotionItems.map(async (promotionItem, index) => {
          const imagePath = promotionItemModel.getImagePath(promotionItem);

          if (imagePath && imagePath.includes('blob:')) {
            const { preferredSize } = promoMediaOptions.miniPromo;

            const response = await fetch(imagePath);
            const blob = await response.blob();

            const fileName = `promoTileImageUrl-${index}.${blob.type
              .replace('image/', '')
              .replace('+xml', '')}`;
            const file = new File([blob], fileName, {
              type: blob.type,
            });

            const blobToUpload = await compressImage(file, {
              resize: 'cover',
              width: preferredSize.width,
              height: preferredSize.height,
            });

            const itemImagePath = getPromotionItemImagePath(index)(
              ownerId,
              storeId,
              promotionId || '',
              fileName,
            );

            await storage.upload(itemImagePath, blobToUpload);

            const publicUrl = await getFirebaseStoragePipe().getLinkForFile(
              itemImagePath,
            );

            return promotionItemModel.setMedia(
              publicUrl,
              itemImagePath,
              promotionItem,
            );
          }

          return promotionItem;
        }),
      );

      newPromotion = promotionModel.setPromotionItems(
        newPromoItems,
        newPromotion,
      );
    }

    const promises = [
      getFirestorePipe().setDocument(
        getPromotionsPath(storeId),
        promotionId || '',
        promotionModel.prepare(newPromotion),
      ),
    ];

    // if it is default sync the metadata(default) one
    if (promotionModel.getIsDefault(newPromotion)) {
      promises.push(
        getFirestorePipe().setDocument(
          getPromotionsPath(storeId),
          'metadata',
          promotionModel.prepare(newPromotion),
        ),
      );
    }

    const updates = await Promise.all(promises);

    return updates as Promotion[];
  };

  /**
   * Remove Store Promotion.
   *
   * No need to delete the images as they are going to be deleted automatically
   * be the cloud function onStoreDelete
   *
   * @param {string} storeId Store's id.
   * @param {Promotion} promotion promotion's data.
   * @returns {Promise<Promotion>} Promise that resolves to the set Store Promotion.
   */
  const removePromotion = async (
    storeId: string,
    promotion: Promotion,
  ): Promise<Promotion> => {
    await Promise.all([
      getFirestorePipe().deleteDocument(
        getPromotionsPath(storeId),
        promotionModel.getId(promotion) || '',
      ),
      promotionModel.getIsDefault(promotion)
        ? getFirestorePipe().deleteDocument(
            getPromotionsPath(storeId),
            'metadata',
          )
        : null,
    ]);

    return promotion as Promotion;
  };

  return {
    getPromotion,
    getDefaultPromotion,
    getPromotions,
    addPromotion,
    copyPromotion,
    setDefaultPromotion,
    updatePromotion,
    removePromotion,
  };
};

export default getPromotionService;
