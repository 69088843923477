import { FC, LabelHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Variant } from './Heading';

interface WrapperProps {
  $variant: Variant;
}

const Wrapper = styled.label<WrapperProps>`
  ${({ $variant, theme }) => theme.typography[$variant]};
`;

export interface InputLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  variant?: Variant;
}

const InputLabel: FC<InputLabelProps> = ({ variant, ...rest }) => (
  <Wrapper $variant={variant || 'h4'} {...rest} />
);

export default InputLabel;
