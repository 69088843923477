import { ChangeEventHandler, FC, useEffect, useId, useState } from 'react';
import { Store, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { Languages } from '../../../../enums';

import { getLanguageCountryCode } from '../../../../utils/i18nUtils';

import {
  Checkbox,
  DialogHeading,
  DialogInputLabel,
  DialogSelect,
  FlagIcon,
  Option,
} from '../../../../components';
import InformationIcon from '../../../../components/InformationIcon';

import { ExtraStoreData } from '../../types';

const LANGUAGE_OPTIONS = Object.keys(Languages).map((language) => ({
  adornment: <FlagIcon country={getLanguageCountryCode(language)} />,
  key: language,
  label: language,
  value: language,
}));

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 32px;
`;

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 18px;
`;

const FlexWrapper = styled.section`
  display: flex;
  gap: 24px;
`;

interface FieldProps {
  $last?: boolean;
  $direction?: 'column' | 'row';
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'row'};
  ${({ $last }) => `margin-${$last ? 'top' : 'bottom'}: 10px;`};
`;

const StyledInputLabel = styled(DialogInputLabel)`
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const CapitalizedDialogSelect = styled(DialogSelect)`
  input,
  li {
    text-transform: capitalize;
  }
`;

const FieldHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 10px;
  gap: 6px;
`;

interface Props {
  store: Store;
  onChange: (value: ExtraStoreData) => void;
}

const Extra: FC<Props> = ({ store, onChange }) => {
  const [preferredLanguage, setPreferredLanguage] = useState(
    storeModel.getPreferredLanguage(store),
  );
  const [treadmill, setTreadmill] = useState(storeModel.hasTreadmill(store));
  const [track, setTrack] = useState(storeModel.hasTrack(store));

  useEffect(() => {
    setPreferredLanguage(storeModel.getPreferredLanguage(store));
    setTreadmill(storeModel.hasTreadmill(store));
    setTrack(storeModel.hasTrack(store));
  }, [store]);

  const handleTreadmillChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked } = e.target;

    setTreadmill(checked);

    onChange({
      treadmill: checked,
    });
  };

  const handleTrackChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked } = e.target;

    setTrack(checked);

    onChange({
      track: checked,
    });
  };

  const handleCountryChange = (option: Option | null) => {
    const value = option?.value || preferredLanguage;

    setPreferredLanguage(value);

    // NOTE: Not debounced since it is selected from dropdown
    onChange({
      preferredLanguage: value,
    });
  };

  const id = useId();
  const treadmillId = `${id}-treadmill`;
  const trackId = `${id}-track`;
  const langId = `${id}-language`;

  return (
    <Wrapper>
      <StyledHeading variant="h3">Equipment</StyledHeading>
      <FlexWrapper>
        <Field>
          <Checkbox
            id={treadmillId}
            checked={treadmill}
            onChange={handleTreadmillChange}
          />
          <StyledInputLabel htmlFor={treadmillId} variant="h4">
            Treadmill
          </StyledInputLabel>
        </Field>
        <Field>
          <Checkbox id={trackId} checked={track} onChange={handleTrackChange} />
          <StyledInputLabel htmlFor={trackId} variant="h4">
            Running Track
          </StyledInputLabel>
        </Field>
      </FlexWrapper>
      <Field $last $direction="column">
        <FieldHeading>
          <DialogHeading variant="h3">Preferred language</DialogHeading>
          <InformationIcon tooltip="Will be applied as default language to the HUB app, email letters and digital flyer." />
        </FieldHeading>
        <CapitalizedDialogSelect
          id={langId}
          options={LANGUAGE_OPTIONS}
          startAdornment={
            <FlagIcon country={getLanguageCountryCode(preferredLanguage)} />
          }
          value={preferredLanguage}
          onChange={handleCountryChange}
        />
      </Field>
    </Wrapper>
  );
};

export default Extra;
