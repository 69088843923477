import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import Text from './Text';

const Wrapper = styled.div`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
`;

const Label = styled(Text)`
  margin: 0 6px;
`;

export interface ChipProps extends HTMLAttributes<HTMLDivElement> {
  avatar?: JSX.Element;
  button?: JSX.Element;
  label?: string;
}

const Chip: FC<ChipProps> = ({ avatar, button, label, ...rest }) => (
  <Wrapper {...rest}>
    {avatar}
    <Label variant="body2">{label}</Label>
    {button}
  </Wrapper>
);

export default Chip;
