import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useId,
  useState,
} from 'react';
import { isNilOrWhitespace } from '@atogear/arion-utils';
import styled from 'styled-components';

import {
  Contact,
  getCallingCode,
  getEmail,
  getFullName,
  getId,
  getPhoneNumber,
  getRole,
} from '../models/contactModel';

import Button from './Button';
import Dialog from './Dialog/Dialog';
import DialogActions from './Dialog/DialogActions';
import DialogInput from './Dialog/DialogInput';
import DialogInputLabel from './Dialog/DialogInputLabel';
import DialogSelect from './Dialog/DialogSelect';
import FlagIcon from './FlagIcon';

import { Option } from './Select';

import countries from '../assets/data/countries.json';

const countryOptions = countries
  .sort((a, b) => -b.callingCode.localeCompare(a.callingCode))
  .map(({ alpha2, callingCode }) => ({
    adornment: <FlagIcon country={alpha2.toLowerCase()} />,
    key: alpha2,
    label: callingCode,
    value: alpha2,
  }));

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 450px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

interface FieldProps {
  $direction?: 'column' | 'row';
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  margin-bottom: 16px;
`;

interface ColumnProps {
  $left?: boolean;
}

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  ${({ $left }) => (!$left ? 'flex: 1' : 'width: 120px')};
  ${({ $left }) => `margin-${$left ? 'right' : 'left'}: 4px`};
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

interface Props {
  contact?: Contact;
  loading?: boolean;
  open: boolean;
  onCancel: () => void;
  onConfirm: (contact: Contact) => void;
}

const ContactDialog: FC<Props> = ({
  contact,
  loading = false,
  open,
  onCancel,
  onConfirm,
}) => {
  const [name, setName] = useState(getFullName(contact));
  const [role, setRole] = useState(getRole(contact));
  const [email, setEmail] = useState(getEmail(contact));
  const [callingCode, setCallingCode] = useState(getCallingCode(contact));
  const [phoneNumber, setPhoneNumber] = useState(getPhoneNumber(contact));

  useEffect(() => {
    setName(getFullName(contact));
    setRole(getRole(contact));
    setEmail(getEmail(contact));
    setCallingCode(getCallingCode(contact));
    setPhoneNumber(getPhoneNumber(contact));
  }, [contact]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const [firstName, ...rest] = name.split(' ');

    onConfirm({
      id: getId(contact),
      firstName,
      lastName: rest.join(' '),
      role,
      email,
      callingCode,
      phoneNumber,
    });
  };

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setName(e.target.value);

  const handleRoleChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setRole(e.target.value);

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setEmail(e.target.value.toLowerCase());

  const handleCallingCodeChange = (option: Option | null) =>
    setCallingCode(option?.value || callingCode);

  const handlePhoneNumberChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setPhoneNumber(e.target.value);

  // TODO: Validate on more than just empty strings
  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const id = useId();
  const nameId = `${id}-name`;
  const roleId = `${id}-role`;
  const emailId = `${id}-email`;
  const callingCodeId = `${id}-calling-code`;
  const phoneNumberId = `${id}-phone-number`;

  return (
    <Dialog loading={loading} open={open} title="Contact" onClose={onCancel}>
      <Form onSubmit={handleSubmit}>
        <Content>
          <Field>
            <StyledInputLabel htmlFor={nameId}>Name</StyledInputLabel>
            <DialogInput
              id={nameId}
              placeholder="Name"
              type="text"
              value={name}
              validate={handleValidate}
              onChange={handleNameChange}
            />
          </Field>
          <Field>
            <StyledInputLabel htmlFor={roleId}>Role</StyledInputLabel>
            <DialogInput
              id={roleId}
              placeholder="Role"
              type="text"
              value={role}
              validate={handleValidate}
              onChange={handleRoleChange}
            />
          </Field>
          <Field>
            <StyledInputLabel htmlFor={emailId}>Email</StyledInputLabel>
            <DialogInput
              id={emailId}
              placeholder="Email"
              type="email"
              value={email}
              validate={handleValidate}
              onChange={handleEmailChange}
            />
          </Field>
          <StyledInputLabel htmlFor={phoneNumberId}>
            Phone number
          </StyledInputLabel>
          <Field $direction="row">
            <Column $left>
              <DialogSelect
                id={callingCodeId}
                options={countryOptions}
                startAdornment={<FlagIcon country={callingCode} />}
                value={callingCode}
                onChange={handleCallingCodeChange}
              />
            </Column>
            <Column>
              <DialogInput
                id={phoneNumberId}
                placeholder="Phone Number"
                type="tel"
                value={phoneNumber}
                validate={handleValidate}
                onChange={handlePhoneNumberChange}
              />
            </Column>
          </Field>
        </Content>
        <DialogActions>
          <CancelButton
            disabled={loading}
            size="small"
            type="button"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </CancelButton>
          <Button
            color="primary"
            disabled={loading}
            size="small"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ContactDialog;
