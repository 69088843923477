import firebase from 'firebase/compat/app';
import { getFirebaseAuthPipe } from '@atogear/ato-broker';

const getAuthService = () => {
  /**
   * Sign in with an email and password.
   *
   * @param email The email.
   * @param password The password.
   * @returns Promise which resolves to user's credentials when signed in successfully.
   */
  const signIn = (
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> =>
    getFirebaseAuthPipe().signInWithEmailAndPassword(email, password);

  /**
   * Sign out.
   *
   * @returns Promise which resolves to true when signed out successfully.
   */
  const signOut = (): Promise<boolean> => getFirebaseAuthPipe().signOut();

  /**
   * Send reset password email to email.
   *
   * @param email The email.
   * @returns Promise which resolves when email has been sent successfully.
   */
  const sendPasswordResetEmail = async (email: string): Promise<void> =>
    getFirebaseAuthPipe().sendPasswordResetEmail(email);

  /**
   * Get available sign in methods for email.
   *
   * @param email The email.
   * @returns Promise which resolves when sign in methods have been fetched successfully.
   */
  const getSignInMethods = (email: string): Promise<string[]> =>
    getFirebaseAuthPipe().fetchSignInMethodsForEmail(email);

  /**
   * Change a user's password.
   *
   * @param currentPassword The current password.
   * @param newPassword The new password.
   * @returns Promise which resolves when the password has been changed successfully.
   */
  const changePassword = async (
    currentPassword: string,
    newPassword: string,
  ): Promise<void> => {
    const auth = getFirebaseAuthPipe();

    const user = auth.currentUser();

    if (!user) {
      throw new Error('No user signed in!');
    }

    if (!user.email) {
      throw new Error('User does not have email!');
    }

    const credentials = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword,
    );

    await auth.verifyCredentials(credentials);

    return auth.changePassword(newPassword);
  };

  /**
   * Add a callback function to the authStateChanged event.
   *
   * @param onChange The callback function to be executed when the authStateChanged event occurs.
   * @returns Unsubscribe function.
   */
  const onAuthStateChanged = (
    onChange: (user: firebase.User | null) => void,
  ): firebase.Unsubscribe => getFirebaseAuthPipe().onAuthStateChanged(onChange);

  /**
   * Gets the jwt token of the current user if any.
   *
   * @returns Promise which resolves to the jtw token.
   */
  const getIdToken = async (): Promise<string> => {
    const user = getFirebaseAuthPipe().currentUser();

    if (!user) {
      throw new Error('No user signed in!');
    }

    return user.getIdToken();
  };

  return {
    signIn,
    signOut,
    sendPasswordResetEmail,
    getSignInMethods,
    changePassword,
    onAuthStateChanged,
    getIdToken,
  };
};

export default getAuthService;
