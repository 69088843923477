import { SerializedError } from '@reduxjs/toolkit';
import { formatAmount, formatPercentage } from '@atogear/arion-utils';
import firebase from 'firebase/compat/app';

export const formatStores = formatAmount('store');

export const formatVisits = formatAmount('visit');

export const formatShoes = formatAmount('shoe');

export const formatMonths = formatAmount('month');

export const formatYears = formatAmount('year');

export const formatEquipment = (track: boolean, treadmill: boolean) => {
  const equipment = [];

  if (track) {
    equipment.push('Running track');
  }

  if (treadmill) {
    equipment.push('Treadmill');
  }

  return equipment.length > 0 ? equipment.join(', ') : 'None';
};

export const formatError = (
  error: Error | firebase.FirebaseError | SerializedError,
) => {
  switch ((error as firebase.FirebaseError).code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      error.message = 'Invalid email or password.';
      break;
    default:
      break;
  }

  return error;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const formatFileSize = (bytes: number, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const padTo2Digits = (value: number) =>
  value >= 0 && value < 10 ? `0${value}` : `${value}`;

export const formatTimeSpent = (ms: number) => {
  const mins = Math.floor((ms / (1000 * 60)) % 60);
  const hrs = Math.floor((ms / (1000 * 60 * 60)) % 60);

  const hrsStr = hrs > 0 ? formatAmount('hr')(hrs) : '';

  return `${hrsStr} ${formatAmount('min')(mins)}`.trim();
};

export const formatConversionRate = (value: number) =>
  formatPercentage(value, {
    decimals: 2,
    withSpacing: false,
  });
