import {
  formatDateTime,
  isNilOrEmpty,
  toMillis,
  userModel,
  UserRoles,
} from '@atogear/arion-utils';
import { mergeRight } from 'ramda';

import { EventGroups, EventTypes } from '../enums';

export interface Event {
  id?: string;
  attachment?: string;
  date: number;
  description: string;
  type: EventTypes;
  userId: string;
  userEmail: string;
  userName: string;
  userRole: UserRoles;
}

export const fields = {
  ID: 'id',
  ATTACHMENT: 'attachment',
  DATE: 'date',
  DESCRIPTION: 'description',
  TYPE: 'type',
  USER_ID: 'userId',
  USER_EMAIL: 'userEmail',
  USER_NAME: 'userName',
  USER_ROLE: 'userRole',
};

export const defaults: Event = {
  id: '',
  attachment: '',
  date: Date.now(),
  description: '',
  type: EventTypes.STORE_CREATED,
  userId: '',
  userEmail: '',
  userName: '',
  userRole: UserRoles.ADMIN,
};

export const getId = (event: Event) => (event || defaults).id;

export const getAttachment = (event: Event) => (event || defaults).attachment;

export const getDate = (event: Event) => (event || defaults).date;

export const getDescription = (event: Event) => (event || defaults).description;

export const getType = (event: Event) => (event || defaults).type;

export const getUserId = (event: Event) => (event || defaults).userId;

export const getUserEmail = (event: Event) => (event || defaults).userEmail;

export const getUserName = (event: Event) => (event || defaults).userName;

export const getUserRole = (event: Event) => (event || defaults).userRole;

export const hasUser = (event: Event) => !isNilOrEmpty(getUserId(event));

export const hasAttachment = (event: Event) =>
  !isNilOrEmpty(getAttachment(event));

export const getFormattedDateString = (event: Event): string =>
  formatDateTime(getDate(event));

// TODO: Remove this when legacy events are migrated
export const getDisplayName = (event: Event) => {
  let actionText = '';

  switch (getType(event)) {
    case EventTypes.SUB_ACTIVATED:
      actionText = hasAttachment(event) ? 'Agreement signed' : 'Store created';
      break;
    case EventTypes.SUB_CANCELLED:
      actionText = 'Subscription cancelled';
      break;
    case EventTypes.SUB_REACTIVATED:
      actionText = 'Subscription reactivated';
      break;
    case EventTypes.TRIAL_EXTENDED:
      actionText = 'Trial extended';
      break;
    default:
      throw new Error('Invalid type');
  }

  let userText = '';

  // @ts-expect-error
  if (event?.user && !hasAttachment(event)) {
    // @ts-expect-error
    userText = ` by ${userModel.getFullName(event.user)}`;
  }

  return `${actionText}${userText} on ${formatDateTime(getDate(event))}`;
};

export const getEventGroup = (event: Event): string => {
  switch (getType(event)) {
    case EventTypes.STORE_CREATED:
    case EventTypes.STORE_UPDATED:
    case EventTypes.STORE_UPGRADED:
      return EventGroups.STORE;
    case EventTypes.BRANDING_UPDATED:
      return EventGroups.BRANDING;
    case EventTypes.PROMOTIONS_UPDATED:
      return EventGroups.PROMOTIONS;
    case EventTypes.LEAD_CREATED:
    case EventTypes.LEAD_UPDATED:
    case EventTypes.LEAD_DELETED:
      return EventGroups.LEAD;
    case EventTypes.SHOES_UPLOADED:
      return EventGroups.SHOES;
    case EventTypes.USER_INVITED:
    case EventTypes.USER_ADDED:
    case EventTypes.USER_UPDATED:
    case EventTypes.USER_REMOVED:
      return EventGroups.USER;
    case EventTypes.SUB_ACTIVATED:
    case EventTypes.SUB_CANCELLED:
    case EventTypes.SUB_REACTIVATED:
    case EventTypes.SUB_ENDED:
    case EventTypes.TRIAL_EXTENDED:
      return EventGroups.SUBSCRIPTION;
    case EventTypes.PROSPECT_CREATED:
    case EventTypes.PROSPECT_UPDATED:
    case EventTypes.PROSPECT_TRANSFORMED:
      return EventGroups.PROSPECT;
    default:
      return 'Unknown Group';
  }
};

export const getEventIcon = (event: Event): string => {
  switch (getType(event)) {
    case EventTypes.STORE_CREATED:
    case EventTypes.STORE_UPDATED:
    case EventTypes.STORE_UPGRADED:
      return 'store';
    case EventTypes.BRANDING_UPDATED:
      return 'theme';
    case EventTypes.PROMOTIONS_UPDATED:
      return 'promotion-created';
    case EventTypes.LEAD_CREATED:
    case EventTypes.LEAD_UPDATED:
    case EventTypes.LEAD_DELETED:
      return 'lead-activation-created';
    case EventTypes.SHOES_UPLOADED:
      return 'upload';
    case EventTypes.USER_INVITED:
    case EventTypes.USER_ADDED:
    case EventTypes.USER_UPDATED:
    case EventTypes.USER_REMOVED:
      return 'members';
    case EventTypes.SUB_ACTIVATED:
    case EventTypes.SUB_CANCELLED:
    case EventTypes.SUB_REACTIVATED:
    case EventTypes.SUB_ENDED:
    case EventTypes.TRIAL_EXTENDED:
      return 'sign';
    case EventTypes.PROSPECT_CREATED:
    case EventTypes.PROSPECT_UPDATED:
    case EventTypes.PROSPECT_TRANSFORMED:
      return 'prospects';
    default:
      return 'info';
  }
};

export const getName = (event: Event): string => {
  switch (getType(event)) {
    case EventTypes.STORE_CREATED:
      return 'Store created';
    case EventTypes.STORE_UPDATED:
      return 'Store updated';
    case EventTypes.STORE_UPGRADED:
      return 'Store upgraded';
    case EventTypes.BRANDING_UPDATED:
      return 'Branding updated';
    case EventTypes.PROMOTIONS_UPDATED:
      return 'Promotions updated';
    case EventTypes.LEAD_CREATED:
      return 'Lead activation created';
    case EventTypes.LEAD_UPDATED:
      return 'Lead activation updated';
    case EventTypes.LEAD_DELETED:
      return 'Lead activation deleted';
    case EventTypes.SHOES_UPLOADED:
      return 'Shoes uploaded';
    case EventTypes.USER_INVITED:
      return 'User invited';
    case EventTypes.USER_ADDED:
      return 'User accepted';
    case EventTypes.USER_UPDATED:
      return 'User updated';
    case EventTypes.USER_REMOVED:
      return 'User removed';
    case EventTypes.SUB_ACTIVATED:
      return 'Subscription signed';
    case EventTypes.SUB_CANCELLED:
      return 'Subscription cancelled';
    case EventTypes.SUB_REACTIVATED:
      return 'Subscription reactivated';
    case EventTypes.SUB_ENDED:
      return 'Subscription ended';
    case EventTypes.TRIAL_EXTENDED:
      return 'Trial extended';
    case EventTypes.PROSPECT_CREATED:
      return 'Prospect created';
    case EventTypes.PROSPECT_UPDATED:
      return 'Prospect updated';
    case EventTypes.PROSPECT_TRANSFORMED:
      return 'Prospect transformed';
    default:
      return 'Unknown activity';
  }
};

// Utils
export const serialize = (data: Event): Event => {
  const event = mergeRight(defaults, data);

  if (event.date) {
    event.date = toMillis(getDate(event));
  }

  return event;
};
