import { RootState } from '../types';

export const selectProspectStores = (state: RootState) =>
  state.prospectStores.prospectStores.data;

export const selectProspectStoresFetching = (state: RootState) =>
  state.prospectStores.prospectStores.fetching;

export const selectProspectStore = (state: RootState) =>
  state.prospectStores.prospectStore.data;

export const selectProspectStorePreview = (state: RootState) =>
  state.prospectStores.prospectStore.preview;

export const selectProspectStoreFetching = (state: RootState) =>
  state.prospectStores.prospectStore.fetching;

export const selectProspectStoreUpdating = (state: RootState) =>
  state.prospectStores.prospectStore.updating;

export const selectProspectStoreLoading = (state: RootState) =>
  state.prospectStores.prospectStore.fetching ||
  state.prospectStores.prospectStore.updating;
