import {
  BillingCycles,
  Gender,
  StoreModule,
  SubscriptionStatus,
  UserRoles,
} from '@atogear/arion-utils';

import {
  ATOContactRoles,
  Competitors,
  EventTypes,
  FontCases,
  FontStyles,
  FontWeights,
  GroupType,
  Product,
  InviteStatus,
  LeadCadenceZones,
  LeadFootstrikeYZones,
  LeadRecipients,
  LeadStabilityXZones,
  LeadTemplates,
  LeadTypes,
  ProspectStatus,
} from '../enums';
import { NotificationList } from '../models/reminderModel';

export const genderIcons = {
  [Gender.OTHER]: '\u26A5',
  [Gender.MALE]: '\u2642',
  [Gender.FEMALE]: '\u2640',
};

export const translatedGenders = {
  [Gender.OTHER]: 'Other',
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female',
};

export const translatedSubscriptionStatus = {
  [SubscriptionStatus.INACTIVE]: 'Not signed',
  [SubscriptionStatus.SIGNED]: 'Signed',
  [SubscriptionStatus.ACTIVE]: 'Active',
  [SubscriptionStatus.CANCELLED]: 'Cancelled',
  [SubscriptionStatus.ENDED]: 'Ended',
};

export const translatedBillingCycles = {
  [BillingCycles.MONTHLY]: 'every month',
  [BillingCycles.YEARLY]: 'every year',
};

export const translatedUserRoles: Record<UserRoles, string> = {
  [UserRoles.ADMIN]: 'Admin',
  [UserRoles.DATA_VIEWER]: 'Data Viewer',
  [UserRoles.INTERNAL_DESIGNER]: 'Internal Designer',
  [UserRoles.BACK_OFFICE]: 'Back Office',
  [UserRoles.INTERNAL_SALES]: 'Internal Sales',
  [UserRoles.EXTERNAL_SALES]: 'External Sales',
  [UserRoles.DISTRIBUTOR]: 'Distributor',
  [UserRoles.GROUP_MANAGER]: 'Group Manager',
  [UserRoles.OWNER]: 'Owner',
  [UserRoles.MANAGER]: 'Manager',
  [UserRoles.EMPLOYEE]: 'Employee',
};

export const translatedATOContactRoles = {
  [ATOContactRoles.SALES]: 'ATOGEAR sales',
  [ATOContactRoles.SUPPORT]: 'ATOGEAR support',
};

export const translatedEventTypes = {
  [EventTypes.STORE_CREATED]: 'Store Created',
  [EventTypes.STORE_UPDATED]: 'Store Updated',
  [EventTypes.STORE_UPGRADED]: 'Store Upgraded',
  [EventTypes.BRANDING_UPDATED]: 'Branding Updated',
  [EventTypes.PROMOTIONS_UPDATED]: 'Promotions Updated',
  [EventTypes.LEAD_CREATED]: 'Lead Activation Created',
  [EventTypes.LEAD_UPDATED]: 'Lead Activation Updated',
  [EventTypes.LEAD_DELETED]: 'Lead Activation Deleted',
  [EventTypes.SHOES_UPLOADED]: 'Shoes Uploaded',
  [EventTypes.USER_INVITED]: 'User Invited',
  [EventTypes.USER_ADDED]: 'User Added',
  [EventTypes.USER_UPDATED]: 'User Updated',
  [EventTypes.USER_REMOVED]: 'User Removed',
  [EventTypes.SUB_ACTIVATED]: 'Subscription Activated',
  [EventTypes.SUB_CANCELLED]: 'Subscription Cancelled',
  [EventTypes.SUB_REACTIVATED]: 'Subscription Reactivated',
  [EventTypes.SUB_ENDED]: 'Subscription Ended',
  [EventTypes.TRIAL_EXTENDED]: 'Trial Extended',
  [EventTypes.PROSPECT_CREATED]: 'Prospect Created',
  [EventTypes.PROSPECT_UPDATED]: 'Prospect Updated',
  [EventTypes.PROSPECT_TRANSFORMED]: 'Prospect Transformed',
};

export const translatedInviteStatus = {
  [InviteStatus.PENDING]: 'Pending',
  [InviteStatus.COMPLETE]: 'Complete',
};

export const translatedGroupType = {
  [GroupType.BRAND]: 'Brand',
  [GroupType.CHAIN]: 'Chain',
  [GroupType.ECOMMERCE_RETAILER]: 'E-commerce Retailer',
  [GroupType.INTERNATIONAL_CHAIN]: 'International Chain',
  [GroupType.MULTI_CATEGORY_SPECIALIST]: 'Multi-Category Specialist',
  [GroupType.RUNNING_SPECIALITY_CHAIN]: 'Running Speciality Chain',
  [GroupType.RUNNING_SPECIALITY_RETAILER]: 'Running Speciality Retailer',
  [GroupType.CLUB]: 'Club',
  [GroupType.OTHER]: 'Other',
};

export const translatedProduct = {
  [Product.HUB]: 'HUB',
  [Product.EHUB]: 'eHUB',
  [Product.STUDIO]: 'STUDIO',
  [Product.PRO]: 'PRO',
  [Product.OTHER]: 'Other',
};

export const translatedProspectStatus = {
  [ProspectStatus.IN_PREPARATION]: 'In Preparation',
  [ProspectStatus.CONTACTED]: 'Contacted',
  [ProspectStatus.NEGOTIATION]: 'Negotiation',
  [ProspectStatus.PENDING]: 'Pending',
  [ProspectStatus.WON]: 'Won',
  [ProspectStatus.LOST]: 'Lost',
  [ProspectStatus.NOT_COMPATIBLE]: 'Not Compatible',
};

export const translatedCompetitors = {
  [Competitors.VOLUMENTAL]: 'Volumental',
  [Competitors.SAFE_SIZE]: 'Safe Size',
  [Competitors.FIT_STATION]: 'Fit Station',
  [Competitors.AETREX]: 'Aetrex',
  [Competitors.DART_FISH]: 'Dart Fish',
  [Competitors.RS_SCAN]: 'RS Scan',
  [Competitors.MOTION_METRIX]: 'Motion Metrix',
  [Competitors.CONTEMPLAS]: 'Contemplas',
  [Competitors.COACHING_EYE]: 'Coaching Eye',
  [Competitors.TEMPLO]: 'Templo',
  [Competitors.CURREX]: 'Currex',
  [Competitors.NURVV]: 'Nurvv',
  [Competitors.OTHER]: 'Other',
};

export const translatedNotificationTimeFrames: Record<
  keyof NotificationList,
  string
> = {
  present: 'Today',
  future: 'Upcoming',
  past: 'Past',
};

export const translatedLeadTypes = {
  [LeadTypes.SCHEDULED]: 'Scheduled',
  [LeadTypes.INSTANT]: 'Instant',
  [LeadTypes.RECURRING]: 'Recurring',
};

export const translatedLeadRecipients = {
  [LeadRecipients.ALL]: 'All customers',
  [LeadRecipients.FILTERED]: 'Selected customers',
};

export const translatedLeadTemplates = {
  [LeadTemplates.BASIC]: 'Basic',
  [LeadTemplates.IMAGE]: 'Image',
};

export const translatedLeadCadenceZones = {
  [LeadCadenceZones.LOW]: 'Cadence low (<140 spm)',
  [LeadCadenceZones.MEDIUM]: 'Cadence med (140-175 spm)',
  [LeadCadenceZones.HIGH]: 'Cadence high (>175 spm)',
};

export const translatedLeadFootstrikeYZones = {
  [LeadFootstrikeYZones.LOW]: 'Footstrike rearfoot',
  [LeadFootstrikeYZones.MEDIUM]: 'Footstrike midfoot',
  [LeadFootstrikeYZones.HIGH]: 'Footstrike forefoot',
};

export const translatedLeadStabilityXZones = {
  [LeadStabilityXZones.LOW]: 'Stability low (<70%)',
  [LeadStabilityXZones.MEDIUM]: 'Stability med (70-90%)',
  [LeadStabilityXZones.HIGH]: 'Stability high (>90%)',
};

export const translatedModules = {
  [StoreModule.COMPARISON]: {
    title: 'Shoe comparison',
    description: 'compare shoe stats.(Example description)',
    icon: 'shoe-comparison-module',
  },
  [StoreModule.CUSTOM_ADVISOR]: {
    title: 'Custom advisor',
    description: 'custom advisor description.(Example description)',
    icon: 'custom-advisor-module',
  },
  [StoreModule.CUSTOM_THEME]: {
    title: 'Custom theme',
    description:
      'Get your own branding features: custom fonts, colors, text here.(Example description)',
    icon: 'theme',
  },
  [StoreModule.VIDEO_MODE]: {
    title: 'Video recording',
    description:
      'Record video sessions and posture analysis.(Example description)',
    icon: 'video-recording-module',
  },
  [StoreModule.WALKING_MODE]: {
    title: 'Walking mode',
    description: 'record sessions with walking.(Example description)',
    icon: 'walking-mode-module',
  },
  [StoreModule.INSOLE_RECOMMENDATION]: {
    title: 'Insole recommendation',
    description:
      'insole recommendation based on sessions.(Example description)',
    icon: 'insole-recommendation-module',
  },
  [StoreModule.SHOE_RECOMMENDATION]: {
    title: 'Shoe recommendation',
    description: 'shoe recommendation based on sessions.(Example description)',
    icon: 'shoe-recommendation-module',
  },
  [StoreModule.ACCELERATED_ONBOARDING]: {
    title: 'Accelerated onboarding',
    description:
      'Accelerate onboarding by scanning client card.(Example description)',
    icon: 'accelerated-onboarding-module',
  },
  [StoreModule.SHOE_MANAGEMENT]: {
    title: 'Shoe management',
    description: 'shoe management description.(Example description)',
    icon: 'shoe-management-module',
  },
  [StoreModule.FUSION_INSOLES]: {
    title: 'Fusion Insoles',
    description:
      'Allows the store to use FUSION insoles when recording sessions',
    icon: 'insole-recommendation-module',
  },
  [StoreModule.POD_INSOLES]: {
    title: 'Pod Insoles',
    description:
      'Allows the store to use insoles with Pods when recording sessions',
    icon: 'insole-recommendation-module',
  },
};

export const translatedFontWeights = {
  [FontWeights[100]]: 'Thin',
  [FontWeights[200]]: 'Lighter',
  [FontWeights[300]]: 'Light',
  [FontWeights[400]]: 'Regular',
  [FontWeights[500]]: 'Medium',
  [FontWeights[600]]: 'Semi Bold',
  [FontWeights[700]]: 'Bold',
  [FontWeights[800]]: 'Bolder',
  [FontWeights[900]]: 'Black',
};

export const translatedFontCases = {
  [FontCases.none]: '',
  [FontCases.capitalize]: 'Capitalized',
  [FontCases.uppercase]: 'Uppercase',
  [FontCases.lowercase]: 'Lowercase',
};

export const translatedFontStyles = {
  [FontStyles.normal]: '',
  [FontStyles.italic]: 'Italic',
};

export const translatedTypographies = {
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  body1: 'Body 1',
  body2: 'Body 2',
  body3: 'Body 3',
  caption: 'Caption',
  a: 'Link',
};
