import { ElementType, FC, HTMLAttributes } from 'react';
import { apply } from '@atogear/arion-utils';
import styled from 'styled-components';

import { getName } from '../../../../models/themeModel';

import { defaultTheme } from '../../../../theme';

import { MediaType, Theme } from '../../../../theme/types';

import { Heading, Tag, Text } from '../../../../components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Heading)`
  margin-bottom: 8px;
`;

const Description = styled(Text)`
  margin-bottom: 32px;
`;

const Images = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

interface ImageProps {
  $first?: boolean;
}

const Image = styled.div<ImageProps>`
  display: flex;
  flex-direction: column;

  @media (min-width: 1600px) {
    ${({ $first }) => ($first ? 'margin-right: 8px;' : 'margin-left: 8px;')};
  }

  @media (max-width: 1600px) {
    ${({ $first }) => ($first ? 'margin-bottom: 8px;' : 'margin-top: 8px;')};
  }
`;

const ThemeName = styled(Heading)`
  margin-bottom: 16px;
`;

const ThemeTag = styled(Tag)`
  margin-left: 8px;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  lightTheme?: Theme;
  darkTheme?: Theme;
  mediaKey: keyof MediaType;
  title: string;
  description: string;
  component: ElementType;
  componentProps: any;
}

const MediaPreview: FC<Props> = ({
  lightTheme,
  darkTheme,
  mediaKey,
  title,
  description,
  component: Component,
  componentProps,
  ...rest
}) => {
  const lightThemeName = apply(getName, lightTheme, '');
  const darkThemeName = apply(getName, darkTheme, '');

  return (
    <Wrapper {...rest}>
      <Title variant="h3">{title}</Title>
      <Description variant="body2">{description}</Description>
      <Images>
        <Image $first>
          <ThemeName variant="h4">
            {lightThemeName}
            <ThemeTag label="LIGHT" />
          </ThemeName>
          <Component
            {...componentProps}
            src={(lightTheme || defaultTheme).media[mediaKey]}
            alt={`${title} (Light)`}
            background={(lightTheme || defaultTheme).colors.light}
          />
        </Image>
        <Image>
          <ThemeName variant="h4">
            {darkThemeName}
            <ThemeTag label="DARK" />
          </ThemeName>
          <Component
            {...componentProps}
            src={(darkTheme || defaultTheme).media[mediaKey]}
            alt={`${title} (Dark)`}
            background={(lightTheme || defaultTheme).colors.dark}
          />
        </Image>
      </Images>
    </Wrapper>
  );
};

export default MediaPreview;
