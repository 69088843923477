import { FC, useCallback, useEffect, useState } from 'react';
import {
  capitalizeEveryWord,
  formatDate,
  Survey,
  userModel,
} from '@atogear/arion-utils';
import { isNil } from 'ramda';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { GetSurveysOptions } from '../../services/surveys';

import { useDispatch, useSelector } from '../../store';
import { SurveyActions } from '../../store/actions';
import { AuthSelectors, SurveysSelectors } from '../../store/selectors';

import { ApiSurvey } from '../../models/apiSurveyModel';

import { surveyColumns, tabOptions } from '../../tables/surveyTable';
import { composeColumns } from '../../utils/tableUtils';

import {
  Button,
  Footer,
  Heading,
  Icon,
  Table,
  Tabs,
  Text,
} from '../../components';
import { SortBy, TableCellData } from '../../components/Table/types';

import ExportSurveyResponsesButton from './ExportSurveyResponsesButton';
import ManageSurveyDialog from './ManageSurveyDialog';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
  padding: 0 60px;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  min-height: 48px;
`;

const Breadcrumb = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BreadcrumbIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 12px;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const SurveyTabs = styled(Tabs)`
  height: 48px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 60px;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
`;

const SurveyTable = styled(Table)`
  max-height: calc(100vh - 330px);
`;

const cells: TableCellData<ApiSurvey>[] = [
  {
    key: 'type',
    renderBody: (survey) => (
      <Text variant="body2">{capitalizeEveryWord(survey.type)}</Text>
    ),
  },
  {
    key: 'numberOfResponses',
    renderBody: (survey) => {
      return <Text variant="body2">{survey.numberOfResponses}</Text>;
    },
  },
  {
    key: 'startAt',
    renderBody: (survey) => {
      return <Text variant="body2">{formatDate(survey.startAt)}</Text>;
    },
  },
  {
    key: 'endAt',
    renderBody: (survey) => {
      return <Text variant="body2">{formatDate(survey.endAt)}</Text>;
    },
  },
  {
    key: 'createdAt',
    renderBody: (survey) => {
      return <Text variant="body2">{formatDate(survey.createdAt)}</Text>;
    },
  },
  {
    key: 'export',
    renderBody: (survey) => {
      return (
        <ExportSurveyResponsesButton
          surveyId={survey.id}
          numberOfResponses={survey.numberOfResponses}
        />
      );
    },
  },
];

const columns = composeColumns(surveyColumns, cells);

const defaultSort: SortBy = {
  key: 'createdAt',
  desc: true,
};

const Surveys: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const surveys = useSelector(SurveysSelectors.selectSurveys);
  const total = useSelector(SurveysSelectors.selectSurveysTotal);
  const fetching = useSelector(SurveysSelectors.selectSurveysFetching);

  const [tabIndex, setTabIndex] = useState(0);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const tabs = [{ label: 'Active' }, { label: 'Inactive' }];

  const [sortBy, setSortBy] = useState<SortBy>(defaultSort);

  const getSurveys = useCallback(
    async (options?: GetSurveysOptions) => {
      try {
        if (!isNil(user)) {
          await dispatch(SurveyActions.getSurveys(options)).unwrap();
        }
      } catch {
        toast('Failed to retrieve surveys!', {
          type: 'error',
        });
      }
    },
    [dispatch, user],
  );

  useEffect(() => {
    getSurveys({
      active: true,
      sortBy: {
        //@ts-ignore
        field: defaultSort.key,
        type: defaultSort.desc ? 'desc' : 'asc',
      },
    });
  }, [getSurveys]);

  const handleSurveyClick = useCallback(
    (survey: ApiSurvey) => {
      dispatch(SurveyActions.setSurvey(survey));

      openEditDialog();
    },
    [dispatch],
  );

  const openEditDialog = () => setEditDialogOpen(true);

  const closeEditDialog = () => setEditDialogOpen(false);

  const handleAddClick = () => {
    dispatch(SurveyActions.surveyPreviewReset());

    dispatch(
      SurveyActions.surveyPreviewUpdate({
        id: '',
      }),
    );

    openEditDialog();
  };

  const handleCreate = async (survey: Survey) => {
    await toast.promise(
      dispatch(
        SurveyActions.createSurvey({
          type: survey.type,
          startAt: survey.startAt,
          endAt: survey.endAt,
        }),
      ).unwrap(),
      {
        pending: 'Creating survey...',
        success: 'Successfully created survey',
        error: 'Failed to create survey',
      },
    );

    closeEditDialog();

    dispatch(SurveyActions.surveyPreviewReset());
  };

  const handleUpdate = async (survey: Survey) => {
    await toast.promise(
      dispatch(
        SurveyActions.updateSurvey({
          surveyId: survey.id,
          data: {
            type: survey.type,
            startAt: survey.startAt,
            endAt: survey.endAt,
          },
        }),
      ).unwrap(),
      {
        pending: 'Updating survey...',
        success: 'Successfully updated survey',
        error: 'Failed to update survey',
      },
    );
  };

  const handleDelete = async (survey: Survey) => {
    await toast.promise(
      dispatch(SurveyActions.deleteSurvey(survey.id)).unwrap(),
      {
        pending: 'Deleting survey...',
        success: 'Successfully deleted survey',
        error: 'Failed to delete survey',
      },
    );

    closeEditDialog();

    dispatch(SurveyActions.surveyPreviewReset());
  };

  const handleOnTabChange = (index: number) => {
    dispatch(SurveyActions.reset());

    setTabIndex(index);

    const tab = tabOptions[index];

    getSurveys({
      active: tab.key === 'active',
      sortBy: {
        //@ts-ignore
        field: sortBy.key,
        type: sortBy.desc ? 'desc' : 'asc',
      },
    });
  };

  const handleOnSortClick = (newSortBy: SortBy) => {
    setSortBy(newSortBy);

    const tab = tabOptions[tabIndex];

    getSurveys({
      active: tab.key === 'active',
      sortBy: {
        //@ts-ignore
        field: newSortBy.key,
        type: newSortBy.desc ? 'desc' : 'asc',
      },
    });
  };

  const requestMoreSurveys = () => {
    if (surveys.length === total || fetching) {
      return;
    }

    const tab = tabOptions[tabIndex];

    getSurveys({
      active: tab.key === 'active',
      offset: surveys.length - 1,
      sortBy: {
        //@ts-ignore
        field: sortBy.key,
        type: sortBy.desc ? 'desc' : 'asc',
      },
    });
  };

  const canCreateSurvey =
    !!user &&
    (userModel.isAdmin(user) ||
      userModel.isBackOffice(user) ||
      userModel.isInternalSales(user));

  return (
    <Wrapper>
      <Header>
        <TopSection>
          <Breadcrumb>
            <BreadcrumbIcon name="assignment" />
            <Heading variant="h3">Surveys</Heading>
          </Breadcrumb>
          {canCreateSurvey && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleAddClick}
            >
              Create new survey
            </Button>
          )}
        </TopSection>
        <BottomSection>
          <SurveyTabs
            tabs={tabs}
            tabWidth={200}
            onTabChange={handleOnTabChange}
          />
        </BottomSection>
      </Header>
      <Content>
        <Toolbar></Toolbar>
        <SurveyTable
          columns={columns}
          data={surveys}
          loading={fetching && total === 0}
          sortBy={sortBy}
          onSort={handleOnSortClick}
          onRowClick={handleSurveyClick}
          onEndReached={() => requestMoreSurveys()}
        />
      </Content>
      <Footer />
      <ManageSurveyDialog
        open={editDialogOpen}
        onCancel={closeEditDialog}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        onCreate={handleCreate}
      />
    </Wrapper>
  );
};

export default Surveys;
