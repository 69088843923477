import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import { trimWhiteSpace } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../store';
import { AuthActions } from '../../store/actions';
import { AuthSelectors } from '../../store/selectors';

import { unauthenticatedRoutes } from '../../routing';

import { Button, Input, InputLabel, NavLink } from '../../components';
import Auth from './Auth';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 24px;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 32px;
`;

const Actions = styled.div`
  margin-bottom: 24px;
`;

const Login: FC = () => {
  const dispatch = useDispatch();

  const initialEmail = useSelector(AuthSelectors.selectEmail);
  const fetching = useSelector(AuthSelectors.selectAuthUserFetching);

  const [email, setEmail] = useState(initialEmail || '');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setEmail(initialEmail || '');
  }, [initialEmail]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        AuthActions.signIn({
          email: trimWhiteSpace(email),
          password: trimWhiteSpace(password),
        }),
      ).unwrap();
    } catch {
      toast('Invalid email or password!', {
        type: 'error',
      });
    }
  };

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setEmail(e.target.value);

  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setPassword(e.target.value);

  return (
    <Auth loading={fetching} title="Welcome to ARIONHUB">
      <StyledForm onSubmit={handleSubmit}>
        <StyledInputLabel htmlFor="email" variant="h3">
          Email
        </StyledInputLabel>
        <StyledInput
          id="email"
          placeholder="Email"
          name="email"
          autoFocus
          autoComplete="email"
          type="email"
          value={email}
          required
          maxLength={50}
          onChange={handleEmailChange}
        />
        <StyledInputLabel htmlFor="password" variant="h3">
          Password
        </StyledInputLabel>
        <StyledInput
          id="password"
          placeholder="Password"
          name="password"
          autoComplete="current-password"
          value={password}
          required
          maxLength={100}
          type="password"
          onChange={handlePasswordChange}
        />
        <Actions>
          <Button color="primary" type="submit">
            Login
          </Button>
        </Actions>
        <NavLink to={unauthenticatedRoutes.forgotPassword.path}>
          Forgot password?
        </NavLink>
      </StyledForm>
    </Auth>
  );
};

export default Login;
