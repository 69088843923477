export enum ATOContactRoles {
  SALES = 'sales',
  SUPPORT = 'support',
}

export enum EventTypes {
  STORE_CREATED = 'storeCreated',
  STORE_UPDATED = 'storeUpdated',
  STORE_UPGRADED = 'storeUpgraded',
  BRANDING_UPDATED = 'brandingUpdated',
  PROMOTIONS_UPDATED = 'promotionsUpdated',
  LEAD_CREATED = 'leadCreated',
  LEAD_UPDATED = 'leadUpdated',
  LEAD_DELETED = 'leadDeleted',
  SHOES_UPLOADED = 'shoesUploaded',
  USER_INVITED = 'userInvited',
  USER_ADDED = 'userAdded',
  USER_UPDATED = 'userUpdated',
  USER_REMOVED = 'userRemoved',
  SUB_ACTIVATED = 'created',
  SUB_CANCELLED = 'cancelled',
  SUB_REACTIVATED = 'reactivated',
  SUB_ENDED = 'ended',
  TRIAL_EXTENDED = 'trialExtended',
  PROSPECT_CREATED = 'prospectCreated',
  PROSPECT_UPDATED = 'prospectUpdated',
  PROSPECT_TRANSFORMED = 'prospectTransformed',
}

export enum EventGroups {
  SUBSCRIPTION = 'Subscription',
  STORE = 'Store',
  THEME = 'Themes',
  BRANDING = 'Branding',
  PROMOTIONS = 'Promotions',
  LEAD = 'Lead Activations',
  USER = 'Accounts',
  SHOES = 'Shoes',
  PAYMENT = 'Payment',
  PROSPECT = 'Prospect',
}

export enum InviteStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
}

export enum GroupType {
  BRAND = 'brand',
  CHAIN = 'chain',
  ECOMMERCE_RETAILER = 'ecommerceRetailer',
  INTERNATIONAL_CHAIN = 'internationalChain',
  MULTI_CATEGORY_SPECIALIST = 'multiCategorySpecialist',
  RUNNING_SPECIALITY_CHAIN = 'runningSpecialityChain',
  RUNNING_SPECIALITY_RETAILER = 'runningSpecialityRetailer',
  CLUB = 'club',
  OTHER = 'other',
}

export enum Product {
  HUB = 'hub',
  EHUB = 'eHub',
  STUDIO = 'studio',
  PRO = 'pro',
  OTHER = 'other',
}

export enum ProspectStatus {
  IN_PREPARATION = 'inPreparation',
  CONTACTED = 'contacted',
  NEGOTIATION = 'negotiation',
  PENDING = 'pending',
  WON = 'won',
  LOST = 'lost',
  NOT_COMPATIBLE = 'notCompatible',
}

export enum Competitors {
  VOLUMENTAL = 'volumental',
  SAFE_SIZE = 'safeSize',
  FIT_STATION = 'fitStation',
  AETREX = 'aetrex',
  DART_FISH = 'dartFish',
  RS_SCAN = 'rsScan',
  MOTION_METRIX = 'motionMetrix',
  CONTEMPLAS = 'contemplas',
  COACHING_EYE = 'coachingEye',
  TEMPLO = 'templo',
  CURREX = 'currex',
  NURVV = 'nurvv',
  OTHER = 'other',
}

export enum LeadTypes {
  SCHEDULED = 'scheduled',
  INSTANT = 'instant',
  RECURRING = 'recurring',
}

export enum LeadRecipients {
  ALL = 'all',
  FILTERED = 'filtered',
}

export enum LeadTemplates {
  BASIC = 'basic',
  IMAGE = 'image',
}

export enum LeadCadenceZones {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum LeadFootstrikeYZones {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum LeadStabilityXZones {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum Locales {
  EN = 'en-GB',
  DE = 'de-DE',
  NL = 'nl-NL',
  ES = 'es-ES',
}

export enum Languages {
  english = 'GB',
  german = 'DE',
  dutch = 'NL',
}

export enum Currencies {
  USD = 'US',
  EUR = 'EU',
  GBP = 'GB',
  CAD = 'CA',
  AUD = 'AU',
  CHF = 'CH',
  NOK = 'NO',
  SEK = 'SE',
  DKK = 'DK',
  BGN = 'BG',
}

export enum DefaultThemeIds {
  ARION_LIGHT = 'arionLight',
  ARION_DARK = 'arionDark',
}

// NOTE: const because enums can not have numeric names
// And because keys are only subset of possible values
export const FontWeights = {
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900',
};

export const FontCases = {
  none: 'none',
  capitalize: 'capitalize',
  uppercase: 'uppercase',
  lowercase: 'lowercase',
};

export const FontStyles = {
  normal: 'normal',
  italic: 'italic',
};
