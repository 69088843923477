import { FC, HTMLAttributes, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import Heading from './Heading';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface ContainerProps {
  $checked?: boolean;
}

const Container = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  box-shadow: ${({ $checked, theme }) =>
    `inset 0px 0px 0px ${$checked ? '7px' : '1px'} ${
      $checked ? theme.colors.primary : theme.colors.textTwo
    }`};
  transition: box-shadow 0.1s ease-in-out;
`;

const Input = styled.input`
  display: none;
`;

const StyledText = styled(Heading)`
  margin-left: 10px;
  display: inline-block;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  checked?: boolean;
  InputProps?: InputHTMLAttributes<HTMLInputElement>;
}

const Radio: FC<Props> = (props) => {
  const { checked, InputProps = {}, children, ...rest } = props;

  return (
    <Wrapper {...rest}>
      <Container $checked={checked}>
        <Input checked={checked} type="radio" {...InputProps} />
      </Container>
      <StyledText variant="h4">{children}</StyledText>
    </Wrapper>
  );
};

export default Radio;
