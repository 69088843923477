import { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { unauthenticatedRoutes } from '../../routing';

import { SentryRoute } from '../../components';

const routes = Object.values(unauthenticatedRoutes);

const AuthRouter: FC = () => (
  <Switch>
    {routes.map((route) => (
      <SentryRoute {...route} />
    ))}
    <Redirect to={unauthenticatedRoutes.login.path} />
  </Switch>
);

export default AuthRouter;
