import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { Event } from '../../models/eventModel';
import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
} from '../utils';
import { reset } from './actions';
import { getEvents } from './thunks';

interface State {
  events: {
    data: Event[];
    fetching: boolean;
    error?: SerializedError;
  };
  event: {
    data?: Event;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  events: {
    data: [],
    fetching: false,
    error: undefined,
  },
  event: {
    data: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Events
    .addCase(getEvents.pending, setFetchingState('events'))
    .addCase(getEvents.fulfilled, (state, { payload }) => {
      state.events.data = payload;
      setFulfilledState('events')(state);
    })
    .addCase(getEvents.rejected, setRejectedState('events'))
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
