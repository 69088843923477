import { createAction } from '@reduxjs/toolkit';
import { User } from '@atogear/arion-utils';

import { Invite } from '../../models/inviteModel';

export const setStoreAccount = createAction<User>(
  'storeAccounts/setStoreAccount',
);

export const resetStoreAccount = createAction<User>(
  'storeAccounts/resetStoreAccount',
);

export const setInvite = createAction<Invite>('storeAccounts/setInvite');

export const resetInvite = createAction<Invite>('storeAccounts/resetInvite');

export const reset = createAction('storeAccounts/reset');
