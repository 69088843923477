import { FC } from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import Heading from './Heading';

const Wrapper = styled(Heading)`
  color: ${({ theme }) =>
    tinycolor(theme.colors.primary).getBrightness() < 200
      ? theme.colors.white
      : theme.colors.dark};
  text-transform: uppercase;
`;

interface Props {
  children?: string;
}

const Initials: FC<Props> = ({ children }) => (
  <Wrapper variant="h4">{children}</Wrapper>
);

export default Initials;
