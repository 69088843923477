import { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';
import {
  ClickAwayListener,
  PopperUnstyled,
  PopperUnstyledProps,
} from '@mui/base';

const Wrapper = styled(PopperUnstyled)`
  background-color: ${({ theme }) => theme.colors.surfaceOne};
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow};
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

export interface PopperProps extends PopperUnstyledProps {
  disableReactTree?: boolean;
  onDismiss: () => void;
}

const Popper: FC<PopperProps> = ({
  disableReactTree,
  open,
  anchorEl,
  onDismiss,
  ...rest
}) => {
  if (!open) {
    return null;
  }

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) =>
    e.stopPropagation();

  return (
    <ClickAwayListener onClickAway={onDismiss} disableReactTree>
      <Wrapper
        open={open}
        onClick={handleClick}
        anchorEl={anchorEl}
        {...rest}
      />
    </ClickAwayListener>
  );
};

export default Popper;
