import { formatDate, isNilOrEmpty, toMillis } from '@atogear/arion-utils';
import { mergeRight } from 'ramda';

const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
});

export interface Invoice {
  id?: string;
  orderId: string;
  invoiceNumber: string;
  amount: number;
  createdAt: number;
  updatedAt?: number;
  paidAt?: number;
  paymentLink?: string;
  downloadLink?: string;
}

export const fields = {
  ID: 'id',
  ORDER_ID: 'orderId',
  INVOICE_NR: 'invoiceNumber',
  AMOUNT: 'amount',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  PAID_AT: 'paidAt',
  PAYMENT_LINK: 'paymentLink',
  DOWNLOAD_LINK: 'downloadLink',
};

export const defaults: Invoice = {
  id: '',
  orderId: '',
  invoiceNumber: '',
  amount: 0,
  createdAt: Date.now(),
  updatedAt: undefined,
  paidAt: undefined,
  paymentLink: '',
  downloadLink: '',
};

export const getId = (invoice?: Invoice) => (invoice || defaults).id;

export const getOrderId = (invoice?: Invoice) => (invoice || defaults).orderId;

export const getInvoiceNumber = (invoice?: Invoice) =>
  (invoice || defaults).invoiceNumber;

// Amount
export const getAmount = (invoice?: Invoice) => (invoice || defaults).amount;

export const getFormattedAmount = (invoice?: Invoice) =>
  CURRENCY_FORMATTER.format(getAmount(invoice));

// Created
export const getCreatedAt = (invoice?: Invoice) =>
  (invoice || defaults).createdAt;

export const getFormattedCreatedAt = (invoice?: Invoice) =>
  formatDate(getCreatedAt(invoice));

// Updated
export const getUpdatedAt = (invoice?: Invoice) =>
  (invoice || defaults).updatedAt;

export const isUpdated = (invoice?: Invoice) =>
  !isNilOrEmpty(getUpdatedAt(invoice));

export const getFormattedUpdatedAt = (invoice?: Invoice) =>
  isUpdated(invoice) ? formatDate(getUpdatedAt(invoice)) : undefined;

// Paid
export const getPaidAt = (invoice?: Invoice) => (invoice || defaults).paidAt;

export const isPaid = (invoice?: Invoice) => !isNilOrEmpty(getPaidAt(invoice));

export const getFormattedPaidAt = (invoice?: Invoice) =>
  isPaid(invoice) ? formatDate(getPaidAt(invoice)) : undefined;

// Payment Link
export const getPaymentLink = (invoice?: Invoice) =>
  (invoice || defaults).paymentLink;

export const hasPaymentLink = (invoice?: Invoice) =>
  !isNilOrEmpty(getPaymentLink(invoice));

// Download Link
export const getDownloadLink = (invoice?: Invoice) =>
  (invoice || defaults).downloadLink;

export const hasDownloadLink = (invoice?: Invoice) =>
  !isNilOrEmpty(getDownloadLink(invoice));

// Utils
export const serialize = (data?: Invoice): Invoice => {
  const invoice = mergeRight(defaults, data || {});

  // Save dates as milliseconds
  invoice.createdAt = toMillis(getCreatedAt(invoice));

  if (invoice.updatedAt) {
    invoice.updatedAt = toMillis(getUpdatedAt(invoice));
  }

  if (invoice.paidAt) {
    invoice.paidAt = toMillis(getPaidAt(invoice));
  }

  return invoice;
};
