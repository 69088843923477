import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  apply,
  BillingCycles,
  formatDate,
  userModel,
} from '@atogear/arion-utils';
import { addDays } from 'date-fns';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import {
  AuthSelectors,
  ProspectSelectors,
  SettingsSelectors,
} from '../../../store/selectors';
import { ProspectActions } from '../../../store/actions';

import { appRoutes, getPath } from '../../../routing';

import { formatYears } from '../../../utils/formatters';

import {
  Collapsible,
  Icon,
  Heading,
  Text,
  InputLabel,
  Button,
} from '../../../components';

import { getBaseMonthlySubPrice } from '../../../models/appSettingsModel';
import { prospectModel } from '../../../models';
import { ProspectRouteParams } from '../types';

import { ProspectStatus } from '../../../enums';
import { Prospect } from '../../../models/prospectModel';

const Wrapper = styled(Collapsible)`
  margin-bottom: 56px;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

interface RowProps {
  $first?: boolean;
  $last?: boolean;
}

const Row = styled(Header)<RowProps>`
  align-items: center;
  justify-content: ${({ $last }) => ($last ? 'flex-end' : 'flex-start')};
  min-height: 56px;
  margin-top: ${({ $first }) => ($first ? '16px' : 0)};
  margin-right: 32px;
`;

const SubType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-right: 24px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 2px;
`;

const Label = styled(Heading)`
  display: flex;
  flex: 1;
  margin-right: 18px;
`;

const Value = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
`;

const StyledText = styled(Text)`
  text-transform: capitalize;
`;

const UploadButton = styled(InputLabel)`
  ${({ theme }) => theme.typography.h3};
  ${({ theme }) => theme.components.buttons.secondary};
  border: ${({ theme }) => `1px solid ${theme.colors.grayOpaque}`};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: 0 12px;
  text-overflow: ellipsis;
  /* Both of the following are required for text-overflow */
  overflow: hidden;
  white-space: nowrap;
  transition: all ease 0.2s;
  margin-right: 8px;
`;

const UploadIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  margin-right: 8px;
`;

interface LinkProps {
  $disabled?: boolean;
}

const DownloadLink = styled(Text)<LinkProps>`
  ${({ theme }) => theme.typography.a};
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
`;

const StyledDownloadLink = styled(DownloadLink)`
  margin-left: 8px;
`;

interface Props {
  open?: boolean;
}

const PaymentInfo: FC<Props> = ({ open }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const user = useSelector(AuthSelectors.selectUser);
  const appSettings = useSelector(SettingsSelectors.selectAppSettings);
  const prospect = useSelector(ProspectSelectors.selectProspect);

  const { prospectId } = useParams<ProspectRouteParams>();

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);

  const status = apply(
    prospectModel.getStatus,
    prospect,
    prospectModel.getStatus(prospectModel.defaults),
  );

  const hasPermissions = isAdmin || isSales;
  const isTransformed = status === ProspectStatus.WON;

  const billingCycle = apply(
    prospectModel.getBillingCycle,
    prospect,
    BillingCycles.MONTHLY,
  );

  const subPrice = useMemo(() => {
    if (!prospect) {
      return undefined;
    }

    return prospectModel.getFormattedSubscriptionPrice(
      prospect,
      getBaseMonthlySubPrice(appSettings),
    );
  }, [appSettings, prospect]);

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    try {
      if (!prospectId) {
        throw new Error('Invalid prospect');
      }

      if (!e.target.files) {
        throw new Error('No file selected');
      }

      const file = e.target.files[0];

      if (!file || file.type !== 'application/pdf') {
        throw new Error('Selected file is not a valid agreement!');
      }

      const buffer = await file.arrayBuffer();

      await dispatch(
        ProspectActions.uploadAgreement({
          prospectId,
          data: buffer,
        }),
      ).unwrap();

      toast('Successfully uploaded an agreement!', {
        type: 'success',
      });
    } catch (error) {
      toast((error as Error)?.message || 'Failed to upload agreement!', {
        type: 'error',
      });
    }
  };

  const handleDownloadClick = useCallback(
    (documentPath: string, isQuote: boolean) => async () => {
      try {
        if (!prospectId) {
          throw new Error('Invalid Prospect');
        }

        const downloadAction = isQuote
          ? ProspectActions.downloadQuote
          : ProspectActions.downloadAgreementProspect;

        const downloadUrl = await dispatch(
          downloadAction({
            prospectId,
            documentPath,
          }),
        ).unwrap();

        const link = document.createElement('a');

        link.href = downloadUrl;
        link.download = `ARIONHUB-${
          isQuote ? 'Quote' : 'Agreement'
        }-${Date.now()}.pdf`;

        link.click();
        link.remove();
      } catch (error) {
        toast((error as Error)?.message || 'Failed to download document!', {
          type: 'error',
        });
      }
    },
    [dispatch, prospectId],
  );

  const handleSignAgreement = () => {
    if (!prospectId) return;

    history.push(
      getPath(appRoutes.prospectAgreement.path, {
        prospectId: prospectId,
      }),
    );
  };

  const handleGenerateQuote = () => {
    if (!prospectId) return;

    history.push(
      getPath(appRoutes.prospectQuote.path, {
        prospectId: prospectId,
      }),
    );
  };

  const billingInfo = useMemo(
    () => [
      {
        key: prospectModel.fields.BILLING_PAYMENT_TERM,
        label: 'Payment term',
        getValue: (prospect?: Prospect) => {
          const subStartDate = apply(
            prospectModel.getSubscriptionStartDate,
            prospect,
            Date.now(),
          );
          const paymentTerm = apply(
            prospectModel.getBillingPaymentTerm,
            prospect,
            30,
          );

          return `Within ${paymentTerm} days (${formatDate(
            addDays(subStartDate, paymentTerm),
          )})`;
        },
      },
      {
        key: 'payment-per-one',
        label: 'Regular payments (First)',
        getValue: () => subPrice?.perCycle || '',
      },
      {
        key: 'payment-total',
        getLabel: (prospect?: Prospect) => {
          const subPeriod = apply(
            prospectModel.getSubscriptionPeriod,
            prospect,
            2,
          );

          return `Total (${formatYears(subPeriod)})`;
        },
        getValue: () => subPrice?.total || '',
      },
      {
        key: 'billing-person',
        label: 'Billing person',
        getValue: (prospect?: Prospect) => {
          const billingName = apply(prospectModel.getBillingName, prospect, '');
          const billingEmail = apply(
            prospectModel.getBillingEmail,
            prospect,
            '',
          );

          return `${billingName}, ${billingEmail}`;
        },
      },
      {
        key: 'documents',
        label: 'Documents',
        renderValue: (prospect?: Prospect) => {
          const agreementPath = apply(
            prospectModel.getAgreementPath,
            prospect,
            '',
          );

          const quotePath = apply(prospectModel.getQuotePath, prospect, '');

          if (!agreementPath && !quotePath) {
            return <Text variant="body2">None uploaded yet</Text>;
          }

          return (
            <>
              {!!agreementPath && (
                <DownloadLink
                  onClick={handleDownloadClick(agreementPath, false)}
                >
                  <StyledIcon name="attachment" />
                  Agreement PDF
                </DownloadLink>
              )}
              {!!quotePath && (
                <StyledDownloadLink
                  onClick={handleDownloadClick(quotePath, true)}
                >
                  <StyledIcon name="attachment" />
                  Quote PDF
                </StyledDownloadLink>
              )}
            </>
          );
        },
      },
    ],
    [handleDownloadClick, subPrice?.perCycle, subPrice?.total],
  );

  return (
    <Wrapper open={open}>
      <Header>
        <SubType>
          <StyledIcon name="star" />
          <Text variant="body2">Custom payment plan</Text>
        </SubType>
        <Label variant="h3">Billing cycle</Label>
        <Value>
          <StyledText variant="body2">{billingCycle}</StyledText>
        </Value>
      </Header>
      {billingInfo.map(
        ({ key, getLabel, label, getValue, renderValue }, index) => (
          <Row key={key} $first={index === 0}>
            <SubType />
            <Label variant="h3">{getLabel ? getLabel(prospect) : label}</Label>
            <Value>
              {renderValue ? (
                renderValue(prospect)
              ) : (
                <Text variant="body2">{getValue(prospect)}</Text>
              )}
            </Value>
          </Row>
        ),
      )}
      {hasPermissions && !isTransformed && (
        <Row $last>
          <UploadButton htmlFor="agreement-upload-btn">
            <UploadIcon name="upload-doc" />
            Upload signed agreement
          </UploadButton>
          <input
            id="agreement-upload-btn"
            accept="application/pdf"
            hidden
            type="file"
            onChange={handleFileChange}
          />
          <StyledButton
            color="primary"
            size="small"
            variant="contained"
            onClick={handleSignAgreement}
          >
            Sign agreement
          </StyledButton>
          <StyledButton
            color="primary"
            size="small"
            variant="contained"
            onClick={handleGenerateQuote}
          >
            Generate quote
          </StyledButton>
        </Row>
      )}
    </Wrapper>
  );
};

export default PaymentInfo;
