import { createReducer, SerializedError } from '@reduxjs/toolkit';
import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import { prospectModel } from '../../models';

import { reset, prospectPreviewReset, prospectPreviewUpdated } from './actions';
import {
  createProspect,
  deleteProspect,
  downloadQuote,
  downloadAgreementProspect,
  getAllProspects,
  getProspect,
  getProspects,
  sendAgreementToContactsProspect,
  transformProspectToStore,
  updateProspect,
  uploadAgreement,
  uploadQuote,
} from './thunks';
import { Prospect } from '../../models/prospectModel';

interface State {
  prospects: {
    data: Prospect[];
    fetching: boolean;
    error?: SerializedError;
  };
  prospect: {
    data?: Prospect;
    preview?: Prospect;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  agreement: {
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  quote: {
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  prospects: {
    data: [],
    fetching: false,
    error: undefined,
  },
  prospect: {
    data: undefined,
    preview: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
  agreement: {
    fetching: false,
    updating: false,
    error: undefined,
  },
  quote: {
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get All Prospects
    .addCase(getAllProspects.pending, setFetchingState('prospects'))
    .addCase(getAllProspects.fulfilled, (state, { payload }) => {
      state.prospects.data = payload;
      setFulfilledState('prospects')(state);
    })
    .addCase(getAllProspects.rejected, setRejectedState('prospects'))
    // Get Prospects
    .addCase(getProspects.pending, setFetchingState('prospects'))
    .addCase(getProspects.fulfilled, (state, { payload }) => {
      state.prospects.data = payload;
      setFulfilledState('prospects')(state);
    })
    .addCase(getProspects.rejected, setRejectedState('prospects'))
    // Get Prospect
    .addCase(getProspect.pending, setFetchingState('prospect'))
    .addCase(getProspect.fulfilled, (state, { payload }) => {
      state.prospect.data = payload;
      state.prospect.preview = payload;
      setFulfilledState('prospect')(state);
    })
    .addCase(getProspect.rejected, setRejectedState('prospect'))
    // Create Prospect
    .addCase(createProspect.pending, setUpdatingState('prospect'))
    .addCase(createProspect.fulfilled, (state) => {
      state.prospect.preview = undefined;
      setFulfilledState('prospect')(state);
    })
    .addCase(createProspect.rejected, setRejectedState('prospect'))
    // Update Prospect
    .addCase(updateProspect.pending, setUpdatingState('prospect'))
    .addCase(updateProspect.fulfilled, (state, { payload }) => {
      state.prospect.data = payload;
      state.prospect.preview = payload;
      setFulfilledState('prospect')(state);
    })
    .addCase(updateProspect.rejected, setRejectedState('prospect'))
    // Transform Prospect
    .addCase(transformProspectToStore.pending, setUpdatingState('prospect'))
    .addCase(transformProspectToStore.fulfilled, (state, { payload }) => {
      state.prospect.data = payload;
      state.prospect.preview = payload;
      setFulfilledState('prospect')(state);
    })
    .addCase(transformProspectToStore.rejected, setRejectedState('prospect'))
    // Delete Store
    .addCase(deleteProspect.pending, setUpdatingState('prospect'))
    .addCase(deleteProspect.fulfilled, (state) => {
      state.prospect.data = undefined;
      state.prospect.preview = undefined;
      setFulfilledState('prospect')(state);
    })
    .addCase(deleteProspect.rejected, setRejectedState('prospect'))
    // Upload Agreement
    .addCase(uploadAgreement.pending, setUpdatingState('agreement'))
    .addCase(uploadAgreement.fulfilled, setFulfilledState('agreement'))
    .addCase(uploadAgreement.rejected, setRejectedState('agreement'))
    // Download Agreement
    .addCase(downloadAgreementProspect.pending, setFetchingState('agreement'))
    .addCase(
      downloadAgreementProspect.fulfilled,
      setFulfilledState('agreement'),
    )
    .addCase(downloadAgreementProspect.rejected, setRejectedState('agreement'))
    // Send Agreement
    .addCase(
      sendAgreementToContactsProspect.pending,
      setFetchingState('agreement'),
    )
    .addCase(
      sendAgreementToContactsProspect.fulfilled,
      setFulfilledState('agreement'),
    )
    .addCase(
      sendAgreementToContactsProspect.rejected,
      setRejectedState('agreement'),
    )
    // Upload Quote
    .addCase(uploadQuote.pending, setUpdatingState('quote'))
    .addCase(uploadQuote.fulfilled, setFulfilledState('quote'))
    .addCase(uploadQuote.rejected, setRejectedState('quote'))
    // Download Quote
    .addCase(downloadQuote.pending, setFetchingState('quote'))
    .addCase(downloadQuote.fulfilled, setFulfilledState('quote'))
    .addCase(downloadQuote.rejected, setRejectedState('quote'))
    // Local
    .addCase(prospectPreviewUpdated, ({ prospect }, { payload }) => {
      prospect.preview = prospectModel.create({
        ...prospect.preview,
        ...payload,
      });
    })
    .addCase(prospectPreviewReset, ({ prospect }) => {
      prospect.preview = undefined;
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
