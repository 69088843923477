import { FC } from 'react';
import { apply, StoreModule } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useSelector } from '../../../store';
import { ProspectSelectors } from '../../../store/selectors';

import { formatYears } from '../../../utils/formatters';

import {
  Collapsible,
  Heading,
  Icon,
  ModuleChip,
  Text,
} from '../../../components';

import { prospectModel } from '../../../models';

import { translatedModules } from '../../../translations';
import { Prospect } from '../../../models/prospectModel';
import { Product } from '../../../enums';

const Wrapper = styled(Collapsible)`
  margin-bottom: 56px;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

interface ItemProps {
  $first?: boolean;
  $last?: boolean;
}

const Row = styled(Header)<ItemProps>`
  align-items: center;
  justify-content: ${({ $last }) => ($last ? 'flex-end' : 'flex-start')};
  min-height: 56px;
  margin-top: ${({ $first }) => ($first ? '16px' : 0)};
  margin-right: 32px;
`;

const SubType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-right: 24px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const Label = styled(Heading)`
  display: flex;
  flex: 1;
  margin-right: 18px;
`;

const Value = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
`;

const ModulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const subInfo = [
  {
    key: prospectModel.fields.SUBSCRIPTION_START_DATE,
    label: 'Start date',
    getValue: (prospect?: Prospect) =>
      apply(prospectModel.getFormattedSubscriptionStartDate, prospect, '-'),
  },
  {
    key: prospectModel.fields.SUBSCRIPTION_END_DATE,
    label: 'End date',
    getValue: (prospect?: Prospect) =>
      apply(prospectModel.getFormattedSubscriptionEndDate, prospect, '-'),
  },
  {
    key: prospectModel.fields.MODULES,
    label: 'Modules',
    renderValue: (prospect?: Prospect) => {
      const modules = apply(
        prospectModel.getModules,
        prospect,
        prospectModel.getModules(prospectModel.defaults),
      );

      const activeModules = Object.keys(modules)
        .filter((key) => modules[key as StoreModule])
        .sort() as StoreModule[];

      return (
        <ModulesContainer>
          {activeModules.length > 0 ? (
            activeModules.map((key) => {
              const { title, icon } = translatedModules[key];

              return (
                <ModuleChip
                  key={`module-${key}`}
                  avatar={<Icon name={icon} />}
                  label={title}
                />
              );
            })
          ) : (
            <Text variant="body2">None</Text>
          )}
        </ModulesContainer>
      );
    },
  },
  {
    key: prospectModel.fields.SUBSCRIPTION_CREATED_AT,
    label: 'Subscription created',
    getValue: (prospect?: Prospect) =>
      apply(prospectModel.getFormattedSubscriptionCreatedAt, prospect, '-'),
  },
];

interface Props {
  open?: boolean;
}

const SubscriptionInfo: FC<Props> = ({ open }) => {
  const prospect = useSelector(ProspectSelectors.selectProspect);

  const subPeriod = apply(prospectModel.getSubscriptionPeriod, prospect, 2);

  const products = apply(
    prospectModel.getProducts,
    prospect,
    prospectModel.getProducts(prospectModel.defaults),
  );

  const isHUB = products.includes(Product.HUB);

  return (
    <Wrapper open={open}>
      <Header>
        <SubType>
          <StyledIcon name="star" />
          <Text variant="body2">Subscription</Text>
        </SubType>
        <Label variant="h3">Duration</Label>
        <Value>
          <Text variant="body2">{formatYears(subPeriod)}</Text>
        </Value>
      </Header>
      {subInfo.map(({ key, label, getValue, renderValue }, index) => {
        if (!isHUB && key === prospectModel.fields.MODULES) {
          return null;
        }

        return (
          <Row key={key} $first={index === 0}>
            <SubType />
            <Label variant="h3">{label}</Label>
            <Value>
              {renderValue ? (
                renderValue(prospect)
              ) : (
                <Text variant="body2">{getValue(prospect)}</Text>
              )}
            </Value>
          </Row>
        );
      })}
    </Wrapper>
  );
};

export default SubscriptionInfo;
