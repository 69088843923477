import {
  ChangeEventHandler,
  FC,
  Fragment,
  MouseEventHandler,
  useState,
} from 'react';
import {
  allHaveKey,
  filterByKey,
  filterByKeys,
  findByKey,
  sortColumns,
} from '../../utils/tableUtils';

import { TableColumn } from '../Table/types';
import ViewColumnsButton from './ViewColumnsButton';
import ViewColumnsModal from './ViewColumnsModal';

interface Props {
  filters?: string[];
  columns: TableColumn[];
  selectedColumns: TableColumn[];
  onChange: (columns: TableColumn[]) => void;
}

const ColumnFilter: FC<Props> = ({
  filters,
  columns,
  selectedColumns,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleToggleOpen: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };

  const handleDismiss = () => setAnchorEl(null);

  const handleColumnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      // Find selected column
      const column = findByKey(columns, e.target.id);

      if (!column) {
        return;
      }

      // Add column to the list of visible table columns
      const next = selectedColumns.slice();

      // Re-adds actions column when another is added
      if (next.length === 0) {
        const actionsColumn = findByKey(columns, 'actions');

        if (actionsColumn) {
          next.push(actionsColumn);
        }
      }

      next.push(column);

      // Sort the array in original form
      onChange(next.sort(sortColumns(columns)));
    } else {
      // Remove unchecked column from the array of visible table columns
      const filtered = filterByKey(selectedColumns, e.target.id);

      // Removes actions column when only column left
      if (filtered.length === 1 && allHaveKey(filtered, 'actions')) {
        onChange([]);
      } else {
        onChange(filtered);
      }
    }
  };

  return (
    <Fragment>
      <ViewColumnsButton onClick={handleToggleOpen} />
      <ViewColumnsModal
        anchorEl={anchorEl}
        columns={filters ? filterByKeys(columns, filters) : columns}
        selectedColumns={selectedColumns}
        onColumnChange={handleColumnChange}
        onDismiss={handleDismiss}
      />
    </Fragment>
  );
};

export default ColumnFilter;
