import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthUser, User, userModel, UserRoles } from '@atogear/arion-utils';

import { getAccountService, getAuthService } from '../../services';

import { validateChangePassword, validateEmail } from '../../utils/validators';

import { RootState } from '../types';

import { authUserChanged, authUserReset, emailUpdated } from './actions';

/**
 * @category store
 * @module AuthStore
 */

interface Config {
  state: RootState;
}

export const init: AsyncThunk<void, void, Config> = createAsyncThunk<
  void,
  void,
  Config
>(
  'auth/init',
  /**
   * Add listener to auth state change event.
   */
  (_, { dispatch, getState }) => {
    getAuthService().onAuthStateChanged(async (user) => {
      const authUser = getState().auth.authUser.data;

      // User signed in
      if (user && !authUser) {
        // Set authUser in state
        dispatch(
          authUserChanged({
            uid: user.uid,
            email: user.email || '',
          }),
        );
        // Get user document and set user in state
        dispatch(getUser(user.uid));

        const { claims } = await user.getIdTokenResult();

        if (claims.role === UserRoles.OWNER) {
          getAccountService().logOwnerSignIn();
        }
      }
      // No user signed in
      else if (!user) {
        dispatch(authUserReset());
      }
    });
  },
);

interface SignInParams {
  email: string;
  password: string;
}

export const signIn = createAsyncThunk(
  'auth/signIn',
  /**
   * Sign in with an email and password.
   *
   * @returns Promise which resolves to AuthUser when it signed in successfully.
   */
  async (params: SignInParams, { dispatch }): Promise<AuthUser> => {
    const { email, password } = params;

    validateEmail(email);

    const { user } = await getAuthService().signIn(email, password);

    if (!user) {
      throw new Error('Invalid email or password');
    }

    dispatch(emailUpdated(email));

    return {
      uid: user.uid,
      email: user.email || '',
    };
  },
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  /**
   * Sign out.
   *
   * @returns Promise which resolves when it signed out successfully.
   */
  (): Promise<boolean> => getAuthService().signOut(),
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  /**
   * Sends a reset password email to specified email.
   *
   * @param email Email.
   * @returns Promise which resolves when it sent email successfully.
   */
  async (email: string, { dispatch }): Promise<void> => {
    validateEmail(email);

    await getAuthService().sendPasswordResetEmail(email);

    dispatch(emailUpdated(email));
  },
);

interface ChangePasswordParams {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

/**
 * Change the current signed in user's password.
 *
 * @returns Promise which resolves when the password has been changed successfully.
 */
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  (params: ChangePasswordParams): Promise<void> => {
    const { currentPassword, newPassword, confirmPassword } = params;

    validateChangePassword(currentPassword, newPassword, confirmPassword);

    return getAuthService().changePassword(currentPassword, newPassword);
  },
);

interface SetPasswordParams {
  newPassword: string;
  confirmPassword: string;
}

export const setPassword: AsyncThunk<User, SetPasswordParams, Config> =
  createAsyncThunk<User, SetPasswordParams, Config>(
    'auth/setPassword',
    /**
     * Change current signed in user's password.
     *
     * @returns Promise which resolves when password has been changed successfully.
     */
    async (params: SetPasswordParams, { getState }): Promise<User> => {
      const { newPassword, confirmPassword } = params;

      const user = getState().auth.user.data;

      if (!user) {
        throw new Error('No user signed in!');
      }

      const currentPassword = userModel.getTempPassword(user);

      if (!currentPassword) {
        throw new Error('Password already set!');
      }

      validateChangePassword(currentPassword, newPassword, confirmPassword);

      await getAuthService().changePassword(currentPassword, newPassword);

      const accountService = getAccountService();

      const userId = userModel.getId(user);

      await accountService.removeTempPassword(userId);

      const updatedUser = await accountService.getUser(userId);

      return updatedUser;
    },
  );

export const getUser = createAsyncThunk(
  'auth/getUser',
  /**
   * Get User.
   *
   * @param id User's id.
   * @returns Promise that resolves to User.
   */
  async (id: string): Promise<User> => {
    const user = await getAccountService().getUser(id);

    return user;
  },
);
