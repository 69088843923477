import { getFirestorePipe } from '@atogear/ato-broker';

import { AppSettings } from '../models/appSettingsModel';

const getSettingsService = () => {
  /**
   * Get App Settings.
   *
   * @returns Promise that resolves to App Settings.
   */
  const getAppSettings = async (): Promise<AppSettings> => {
    const appSettings = await getFirestorePipe().getDocument(
      'appSettings',
      'arionhub_admin',
    );

    return appSettings as AppSettings;
  };

  return {
    getAppSettings,
  };
};

export default getSettingsService;
