import { FC, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import SignatureCanvas, {
  ReactSignatureCanvasProps,
} from 'react-signature-canvas';
import Icon from '../Icon';

const Wrapper = styled.div`
  margin-top: 8px;
  position: relative;
`;

const StyledSignatureCanvas = styled(SignatureCanvas)`
  margin-top: 0px;
  ${(props) => props.theme.components.tiles.default}
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 24px;
  background-color: ${(props) => props.theme.colors.surfaceOne};
  padding: 4px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;
interface Props extends ReactSignatureCanvasProps {
  width: number | string;
  height: number | string;
  onChange?: (signature: string) => void;
  onClear?: () => void;
  className?: string;
}

const DocumentSignatureCanvas: FC<Props> = ({
  width,
  height,
  onChange,
  onClear,
  className,
  ...rest
}) => {
  const canvasRef = useRef<SignatureCanvas | null>(null);
  const theme = useTheme();

  const handleClear = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
    }

    if (onClear) {
      onClear();
    }
  };

  const handleChange = (e: MouseEvent) => {
    if (onChange) {
      if (canvasRef.current) {
        const data = canvasRef.current.toDataURL().toString();
        onChange(data);
      }
    }
  };

  return (
    <Wrapper>
      <StyledSignatureCanvas
        {...rest}
        penColor={theme.colors.text}
        onEnd={handleChange}
        ref={canvasRef}
        canvasProps={{
          width: width,
          height: height,
          className: className,
        }}
      />
      <CloseIcon name="close" onClick={handleClear} />
    </Wrapper>
  );
};

export default DocumentSignatureCanvas;
