import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import { getStatsService } from '../../services';

import { StaffData } from '../../models/apiEmployeeInfoModel';

import { PerformanceStats, SessionStats, VisitStats } from '../../types/stats';
import { RootState } from '../types';

export const getStores = createAsyncThunk('stats/getStores', async () => {
  const stores = await getStatsService().getStores();

  return stores;
});

interface Config {
  state: RootState;
}

export const getSessionStats: AsyncThunk<SessionStats[], void, Config> =
  createAsyncThunk<SessionStats[], void, Config>(
    'stats/getSessionStats',
    async (_, { getState }): Promise<SessionStats[]> => {
      const { filters } = getState().stats;

      const stats = await getStatsService().getSessionStats(filters);

      return stats;
    },
  );

export const getVisitStats: AsyncThunk<VisitStats[], void, Config> =
  createAsyncThunk<VisitStats[], void, Config>(
    'stats/getVisitStats',
    async (_, { getState }): Promise<VisitStats[]> => {
      const { filters } = getState().stats;

      const stats = await getStatsService().getVisitStats(filters);

      return stats;
    },
  );

export const getStaffStats: AsyncThunk<StaffData[], void, Config> =
  createAsyncThunk<StaffData[], void, Config>(
    'stats/getStaffStats',
    async (_, { getState }) => {
      const { filters } = getState().stats;

      const staff = await getStatsService().getStaffStats(filters);

      return staff;
    },
  );

export const getPerformanceStats: AsyncThunk<PerformanceStats[], void, Config> =
  createAsyncThunk<PerformanceStats[], void, Config>(
    'stats/getPerformanceStats',
    async (_args, { getState }): Promise<PerformanceStats[]> => {
      const { filters } = getState().stats;

      const stats = await getStatsService().getPerformanceStats(filters, {
        sessionType: 'running',
      });

      return stats;
    },
  );
