import { ApiPerformance } from '../models/apiPerformanceModel';
import { ApiSession, Derivatives } from '../models/apiSessionModel';

export const sumSessionToDerivatives = (
  prev: Derivatives,
  current: ApiSession,
) => ({
  runnerType: {
    foreFoot:
      prev.runnerType.foreFoot + current.derivatives.runnerType.foreFoot,
    midFoot: prev.runnerType.midFoot + current.derivatives.runnerType.midFoot,
    rearFoot:
      prev.runnerType.rearFoot + current.derivatives.runnerType.rearFoot,
    unknown: prev.runnerType.unknown + current.derivatives.runnerType.unknown,
  },
  sessionType: {
    running: prev.sessionType.running + current.derivatives.sessionType.running,
    walking: prev.sessionType.walking + current.derivatives.sessionType.walking,
    unknown: prev.sessionType.unknown + current.derivatives.sessionType.unknown,
  },
  cadence: {
    low: prev.cadence.low + current.derivatives.cadence.low,
    mid: prev.cadence.mid + current.derivatives.cadence.mid,
    high: prev.cadence.high + current.derivatives.cadence.high,
    unknown: prev.cadence.unknown + current.derivatives.cadence.unknown,
  },
  runnerEfficiency: {
    recreational:
      prev.runnerEfficiency.recreational +
      current.derivatives.runnerEfficiency.recreational,
    experienced:
      prev.runnerEfficiency.experienced +
      current.derivatives.runnerEfficiency.experienced,
    elite:
      prev.runnerEfficiency.elite + current.derivatives.runnerEfficiency.elite,
  },
});

export const sumSessionPerformance = (
  prev: Derivatives,
  current: ApiPerformance,
) => ({
  runnerType: {
    foreFoot: prev.runnerType.foreFoot + current.runnerType.foreFoot,
    midFoot: prev.runnerType.midFoot + current.runnerType.midFoot,
    rearFoot: prev.runnerType.rearFoot + current.runnerType.rearFoot,
    unknown: prev.runnerType.unknown + current.runnerType.unknown,
  },
  sessionType: {
    running: prev.sessionType.running + current.sessionType.running,
    walking: prev.sessionType.walking + current.sessionType.walking,
    unknown: prev.sessionType.unknown + current.sessionType.unknown,
  },
  cadence: {
    low: prev.cadence.low + current.cadence.low,
    mid: prev.cadence.mid + current.cadence.mid,
    high: prev.cadence.high + current.cadence.high,
    unknown: prev.cadence.unknown + current.cadence.unknown,
  },
  runnerEfficiency: {
    recreational:
      prev.runnerEfficiency.recreational +
      current.runnerEfficiency.recreational,
    experienced:
      prev.runnerEfficiency.experienced + current.runnerEfficiency.experienced,
    elite: prev.runnerEfficiency.elite + current.runnerEfficiency.elite,
  },
});

export const defaultDerivatives: Derivatives = {
  runnerType: {
    rearFoot: 0,
    midFoot: 0,
    foreFoot: 0,
    unknown: 0,
  },
  sessionType: {
    running: 0,
    walking: 0,
    unknown: 0,
  },
  cadence: {
    low: 0,
    mid: 0,
    high: 0,
    unknown: 0,
  },
  runnerEfficiency: {
    recreational: 0,
    experienced: 0,
    elite: 0,
  },
};
