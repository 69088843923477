import { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

import { Heading, Icon } from '../../../../components';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${(props) => props.theme.colors.textTwo};
  border-radius: 4px;
  padding: 24px;
  margin-right: 16px;
  min-width: 280px;
  max-width: 280px;
  height: 175px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  transition: background-color 0.1s ease-in-out;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  :active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:last-child {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 18px;
  margin-right: 8px;
  margin-top: -2px;
  ::before {
    color: ${(props) => props.theme.colors.primary};
  }
`;

interface Props {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const AddContactTile: FC<Props> = (props) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <StyledIcon name={'add-circle'}></StyledIcon>
      <Heading variant="h3">Add contact person</Heading>
    </Container>
  );
};

export default AddContactTile;
