import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apply, userModel, UserRoles } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { AuthActions } from '../../../store/actions';
import { AuthSelectors } from '../../../store/selectors';

import { unauthenticatedRoutes } from '../../../routing';

import { translatedUserRoles } from '../../../translations';

import { Button, Heading, Icon, Text } from '../../../components';
import ChangePasswordDialog from './ChangePasswordDialog';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  padding: 0 40px;
  margin-top: 2px;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  flex: 2;
`;

const Value = styled.div`
  display: flex;
  flex: 3;
  flex-direction: row;
`;

const ClickableText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  cursor: pointer;
`;

const DisabledText = styled(Text)`
  color: ${({ theme }) => theme.colors.grayOpaque};
`;

const LogoutIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.danger};
  margin-right: 4px;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 24px;
`;

const Settings: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);

  const history = useHistory();

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);

  const handleSignOut = async () => {
    try {
      await dispatch(AuthActions.signOut()).unwrap();

      history.push(unauthenticatedRoutes.login.path);
    } catch (error) {
      toast((error as Error)?.message || 'Failed to sign out!', {
        type: 'error',
      });
    }
  };

  const firstName = apply(userModel.getFirstName, user, '');
  const lastName = apply(userModel.getLastName, user, '');
  const role = apply(userModel.getRole, user, UserRoles.EMPLOYEE);
  const email = apply(userModel.getEmail, user, '');

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Settings</ContentHeading>
        </Info>
      </Header>
      <Content>
        <Row>
          <StyledHeading variant="h3">First name</StyledHeading>
          <Value>
            <Text variant="body2">{firstName}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Last name</StyledHeading>
          <Value>
            <Text variant="body2">{lastName}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Role</StyledHeading>
          <Value>
            <DisabledText variant="body2">
              {translatedUserRoles[role]}
            </DisabledText>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Email address</StyledHeading>
          <Value>
            <DisabledText variant="body2">{email}</DisabledText>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Password</StyledHeading>
          <Value>
            <ClickableText
              onClick={() => setChangePasswordDialogOpen(true)}
              variant="body2"
            >
              Change password
            </ClickableText>
          </Value>
        </Row>
        <Actions>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleSignOut}
          >
            <LogoutIcon name="logout" /> Log out
          </Button>
        </Actions>
      </Content>
      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        onClose={() => setChangePasswordDialogOpen(false)}
        onCancel={() => setChangePasswordDialogOpen(false)}
      />
    </Wrapper>
  );
};

export default Settings;
