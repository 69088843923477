import { createAsyncThunk } from '@reduxjs/toolkit';
import { User, userModel, UserRoles } from '@atogear/arion-utils';

import { getAccountService } from '../../services';

export const getAccounts = createAsyncThunk(
  'accounts/getAccounts',
  /**
   * Get Accounts.
   *
   * @returns Promise that resolves to Accounts.
   */
  async (): Promise<User[]> => {
    const accounts = await getAccountService().getUsers({
      where: {
        fieldName: userModel.fields.ROLE,
        operator: 'in',
        fieldValue: [
          UserRoles.ADMIN,
          UserRoles.DATA_VIEWER,
          UserRoles.BACK_OFFICE,
          UserRoles.INTERNAL_DESIGNER,
          UserRoles.INTERNAL_SALES,
          UserRoles.EXTERNAL_SALES,
          UserRoles.DISTRIBUTOR,
        ],
      },
    });

    // Sort by role
    return accounts.sort(
      (a, b) => userModel.getRoleIndex(a) - userModel.getRoleIndex(b),
    );
  },
);

export const getAccount = createAsyncThunk(
  'accounts/getAccount',
  /**
   * Get Account.
   *
   * @param id Account's id.
   * @returns Promise that resolves to Account.
   */
  async (id: string): Promise<User> => {
    const account = await getAccountService().getUser(id);

    return account;
  },
);

interface CreateAccountParams {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
}

export const createAccount = createAsyncThunk(
  'accounts/createAccount',
  /**
   * Create Account.
   *
   * @param params Account's data.
   * @returns Promise that resolves to created Account.
   */
  async (params: CreateAccountParams): Promise<User> => {
    const { email, password, firstName, lastName, role } = params;

    const result = await getAccountService().createAccount(
      email,
      password,
      firstName,
      lastName,
      role,
    );

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.account;
  },
);

interface UpdateAccountParams {
  userId: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
}

export const updateAccount = createAsyncThunk(
  'accounts/updateAccount',
  /**
   * Update Account.
   *
   * @param params Account's data.
   * @returns Promise that resolves to updated Account.
   */
  async (params: UpdateAccountParams): Promise<User> => {
    const { userId, firstName, lastName, role } = params;

    const result = await getAccountService().updateAccount(
      userId,
      firstName,
      lastName,
      role,
    );

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.account;
  },
);

export const deleteAccount = createAsyncThunk(
  'accounts/deleteAccount',
  /**
   * Delete Account.
   *
   * @param id Account's id.
   * @returns Promise that resolves when Account has been successfully deleted.
   */
  async (id: string): Promise<void> => {
    const result = await getAccountService().deleteAccount(id);

    if (!result.data.success) {
      throw result.data.error;
    }
  },
);
