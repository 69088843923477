import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  TextareaHTMLAttributes,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

interface TextAreaProps {
  $error?: boolean;
}

interface WrapperProps extends TextAreaProps {
  $disabled?: boolean;
  $focused?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  border-color: ${({ $focused, theme }) =>
    $focused ? theme.colors.primary : theme.colors.grayOpaque};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  overflow: hidden;
`;

const StyledTextArea = styled.textarea<TextAreaProps>`
  ${({ theme }) => theme.typography.body2};
  background-color: transparent;
  border: none;
  caret-color: ${({ $error, theme }) =>
    $error ? theme.colors.danger : theme.colors.primary};
  display: flex;
  flex: 1;
  resize: none;

  &:focus {
    outline: none;
  }
`;

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  validate?: (value: string) => boolean;
}

const TextArea: FC<Props> = ({
  className,
  disabled,
  validate,
  onBlur,
  onFocus,
  onChange,
  ...rest
}) => {
  const inputEl = useRef<HTMLTextAreaElement | null>(null);

  const [error, setError] = useState(false);

  const [focused, setFocused] = useState(false);

  const checkInput = (value: string) => {
    if (validate) {
      setError(!validate(value));
    }
  };

  const handleClick = () => inputEl.current && inputEl.current.focus();

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    setFocused(false);

    checkInput(e.target.value);

    onBlur && onBlur(e);
  };

  const handleFocus: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    setFocused(true);

    checkInput(e.target.value);

    onFocus && onFocus(e);
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    checkInput(e.target.value);

    onChange && onChange(e);
  };

  return (
    <Wrapper
      className={className}
      $disabled={disabled}
      $error={error}
      $focused={focused}
      onClick={handleClick}
    >
      <StyledTextArea
        {...rest}
        ref={inputEl}
        $error={error}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
      />
    </Wrapper>
  );
};

export default TextArea;
