import {
  FC,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import { useSelector } from '../../../../store';
import { ProspectSelectors } from '../../../../store/selectors';

import { Button, Dialog, DialogActions } from '../../../../components';
import Address from './Address';
import Contact from './Contact';
import Extra from './Extra';
import General from './General';

import { prospectModel } from '../../../../models';
import { Prospect } from '../../../../models/prospectModel';
import { isNilOrWhitespace } from '@atogear/arion-utils';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 900px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
  overflow-y: auto;
  max-height: 72vh;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.span`
  background-color: ${({ theme }) => theme.colors.grayOpaque};
  height: 1px;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

interface Props {
  open?: boolean;
  prospect?: Prospect;
  onClose?: () => void;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: (prospect: Partial<Prospect>) => void;
}

const ProspectDialog: FC<Props> = ({
  open,
  prospect,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const loading = useSelector(ProspectSelectors.selectProspectLoading);

  const [data, setData] = useState(prospectModel.create(prospect));

  useEffect(() => {
    if (prospect) {
      setData(prospect);
    }
  }, [prospect]);

  const handleChange = (value: Partial<Prospect>) =>
    setData((prev) => ({
      ...prev,
      ...value,
    }));

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      onConfirm && onConfirm(data);
    },
    [data, onConfirm],
  );

  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const disabled =
    ![data.name, data.type].every(handleValidate) || data.products.length < 1;

  return (
    <Dialog
      loading={loading}
      open={open}
      title="Prospect profile"
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <General prospect={data} onChange={handleChange} />
          <Divider />
          <Row>
            <Address prospect={data} onChange={handleChange} />
            <Contact prospect={data} onChange={handleChange} />
          </Row>
          <Divider />
          <Extra prospect={data} onChange={handleChange} />
        </Content>
        <DialogActions>
          <CancelButton
            disabled={loading}
            size="small"
            type="button"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </CancelButton>
          <Button
            color="primary"
            disabled={loading || disabled}
            size="small"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ProspectDialog;
