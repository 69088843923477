import { createAction } from '@reduxjs/toolkit';
import { Survey } from '@atogear/arion-utils';
import { ApiSurvey } from '../../models/apiSurveyModel';

export const setSurvey = createAction<ApiSurvey>('surveys/setSurvey');
export const surveyPreviewUpdate = createAction<Partial<Survey>>(
  'surveys/surveyPreviewUpdate',
);

export const surveyPreviewReset = createAction('surveys/surveyPreviewReset');
export const reset = createAction('surveys/reset');
