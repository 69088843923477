import { useEffect } from 'react';
import { KeyValues } from './types';

const useWindowKeyListener = (key: KeyValues, callbackFunction?: Function) => {
  useEffect(() => {
    const handleKeyup = (e: KeyboardEvent) => {
      if (e.key === key) {
        callbackFunction && callbackFunction();
      }
    };

    window.addEventListener('keyup', handleKeyup);

    return () => window.removeEventListener('keyup', handleKeyup);
  }, [callbackFunction, key]);
};

export default useWindowKeyListener;
