import { Languages, Currencies } from '../enums';

export const getLanguageCountryCode = (language: string) => {
  const allowedLanguages = Object.keys(Languages);
  if (allowedLanguages.indexOf(language) < 0) {
    language = 'english';
  }

  return Languages[language as keyof typeof Languages].toLowerCase();
};

export const getCurrencyCountryCode = (currency: string) => {
  const allowedCurrencies = Object.keys(Currencies);

  if (allowedCurrencies.indexOf(currency) < 0) {
    currency = 'EUR';
  }

  return Currencies[currency as keyof typeof Currencies].toLowerCase();
};
