import { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

import Heading from './Heading';
import Icon from './Icon';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${(props) => props.theme.colors.highlight};
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
  min-height: 48px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  transition: background-color 0.1s ease-in-out;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  :active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 18px;
  margin-right: 8px;
  margin-top: -2px;
  ::before {
    color: ${(props) => props.theme.colors.primary};
  }
`;

interface Props {
  text: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const AddTile: FC<Props> = (props) => {
  const { text, onClick, ...rest } = props;

  return (
    <Container onClick={onClick} {...rest}>
      <StyledIcon name={'add-circle'}></StyledIcon>
      <Heading variant="h3">Add new {text}</Heading>
    </Container>
  );
};

export default AddTile;
