import { FC } from 'react';
import styled from 'styled-components';
import Chip, { ChipProps } from './Chip';

interface WrapperProps {
  $dark?: boolean;
  $selected?: boolean;
}

const Wrapper = styled.section<WrapperProps>`
  background-color: ${({ theme, $dark, $selected }) => {
    if (!$dark) {
      return $selected ? theme.colors.primary : theme.colors.light;
    }

    return $selected ? theme.colors.primary : theme.colors.dark;
  }};
  border: 1px solid
    ${({ theme, $dark, $selected }) => {
      if (!$dark) {
        return theme.colors.grayOpaque;
      }

      return $selected ? theme.colors.primary : theme.colors.grayOpaque;
    }};
  border-radius: 24px;
  margin: 0.25rem;
  transition: all 0.2s linear;

  p,
  span {
    color: ${({ theme, $dark }) => ($dark ? theme.colors.white : 'auto')};
    transition: color 0.2s linear;
  }
`;

const StyledChip = styled(Chip)`
  padding: 0.5em 0.5em 0.5em 1em;
`;

interface Props extends ChipProps {
  dark?: boolean;
  selected?: boolean;
}

const ModuleChip: FC<Props> = ({
  avatar,
  button,
  label,
  dark,
  selected,
  onClick,
}) => (
  <Wrapper $dark={dark} $selected={selected}>
    <StyledChip
      avatar={avatar}
      button={button}
      label={label}
      onClick={onClick}
    />
  </Wrapper>
);

export default ModuleChip;
