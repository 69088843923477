import axios from 'axios';

import {
  ExternalSurvey,
  Survey,
  SurveyResponse,
  surveyResponseModel,
} from '@atogear/arion-utils';

import getAuthService from './auth';
import { ApiSurvey, serialize } from '../models/apiSurveyModel';

interface APIResponse<T extends any> {
  data: {
    data: T;
    error?: Error;
  };
}

const getAPIUrl = (path: string) => `${process.env.REACT_APP_HUB_API}${path}`;

export interface GetSurveysOptions {
  offset?: number;
  limit?: number;
  active?: boolean;
  sortBy?: {
    field:
      | 'type'
      | 'startAt'
      | 'endAt'
      | 'numberOfResponses'
      | 'updatedAt'
      | 'createdAt';
    type: 'asc' | 'desc';
  };
}

const getSurveysService = () => {
  const call = async <T extends object | null | string | number>(
    method: string,
    path: string,
    body?: Record<string, any>,
  ): Promise<APIResponse<T>> => {
    const idToken = await getAuthService().getIdToken();

    return axios({
      method,
      url: getAPIUrl(path),
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: body,
    });
  };

  const getSurveys = async (
    options?: GetSurveysOptions,
  ): Promise<{ items: ApiSurvey[]; total: number }> => {
    let path = '/surveys';

    if (options) {
      const query: string[] = ['limit=20', 'shouldCountResponses=true'];

      Object.keys(options).forEach((key) => {
        const value = options[key as keyof typeof options];

        if (typeof value === 'object') {
          query.push(key + '=' + JSON.stringify(value));
        } else {
          query.push(key + '=' + value!.toString());
        }
      });

      if (query.length > 0) {
        path += '?' + query.join('&');
      }
    }

    const response = await call<{ items: ExternalSurvey[]; total: number }>(
      'get',
      path,
    );

    if (response.data.error) {
      throw response.data.error;
    }

    return {
      items: response.data.data.items.map(serialize),
      total: response.data.data.total,
    };
  };

  const getSurvey = async (surveyId: string): Promise<ApiSurvey> => {
    const response = await call<ExternalSurvey>('get', `/surveys/${surveyId}`);

    if (response.data.error) {
      throw response.data.error;
    }

    return serialize(response.data.data);
  };

  const createSurvey = async (
    args: Pick<Survey, 'type' | 'startAt' | 'endAt'>,
  ): Promise<ApiSurvey> => {
    const response = await call<ExternalSurvey>('post', `/surveys`, args);

    if (response.data.error) {
      throw response.data.error;
    }

    return serialize(response.data.data);
  };

  const updateSurvey = async (
    surveyId: string,
    args: Partial<Pick<Survey, 'type' | 'startAt' | 'endAt'>>,
  ): Promise<ApiSurvey> => {
    const response = await call<ExternalSurvey>(
      'put',
      `/surveys/${surveyId}`,
      args,
    );

    if (response.data.error) {
      throw response.data.error;
    }

    return serialize(response.data.data);
  };

  const deleteSurvey = async (surveyId: string): Promise<string> => {
    const response = await call<{ id: string }>(
      'delete',
      `/surveys/${surveyId}`,
    );

    if (response.data.error) {
      throw response.data.error;
    }

    return response.data.data.id;
  };

  const getSurveyResponses = async (surveyId: string) => {
    const response = await call<SurveyResponse[]>(
      'get',
      `/surveys/${surveyId}/responses`,
    );

    if (response.data.error) {
      throw response.data.error;
    }

    return response.data.data.map(surveyResponseModel.serialize);
  };

  return {
    getSurveys,
    getSurvey,
    createSurvey,
    updateSurvey,
    deleteSurvey,
    getSurveyResponses,
  };
};

export default getSurveysService;
