import { FC } from 'react';

import { Theme } from '../../../../theme/types';

import { ImagePreview } from '../../../../components';
import { MediaPreview, MediaTile } from '../Components';

interface Props {
  lightTheme?: Theme;
  darkTheme?: Theme;
}

const FlyerSection: FC<Props> = ({ lightTheme, darkTheme }) => (
  <MediaTile>
    <MediaPreview
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      mediaKey="flyerHeaderURL"
      title="Digital flyer header"
      description="A main banner image displays at the top of the page, adding a visual element that sets the tone for your digital flyer."
      component={ImagePreview}
      componentProps={{
        height: 300,
        width: 475,
      }}
    />
  </MediaTile>
);

export default FlyerSection;
