import { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import Icon from './Icon';

interface WrapperProps {
  $checked?: boolean;
}

const Wrapper = styled.label<WrapperProps>`
  background-color: ${({ $checked, theme }) =>
    $checked ? theme.colors.primary : theme.colors.white};
  border-radius: 2px;
  border: 2px solid
    ${({ $checked, theme }) =>
      $checked ? 'transparent' : theme.colors.textTwo};
  color: ${({ $checked, theme }) =>
    $checked ? theme.colors.background : 'transparent'};
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  transition: background-color ease 0.2s;
`;

const Input = styled.input`
  display: none;
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {}

const Checkbox: FC<Props> = ({ className, checked, ...rest }) => (
  <Wrapper className={className} $checked={checked}>
    <Input checked={checked} type="checkbox" {...rest} />
    <Icon name="checkmark" />
  </Wrapper>
);

export default Checkbox;
