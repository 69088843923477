import { RootState } from '../types';

export const selectStores = (state: RootState) => state.stores.stores.data;

export const selectStoresFetching = (state: RootState) =>
  state.stores.stores.fetching;

export const selectStore = (state: RootState) => state.stores.store.data;

export const selectStorePreview = (state: RootState) =>
  state.stores.store.preview;

export const selectStoreFetching = (state: RootState) =>
  state.stores.store.fetching;

export const selectStoreUpdating = (state: RootState) =>
  state.stores.store.updating;

export const selectStoreLoading = (state: RootState) =>
  state.stores.store.fetching || state.stores.store.updating;

export const selectInvoices = (state: RootState) => state.stores.invoices.data;

export const selectInvoicesFetching = (state: RootState) =>
  state.stores.invoices.fetching;

export const selectAgreementFetching = (state: RootState) =>
  state.stores.agreement.fetching;

export const selectAgreementUpdating = (state: RootState) =>
  state.stores.agreement.updating;

export const selectAgreementLoading = (state: RootState) =>
  state.stores.agreement.fetching || state.stores.agreement.updating;
