import {
  deleteUndefinedFields,
  RequiredAny,
  validateOptionalNumber,
  validateRequiredNumber,
  validateRequiredObject,
} from '@atogear/arion-utils';
import { set, startOfMonth, startOfWeek, subMinutes } from 'date-fns';

import { DateType, TimeFrameId } from '../types/stats';

export const getDateByTimeFrameId = (
  dateType: DateType,
  { year, month, week, day, hour }: TimeFrameId,
) => {
  let date = set(Date.now(), {
    year,
    month: month || 0,
    date: day || (week ? week * 7 : 0),
    hours: hour || 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  if (dateType === 'week') {
    date = startOfWeek(date);
  } else if (dateType === 'month') {
    date = startOfMonth(date);
  }

  return subMinutes(date, date.getTimezoneOffset());
};

export const validateTimeFrameId = (data: RequiredAny): TimeFrameId => {
  validateRequiredObject(data, '_id');

  return deleteUndefinedFields({
    year: validateRequiredNumber(data.year, '_id.year'),
    day: validateOptionalNumber(data.day, '_id.day'),
    hour: validateOptionalNumber(data.hour, '_id.hour'),
    week: validateOptionalNumber(data.week, '_id.week'),
    month: validateOptionalNumber(data.month, '_id.month'),
  });
};
