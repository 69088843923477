import { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../../../components/Icon';
import { useSelector } from '../../../store';
import { userRoutes } from '../../../routing';

import Popper from '../../../components/Popper';
import NotificationItem from './NotificationItem';
import { ReminderSelectors } from '../../../store/selectors';

import {
  NotificationList,
  transformToNotifications,
} from '../../../models/reminderModel';
import { translatedNotificationTimeFrames } from '../../../translations';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media (max-width: 1400px) {
    margin-left: 24px;
  }
`;

const NotificationMenu = styled.ul`
  list-style: none;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  width: 220px;
  padding: 8px;
`;

const NotificationsContainer = styled.div`
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0px;
  }
`;

const NotificationsHeading = styled.p`
  ${({ theme }) => theme.typography.h3};
`;

interface IconProps {
  $notifications?: boolean;
}

const StyledIcon = styled(Icon)<IconProps>`
  color: ${({ $notifications, theme }) =>
    $notifications ? theme.colors.primary : theme.colors.textTwo};
`;

const StyledParagraph = styled.p`
  ${({ theme }) => theme.typography.body1};
  padding: 8px 16px 8px 16px;
`;

const Notifications: FC = () => {
  const reminders = useSelector(ReminderSelectors.selectReminders);
  const notificationList = transformToNotifications(reminders);

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const anchorEl = useRef<HTMLDivElement | null>(null);

  const toggleOpen = () => setOpen((prev) => !prev);

  const handleClose = () => setOpen(false);

  const handleNotificationClick = () => {
    handleClose();

    history.push(userRoutes.reminders.path);
  };

  const hasNotifications = Object.values(notificationList).some(
    (notifications) => notifications.length > 0,
  );

  return (
    <Wrapper onClick={toggleOpen}>
      <StyledIcon
        name="notification"
        fontSize={30}
        ref={anchorEl}
        $notifications={
          notificationList.future.length > 0 ||
          notificationList.present.length > 0
        }
      />
      <Popper anchorEl={anchorEl.current} open={open} onDismiss={handleClose}>
        {hasNotifications ? (
          <NotificationMenu>
            {Object.keys(notificationList).map((key) => {
              const notifications =
                notificationList[key as keyof NotificationList];

              if (notifications.length < 1) {
                return null;
              }

              const sortedNotifications = notifications.sort(
                (firstNotification, secondNotification) =>
                  firstNotification.triggerOn - secondNotification.triggerOn,
              );

              return (
                <NotificationsContainer key={key}>
                  <NotificationsHeading>
                    {
                      translatedNotificationTimeFrames[
                        key as keyof NotificationList
                      ]
                    }
                  </NotificationsHeading>
                  {sortedNotifications.map((notification) => (
                    <NotificationItem
                      key={notification.reminderId}
                      title={notification.title}
                      content={notification.content}
                      date={notification.triggerOn}
                      onClick={handleNotificationClick}
                    ></NotificationItem>
                  ))}
                </NotificationsContainer>
              );
            })}
          </NotificationMenu>
        ) : (
          <StyledParagraph>No notifications!</StyledParagraph>
        )}
      </Popper>
    </Wrapper>
  );
};

export default Notifications;
