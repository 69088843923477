import { ChangeEventHandler, FC, useEffect, useId, useState } from 'react';
import { apply, isNilOrWhitespace } from '@atogear/arion-utils';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import {
  DatePicker,
  DialogInput,
  DialogInputLabel,
} from '../../../../components';
import { Reminder, checkDate } from '../../../../models/reminderModel';
import { reminderModel } from '../../../../models';
import { setHours, setMinutes } from 'date-fns';
import { GeneralReminderData } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const StyledTextField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledDialogInput = styled(DialogInput)`
  width: 50%;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface Props {
  reminder?: Reminder;
  onChange: (value: GeneralReminderData) => void;
}

const General: FC<Props> = ({ reminder, onChange }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [triggerOn, setTriggerOn] = useState(reminderModel.defaults.triggerOn);

  useEffect(() => {
    setTitle(apply(reminderModel.getTitle, reminder, ''));
    setContent(apply(reminderModel.getContent, reminder, ''));
    setTriggerOn(
      apply(
        reminderModel.getTriggerOn,
        reminder,
        reminderModel.defaults.triggerOn,
      ),
    );
  }, [reminder]);

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleChangeTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setTitle(value);

    debouncedOnChange({
      title: value,
    });
  };

  const handleChangeContent: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setContent(value);

    debouncedOnChange({
      content: value,
    });
  };

  const handleTriggerOnChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    const validatedDate = isValidHalfHour(date.getMinutes())
      ? date
      : checkDate(date);

    const value = validatedDate.getTime();

    setTriggerOn(value);

    onChange({
      triggerOn: value,
    });
  };

  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const isValidHalfHour = (minutes: number) => {
    return minutes % 30 === 0;
  };

  const filterPassedTime = (date: Date) => {
    const currentDate = new Date();
    const selectedDate = date;

    return currentDate.getTime() < selectedDate.getTime();
  };

  const setMaxTime = () => {
    return setHours(setMinutes(new Date(), 30), 23);
  };

  const setMinTime = () =>
    new Date(triggerOn).getDate() === new Date().getDate()
      ? new Date()
      : setHours(setMinutes(new Date(), 0), 24);

  const id = useId();
  const titleId = `${id}-title`;
  const contentId = `${id}-content`;
  const triggerOnId = `${id}-triggerOn`;

  return (
    <Wrapper>
      <StyledTextField>
        <StyledInputLabel htmlFor="title">Title</StyledInputLabel>
        <StyledDialogInput
          id={titleId}
          placeholder="Title"
          value={title}
          required
          maxLength={30}
          validate={handleValidate}
          onChange={handleChangeTitle}
        />
      </StyledTextField>
      <StyledTextField>
        <StyledInputLabel htmlFor="content">Content</StyledInputLabel>
        <DialogInput
          id={contentId}
          placeholder="Content"
          value={content}
          required
          maxLength={80}
          validate={handleValidate}
          onChange={handleChangeContent}
        />
      </StyledTextField>
      <StyledInputLabel htmlFor="content">Trigger on</StyledInputLabel>
      <DatePicker
        customInput={<DialogInput />}
        id={triggerOnId}
        selected={new Date(triggerOn)}
        onChange={handleTriggerOnChange}
        showTimeSelect
        timeIntervals={30}
        dateFormat="dd-MM-yyyy HH:mm"
        timeFormat="HH:mm"
        minDate={new Date()}
        minTime={setMinTime()}
        maxTime={setMaxTime()}
        filterTime={filterPassedTime}
      />
    </Wrapper>
  );
};

export default General;
