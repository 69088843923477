import colors from './colors';
import { ComponentsType } from './types';

const components: ComponentsType = {
  buttons: {
    primary: {
      backgroundColor: colors.primary,
      color: '#FFFFFF',
      borderRadius: 4,
    },
    secondary: {
      backgroundColor: colors.secondary,
      color: '#FFFFFF',
      borderRadius: 4,
    },
  },
  input: {
    borderRadius: 6,
  },
  navigation: {
    borderRadius: 6,
  },
  tiles: {
    default: {
      borderRadius: 4,
    },
    session: {
      borderRadius: 6,
    },
    shoe: {
      backgroundColor: '#FFFFFF',
      borderRadius: 6,
    },
  },
};

export default components;
