import { ChangeEventHandler, FC, useEffect, useId, useState } from 'react';
import { apply } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ProspectActions } from '../../../store/actions';
import { ProspectSelectors } from '../../../store/selectors';

import {
  Button,
  Heading,
  Icon,
  InputLabel,
  Text,
  TextArea,
} from '../../../components';

import { prospectModel } from '../../../models';
import { Prospect } from '../../../models/prospectModel';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: 32px;
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 48px;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 60px 48px 60px;
`;

const PrevIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 8px;
`;

interface Props {
  onPrev: () => void;
  onSubmit: (prospect: Prospect) => void;
}

const Notes: FC<Props> = ({ onPrev, onSubmit }) => {
  const dispatch = useDispatch();

  const prospect = useSelector(ProspectSelectors.selectProspectPreview);

  const [notes, setNotes] = useState('');

  useEffect(() => {
    setNotes(apply(prospectModel.getNotes, prospect, ''));
  }, [prospect]);

  const handleNotesChange: ChangeEventHandler<HTMLTextAreaElement> = (e) =>
    setNotes(e.target.value);

  const id = useId();
  const notesId = `${id}-notes`;

  const getData = () =>
    prospectModel.create({
      ...prospect,
      notes,
    });

  const handlePrevClick = () => {
    dispatch(ProspectActions.prospectPreviewUpdated(getData()));

    onPrev();
  };

  const handleNextClick = () => onSubmit(getData());

  return (
    <Wrapper>
      <Content>
        <Form>
          <StyledHeading variant="h1">Anything else?</StyledHeading>
          <StyledText variant="body2">
            Add any relevant information regarding the store.
          </StyledText>
          <StyledInputLabel htmlFor={notesId}>Notes</StyledInputLabel>
          <TextArea
            id={notesId}
            placeholder="Notes"
            rows={5}
            value={notes}
            onChange={handleNotesChange}
          />
        </Form>
      </Content>
      <Buttons>
        <Button variant="outlined" onClick={handlePrevClick}>
          <PrevIcon name="chevron-back" />
          Previous
        </Button>
        <Button onClick={handleNextClick}>Create prospect</Button>
      </Buttons>
    </Wrapper>
  );
};

export default Notes;
