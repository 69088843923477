import { RequiredAny } from '@atogear/arion-utils';
import { z } from 'zod';

export const UploadedShoeSchema = z.object({
  brand: z.string(),
  model: z.string(),
  ean: z.string(),
});

export interface UploadedShoe extends z.infer<typeof UploadedShoeSchema> {}

export const serialize = (data: RequiredAny): UploadedShoe =>
  UploadedShoeSchema.parse(data);
