import { FC, TableHTMLAttributes, useEffect, useMemo, useState } from 'react';
import {
  ascend,
  clone,
  descend,
  isEmpty,
  Ordering,
  sort,
  sortWith,
} from 'ramda';
import styled from 'styled-components';

import { getFilters, getSortBy } from './utils';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

import { Filter, SortBy, TableProps } from './types';

interface WrapperProps {
  $empty?: boolean;
}

const Wrapper = styled.table<WrapperProps>`
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow};
  display: flex;
  ${({ $empty }) => $empty && 'flex: 1'};
  flex-direction: column;
`;

interface Props extends TableHTMLAttributes<HTMLTableElement>, TableProps {
  initialSortBy?: SortBy;
  handleSort?: (a: any, b: any) => Ordering;
  /**
   * @param sortBy this prop is used for external sort managing
   */
  sortBy?: SortBy;
  onSort?: (sort: SortBy) => void;
}

const Table: FC<Props> = ({
  columns,
  data,
  initialSortBy,
  loading,
  handleSort,
  sortBy: sortByFromProps,
  onSort,
  onRowClick,
  endOffset,
  onEndReached,
  ...rest
}) => {
  const [filters, setFilters] = useState(getFilters(columns, data));

  const [sortBy, setSortBy] = useState(
    initialSortBy ||
      getSortBy(columns.filter(({ sort }) => sort === true)[0].key, true),
  );

  // Filter data
  const filtered = useMemo(() => {
    // No columns with filter option
    if (isEmpty(filters)) {
      return data;
    }

    return data.filter((row) =>
      filters.reduce((exclude: boolean, f) => {
        if (exclude) {
          return exclude;
        }

        const column = columns.find((c) => c.filter && c.key === f.key);

        return column ? f.values.includes(column.getValue(row)) : false;
      }, false),
    );
  }, [columns, data, filters]);

  // Sort data
  const sortedData = useMemo(() => {
    if (sortByFromProps) {
      return filtered;
    }

    const { getValue } =
      columns.find((c) => c.key === sortBy.key) || columns[0];

    const sortFn = sortBy.desc ? descend(getValue) : ascend(getValue);

    return handleSort
      ? sortWith([handleSort, sortFn], filtered)
      : sort(sortFn, filtered);
  }, [columns, filtered, handleSort, sortBy.desc, sortBy.key, sortByFromProps]);

  useEffect(() => {
    setFilters(getFilters(columns, data));
  }, [columns, data]);

  const handleHeaderCellClick = (key: string) => {
    if (sortByFromProps) {
      const newSortBy = getSortBy(
        key,
        // If already sorted, reverse sort order; else desc sort order
        key === sortByFromProps.key ? !sortByFromProps.desc : true,
      );

      if (onSort) {
        onSort(newSortBy);
      }

      return;
    }

    setSortBy((prev) => {
      const newSortBy = getSortBy(
        key,
        // If already sorted, reverse sort order; else desc sort order
        key === prev.key ? !prev.desc : true,
      );

      if (onSort) {
        onSort(newSortBy);
      }

      return newSortBy;
    });
  };

  const handleFilterChange = (filter: Filter) =>
    setFilters((prev) => {
      const index = prev.findIndex((f) => f.key === filter.key);

      // Sanity check
      if (index === -1) {
        return prev;
      }

      const newFilters = clone(prev);

      newFilters[index] = filter;

      // Save the changes to the session storage for user convenience
      const serializedFilterValues = JSON.stringify(filter.values);

      sessionStorage.setItem(newFilters[index].key, serializedFilterValues);

      return newFilters;
    });

  return (
    <Wrapper $empty={!loading && isEmpty(sortedData)} {...rest}>
      <TableHeader
        columns={columns}
        filters={filters}
        sortBy={sortByFromProps || sortBy}
        onCellClick={handleHeaderCellClick}
        onFilterChange={handleFilterChange}
      />
      <TableBody
        columns={columns}
        data={sortedData}
        loading={loading}
        onRowClick={onRowClick}
        endOffset={endOffset}
        onEndReached={onEndReached}
      />
    </Wrapper>
  );
};

export default Table;
