import { FC } from 'react';
import styled from 'styled-components';

import { contactModel } from '../../../../models';

import {
  Avatar,
  Heading,
  Icon,
  Initials,
  MouseTooltip,
  Text,
} from '../../../../components';

import { copyTextToClipboard } from '../../../../utils/helperUtils';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  user-select: none;
  padding: 16px 24px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  margin-right: 16px;
  max-width: 280px;
  min-width: 280px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  :active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const RemoveIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.danger};
`;

const CopyIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.gray || 'gray'};
  cursor: pointer;
  margin-left: 8px;
`;

const MemberNameContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const MemberEmail = styled.div`
  margin-top: 4px;
  display: flex;
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  contact: contactModel.Contact;
  onEdit?: (user: contactModel.Contact) => void;
  onRemove?: (user: contactModel.Contact) => void;
}

const ContactTile: FC<Props> = (props) => {
  const { contact, onEdit, onRemove } = props;

  return (
    <Container>
      <HeaderContainer>
        <Avatar size="large">
          <Initials>{contactModel.getInitials(contact)}</Initials>
        </Avatar>
        <Actions>
          <MouseTooltip tooltip="Remove">
            <Action onClick={() => (onRemove ? onRemove(contact) : null)}>
              <RemoveIcon name="delete" />
            </Action>
          </MouseTooltip>
          <MouseTooltip tooltip="Edit">
            <Action onClick={() => (onEdit ? onEdit(contact) : null)}>
              <Icon name="edit" />
            </Action>
          </MouseTooltip>
        </Actions>
      </HeaderContainer>
      <MemberNameContainer>
        <Heading variant="h3">{contactModel.getFullName(contact)}</Heading>
        <Text variant="body2">{contactModel.getRole(contact)}</Text>
      </MemberNameContainer>
      <MemberEmail>
        <StyledText variant="body2">
          {contactModel.getEmail(contact)}
        </StyledText>
        <MouseTooltip tooltip="Copy">
          <CopyIcon
            name="copy"
            onClick={() => copyTextToClipboard(contactModel.getEmail(contact))}
          />
        </MouseTooltip>
      </MemberEmail>
      <StyledText variant="body2">
        {contactModel.getPhoneNumber(contact)}
      </StyledText>
    </Container>
  );
};

export default ContactTile;
