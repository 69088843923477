import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { Survey } from '@atogear/arion-utils';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import {
  reset,
  setSurvey,
  surveyPreviewReset,
  surveyPreviewUpdate,
} from './actions';
import {
  createSurvey,
  deleteSurvey,
  getSurvey,
  getSurveys,
  updateSurvey,
} from './thunks';
import { ApiSurvey } from '../../models/apiSurveyModel';

interface State {
  surveys: {
    data: ApiSurvey[];
    total: number;
    fetching: boolean;
    error?: SerializedError;
  };
  survey: {
    data?: ApiSurvey;
    preview?: Survey;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  surveys: {
    data: [],
    total: 0,
    fetching: false,
    error: undefined,
  },
  survey: {
    data: undefined,
    preview: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const checkIsSurveyActive = (survey: Survey) => {
  const now = Date.now();

  return survey.startAt < now && survey.endAt < now;
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Surveys
    .addCase(getSurveys.pending, setFetchingState('surveys'))
    .addCase(getSurveys.fulfilled, (state, { payload }) => {
      if (payload.offset) {
        state.surveys.data.splice(
          payload.offset,
          payload.items.length,
          ...payload.items,
        );
      } else {
        state.surveys.data = payload.items;
      }
      state.surveys.total = payload.total;
      setFulfilledState('surveys')(state);
    })
    .addCase(getSurveys.rejected, setRejectedState('surveys'))
    // Get Surveys
    .addCase(getSurvey.pending, setFetchingState('survey'))
    .addCase(getSurvey.fulfilled, (state, { payload }) => {
      state.survey.data = payload;
      state.survey.preview = payload;
      setFulfilledState('survey')(state);
    })
    .addCase(getSurvey.rejected, setRejectedState('survey'))
    // Create Survey
    .addCase(createSurvey.pending, setUpdatingState('survey'))
    .addCase(createSurvey.fulfilled, (state, { payload }) => {
      const surveyIndex = state.surveys.data.findIndex(
        (s) => s.id === payload.id,
      );

      const isNewSurveyActive = checkIsSurveyActive(payload);

      const areRestSame =
        state.surveys.data.find(
          (s) => checkIsSurveyActive(s) === isNewSurveyActive,
        ) !== undefined;

      // only add survey if its of the same type(active/inactive) and it is not yet present
      if (surveyIndex === -1 && areRestSame) {
        state.surveys.data.unshift(payload);
      }

      setFulfilledState('survey')(state);
    })
    .addCase(createSurvey.rejected, setRejectedState('survey'))
    // Update Surveys
    .addCase(updateSurvey.pending, setUpdatingState('survey'))
    .addCase(updateSurvey.fulfilled, (state, { payload }) => {
      state.survey.data = payload;
      state.survey.preview = payload;

      const surveyIndex = state.surveys.data.findIndex(
        (s) => s.id === payload.id,
      );

      const isUpdatedSurveyActive = checkIsSurveyActive(payload);

      const areRestSame =
        state.surveys.data.find(
          (s) => checkIsSurveyActive(s) === isUpdatedSurveyActive,
        ) !== undefined;

      // if survey is present and still the same(active/inactive) as rest of the list update it
      if (surveyIndex > -1 && areRestSame) {
        state.surveys.data[surveyIndex] = payload;
      }
      // if survey is present and NOT the same(active/inactive) as rest of the list remove it
      else if (surveyIndex > -1 && !areRestSame) {
        state.surveys.data.splice(surveyIndex, 1);
      }
      // if survey is the same(active/inactive) as rest of the list but missing add it
      else if (surveyIndex === -1 && areRestSame) {
        state.surveys.data.push(payload);
      }

      setFulfilledState('survey')(state);
    })
    .addCase(updateSurvey.rejected, setRejectedState('survey'))
    // Delete Surveys
    .addCase(deleteSurvey.pending, setUpdatingState('survey'))
    .addCase(deleteSurvey.fulfilled, (state, { payload }) => {
      if (state.survey.data?.id === payload) {
        state.survey.data = undefined;
        state.survey.preview = undefined;
      }

      const surveyIndex = state.surveys.data.findIndex((s) => s.id === payload);

      if (surveyIndex > -1) {
        state.surveys.data.splice(surveyIndex, 1);
      }

      setFulfilledState('survey')(state);
    })
    .addCase(deleteSurvey.rejected, setRejectedState('survey'))

    // Local
    .addCase(setSurvey, (state, { payload }) => {
      state.survey.data = payload;
      state.survey.preview = payload;
    })
    .addCase(surveyPreviewUpdate, (state, { payload }) => {
      state.survey.preview = {
        ...state.survey.preview!,
        ...payload,
      };
    })
    .addCase(surveyPreviewReset, (state) => {
      state.survey.preview = undefined;
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
