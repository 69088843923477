import { FC } from 'react';
import { Store } from '@atogear/arion-utils';
import styled from 'styled-components';

import { Theme } from '../../theme/types';

import Icon from '../Icon';
import MouseTooltip from '../MouseTooltip';
import AppPrimaryButton from './AppPrimaryButton';
import AppSecondaryButton from './AppSecondaryButton';
import AppTile from './AppTile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppPreview = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px dashed gray;
  width: 100%;
`;

const Header = styled.div<StyleProps>`
  background-color: ${(props) => props.previewTheme.colors.surfaceOne};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 90px;
  padding: 12px;
`;

const Logo = styled.img<StyleProps>`
  width: 240px;
  height: 60px;
`;

const StyledIcon = styled(Icon)<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.previewTheme.colors.text};
  font-size: 34px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${(props) => props.previewTheme.colors.surfaceTwo};
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.shadow};
  margin-right: 24px;
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledBody = styled.div<StyleProps>`
  background-color: ${(props) => props.previewTheme.colors.background};
  height: 100%;
  width: 100%;
  padding: 50px 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledAppHeading = styled.div<StyleProps>`
  ${(props) => props.previewTheme.typography.h1}
  margin-bottom: 24px;
`;

const StyledAppSubHeading = styled.div<StyleProps>`
  ${(props) => props.previewTheme.typography.caption}
  margin-bottom: 17px;
`;

const PrimaryButtonTooltip = styled(MouseTooltip)`
  margin-top: 16px;
  align-self: flex-end;
`;

interface StyleProps {
  previewTheme: Theme;
}

interface Props {
  previewTheme: Theme;
  store?: Partial<Store>;
}

const ThemePreview: FC<Props> = ({ previewTheme, store, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <AppPreview>
        <Header previewTheme={previewTheme}>
          <MouseTooltip tooltip={'Full Logo'}>
            <Logo
              previewTheme={previewTheme}
              src={previewTheme.media.fullLogoURL}
            />
          </MouseTooltip>
          <HeaderRight>
            <StyledIcon name="print-report" previewTheme={previewTheme} />
            <MouseTooltip tooltip={'Secondary Button'}>
              <AppSecondaryButton previewTheme={previewTheme} />
            </MouseTooltip>
          </HeaderRight>
        </Header>
        <StyledBody previewTheme={previewTheme}>
          <MouseTooltip tooltip={'Heading 1'}>
            <StyledAppHeading previewTheme={previewTheme}>
              Discover your running identity
            </StyledAppHeading>
          </MouseTooltip>
          <MouseTooltip tooltip={'Caption'}>
            <StyledAppSubHeading previewTheme={previewTheme}>
              This session was recorded at
            </StyledAppSubHeading>
          </MouseTooltip>
          <AppTile previewTheme={previewTheme} store={store} />
          <PrimaryButtonTooltip tooltip={'Primary Button'}>
            <AppPrimaryButton previewTheme={previewTheme} />
          </PrimaryButtonTooltip>
        </StyledBody>
      </AppPreview>
    </Wrapper>
  );
};

export default ThemePreview;
