import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import initBrokerPipes from './pipes';
import { store } from './store';
import { defaultTheme } from './theme';
import Fallback from './views/App/Error/Fallback';
import Root from './Root';
import './index.css';
// ARIONHUB Admin icon font
import './assets/icons/style.css';
// Country flags icon font
import 'flag-icons/css/flag-icons.min.css';
// react-pdf Annotation Layer css
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const history = createBrowserHistory();

const Application = Sentry.withProfiler(() => (
  <Provider store={store}>
    <ThemeProvider theme={defaultTheme}>
      <Router history={history}>
        <Root />
      </Router>
    </ThemeProvider>
  </Provider>
));

const render = async () => {
  const container = document.getElementById('root');

  const RootWithErrorBoundary = Sentry.withErrorBoundary(Application, {
    fallback: (props) => <Fallback {...props} />,
  });

  const root = createRoot(container!);

  root.render(<RootWithErrorBoundary />);
};

const init = async () => {
  console.log(
    `Welcome to the ARIONHUB admin dashboard you are currently viewing app version: ${process.env.REACT_APP_VERSION}`,
  );

  await initBrokerPipes();

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    // For react-router integration
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    // For redux integration
    normalizeDepth: 10,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  Sentry.setTag('appVersion', process.env.REACT_APP_VERSION);

  // Disables the sentry SDK when on a local environment to prevent clutter in Sentry
  // Comment out this line if you want to test Sentry locally
  if (process.env.REACT_APP_ENVIRONMENT === 'local') Sentry.init({});

  render();
};

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
