import { FC } from 'react';
import styled from 'styled-components';
import { alphaToHex } from '../../../utils/colorUtils';

interface Props {
  title: string;
  content: string;
  date: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const StyledTitle = styled.p`
  ${({ theme }) => theme.typography.body1};
`;

const StyledContent = styled.p`
  ${({ theme }) => theme.typography.body2};
`;

const StyledDate = styled.p`
  ${({ theme }) => theme.typography.body3};
`;

const StyledNotification = styled.div`
  word-wrap: break-word;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 8px;
  transition: background-color ease 0.2s;

  &:hover,
  &:focus,
  &[aria-selected='true'] {
    background-color: ${({ theme }) =>
      theme.colors.secondary + alphaToHex(0.3)};
  }
`;

const NotificationItem: FC<Props> = ({ title, content, date, onClick }) => {
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <StyledNotification onClick={onClick}>
      <StyledTitle>{title}</StyledTitle>
      <StyledContent>{content}</StyledContent>
      <StyledDate>{`${formattedDate} ${formattedTime}`}</StyledDate>
    </StyledNotification>
  );
};
export default NotificationItem;
