import { FC } from 'react';
import styled from 'styled-components';

import {
  Invite,
  getEmail,
  getFormattedRole,
  getFullName,
  getStatus,
} from '../../../../models/inviteModel';

import { Heading, Icon, Skeleton, Text } from '../../../../components';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  user-select: none;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  padding: 24px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  margin-right: 16px;
  min-width: 240px;
  transition: background-color 0.1s ease-in-out;
  opacity: 0.7;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  :active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const RoleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RoleIcon = styled(Icon)`
  margin-right: 8px;
  font-size: 16px;
  ::before {
    color: ${(props) => props.theme.colors.dark};
  }
`;

const MemberName = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const MemberEmail = styled.div`
  margin-top: 4px;
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  invite?: Invite;
  onClick?: (invite: Invite) => void;
}

const PendingMemberTile: FC<Props> = (props) => {
  const { invite, onClick } = props;

  if (!invite) {
    return (
      <Container>
        <RoleContainer>
          <Skeleton height={20} width={64} />
        </RoleContainer>
        <MemberName>
          <Skeleton height={20} width={120} />
        </MemberName>
        <MemberEmail>
          <Skeleton height={12} width={180} />
        </MemberEmail>
      </Container>
    );
  }

  return (
    <Container onClick={onClick ? () => onClick(invite) : undefined}>
      <RoleContainer>
        <RoleIcon name={invite.role === 'owner' ? 'owner' : 'members'} />
        <Text variant="body2">
          {getFormattedRole(invite)} ({getStatus(invite)})
        </Text>
      </RoleContainer>
      <MemberName>
        <Heading variant="h3">{getFullName(invite)}</Heading>
      </MemberName>
      <MemberEmail>
        <StyledText variant="body2">{getEmail(invite)}</StyledText>
      </MemberEmail>
    </Container>
  );
};

export default PendingMemberTile;
