import { FC, useMemo, useState } from 'react';
import { apply, storeModel, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useSelector } from '../../../store';
import {
  AuthSelectors,
  SettingsSelectors,
  StoreSelectors,
} from '../../../store/selectors';

import { withWooCommerce } from '../../../models/appSettingsModel';

import { Button, Heading, Icon, Text } from '../../../components';
import Invoices from './Invoices';
import SubscriptionDialog from './SubscriptionDialog';
import SubscriptionInfo from './SubscriptionInfo';
import UpdateActiveSubscriptionDialog from './UpdateActiveSubscriptionDialog';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const ButtonIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 17px;
  margin-right: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Subscriptions: FC = () => {
  const appSettings = useSelector(SettingsSelectors.selectAppSettings);
  const user = useSelector(AuthSelectors.selectUser);
  const store = useSelector(StoreSelectors.selectStore);

  const isPowerUser = useMemo(() => {
    return apply(userModel.isPowerUser, user, false);
  }, [user]);

  const { isSalesRep, isStoreOwner } = useMemo(() => {
    const loaded = user && store;

    return {
      isSalesRep: loaded ? storeModel.isSalesRep(user, store) : false,
      isStoreOwner: loaded ? storeModel.isStoreOwner(user, store) : false,
    };
  }, [store, user]);

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const openEditDialog = () => setEditDialogOpen(true);

  const openUpdateDialog = () => setUpdateDialogOpen(true);

  const closeEditDialog = () => setEditDialogOpen(false);

  const closeUpdateDialog = () => setUpdateDialogOpen(false);

  const hasSubscriptionInfo = apply(
    storeModel.hasSubscriptionInfo,
    store,
    false,
  );

  const isActive = apply(storeModel.isActive, store, false);
  const isInactive = apply(storeModel.isInactive, store, true);

  const canAddSubInfo = isPowerUser || isSalesRep;

  const canEditSubInfo = canAddSubInfo && isInactive && hasSubscriptionInfo;

  const canUpdateActiveSubscription =
    canAddSubInfo && isActive && hasSubscriptionInfo;

  const showInvoices =
    withWooCommerce(appSettings) && (isPowerUser || isSalesRep || isStoreOwner);

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Subscription</ContentHeading>
          <Text variant="body2">
            Preview your subscription details. If you would like to upgrade your
            subscription please contact the ATOGEAR support or a sales
            representative!
          </Text>
        </Info>
        {canEditSubInfo && (
          <EditButton>
            <Button variant="outlined" onClick={openEditDialog}>
              <ButtonIcon name="edit" />
              Edit subscription
            </Button>
          </EditButton>
        )}
        {canUpdateActiveSubscription && (
          <EditButton>
            <Button variant="outlined" onClick={openUpdateDialog}>
              <ButtonIcon name="edit" />
              Update Active Subscription
            </Button>
          </EditButton>
        )}
      </Header>
      <Content>
        {!hasSubscriptionInfo ? (
          canAddSubInfo && (
            <div>
              <Button variant="outlined" onClick={openEditDialog}>
                <ButtonIcon name="edit" />
                Add subscription
              </Button>
            </div>
          )
        ) : (
          <>
            <SubscriptionInfo open={!showInvoices} />
            {showInvoices && <Invoices />}
          </>
        )}
      </Content>
      {(canAddSubInfo || canEditSubInfo) && (
        <SubscriptionDialog
          store={store}
          open={editDialogOpen}
          onClose={closeEditDialog}
        />
      )}
      <UpdateActiveSubscriptionDialog
        store={store}
        open={updateDialogOpen}
        onClose={closeUpdateDialog}
      />
    </Wrapper>
  );
};

export default Subscriptions;
