import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { User, userModel } from '@atogear/arion-utils';
import { isEmpty } from 'ramda';

import { RootState } from '../types';
import { Contact } from '../../models/contactModel';

import { getProspectService, getSubscriptionService } from '../../services';

import { reset as contactsReset } from '../contacts/actions';
import { CreateProspectData, Prospect } from '../../models/prospectModel';

interface Config {
  state: RootState;
}

export const getAllProspects = createAsyncThunk(
  'prospects/getAllProspects',
  /**
   * Get all Prospects.
   *
   * @returns Promise that resolves to all Prospects.
   */
  async (): Promise<Prospect[]> => {
    const prospects = await getProspectService().getProspects();

    return prospects;
  },
);

export const getProspects = createAsyncThunk(
  'prospects/getProspects',
  /**
   * Get User's Prospects.
   *
   * @param user User.
   * @returns Promise that resolves to User's Prospects.
   */
  async (user: User): Promise<Prospect[]> => {
    const service = getProspectService();

    let prospects = [];

    // Admin, Back Office and Internal Sales can see all prospects
    if (
      userModel.isPowerUser(user) ||
      userModel.isDataViewer(user) ||
      userModel.isInternalSales(user)
    ) {
      prospects = await service.getProspects();
    }
    // External Sales can see their own prospects
    else if (userModel.isExternalSales(user)) {
      prospects = await service.getUserProspects(user);
    }

    // Invalid role
    else {
      throw new Error('Unauthorized');
    }

    return prospects;
  },
);

export const getProspect = createAsyncThunk(
  'prospects/getProspect',
  /**
   * Get Prospect.
   *
   * @param prospectId Prospect's id.
   * @returns Promise that resolves to Prospect.
   */
  async (prospectId: string): Promise<Prospect> => {
    const prospect = await getProspectService().getProspect(prospectId);

    return prospect;
  },
);

interface CreateProspectParams {
  prospect: CreateProspectData;
  contacts: Contact[];
}

export const createProspect = createAsyncThunk(
  'prospects/createProspect',
  /**
   * Create Prospect.
   *
   * @param params Prospect's data and contacts.
   * @returns Promise that resolves to created Prospect's id.
   */
  async (params: CreateProspectParams, { dispatch }): Promise<string> => {
    const { prospect, contacts } = params;

    const prospectId = await getProspectService().createProspect(
      prospect,
      contacts,
    );

    dispatch(contactsReset());

    return prospectId;
  },
);

interface UpdateProspectParams {
  prospectId: string;
  data: Partial<Prospect>;
}

export const updateProspect: AsyncThunk<
  Prospect,
  UpdateProspectParams,
  Config
> = createAsyncThunk<Prospect, UpdateProspectParams, Config>(
  'prospects/updateProspect',
  /**
   * Update Prospect.
   *
   * @param params Prospect's id and data.
   * @returns Promise that resolves to updated Prospect.
   */
  async (params: UpdateProspectParams, { getState }): Promise<Prospect> => {
    const { prospectId, data } = params;

    const service = getProspectService();

    const user = getState().auth.user.data;

    if (!user) {
      throw new Error('No user signed in!');
    }

    const updateFn = userModel.isAdmin(user)
      ? service.updateProspectDoc
      : service.updateProspectFn;

    const prospect = await updateFn(prospectId, data);

    return prospect;
  },
);

export const transformProspectToStore = createAsyncThunk(
  'prospects/transformProspectToStore',
  async (prospectId: string): Promise<Prospect> => {
    const res = await getProspectService().transformProspect(prospectId);

    return res;
  },
);

export const deleteProspect = createAsyncThunk(
  'prospects/deleteProspect',
  /**
   * Delete Prospect.
   *
   * @param prospectId Prospect's id.
   * @returns Promise that resolves when Prospect has been deleted successfully.
   */
  async (prospectId: string): Promise<boolean> => {
    const success = await getProspectService().deleteProspect(prospectId);

    return success;
  },
);

interface UploadDocumentParams {
  prospectId: string;
  data: Blob | ArrayBuffer | Uint8Array;
}

export const uploadAgreement: AsyncThunk<
  Prospect,
  UploadDocumentParams,
  Config
> = createAsyncThunk<Prospect, UploadDocumentParams, Config>(
  'prospects/uploadAgreement',
  /**
   * Upload prospect's Agreement.
   *
   * @param params prospect's id and file to upload.
   * @returns Promise that resolves when Agreement has been uploaded successfully.
   */
  async (params: UploadDocumentParams, { dispatch }): Promise<Prospect> => {
    const { prospectId, data } = params;

    const agreementPath = await getSubscriptionService().uploadAgreement(
      prospectId,
      data,
      true,
    );

    const updatedProspect = await dispatch(
      updateProspect({
        prospectId,
        data: {
          agreementPath,
        },
      }),
    ).unwrap();

    return updatedProspect;
  },
);

interface DownloadDocumentParams {
  prospectId: string;
  documentPath: string;
}

export const downloadAgreementProspect = createAsyncThunk(
  'prospects/downloadAgreementProspect',
  /**
   * Download prospect's Agreement.
   *
   * @param params prospect's id and path to agreement in storage.
   * @returns Promise that resolves to Agreement's download url.
   */
  async (params: DownloadDocumentParams): Promise<string> => {
    const { prospectId, documentPath } = params;

    const result = await getSubscriptionService().downloadAgreementProspect(
      prospectId,
      documentPath,
    );

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.url;
  },
);

interface SendDocumentParams {
  prospectId: string;
  contacts: object[];
}

export const sendAgreementToContactsProspect = createAsyncThunk(
  'prospects/sendAgreementToContactsProspect',
  /**
   * Upload prospect's Agreement.
   *
   * @param params prospect's id and file to upload.
   * @returns Promise that resolves when Agreement has been uploaded successfully.
   */
  async (params: SendDocumentParams): Promise<void> => {
    const { prospectId, contacts } = params;

    if (isEmpty(contacts)) return;

    await getSubscriptionService().sendAgreementToContactsProspect(
      prospectId,
      contacts,
    );
  },
);

export const uploadQuote: AsyncThunk<Prospect, UploadDocumentParams, Config> =
  createAsyncThunk<Prospect, UploadDocumentParams, Config>(
    'prospects/uploadQuote',
    /**
     * Upload prospect's quote.
     *
     * @param params prospect's id and file to upload.
     * @returns Promise that resolves when quote has been uploaded successfully.
     */
    async (params: UploadDocumentParams, { dispatch }): Promise<Prospect> => {
      const { prospectId, data } = params;

      const quotePath = await getProspectService().uploadQuote(
        prospectId,
        data,
      );

      const updatedProspect = await dispatch(
        updateProspect({
          prospectId,
          data: {
            quotePath,
          },
        }),
      ).unwrap();

      return updatedProspect;
    },
  );

export const downloadQuote = createAsyncThunk(
  'prospects/downloadQuote',
  /**
   * Download prospect's Quote.
   *
   * @param params prospect's id and path to quote in storage.
   * @returns Promise that resolves to quote's download url.
   */
  async (params: DownloadDocumentParams): Promise<string> => {
    const { prospectId, documentPath } = params;

    const result = await getProspectService().downloadQuote(
      prospectId,
      documentPath,
    );

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.url;
  },
);
