import { MutableRefObject } from 'react';
import { storeModel } from '@atogear/arion-utils';

import { createFlyerPreview, FlyerPreviewType } from '../models/flyerModel';
import { Promotion } from '../models/promotionModel';

import { Theme } from '../theme/types';

export const FLYER_URL = process.env.REACT_APP_FLYER_URL;
export const IS_LOADED_REQUEST = 'IS_LOADED_REQUEST';
export const GET_PREVIEW_REQUEST = 'GET_PREVIEW_REQUEST';
export const GET_PREVIEW_RESPONSE = 'GET_PREVIEW_RESPONSE';

const stopPolling = (pollingIdRef: MutableRefObject<NodeJS.Timer | null>) => {
  if (pollingIdRef.current) {
    clearInterval(pollingIdRef.current);
  }
};

// Handle incoming messages
const onMessage =
  (
    previewRef: MutableRefObject<FlyerPreviewType | null>,
    pollingIdRef: MutableRefObject<NodeJS.Timer | null>,
  ) =>
  (event: MessageEvent) => {
    // Verify sender
    if (!FLYER_URL || event.origin !== FLYER_URL) {
      return;
    }

    // Check if flyer requested preview data
    if (event.data?.type === GET_PREVIEW_REQUEST && event.source) {
      // Reply with preview
      event.source.postMessage(
        {
          type: GET_PREVIEW_RESPONSE,
          payload: previewRef.current,
        },
        {
          targetOrigin: FLYER_URL,
        },
      );

      stopPolling(pollingIdRef);
    }
  };

export const bindMessageListener = (
  previewRef: MutableRefObject<FlyerPreviewType | null>,
  pollingIdRef: MutableRefObject<NodeJS.Timer | null>,
) => {
  const messageHandler = onMessage(previewRef, pollingIdRef);

  window.addEventListener('message', messageHandler, false);

  // return clean up function
  return () => window.removeEventListener('message', messageHandler, false);
};

export const openPreview = async (
  previewRef: MutableRefObject<FlyerPreviewType | null>,
  pollingIdRef: MutableRefObject<NodeJS.Timer | null>,
  pollingCount: MutableRefObject<number>,
  promotion?: Promotion,
  theme?: Theme,
) => {
  if (!FLYER_URL) {
    return;
  }

  // Merge preview data with flyer relevant store data
  previewRef.current = await createFlyerPreview(
    storeModel.create({
      // Store Info
      storeId: 'placeholder-store-id',
      storeName: 'ATOGEAR',
      // Address
      address: 'Schimmelt 28',
      zipCode: '5622 ZX',
      city: 'Eindhoven',
      country: 'NL',
      // Contact Info
      email: 'info@ato-gear.com',
      website: 'arion.run',
      // Socials
      facebookHandle: 'arion.run',
      instagramHandle: 'arion.run',
      linkedInHandle: 'ato-gear',
    }),
    promotion,
    theme,
  );

  // Open flyer preview in new window
  const win = window.open(`${FLYER_URL}/preview`, '_blank');

  // Check if window opened successfully
  if (win) {
    // Start polling to check if window is loaded
    pollingIdRef.current = setInterval(() => {
      if (!FLYER_URL) {
        return;
      }

      // Send polling message
      win.postMessage(
        {
          type: IS_LOADED_REQUEST,
        },
        FLYER_URL,
      );

      // Up polling count
      ++pollingCount.current;

      // Stop polling after 50 tries
      if (pollingCount.current >= 50) {
        stopPolling(pollingIdRef);
      }
    }, 500);
  }
};
