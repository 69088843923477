import { FC, FormEventHandler } from 'react';
import styled from 'styled-components';
import Button from './Button';
import Dialog, { DialogProps } from './Dialog/Dialog';
import DialogActions from './Dialog/DialogActions';
import DialogText from './Dialog/DialogText';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 32px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
`;

interface Props extends DialogProps {
  text: string;
  content: string;
  onConfirm: () => void;
}

const DeleteDialog: FC<Props> = ({
  text,
  content,
  loading,
  open,
  onClose,
  onConfirm,
}) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    onConfirm();
  };

  return (
    <Dialog open={open} title={`Delete ${text}`} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <Content>
          <DialogText variant="body2">{content}</DialogText>
        </Content>
        <DialogActions>
          <CancelButton
            disabled={loading}
            size="small"
            type="button"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </CancelButton>
          <ConfirmButton
            disabled={loading}
            size="small"
            type="submit"
            variant="outlined"
          >
            Delete
          </ConfirmButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default DeleteDialog;
