import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { create, getId, prepare, Promotion } from '../../models/promotionModel';
import { getPromotionService } from '../../services';
import { RootState } from '../types';

export const getPromotions = createAsyncThunk(
  'promotions/getPromotions',
  /**
   * Get Promotions.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Promotions[].
   */
  async (storeId: string): Promise<Promotion[]> => {
    const promotions = await getPromotionService().getPromotions(storeId);

    return promotions;
  },
);

export const getDefaultPromotion = createAsyncThunk(
  'promotions/getDefaultPromotion',
  /**
   * Get Store's default Promotion.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Store's default Promotion.
   */
  async (storeId: string): Promise<Promotion> => {
    const promotion = await getPromotionService().getDefaultPromotion(storeId);

    return promotion;
  },
);

export const addPromotions = createAsyncThunk(
  'promotions/addPromotions',
  /**
   * add Promotion.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to the new Promotion.
   */
  async (storeId: string): Promise<Promotion> => {
    const promotion = await getPromotionService().addPromotion(
      storeId,
      create(),
    );

    return promotion;
  },
);

interface CopyPromotionParams {
  storeId: string;
  promotion: Promotion;
}

export const copyPromotions = createAsyncThunk(
  'promotions/copyPromotions',
  /**
   * copy Promotion.
   *
   * @param params Promotion's data.
   * @returns Promise that resolves to the new Promotion.
   */
  async (params: CopyPromotionParams): Promise<Promotion> => {
    const { storeId, promotion } = params;

    const newPromotion = await getPromotionService().copyPromotion(
      storeId,
      prepare(promotion),
    );

    return newPromotion;
  },
);

interface removePromotionParams {
  storeId: string;
  promotion: Promotion;
}

export const removePromotion = createAsyncThunk(
  'promotions/removePromotion',
  /**
   * remove Promotion.
   *
   * @param params Promotion's data.
   * @returns Promise that resolves to the new Promotion.
   */
  async (params: removePromotionParams): Promise<Promotion> => {
    const { storeId, promotion } = params;

    const removedPromotion = await getPromotionService().removePromotion(
      storeId,
      promotion,
    );

    return removedPromotion;
  },
);

interface Config {
  state: RootState;
}

interface SetDefaultPromotionParams {
  storeId: string;
  promotion: Promotion;
}

export const setDefaultPromotion: AsyncThunk<
  Promotion[],
  SetDefaultPromotionParams,
  Config
> = createAsyncThunk<Promotion[], SetDefaultPromotionParams, Config>(
  'promotions/setDefaultPromotion',
  /**
   * Get Promotions.
   *
   * @param params Promotion's data.
   * @returns Promise that resolves to default Promotion.
   */
  async (
    params: SetDefaultPromotionParams,
    { getState },
  ): Promise<Promotion[]> => {
    const currentPromotions = getState().promotions.promotions.data || [];

    const { storeId, promotion } = params;

    const updates = await getPromotionService().setDefaultPromotion(
      storeId,
      currentPromotions.filter(
        (promo) =>
          getId(promo) !== getId(promotion) && getId(promo) !== 'metadata',
      ),
      promotion,
    );

    return updates;
  },
);

interface UpdatePromotionParams {
  ownerId: string;
  storeId: string;
  promotion: Promotion;
}

export const updatePromotion = createAsyncThunk(
  'promotions/updatePromotion',
  /**
   * Update Promotion.
   *
   * @param params Promotion's data.
   * @returns Promise that resolves to updated Branding.
   */
  async (params: UpdatePromotionParams): Promise<Promotion[]> => {
    const { ownerId, storeId, promotion } = params;

    const updatedPromotions = await getPromotionService().updatePromotion(
      ownerId,
      storeId,
      promotion,
    );

    return updatedPromotions;
  },
);
