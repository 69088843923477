import { FC } from 'react';
import styled from 'styled-components';

import { Theme } from '../../../../theme/types';

import { ImagePreview } from '../../../../components';
import { MediaPreview, MediaTile } from '../Components';

const FirstMediaPreview = styled(MediaPreview)`
  margin-bottom: 40px;
`;

interface Props {
  lightTheme?: Theme;
  darkTheme?: Theme;
}

const AppSection: FC<Props> = ({ lightTheme, darkTheme }) => (
  <MediaTile>
    <FirstMediaPreview
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      mediaKey="appBackgroundURL"
      title="App background"
      description="A main banner image displays at the top of the page, adding a visual element that sets the tone for your app."
      component={ImagePreview}
      componentProps={{
        height: 300,
        width: 200,
      }}
    />
    <MediaPreview
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      mediaKey="settingsHeaderURL"
      title="App settings header"
      description="An image displayed in the Settings page header in ARIONHUB app."
      component={ImagePreview}
      componentProps={{
        height: 120,
        width: 470,
      }}
    />
  </MediaTile>
);

export default AppSection;
