import { FC } from 'react';
import styled from 'styled-components';

import { Heading, Text } from '../../../components';
import Upload from './Upload';

import ExampleShoeCsv from '../../../assets/examples/example_shoe_format.csv';

const Wrapper = styled.div``;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
`;

const Shoes: FC = () => (
  <Wrapper>
    <StyledHeading variant="h1">Upload shoes</StyledHeading>
    <StyledText variant="body2">
      Upload a CSV that contains the list of shoes you have in your store. The
      CSV must contain columns named “brand”, “model” and “EAN”. If the file
      does not contain these columns with corresponding content, the file might
      not be uploaded and your shoes might not all be available in the ARIONHUB
      app.
    </StyledText>
    <Text variant="body2">
      Please, check the example format of the{' '}
      <Link download={true} href={ExampleShoeCsv}>
        CSV file
      </Link>
      .
    </Text>
    <Upload />
  </Wrapper>
);

export default Shoes;
