import { Store, storeModel } from '@atogear/arion-utils';

import { Theme } from '../theme/types';

import { mediaOptions } from '../utils/themeUtils';

import { promotionItemModel, promotionModel, themeModel } from './index';

export interface FlyerPreviewType {
  flyer: {
    storeId: string;
    storeAddress?: string;
    storeCity?: string;
    storeEmail?: string;
    storeName: string;
    storeWebsite?: string;
    storeZipCode?: string;
    storeFacebookUrl?: string;
    storeInstagramUrl?: string;
    storeLinkedInUrl?: string;
    groupId: string;
    themeIdDark?: string;
    themeIdLight?: string;
  };
  promotion?: promotionModel.Promotion;
  theme?: Theme;
}

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

const prepareThemeMedia = async (theme?: Theme): Promise<Theme | undefined> => {
  if (!theme) {
    return undefined;
  }

  let newTheme = { ...theme };

  await Promise.all(
    Object.keys(mediaOptions).map(async (key) => {
      const mediaKey = key as keyof themeModel.MediaOptions;

      const mediaUrl = themeModel.getMedia(mediaKey, theme);

      if (mediaUrl && mediaUrl.includes('blob:')) {
        const response = await fetch(mediaUrl);
        const blob = await response.blob();

        const base64 = await blobToBase64(blob);

        newTheme = themeModel.setMedia(mediaKey, base64, newTheme);
      }
    }),
  );

  return newTheme;
};

const preparePromoMedia = async (
  promo?: promotionModel.Promotion,
): Promise<promotionModel.Promotion | undefined> => {
  if (!promo) {
    return undefined;
  }

  let newPromo = { ...promo };

  const mediaUrl = promotionModel.getImageUrl(newPromo);

  if (mediaUrl && mediaUrl.includes('blob:')) {
    const response = await fetch(mediaUrl);
    const blob = await response.blob();

    const base64 = await blobToBase64(blob);

    newPromo = promotionModel.setMedia(
      base64,
      promotionModel.getImagePath(newPromo),
      newPromo,
    );
  }

  if (promotionModel.hasPromotionItems(newPromo)) {
    await Promise.all(
      promotionModel
        .getPromotionItems(newPromo)
        .map(
          async (
            promoItem: promotionItemModel.PromotionItem,
            index: number,
          ) => {
            const mediaUrl = promotionItemModel.getImageUrl(promoItem);

            if (mediaUrl && mediaUrl.includes('blob:')) {
              const response = await fetch(mediaUrl);
              const blob = await response.blob();

              const base64 = await blobToBase64(blob);

              newPromo.promotions[index] = promotionItemModel.setMedia(
                base64,
                promotionItemModel.getImagePath(promoItem),
                promoItem,
              );
            }
          },
        ),
    );
  }

  return newPromo;
};

export const createFlyerPreview = async (
  store: Store,
  promotion?: promotionModel.Promotion,
  theme?: Theme,
): Promise<FlyerPreviewType> => {
  const preparedTheme = await prepareThemeMedia(theme);

  const preparedPromo = await preparePromoMedia(promotion);

  return {
    flyer: {
      storeId: storeModel.getId(store),
      storeName: storeModel.getName(store),
      storeAddress: storeModel.getStreet(store),
      storeZipCode: storeModel.getZipCode(store),
      storeCity: storeModel.getCity(store),
      storeEmail: storeModel.getEmail(store),
      storeWebsite: storeModel.getFormattedWebsite(store),
      storeFacebookUrl: storeModel.getFacebookUrl(store),
      storeInstagramUrl: storeModel.getInstagramUrl(store),
      storeLinkedInUrl: storeModel.getLinkedInUrl(store),
      groupId: storeModel.getGroupId(store),
      themeIdDark: storeModel.getDarkThemeId(store),
      themeIdLight: storeModel.getLightThemeId(store),
    },
    promotion: preparedPromo,
    theme: preparedTheme,
  };
};
