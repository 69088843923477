import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { mergeRight } from 'ramda';

import { DefaultThemeIds } from '../../enums';

import { getId } from '../../models/themeModel';

import { Theme } from '../../theme/types';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import {
  editorStateReset,
  editorStateUpdated,
  reset,
  setThemeDarkById,
  setThemeLightById,
  themePreviewUpdated,
  themeReset,
  themesReset,
} from './actions';
import {
  copyGroupTheme,
  createGroupTheme,
  deleteGroupTheme,
  getDefaultThemes,
  getGroupThemes,
  getStoreThemes,
  getTheme,
  getThemes,
  updateGroupTheme,
} from './thunks';
import { EditorState } from './types';

interface State {
  themes: {
    data: Theme[];
    fetching: boolean;
    error?: SerializedError;
  };
  theme: {
    data?: Theme;
    preview?: Theme;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  editorState: EditorState;
  lightTheme: {
    data?: Theme;
    fetching: boolean;
    error?: SerializedError;
  };
  darkTheme: {
    data?: Theme;
    fetching: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  themes: {
    data: [],
    fetching: false,
    error: undefined,
  },
  theme: {
    data: undefined,
    preview: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
  editorState: {
    tabIndex: 0,
    open: true,
    disabled: false,
    selectedElementKey: undefined,
  },
  lightTheme: {
    data: undefined,
    fetching: false,
    error: undefined,
  },
  darkTheme: {
    data: undefined,
    fetching: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get All Themes
    .addCase(getThemes.pending, setFetchingState('themes'))
    .addCase(getThemes.fulfilled, (state, { payload }) => {
      state.themes.data = payload;
      setFulfilledState('themes')(state);
    })
    .addCase(getThemes.rejected, setRejectedState('themes'))
    // Get Default Themes
    .addCase(getDefaultThemes.pending, setFetchingState('themes'))
    .addCase(getDefaultThemes.fulfilled, (state, { payload }) => {
      state.themes.data = payload;
      setFulfilledState('themes')(state);
    })
    .addCase(getDefaultThemes.rejected, setRejectedState('themes'))
    // Get Any Theme
    .addCase(getTheme.pending, setFetchingState('theme'))
    .addCase(getTheme.fulfilled, (state, { payload }) => {
      state.theme.data = payload;
      state.theme.preview = payload;
      setFulfilledState('theme')(state);
    })
    .addCase(getTheme.rejected, setRejectedState('theme'))
    // Get Group Themes
    .addCase(getGroupThemes.pending, setFetchingState('themes'))
    .addCase(getGroupThemes.fulfilled, (state, { payload }) => {
      state.themes.data = payload;
      setFulfilledState('themes')(state);
    })
    .addCase(getGroupThemes.rejected, setRejectedState('themes'))
    // Get Current Store Themes
    .addCase(getStoreThemes.pending, () => {
      setFetchingState('lightTheme');
      setFetchingState('darkTheme');
    })
    .addCase(getStoreThemes.fulfilled, (state, { payload }) => {
      state.darkTheme.data = payload.darkTheme;
      state.lightTheme.data = payload.lightTheme;
      setFulfilledState('lightTheme')(state);
      setFulfilledState('darkTheme')(state);
    })
    .addCase(getStoreThemes.rejected, () => {
      setRejectedState('lightTheme');
      setRejectedState('darkTheme');
    })
    // Create Theme
    .addCase(createGroupTheme.pending, setUpdatingState('theme'))
    .addCase(createGroupTheme.fulfilled, (state, { payload }) => {
      state.theme.data = payload;
      state.theme.preview = payload;
      setFulfilledState('theme')(state);
    })
    .addCase(createGroupTheme.rejected, setRejectedState('theme'))
    // Copy Theme
    .addCase(copyGroupTheme.fulfilled, (state, { payload }) => {
      if (!state.themes.data) {
        state.themes.data = [payload];
      } else {
        state.themes.data.unshift(payload);
      }

      setFulfilledState('themes')(state);
    })
    // Update Theme
    .addCase(updateGroupTheme.pending, setUpdatingState('theme'))
    .addCase(updateGroupTheme.fulfilled, (state, { payload }) => {
      state.theme.data = payload;
      state.theme.preview = payload;
      setFulfilledState('theme')(state);
    })
    .addCase(updateGroupTheme.rejected, setRejectedState('theme'))
    // Delete Theme
    .addCase(deleteGroupTheme.pending, setUpdatingState('theme'))
    .addCase(deleteGroupTheme.fulfilled, (state) => {
      state.theme.data = undefined;
      state.theme.preview = undefined;
      setFulfilledState('theme')(state);
    })
    .addCase(deleteGroupTheme.rejected, setRejectedState('theme'))
    // Local
    .addCase(setThemeLightById, (state, { payload }) => {
      const themeId = payload || DefaultThemeIds.ARION_LIGHT;

      state.lightTheme.data = state.themes.data.find(
        (t) => getId(t) === themeId,
      );
    })
    .addCase(setThemeDarkById, (state, { payload }) => {
      const themeId = payload || DefaultThemeIds.ARION_DARK;

      state.darkTheme.data = state.themes.data.find(
        (t) => getId(t) === themeId,
      );
    })
    // Update preview
    .addCase(themePreviewUpdated, (state, { payload }) => {
      state.theme.preview = payload;
    })
    // Update editor state
    .addCase(editorStateUpdated, (state, { payload }) => {
      state.editorState = mergeRight(state.editorState, payload);
    })
    // Update editor state
    .addCase(editorStateReset, (state) => {
      state.editorState = initialState.editorState;
    })
    // Reset Theme
    .addCase(themeReset, (state) => {
      state.theme = initialState.theme;
    })
    .addCase(themesReset, (state) => {
      state.themes = initialState.themes;
    })

    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
