import { FC } from 'react';
import styled from 'styled-components';
import InputLabel, { InputLabelProps } from '../InputLabel';

const StyledInputLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.colors.white};
`;

const DialogInputLabel: FC<InputLabelProps> = (props) => (
  <StyledInputLabel {...props} />
);

export default DialogInputLabel;
