import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { storeModel } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import { DragDropContext } from 'react-beautiful-dnd';

import { PromotionSelectors, StoreSelectors } from '../../../store/selectors';
import { useDispatch, useSelector } from '../../../store';
import { PromotionActions } from '../../../store/actions';

import { getId as getPromotionId } from '../../../models/promotionModel';

import { Heading, Text } from '../../../components';
import DefaultPromotion from './DefaultPromotion';
import AllPromotions from './AllPromotions';

const Wrapper = styled.div``;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

const Promotions: FC = () => {
  const dispatch = useDispatch();

  const store = useSelector(StoreSelectors.selectStore);
  const promotions = useSelector(PromotionSelectors.selectPromotions);
  const isFetchingPromotions = useSelector(
    PromotionSelectors.isFetchingPromotions,
  );
  const promotionsError = useSelector(PromotionSelectors.selectPromotionsError);

  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (!promotions && !isFetchingPromotions && !promotionsError && store) {
      dispatch(PromotionActions.getPromotions(storeModel.getId(store)))
        .unwrap()
        .catch((err) => {
          toast('Failed to retrieve promotions!', {
            type: 'error',
          });
        });
    }
  }, [dispatch, isFetchingPromotions, promotionsError, store, promotions]);

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    setIsDragging(false);

    if (!promotions || !destination || !store) return;

    // if the user was dropped in the same place do nothing
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    )
      return;

    const promo = promotions.find(
      (promo) => getPromotionId(promo) === draggableId,
    );

    if (!promo) return;

    dispatch(
      PromotionActions.setDefaultPromotion({
        promotion: promo,
        storeId: storeModel.getId(store),
      }),
    )
      .unwrap()
      .then(() => {
        toast('Successfully set default promotion!', {
          type: 'success',
        });
      })
      .catch((err) => {
        toast('Failed to set default promotion!', {
          type: 'error',
        });
      });
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <StyledHeading variant="h1">Promotions</StyledHeading>
        <StyledText variant="body2">
          Manage promotions that can be activated in the promotional sections of
          the digital flyer or your service emails.{' '}
          <u>Changes are immediately available across all platforms.</u>
        </StyledText>
        <DefaultPromotion isDragging={isDragging} />
        <AllPromotions />
      </DragDropContext>
    </Wrapper>
  );
};

export default Promotions;
