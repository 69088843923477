import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

const SliderTrackContainer = styled.div`
  position: relative;
  flex: 1;
`;

const Thumb = styled.input`
  pointer-events: none;
  position: absolute;
  height: 0px;
  top: 2px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  z-index: 4;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    height: 16px;
    width: 16px;
    pointer-events: all;
    position: relative;

    &:hover {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      border-color: ${({ theme }) => theme.colors.primary};
      border-style: solid;
      border-width: 6px;
    }
  }
`;

const SliderTrack = styled.div`
  border-radius: 3px;
  height: 4px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  z-index: 1;
  flex: 1;
`;

const SliderRange = styled.div`
  border-radius: 3px;
  height: 4px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: 2;
`;

const LabelsWrapper = styled.div`
  ${({ theme }) => theme.typography.h3};
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 20px;
`;

const Label = styled.div``;

interface Props {
  defaultValue: number;
  disabled?: boolean;
  min: number;
  max: number;
  startLabel?: string;
  endLabel?: string;
  onChange: (value: number) => void;
}

const Slider: FC<Props> = ({
  defaultValue,
  disabled = false,
  min,
  max,
  startLabel,
  endLabel,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const range = useRef<HTMLInputElement | null>(null);

  const getPercent = useCallback(
    (value: number) => {
      return Math.round(((value - min) / (max - min)) * 100);
    },
    [min, max],
  );

  useEffect(() => {
    const maxPercent = getPercent(value);

    if (range?.current) {
      range.current.style.left = '0%';
      range.current.style.width = `${maxPercent}%`;
    }
  }, [getPercent, value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (disabled) {
      return;
    }

    const value = Number.parseFloat(e.target.value);

    setValue(value);

    onChange(value);
  };

  return (
    <Container>
      <SliderTrackContainer>
        <Thumb
          disabled={disabled}
          min={min}
          max={max}
          type="range"
          value={value}
          onChange={handleChange}
        />
        <SliderTrack />
        <SliderRange ref={range} />
      </SliderTrackContainer>
      {startLabel || endLabel ? (
        <LabelsWrapper>
          <Label>{startLabel}</Label>
          <Label>{endLabel}</Label>
        </LabelsWrapper>
      ) : null}
    </Container>
  );
};

export default Slider;
