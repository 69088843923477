import { deleteUndefinedFields } from '@atogear/arion-utils';
import {
  getFirebaseFunctionsPipe,
  getFirestorePipe,
} from '@atogear/ato-broker';

import { prospectStoreModel } from '../models';
import { CreateProspectStoreResult, DeleteProspectStoreResult } from './types';
import {
  CreateProspectStoreData,
  ProspectStore,
} from '../models/prospectStoreModel';

const getCollPath = (id: string) => `prospects/${id}/stores`;

const getProspectStoreService = () => {
  /**
   * Get prospect's stores.
   *
   * @param prospectId prospect's id.
   * @returns Promise that resolves to prospect's stores.
   */
  const getProspectStores = async (
    prospectId: string,
  ): Promise<ProspectStore[]> => {
    const prospectStores = await getFirestorePipe().getDocuments(
      getCollPath(prospectId),
      { orderBy: { fieldName: 'createdAt', sortOrder: 'asc' } },
    );
    return prospectStores.map(prospectStoreModel.serialize);
  };

  /**
   * Get prospect's store.
   *
   * @param prospectId prospect's id.
   * @param prospectStoreId prospect's store's id
   * @returns Promise that resolves to prospect's store.
   */
  const getProspectStore = async (
    prospectId: string,
    prospectStoreId: string,
  ): Promise<ProspectStore> => {
    const prospectStore = await getFirestorePipe().getDocument(
      getCollPath(prospectId),
      prospectStoreId,
    );

    if (!prospectStore) {
      throw new Error('Prospect store not found!');
    }

    return prospectStoreModel.serialize(prospectStore);
  };

  /**
   * Create prospect's store.
   *
   * @param prospectId prospect's id.
   * @param prospectStore prospect's store's data.
   * @returns Promise that resolves to created prospect's stores's id.
   */
  const createProspectStore = async (
    prospectId: string,
    prospectStore: CreateProspectStoreData,
  ): Promise<string> => {
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-createProspectStore',
      {
        prospectStore: deleteUndefinedFields(prospectStore),
        prospectId,
      },
    )) as CreateProspectStoreResult;

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.prospectStoreId;
  };

  /**
   * Update prospect's store.
   *
   * @param prospectId prospect's id.
   * @param prospectStoreId prospect's store's id.
   * @param prospectStore prospect's store's data.
   * @returns Promise that resolves to updated prospect's store.
   */
  const updateProspectStore = async (
    prospectId: string,
    prospectStoreId: string,
    prospectStore: Partial<ProspectStore>,
  ): Promise<ProspectStore> => {
    const updatedProspectStore = await getFirestorePipe().editDocument(
      getCollPath(prospectId),
      prospectStoreId,
      prospectStoreModel.deserialize(prospectStore),
    );

    return prospectStoreModel.serialize(updatedProspectStore);
  };

  /**
   * Delete prospect's store.
   *
   * @param prospectId prospect's id.
   * @param prospectStoreId prospect's store's id.
   * @returns Promise that resolves when prospect's store has been successfully deleted.
   */
  const deleteProspectStore = async (
    prospectId: string,
    prospectStoreId: string,
  ): Promise<boolean> => {
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-deleteProspectStore',
      {
        prospectStoreId,
        prospectId,
      },
    )) as DeleteProspectStoreResult;

    return result.data.success;
  };

  return {
    getProspectStores,
    getProspectStore,
    createProspectStore,
    updateProspectStore,
    deleteProspectStore,
  };
};

export default getProspectStoreService;
