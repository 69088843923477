import { clone, mergeRight, pick } from 'ramda';
import tinycolor from 'tinycolor2';
import { defaultTheme } from '../theme';
import {
  ColorsType,
  ComponentsType,
  FontsType,
  MediaType,
  Theme,
  TypographyStylesType,
  TypographyType,
} from '../theme/types';
import { RequiredAny } from './types';

export const fields = {
  // General
  ID: 'id',
  NAME: 'themeName',
  IS_DARK: 'isDark',
  IS_DEFAULT: 'isDefault',
  // Group
  GROUP_ID: 'groupId',
  // Styles
  COLORS: 'colors',
  COMPONENTS: 'components',
  FONTS: 'fonts',
  MEDIA: 'media',
  TYPOGRAPHY: 'typography',
};

const primaryTypography = ['h1', 'h2', 'h3', 'h4', 'caption'];

const secondaryTypography = ['body1', 'body2', 'body3', 'a'];

interface MediaDimensions {
  height: number;
  width: number;
}

export interface MediaOption {
  aspectRatio: MediaDimensions;
  preferredSize: MediaDimensions;
  type: 'image' | 'video';
  maxFileSize: number;
}

export interface MediaOptions {
  fullLogoURL: MediaOption;
  miniLogoURL: MediaOption;
  appBackgroundURL: MediaOption;
  settingsHeaderURL: MediaOption;
  emailHeaderURL: MediaOption;
  flyerHeaderURL: MediaOption;
  kioskVideoURL: MediaOption;
}

const textColorTypography = [
  'h1',
  'h2',
  'h3',
  'h4',
  'body1',
  'body2',
  'body3',
  'caption',
];

export const sortedTypographyKeys = [
  'h1',
  'h2',
  'h3',
  'h4',
  'body1',
  'body2',
  'body3',
  'caption',
  'a',
];

// General
export const getId = (theme: Theme) => theme.id;

export const getName = (theme: Theme) => theme.themeName;

export const setName = (name: string, theme: Theme): Theme =>
  mergeRight(theme, {
    themeName: name,
  });

export const isDark = (theme: Theme) => theme.isDark;

export const isDefault = (theme: Theme) => theme.isDefault || false;

// Group
export const getGroupId = (theme: Theme) => theme.groupId;

export const setGroupId = (groupId: string, theme: Theme): Theme =>
  mergeRight(theme, {
    groupId,
  });

// Colors
export const getColors = (theme: Theme) => theme.colors;

export const setColor = (
  theme: Theme,
  key: keyof ColorsType,
  value: string,
): Theme => {
  let newTheme = clone(theme);

  newTheme.colors[key] = value;

  if (key === 'background') {
    newTheme.isDark = tinycolor(newTheme.colors.background).isDark();
  }

  // Apply text colors to typography styles
  if (key === 'text') {
    textColorTypography.forEach((typographyKey) => {
      newTheme = setTypography(
        typographyKey as keyof TypographyType,
        {
          color: value,
        },
        newTheme,
      );
    });
  } else if (key === 'primary') {
    newTheme = setTypography(
      'a',
      {
        color: value,
      },
      newTheme,
    );
  }

  return newTheme;
};

// Media
export const getMedia = (key: keyof MediaType, theme: Theme) =>
  theme.media[key];

export const setMedia = (key: keyof MediaType, value: string, theme: Theme) => {
  const newTheme = clone(theme);

  newTheme.media[key] = value;

  return newTheme;
};

export const getFullLogoUrl = (theme: Theme) => getMedia('fullLogoURL', theme);

export const getMiniLogoUrl = (theme: Theme) => getMedia('miniLogoURL', theme);

// Fonts
export const getFont = (key: keyof FontsType, theme: Theme) => theme.fonts[key];

export const setFont = (
  key: keyof FontsType,
  fontFamily: string,
  theme: Theme,
): Theme => {
  const newTheme = clone(theme);

  newTheme.fonts[key] = fontFamily;

  return newTheme;
};

export const getPrimaryFont = (theme: Theme) => getFont('primary', theme);

export const setPrimaryFont = (fontFamily: string, theme: Theme): Theme => {
  let newTheme = setFont('primary', fontFamily, theme);

  // Apply new fontFamily to other typography styles
  primaryTypography.forEach((key) => {
    newTheme = setTypography(
      key as keyof TypographyType,
      {
        fontFamily,
      },
      newTheme,
    );
  });

  return newTheme;
};

export const getSecondaryFont = (theme: Theme) => getFont('secondary', theme);

export const setSecondaryFont = (fontFamily: string, theme: Theme): Theme => {
  let newTheme = setFont('secondary', fontFamily, theme);

  // Apply new fontFamily to other typography styles
  secondaryTypography.forEach((key) => {
    newTheme = setTypography(
      key as keyof TypographyType,
      {
        fontFamily,
      },
      newTheme,
    );
  });

  return newTheme;
};

// Typography
export const getTypography = (key: keyof TypographyType, theme: Theme) =>
  theme.typography[key];

export const setTypography = (
  key: keyof TypographyType,
  value: TypographyStylesType,
  theme: Theme,
): Theme => {
  const newTheme = clone(theme);

  newTheme.typography[key] = {
    ...theme.typography[key],
    ...value,
  };

  return newTheme;
};

// Utils
export const copy = (theme: Theme): Theme => ({
  ...theme,
  applyCount: 0,
  themeName: `${getName(theme)} (copy)`,
});

const unwrapTypography = (object: any): any =>
  pick(
    [
      'color',
      'fontFamily',
      'fontSize',
      'fontStyle',
      'fontWeight',
      'letterSpacing',
      'fontCase',
    ],
    object || {},
  );

export const serialize = (data: RequiredAny): Theme => {
  if (data.TEMP_isNewTheme) {
    return {
      colors: data.colors,
      fonts: data.fonts,
      typography: data.typography,
      media: data.media,
      components: data.components,
      applyCount: data.applyCount,
      id: data.id,
      groupId: data.groupId,
      themeName: data.themeName,
      isDark: data.isDark || false,
      TEMP_isNewTheme: data.TEMP_isNewTheme,
      isDefault: data.isDefault,
      version: data.version,
    };
  }

  const filteredH1 = unwrapTypography(data.h1);
  const filteredH2 = unwrapTypography(data.h2);
  const filteredBody1 = unwrapTypography(data.body1);
  const filteredBody2 = unwrapTypography(data.body2);
  const filteredBody3 = unwrapTypography(data.body3);
  const filteredCaption = unwrapTypography(data.caption);

  const colors: ColorsType = {
    background: data.colors?.background || defaultTheme.colors.background,
    danger: data.colors?.danger || defaultTheme.colors.danger,
    primary: data.colors?.primary || defaultTheme.colors.primary,
    secondary: data.colors?.secondary || defaultTheme.colors.secondary,
    surfaceOne: data.colors?.surfaceOne || defaultTheme.colors.surfaceOne,
    surfaceTwo: data.colors?.surfaceTwo || defaultTheme.colors.surfaceTwo,
    success: data.colors?.success || defaultTheme.colors.success,
    text: data.colors?.text || defaultTheme.colors.text,
    textTwo: data.colors?.textTwo || defaultTheme.colors.textTwo,
    white: data.colors?.white || defaultTheme.colors.white,
    dark: data.colors?.dark || defaultTheme.colors.dark,
    light: data.colors?.light || defaultTheme.colors.light,
  };

  const fonts: FontsType = {
    primary: data.FontFamilyOne || defaultTheme.fonts.primary,
    secondary: data.FontFamilyTwo || defaultTheme.fonts.secondary,
  };

  const typography: TypographyType = {
    h1: {
      ...defaultTheme.typography.h1,
      ...filteredH1,
      textTransform: filteredH1.fontCase || 'none',
    },
    h2: {
      ...defaultTheme.typography.h2,
      ...filteredH2,
      textTransform: filteredH2.fontCase || 'none',
    },
    h3: {
      ...defaultTheme.typography.h3,
      color: data.h2?.color || defaultTheme.colors.text,
    },
    h4: {
      ...defaultTheme.typography.h4,
      color: data.h2?.color || defaultTheme.colors.text,
    },
    body1: {
      ...defaultTheme.typography.body1,
      ...filteredBody1,
      textTransform: filteredBody1.fontCase || 'none',
    },
    body2: {
      ...defaultTheme.typography.body2,
      ...filteredBody2,
      textTransform: filteredBody2.fontCase || 'none',
    },
    body3: {
      ...defaultTheme.typography.body3,
      ...filteredBody3,
      textTransform: filteredBody3.fontCase || 'none',
    },
    caption: {
      ...defaultTheme.typography.caption,
      ...filteredCaption,
      textTransform: filteredCaption.fontCase || 'none',
    },
    a: {
      ...defaultTheme.typography.a,
      color: data.body2?.color || defaultTheme.colors.text,
    },
  };

  const media: MediaType = {
    fullLogoURL: data.splashScreenURL || '',
    miniLogoURL: data.miniLogoURL || '',
    settingsHeaderURL: data.settingsLogoURL || '',
    appBackgroundURL: data.backgroundLogoURL || '',
    flyerHeaderURL: defaultTheme.media.flyerHeaderURL || '',
    emailHeaderURL: defaultTheme.media.flyerHeaderURL || '',
    kioskVideoURL: data.backgroundVideoURL || '',
  };

  const components: ComponentsType = {
    buttons: {
      primary: {
        backgroundColor: data.buttons?.primary?.background || colors.primary,
        color: data.buttons?.primary?.color || colors.text,
        borderRadius: data.buttons?.primary?.radius || 6,
      },
      secondary: {
        backgroundColor:
          data.buttons?.secondary?.background || colors.secondary,
        color: data.buttons?.secondary?.color || colors.text,
        borderRadius: data.buttons?.secondary?.radius || 6,
      },
    },
    input: {
      borderRadius: data.input?.radius || 6,
    },
    navigation: {
      borderRadius: data.navigation?.radius || 6,
    },
    tiles: {
      default: {
        borderRadius: data.tiles?.default?.radius || 6,
      },
      session: {
        borderRadius: data.tiles?.session?.radius || 6,
      },
      shoe: {
        borderRadius: data.tiles?.shoe?.radius || 6,
      },
    },
  };

  const newTheme: Theme = {
    colors,
    fonts,
    typography,
    applyCount: data.applyCount || 0,
    TEMP_isNewTheme: true,
    isDefault: data.isDefault,
    isDark: tinycolor(
      data.colors?.background || defaultTheme.colors.background,
    ).isDark(),
    id: data.id,
    groupId: data.groupId,
    media,
    themeName: data.themeName,
    version: data.version || '0.0.0-old',
    components,
  };

  return newTheme;
};
