import { FC } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../../../components';
import { ColorsType } from '../../../../../theme/types';
import ColorTile from './ColorTile';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 225px 225px;
  grid-template-rows: 28px repeat(4, 48px);
  row-gap: 16px;
  column-gap: 25px;
`;

const StyledHeading = styled(Heading)`
  grid-column: span 2;
  margin-bottom: 24px;
`;

interface Props {
  colors: ColorsType;
}

const ColorsPreview: FC<Props> = ({ colors, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <StyledHeading variant="h3">Colors</StyledHeading>
      <ColorTile name="Primary" $color={colors.primary} />
      <ColorTile name="Secondary" $color={colors.secondary} />
      <ColorTile name="Text" $color={colors.text} />
      <ColorTile name="TextTwo" $color={colors.textTwo} />
      <ColorTile name="Background" $color={colors.background} />
      <ColorTile name="Surface One" $color={colors.surfaceOne} />
      <ColorTile name="Surface Two" $color={colors.surfaceTwo} />
    </Wrapper>
  );
};

export default ColorsPreview;
