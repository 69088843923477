import { RootState } from '../types';

export const selectProspects = (state: RootState) =>
  state.prospects.prospects.data;

export const selectProspectsFetching = (state: RootState) =>
  state.prospects.prospects.fetching;

export const selectProspect = (state: RootState) =>
  state.prospects.prospect.data;

export const selectProspectPreview = (state: RootState) =>
  state.prospects.prospect.preview;

export const selectProspectFetching = (state: RootState) =>
  state.prospects.prospect.fetching;

export const selectProspectUpdating = (state: RootState) =>
  state.prospects.prospect.updating;

export const selectProspectLoading = (state: RootState) =>
  state.prospects.prospect.fetching || state.prospects.prospect.updating;

export const selectAgreementFetching = (state: RootState) =>
  state.prospects.agreement.fetching;

export const selectAgreementUpdating = (state: RootState) =>
  state.prospects.agreement.updating;

export const selectAgreementLoading = (state: RootState) =>
  state.prospects.agreement.fetching || state.prospects.agreement.updating;

export const selectQuoteFetching = (state: RootState) =>
  state.prospects.quote.fetching;

export const selectQuoteUpdating = (state: RootState) =>
  state.prospects.quote.updating;

export const selectQuoteLoading = (state: RootState) =>
  state.prospects.quote.fetching || state.prospects.quote.updating;
