import { BillingCycles } from '@atogear/arion-utils';

export const PAYMENT_TERM_OPTIONS = [
  {
    key: '1-week',
    label: 'Within 1 week',
    value: 7,
  },
  {
    key: '2-weeks',
    label: 'Within 2 weeks',
    value: 14,
  },
  {
    key: '30-days',
    label: 'Within 30 days',
    value: 30,
  },
];

export const CYCLE_OPTIONS = [
  {
    key: BillingCycles.MONTHLY,
    label: 'Monthly',
    value: BillingCycles.MONTHLY,
  },
  {
    key: BillingCycles.YEARLY,
    label: 'Yearly',
    value: BillingCycles.YEARLY,
  },
];

export const SUBSCRIPTION_CURRENCY_OPTIONS = [
  {
    key: 'USD',
    label: 'United States Dollars',
    value: 'USD',
  },
  {
    key: 'EUR',
    label: 'Euro',
    value: 'EUR',
  },
  {
    key: 'GBP',
    label: 'Pounds sterling',
    value: 'GBP',
  },
  {
    key: 'CAD',
    label: 'Canadian Dollars',
    value: 'CAD',
  },
  {
    key: 'AUD',
    label: 'Australian Dollars',
    value: 'AUD',
  },
  {
    key: 'CHF',
    label: 'Swiss Francs',
    value: 'CHF',
  },
  {
    key: 'NOK',
    label: 'Norwegian Kroner',
    value: 'NOK',
  },
  {
    key: 'SEK',
    label: 'Swedish Kronor',
    value: 'SEK',
  },
  {
    key: 'DKK',
    label: 'Danish Kroner',
    value: 'DKK',
  },
  {
    key: 'BGN',
    label: 'Bulgarian Levs',
    value: 'BGN',
  },
];
