import {
  ChangeEventHandler,
  FC,
  HTMLAttributes,
  InputHTMLAttributes,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isNilOrWhitespace } from '@atogear/arion-utils';
import styled from 'styled-components';

import { formatFileSize } from '../../../../../utils/formatters';
import { getVideoProps } from '../../../../../utils/mediaUtils';

import { Icon, MouseTooltip, Text } from '../../../../../components';
import { defaultTheme } from '../../../../../theme';
import { MediaOption } from '../../../../../models/themeModel';
import MediaPreview from './MediaPreview';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  &:hover .hover-icon {
    visibility: visible;
    opacity: 1;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 10;
`;

const IconButton = styled.div`
  border-radius: 50%;
  padding: 8px;
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  margin-right: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: opacity 150ms ease-in-out, visibility 150ms ease-in-out;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.grayOpaque};
`;

const RemoveIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.danger};
  cursor: pointer;
`;
interface HighlightProps {
  $highlight?: string;
}

const UploadContainer = styled.div<HighlightProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 12px;
  border: 1px dashed
    ${(props) => props.$highlight || props.theme.colors.highlight};
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const UploadTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.primary};
  margin-right: 8px;
`;

const UploadInfoIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.textTwo};
  margin-left: 8px;
  font-size: 12px;
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.textTwo};
`;

const StyledUploadIcon = styled(Icon)`
  font-size: 88px;
  color: ${(props) => props.theme.colors.highlight};
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const HighlightedText = styled(Text)<HighlightProps>`
  margin-top: 8px;
  color: ${(props) => props.$highlight || props.theme.colors.danger};
`;

interface ImagePreviewProps extends HTMLAttributes<HTMLDivElement> {
  src?: string;
  loading?: boolean;
  mediaOption: MediaOption;
  alt?: string;
  selected?: boolean;
  InputProps?: InputHTMLAttributes<HTMLInputElement>;
  onFileChange?: (file: string) => void;
  onDelete?: () => void;
  onRevert?: (original: string) => void;
  onClick?: (el: MouseEvent<HTMLDivElement>) => void;
}

const imageTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];

const videoTypes = ['video/mp4'];

const MediaInput: FC<ImagePreviewProps> = (props) => {
  const {
    src,
    mediaOption,
    alt,
    selected,
    InputProps,
    onFileChange,
    onDelete,
    onRevert,
    onClick,
    ...rest
  } = props;
  const [oldValue, setOldValue] = useState<string | undefined>();

  const [imageSizeBytes, setImageSizeBytes] = useState(0);
  const [error, setError] = useState<
    'media/size-mismatch' | 'media/duration-mismatch' | undefined
  >();
  const [warning, setWarning] = useState<
    'media/dimension-mismatch' | undefined
  >();

  const { type, preferredSize, maxFileSize } = mediaOption;

  useEffect(() => {
    // Set the max file size converted to bytes
    setImageSizeBytes(maxFileSize * 1000000);
  }, [maxFileSize]);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const openFileExplorer: MouseEventHandler<
    HTMLButtonElement | HTMLDivElement
  > = (e) => {
    e.stopPropagation();

    if (inputFileRef.current !== null) {
      inputFileRef.current.click();
    }
  };

  const onFileUpload: ChangeEventHandler<HTMLInputElement> = async (event) => {
    if (event.target.files === null || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];

    if (file.size > imageSizeBytes) {
      setError('media/size-mismatch');

      return;
    }

    if (type === 'video') {
      const props = await getVideoProps(file);

      if (props === null) return;

      if (
        props.height > preferredSize.height ||
        props.width > preferredSize.width
      ) {
        setWarning('media/dimension-mismatch');
      }

      if (props.duration > 60) {
        setError('media/duration-mismatch');

        return;
      }
    }

    setWarning(undefined);
    setError(undefined);

    if (!oldValue) {
      setOldValue(src);
    }

    if (onFileChange) onFileChange(URL.createObjectURL(file));
  };

  const handleOnDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setOldValue(undefined);

    if (onDelete) onDelete();
  };

  const handleRevert = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (onRevert) {
      onRevert(oldValue || '');
    }

    setOldValue(undefined);

    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  const hasImage = src !== undefined && src !== '';

  const inputProps = InputProps || {};

  const getMessage = (error: string) => {
    switch (error) {
      case 'media/dimension-mismatch':
        return `Recommended aspect ratio is: 16:9`;
      case 'media/size-mismatch':
        return `The image should not be larger than ${formatFileSize(
          imageSizeBytes,
        )}`;
      case 'media/duration-mismatch':
        return `The video should not be longer than 60 seconds`;
      default:
        return '';
    }
  };

  const getHighlightColor = () => {
    let color = undefined;

    if (warning) color = '#feb817';

    if (error) color = defaultTheme.colors.danger;
    return color;
  };

  return (
    <Container {...rest} onClick={onClick}>
      {!inputProps.readOnly && hasImage ? (
        <ActionContainer>
          {onDelete && !isNilOrWhitespace(oldValue) ? (
            <IconButton className="hover-icon" onClick={handleOnDelete}>
              <RemoveIcon name="delete" />
            </IconButton>
          ) : null}
          {hasImage && !inputProps.readOnly ? (
            <IconButton className="hover-icon" onClick={openFileExplorer}>
              <StyledIcon name="edit" />
            </IconButton>
          ) : null}
          {!isNilOrWhitespace(oldValue) && src !== '' ? (
            <IconButton className="hover-icon" onClick={handleRevert}>
              <StyledIcon name="undo" />
            </IconButton>
          ) : null}
        </ActionContainer>
      ) : null}
      {hasImage || inputProps.readOnly ? (
        !src || src === '' ? (
          <UploadContainer>
            <StyledUploadIcon name="alert" />
            <StyledText variant="body3">No Image</StyledText>
          </UploadContainer>
        ) : (
          <MediaPreview
            type={mediaOption.type}
            src={src}
            error={error !== undefined}
            alt={alt}
            selected={selected}
          />
        )
      ) : (
        <UploadContainer
          $highlight={getHighlightColor()}
          onClick={openFileExplorer}
        >
          <UploadTextContainer>
            <UploadIcon name="add-circle" />
            <Text>Upload new</Text>
            <MouseTooltip
              tooltip={`Supported ${
                type === 'image' ? 'image' : 'video'
              } types: ${
                type === 'image' ? 'JPG, PNG, SVG' : 'MP4'
              }. Recommended dimensions: ${preferredSize.width} x ${
                preferredSize.height
              }. Max file size ${formatFileSize(imageSizeBytes)}. ${
                type === 'video' ? `Max duration ${60} seconds.` : ''
              }`}
            >
              <UploadInfoIcon name="info" />
            </MouseTooltip>
          </UploadTextContainer>
        </UploadContainer>
      )}
      {error ? (
        <HighlightedText variant="body3">{getMessage(error)}</HighlightedText>
      ) : null}
      {warning ? (
        <HighlightedText variant="body3" $highlight={'#feb817'}>
          {getMessage(warning)}
        </HighlightedText>
      ) : null}
      <input
        onClick={(e) => e.stopPropagation()}
        style={{ display: 'none' }}
        type="file"
        accept={type === 'image' ? imageTypes.join(',') : videoTypes.join(',')}
        ref={inputFileRef}
        onChange={onFileUpload}
      />
    </Container>
  );
};

export default MediaInput;
