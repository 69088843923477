import { FC, WheelEventHandler } from 'react';
import styled from 'styled-components';

import { ATOContact } from '../../../../models/atoContactModel';

import ContactTile from './ContactTile';

const EMPTY_CONTACTS = new Array(3).fill(undefined);

const Container = styled.div`
  display: flex;
  margin-top: 22px;
  overflow-x: auto;
  /* we add padding because otherwise the box-shadow of the tile is cut-off */
  padding-bottom: 4px;

  ::-webkit-scrollbar-thumb {
    /* Handle */
    background: ${(props) => props.theme.colors.grayOpaque};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track-piece {
    /* not handle on */
    background: ${(props) => props.theme.colors.highlight};
  }
`;

interface Props {
  contacts?: ATOContact[];
}

const Contacts: FC<Props> = ({ contacts }) => {
  const handleWheel: WheelEventHandler<HTMLDivElement> = (e) => {
    if (e.deltaY === 0) {
      return;
    }

    e.currentTarget.scrollTo({
      left: e.currentTarget.scrollLeft + e.deltaY,
    });
  };

  return (
    <Container onWheel={handleWheel}>
      {(contacts || EMPTY_CONTACTS).map((contact, index) => (
        <ContactTile key={index} contact={contact} />
      ))}
    </Container>
  );
};

export default Contacts;
