import { createAction } from '@reduxjs/toolkit';
import { Prospect } from '../../models/prospectModel';

export const prospectPreviewUpdated = createAction<Partial<Prospect>>(
  'prospects/prospectPreviewUpdated',
);

export const prospectPreviewReset = createAction(
  'prospects/prospectPreviewReset',
);

export const reset = createAction('prospects/reset');
