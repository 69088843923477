import { FC, FormEventHandler } from 'react';
import { User, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogText,
} from '../../../../components';
import { translatedUserRoles } from '../../../../translations';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 32px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
`;

interface Props extends DialogProps {
  member: User;
  onConfirm?: () => void;
}

const DeleteDialog: FC<Props> = ({
  loading,
  open,
  member,
  onClose,
  onConfirm,
}) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    onConfirm && onConfirm();
  };

  const isOwner = userModel.isOwner(member);

  return (
    <Dialog
      loading={loading}
      open={open}
      title={isOwner ? 'Delete store owner' : 'Delete team member'}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <DialogText variant="body2">
            {isOwner
              ? `This will remove ${userModel.getFullName(member)} (${
                  translatedUserRoles[userModel.getRole(member)]
                }) and once removed, they will no longer be able to access the store in the ARIONHUB apps.`
              : `This will permanently delete ${userModel.getFullName(
                  member,
                )} (${
                  translatedUserRoles[userModel.getRole(member)]
                }) and once deleted, they will no longer be able to sign in to the ARIONHUB apps.`}
          </DialogText>
        </Content>
        <DialogActions>
          <CancelButton
            disabled={loading}
            size="small"
            type="button"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </CancelButton>
          <ConfirmButton
            disabled={loading}
            size="small"
            type="submit"
            variant="outlined"
          >
            Delete
          </ConfirmButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default DeleteDialog;
