interface MediaDimensions {
  height: number;
  width: number;
}

export interface PromoMediaOption {
  aspectRatio: MediaDimensions;
  preferredSize: MediaDimensions;
  type: 'image' | 'video';
  maxFileSize: number;
}

interface PromoMediaOptions {
  mainPromo: PromoMediaOption;
  miniPromo: PromoMediaOption;
}

export const promoMediaOptions: PromoMediaOptions = {
  mainPromo: {
    preferredSize: {
      height: 400,
      width: 400,
    },
    aspectRatio: {
      height: 1,
      width: 1,
    },
    type: 'image',
    maxFileSize: 10,
  },
  miniPromo: {
    preferredSize: {
      height: 400,
      width: 400,
    },
    aspectRatio: {
      height: 1,
      width: 1,
    },
    type: 'image',
    maxFileSize: 5,
  },
};

export const getMediaAspectRatio = (mediaOption: PromoMediaOption) => {
  const { aspectRatio } = mediaOption;

  return aspectRatio.width / aspectRatio.height;
};
