import { getFirestorePipe } from '@atogear/ato-broker';

import { Event, fields, serialize } from '../models/eventModel';

const getStoreCollPath = (id: string) => `stores/${id}/events`;
const getProspectCollPath = (id: string) => `prospects/${id}/events`;

export interface EventOptions {
  isProspect: boolean;
}

const getEventService = () => {
  /**
   * Get object's Events.
   *
   * @param id object's id.
   * @returns Promise that resolves to object's Events.
   */
  const getEvents = async (
    id: string,
    options?: EventOptions,
  ): Promise<Event[]> => {
    const events = await getFirestorePipe().getDocuments(
      options?.isProspect ? getProspectCollPath(id) : getStoreCollPath(id),
      {
        orderBy: {
          fieldName: fields.DATE,
          sortOrder: 'desc',
        },
      },
    );

    return (events as Event[]).map(serialize);
  };

  return {
    getEvents,
  };
};

export default getEventService;
