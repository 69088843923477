import {
  getFirebaseFunctionsPipe,
  getFirestorePipe,
} from '@atogear/ato-broker';
import { v4 as uuidv4 } from 'uuid';

import { CreateReminderData, Reminder } from '../models/reminderModel';
import { reminderModel } from '../models';
import { QueryOptions } from '@atogear/ato-broker/firestore';

const REMINDERS_COLL_PATH = 'reminders';

const getQueryOptions = (userId: string): QueryOptions => {
  return {
    where: {
      fieldName: reminderModel.fields.USER_ID,
      operator: '==',
      fieldValue: userId,
    },
    orderBy: { fieldName: 'createdAt', sortOrder: 'desc' },
  };
};

const getRemindersService = () => {
  /**
   * Get reminders.
   *
   * @param queryOptions Query options.
   * @returns Promise that resolves to reminders.
   */
  const getReminders = async (
    queryOptions?: QueryOptions,
  ): Promise<Reminder[]> => {
    const reminders = await getFirestorePipe().getDocuments(
      REMINDERS_COLL_PATH,
      queryOptions,
    );

    return reminders.map(reminderModel.serialize);
  };

  /**
   * Get user's reminders.
   *
   * @param userId User's id.
   * @returns Promise that resolves to user's reminders.
   */
  const getUserReminders = (userId: string): Promise<Reminder[]> =>
    getReminders(getQueryOptions(userId));

  /**
   * Get reminder.
   *
   * @param reminderId Reminder's id.
   * @returns Promise that resolves to Reminder.
   */
  const getReminder = async (reminderId: string): Promise<Reminder> => {
    const reminder = await getFirestorePipe().getDocument(
      REMINDERS_COLL_PATH,
      reminderId,
    );

    if (!reminder) {
      throw new Error('Reminder not found!');
    }

    return reminderModel.serialize(reminder);
  };

  /**
   * Create reminder.
   *
   * @param data Reminder's data.
   * @returns Promise that resolves to created reminder.
   */
  const createReminder = async (
    data: CreateReminderData,
  ): Promise<Reminder> => {
    const uuid = uuidv4();

    const reminder = await getFirestorePipe().addDocumentWithId(
      REMINDERS_COLL_PATH,
      uuid,
      reminderModel.deserialize({ ...data, reminderId: uuid }),
    );

    return reminderModel.serialize(reminder);
  };

  /**
   * Update reminder.
   *
   * @param reminderId Reminder's id.
   * @param data Reminder's data.
   * @returns Promise that resolves to updated reminder.
   */
  const updateReminder = async (
    reminderId: string,
    data: Partial<Reminder>,
  ): Promise<Reminder> => {
    const reminder = await getFirestorePipe().editDocument(
      REMINDERS_COLL_PATH,
      reminderId,
      reminderModel.deserialize(data),
    );

    return reminderModel.serialize(reminder);
  };

  /**
   * Delete reminder.
   *
   * @param reminderId Reminder's id.
   * @returns Promise that resolves when reminder has been successfully deleted.
   */
  const deleteReminder = async (reminderId: string): Promise<boolean> => {
    const result = await getFirestorePipe().deleteDocument(
      REMINDERS_COLL_PATH,
      reminderId,
    );

    return result;
  };

  /**
   * Send reminder email.
   *
   * @returns Promise that resolves when reminder email has been sent.
   */
  const triggerSendReminderEmail = async () => {
    return getFirebaseFunctionsPipe().callCloudFunction(
      'admin-callableSendReminderEmails',
    );
  };

  return {
    getReminders,
    getUserReminders,
    getReminder,
    createReminder,
    updateReminder,
    deleteReminder,
    triggerSendReminderEmail,
  };
};

export default getRemindersService;
