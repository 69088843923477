import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 200px;
  height: 48px;
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
`;

interface ColorProps {
  $color?: string;
}

const Color = styled.div<ColorProps>`
  width: 48px;
  height: 48px;
  border: 1px solid ${(props) => props.theme.colors.grayOpaque};
  border-radius: 4px;
  background-color: ${(props) => props.$color || 'transparent'};
  margin-right: 4px;
`;

interface FontProps {
  $bold?: boolean;
}

const Font = styled.div<FontProps>`
  ${(props) => props.theme.typography.h3}
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: '12px';
  font-weight: ${(props) => (props.$bold ? 700 : 400)};
  margin-right: 4px;
`;

interface Props {
  $color: string;
  name: string;
}

const ColorTile: FC<Props> = ({ $color, name }) => {
  return (
    <Wrapper>
      <Color $color={$color} />
      <TextWrapper>
        <Font $bold>{name}</Font>
        <Font>{$color}</Font>
      </TextWrapper>
    </Wrapper>
  );
};

export default ColorTile;
