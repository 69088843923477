import { FC, useState, WheelEvent } from 'react';
import { isEmpty } from 'ramda';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ContactSelectors } from '../../../store/selectors';
import { ContactActions } from '../../../store/actions';

import { Button, Heading, Icon, Text } from '../../../components';
import AddContactDialog from '../CreateProspect/Dialogs/AddContactDialog';
import EditContactDialog from '../CreateProspect/Dialogs/EditContactDialog';
import ContactTile from '../../Stores/CreateStore/Components/ContactTile';
import AddContactTile from '../../Stores/CreateStore/Components/AddContactTile';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 80px auto 0 auto;
  width: 70%;
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 48px;
`;

const ContactsContainer = styled.div`
  display: flex;
  margin: 22px auto;
  overflow-x: auto;
  width: 100%;
  max-width: 1200px;

  /* we add padding because otherwise the box-shadow of the tile is cut-off */
  padding-bottom: 4px;
  ::-webkit-scrollbar-thumb {
    /* Handle */
    background: ${(props) => props.theme.colors.grayOpaque};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track-piece {
    /* not handle on */
    background: ${(props) => props.theme.colors.highlight};
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 60px 48px 60px;
`;

const PrimaryButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SkipButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.text};
  color: ${({ theme }) => theme.colors.background};
  margin-right: 16px;
`;

const PrevIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 8px;
`;

interface Props {
  onPrev: () => void;
  onNext: () => void;
}

const Contacts: FC<Props> = ({ onPrev, onNext }) => {
  const dispatch = useDispatch();

  const contacts = useSelector(ContactSelectors.selectContactsPreview);

  const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false);
  const [isEditContactDialogOpen, setIsEditContactDialogOpen] = useState(false);
  const [contactIndex, setContactIndex] = useState<number | undefined>(
    undefined,
  );

  const handleContactEdit = (index: number) => {
    setContactIndex(index);

    setIsEditContactDialogOpen(true);
  };

  const handleOpenContactAdd = () => {
    setIsAddContactDialogOpen(true);
  };

  const handleCloseContactAdd = () => {
    setIsAddContactDialogOpen(false);
  };

  const handleCloseContactEdit = () => {
    setIsEditContactDialogOpen(false);

    setContactIndex(undefined);
  };

  const handleContactDelete = (index: number) => {
    dispatch(ContactActions.contactRemoved(index));
  };

  const onWheel = (event: WheelEvent<HTMLDivElement>) => {
    if (event.deltaY === 0) return;

    event.currentTarget.scrollTo({
      left: event.currentTarget.scrollLeft + event.deltaY,
    });
  };

  const nextDisabled = isEmpty(contacts);

  const addSlots = 4 - contacts.length > 0 ? 4 - contacts.length : 1;

  return (
    <Wrapper>
      <Content>
        <StyledHeading variant="h1">Who is the contact person?</StyledHeading>
        <StyledText variant="body2">
          Who can be contacted for the contract and for the product pitch.
        </StyledText>
        <ContactsContainer onWheel={onWheel}>
          {contacts.map((contact, index) => (
            <ContactTile
              key={index}
              contact={contact}
              onEdit={() => handleContactEdit(index)}
              onRemove={() => handleContactDelete(index)}
            />
          ))}
          {[...Array(addSlots)].map((_a, index) => {
            return (
              <AddContactTile key={index} onClick={handleOpenContactAdd} />
            );
          })}
        </ContactsContainer>
      </Content>
      <Buttons>
        <Button variant="outlined" onClick={onPrev}>
          <PrevIcon name="chevron-back" />
          Previous
        </Button>
        <PrimaryButtons>
          <SkipButton onClick={onNext}>Skip</SkipButton>
          <Button disabled={nextDisabled} onClick={onNext}>
            Next
          </Button>
        </PrimaryButtons>
      </Buttons>
      <AddContactDialog
        open={isAddContactDialogOpen}
        onClose={handleCloseContactAdd}
      />
      <EditContactDialog
        open={isEditContactDialogOpen}
        contactIndex={contactIndex}
        onClose={handleCloseContactEdit}
      />
    </Wrapper>
  );
};

export default Contacts;
