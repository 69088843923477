import { createAction } from '@reduxjs/toolkit';

import { Contact } from '../../models/contactModel';

export const contactAdded = createAction<Contact>('contacts/contactAdded');

export const contactUpdated = createAction<{
  index: number;
  contact: Contact;
}>('contacts/contactUpdated');

export const contactRemoved = createAction<number>('contacts/contactRemoved');

export const reset = createAction('contacts/reset');
