import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  apply,
  BillingCycles,
  formatDate,
  isNilOrWhitespace,
  Store,
  storeModel,
} from '@atogear/arion-utils';
import { addDays } from 'date-fns';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreActions } from '../../../store/actions';
import { SettingsSelectors, StoreSelectors } from '../../../store/selectors';

import { getBaseMonthlySubPrice } from '../../../models/appSettingsModel';

import { appRoutes, getPath } from '../../../routing';

import {
  CYCLE_OPTIONS,
  PAYMENT_TERM_OPTIONS,
  SUBSCRIPTION_CURRENCY_OPTIONS,
} from '../../../utils/constants';
import { isValidEmail } from '../../../utils/validators';
import { getCurrencyCountryCode } from '../../../utils/i18nUtils';

import {
  Button,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  FlagIcon,
  Option,
} from '../../../components';
import { StoreRouteParams } from '../types';

import PaymentDialogSummary from './PaymentDialogSummary';

const SUBSCRIPTION_CURRENCY_OPTIONS_WITH_FLAGS =
  SUBSCRIPTION_CURRENCY_OPTIONS.map((option) => ({
    ...option,
    adornment: <FlagIcon country={getCurrencyCountryCode(option.value)} />,
  }));

const StyledDialog = styled(Dialog)`
  width: 1000px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 32px;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

interface GroupProps {
  $row?: boolean;
  $marginTop?: boolean;
}

const Group = styled.div<GroupProps>`
  display: flex;
  flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
  justify-content: ${({ $row }) => ($row ? 'space-between' : 'center')};
  margin-top: ${({ $marginTop }) => ($marginTop ? 32 : 16)}px;

  &:first-child {
    margin-top: 0;
  }
`;

interface FieldProps {
  $right?: boolean;
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ $right }) => `margin-${$right ? 'left' : 'right'}: 4px;`}
`;

const PaymentTerm = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHeading = styled(DialogInputLabel)`
  margin-top: 32px;
`;

const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SecondaryButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

interface Props {
  open?: boolean;
  store?: Store;
  onClose?: () => void;
}

const PaymentDialog: FC<Props> = ({ store, open, onClose }) => {
  const dispatch = useDispatch();

  const appSettings = useSelector(SettingsSelectors.selectAppSettings);
  const loading = useSelector(StoreSelectors.selectStoreUpdating);

  const history = useHistory();

  const { storeId } = useParams<StoreRouteParams>();

  const formRef = useRef<HTMLFormElement | null>(null);

  const [data, setData] = useState(storeModel.create(store));

  const {
    subStartDate,
    subscriptionCurrency,
    billingCycle,
    billingPaymentTerm,
    billingFirstName,
    billingLastName,
    billingEmail,
  } = useMemo(
    () => ({
      subStartDate: apply(
        storeModel.getSubscriptionStartDate,
        data,
        Date.now(),
      ),
      subscriptionCurrency: data.subscriptionCurrency || 'EUR',
      billingCycle: apply(
        storeModel.getBillingCycle,
        data,
        BillingCycles.MONTHLY,
      ),
      billingPaymentTerm: apply(storeModel.getBillingPaymentTerm, data, 30),
      billingFirstName: apply(storeModel.getBillingFirstName, data, ''),
      billingLastName: apply(storeModel.getBillingLastName, data, ''),
      billingEmail: apply(storeModel.getBillingEmail, data, ''),
    }),
    [data],
  );

  const paymentDate = useMemo(() => {
    return formatDate(addDays(subStartDate, billingPaymentTerm));
  }, [billingPaymentTerm, subStartDate]);

  useEffect(() => {
    if (open && store) {
      setData(store);
    }
  }, [open, store]);

  useEffect(() => {
    // If the store does not have the base subscription price set we set it to the default from the app settings
    if (data && !data.subscriptionPrice) {
      setData((prev) => ({
        ...prev,
        subscriptionPrice: getBaseMonthlySubPrice(appSettings),
      }));
    }
  }, [appSettings, data]);

  const handleUpdate = async () => {
    try {
      if (!storeId || !formRef.current?.checkValidity()) {
        return;
      }

      await dispatch(
        StoreActions.updateStore({
          storeId,
          data: {
            ...data,
            // NOTE: Explicitly pass values to update fn to make sure they are set correctly
            subscriptionStartDate: subStartDate,
            billingCycle,
            billingPaymentTerm,
            subscriptionCurrency,
          },
        }),
      ).unwrap();

      toast("Successfully updated the store's Payment plan!", {
        type: 'success',
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem updating the store's payment plan. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      if (!store || !storeId) {
        return;
      }

      e.preventDefault();

      await handleUpdate();

      history.push(
        getPath(appRoutes.agreement.path, {
          storeId: storeId,
        }),
      );
    } catch {}
  };

  const handleChange = (key: keyof Store, value: any) =>
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));

  const handleCycleChange = (option: Option | null) =>
    handleChange('billingCycle', option?.value || BillingCycles.MONTHLY);

  const handlePaymentTermChange = (option: Option | null) =>
    handleChange('billingPaymentTerm', option?.value || 30);

  const handleSubscriptionCurrencyChange = (option: Option | null) =>
    handleChange('subscriptionCurrency', option?.value || 'EUR');

  const handleFirstNameChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    handleChange('billingFirstName', e.target.value);

  const handleLastNameChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    handleChange('billingLastName', e.target.value);

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    handleChange('billingEmail', e.target.value.toLowerCase());

  const handlePriceChange = (price: number) =>
    handleChange('subscriptionPrice', price);

  // TODO: Validate on more than just empty strings
  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const id = useId();
  const cycleId = `${id}-cycle`;
  const paymentTermId = `${id}-payment-term`;
  const paymentDateId = `${id}-payment-date`;
  const paymentCurrencyId = `${id}-payment-currency`;
  const firstNameId = `${id}-first-name`;
  const lastNameId = `${id}-last-name`;
  const emailId = `${id}-email`;

  const valid =
    handleValidate(billingFirstName) &&
    handleValidate(billingLastName) &&
    isValidEmail(billingEmail);

  return (
    <StyledDialog
      open={open}
      loading={loading}
      title="Payment plan settings"
      onClose={onClose}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container>
          <Wrapper>
            <Group>
              <StyledInputLabel htmlFor={cycleId}>
                Billing cycle
              </StyledInputLabel>
              <DialogSelect
                id={cycleId}
                options={CYCLE_OPTIONS}
                value={billingCycle}
                onChange={handleCycleChange}
              />
            </Group>
            <Group>
              <StyledInputLabel htmlFor={paymentTermId}>
                Payment term
              </StyledInputLabel>
              <PaymentTerm>
                <Field>
                  <DialogSelect
                    id={paymentTermId}
                    options={PAYMENT_TERM_OPTIONS}
                    value={billingPaymentTerm}
                    onChange={handlePaymentTermChange}
                  />
                </Field>
                <Field $right>
                  <DialogInput
                    id={paymentDateId}
                    disabled
                    value={paymentDate}
                  />
                </Field>
              </PaymentTerm>
            </Group>
            <Group>
              <StyledInputLabel htmlFor={paymentCurrencyId}>
                Currency
              </StyledInputLabel>
              <Field>
                <DialogSelect
                  id={paymentCurrencyId}
                  options={SUBSCRIPTION_CURRENCY_OPTIONS_WITH_FLAGS}
                  startAdornment={
                    <FlagIcon
                      country={getCurrencyCountryCode(subscriptionCurrency)}
                    />
                  }
                  value={subscriptionCurrency}
                  onChange={handleSubscriptionCurrencyChange}
                />
              </Field>
            </Group>
            <StyledHeading variant="h3">Billing person</StyledHeading>
            <Group $row>
              <Field>
                <StyledInputLabel htmlFor={firstNameId}>
                  First name
                </StyledInputLabel>
                <DialogInput
                  id={firstNameId}
                  required
                  value={billingFirstName}
                  validate={handleValidate}
                  onChange={handleFirstNameChange}
                />
              </Field>
              <Field $right>
                <StyledInputLabel htmlFor={lastNameId}>
                  Last name
                </StyledInputLabel>
                <DialogInput
                  id={lastNameId}
                  required
                  value={billingLastName}
                  validate={handleValidate}
                  onChange={handleLastNameChange}
                />
              </Field>
            </Group>
            <Group>
              <StyledInputLabel htmlFor={emailId}>Email</StyledInputLabel>
              <DialogInput
                id={emailId}
                required
                type="email"
                value={billingEmail}
                validate={isValidEmail}
                onChange={handleEmailChange}
              />
            </Group>
          </Wrapper>
          <PaymentDialogSummary store={data} onChange={handlePriceChange} />
        </Container>
        <DialogActions>
          <StyledActions>
            <SecondaryButton
              disabled={!valid}
              size="small"
              type="button"
              variant="outlined"
              onClick={handleUpdate}
            >
              Save
            </SecondaryButton>
            <Button
              color="primary"
              disabled={!valid}
              size="small"
              type="submit"
              variant="contained"
            >
              Proceed to agreement
            </Button>
          </StyledActions>
        </DialogActions>
      </Form>
    </StyledDialog>
  );
};

export default PaymentDialog;
