import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { AuthActions } from '../../../store/actions';

import {
  Button,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
} from '../../../components';
import { AuthSelectors } from '../../../store/selectors';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledInputLabel = styled(DialogInputLabel)`
  display: flex;
  margin-bottom: 10px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

const StyledDialog = styled(Dialog)`
  min-width: 400px;
`;

const HiddenInput = styled(DialogInput)`
  display: none;
`;

interface Props {
  open?: boolean;
  onClose?: () => void;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
}

const ChangePasswordDialog: FC<Props> = ({ open, onClose, onCancel }) => {
  const dispatch = useDispatch();

  const updating = useSelector(AuthSelectors.selectUserUpdating);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      await dispatch(
        AuthActions.changePassword({
          currentPassword,
          newPassword,
          confirmPassword,
        }),
      ).unwrap();

      toast('Successfully changed password!', {
        type: 'success',
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      toast((error as Error)?.message || 'Failed to change password!', {
        type: 'error',
      });
    }
  };

  const handleCurrentPasswordChange: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => setCurrentPassword(e.target.value);

  const handleNewPasswordChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setNewPassword(e.target.value);

  const handleConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => setConfirmPassword(e.target.value);

  return (
    <StyledDialog
      open={open}
      loading={updating}
      title="Change password"
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Wrapper>
          <HiddenInput autoComplete="username" id="username" type="text" />
          <Group>
            <StyledInputLabel htmlFor="current-password">
              Current Password
            </StyledInputLabel>
            <DialogInput
              autoFocus
              autoComplete="current-password"
              id="current-password"
              maxLength={50}
              required
              type="password"
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor="new-password">
              New Password
            </StyledInputLabel>
            <DialogInput
              autoComplete="new-password"
              id="new-password"
              maxLength={50}
              required
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor="confirm-password">
              Confirm New Password
            </StyledInputLabel>
            <DialogInput
              autoComplete="new-password"
              id="confirm-password"
              maxLength={50}
              required
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Group>
        </Wrapper>
        <DialogActions>
          <CancelButton
            size="small"
            type="button"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </CancelButton>
          <Button
            color="primary"
            size="small"
            type="submit"
            variant="contained"
          >
            Change Password
          </Button>
        </DialogActions>
      </Form>
    </StyledDialog>
  );
};

export default ChangePasswordDialog;
