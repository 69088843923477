import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { apply, storeModel, StoreModule } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreActions } from '../../../store/actions';
import { StoreSelectors } from '../../../store/selectors';

import { Heading, Text } from '../../../components';
import Module from './Module';

import { StoreRouteParams } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const Container = styled.div`
  margin-top: 16px;
`;

const Modules: FC = () => {
  const dispatch = useDispatch();

  const store = useSelector(StoreSelectors.selectStore);

  const { storeId } = useParams<StoreRouteParams>();

  const modules = useMemo(() => {
    return Object.entries(
      apply(
        storeModel.getModules,
        store,
        storeModel.getModules(storeModel.defaults),
      ),
    ).sort() as [StoreModule, boolean][];
  }, [store]);

  const handleChange = (key: StoreModule) => () => {
    if (!storeId || !store) {
      return;
    }

    const newStore = storeModel.toggleModule(key, store);

    toast.promise(
      dispatch(
        StoreActions.updateStore({
          storeId,
          data: newStore,
        }),
      ).unwrap(),
      {
        pending: 'Updating modules...',
        success: 'Modules updated successfully!',
        error: 'Failed to update modules.',
      },
    );
  };

  return (
    <Wrapper>
      <StyledHeading variant="h1">Modules</StyledHeading>
      <Text variant="body2">
        Manage the store functional modules and permissions they have for
        ARIONHUB.
      </Text>
      <Container>
        {modules.map(([key, value]) => (
          <Module
            key={key}
            name={key}
            value={value}
            onClick={handleChange(key)}
          />
        ))}
      </Container>
    </Wrapper>
  );
};

export default Modules;
