import { Store } from '@atogear/arion-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthService, getShoeService } from '../../services';
import { UploadedShoe } from '../../models/shoeModel';

export const uploadShoes = createAsyncThunk(
  'shoes/uploadShoes',
  /**
   * Add Shoes.
   *
   * @param shoes Shoes to add.
   * @returns Promise that resolves when Shoes have been added successfully.
   */
  async (shoes: UploadedShoe[]): Promise<any> => {
    const token = await getAuthService().getIdToken();

    const result = await getShoeService()
      .upload(token, {
        origin: 'retailer',
        shoes,
      })
      .catch((err) => {
        return err;
      });

    if (result.error) {
      throw result.error;
    }

    return result;
  },
);

interface UploadParams {
  file: File;
  store: Store;
}
export const uploadFailedFile = createAsyncThunk(
  'shoes/uploadFailedFile',
  /**
   * Add Shoes.
   *
   * @param shoes Shoes to add.
   * @returns Promise that resolves when Shoes have been added successfully.
   */
  async (params: UploadParams) => {
    const { file, store } = params;

    const result = await getShoeService().saveFailedUpload(store, file);

    return result;
  },
);
