import { FC, FormEventHandler } from 'react';
import { Survey } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../store';
import { SurveysSelectors } from '../../store/selectors';

import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  Icon,
} from '../../components';
import { SurveyActions } from '../../store/actions';
import { startOfDay } from 'date-fns';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
  gap: 16px;
`;

const StyledInputLabel = styled(DialogInputLabel)``;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container,
  & input {
    width: 100%;
  }
`;

const Delete = styled.div`
  display: flex;
  flex: 1;
`;

const DeleteButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
`;

const DeleteIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.danger};
  margin-right: 8px;
`;

const CancelButton = styled(DeleteButton)`
  margin-right: 8px;
`;

interface Props {
  survey?: Survey;
  open: boolean;
  onCancel: () => void;
  onDelete: (survey: Survey) => void;
  onUpdate: (survey: Survey) => void;
  onCreate: (survey: Survey) => void;
}

const ManageSurveyDialog: FC<Props> = ({
  open,
  onCancel,
  onDelete,
  onUpdate,
  onCreate,
}) => {
  const dispatch = useDispatch();

  const preview = useSelector(SurveysSelectors.selectSurveyPreview);
  const updating = useSelector(SurveysSelectors.selectSurveyUpdating);

  const isCreating = preview && preview.id === '';

  const handleDelete = () => {
    if (!preview) {
      return;
    }

    onDelete(preview);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!preview) {
      return;
    }

    if (!isCreating) {
      onUpdate(preview);
    } else {
      onCreate(preview);
    }
  };

  return (
    <Dialog
      loading={updating}
      open={open}
      title={!isCreating ? 'Edit survey' : 'Create new survey'}
      onClose={onCancel}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <StyledInputLabel htmlFor="name">Type</StyledInputLabel>
          <DialogSelect
            options={[
              {
                key: 'nps',
                value: 'nps',
                label: 'nps',
              },
            ]}
            value={preview?.type}
            onChange={(option) => {
              if (!option) {
                return;
              }

              dispatch(
                SurveyActions.surveyPreviewUpdate({
                  type: option?.value,
                }),
              );
            }}
          />
          <StyledInputLabel htmlFor="name">Start On</StyledInputLabel>
          <StyledDatePicker
            customInput={<DialogInput />}
            id="startAt"
            selected={
              preview?.startAt ? new Date(preview?.startAt) : new Date()
            }
            onChange={(date: Date | null) => {
              if (!date) {
                return;
              }

              dispatch(
                SurveyActions.surveyPreviewUpdate({
                  startAt: startOfDay(date).getTime(),
                }),
              );
            }}
          />
          <StyledInputLabel htmlFor="name">End On</StyledInputLabel>

          <StyledDatePicker
            customInput={<DialogInput />}
            id="endAt"
            selected={preview?.endAt ? new Date(preview?.endAt) : new Date()}
            onChange={(date: Date | null) => {
              if (!date) {
                return;
              }

              dispatch(
                SurveyActions.surveyPreviewUpdate({
                  endAt: startOfDay(date).getTime(),
                }),
              );
            }}
          />
        </Content>
        <DialogActions>
          {!isCreating && (
            <Delete>
              <DeleteButton
                disabled={updating}
                size="small"
                type="button"
                variant="outlined"
                onClick={handleDelete}
              >
                <DeleteIcon name="delete" />
                Delete
              </DeleteButton>
            </Delete>
          )}
          <CancelButton
            disabled={updating}
            size="small"
            type="button"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </CancelButton>
          <Button
            color="primary"
            disabled={
              updating ||
              (isCreating &&
                (!preview.type || !preview.startAt || !preview.endAt))
            }
            size="small"
            type="submit"
            variant="contained"
          >
            {!isCreating ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ManageSurveyDialog;
