import { createAction } from '@reduxjs/toolkit';

import { Theme } from '../../theme/types';
import { EditorState } from './types';

export const setThemeLightById = createAction<string | undefined>(
  'themes/setThemeLightById',
);

export const setThemeDarkById = createAction<string | undefined>(
  'themes/setThemeDarkById',
);

export const editorStateUpdated = createAction<Partial<EditorState>>(
  'themes/editorStateUpdated',
);

export const editorStateReset = createAction('themes/editorStateReset');

export const themePreviewUpdated = createAction<Theme>(
  'themes/themePreviewUpdated',
);

export const themeReset = createAction('themes/themeReset');

export const themesReset = createAction('themes/themesReset');

export const reset = createAction('themes/reset');
