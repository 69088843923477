import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import Heading from './Heading';
import Icon from './Icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

interface TabContentProps {
  $active?: boolean;
  $width?: number;
}

const Tab = styled.div<TabContentProps>`
  border-bottom-color: ${({ $active, theme }) =>
    $active ? theme.colors.primary : 'transparent'};
  border-bottom-style: solid;
  border-bottom-width: 3px;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  width: ${({ $width }) => ($width ? $width + 'px' : 'auto')};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
    border-bottom-color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 1400px) {
    width: ${({ $width }) => ($width ? $width * 0.8 + 'px' : 'auto')};
  }

  @media (max-width: 1000px) {
    width: ${({ $width }) => ($width ? $width * 0.6 + 'px' : 'auto')};
  }
`;

const TabIcon = styled(Icon)<TabContentProps>`
  color: ${({ $active, theme }) =>
    $active ? theme.colors.primary : theme.colors.textTwo};
  margin-bottom: 2px;
`;

interface TabConfig {
  icon?: string;
  label: string;
}

interface Props {
  className?: string;
  tabs: TabConfig[];
  tabIndex?: number;
  tabWidth?: number;
  onTabChange?: (index: number) => void;
}

const Tabs: FC<Props> = ({
  className,
  tabIndex,
  tabs,
  tabWidth,
  onTabChange,
}) => {
  const [innerTabIndex, setInnerTabIndex] = useState(tabIndex || 0);

  useEffect(() => {
    if (tabIndex) {
      setInnerTabIndex(tabIndex);
    }
  }, [tabIndex]);

  const handleTabChange = (index: number) => () => {
    setInnerTabIndex(index);

    onTabChange && onTabChange(index);
  };

  return (
    <Wrapper className={className}>
      {tabs.map(({ icon, label }, index) => {
        const active = innerTabIndex === index;

        return (
          <Tab
            key={`tab-${index}`}
            $active={active}
            $width={tabWidth}
            onClick={handleTabChange(index)}
          >
            {icon ? <TabIcon $active={active} name={icon} /> : null}
            <Heading variant={active ? 'h3' : 'h4'}>{label}</Heading>
          </Tab>
        );
      })}
    </Wrapper>
  );
};

export default Tabs;
