import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Icon from './Icon';

interface BaseInputProps {
  $error?: boolean;
}

interface WrapperProps extends BaseInputProps {
  $disabled?: boolean;
  $focused?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  border-color: ${({ $error, $focused, theme }) => {
    if ($error) {
      return theme.colors.danger;
    }

    if ($focused) {
      return theme.colors.primary;
    }

    return theme.colors.grayOpaque;
  }};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`;

interface AdornmentProps {
  $start?: boolean;
}

const Adornment = styled.span<AdornmentProps>`
  color: ${(props) => props.theme.colors.textTwo};
  cursor: pointer;
  display: flex;
  ${({ $start }) => `padding-${$start ? 'right' : 'left'}: 6px`};
`;

const StyledInput = styled.input<BaseInputProps>`
  ${({ theme }) => theme.typography.body2};
  background-color: transparent;
  border: none;
  caret-color: ${({ $error, theme }) =>
    $error ? theme.colors.danger : theme.colors.primary};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  display: flex;
  flex: 1;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  validate?: (value: string) => boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      error,
      disabled,
      type = 'text',
      startAdornment,
      endAdornment,
      validate,
      onClick,
      onBlur,
      onFocus,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const inputEl = useRef<HTMLInputElement | null>(null);

    const [invalid, setInvalid] = useState(error ?? false);

    const [focused, setFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const isPasswordInput = type === 'password';

    useEffect(() => {
      setInvalid(error ?? false);
    }, [error]);

    const checkInput = (value: string) => {
      if (validate) {
        setInvalid(!validate(value));
      }
    };

    const handleClick: MouseEventHandler<HTMLInputElement> = (e) => {
      inputEl.current && inputEl.current.focus();

      onClick && onClick(e);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      setFocused(false);

      checkInput(e.target.value);

      onBlur && onBlur(e);
    };

    const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
      setFocused(true);

      checkInput(e.target.value);

      onFocus && onFocus(e);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      checkInput(e.target.value);

      onChange && onChange(e);
    };

    return (
      <Wrapper
        className={className}
        $disabled={disabled}
        $error={invalid}
        $focused={focused}
        onClick={handleClick}
      >
        {startAdornment && (
          <Adornment $start onClick={handleClick}>
            {startAdornment}
          </Adornment>
        )}
        <StyledInput
          {...rest}
          ref={ref || inputEl}
          $error={invalid}
          disabled={disabled}
          type={showPassword ? 'text' : type}
          onClick={onClick}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
        />
        {endAdornment && (
          <Adornment onClick={handleClick}>{endAdornment}</Adornment>
        )}
        {isPasswordInput && (
          <Adornment onClick={() => setShowPassword((prev) => !prev)}>
            <Icon name={showPassword ? 'eye-closed' : 'eye'} />
          </Adornment>
        )}
      </Wrapper>
    );
  },
);

export default Input;
