import { Survey, surveyModel } from '@atogear/arion-utils';

export interface ApiSurvey extends Survey {
  numberOfResponses: number;
}

export const serialize = (data: any): ApiSurvey => {
  return {
    ...surveyModel.serialize(data),
    numberOfResponses: data.numberOfResponses || 0,
  };
};
