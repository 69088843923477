import { FC, FormEventHandler, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apply, capitalizeEveryWord, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../../store';
import { ProspectStoreActions } from '../../../../store/actions';
import {
  AuthSelectors,
  ProspectStoreSelectors,
} from '../../../../store/selectors';

import { Button, Dialog, DialogActions } from '../../../../components';

import { prospectStoreModel } from '../../../../models';
import { ProspectRouteParams } from '../../types';

import Address from './Address';
import Contact from './Contact';
import General from './General';
import Extra from './Extra';
import { ProspectStore } from '../../../../models/prospectStoreModel';
import { isEmpty } from 'ramda';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 900px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
  overflow-y: auto;
  max-height: 70vh;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.span`
  background-color: ${({ theme }) => theme.colors.grayOpaque};
  height: 1px;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

interface Props {
  store: ProspectStore | undefined;
  open?: boolean;
  onClose?: () => void;
}

const StoreDialog: FC<Props> = ({ store, open, onClose }) => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const loading = useSelector(
    ProspectStoreSelectors.selectProspectStoreLoading,
  );

  const { prospectId } = useParams<ProspectRouteParams>();

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);

  const [data, setData] = useState(prospectStoreModel.create(store));

  const modeType = isEmpty(store?.storeId) ? 'creat' : 'updat';

  useEffect(() => {
    if (store) {
      setData(store);
    } else {
      setData(prospectStoreModel.create(store));
    }
  }, [store]);

  const handleChange = (value: Partial<ProspectStore>) => {
    setData((prev) => ({
      ...prev,
      ...value,
    }));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      if (!data || !prospectId) {
        return;
      }

      if (modeType === 'creat') {
        await dispatch(
          ProspectStoreActions.createProspectStore({
            prospectId,
            prospectStore: data,
          }),
        )
          .unwrap()
          .then(() => setData(prospectStoreModel.create()));
      } else {
        await dispatch(
          ProspectStoreActions.updateProspectStore({
            prospectId,
            prospectStoreId: data.storeId,
            prospectStore: data,
          }),
        )
          .unwrap()
          .then(() => setData(prospectStoreModel.create()));
      }
      toast(`Successfully ${modeType}ed prospect store!`, {
        type: 'success',
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem ${modeType}ing the prospect store. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  const canEdit = isAdmin || isSales;

  return (
    <div>
      <Dialog
        loading={loading}
        open={open}
        title="Store profile"
        onClose={onClose}
      >
        <Form onSubmit={handleSubmit}>
          <Content>
            <Row>
              <General store={data} onChange={handleChange} />
            </Row>
            <Divider />
            <Row>
              <Address store={data} onChange={handleChange} />
              <Contact store={data} onChange={handleChange} />
            </Row>
            <Divider />
            <Extra store={data} onChange={handleChange} />
          </Content>
          <DialogActions>
            <CancelButton
              disabled={loading}
              size="small"
              type="button"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </CancelButton>
            <Button
              color="primary"
              disabled={loading || !canEdit}
              size="small"
              type="submit"
              variant="contained"
            >
              {capitalizeEveryWord(modeType)}e
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default StoreDialog;
