import { ChangeEventHandler, FC, useEffect, useId, useState } from 'react';
import { apply, isNilOrWhitespace, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import {
  DialogHeading,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  FlagIcon,
  Option,
} from '../../../../components';

import { ContactProspectStoreData } from '../../types';

import countries from '../../../../assets/data/countries.json';
import { ProspectStore } from '../../../../models/prospectStoreModel';

const countryOptions = countries
  .sort((a, b) => -b.callingCode.localeCompare(a.callingCode))
  .map(({ alpha2, callingCode }) => ({
    adornment: <FlagIcon country={alpha2.toLowerCase()} />,
    key: alpha2,
    label: callingCode,
    value: alpha2,
  }));

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
`;

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 24px;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

interface FieldProps {
  $direction?: 'column' | 'row';
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  margin-bottom: 16px;
`;

interface ColumnProps {
  $left?: boolean;
}

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  ${({ $left }) => (!$left ? 'flex: 1' : 'width: 120px')};
  ${({ $left }) => `margin-${$left ? 'right' : 'left'}: 4px`};
`;

interface Props {
  store?: ProspectStore;
  onChange: (value: ContactProspectStoreData) => void;
}

const Contact: FC<Props> = ({ store, onChange }) => {
  const [email, setEmail] = useState('');
  const [callingCode, setCallingCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [website, setWebsite] = useState('');

  useEffect(() => {
    setEmail(apply(storeModel.getEmail, store, ''));
    setCallingCode(apply(storeModel.getCallingCode, store, ''));
    setPhoneNumber(apply(storeModel.getPhoneNumber, store, ''));
    setWebsite(apply(storeModel.getWebsite, store, ''));
  }, [store]);

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setEmail(value);

    debouncedOnChange({
      email: value,
    });
  };

  const handleCallingCodeChange = (option: Option | null) => {
    const value = option?.value || callingCode;

    setCallingCode(value);

    // NOTE: Not debounced since it is selected from dropdown
    onChange({
      callingCode: value,
    });
  };

  const handlePhoneNumberChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setPhoneNumber(value);

    debouncedOnChange({
      phoneNumber: value,
    });
  };

  const handleWebsiteChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setWebsite(value);

    debouncedOnChange({
      website: value,
    });
  };

  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const id = useId();
  const emailId = `${id}-email`;
  const callingCodeId = `${id}-calling-code`;
  const phoneNumberId = `${id}-phone-number`;
  const websiteId = `${id}-website`;

  return (
    <Wrapper>
      <StyledHeading variant="h3">Contact info</StyledHeading>
      <Field>
        <StyledInputLabel htmlFor={emailId}>Email</StyledInputLabel>
        <DialogInput
          id={emailId}
          placeholder="Email"
          type="email"
          value={email}
          validate={handleValidate}
          onChange={handleEmailChange}
        />
      </Field>
      <StyledInputLabel htmlFor={phoneNumberId}>Phone</StyledInputLabel>
      <Field $direction="row">
        <Column $left>
          <DialogSelect
            id={callingCodeId}
            options={countryOptions}
            startAdornment={<FlagIcon country={callingCode} />}
            value={callingCode}
            onChange={handleCallingCodeChange}
          />
        </Column>
        <Column>
          <DialogInput
            id={phoneNumberId}
            placeholder="Phone Number"
            type="tel"
            value={phoneNumber}
            validate={handleValidate}
            onChange={handlePhoneNumberChange}
          />
        </Column>
      </Field>
      <StyledInputLabel htmlFor={websiteId}>Website</StyledInputLabel>
      <DialogInput
        id={websiteId}
        placeholder="Website"
        type="text"
        value={website}
        validate={handleValidate}
        onChange={handleWebsiteChange}
      />
    </Wrapper>
  );
};

export default Contact;
