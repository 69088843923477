import {
  deleteUndefinedFields,
  RequiredAny,
  validateOptionalNumber,
  validateRequiredArray,
  validateRequiredNumber,
  validateRequiredObject,
  validateRequiredString,
} from '@atogear/arion-utils';

export interface StaffData {
  id: string;
  soldShoes: number;
  employeeName: string;
  sessionCount: number;
  shoesScanned: number;
  storeCountry: string;
  storeId: string;
  storeName: string;
  visitIds: (string | null)[];
  visits: number;
  visitsSharedEmail?: number;
}

export const fields = {
  ID: '_id',
  SOLD_SHOES: 'soldShoes',
  EMPLOYEE_NAME: 'employeeName',
  SESSION_COUNT: 'sessionCount',
  SHOES_SCANNED: 'shoesScanned',
  STORE_COUNTRY: 'storeCountry',
  STORE_ID: 'storeId',
  STORE_NAME: 'storeName',
  VISIT_IDS: 'visitIds',
  VISITS: 'visits',
  VISITS_SHARED_EMAIL: 'visitsSharedEmail',
};

export const defaults: StaffData = {
  id: '',
  soldShoes: 0,
  employeeName: '',
  sessionCount: 0,
  shoesScanned: 0,
  storeCountry: '',
  storeId: '',
  storeName: '',
  visitIds: [],
  visits: 0,
  visitsSharedEmail: 0,
};

export const getId = (staff?: StaffData) => (staff || defaults).id;

export const getBoughtShoes = (staff?: StaffData) =>
  (staff || defaults).soldShoes;

export const getEmployeeName = (staff?: StaffData) =>
  (staff || defaults).employeeName;

export const getSessionCount = (staff?: StaffData) =>
  (staff || defaults).sessionCount;

export const getShoesScanned = (staff?: StaffData) =>
  (staff || defaults).shoesScanned;

export const getStoreCountry = (staff?: StaffData) =>
  (staff || defaults).storeCountry;

export const getStoreId = (staff?: StaffData) => (staff || defaults).storeId;

export const getStoreName = (staff?: StaffData) =>
  (staff || defaults).storeName;

export const getVisitIds = (staff?: StaffData) => (staff || defaults).visitIds;

export const getVisits = (staff?: StaffData) => (staff || defaults).visits;

export const getVisitsSharedEmail = (staff?: StaffData) =>
  (staff || defaults).visitsSharedEmail;

export const serialize = (data: RequiredAny): StaffData => {
  validateRequiredObject(data, 'api store account data');

  return deleteUndefinedFields({
    id: validateRequiredString(data._id.userId, 'id'),
    soldShoes: validateRequiredNumber(data.soldShoes, 'soldShoes'),
    employeeName: validateRequiredString(data.employeeName, 'employeeName'),
    sessionCount: validateRequiredNumber(data.sessionCount, 'sessionCount'),
    shoesScanned: validateRequiredNumber(data.shoesScanned, 'shoesScanned'),
    storeCountry: validateRequiredString(data.storeCountry, 'storeCountry'),
    storeId: validateRequiredString(data.storeId, 'storeId'),
    storeName: validateRequiredString(data.storeName, 'storeName'),
    visitIds: validateRequiredArray(data.visitIds, 'visitIds').map((visitId) =>
      // TODO: should visitId be null at all? why not just not push it into the array in the backend?
      visitId === null ? null : validateRequiredString(visitId, 'visitId'),
    ),
    visits: validateRequiredNumber(data.visits, 'visits'),
    visitsSharedEmail: validateOptionalNumber(
      data.visitsSharedEmail,
      'visitsSharedEmail',
    ),
  });
};
