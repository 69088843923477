import { createAsyncThunk } from '@reduxjs/toolkit';

import { getEventService } from '../../services';

import { Event } from '../../models/eventModel';
import { EventOptions } from '../../services/events';

interface GetEventParams {
  parentId: string;
  options?: EventOptions;
}

export const getEvents = createAsyncThunk(
  'events/getEvents',
  /**
   * Get parent's Events.
   *
   * @param params parent's id and event options.
   * @returns Promise that resolves to parent's Events.
   */
  async (params: GetEventParams): Promise<Event[]> => {
    const { parentId, options } = params;
    const events = await getEventService().getEvents(parentId, options);

    return events;
  },
);
