import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { Group, Store } from '@atogear/arion-utils';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import { reset, resetGroup } from './actions';
import {
  createGroup,
  deleteGroup,
  getAccessibleGroups,
  getGroup,
  getGroups,
  getGroupStores,
  updateGroup,
} from './thunks';

interface State {
  groups: {
    data: Group[];
    fetching: boolean;
    error?: SerializedError;
  };
  group: {
    data?: Group;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  stores: {
    data: Store[];
    fetching: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  groups: {
    data: [],
    fetching: false,
    error: undefined,
  },
  group: {
    data: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
  stores: {
    data: [],
    fetching: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Groups
    .addCase(getGroups.pending, setFetchingState('groups'))
    .addCase(getGroups.fulfilled, (state, { payload }) => {
      state.groups.data = payload;
      setFulfilledState('groups')(state);
    })
    .addCase(getGroups.rejected, setRejectedState('groups'))
    // Get Group
    .addCase(getGroup.pending, setFetchingState('group'))
    .addCase(getGroup.fulfilled, (state, { payload }) => {
      state.group.data = payload;
      setFulfilledState('group')(state);
    })
    .addCase(getGroup.rejected, setRejectedState('group'))
    // Create Group
    .addCase(createGroup.pending, setUpdatingState('group'))
    .addCase(createGroup.fulfilled, (state, { payload }) => {
      state.group.data = payload;
      setFulfilledState('group')(state);
    })
    .addCase(createGroup.rejected, setRejectedState('group'))
    // Update Group
    .addCase(updateGroup.pending, setUpdatingState('group'))
    .addCase(updateGroup.fulfilled, (state, { payload }) => {
      state.group.data = payload;
      setFulfilledState('group')(state);
    })
    .addCase(updateGroup.rejected, setRejectedState('group'))
    // Delete Group
    .addCase(deleteGroup.pending, setUpdatingState('group'))
    .addCase(deleteGroup.fulfilled, (state) => {
      state.group.data = undefined;
      setFulfilledState('group')(state);
    })
    .addCase(deleteGroup.rejected, setRejectedState('group'))
    // Get Group Stores
    .addCase(getGroupStores.pending, setFetchingState('stores'))
    .addCase(getGroupStores.fulfilled, (state, { payload }) => {
      state.stores.data = payload;
      setFulfilledState('stores')(state);
    })
    .addCase(getGroupStores.rejected, setRejectedState('stores'))
    // Get Accessible Groups
    .addCase(getAccessibleGroups.pending, setFetchingState('groups'))
    .addCase(getAccessibleGroups.fulfilled, (state, { payload }) => {
      state.groups.data = payload;
      setFulfilledState('groups')(state);
    })
    .addCase(getAccessibleGroups.rejected, setRejectedState('groups'))
    // Reset
    .addCase(resetGroup, ({ group }) => {
      group.data = undefined;
    })
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
