import { FC } from 'react';
import Icon from './Icon';
import MouseTooltip from './MouseTooltip';

interface Props {
  tooltip: string;
}

const InformationIcon: FC<Props> = ({ tooltip, ...rest }) => {
  return (
    <MouseTooltip {...rest} tooltip={tooltip}>
      <Icon name="info" />
    </MouseTooltip>
  );
};

export default InformationIcon;
