import { FC } from 'react';
import styled from 'styled-components';

import {
  getDescription,
  getFormattedRedirectUrl,
  getImageUrl,
  getPriceText,
  PromotionItem,
} from '../../../../../models/promotionItemModel';

import { Link, Text } from '../../../../../components';

const Wrapper = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
`;

const Image = styled.img`
  height: 80px;
  width: 100%;
  object-fit: cover;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface Props {
  promotion: PromotionItem;
}

const PromoTile: FC<Props> = ({ promotion }) => {
  const description = getDescription(promotion);

  return (
    <Wrapper href={getFormattedRedirectUrl(promotion)}>
      <Content>
        {/* TODO: Check if image type is correct */}
        <Image src={getImageUrl(promotion).toString()} alt={description} />
        <TextWrapper>
          <Text variant="body3">{description}</Text>
          <Text variant="body3">{getPriceText(promotion)}</Text>
        </TextWrapper>
      </Content>
    </Wrapper>
  );
};

export default PromoTile;
