import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { useSelector } from '../../../store';
import { ContactSelectors, ProspectSelectors } from '../../../store/selectors';

import { appRoutes, getPath } from '../../../routing';

import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Dialog from '../../Dialog/Dialog';
import DialogActions from '../../Dialog/DialogActions';
import DialogHeading from '../../Dialog/DialogHeading';
import DialogText from '../../Dialog/DialogText';

import { ProspectRouteParams } from '../../../views/Prospects/types';
import { prospectModel } from '../../../models';
import { DocumentContact } from '../types';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

const ContactEmail = styled(DialogText)`
  display: flex;
  align-items: center;
  margin-left: 26px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

const StyledText = styled(DialogText)`
  margin-bottom: 16px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

interface Props {
  open?: boolean;
  onClose?: () => void;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: (contacts: DocumentContact[]) => void;
}

const ProspectDocumentDialog: FC<Props> = ({
  open,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const history = useHistory();

  const { prospectId } = useParams<ProspectRouteParams>();

  const contacts = useSelector(ContactSelectors.selectContacts);

  const prospect = useSelector(ProspectSelectors.selectProspect);

  const [prospectContacts, setProspectContacts] = useState<DocumentContact[]>(
    [],
  );

  useEffect(() => {
    if (!prospect && prospectId) {
      history.push(
        getPath(appRoutes.prospect.path, {
          prospectId,
        }),
      );
    } else if (!prospectId) {
      history.push(getPath(appRoutes.prospects.path));
    }
  }, [prospect, prospectId, history]);

  useEffect(() => {
    const newProspectContacts = contacts.map((contact) => {
      return {
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        isSelected: true,
      };
    });

    if (prospect) {
      const prospectSales = {
        email: prospectModel.getSalesRepEmail(prospect) || '',
        firstName: prospectModel.getSalesRepFirstName(prospect) || '',
        lastName: prospectModel.getSalesRepLastName(prospect) || '',
        isSelected: true,
      };

      // ? Prospect store owners?

      const prospectOwner = {
        email: prospectModel.getBillingEmail(prospect) || '',
        firstName: prospectModel.getBillingFirstName(prospect) || '',
        lastName: prospectModel.getBillingLastName(prospect) || '',
        isSelected: true,
      };

      newProspectContacts.push(prospectOwner);
      newProspectContacts.push(prospectSales);
    }

    setProspectContacts(newProspectContacts);
  }, [contacts, prospect]);

  const handleContactChange = (contact: DocumentContact) => {
    const newContacts = prospectContacts.map((c) => {
      if (c.email === contact.email) {
        c.isSelected = !c.isSelected;
      }
      return c;
    });

    setProspectContacts(newContacts);
  };

  const handleSubmit = () => {
    const selectedContacts = prospectContacts.filter(
      (contact) => contact.isSelected,
    );

    onConfirm && onConfirm(selectedContacts);
  };

  return (
    <Dialog open={open} title="Send" onClose={onClose}>
      <Content>
        <Row>
          <StyledText variant="body2">
            Select to whom you want to send the document
          </StyledText>
        </Row>
        {prospectContacts.map((contact) => (
          <Row key={contact.email}>
            <CheckboxWrapper>
              <StyledCheckbox
                checked={contact.isSelected}
                onChange={() => handleContactChange(contact)}
              />
              <DialogHeading>
                {contact.firstName} {contact.lastName}
              </DialogHeading>
            </CheckboxWrapper>

            <ContactEmail variant="body2">{contact.email}</ContactEmail>
          </Row>
        ))}
      </Content>
      <DialogActions>
        <CancelButton
          size="small"
          type="button"
          variant="outlined"
          onClick={onCancel}
        >
          Cancel
        </CancelButton>
        <Button
          color="primary"
          size="small"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProspectDocumentDialog;
