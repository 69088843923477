import { createAction } from '@reduxjs/toolkit';
import { Store } from '@atogear/arion-utils';

export const prospectStorePreviewUpdated = createAction<Partial<Store>>(
  'prospectStores/prospectStorePreviewUpdated',
);

export const prospectStorePreviewReset = createAction(
  'prospectStores/prospectStorePreviewReset',
);

export const reset = createAction('prospectStores/reset');
