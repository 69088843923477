import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalThemeService } from '../../services';

// Get Global Theme
export const getGlobalTheme = createAsyncThunk(
  'globalTheme/getGlobalTheme',
  getGlobalThemeService().getGlobalTheme,
);

// Update Global Theme
export const updateGlobalTheme = createAsyncThunk(
  'globalTheme/updateGlobalTheme',
  ({ themeId, data }) =>
    getGlobalThemeService().updateGlobalTheme(themeId, data),
);
