import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

interface WrapperProps {
  $small: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  min-width: 500px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  overflow-y: scroll;
`;

const StyledDocument = styled(Document)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledPage = styled(Page)`
  margin-top: 16px;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.shadow};
`;

interface Props {
  templateFile: Blob | Uint8Array | ArrayBuffer;
  focusedPage?: number;
  signBoxOpened?: boolean;
  pdf?: { data: Uint8Array };
}

const calcHeight = () => (window.innerWidth > 1100 ? 1300 : 900);

const DocumentPreview: FC<Props> = ({
  templateFile,
  focusedPage,
  pdf,
  signBoxOpened = false,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [height, setHeight] = useState(calcHeight());
  const wrapperRef = useRef<HTMLDivElement>(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error: any) {
    console.log(error);
  }

  useEffect(() => {
    if (wrapperRef && wrapperRef.current && focusedPage !== undefined) {
      wrapperRef.current.scrollTop = focusedPage * 1316;
    }
  }, [focusedPage]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(calcHeight());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef} $small={signBoxOpened}>
      <StyledDocument
        renderMode={'canvas'}
        file={pdf || templateFile}
        onLoadError={onDocumentLoadError}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <StyledPage
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            height={height}
          />
        ))}
      </StyledDocument>
    </Wrapper>
  );
};

export default DocumentPreview;
