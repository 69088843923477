import {
  CreateStoreData,
  Store,
  storeModel,
  User,
  userModel,
} from '@atogear/arion-utils';
import {
  getFirebaseFunctionsPipe,
  getFirestorePipe,
} from '@atogear/ato-broker';
import { QueryOptions } from '@atogear/ato-broker/firestore';

import { DefaultThemeIds } from '../enums';

import { Contact } from '../models/contactModel';
import { StoreOwner } from '../models/types';

import getThemeService from './themes';

import { CreateStoreResult, UpdateStoreResult } from './types';

const COLL_PATH = 'stores';

const getQueryOptions = (user: User): QueryOptions => {
  if (userModel.isGroupManager(user)) {
    return {
      where: {
        fieldName: storeModel.fields.GROUP_ID,
        operator: 'in',
        fieldValue: user.groups || [],
      },
    };
  }

  if (userModel.isOwner(user)) {
    return {
      where: {
        fieldName: storeModel.fields.OWNER_ID,
        operator: '==',
        fieldValue: userModel.getId(user),
      },
    };
  }

  if (
    userModel.isDistributor(user) ||
    userModel.isExternalSales(user) ||
    userModel.isInternalSales(user)
  ) {
    return {
      where: {
        fieldName: storeModel.fields.SALES_ID,
        operator: '==',
        fieldValue: userModel.getId(user),
      },
    };
  }

  return {};
};

const getStoreService = () => {
  /**
   * Get multiple Stores.
   *
   * @param queryOptions Query options.
   * @returns Promise that resolves to Stores.
   */
  const getStores = async (queryOptions?: QueryOptions): Promise<Store[]> => {
    const stores = await getFirestorePipe().getDocuments(
      COLL_PATH,
      queryOptions,
    );

    return stores.map(storeModel.serialize);
  };

  /**
   * Get User's Stores.
   *
   * @param user User.
   * @returns Promise that resolves to User's Stores.
   */
  const getUserStores = (user: User): Promise<Store[]> =>
    getStores(getQueryOptions(user));

  /**
   * Get Store.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Store.
   */
  const getStore = async (storeId: string): Promise<Store> => {
    const store = await getFirestorePipe().getDocument(COLL_PATH, storeId);

    if (!store) {
      throw new Error('Store not found!');
    }

    return storeModel.serialize(store);
  };

  /**
   * Create Store.
   *
   * @param store Store's data.
   * @param contacts Store's contacts.
   * @returns Promise that resolves to created Store's id.
   */
  const createStore = async (
    store: CreateStoreData,
    contacts: Contact[],
    owner: StoreOwner,
  ): Promise<string> => {
    const deserializedStore = storeModel.deserialize(store);
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-createStore',
      {
        store: deserializedStore,
        contacts,
        owner,
      },
    )) as CreateStoreResult;

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.storeId;
  };

  /**
   * Update Store document directly.
   *
   * @param storeId Store's id.
   * @param data Store's data.
   * @returns Promise that resolves to updated Store.
   */
  const updateStoreDoc = async (
    storeId: string,
    data: Partial<Store>,
  ): Promise<Store> => {
    const store = await getFirestorePipe().editDocument(
      COLL_PATH,
      storeId,
      storeModel.deserialize(data),
    );

    return storeModel.serialize(store);
  };

  /**
   * Update Store document using cloud function.
   *
   * @param storeId Store's id.
   * @param data Store's data.
   * @returns Promise that resolves to updated Store.
   */
  const updateStoreFn = async (
    storeId: string,
    data: Partial<Store>,
  ): Promise<Store> => {
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-updateStore',
      {
        storeId,
        store: storeModel.deserialize(data),
      },
    )) as UpdateStoreResult;

    if (!result.data.success) {
      throw result.data.error;
    }

    return storeModel.serialize(result.data.store);
  };

  /**
   * Upgrade Store.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves when Store has been upgraded successfully.
   */
  const upgradeStore = async (storeId: string): Promise<void> => {
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-upgradeStore',
      {
        storeId,
      },
    )) as UpdateStoreResult;

    if (!result.data.success) {
      throw result.data.error;
    }
  };

  const unassignStoreTheme = async (groupId: string, themeId: string) => {
    const themeService = getThemeService();

    const theme = await themeService.getTheme(groupId, themeId);

    if (theme) {
      const applyCount = Math.max(theme.applyCount - 1, 0);

      await themeService.updateTheme(groupId, themeId, {
        applyCount,
      });
    }
  };

  /**
   * Delete Store.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to true when Store has been successfully deleted.
   */
  const deleteStore = async (storeId: string): Promise<boolean> => {
    const store = await getStore(storeId);

    if (!store) {
      throw new Error('Store not found!');
    }

    const groupId = storeModel.getGroupId(store);
    const darkThemeId = storeModel.getDarkThemeId(store);
    const lightThemeId = storeModel.getLightThemeId(store);

    const promises = [];

    // Decrease Store's custom Theme's applyCount
    if (darkThemeId && darkThemeId !== DefaultThemeIds.ARION_DARK) {
      promises.push(unassignStoreTheme(groupId, darkThemeId));
    }

    if (lightThemeId && lightThemeId !== DefaultThemeIds.ARION_LIGHT) {
      promises.push(unassignStoreTheme(groupId, lightThemeId));
    }

    await Promise.all(promises);

    return getFirestorePipe().deleteDocument(COLL_PATH, storeId);
  };

  return {
    getStores,
    getUserStores,
    getStore,
    createStore,
    updateStoreDoc,
    updateStoreFn,
    upgradeStore,
    deleteStore,
  };
};

export default getStoreService;
