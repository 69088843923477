import { FC, Fragment, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { storeModel, userModel, UserRoles } from '@atogear/arion-utils';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { PromotionActions } from '../../../store/actions';
import {
  AuthSelectors,
  PromotionSelectors,
  StoreSelectors,
} from '../../../store/selectors';

import { FlyerPreviewType } from '../../../models/flyerModel';
import {
  getId as getPromotionId,
  Promotion,
} from '../../../models/promotionModel';

import {
  bindMessageListener,
  openPreview,
} from '../../../utils/messagingUtils';

import { Heading, Text } from '../../../components';
import PromotionTile from './Promotion/PromotionTile';
import AddTile from '../../../components/AddTile';

const StyledPromoHeading = styled(Heading)`
  margin-top: 32px;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

const PlaceholderWrapper = styled.div`
  display: none !important;
  & > div {
    display: none !important;
  }
`;

const AllPromotions: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const store = useSelector(StoreSelectors.selectStore);
  const promotions = useSelector(PromotionSelectors.selectPromotions);

  const filteredPromotions = promotions
    ? promotions.filter((promo) => getPromotionId(promo) !== 'metadata')
    : [];

  // Messaging
  const previewRef = useRef<FlyerPreviewType | null>(null);
  const pollingIdRef = useRef<NodeJS.Timer | null>(null);
  const pollingCount = useRef(0);

  useEffect(() => {
    const cleanUp = bindMessageListener(previewRef, pollingIdRef);

    return cleanUp;
  }, []);

  const handleUpdate = async (promotion: Promotion) => {
    if (!store) {
      return;
    }

    const ownerId = storeModel.getOwnerId(store);

    if (!ownerId) {
      toast('Promotion was not saved! Store owner does not exist!', {
        type: 'error',
      });
      return;
    }

    await dispatch(
      PromotionActions.updatePromotion({
        promotion,
        storeId: storeModel.getId(store),
        ownerId,
      }),
    )
      .unwrap()
      .then(() => {
        toast('Successfully updated promotion!', {
          type: 'success',
        });
      })
      .catch((err) => {
        toast('Failed to update promotion!', {
          type: 'error',
        });
      });
  };

  const handleRemove = (promotion: Promotion) => {
    if (!store) {
      return;
    }

    dispatch(
      PromotionActions.removePromotion({
        promotion,
        storeId: storeModel.getId(store),
      }),
    )
      .unwrap()
      .then(() => {
        toast('Successfully removed promotion!', {
          type: 'success',
        });
      })
      .catch((err) => {
        toast('Failed to remove promotion!', {
          type: 'error',
        });
      });
  };

  const handleAdd = () => {
    if (!store) {
      return;
    }

    dispatch(PromotionActions.addPromotions(storeModel.getId(store)))
      .unwrap()
      .then(() =>
        toast('Added new promotion!', {
          type: 'success',
        }),
      )
      .catch(() =>
        toast('Failed to add new promotion!', {
          type: 'error',
        }),
      );
  };

  const handleCopy = (promotion: Promotion) => {
    if (!store) {
      return;
    }

    dispatch(
      PromotionActions.copyPromotions({
        promotion,
        storeId: storeModel.getId(store),
      }),
    )
      .unwrap()
      .then(() => {
        toast('Successfully copied promotion!', {
          type: 'success',
        });
      })
      .catch((err) => {
        toast('Failed to copy promotion!', {
          type: 'error',
        });
      });
  };

  const handlePreview = async (promo: Promotion) => {
    try {
      // TODO: fetch theme for the store before opening the preview in the promotion
      await openPreview(previewRef, pollingIdRef, pollingCount, promo);
    } catch (error) {
      toast('Failed to create preview!', {
        type: 'error',
      });
    }
  };

  // permissions
  // invite -> admin, owner, back office
  const canSetDefault =
    !!user &&
    userModel.hasRole(
      [
        UserRoles.ADMIN,
        UserRoles.OWNER,
        UserRoles.BACK_OFFICE,
        UserRoles.INTERNAL_DESIGNER,
      ],
      user,
    );

  return (
    <Fragment>
      <StyledPromoHeading variant="h2">All promotions</StyledPromoHeading>
      <StyledText variant="body2">
        All promotions created for your store. Drag a collapsed promotional tile
        to the <b>"Current promotion"</b> section to start using it!
      </StyledText>
      <Droppable droppableId={'promotions-container'} isDropDisabled={true}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {filteredPromotions ? (
              filteredPromotions.length === 0 ? (
                <Text variant="body2">No promotions yet.</Text>
              ) : (
                filteredPromotions.map((promo: Promotion, index) => (
                  <PromotionTile
                    key={getPromotionId(promo)}
                    promotion={promo}
                    onUpdate={handleUpdate}
                    onCopy={handleCopy}
                    onRemove={handleRemove}
                    onPreview={handlePreview}
                    disabled={!canSetDefault}
                    index={index}
                  />
                ))
              )
            ) : null}
            {/* wrap the placeholder as we do not want the default behavior(if not rendered dnd is going to complain) */}
            <PlaceholderWrapper>{provided.placeholder}</PlaceholderWrapper>
          </div>
        )}
      </Droppable>
      <AddTile text={'promotion'} onClick={handleAdd} />
    </Fragment>
  );
};

export default AllPromotions;
