import { User, userModel, UserRoles } from '@atogear/arion-utils';
import {
  getFirebaseFunctionsPipe,
  getFirestorePipe,
} from '@atogear/ato-broker';
import firebase from 'firebase/compat/app';
import { QueryOptions } from '@atogear/ato-broker/firestore';

import { AccountResult, HttpsCallableResult } from './types';

const COLL_PATH = 'users';

const getAccountService = () => {
  /**
   * Get Users.
   *
   * @returns Promise that resolves to Users.
   */
  const getUsers = async (queryOptions?: QueryOptions): Promise<User[]> => {
    const accounts = await getFirestorePipe().getDocuments(
      COLL_PATH,
      queryOptions,
    );

    return accounts.map(userModel.serialize);
  };

  /**
   * Get User.
   *
   * @param userId User's id.
   * @returns Promise that resolves to User.
   */
  const getUser = async (userId: string): Promise<User> => {
    const account = await getFirestorePipe().getDocument(COLL_PATH, userId);

    if (!account) {
      throw new Error('User not found!');
    }

    return userModel.serialize(account);
  };

  /**
   * Create Account.
   *
   * @param email Account's email.
   * @param password Account's password.
   * @param firstName Account's first name.
   * @param lastName Account's last name.
   * @param role Account's role.
   * @returns Promise that resolves to created Account.
   */
  const createAccount = (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    role: UserRoles,
  ): Promise<AccountResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-createAccount', {
      email,
      password,
      firstName,
      lastName,
      role,
    });

  /**
   * Update Account.
   *
   * @param userId Account's id.
   * @param firstName Account's first name.
   * @param lastName Account's last name.
   * @param role Account's role.
   * @returns Promise that resolves to updated Account.
   */
  const updateAccount = (
    userId: string,
    firstName: string,
    lastName: string,
    role: UserRoles,
  ): Promise<AccountResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-updateAccount', {
      userId,
      firstName,
      lastName,
      role,
    });

  /**
   * Delete Account.
   *
   * @param userId Account's id.
   * @returns Promise that resolves when Account has been successfully deleted.
   */
  const deleteAccount = (userId: string): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-deleteAccount', {
      userId,
    });

  /**
   * Remove Account's temp password.
   *
   * @param userId Account's id.
   * @returns Promise that resolves when Account's temp password has been successfully removed.
   */
  const removeTempPassword = (userId: string): Promise<boolean> =>
    getFirestorePipe().editDocumentField(
      COLL_PATH,
      userId,
      userModel.fields.PASSWORD,
      firebase.firestore.FieldValue.delete(),
    );

  const logOwnerSignIn = (): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-logOwnerSignIn');

  return {
    getUsers,
    getUser,
    createAccount,
    updateAccount,
    deleteAccount,
    removeTempPassword,
    logOwnerSignIn,
  };
};

export default getAccountService;
