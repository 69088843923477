import { RootState } from '../types';

export const selectSurveys = (state: RootState) => state.surveys.surveys.data;
export const selectSurveysTotal = (state: RootState) =>
  state.surveys.surveys.total;

export const selectSurveysFetching = (state: RootState) =>
  state.surveys.surveys.fetching;

export const selectSurvey = (state: RootState) => state.surveys.survey.data;

export const selectSurveyPreview = (state: RootState) =>
  state.surveys.survey.preview;

export const selectSurveyFetching = (state: RootState) =>
  state.surveys.survey.fetching;

export const selectSurveyUpdating = (state: RootState) =>
  state.surveys.survey.updating;
