import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatDate } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ContactActions, ProspectActions } from '../../../store/actions';
import { ProspectSelectors } from '../../../store/selectors';

import { fillQuotePdf } from '../../../utils/pdfUtils';

import LoadingMask from '../../LoadingMask';
import Button from '../../Button';
import Icon from '../../Icon';
import IconButton from '../../IconButton';
import DocumentPreview from '../DocumentPreview';

import { ProspectRouteParams } from '../../../views/Prospects/types';
import { prospectModel } from '../../../models';
import DocumentPagePreview from '../DocumentPagePreview';

import quotePDF from '../../../assets/pdf/ARIONHUB-Quote.pdf';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
`;

const StyledDocumentPagePreview = styled(DocumentPagePreview)`
  z-index: 2;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const BottomButtonsWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 16px;
  right: 65px;
`;

const StyledPrintButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-right: 16px;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.shadow};
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.textTwo};
  cursor: pointer;
`;

const StyledLink = styled.a`
  display: none;
`;

const StyledBackButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  color: ${(props) => props.theme.colors.text};
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 16px;
  @media (max-width: 1700px) {
    bottom: 16px;
  }
  transition: bottom 0.2s ease-out;
  left: 250px;
`;

const StyledBackIcon = styled(Icon)`
  margin-right: 8px;
  color: ${(props) => props.theme.colors.primary};
`;

const StyledSaveButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  margin-right: 16px;
`;

const Quote: FC = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { prospectId } = useParams<ProspectRouteParams>();

  const prospect = useSelector(ProspectSelectors.selectProspect);
  const quoteLoading = useSelector(ProspectSelectors.selectQuoteLoading);

  const [focusedPage, setFocusedPage] = useState(0);

  const [pdf, setPdf] = useState<{ data: Uint8Array } | null>(null);

  const downloadRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (prospect) {
      fillQuotePdf(prospect).then((pdf) => {
        setPdf({ data: pdf });
      });
    }
  }, [prospect]);

  const getContacts = useCallback(() => {
    if (prospectId) {
      dispatch(
        ContactActions.getContacts({
          parentId: prospectId,
          options: { isProspect: true },
        }),
      );
    }
  }, [dispatch, prospectId]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const handleDownload = async () => {
    if (!pdf) return;

    if (downloadRef !== null) {
      const blob = new Blob([pdf.data], { type: 'application/pdf' });
      const link = downloadRef.current;

      if (!link) return;

      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  };

  const getQuoteName = useMemo(() => {
    const today = new Date();

    if (prospect) {
      const prospectName = prospectModel.getName(prospect);
      return `ARIONHUB Quote ${prospectName} ${formatDate(today)}`;
    }
    return `ARIONHUB Quote ${formatDate(today)}`;
  }, [prospect]);

  const handleSave = async () => {
    if (!prospectId || !pdf) return;

    await dispatch(
      ProspectActions.uploadQuote({
        prospectId,
        data: pdf.data,
      }),
    )
      .unwrap()
      .then(() => {
        toast('Quote saved!', {
          type: 'success',
        });
      })
      .catch(() => {
        toast('Quote could not be saved!', {
          type: 'error',
        });
      });
  };

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <Wrapper>
      <LoadingMask loading={quoteLoading} />
      <StyledDocumentPagePreview onPageClick={setFocusedPage} file={quotePDF} />
      <Content>
        <DocumentPreview
          templateFile={quotePDF}
          pdf={pdf || undefined}
          focusedPage={focusedPage}
        />
        <BottomButtonsWrapper>
          <StyledSaveButton variant="outlined" onClick={handleSave}>
            Save
          </StyledSaveButton>
          <StyledPrintButton color="secondary" onClick={handleDownload}>
            <StyledIcon name="download" />
          </StyledPrintButton>
        </BottomButtonsWrapper>
        <StyledBackButton variant="outlined" onClick={handleBackClick}>
          <StyledBackIcon name={'chevron-back'} />
          Back
        </StyledBackButton>
      </Content>
      <StyledLink ref={downloadRef} download={getQuoteName} />
    </Wrapper>
  );
};

export default Quote;
