import { UserRoles } from '@atogear/arion-utils';

import About from '../../views/Stores/About/About';
import Payments from '../../views/Stores/Payments/Payments';
import Subscriptions from '../../views/Stores/Subscriptions/Subscriptions';
import Team from '../../views/Stores/Team/Team';

const storePath = `/stores/:storeId`;

export const notSignedStoreRoutes = {
  about: {
    key: 'about',
    label: 'About',
    path: `${storePath}/about`,
    accessibleBy: [],
    component: About,
  },
  team: {
    key: 'team',
    label: 'Team',
    path: `${storePath}/team`,
    // TODO check which roles should be able to access this route
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Team,
  },
  subscription: {
    key: 'subscriptions',
    label: 'Subscription',
    path: `${storePath}/subscription`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.DISTRIBUTOR,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Subscriptions,
  },
  payments: {
    key: 'payments',
    label: 'Payments',
    path: `${storePath}/payments`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Payments,
  },
};
