import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { apply, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../../store';
import { StoreActions } from '../../../../store/actions';
import { StoreSelectors, ThemeSelectors } from '../../../../store/selectors';

import { getId, isDefault } from '../../../../models/themeModel';

import { Collapsible, Heading, Icon, Text } from '../../../../components';
import ThemeTileBody from './ThemeTileBody';
import ThemeTileHeader from './ThemeTileHeader';

import { StoreRouteParams } from '../../types';
import { Theme } from '../../../../theme/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurrentThemesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const AllThemesList = styled(CurrentThemesList)`
  margin-top: 48px;
`;

const CallToAction = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.grayOpaque};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  margin-top: 16px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const StyledCollapsible = styled(Collapsible)`
  margin-top: 16px;
`;

const StyledIcon = styled(Icon)`
  font-size: 20px;
  margin-right: 8px;
`;

const Themes: FC = () => {
  const dispatch = useDispatch();

  const themes = useSelector(ThemeSelectors.selectThemes);
  const currentThemes = useSelector(ThemeSelectors.selectCurrentThemes);
  const store = useSelector(StoreSelectors.selectStore);

  const { storeId } = useParams<StoreRouteParams>();

  const hasCustomThemeModule = apply(storeModel.hasCustomTheme, store, false);

  const handleSetTheme = async (isDark: boolean, themeId: string) => {
    if (!storeId) {
      return;
    }

    const data = isDark
      ? {
          darkThemeId: themeId,
        }
      : {
          lightThemeId: themeId,
        };

    await dispatch(
      StoreActions.updateStore({
        storeId,
        data,
      }),
    );
  };

  const renderTheme = (theme: Theme, isCurrent = false) => (
    <StyledCollapsible key={getId(theme)}>
      <ThemeTileHeader
        type={isDefault(theme) ? 'Default' : 'Custom'}
        theme={theme}
        isCurrent={isCurrent}
        onSetTheme={handleSetTheme}
      />
      <ThemeTileBody theme={theme} />
    </StyledCollapsible>
  );

  return (
    <Wrapper>
      <CurrentThemesList>
        <StyledHeading variant="h2">Current themes</StyledHeading>
        <Text variant="body2">
          Customise your ARIONHUB experience. You can select 1 light and 1 dark
          theme for your store.
        </Text>
        {currentThemes.map((theme) => renderTheme(theme, true))}
      </CurrentThemesList>
      {hasCustomThemeModule ? (
        <AllThemesList>
          <StyledHeading variant="h2">All themes</StyledHeading>
          <Text variant="body2">
            All themes available to your store. If you would like to add more
            themes please contact the ATOGEAR support or your sales
            representative.
          </Text>
          {themes.map((theme) => renderTheme(theme, false))}
        </AllThemesList>
      ) : (
        <CallToAction>
          <StyledIcon name="info" />
          <Text variant="body1">
            To create your own theme, you need to enable the Custom theme
            module. Contact your sales representative to request an upgrade.
          </Text>
        </CallToAction>
      )}
    </Wrapper>
  );
};

export default Themes;
