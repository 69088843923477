import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRemindersService } from '../../services';
import { CreateReminderData, Reminder } from '../../models/reminderModel';

export const getReminders = createAsyncThunk(
  'reminders/getReminders',
  /**
   * Get reminders.
   *
   * @returns Promise that resolves to reminders.
   */
  async (): Promise<Reminder[]> => {
    const reminders = await getRemindersService().getReminders();

    return reminders;
  },
);

interface GetUserRemindersParams {
  userId: string;
}

export const getUserReminders = createAsyncThunk(
  'reminders/getUserReminders',
  /**
   * Get user's reminders.
   *
   * @param params User's id.
   * @returns Promise that resolves to user's reminders.
   */
  async (params: GetUserRemindersParams): Promise<Reminder[]> => {
    const { userId } = params;
    const reminders = await getRemindersService().getUserReminders(userId);

    return reminders;
  },
);

interface GetReminderParams {
  reminderId: string;
}

export const getReminder = createAsyncThunk(
  'reminders/getReminder',
  /**
   * Get reminder.
   *
   * @param params Reminder's id.
   * @returns Promise that resolves to reminder.
   */
  async (params: GetReminderParams): Promise<Reminder> => {
    const { reminderId } = params;
    const reminder = await getRemindersService().getReminder(reminderId);

    return reminder;
  },
);

interface CreateReminderParams {
  data: CreateReminderData;
}

export const createReminder = createAsyncThunk(
  'reminders/createReminder',
  /**
   * Create reminder.
   *
   * @param params Reminder's data.
   * @returns Promise that resolves to created reminder.
   */
  async (params: CreateReminderParams): Promise<Reminder> => {
    const { data } = params;

    const reminder = await getRemindersService().createReminder(data);

    return reminder;
  },
);

interface UpdateReminderParams {
  reminderId: string;
  data: Partial<Reminder>;
}

export const updateReminder = createAsyncThunk(
  'reminders/updateReminder',
  /**
   * Update reminder.
   *
   * @param params Reminder's id and data.
   * @returns Promise that resolves to updated reminder.
   */
  async (params: UpdateReminderParams): Promise<Reminder> => {
    const { reminderId, data } = params;

    const reminder = await getRemindersService().updateReminder(
      reminderId,
      data,
    );

    return reminder;
  },
);

export const deleteReminder = createAsyncThunk(
  'reminders/deleteReminder',
  /**
   * Delete reminder.
   *
   * @param params Reminder's id.
   * @returns Promise that resolves when reminder has been successfully deleted.
   */
  async (params: GetReminderParams): Promise<string | undefined> => {
    const { reminderId } = params;
    const result = await getRemindersService().deleteReminder(reminderId);

    return result ? reminderId : undefined;
  },
);
