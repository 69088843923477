import { Fragment, useEffect } from 'react';
import { isNil } from 'ramda';
import { ToastContainer } from 'react-toastify';

import { useDispatch, useSelector } from './store';
import { AuthActions } from './store/actions';
import { AuthSelectors } from './store/selectors';

import AuthRouter from './views/Auth/AuthRouter';
import App from './views/App/App';
import SplashScreen from './views/App/SplashScreen';

import 'react-toastify/dist/ReactToastify.css';

const Root = () => {
  const dispatch = useDispatch();

  const initializing = useSelector(AuthSelectors.selectInitializing);
  const authUser = useSelector(AuthSelectors.selectAuthUser);

  useEffect(() => {
    dispatch(AuthActions.init());
  }, [dispatch]);

  if (initializing) {
    return <SplashScreen />;
  }

  return (
    <Fragment>
      {isNil(authUser) ? <AuthRouter /> : <App />}
      <ToastContainer
        position="bottom-left"
        toastClassName="custom-toast"
        theme="dark"
      />
    </Fragment>
  );
};

export default Root;
