import { FC, useRef, useState } from 'react';
import styled from 'styled-components';

import { Contact, getFullName, getInitials } from '../models/contactModel';

import Avatar from './Avatar';
import Chip from './Chip';
import ContactPopper from './ContactPopper';
import Icon from './Icon';
import Initials from './Initials';

const MoreIcon = styled(Icon)`
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  font-size: 12px;
  padding: 4px;
  transform: rotate(90deg);
  transition: background-color ease 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
  }
`;

interface Props {
  contact: Contact;
  index: number;
  onDismiss: (index: number, contact: Contact) => void;
  onEdit: (index: number, contact: Contact) => void;
  onDelete: (index: number, contact: Contact) => void;
  permission?: Boolean;
}

const ContactChip: FC<Props> = ({
  contact,
  index,
  onDismiss,
  onEdit,
  onDelete,
  permission,
}) => {
  const [open, setOpen] = useState(false);

  const iconRef = useRef<HTMLSpanElement | null>(null);

  const openPopper = () => setOpen(true);

  const closePopper = () => setOpen(false);

  const handleDismiss = () => {
    onDismiss(index, contact);

    closePopper();
  };

  const handleEdit = () => {
    onEdit(index, contact);

    closePopper();
  };

  const handleDelete = () => {
    onDelete(index, contact);

    closePopper();
  };

  return (
    <>
      <Chip
        avatar={
          <Avatar index={index}>
            <Initials>{getInitials(contact)}</Initials>
          </Avatar>
        }
        button={<MoreIcon ref={iconRef} name="more" onClick={openPopper} />}
        label={getFullName(contact)}
      />
      <ContactPopper
        anchorEl={iconRef.current}
        contact={contact}
        index={index}
        open={open}
        onDismiss={handleDismiss}
        onEdit={handleEdit}
        onDelete={handleDelete}
        permission={permission}
      />
    </>
  );
};

export default ContactChip;
