import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const format = (prop?: number | string, defaultValue = 20) => {
  if (!prop) {
    return `${defaultValue}px`;
  }

  if (typeof prop === 'number') {
    return `${prop}px`;
  }

  return prop;
};

const pulse = keyframes`
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.25;
  }
`;

interface SkeletonProps {
  $height?: number | string;
  $width?: number | string;
}

const StyledSkeleton = styled.div<SkeletonProps>`
  display: block;
  background-color: ${(props) => props.theme.colors.textTwo};
  opacity: 0.3;
  height: ${(props) => format(props.$height)};
  width: ${(props) => format(props.$width)};
  margin-top: 0px;
  margin-bottom: 0px;

  border-radius: 4px;
  animation: 1.5s ease-in-out 0.5s infinite normal none running ${pulse};

  &::before {
    content: ' ';
  }
`;

interface Props {
  height?: number | string;
  width?: number | string;
  style?: object;
}

const Skeleton: FC<Props> = (props) => {
  const { height, width, style, ...rest } = props;

  return (
    <StyledSkeleton
      $height={height}
      $width={width}
      style={style ? style : {}}
      {...rest}
    />
  );
};

export default Skeleton;
