import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apply, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ProspectStoreActions } from '../../../store/actions';
import {
  AuthSelectors,
  ProspectSelectors,
  ProspectStoreSelectors,
} from '../../../store/selectors';

import { Heading, Text } from '../../../components';
import AddTile from '../../../components/AddTile';
import StoreDialog from './StoreDialog/StoreDialog';
import StoreInfo from './StoreInfo';

import { ProspectRouteParams } from '../types';
import { ProspectStatus } from '../../../enums';
import DeleteDialog from '../../../components/DeleteDialog';
import { prospectModel, prospectStoreModel } from '../../../models';
import { ProspectStore } from '../../../models/prospectStoreModel';

const Wrapper = styled.div``;

const StyledAddTile = styled(AddTile)`
  margin-bottom: 24px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

const Stores: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const stores = useSelector(ProspectStoreSelectors.selectProspectStores);
  const prospect = useSelector(ProspectSelectors.selectProspect);
  const loading = useSelector(
    ProspectStoreSelectors.selectProspectStoreLoading,
  );

  const { prospectId } = useParams<ProspectRouteParams>();

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);
  const status = apply(
    prospectModel.getStatus,
    prospect,
    prospectModel.defaults.prospectStatus,
  );

  const [storeDialogOpen, setStoreDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentStore, setCurrentStore] = useState<ProspectStore | undefined>(
    undefined,
  );

  const getProspectStores = useCallback(async () => {
    try {
      if (prospectId) {
        await dispatch(
          ProspectStoreActions.getProspectStores({
            prospectId: prospectId || '',
          }),
        ).unwrap();
      }
    } catch {
      toast('Failed to retrieve prospect stores!', {
        type: 'error',
      });
    }
  }, [dispatch, prospectId]);

  useEffect(() => {
    getProspectStores();
  }, [getProspectStores]);

  const openStoreDialog = (store?: ProspectStore) => {
    if (!store) {
      const newStore = prospectStoreModel.create({
        storeName: apply(prospectModel.getName, prospect, ''),
        country: apply(prospectModel.getCountryCode, prospect, ''),
        subscriptionCurrency: apply(
          prospectModel.getSubscriptionCurrency,
          prospect,
          'EUR',
        ),
        preferredLanguage: apply(
          prospectModel.getPreferredLanguage,
          prospect,
          'english',
        ),
      });
      setCurrentStore(newStore);
    } else {
      setCurrentStore(store);
    }

    setStoreDialogOpen(true);
  };

  const closeStoreDialog = () => {
    setStoreDialogOpen(false);
    setCurrentStore(undefined);
  };

  const openDeleteDialog = (store: ProspectStore) => {
    setDeleteDialogOpen(true);
    setCurrentStore(store);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCurrentStore(undefined);
  };

  const handleDelete = async () => {
    try {
      if (!currentStore || !prospectId) {
        return;
      }

      await dispatch(
        ProspectStoreActions.deleteProspectStore({
          prospectId,
          prospectStoreId: currentStore.storeId,
        }),
      ).unwrap();

      toast(
        'Successfully deleted prospect store!',

        {
          type: 'success',
        },
      );

      closeDeleteDialog();
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem deleting the prospect store. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  const canEdit = isAdmin || isSales;

  const isTransformed = status === ProspectStatus.WON;

  return (
    <Wrapper>
      <StyledHeading variant="h1">Stores</StyledHeading>
      <StyledText variant="body2">
        Manage the stores that belong to the{' '}
        <b>{prospect ? prospectModel.getName(prospect) : 'current'} </b>
        prospect.
      </StyledText>
      {canEdit && !isTransformed ? (
        <StyledAddTile text={'store'} onClick={() => openStoreDialog()} />
      ) : null}
      {stores.map((store: ProspectStore, index) => (
        <StoreInfo
          key={store.storeId}
          store={store}
          onEdit={openStoreDialog}
          onDelete={openDeleteDialog}
        ></StoreInfo>
      ))}
      <StoreDialog
        store={currentStore}
        open={storeDialogOpen}
        onClose={closeStoreDialog}
      />
      <DeleteDialog
        text="store"
        content="This will permanently delete this store."
        loading={loading}
        open={deleteDialogOpen && Boolean(currentStore)}
        onClose={closeDeleteDialog}
        onConfirm={handleDelete}
      />
    </Wrapper>
  );
};

export default Stores;
