import { calcAvg, RequiredAny, visitModel } from '@atogear/arion-utils';

import { ApiVisit, getSoldShoes } from '../../models/apiVisitModel';

import {
  SessionGraphPoint,
  ShoeModelSales,
  VisitGraphPoint,
} from '../../types/stats';

import { PALLETTE3 } from '../../utils/colorUtils';
import { formatDateRange } from '../../utils/dateUtils';
import {
  defaultDemographics,
  sumSessionToDemographic,
  sumVisitToDemographic,
} from '../../utils/demographicsUtils';
import {
  defaultDerivatives,
  sumSessionPerformance,
  sumSessionToDerivatives,
} from '../../utils/derivativesUtils';
import {
  formatConversionRate,
  formatShoes,
  formatStores,
  formatVisits,
} from '../../utils/formatters';
import { deepMergeSum } from '../../utils/helperUtils';
import {
  defaultInjuries,
  formatInjuries,
  sumVisitToInjuries,
} from '../../utils/injuriesUtils';

import { RootState } from '../types';

// ===================================================================================================
// Redux-toolkit suggest that any data transformation happens in side the selectors
// This ensures that all components get the same data and don't have to each do the work on their own
// ===================================================================================================

export const selectStores = (state: RootState) => state.stats.stores.data;

export const selectStoresFetching = (state: RootState) =>
  state.stats.stores.fetching;

export const selectStoresError = (state: RootState) => state.stats.stores.error;

export const selectSessionStats = (state: RootState) => {
  const rawData = state.stats.sessions.data;

  const storeNames = new Set<string>();

  const colors: RequiredAny = {};

  const totals = {
    sessions: 0,
  };

  rawData.forEach(({ sessions, sessionCount }) => {
    sessions.forEach(({ storeName }) => storeNames.add(storeName));

    totals.sessions += sessionCount;
  });

  Array.from(storeNames).forEach(
    (name, index) => (colors[name] = PALLETTE3[index]),
  );

  const data = rawData.map(
    ({ date, dateType, formattedDate, sessionCount, sessions }) => {
      const currentPoint: SessionGraphPoint = {
        date,
        dateType,
        formattedDate,
        sessions,
        sessionCount,
      };
      // We need to add all store names as keys to the object for recharts to pick it up automatically
      storeNames.forEach((name) => {
        currentPoint[name] = 0;

        sessions.forEach(({ entries, storeName }) => {
          if (name === storeName) {
            currentPoint[name] = entries;
          }
        });
      });

      return currentPoint;
    },
  );

  return {
    points: storeNames,
    colors,
    data,
    totals,
  };
};

export const selectSessionStatsFetching = (state: RootState) =>
  state.stats.sessions.fetching;

export const selectSessionStatsError = (state: RootState) =>
  state.stats.sessions.error;

export const selectVisitStats = (state: RootState) => {
  const rawData = state.stats.visits.data;

  const storeNames = new Set<string>();
  const visitsMap = new Map<string, ApiVisit>();

  const colors: RequiredAny = {};

  // Get unique storeNames and visits
  rawData.forEach(({ visits }) =>
    visits.forEach((v) => {
      storeNames.add(v.storeName);
      visitsMap.set(v.id, v);
    }),
  );

  Array.from(storeNames).forEach(
    (name, index) => (colors[name] = PALLETTE3[index]),
  );

  // Graph data
  const data = rawData.map(
    ({ date, formattedDate, numberOfVisits, visits, dateType }) => {
      const currentPoint: VisitGraphPoint = {
        date,
        dateType: dateType,
        formattedDate,
        numberOfVisits,
        visits,
      };

      // We need to add all store names as keys to the object for recharts to pick it up
      storeNames.forEach((name) => {
        currentPoint[name] = visits.filter(
          ({ storeName }) => storeName === name,
        ).length;
      });

      return currentPoint;
    },
  );

  // Calc totals and averages
  const age = {
    sum: 0,
    count: 0,
  };

  const gender = {
    male: 0,
    female: 0,
  };

  const shoesTried = {
    sum: 0,
    count: 0,
  };

  const shoesSold = {
    sum: 0,
    count: 0,
  };

  let visitsShared = 0;

  visitsMap.forEach(({ demographics, injuries, numberOfSessions, ...rest }) => {
    age.sum += demographics.age.age;
    age.count += Number(demographics.age.age > 0);

    gender.female += demographics.gender.female;
    gender.male += demographics.gender.male;

    const visit = visitModel.serialize(rest);

    const tried = visitModel.getNumberOfTriedShoes(visit);

    if (tried > 0) {
      shoesTried.sum += tried;
      shoesTried.count += 1;
    }

    const sold = visitModel.getNumberOfSoldShoes(visit);

    if (sold > 0) {
      shoesSold.sum += sold;
      shoesSold.count += 1;
    }

    if (visitModel.isShared(visit)) {
      visitsShared += 1;
    }
  });

  const totals = {
    shoesSold: shoesSold.sum,
    shoesTried: shoesTried.sum,
    conversions: shoesSold.count,
    visitsShared,
    visits: visitsMap.size,
  };

  const avg = {
    age: age.sum > 0 ? calcAvg(age.sum, age.count) : undefined,
    gender:
      gender.female > 0 || gender.male > 0
        ? gender.female > gender.male
          ? 'female'
          : 'male'
        : undefined,
    shoesSold: calcAvg(shoesSold.sum, shoesSold.count),
    shoesTried: calcAvg(shoesTried.sum, shoesTried.count),
  };

  return {
    points: storeNames,
    colors,
    data,
    avg,
    totals,
  };
};

export const selectVisitStatsFetching = (state: RootState) =>
  state.stats.visits.fetching;

export const selectVisitStatsError = (state: RootState) =>
  state.stats.visits.error;

export const selectPerformanceStatsFetching = (state: RootState) =>
  state.stats.performance.fetching;

export const selectPerformanceStatsError = (state: RootState) =>
  state.stats.performance.error;

export const selectStaff = (state: RootState) => state.stats.staff.data;

export const selectStaffFetching = (state: RootState) =>
  state.stats.staff.fetching;

export const selectStaffError = (state: RootState) => state.stats.staff.error;

export const selectFilters = (state: RootState) => state.stats.filters;

export const selectStoreFilter = (state: RootState) =>
  state.stats.filters.storeIds;

export const selectDateFilter = (state: RootState) =>
  state.stats.filters.dateRange;

export const selectTimeFilter = (state: RootState) =>
  state.stats.filters.timeFrame;

export const selectFilterTitle = ({ stats }: RootState) =>
  `${formatDateRange(stats.filters.dateRange)}; ${formatStores(
    stats.filters.storeIds.length,
  )}`;

export const selectAverageShoeConversionRate = (state: RootState) => {
  const staffStats = state.stats.staff.data;

  let shoesSold = 0;
  let visitsShared = 0;

  staffStats.forEach(({ soldShoes, visitsSharedEmail }) => {
    shoesSold += soldShoes;
    visitsShared += visitsSharedEmail ?? 0;
  });

  const shoesSoldText = `${formatShoes(shoesSold)} sold`;
  const visitsSharedText = `${formatVisits(visitsShared)} shared`;

  return {
    subText: `${shoesSoldText} / ${visitsSharedText}`,
    value: formatConversionRate(calcAvg(shoesSold, visitsShared) ?? 0),
  };
};

const extractData = (data: any, formatter?: (key: string) => string) => {
  const totals = {
    unknown: 0,
    other: 0,
  };

  // Convert demographics to recharts data objects and return them
  const entries = Object.entries<number>(data).map((entry) => {
    totals[entry[0] === 'unknown' ? 'unknown' : 'other'] += entry[1];

    return {
      category: formatter ? formatter(entry[0]) : entry[0],
      value: entry[1],
    };
  });

  return {
    data: entries.filter((entry) => entry.category !== 'unknown'),
    totals: totals,
  };
};

export const selectDemographicsGraphData = (state: RootState) => {
  const demographics = state.stats.sessions.data.reduce(
    (prevDemographics, currentDay) => {
      const currentDemographics = currentDay.sessions.reduce(
        sumSessionToDemographic,
        defaultDemographics,
      );

      return deepMergeSum(prevDemographics, currentDemographics);
    },
    defaultDemographics,
  );

  return {
    age: extractData(demographics.age, (key) => key.split('to').join(' to ')),
    gender: extractData(demographics.gender),
  };
};

export const selectVisitsDemographicsGraphData = (state: RootState) => {
  const demographics = state.stats.visits.data.reduce(
    (prevDemographics, currentDay) => {
      const currentDemographics = currentDay.visits.reduce(
        sumVisitToDemographic,
        defaultDemographics,
      );

      return deepMergeSum(prevDemographics, currentDemographics);
    },
    defaultDemographics,
  );

  return {
    age: extractData(demographics.age, (key) => key.split('to').join(' to ')),
    gender: extractData(demographics.gender),
  };
};

export const selectDerivativesGraphData = (state: RootState) => {
  const derivatives = state.stats.sessions.data.reduce(
    (prevDerivatives, currentDay) => {
      const currentDerivatives = currentDay.sessions.reduce(
        sumSessionToDerivatives,
        defaultDerivatives,
      );

      return deepMergeSum(prevDerivatives, currentDerivatives);
    },
    defaultDerivatives,
  );

  const fnFunc = (key: string, prepend?: string) => {
    const nameParts = key.split(/(?=[A-Z])/);

    if (!nameParts) return prepend ? `${prepend} ${key}` : key;

    const newKey = nameParts.join(' ').toLowerCase();

    if (newKey.indexOf('unknown') > -1) {
      return newKey;
    }

    return prepend ? `${prepend} ${newKey}` : newKey;
  };

  return {
    runnerType: extractData(derivatives.runnerType, fnFunc),
    sessionType: extractData(derivatives.sessionType, fnFunc),
    cadence: extractData(derivatives.cadence, (key) => fnFunc(key, 'cadence')),
    runnerEfficiency: extractData(derivatives.runnerEfficiency, fnFunc),
  };
};

export const selectInjuriesGraphData = (state: RootState) => {
  const totals = {
    injured: 0,
    notInjured: 0,
    // unknown: 0,
    // other: 0,
  };

  const injuries = state.stats.visits.data.reduce(
    (prevInjuries, currentTimeframe) => {
      if (currentTimeframe.numberOfVisits === 0) return prevInjuries;

      const currentInjuries = currentTimeframe.visits.reduce(
        sumVisitToInjuries,
        defaultInjuries,
      );

      Object.entries(currentInjuries).forEach((entry) => {
        if (entry[0] === 'unknown') return;

        if (entry[0] === 'notInjured') {
          totals.notInjured += entry[1];
          return;
        }

        if (entry[1] > 0) {
          totals.injured += entry[1];
        }
      });

      return deepMergeSum(prevInjuries, currentInjuries);
    },
    defaultInjuries,
  );

  const combine = (a: any) => (a.left || 0) + (a.right || 0) + (a.other || 0);

  return {
    data: formatInjuries(injuries).sort((a, b) => combine(b) - combine(a)),
    totals,
  };
};

export const selectShoesOverviewGraphData = (state: RootState) => {
  const data: Object[] = [];

  const allVisits = state.stats.visits.data;

  allVisits.forEach((visitsPerDay) => {
    const date = visitsPerDay.formattedDate;
    let tried = 0;
    let bought = 0;

    if (visitsPerDay.visits[0]) {
      visitsPerDay.visits.forEach((singularVisit) => {
        if (singularVisit) {
          const { sessions } = singularVisit;
          const boughtShoes =
            singularVisit.boughtShoes && singularVisit.boughtShoes[0]
              ? singularVisit.boughtShoes
              : Object.values(getSoldShoes(singularVisit));

          tried += sessions ? Object.keys(sessions).length : 0;

          if (
            boughtShoes &&
            boughtShoes[0] &&
            boughtShoes[0].brand !== 'none'
          ) {
            bought += boughtShoes.length;
          }
        }
      });
    }

    const graphData = {
      tried,
      bought,
      date,
    };

    data.push(graphData);
  });

  return data;
};

export const selectShoesScansGraphData = (state: RootState) => {
  const data = [
    { category: 'Scanned', value: 0 },
    { category: 'Selected from list', value: 0 },
  ];

  const allVisits = state.stats.visits.data;

  allVisits.forEach((visitsPerDay) => {
    if (visitsPerDay.visits[0]) {
      visitsPerDay.visits.forEach((singularVisit, index) => {
        if (singularVisit) {
          for (const sesionId in singularVisit.sessions) {
            const shoeModel = singularVisit.sessions[sesionId].model;
            if (shoeModel && shoeModel !== '') {
              data[0].value += 1;
            } else {
              data[1].value += 1;
            }
          }
        }
      });
    }
  });
  return data;
};

export const selectMostSoldModelGraphData = (state: RootState) => {
  interface ModelProps {
    model?: string;
    sold: number;
  }

  const data: ModelProps[] = [];
  const allVisits = state.stats.visits.data;

  allVisits.forEach((visitsPerDay) => {
    if (visitsPerDay.visits[0]) {
      visitsPerDay.visits.forEach((singularVisit) => {
        const boughtShoes =
          singularVisit.boughtShoes && singularVisit.boughtShoes[0]
            ? singularVisit.boughtShoes
            : Object.values(getSoldShoes(singularVisit));

        if (boughtShoes && boughtShoes[0] && boughtShoes[0].brand !== 'none') {
          boughtShoes.forEach((shoe) => {
            if (shoe.model && shoe.model !== '') {
              const result = data.findIndex(
                (item) => item.model === shoe.model,
              );

              if (result > -1) data[result].sold += 1;
              else data.push({ model: shoe.model || '', sold: 1 });
            }
          });
        }
      });
    }
  });

  data.sort((first, second) => second.sold - first.sold);

  return data;
};

export const selectMostScannedModelGraphData = (state: RootState) => {
  interface ModelProps {
    model?: string;
    scanned: number;
  }

  const data: ModelProps[] = [];
  const allVisits = state.stats.visits.data;

  allVisits.forEach((visitsPerDay) => {
    if (visitsPerDay.visits[0]) {
      visitsPerDay.visits.forEach((singularVisit) => {
        if (singularVisit) {
          for (const sesionId in singularVisit.sessions) {
            const shoeModel = singularVisit.sessions[sesionId].model;
            if (shoeModel && shoeModel !== '') {
              const model = singularVisit.sessions[sesionId].model;

              const result = data.findIndex((item) => item.model === model);

              if (result > -1) data[result].scanned += 1;
              else data.push({ model: model || '', scanned: 1 });
            }
          }
        }
      });
    }
  });

  data.sort((first, second) => second.scanned - first.scanned);

  return data;
};

export const selectMostSoldBrandGraphData = (state: RootState) => {
  interface BrandProps {
    brand?: string;
    scanned: number;
    selected: number;
  }

  const data: BrandProps[] = [];
  const allVisits = state.stats.visits.data;

  allVisits.forEach((visitsPerDay) => {
    if (visitsPerDay.visits[0]) {
      visitsPerDay.visits.forEach((singularVisit) => {
        const boughtShoes =
          singularVisit.boughtShoes && singularVisit.boughtShoes[0]
            ? singularVisit.boughtShoes
            : Object.values(getSoldShoes(singularVisit));

        if (boughtShoes && boughtShoes[0] && boughtShoes[0].brand !== 'none') {
          boughtShoes.forEach((shoe) => {
            if (shoe.brand && shoe.brand !== '') {
              const isScanned = shoe.model !== '';

              const result = data.findIndex(
                (item) => item.brand === shoe.brand,
              );

              if (result > -1) {
                if (isScanned) data[result].scanned += 1;
                else data[result].selected += 1;
              } else {
                data.push({
                  brand: shoe.brand,
                  scanned: Number(isScanned),
                  selected: Number(!isScanned),
                });
              }
            }
          });
        }
      });
    }
  });

  data.sort(
    (first, second) =>
      second.scanned + second.selected - (first.scanned + first.selected),
  );

  const topFive = data.slice(0, 5);

  return topFive;
};

export const selectMostScannedBrandGraphData = (state: RootState) => {
  interface BrandProps {
    brand?: string;
    scanned: number;
    selected: number;
  }

  const data: BrandProps[] = [];
  const allVisits = state.stats.visits.data;

  allVisits.forEach((visitsPerDay) => {
    if (visitsPerDay.visits[0]) {
      visitsPerDay.visits.forEach((singularVisit) => {
        if (singularVisit) {
          for (const sesionId in singularVisit.sessions) {
            const { model, brand } = singularVisit.sessions[sesionId];

            const isSacnned = model !== '';

            const result = data.findIndex((item) => item.brand === brand);

            if (result > -1) {
              if (isSacnned) data[result].scanned += 1;
              else data[result].selected += 1;
            } else {
              data.push({
                brand: brand || '',
                scanned: Number(isSacnned),
                selected: Number(!isSacnned),
              });
            }
          }
        }
      });
    }
  });

  data.sort(
    (first, second) =>
      second.scanned + second.selected - (first.scanned + first.selected),
  );

  const topFive = data.slice(0, 5);

  return topFive;
};

export const selectTotalShoesBoughtData = (state: RootState) => {
  const data = {
    sold: 0,
    tested: 0,
    sharedVisits: 0,
    purchaseVisits: 0,
    totalVisits: 0,
  };
  const allVisits = state.stats.visits.data;

  allVisits.forEach((visitsPerDay) => {
    if (visitsPerDay.visits[0]) {
      visitsPerDay.visits.forEach((singularVisit) => {
        if (singularVisit) {
          const { sessions, email } = singularVisit;
          const boughtShoes =
            singularVisit.boughtShoes && singularVisit.boughtShoes[0]
              ? singularVisit.boughtShoes
              : Object.values(getSoldShoes(singularVisit));
          if (email) {
            data.tested += sessions ? Object.keys(sessions).length : 0;
          }

          if (
            boughtShoes &&
            boughtShoes[0] &&
            boughtShoes[0].brand !== 'none'
          ) {
            data.purchaseVisits += 1;
          }
        }
      });

      data.totalVisits += visitsPerDay.totals.visits;
      data.sold += visitsPerDay.totals.shoesSold;
      data.sharedVisits += visitsPerDay.totals.visitsShared;
    }
  });

  return data;
};

export const selectShoeModelSalesStats = (state: RootState) => {
  const models: ShoeModelSales[] = [];

  state.stats.visits.data.forEach(({ visits }) =>
    visits.forEach((visit) => {
      const boughtShoes =
        visit.boughtShoes && visit.boughtShoes[0]
          ? visit.boughtShoes
          : Object.values(getSoldShoes(visit));

      return boughtShoes?.forEach(({ model }) => {
        if (model) {
          const index = models.findIndex((s) => s.model === model);

          if (index > -1) {
            models[index].sold += 1;
          } else {
            models.push({
              model,
              sold: 1,
            });
          }
        }
      });
    }),
  );

  return models.sort((a, b) => b.sold - a.sold);
};

export const selectPerformanceGraphData = (state: RootState) => {
  const rawData = state.stats.performance.data;

  const totals = {
    sessions: 0,
  };

  rawData.forEach(({ sessions, sessionCount }) => {
    totals.sessions += sessionCount;
  });

  const derivatives = state.stats.performance.data.reduce(
    (prevDerivatives, currentDay) => {
      const currentDerivatives = currentDay.sessions.reduce(
        sumSessionPerformance,
        defaultDerivatives,
      );

      return deepMergeSum(prevDerivatives, currentDerivatives);
    },
    defaultDerivatives,
  );

  const fnFunc = (key: string, prepend?: string) => {
    const nameParts = key.split(/(?=[A-Z])/);

    if (!nameParts) return prepend ? `${prepend} ${key}` : key;

    const newKey = nameParts.join(' ').toLowerCase();

    if (newKey.indexOf('unknown') > -1) {
      return newKey;
    }

    return prepend ? `${prepend} ${newKey}` : newKey;
  };

  return {
    totals,
    runnerType: extractData(derivatives.runnerType, fnFunc),
    sessionType: extractData(derivatives.sessionType, fnFunc),
    cadence: extractData(derivatives.cadence, (key) => fnFunc(key, 'cadence')),
    runnerEfficiency: extractData(derivatives.runnerEfficiency, fnFunc),
  };
};
