import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { RouteConfig } from '../../../routing';

import { DialogHeading, Icon } from '../../../components';

interface ActiveProps {
  $active?: boolean;
}

const Wrapper = styled(NavLink)<ActiveProps>`
  border-bottom-color: ${({ $active, theme }) =>
    $active ? theme.colors.primary : 'transparent'};
  border-bottom-style: solid;
  border-bottom-width: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  width: 130px;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayOpaque};
    border-bottom-color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 1400px) {
    width: 110px;
  }
`;

const StyledIcon = styled(Icon)<ActiveProps>`
  color: ${({ $active, theme }) =>
    $active ? theme.colors.primary : theme.colors.textTwo};
  margin-bottom: 4px;
`;

interface Props {
  active?: boolean;
  route: RouteConfig;
}

const NavigationItem: FC<Props> = ({ active, route }) => (
  <Wrapper $active={active} to={route.path}>
    {!!route.icon && <StyledIcon $active={active} name={route.icon} />}
    <DialogHeading variant={active ? 'h3' : 'h4'}>{route.label}</DialogHeading>
  </Wrapper>
);

export default NavigationItem;
