import { FC, FormEventHandler, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Button, Dialog, DialogActions } from '../../../../components';
import { Reminder } from '../../../../models/reminderModel';
import { useDispatch, useSelector } from '../../../../store';
import { AuthSelectors, ReminderSelectors } from '../../../../store/selectors';
import { reminderModel } from '../../../../models';
import { ReminderActions } from '../../../../store/actions';
import General from './General';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 700px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
  overflow-y: auto;
  max-height: 70vh;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

interface Props {
  reminder: Reminder | undefined;
  open?: boolean;
  onClose?: () => void;
}

const ReminderDialog: FC<Props> = ({ reminder, open, onClose }) => {
  const dispatch = useDispatch();

  const loading = useSelector(ReminderSelectors.selectReminderFetching);
  const user = useSelector(AuthSelectors.selectUser);

  const [data, setData] = useState(reminderModel.createReminder(reminder));

  const createMode = reminder === undefined;
  const modeType = reminder ? 'updat' : 'creat';

  useEffect(() => {
    if (open) {
      if (reminder) {
        setData(reminder);
      } else {
        setData(reminderModel.createReminder(reminder));
      }
    }
  }, [reminder, open]);

  const handleChange = (value: Partial<Reminder>) => {
    setData((prev) => ({
      ...prev,
      ...value,
    }));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      if (!data) {
        return;
      }

      if (createMode) {
        await dispatch(
          ReminderActions.createReminder({
            data: {
              ...data,
              userId: user?.userId || '',
              userEmail: user?.email || '',
              userFirstName: user?.firstName || '',
              userLastName: user?.lastName || '',
            },
          }),
        )
          .unwrap()
          .then(() => setData(reminderModel.createReminder()));
      } else {
        await dispatch(
          ReminderActions.updateReminder({
            reminderId: data.reminderId,
            data,
          }),
        )
          .unwrap()
          .then(() => setData(reminderModel.createReminder()));
      }
      toast(`Successfully ${modeType}ed reminder!`, {
        type: 'success',
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem ${modeType}ing the reminder. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  return (
    <div>
      <Dialog loading={loading} open={open} title="Reminder" onClose={onClose}>
        <Form onSubmit={handleSubmit}>
          <Content>
            <General reminder={data} onChange={handleChange} />
          </Content>
          <DialogActions>
            <CancelButton
              disabled={loading}
              size="small"
              type="button"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </CancelButton>
            <Button
              color="primary"
              disabled={loading}
              size="small"
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default ReminderDialog;
