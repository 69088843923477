import { FC } from 'react';
import { apply, Store, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import Social from './Social';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Props {
  store?: Store;
}

const Socials: FC<Props> = ({ store }) => {
  const facebookHandle = apply(storeModel.getFacebookHandle, store, '');
  const facebookUrl = apply(storeModel.getFacebookUrl, store, '');
  const instagramHandle = apply(storeModel.getInstagramHandle, store, '');
  const instagramUrl = apply(storeModel.getInstagramUrl, store, '');
  const linkedInHandle = apply(storeModel.getLinkedInHandle, store, '');
  const linkedInUrl = apply(storeModel.getLinkedInUrl, store, '');

  return (
    <Wrapper>
      <Social icon="facebook" handle={facebookHandle} url={facebookUrl} />
      <Social icon="instagram" handle={instagramHandle} url={instagramUrl} />
      <Social icon="linkedin" handle={linkedInHandle} url={linkedInUrl} />
    </Wrapper>
  );
};

export default Socials;
