import { FC } from 'react';
import styled from 'styled-components';

import { Heading, Text } from '../../../components';
import Content from './Content';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const Leads: FC = () => {
  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Lead activations</ContentHeading>
          <Text variant="body2">
            Create custom activation emails that will be sent to customer who
            used your ARIONHUB system.
          </Text>
        </Info>
      </Header>
      <Content />
    </Wrapper>
  );
};

export default Leads;
