import { FC } from 'react';
import styled from 'styled-components';

import TableHeaderCell from './TableHeaderCell';

import { Filter, SortBy, TableColumn } from './types';

const Wrapper = styled.thead`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 4px 4px 0px 0px;
  display: flex;
  margin-bottom: 2px;
  height: 60px;
  padding: 0 32px;
`;

const TableHeaderRow = styled.tr`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 60px;
`;

interface Props {
  columns: TableColumn[];
  filters: Filter[];
  sortBy: SortBy;
  onCellClick: (key: string) => void;
  onFilterChange: (filter: Filter) => void;
}

const TableHeader: FC<Props> = ({
  columns,
  filters,
  sortBy,
  onCellClick,
  onFilterChange,
}) => (
  <Wrapper>
    <TableHeaderRow>
      {columns.map((c) => (
        <TableHeaderCell
          key={c.key}
          filters={filters.find((f) => c.filter && f.key === c.key)}
          sortBy={sortBy}
          column={c}
          onClick={onCellClick}
          onFilterChange={onFilterChange}
        />
      ))}
    </TableHeaderRow>
  </Wrapper>
);

export default TableHeader;
