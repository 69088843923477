import { UserRoles } from '@atogear/arion-utils';
import { getFirebaseFunctionsPipe } from '@atogear/ato-broker';

import {
  CreateInviteResult,
  CreateOwnerInviteResult,
  GetInviteResult,
  HttpsCallableResult,
} from './types';

const getOnboardingService = () => {
  /**
   * Get Store Account Invite.
   *
   * @param id Invite's id.
   * @returns Promise that resolves to Store Account Invite.
   */
  const getInvite = (id: string): Promise<GetInviteResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-getInvitation', {
      invitationId: id,
    });

  /**
   * Create Store Account Invite.
   *
   * @param firstName Store Account's first name.
   * @param lastName Store Account's last name.
   * @param email Store Account's email.
   * @param role Store Account's role.
   * @param storeId Store Account's storeId.
   * @returns Promise that resolves when Store Account Invite has been created successfully.
   */
  const createInvite = (
    firstName: string,
    lastName: string,
    email: string,
    role: UserRoles,
    storeId: string,
  ): Promise<CreateInviteResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-createInvitation', {
      firstName,
      lastName,
      email,
      role,
      storeId,
    });

  /**
   * Create Store Owner Account Invite.
   *
   * @param firstName Store Account's first name.
   * @param lastName Store Account's last name.
   * @param email Store Account's email.
   * @param storeId Store Account's storeId.
   * @returns Promise that resolves when Store Account Invite has been created successfully.
   */
  const createOwnerInvite = (
    firstName: string,
    lastName: string,
    email: string,
    storeId: string,
  ): Promise<CreateOwnerInviteResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-inviteStoreOwner', {
      firstName,
      lastName,
      email,
      storeId,
    });

  /**
   * Send Store Account Invite.
   *
   * @param inviteId Store Account Invite's id.
   * @returns Promise that resolves when Store Account Invite has been sent successfully.
   */
  const sendInvite = (inviteId: string): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-sendAppAccountEmail', {
      appInv: inviteId,
    });

  /**
   * Create Store Account.
   *
   * @param password Store Account's password.
   * @param inviteId Store Account Invite's id.
   * @returns Promise that resolves when Store Account has been created successfully.
   */
  const createAccount = (
    password: string,
    inviteId: string,
  ): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-createUser', {
      password,
      invitationId: inviteId,
    });

  return {
    getInvite,
    createInvite,
    createOwnerInvite,
    sendInvite,
    createAccount,
  };
};

export default getOnboardingService;
