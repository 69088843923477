import { FC } from 'react';

import { getInterval, Lead } from '../../../models/leadModel';

import { formatMonths } from '../../../utils/formatters';

import LeadWrapper from './Lead';

interface Props {
  lead: Lead;
}

const Scheduled: FC<Props> = ({ lead }) => {
  const interval = getInterval(lead);

  return (
    <LeadWrapper lead={lead}>
      {`Last visit ${formatMonths(interval || 0)} before`}
    </LeadWrapper>
  );
};

export default Scheduled;
