import { FC } from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from './Button';

const Wrapper = styled(Button)`
  width: ${({ size }) => (size === 'small' ? '34px' : '48px')};
`;

const IconButton: FC<ButtonProps> = ({ size, ...rest }) => (
  <Wrapper size={size} {...rest} />
);

export default IconButton;
