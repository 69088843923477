import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { apply, groupModel, Store, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../../store';
import { GroupActions } from '../../../../store/actions';
import { GroupSelectors } from '../../../../store/selectors';

import {
  DialogInputLabel,
  DialogSelect,
  Loader,
  Option,
} from '../../../../components';

import { GroupStoreData } from '../../types';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

interface Props {
  store: Store;
  onChange: (value: GroupStoreData) => void;
}

const Group: FC<Props> = ({ store, onChange }) => {
  const dispatch = useDispatch();

  const groups = useSelector(GroupSelectors.selectGroups);
  const fetching = useSelector(GroupSelectors.selectGroupsFetching);

  const options = useMemo(() => {
    return groups
      .map((group) => ({
        key: groupModel.getId(group),
        label: groupModel.getName(group),
        value: groupModel.getId(group),
      }))
      .sort((a, b) => -b.label.localeCompare(a.label));
  }, [groups]);

  const findGroupById = useCallback(
    (id: string) => {
      return groups.find((group) => groupModel.getId(group) === id);
    },
    [groups],
  );

  const initialGroup = useMemo(() => {
    return findGroupById(storeModel.getGroupId(store));
  }, [findGroupById, store]);

  const [group, setGroup] = useState(initialGroup);

  const groupId = useMemo(() => {
    return apply(groupModel.getId, group, '');
  }, [group]);

  useEffect(() => {
    dispatch(GroupActions.getAccessibleGroups());
  }, [dispatch]);

  useEffect(() => {
    setGroup(initialGroup);
  }, [initialGroup]);

  const handleChange = (option: Option | null) => {
    const newGroupFind = option ? findGroupById(option.value) : initialGroup;

    const newGroup = groupModel.create({
      uuid: apply(groupModel.getId, newGroupFind, ''),
      name: apply(groupModel.getName, newGroupFind, ''),
    });

    setGroup(newGroup);

    // We use the setGroup because it also makes sure the memberOf field is set properly for us
    onChange(storeModel.setGroup(newGroup, store));
  };

  return (
    <Wrapper>
      <StyledInputLabel id="group-label" htmlFor="group">
        Group
      </StyledInputLabel>
      <DialogSelect
        id="group"
        disabled={fetching || options.length === 0}
        endAdornment={fetching ? <Loader size="small" /> : null}
        options={options}
        value={groupId}
        onChange={handleChange}
      />
    </Wrapper>
  );
};

export default Group;
