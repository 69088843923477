import { FC, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  padding: 32px 40px;
`;

export interface TileProps extends HTMLAttributes<HTMLSpanElement> {
  children?: ReactNode;
}

const Tile: FC<TileProps> = ({ children, ...rest }) => (
  <Container {...rest}> {children}</Container>
);

export default Tile;
