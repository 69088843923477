import { Group, groupModel, Store, storeModel } from '@atogear/arion-utils';
import {
  getFirebaseFunctionsPipe,
  getFirestorePipe,
} from '@atogear/ato-broker';
import { v4 as uuidv4 } from 'uuid';

import { GetAccessibleGroupsData, HttpsCallableResult } from './types';

const GROUP_COLL_PATH = 'groups';
const STORE_COLL_PATH = 'stores';

const getGroupService = () => {
  /**
   * Get Groups.
   *
   * @returns Promise that resolves to Groups.
   */
  const getGroups = async (): Promise<Group[]> => {
    const groups = await getFirestorePipe().getDocuments(GROUP_COLL_PATH);

    return groups.map(groupModel.serialize);
  };

  /**
   * Get Group.
   *
   * @param groupId Group's id.
   * @returns Promise that resolves to Group.
   */
  const getGroup = async (groupId: string): Promise<Group> => {
    const group = await getFirestorePipe().getDocument(
      GROUP_COLL_PATH,
      groupId,
    );

    if (!group) {
      throw new Error('Group not found!');
    }

    return groupModel.serialize(group);
  };

  /**
   * Create Group.
   *
   * @param data Group's data.
   * @returns Promise that resolves to created Group.
   */
  const createGroup = async (data: Group): Promise<Group> => {
    const uuid = uuidv4();

    const group = await getFirestorePipe().addDocumentWithId(
      GROUP_COLL_PATH,
      uuid,
      groupModel.deserialize(
        groupModel.create({
          uuid,
          name: data.name,
        }),
      ),
    );

    return groupModel.serialize(group);
  };

  /**
   * Update Group.
   *
   * @param groupId Group's id.
   * @param data Group's data.
   * @returns Promise that resolves to updated Group.
   */
  const updateGroup = async (
    groupId: string,
    data: Partial<Group>,
  ): Promise<Group> => {
    const group = await getFirestorePipe().editDocument(
      GROUP_COLL_PATH,
      groupId,
      groupModel.deserialize(data),
    );

    return groupModel.serialize(group);
  };

  /**
   * Delete Group.
   *
   * @param groupId Group's id.
   * @returns Promise that resolves when Group has been successfully deleted.
   */
  const deleteGroup = async (groupId: string): Promise<boolean> => {
    const result = await getFirestorePipe().deleteDocument(
      GROUP_COLL_PATH,
      groupId,
    );

    return result;
  };

  /**
   * Get Group's Stores.
   *
   * @param groupId Group's id.
   * @returns Promise that resolves to Group's Stores.
   */
  const getGroupStores = async (groupId: string): Promise<Store[]> => {
    const stores = await getFirestorePipe().getDocuments(STORE_COLL_PATH, {
      where: {
        fieldName: storeModel.fields.GROUP_ID,
        operator: '==',
        fieldValue: groupId,
      },
    });

    return stores.map(storeModel.serialize);
  };

  const getAccessibleGroups = async (): Promise<Group[]> => {
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-getAccessibleGroups',
    )) as HttpsCallableResult<GetAccessibleGroupsData>;

    return result.data.groups.map(groupModel.serialize);
  };

  return {
    getGroups,
    getGroup,
    createGroup,
    updateGroup,
    deleteGroup,
    getGroupStores,
    getAccessibleGroups,
  };
};

export default getGroupService;
