import Reminders from '../../views/User/Reminders/Reminders';
import Settings from '../../views/User/Settings/Settings';

const userPath = `/user`;

export const userRoutes = {
  settings: {
    key: 'settings',
    label: 'Settings',
    path: `${userPath}/settings`,
    accessibleBy: [],
    component: Settings,
  },
  reminders: {
    key: 'reminders',
    label: 'Reminders',
    path: `${userPath}/reminders`,
    accessibleBy: [],
    component: Reminders,
  },
};
