import { FC } from 'react';
import styled from 'styled-components';

import { useSelector } from '../../../../store';
import { ThemeSelectors } from '../../../../store/selectors';

import { Heading, ImagePreview, Text } from '../../../../components';
import { MediaPreview, MediaTile } from '../Components';

const fullLogoProps = {
  height: 100,
  width: 540,
};

const miniLogoProps = {
  height: 98,
  width: 98,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const FirstMediaPreview = styled(MediaPreview)`
  margin-bottom: 40px;
`;

const StyledImagePreview = styled(ImagePreview)`
  border: 1px solid ${({ theme }) => theme.colors.grayOpaque};
`;

const Logos: FC = () => {
  const lightTheme = useSelector(ThemeSelectors.selectLightTheme);
  const darkTheme = useSelector(ThemeSelectors.selectDarkTheme);

  return (
    <Wrapper>
      <StyledHeading variant="h2">Logos</StyledHeading>
      <Text variant="body2">
        Preview the logos displayed across the ARIONHUB ecosystem.
      </Text>
      <MediaTile>
        <FirstMediaPreview
          lightTheme={lightTheme}
          darkTheme={darkTheme}
          mediaKey="fullLogoURL"
          title="Full logo"
          description="The full logo is used as a major representation of your store, like an app splash screen, Digital flyer footer and a newsletter."
          component={StyledImagePreview}
          componentProps={fullLogoProps}
        />
        <MediaPreview
          lightTheme={lightTheme}
          darkTheme={darkTheme}
          mediaKey="miniLogoURL"
          title="Logo (avatar)"
          description="This mini logo is used in the app Settings page avatar and Digital Flyer footer, where the logo feels too repetitive. Use a short symbol or a logo mark here as a branding element."
          component={StyledImagePreview}
          componentProps={miniLogoProps}
        />
      </MediaTile>
    </Wrapper>
  );
};

export default Logos;
