import { ChangeEventHandler, FC, useEffect, useId, useState } from 'react';
import { apply, isNilOrWhitespace } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ProspectActions } from '../../../store/actions';
import { ProspectSelectors } from '../../../store/selectors';

import {
  Button,
  FlagIcon,
  Heading,
  Icon,
  Input,
  InputLabel,
  Option,
  Select,
  Text,
} from '../../../components';

import { prospectModel } from '../../../models';
import countries from '../../../assets/data/countries.json';

const countryOptions = countries
  .sort((a, b) => -b.name.localeCompare(a.name))
  .map(({ alpha2, name }) => ({
    adornment: <FlagIcon country={alpha2.toLowerCase()} />,
    key: alpha2,
    label: name,
    value: alpha2,
  }));

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 48px;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

interface FieldProps {
  $direction?: 'column' | 'row';
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  margin-bottom: 16px;
`;

interface ColumnProps {
  $left?: boolean;
}

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  ${({ $left }) => (!$left ? 'flex: 1' : 'width: 120px')};
  ${({ $left }) => `margin-${$left ? 'right' : 'left'}: 4px`};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 60px 48px 60px;
`;

const PrimaryButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SkipButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.text};
  color: ${({ theme }) => theme.colors.background};
  margin-right: 16px;
`;

const PrevIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 8px;
`;

interface Props {
  onPrev: () => void;
  onNext: () => void;
}

const Address: FC<Props> = ({ onPrev, onNext }) => {
  const dispatch = useDispatch();

  const prospect = useSelector(ProspectSelectors.selectProspectPreview);

  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');

  useEffect(() => {
    setCountry(apply(prospectModel.getCountryCode, prospect, ''));
    setZipCode(apply(prospectModel.getZipCode, prospect, ''));
    setCity(apply(prospectModel.getCity, prospect, ''));
    setStreet(apply(prospectModel.getStreet, prospect, ''));
  }, [prospect]);

  const handleCountryChange = (option: Option | null) =>
    setCountry(option?.value || country);

  const handleZipCodeChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setZipCode(e.target.value);

  const handleCityChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setCity(e.target.value);

  const handleStreetChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setStreet(e.target.value);

  const handleSubmit = () =>
    dispatch(
      ProspectActions.prospectPreviewUpdated({
        country,
        zipCode,
        city,
        address: street,
      }),
    );

  const handlePrevClick = () => {
    handleSubmit();

    onPrev();
  };

  const handleNextClick = () => {
    handleSubmit();

    onNext();
  };

  const handleValidate = (value?: string) => !isNilOrWhitespace(value);

  const id = useId();
  const countryId = `${id}-country`;
  const zipCodeId = `${id}-zip-code`;
  const cityId = `${id}-city`;
  const streetId = `${id}-street`;

  const nextDisabled = ![country, zipCode, city, street].every(handleValidate);

  return (
    <Wrapper>
      <Content>
        <Form>
          <StyledHeading variant="h1">
            What is the prospect's address?
          </StyledHeading>
          <StyledText variant="body2">
            Enter the prospect address or update it later.
          </StyledText>
          <Field>
            <StyledInputLabel htmlFor={countryId} variant="h3">
              Country
            </StyledInputLabel>
            <Select
              id={countryId}
              options={countryOptions}
              startAdornment={<FlagIcon country={country} />}
              value={country}
              onChange={handleCountryChange}
            />
          </Field>
          <Field $direction="row">
            <Column $left>
              <StyledInputLabel htmlFor={zipCodeId}>ZIP code</StyledInputLabel>
              <Input
                id={zipCodeId}
                placeholder="ZIP code"
                required
                validate={handleValidate}
                value={zipCode}
                onChange={handleZipCodeChange}
              />
            </Column>
            <Column>
              <StyledInputLabel htmlFor={cityId}>City</StyledInputLabel>
              <Input
                id={cityId}
                placeholder="City"
                required
                value={city}
                validate={handleValidate}
                onChange={handleCityChange}
              />
            </Column>
          </Field>
          <Field>
            <StyledInputLabel htmlFor={streetId}>
              Street and house number
            </StyledInputLabel>
            <Input
              id={streetId}
              placeholder="Street and house number"
              required
              value={street}
              validate={handleValidate}
              onChange={handleStreetChange}
            />
          </Field>
        </Form>
      </Content>
      <Buttons>
        <Button variant="outlined" onClick={handlePrevClick}>
          <PrevIcon name="chevron-back" />
          Previous
        </Button>
        <PrimaryButtons>
          <SkipButton onClick={onNext}>Skip</SkipButton>
          <Button disabled={nextDisabled} onClick={handleNextClick}>
            Next
          </Button>
        </PrimaryButtons>
      </Buttons>
    </Wrapper>
  );
};

export default Address;
