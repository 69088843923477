import { createReducer, SerializedError } from '@reduxjs/toolkit';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import {
  prospectStorePreviewReset,
  prospectStorePreviewUpdated,
  reset,
} from './actions';
import {
  createProspectStore,
  deleteProspectStore,
  getProspectStore,
  getProspectStores,
  updateProspectStore,
} from './thunks';

import { prospectStoreModel } from '../../models';
import { ProspectStore } from '../../models/prospectStoreModel';

interface State {
  prospectStores: {
    data: ProspectStore[];
    fetching: boolean;
    error?: SerializedError;
  };
  prospectStore: {
    data?: ProspectStore;
    preview?: ProspectStore;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  prospectStores: {
    data: [],
    fetching: false,
    error: undefined,
  },
  prospectStore: {
    data: undefined,
    preview: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    //Get prospect stores
    .addCase(getProspectStores.pending, setFetchingState('prospectStores'))
    .addCase(getProspectStores.fulfilled, (state, { payload }) => {
      state.prospectStores.data = payload;
      setFulfilledState('prospectStores')(state);
    })
    .addCase(getProspectStores.rejected, setRejectedState('prospectStores'))
    // Get Prospect Store
    .addCase(getProspectStore.pending, setFetchingState('prospectStore'))
    .addCase(getProspectStore.fulfilled, (state, { payload }) => {
      state.prospectStore.data = payload;
      state.prospectStore.preview = payload;
      setFulfilledState('prospectStore')(state);
    })
    .addCase(getProspectStore.rejected, setRejectedState('prospectStore'))
    // Create Prospect Store
    .addCase(createProspectStore.pending, setUpdatingState('prospectStore'))
    .addCase(createProspectStore.fulfilled, (state, { payload }) => {
      state.prospectStore.preview = undefined;
      state.prospectStores.data.push(payload);
      setFulfilledState('prospectStore')(state);
    })
    .addCase(createProspectStore.rejected, setRejectedState('prospectStore'))
    // Update Prospect Store
    .addCase(updateProspectStore.pending, setFetchingState('prospectStore'))
    .addCase(updateProspectStore.fulfilled, (state, { payload }) => {
      state.prospectStore.data = payload;
      state.prospectStore.preview = payload;

      const index = state.prospectStores.data.findIndex(
        (prospectStore) => payload.storeId === prospectStore.storeId,
      );

      if (index > -1) {
        state.prospectStores.data[index] = payload;
      }

      setFulfilledState('prospectStore')(state);
    })
    // Delete Store Prospect
    .addCase(deleteProspectStore.pending, setUpdatingState('prospectStore'))
    .addCase(deleteProspectStore.fulfilled, (state, { payload }) => {
      state.prospectStore.data = undefined;
      state.prospectStore.preview = undefined;

      const index = state.prospectStores.data.findIndex(
        (prospectStore) => payload === prospectStore.storeId,
      );

      if (index > -1) {
        state.prospectStores.data.splice(index, 1);
      }
      setFulfilledState('prospectStore')(state);
    })
    .addCase(deleteProspectStore.rejected, setRejectedState('prospectStore'))
    // Local
    .addCase(prospectStorePreviewUpdated, ({ prospectStore }, { payload }) => {
      prospectStore.preview = prospectStoreModel.create({
        ...prospectStore.preview,
        ...payload,
      });
    })
    .addCase(prospectStorePreviewReset, ({ prospectStore }) => {
      prospectStore.preview = undefined;
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
