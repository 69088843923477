import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  apply,
  BillingCycles,
  formatDate,
  Store,
  storeModel,
  userModel,
} from '@atogear/arion-utils';
import { addDays } from 'date-fns';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreActions } from '../../../store/actions';
import {
  AuthSelectors,
  SettingsSelectors,
  StoreSelectors,
} from '../../../store/selectors';

import { getBaseMonthlySubPrice } from '../../../models/appSettingsModel';

import { appRoutes, getPath } from '../../../routing';

import { formatYears } from '../../../utils/formatters';

import {
  Button,
  Collapsible,
  Icon,
  InputLabel,
  Heading,
  Text,
} from '../../../components';

import { StoreRouteParams } from '../types';

const Wrapper = styled(Collapsible)`
  margin-bottom: 56px;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

interface RowProps {
  $first?: boolean;
  $last?: boolean;
}

const Row = styled(Header)<RowProps>`
  align-items: center;
  justify-content: ${({ $last }) => ($last ? 'flex-end' : 'flex-start')};
  min-height: 56px;
  margin-top: ${({ $first }) => ($first ? '16px' : 0)};
  margin-right: 32px;
`;

const SubType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-right: 24px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const Label = styled(Heading)`
  display: flex;
  flex: 1;
  margin-right: 18px;
`;

const Value = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
`;

const StyledText = styled(Text)`
  text-transform: capitalize;
`;

const UploadButton = styled(InputLabel)`
  ${({ theme }) => theme.typography.h3};
  ${({ theme }) => theme.components.buttons.secondary};
  border: ${({ theme }) => `1px solid ${theme.colors.grayOpaque}`};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: 0 12px;
  text-overflow: ellipsis;
  /* Both of the following are required for text-overflow */
  overflow: hidden;
  white-space: nowrap;
  transition: all ease 0.2s;
  margin-right: 8px;
`;

const UploadIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
`;

interface LinkProps {
  $disabled?: boolean;
}

const DownloadLink = styled(Text)<LinkProps>`
  ${({ theme }) => theme.typography.a};
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
`;

interface Props {
  open?: boolean;
}

const PaymentInfo: FC<Props> = ({ open }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const user = useSelector(AuthSelectors.selectUser);
  const appSettings = useSelector(SettingsSelectors.selectAppSettings);
  const store = useSelector(StoreSelectors.selectStore);

  const { storeId } = useParams<StoreRouteParams>();

  const isAdmin = apply(userModel.isAdmin, user, false);

  const { billingCycle, isInactive } = useMemo(
    () => ({
      billingCycle: apply(
        storeModel.getBillingCycle,
        store,
        BillingCycles.MONTHLY,
      ),
      isInactive: apply(
        storeModel.isInactive,
        store,
        storeModel.isInactive(storeModel.defaults),
      ),
    }),
    [store],
  );

  const subPrice = useMemo(() => {
    if (!store) {
      return undefined;
    }

    return storeModel.getFormattedSubscriptionPrice(
      store,
      getBaseMonthlySubPrice(appSettings),
    );
  }, [appSettings, store]);

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    try {
      if (!storeId) {
        throw new Error('Invalid Store');
      }

      if (!e.target.files) {
        throw new Error('No file selected');
      }

      const file = e.target.files[0];

      if (!file || file.type !== 'application/pdf') {
        throw new Error('Selected file is not a valid agreement!');
      }

      const buffer = await file.arrayBuffer();

      await dispatch(
        StoreActions.uploadAgreement({
          storeId,
          data: buffer,
        }),
      ).unwrap();
    } catch (error) {
      toast((error as Error)?.message || 'Failed to upload agreement!', {
        type: 'error',
      });
    }
  };

  const handleDownloadClick = useCallback(
    (agreementPath: string) => async () => {
      try {
        if (!storeId) {
          throw new Error('Invalid Store');
        }
        const downloadUrl = await dispatch(
          StoreActions.downloadAgreement({
            storeId,
            agreementPath,
          }),
        ).unwrap();

        const link = document.createElement('a');

        link.href = downloadUrl;
        link.download = `ARIONHUB-'Agreement'-${Date.now()}.pdf`;

        link.click();
        link.remove();
      } catch (error) {
        toast((error as Error)?.message || 'Failed to download agreement!', {
          type: 'error',
        });
      }
    },
    [dispatch, storeId],
  );

  const handleSignAgreement = () => {
    if (!storeId) return;

    history.push(
      getPath(appRoutes.agreement.path, {
        storeId: storeId,
      }),
    );
  };

  const billingInfo = useMemo(
    () => [
      {
        key: storeModel.fields.BILLING_PAYMENT_TERM,
        label: 'Payment term',
        getValue: (store?: Store) => {
          const subStartDate = apply(
            storeModel.getSubscriptionStartDate,
            store,
            Date.now(),
          );
          const paymentTerm = apply(
            storeModel.getBillingPaymentTerm,
            store,
            30,
          );

          return `Within ${paymentTerm} days (${formatDate(
            addDays(subStartDate, paymentTerm),
          )})`;
        },
      },
      {
        key: 'payment-per-one',
        label: 'Regular payments (First)',
        getValue: () => subPrice?.perCycle || '',
      },
      {
        key: 'payment-total',
        getLabel: (store?: Store) => {
          const subPeriod = apply(storeModel.getSubscriptionPeriod, store, 2);

          return `Total (${formatYears(subPeriod)})`;
        },
        getValue: () => subPrice?.total || '',
      },
      {
        key: 'billing-person',
        label: 'Billing person',
        getValue: (store?: Store) => {
          const billingName = apply(storeModel.getBillingName, store, '');
          const billingEmail = apply(storeModel.getBillingEmail, store, '');

          return `${billingName}, ${billingEmail}`;
        },
      },
      {
        key: 'documents',
        label: 'Documents',
        renderValue: (store?: Store) => {
          const agreementPath = apply(storeModel.getAgreementPath, store, '');

          return !!agreementPath ? (
            <DownloadLink onClick={handleDownloadClick(agreementPath)}>
              <StyledIcon name="attachment" />
              Agreement PDF
            </DownloadLink>
          ) : (
            <Text variant="body2">None uploaded yet</Text>
          );
        },
      },
    ],
    [handleDownloadClick, subPrice?.perCycle, subPrice?.total],
  );

  return (
    <Wrapper open={open}>
      <Header>
        <SubType>
          <StyledIcon name="star" />
          <Text variant="body2">Custom payment plan</Text>
        </SubType>
        <Label variant="h3">Billing cycle</Label>
        <Value>
          <StyledText variant="body2">{billingCycle}</StyledText>
        </Value>
      </Header>
      {billingInfo.map(
        ({ key, getLabel, label, getValue, renderValue }, index) => (
          <Row key={key} $first={index === 0}>
            <SubType />
            <Label variant="h3">{getLabel ? getLabel(store) : label}</Label>
            <Value>
              {renderValue ? (
                renderValue(store)
              ) : (
                <Text variant="body2">{getValue(store)}</Text>
              )}
            </Value>
          </Row>
        ),
      )}
      {(isInactive || isAdmin) && (
        <Row $last>
          <UploadButton htmlFor="agreement-upload-btn">
            <UploadIcon name="upload-doc" />
            Upload signed agreement
          </UploadButton>
          <input
            id="agreement-upload-btn"
            accept="application/pdf"
            hidden
            type="file"
            onChange={handleFileChange}
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={handleSignAgreement}
          >
            Sign agreement
          </Button>
        </Row>
      )}
    </Wrapper>
  );
};

export default PaymentInfo;
