import { FC } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../../components';
import { Theme } from '../../../../theme/types';
import ButtonsPreview from './Buttons/ButtonsPreview';
import ColorsPreview from './Colors/ColorsPreview';
import ComponentsPreview from './Components/ComponentsPreview';
import FontsPreview from './Fonts/FontsPreview';
import ThemePreview from '../../../../components/ThemePreview/ThemePreview';
import { useSelector } from '../../../../store';
import { StoreSelectors } from '../../../../store/selectors';

const Wrapper = styled.div`
  margin: 32px 0px;
  padding-left: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

const StyledThemePreview = styled(ThemePreview)`
  width: 100%;
  height: 500px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 32px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 38%;
  margin-right: 24px;

  @media (max-width: 1600px) {
    width: 98%;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 57%;

  @media (max-width: 1600px) {
    width: 98%;
    margin-top: 32px;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 0px;
  border-top: 2px solid ${(props) => props.theme.colors.grayOpaque};
  border-radius: 5px;
  margin: 32px 0px;
`;

interface Props {
  theme: Theme;
}

const ThemeTileBody: FC<Props> = ({ theme }) => {
  const store = useSelector(StoreSelectors.selectStore);

  return (
    <Wrapper>
      <StyledHeading variant="h2">{theme.themeName}</StyledHeading>
      <Content>
        <Left>
          <ColorsPreview colors={theme.colors} />
          <Separator />
          <FontsPreview
            typography={theme.typography}
            fonts={theme.fonts}
            colors={theme.colors}
          />
          <Separator />
          <ComponentsPreview theme={theme} />
          <Separator />
          <ButtonsPreview theme={theme} />
        </Left>
        <Right>
          <StyledHeading variant="h3">Theme Preview</StyledHeading>
          <StyledThemePreview previewTheme={theme} store={store} />
        </Right>
      </Content>
    </Wrapper>
  );
};

export default ThemeTileBody;
