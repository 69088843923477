import {
  getFirebaseFunctionsPipe,
  getFirebaseStoragePipe,
} from '@atogear/ato-broker';

import { DownloadDocumentResult, HttpsCallableResult } from './types';

const getSubscriptionService = () => {
  /**
   * Cancel Store Subscription.
   *
   * @param storeId Store's id.
   * @param withWooCommerce True to use WooCommerce; false if not.
   * @returns Promise that resolves if Store Subscription has been cancelled successfully.
   */
  const cancelSubscription = (
    storeId: string,
    withWooCommerce: boolean,
  ): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-cancelSubscription', {
      storeId,
      withWooCommerce,
    });

  /**
   * Reactivate Store Subscription.
   *
   * @param storeId Store's id.
   * @param withWooCommerce True to use WooCommerce; false if not.
   * @returns Promise that resolves if Store Subscription has been reactivated successfully.
   */
  const reactivateSubscription = (
    storeId: string,
    withWooCommerce: boolean,
  ): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction(
      'admin-reactivateSubscription',
      {
        storeId,
        withWooCommerce,
      },
    );

  /**
   * Extend Store Trial.
   *
   * @param storeId Store's id.
   * @param days Trial extension in days.
   * @param withWooCommerce True to use WooCommerce; false if not.
   * @returns Promise that resolves if Store Trial has been extended successfully.
   */
  const extendTrial = (
    storeId: string,
    days: number,
    withWooCommerce: boolean,
  ): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-extendTrial', {
      storeId,
      days,
      withWooCommerce,
    });

  /**
   * Upload Store Agreement.
   *
   * @param parentId parent's id.
   * @param data File to upload.
   * @param isProspect Indicator of parent type.
   * @returns Promise that resolves when Agreement has been uploaded successfully.
   */
  const uploadAgreement = async (
    parentId: string,
    data: Blob | Uint8Array | ArrayBuffer,
    isProspect?: boolean,
  ): Promise<string> => {
    const agreementPath = isProspect
      ? `prospects/${parentId}/agreements/agreement-${Date.now()}.pdf`
      : `stores/${parentId}/agreements/agreement-${Date.now()}.pdf`;

    await getFirebaseStoragePipe().upload(agreementPath, data);

    return agreementPath;
  };

  /**
   * Download Agreement.
   *
   * @param parentId parent's id.
   * @param agreementPath Path to agreement in storage.
   * @param isProspect Indicator of parent type.
   * @returns Promise that resolves when Agreement has been downloaded successfully.
   */
  const downloadAgreement = (
    parentId: string,
    agreementPath: string,
  ): Promise<DownloadDocumentResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-downloadAgreement', {
      storeId: parentId,
      agreementPath,
    });

  const sendAgreementToContacts = (
    parentId: string,
    contacts: object[],
  ): Promise<HttpsCallableResult> =>
    getFirebaseFunctionsPipe().callCloudFunction(
      'admin-sendAgreementToContacts',
      {
        storeId: parentId,
        contacts,
      },
    );

  const downloadAgreementProspect = (
    parentId: string,
    agreementPath: string,
  ): Promise<DownloadDocumentResult> =>
    getFirebaseFunctionsPipe().callCloudFunction(
      'admin-downloadAgreementProspect',
      {
        prospectId: parentId,
        agreementPath,
      },
    );

  const sendAgreementToContactsProspect = async (
    parentId: string,
    contacts: object[],
  ): Promise<HttpsCallableResult> => {
    const response = await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-sendAgreementToContactsProspect',
      {
        prospectId: parentId,
        contacts,
      },
    );

    if (!response.data.success) {
      throw new Error('Failed to send agreement!');
    }

    return response;
  };

  return {
    cancelSubscription,
    reactivateSubscription,
    extendTrial,
    uploadAgreement,
    downloadAgreement,
    sendAgreementToContacts,
    downloadAgreementProspect,
    sendAgreementToContactsProspect,
  };
};

export default getSubscriptionService;
