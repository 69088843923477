import { FC, FormEventHandler, useId, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apply, formatDate, Store, storeModel } from '@atogear/arion-utils';
import { addYears, endOfDay } from 'date-fns';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreActions } from '../../../store/actions';
import { SettingsSelectors, StoreSelectors } from '../../../store/selectors';

import { getPath, notSignedStoreRoutes } from '../../../routing';

import { getMaxSubDuration } from '../../../models/appSettingsModel';

import { formatYears } from '../../../utils/formatters';

import {
  Button,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  Option,
} from '../../../components';

import { StoreRouteParams } from '../types';

const StyledDialog = styled(Dialog)`
  width: clamp(500px, 35%, 50%);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

interface GroupProps {
  $row?: boolean;
}

const Group = styled.div<GroupProps>`
  display: flex;
  flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
  justify-content: ${({ $row }) => ($row ? 'flex-start' : 'center')};
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

interface FieldProps {
  $right?: boolean;
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ $right }) => `margin-${$right ? 'left' : 'right'}: 4px;`}
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
`;

interface Props {
  open?: boolean;
  store?: Store;
  onClose?: () => void;
}

const UpdateActiveSubscriptionDialog: FC<Props> = ({
  store,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();

  const appSettings = useSelector(SettingsSelectors.selectAppSettings);
  const loading = useSelector(StoreSelectors.selectStoreUpdating);

  const { storeId } = useParams<StoreRouteParams>();

  const history = useHistory();

  const initialValues = useMemo(
    () => ({
      modules: apply(
        storeModel.getModules,
        store,
        storeModel.getModules(storeModel.defaults),
      ),
      subStartDate: apply(
        storeModel.getSubscriptionStartDate,
        store,
        Date.now(),
      ),
      subDuration: apply(storeModel.getSubscriptionPeriod, store, 2),
      userSlots: apply(
        storeModel.getUserSlots,
        store,
        storeModel.getUserSlots(storeModel.defaults),
      ),
    }),
    [store],
  );

  const [subDuration, setSubDuration] = useState(1);

  const subEndDate = useMemo(() => {
    return formatDate(
      endOfDay(
        addYears(
          initialValues.subStartDate,
          subDuration + initialValues.subDuration,
        ),
      ),
    );
  }, [subDuration, initialValues]);

  const maxSubDuration = getMaxSubDuration(appSettings);

  const durationOptions = useMemo(() => {
    return [...new Array(maxSubDuration)].map((_, i) => ({
      key: `${i + 1}-year`,
      label: formatYears(i + 1),
      value: i + 1,
    }));
  }, [maxSubDuration]);

  const handleUpdate = async () => {
    try {
      if (!storeId) {
        return;
      }

      await dispatch(
        StoreActions.updateStore({
          storeId,
          data: {
            subscriptionCreatedAt: Date.now(),
            subscriptionEndDate: endOfDay(
              addYears(
                initialValues.subStartDate,
                subDuration + initialValues.subDuration,
              ),
            ).getTime(),
            subscriptionPeriod: subDuration + initialValues.subDuration,
          },
        }),
      ).unwrap();

      toast('Successfully updated store Subscription!', {
        type: 'success',
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem updating the store subscription settings. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      if (!store) {
        return;
      }

      e.preventDefault();

      await handleUpdate();

      history.push(
        getPath(notSignedStoreRoutes.payments.path, {
          storeId: storeModel.getId(store),
        }),
      );
    } catch {}
  };

  const handleDurationChange = (option: Option | null) =>
    setSubDuration(option?.value || 1);

  const id = useId();
  const durationId = `${id}-duration`;
  const startDateId = `${id}-start-date`;
  const endDateId = `${id}-end-date`;

  return (
    <StyledDialog
      open={open}
      loading={loading}
      title="Subscription settings"
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <Group>
            <StyledInputLabel htmlFor={durationId}>Extend by</StyledInputLabel>
            <DialogSelect
              id={durationId}
              value={subDuration}
              options={durationOptions}
              onChange={handleDurationChange}
            />
          </Group>
          <Group $row>
            <Field>
              <StyledInputLabel htmlFor={startDateId}>
                Start date
              </StyledInputLabel>
              <DialogInput
                id={startDateId}
                disabled
                value={formatDate(initialValues.subStartDate)}
              />
            </Field>
            <Field $right>
              <StyledInputLabel htmlFor={endDateId}>
                New end date
              </StyledInputLabel>
              <DialogInput id={endDateId} disabled value={subEndDate} />
            </Field>
          </Group>
        </Content>
        <DialogActions>
          <StyledActions>
            <Button
              size="small"
              type="button"
              variant={'contained'}
              onClick={handleUpdate}
            >
              Save
            </Button>
          </StyledActions>
        </DialogActions>
      </Form>
    </StyledDialog>
  );
};

export default UpdateActiveSubscriptionDialog;
