import { User, userModel, UserRoles } from '@atogear/arion-utils';
import {
  getFirebaseFunctionsPipe,
  getFirebaseStoragePipe,
  getFirestorePipe,
} from '@atogear/ato-broker';
import { QueryOptions } from '@atogear/ato-broker/firestore';

import { prospectModel } from '../models';
import { Contact } from '../models/contactModel';

import {
  CreateProspectResult,
  DownloadDocumentResult,
  TransformProspectResult,
  UpdateProspectResult,
} from './types';
import { CreateProspectData, Prospect } from '../models/prospectModel';

const COLL_PATH = 'prospects';

const getQueryOptions = (user: User): QueryOptions => {
  switch (userModel.getRole(user)) {
    case UserRoles.EXTERNAL_SALES:
      return {
        where: {
          fieldName: prospectModel.fields.SALES_ID,
          operator: '==',
          fieldValue: userModel.getId(user),
        },
      };
    default:
      return {};
  }
};

const getProspectService = () => {
  /**
   * Get multiple Prospects.
   *
   * @param queryOptions Query options.
   * @returns Promise that resolves to Prospects.
   */
  const getProspects = async (
    queryOptions?: QueryOptions,
  ): Promise<Prospect[]> => {
    const prospects = await getFirestorePipe().getDocuments(
      COLL_PATH,
      queryOptions,
    );

    return prospects.map(prospectModel.serialize);
  };

  /**
   * Get User's Prospects.
   *
   * @param user User.
   * @returns Promise that resolves to User's Prospects.
   */
  const getUserProspects = (user: User): Promise<Prospect[]> =>
    getProspects(getQueryOptions(user));

  /**
   * Get Prospect.
   *
   * @param prospectId Prospects's id.
   * @returns Promise that resolves to Prospect.
   */
  const getProspect = async (prospectId: string): Promise<Prospect> => {
    const prospect = await getFirestorePipe().getDocument(
      COLL_PATH,
      prospectId,
    );

    if (!prospect) {
      throw new Error('Prospect not found!');
    }

    return prospectModel.serialize(prospect);
  };

  /**
   * Create Prospect.
   *
   * @param prospect Prospect's data.
   * @param contacts Prospect's contacts.
   * @returns Promise that resolves to created Prospect's id.
   */
  const createProspect = async (
    prospect: CreateProspectData,
    contacts: Contact[],
  ): Promise<string> => {
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-createProspect',
      {
        prospect,
        contacts,
      },
    )) as CreateProspectResult;

    if (!result.data.success) {
      throw result.data.error;
    }

    return result.data.prospectId;
  };

  /**
   * Update Prospect document directly.
   *
   * @param prospectId Prospect's id.
   * @param data Prospect's data.
   * @returns Promise that resolves to updated Prospect.
   */
  const updateProspectDoc = async (
    prospectId: string,
    data: Partial<Prospect>,
  ): Promise<Prospect> => {
    const prospect = await getFirestorePipe().editDocument(
      COLL_PATH,
      prospectId,
      prospectModel.deserialize(data),
    );

    return prospectModel.serialize(prospect);
  };

  /**
   * Update Prospect document using cloud function.
   *
   * @param prospectId Prospect's id.
   * @param data Prospect's data.
   * @returns Promise that resolves to updated Prospect.
   */
  const updateProspectFn = async (
    prospectId: string,
    data: Partial<Prospect>,
  ): Promise<Prospect> => {
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-updateProspect',
      {
        prospectId,
        prospect: prospectModel.deserialize(data),
      },
    )) as UpdateProspectResult;

    if (!result.data.success) {
      throw result.data.error;
    }

    return prospectModel.serialize(result.data.prospect);
  };

  /**
   * Upgrade Prospect.
   *
   * @param prospectId Prospect's id.
   * @returns Promise that resolves when Prospect has been upgraded successfully.
   */
  const transformProspect = async (prospectId: string): Promise<Prospect> => {
    const result = (await getFirebaseFunctionsPipe().callCloudFunction(
      'admin-transformProspectToCustomer',
      {
        prospectId,
      },
    )) as TransformProspectResult;

    if (!result.data.success) {
      throw result.data.error;
    }

    return prospectModel.serialize(result.data.updatedProspect);
  };

  /**
   * Delete Prospect.
   *
   * @param prospectId Prospect's id.
   * @returns Promise that resolves to true when Prospect has been successfully deleted.
   */
  const deleteProspect = async (prospectId: string): Promise<boolean> => {
    const result = await getFirestorePipe().deleteDocument(
      COLL_PATH,
      prospectId,
    );

    return result;
  };

  /**
   * Upload Quote.
   *
   * @param parentId parent's id.
   * @param data File to upload.
   * @returns Promise that resolves when quote has been uploaded successfully.
   */
  const uploadQuote = async (
    parentId: string,
    data: Blob | Uint8Array | ArrayBuffer,
  ): Promise<string> => {
    const quotePath = `prospects/${parentId}/quotes/quote-${Date.now()}.pdf`;

    await getFirebaseStoragePipe().upload(quotePath, data);

    return quotePath;
  };

  /**
   * Download Quote.
   *
   * @param parentId parent's id.
   * @param documentPath Path to quote in storage.
   * @returns Promise that resolves when quote has been downloaded successfully.
   */
  const downloadQuote = (
    parentId: string,
    documentPath: string,
  ): Promise<DownloadDocumentResult> =>
    getFirebaseFunctionsPipe().callCloudFunction('admin-downloadQuote', {
      prospectId: parentId,
      quotePath: documentPath,
    });

  return {
    getProspects,
    getUserProspects,
    getProspect,
    createProspect,
    updateProspectDoc,
    updateProspectFn,
    transformProspect,
    deleteProspect,
    uploadQuote,
    downloadQuote,
  };
};

export default getProspectService;
