import {
  ChangeEventHandler,
  FC,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { isNilOrWhitespace, Store, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import {
  DialogHeading,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  FlagIcon,
  Option,
} from '../../../../components';

import { AddressStoreData } from '../../types';

import countries from '../../../../assets/data/countries.json';

const countryOptions = countries
  .sort((a, b) => -b.name.localeCompare(a.name))
  .map(({ alpha2, name }) => ({
    adornment: <FlagIcon country={alpha2.toLowerCase()} />,
    key: alpha2,
    label: name,
    value: alpha2,
  }));

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 32px;
`;

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 24px;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

interface FieldProps {
  $direction?: 'column' | 'row';
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  margin-bottom: 16px;
`;

interface ColumnProps {
  $left?: boolean;
}

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  ${({ $left }) => (!$left ? 'flex: 1' : 'width: 120px')};
  ${({ $left }) => `margin-${$left ? 'right' : 'left'}: 4px`};
`;

interface Props {
  store: Store;
  onChange: (value: AddressStoreData) => void;
}

const Address: FC<Props> = ({ store, onChange }) => {
  const initialValues = useMemo(
    () => ({
      country: storeModel.getCountryCode(store) || '',
      zipCode: storeModel.getZipCode(store) || '',
      city: storeModel.getCity(store) || '',
      street: storeModel.getStreet(store) || '',
    }),
    [store],
  );

  const [country, setCountry] = useState(initialValues.country);
  const [zipCode, setZipCode] = useState(initialValues.zipCode);
  const [city, setCity] = useState(initialValues.city);
  const [street, setStreet] = useState(initialValues.street);

  useEffect(() => {
    setCountry(initialValues.country);
    setZipCode(initialValues.zipCode);
    setCity(initialValues.city);
    setStreet(initialValues.street);
  }, [
    initialValues.city,
    initialValues.country,
    initialValues.street,
    initialValues.zipCode,
  ]);

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleCountryChange = (option: Option | null) => {
    const value = option?.value || country;

    setCountry(value);

    // NOTE: Not debounced since it is selected from dropdown
    onChange({
      country: value,
    });
  };

  const handleZipCodeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setZipCode(value);

    debouncedOnChange({
      zipCode: value,
    });
  };

  const handleCityChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setCity(value);

    debouncedOnChange({
      city: value,
    });
  };

  const handleStreetChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setStreet(value);

    debouncedOnChange({
      address: value,
    });
  };

  // TODO: Validate on more than just empty strings
  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const id = useId();
  const countryId = `${id}-country`;
  const zipCodeId = `${id}-zip-code`;
  const cityId = `${id}-city`;
  const streetId = `${id}-street`;

  return (
    <Wrapper>
      <StyledHeading variant="h3">Address</StyledHeading>
      <Field>
        <StyledInputLabel htmlFor={countryId}>Country</StyledInputLabel>
        <DialogSelect
          id={countryId}
          options={countryOptions}
          startAdornment={<FlagIcon country={country} />}
          value={country}
          onChange={handleCountryChange}
        />
      </Field>
      <Field $direction="row">
        <Column $left>
          <StyledInputLabel htmlFor={zipCodeId}>ZIP code</StyledInputLabel>
          <DialogInput
            id={zipCodeId}
            placeholder="ZIP code"
            value={zipCode}
            validate={handleValidate}
            onChange={handleZipCodeChange}
          />
        </Column>
        <Column>
          <StyledInputLabel htmlFor={cityId}>City</StyledInputLabel>
          <DialogInput
            id={cityId}
            placeholder="City"
            value={city}
            validate={handleValidate}
            onChange={handleCityChange}
          />
        </Column>
      </Field>
      <StyledInputLabel htmlFor={streetId}>
        Street and house number
      </StyledInputLabel>
      <DialogInput
        id={streetId}
        placeholder="Street and house number"
        value={street}
        validate={handleValidate}
        onChange={handleStreetChange}
      />
    </Wrapper>
  );
};

export default Address;
