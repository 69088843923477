import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 400px;
  height: 48px;
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
`;

interface ColorProps {
  $background?: string;
  $fontFamily?: string;
  $textColor?: string;
}

const Font = styled.div<ColorProps>`
  display: flex;
  width: 80px;
  height: 48px;
  border: 1px solid ${(props) => props.theme.colors.grayOpaque};
  background-color: ${(props) => props.$background};
  font-family: ${(props) => props.$fontFamily};
  color: ${(props) => props.$textColor};
  font-size: 24px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

interface FontProps {
  $bold?: boolean;
}

const FontText = styled.div<FontProps>`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: '12px';
  font-weight: ${(props) => (props.$bold ? 700 : 400)};
  margin-right: 4px;
`;

interface Props {
  name: string;
  fontFamily?: string;
  textColor: string;
  background?: string;
}

const FontTile: FC<Props> = ({ name, fontFamily, textColor, background }) => {
  return (
    <Wrapper>
      <Font
        $fontFamily={fontFamily}
        $background={background}
        $textColor={textColor}
      >
        Aa
      </Font>
      <TextWrapper>
        <FontText $bold>{name}</FontText>
        <FontText>{fontFamily}</FontText>
      </TextWrapper>
    </Wrapper>
  );
};

export default FontTile;
