import { createReducer, SerializedError } from '@reduxjs/toolkit';

import { StaffData } from '../../models/apiEmployeeInfoModel';
import { ApiGroup } from '../../models/apiGroupModel';

import {
  PerformanceStats,
  SessionStats,
  StatFilters,
  VisitStats,
} from '../../types/stats';

import { timeFrameToDateRange } from '../../utils/dateUtils';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
} from '../utils';

import { filtersUpdated, reset } from './actions';
import {
  getPerformanceStats,
  getSessionStats,
  getStaffStats,
  getStores,
  getVisitStats,
} from './thunks';

interface State {
  stores: {
    data: ApiGroup[];
    fetching: boolean;
    error?: SerializedError;
  };
  sessions: {
    data: SessionStats[];
    fetching: boolean;
    error?: SerializedError;
  };
  visits: {
    data: VisitStats[];
    fetching: boolean;
    error?: SerializedError;
  };
  staff: {
    data: StaffData[];
    fetching: boolean;
    error?: SerializedError;
  };
  performance: {
    data: PerformanceStats[];
    fetching: boolean;
    error?: SerializedError;
  };
  filters: StatFilters;
}

const initialTimeFrame = 'Last 7 days';

const initialState: State = {
  stores: {
    data: [],
    fetching: false,
    error: undefined,
  },
  sessions: {
    data: [],
    fetching: false,
    error: undefined,
  },
  visits: {
    data: [],
    fetching: false,
    error: undefined,
  },
  staff: {
    data: [],
    fetching: false,
    error: undefined,
  },
  performance: {
    data: [],
    fetching: false,
    error: undefined,
  },
  filters: {
    storeIds: [],
    dateRange: timeFrameToDateRange(initialTimeFrame),
    timeFrame: initialTimeFrame,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Grouped Stores
    .addCase(getStores.pending, setFetchingState('stores'))
    .addCase(getStores.fulfilled, (state, { payload }) => {
      state.stores.data = payload;
      setFulfilledState('stores')(state);
    })
    .addCase(getStores.rejected, setRejectedState('stores'))
    // Get Session Stats
    .addCase(getSessionStats.pending, setFetchingState('sessions'))
    .addCase(getSessionStats.fulfilled, (state, { payload }) => {
      state.sessions.data = payload;
      setFulfilledState('sessions')(state);
    })
    .addCase(getSessionStats.rejected, setRejectedState('sessions'))
    // Get Visit Stats
    .addCase(getVisitStats.pending, setFetchingState('visits'))
    .addCase(getVisitStats.fulfilled, (state, { payload }) => {
      state.visits.data = payload;
      setFulfilledState('visits')(state);
    })
    .addCase(getVisitStats.rejected, setRejectedState('visits'))
    // Get Staff Stats
    .addCase(getStaffStats.pending, setFetchingState('staff'))
    .addCase(getStaffStats.fulfilled, (state, { payload }) => {
      state.staff.data = payload;
      setFulfilledState('staff')(state);
    })
    .addCase(getStaffStats.rejected, setRejectedState('staff'))
    // Get Performance Stats
    .addCase(getPerformanceStats.pending, setFetchingState('performance'))
    .addCase(getPerformanceStats.fulfilled, (state, { payload }) => {
      state.performance.data = payload;
      setFulfilledState('performance')(state);
    })
    .addCase(getPerformanceStats.rejected, setRejectedState('performance'))
    // Local
    .addCase(filtersUpdated, (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
