import { RootState } from '../types';

export const selectContacts = (state: RootState) =>
  state.contacts.contacts.data;

export const selectContactsPreview = (state: RootState) =>
  state.contacts.contacts.preview;

export const selectContactsFetching = (state: RootState) =>
  state.contacts.contacts.fetching;

export const selectContactsUpdating = (state: RootState) =>
  state.contacts.contacts.updating;

export const selectContactsLoading = (state: RootState) =>
  state.contacts.contacts.updating || state.contacts.contacts.fetching;

export const selectContactsError = (state: RootState) =>
  state.contacts.contacts.error;

export const selectContact = (state: RootState) => state.contacts.contact.data;

export const selectContactPreview = (state: RootState) =>
  state.contacts.contact.preview;

export const selectContactFetching = (state: RootState) =>
  state.contacts.contact.fetching;

export const selectContactUpdating = (state: RootState) =>
  state.contacts.contact.updating;
