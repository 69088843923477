import { RootState } from '../types';

export const selectPromotions = (state: RootState) =>
  state.promotions.promotions.data;

export const isUpdatingPromotions = (state: RootState) =>
  state.promotions.promotions.updating;

export const isFetchingPromotions = (state: RootState) =>
  state.promotions.promotions.fetching;

export const selectPromotionsError = (state: RootState) =>
  state.promotions.promotions.error;

export const selectDefaultPromotion = (state: RootState) =>
  state.promotions.default.data;
