import { FC } from 'react';
import styled from 'styled-components';

import {
  Contact,
  getEmail,
  getFormattedPhoneNumber,
  getFullName,
  getInitials,
  getRole,
} from '../models/contactModel';

import Avatar from './Avatar';
import Button from './Button';
import DialogText from './Dialog/DialogText';
import Icon from './Icon';
import IconButton from './IconButton';
import Initials from './Initials';
import Popper, { PopperProps } from './Popper';

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
`;

const Details = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 6px;
`;

const RoleText = styled(DialogText)`
  margin-bottom: 12px;
`;

const EmailText = styled(DialogText)`
  margin-bottom: 4px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

const DeleteButton = styled(IconButton)`
  font-size: 14px;
  margin-right: 8px;
`;

const DeleteIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.danger};
`;

const ConfirmButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
`;

interface Props extends PopperProps {
  contact: Contact;
  index: number;
  onEdit: () => void;
  onDelete: () => void;
  permission?: Boolean;
}

const ContactPopper: FC<Props> = ({
  contact,
  index,
  onEdit,
  onDelete,
  permission,
  ...rest
}) => (
  <Popper {...rest}>
    <Content>
      <Info>
        <Avatar index={index}>
          <Initials>{getInitials(contact)}</Initials>
        </Avatar>
        <Details>
          <DialogText variant="body2">{getFullName(contact)}</DialogText>
          <RoleText variant="body2">{getRole(contact)}</RoleText>
          <EmailText variant="body2">{getEmail(contact)}</EmailText>
          <DialogText variant="body2">
            {getFormattedPhoneNumber(contact)}
          </DialogText>
        </Details>
      </Info>
      {permission && (
        <Actions>
          <DeleteButton size="small" variant="outlined" onClick={onDelete}>
            <DeleteIcon name="delete" />
          </DeleteButton>
          <ConfirmButton size="small" variant="outlined" onClick={onEdit}>
            Edit
          </ConfirmButton>
        </Actions>
      )}
    </Content>
  </Popper>
);

export default ContactPopper;
