import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from '../../../store';
import { AuthSelectors, SettingsSelectors } from '../../../store/selectors';

import { withProspects, withStats } from '../../../models/appSettingsModel';

import {
  getAccessibleRoutes,
  isActiveRoute,
  navigationRoutes,
} from '../../../routing';

import NavigationItem from './NavigationItem';
import { apply, userModel } from '@atogear/arion-utils';

const Wrapper = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const Navigation: FC = () => {
  const user = useSelector(AuthSelectors.selectUser);
  const fetching = useSelector(AuthSelectors.selectUserFetching);
  const appSettings = useSelector(SettingsSelectors.selectAppSettings);

  const location = useLocation();

  // Filter nav items by role and appSettings
  const routes = useMemo(() => {
    if (!user || fetching) {
      return [];
    }

    const filters = [];

    const isPowerUser = apply(userModel.isPowerUser, user);

    if (isPowerUser) return getAccessibleRoutes(user!, navigationRoutes);

    if (!withStats(appSettings)) {
      // Remove stats (dashboard) route
      filters.push(navigationRoutes.dashboard.key);
    }

    if (!withProspects(appSettings)) {
      // Remove prospects (dashboard) route
      filters.push(navigationRoutes.prospects.key);
    }

    return getAccessibleRoutes(user, navigationRoutes, filters);
  }, [appSettings, fetching, user]);

  return (
    <Wrapper>
      {routes.map((route) => (
        <NavigationItem
          key={route.key}
          active={isActiveRoute(location.pathname, route, false)}
          route={route}
        />
      ))}
    </Wrapper>
  );
};

export default Navigation;
