import { FC, MouseEventHandler, useRef, useState } from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import Heading from '../Heading';
import Icon from '../Icon';
import Popper from '../Popper';
import TableFilters from './TableFilters';

import { Filter, SortBy, TableColumn } from './types';

interface WrapperProps {
  $sort?: boolean;
  $flex?: number;
}

const Wrapper = styled.th<WrapperProps>`
  cursor: ${({ $sort }) => ($sort ? 'pointer' : 'auto')};
  font-size: 14px;
  display: flex;
  flex: ${({ $flex }) => $flex ?? 1};
  flex-direction: row;
  align-items: center;
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) =>
    tinycolor(theme.colors.secondary).getBrightness() < 200
      ? theme.colors.light
      : theme.colors.dark};
`;

interface ActiveProps {
  $active?: boolean;
}

const StyledIcon = styled(Icon)<ActiveProps>`
  color: ${({ $active, theme }) =>
    $active ? theme.colors.primary : theme.colors.textTwo};
`;

interface SortProps {
  $desc?: boolean;
}

const SortIcon = styled(StyledIcon)<SortProps>`
  padding: 8px;
  transition: transform ease 0.2s;
  ${({ $desc }) => !$desc && 'transform: rotate(-180deg)'};
`;

const FilterIcon = styled(StyledIcon)`
  padding: 8px 8px 8px 0;
`;

const StyledPopper = styled(Popper)`
  padding: 16px;
`;

interface Props {
  column: TableColumn;
  filters?: Filter;
  sortBy?: SortBy;
  onClick?: (key: string) => void;
  onFilterChange?: (filter: Filter) => void;
}

const TableHeaderCell: FC<Props> = ({
  column,
  filters,
  sortBy,
  onClick,
  onFilterChange,
}) => {
  const { key, label, filter, sort, flex } = column;

  const filtered =
    // Filtering enabled for this column
    filter &&
    filters &&
    // Filtered on this column
    key === filters.key &&
    // Filtered by values
    filters.values.length !== filters.options.length;

  const sorted =
    // Sorting enabled for this column
    sort &&
    sortBy &&
    // Sorted by this column
    key === sortBy.key;

  const [open, setOpen] = useState(false);

  const anchorEl = useRef<HTMLTableCellElement>(null);

  const handleClick = () => sort && onClick && onClick(key);

  const handleFilterClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();

    setOpen((prev) => !prev);
  };

  const handleDismiss = () => setOpen(false);

  return (
    <Wrapper ref={anchorEl} $sort={sort} $flex={flex} onClick={handleClick}>
      {typeof label === 'string' ? (
        <StyledHeading variant="h3">{label}</StyledHeading>
      ) : (
        label
      )}
      {sort && (
        <SortIcon
          $active={sorted}
          $desc={sorted ? sortBy.desc : true}
          name="arrow-down"
        />
      )}
      {filter && (
        <FilterIcon
          $active={filtered}
          name="filter"
          onClick={handleFilterClick}
        />
      )}
      {filter && filters && (
        <StyledPopper
          anchorEl={anchorEl.current}
          open={open}
          onDismiss={handleDismiss}
        >
          <TableFilters filters={filters} onChange={onFilterChange} />
        </StyledPopper>
      )}
    </Wrapper>
  );
};

export default TableHeaderCell;
