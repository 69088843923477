import { validate } from 'email-validator';
import { getImageProps, getVideoProps } from './mediaUtils';

const phoneNumberRegex = /^\+?[\d]+$/;

const websiteRegex =
  /^(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,20}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

// ' -~' => Basic Latin alphabet, digits, punctuation and symbols
// '¡-ÿ' => Latin-1 supplement letters and symbols
// 'Ā-ſ' => Latin Extended-A letters
// '₠-⃀' => Currency symbols
// '+'   => At least 1 match
const textRegex = /^[ -~¡-ÿĀ-ſ₠-⃀]+$/;

// '\w'          => a-z A-Z 0-9 and _
// 'À-ÿ'         => Latin-1 supplement letters
// '.,/\-'()#& ' => Specific chars
// '+'           => At least 1 match
const addressRegex = /^[\wÀ-ÿ.,/\-'()#& ]+$/;

export const isValidEmail = validate;

export const isValidPhoneNumber = (value: string) =>
  phoneNumberRegex.test(value);

export const isValidWebsite = (value: string) => websiteRegex.test(value);

export const isValidText = (value: string) => textRegex.test(value);

export const isValidAddress = (value: string) => addressRegex.test(value);

export const validateEmail = (value: string) => {
  if (!isValidEmail(value)) {
    throw new Error('Invalid email.');
  }
};

export const validateNewPassword = (
  newPassword: string,
  confirmPassword: string,
) => {
  if (newPassword.length < 8) {
    throw new Error('Password must be at least 8 characters.');
  }

  if (newPassword !== confirmPassword) {
    throw new Error("Passwords don't match.");
  }
};

export const validateChangePassword = (
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
) => {
  if (currentPassword === newPassword) {
    throw new Error('New password is the same as the old password.');
  }

  validateNewPassword(newPassword, confirmPassword);
};

// Media
export const isValidVideo = async (videoFile: File) => {
  if (videoFile.type !== 'video/mp4') {
    return false;
  }

  const props = await getVideoProps(videoFile);

  if (!props) {
    return false;
  }

  // checking for 16x9
  if (props.ratio * 9 !== 16) {
    return false;
  }

  // res to be at min 720 (HD)
  if (props.height < 720) {
    return false;
  }

  // res to be at max 1080 (Full HD)
  if (props.height > 1080) {
    return false;
  }

  // duration to be max 1 min
  if (props.duration > 61) {
    return false;
  }

  return true;
};

export const isValidImage = async (
  imageFile: File,
  options = {
    fileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
    // dimensions in pixels(px)
    dimensions: { width: 2048, height: 2048 },
    // size in megabytes(MB)
    size: 5,
  },
) => {
  const { fileTypes, dimensions, size } = options;

  if (fileTypes) {
    const type = fileTypes.findIndex((type) => type === imageFile.type);

    if (type === -1) return [false, 'image/unsupported-type'];
  }

  if (dimensions) {
    const image = await getImageProps(imageFile);

    if (image.width > dimensions.width) {
      return [false, 'image/unsupported-width'];
    }

    if (image.height > dimensions.height) {
      return [false, 'image/unsupported-height'];
    }
  }

  if (size) {
    const bigger = imageFile.size > size * 1000000;

    if (bigger) {
      return [false, 'image/unsupported-size'];
    }
  }

  return [true, null];
};
