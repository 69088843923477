import { FC, UIEventHandler, useRef } from 'react';
import { isEmpty } from 'ramda';
import styled from 'styled-components';

import Heading from '../Heading';
import Skeleton from '../Skeleton';
import Text from '../Text';
import TableBodyRow from './TableBodyRow';

import { TableProps } from './types';

const SKELETON_ROWS = [1, 2, 3, 4, 5, 6, 7];

const Wrapper = styled.tbody`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

const LoadingRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  display: flex;
  flex-direction: row;
  min-height: 70px;
  margin-top: 2px;
  padding: 0 32px;
`;

const LoadingCell = styled.td`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const EmptyRow = styled.tr`
  display: flex;
  flex: 1;
`;

const EmptyCell = styled.td`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 160px 0;
`;

const EmptyHeading = styled(Heading)`
  margin-bottom: 16px;
`;

const TableBody: FC<TableProps> = ({
  columns,
  data,
  loading,
  onRowClick,
  endOffset = 100,
  onEndReached,
}) => {
  const lastScrollPosRef = useRef(0);

  const handleOnScroll: UIEventHandler<HTMLTableSectionElement> = (e) => {
    if (!onEndReached) {
      return;
    }

    const scrollTop = e.currentTarget.scrollTop;

    // trigger only when scrolling down
    if (scrollTop < lastScrollPosRef.current) {
      lastScrollPosRef.current = scrollTop;

      return;
    }

    const containerHeight =
      e.currentTarget.scrollHeight -
      e.currentTarget.getBoundingClientRect().height;

    if (containerHeight - endOffset < scrollTop) {
      onEndReached();
    }

    lastScrollPosRef.current = scrollTop;
  };

  return (
    <Wrapper onScroll={onEndReached ? handleOnScroll : undefined}>
      {loading ? (
        SKELETON_ROWS.map((key) => (
          <LoadingRow key={key}>
            {columns.map((column) => (
              <LoadingCell key={column.key}>
                <Skeleton
                  style={{
                    height: 20,
                    width: `calc((100% / ${1 + Math.random() * 2}) - 64px)`,
                  }}
                />
              </LoadingCell>
            ))}
          </LoadingRow>
        ))
      ) : isEmpty(data) ? (
        <EmptyRow>
          <EmptyCell>
            <EmptyHeading variant="h2">
              Nothing matches your search
            </EmptyHeading>
            <Text variant="body2">Sorry, we couldn't find any results.</Text>
          </EmptyCell>
        </EmptyRow>
      ) : (
        data.map((row, key) => (
          <TableBodyRow
            key={key}
            columns={columns}
            row={row}
            onClick={onRowClick}
          />
        ))
      )}
    </Wrapper>
  );
};

export default TableBody;
