import { FC } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../../../components';
import { Theme } from '../../../../../theme/types';
import ComponentTile from './ComponentTile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 24px;
`;

interface Props {
  theme: Theme;
}

const ComponentsPreview: FC<Props> = ({ theme, ...rest }) => {
  const { components } = theme;
  return (
    <Wrapper {...rest}>
      <StyledHeading variant="h3">Components</StyledHeading>
      <ComponentTile
        name="Tile border"
        value={components.tiles.default.borderRadius}
      />
      <ComponentTile
        name="Primary button border"
        value={components.buttons.primary.borderRadius}
      />
      <ComponentTile
        name="Secondary button border"
        value={components.buttons.secondary.borderRadius}
      />
    </Wrapper>
  );
};

export default ComponentsPreview;
