import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { apply, isNilOrWhitespace, storeModel } from '@atogear/arion-utils';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import { DialogInput, DialogInputLabel } from '../../../../components';

import { GeneralProspectStoreData } from '../../types';
import { ProspectStore } from '../../../../models/prospectStoreModel';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const StyledNameField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

interface Props {
  store?: ProspectStore;
  onChange: (value: GeneralProspectStoreData) => void;
}

const General: FC<Props> = ({ store, onChange }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(apply(storeModel.getName, store, ''));
  }, [store]);

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setName(value);

    debouncedOnChange({
      storeName: value,
    });
  };

  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  return (
    <Wrapper style={{ width: '100%' }}>
      <StyledNameField>
        <StyledInputLabel htmlFor="name">Name</StyledInputLabel>
        <DialogInput
          id="name"
          placeholder="Name"
          value={name}
          required
          validate={handleValidate}
          onChange={handleChange}
        />
      </StyledNameField>
    </Wrapper>
  );
};

export default General;
