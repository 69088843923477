import { createAsyncThunk } from '@reduxjs/toolkit';
import { Survey } from '@atogear/arion-utils';

import { getSurveysService } from '../../services';
import { GetSurveysOptions } from '../../services/surveys';
import { ApiSurvey } from '../../models/apiSurveyModel';

export const getSurveys = createAsyncThunk(
  'surveys/getSurveys',
  /**
   * Get Surveys.
   *
   * @returns Promise that resolves to Surveys.
   */
  async (
    options?: GetSurveysOptions,
  ): Promise<{ items: ApiSurvey[]; total: number; offset?: number }> => {
    const surveys = await getSurveysService().getSurveys(options);

    return { ...surveys, offset: options?.offset };
  },
);

export const getSurvey = createAsyncThunk(
  'surveys/getSurvey',
  /**
   * Get Survey.
   *
   * @returns Promise that resolves to Survey.
   */
  async (surveyId: string): Promise<ApiSurvey> => {
    const survey = await getSurveysService().getSurvey(surveyId);

    return survey;
  },
);

export const createSurvey = createAsyncThunk(
  'surveys/createSurvey',
  /**
   * Create Survey.
   *
   * @param params Survey's data.
   * @returns Promise that resolves to created Survey.
   */
  async (
    params: Pick<Survey, 'type' | 'startAt' | 'endAt'>,
  ): Promise<ApiSurvey> => {
    const survey = await getSurveysService().createSurvey(params);

    return survey;
  },
);

interface UpdateStoreParams {
  surveyId: string;
  data: Partial<Pick<Survey, 'type' | 'startAt' | 'endAt'>>;
}

export const updateSurvey = createAsyncThunk(
  'surveys/updateSurvey',
  /**
   * Update Survey.
   *
   * @param params Survey's id and data.
   * @returns Promise that resolves to updated Survey.
   */
  async (params: UpdateStoreParams): Promise<ApiSurvey> => {
    const { surveyId, data } = params;

    const survey = await getSurveysService().updateSurvey(surveyId, data);

    return survey;
  },
);

export const deleteSurvey = createAsyncThunk(
  'surveys/deleteSurvey',
  /**
   * Delete Survey.
   *
   * @param surveyId Survey's id.
   * @returns Promise that resolves to the survey id that has been deleted.
   */
  async (surveyId: string): Promise<string> => {
    const deletedSurveyId = await getSurveysService().deleteSurvey(surveyId);

    return deletedSurveyId;
  },
);
