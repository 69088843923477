import {
  getFirebaseFunctionsPipe,
  getFirestorePipe,
} from '@atogear/ato-broker';

import { LeadTypes } from '../enums';
import {
  getType,
  Lead,
  serialize,
  setCreatedAt,
  setUpdatedAt,
  toFirestore,
} from '../models/leadModel';

import { HttpsCallableResult } from './types';

const STORE_COLL_PATH = 'stores';
const LEAD_COLL_PATH = 'leads';

/**
 * Get Leads path.
 *
 * @param storeId Store's id.
 * @returns Leads path.
 */
const getLeadsPath = (storeId: string): string =>
  `${STORE_COLL_PATH}/${storeId}/${LEAD_COLL_PATH}`;

const getLeadService = () => {
  /**
   * Get Leads.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Leads.
   */
  const getLeads = async (storeId: string): Promise<Lead[]> => {
    const leads = await getFirestorePipe().getDocuments(getLeadsPath(storeId));

    return (leads as Lead[]).map(serialize);
  };

  /**
   * Get Lead.
   *
   * @param storeId Store's id.
   * @param leadId Lead's id.
   * @returns Promise that resolves to Lead.
   */
  const getLead = async (storeId: string, leadId: string): Promise<Lead> => {
    const lead = await getFirestorePipe().getDocument(
      getLeadsPath(storeId),
      leadId,
    );

    return serialize(lead as Lead);
  };

  /**
   * Create Lead.
   *
   * @param storeId Store's id.
   * @param data Lead's data.
   * @returns Promise that resolves to create Lead's id.
   */
  const createLead = async (storeId: string, data: Lead): Promise<void> => {
    const parsedData = toFirestore(setCreatedAt(Date.now(), data));

    await getFirestorePipe().addDocument(getLeadsPath(storeId), parsedData);
  };

  /**
   * Update Lead.
   *
   * @param storeId Store's id.
   * @param leadId Lead's id.
   * @param data Lead's data.
   * @returns Promise that resolves to updated Lead.
   */
  const updateLead = async (
    storeId: string,
    leadId: string,
    data: Lead,
  ): Promise<Lead> => {
    const parsedData = toFirestore(setUpdatedAt(Date.now(), data));

    const lead = await getFirestorePipe().setDocument(
      getLeadsPath(storeId),
      leadId,
      parsedData,
      {
        merge: false,
      },
    );

    return serialize(lead as Lead);
  };

  /**
   * Delete Lead.
   *
   * @param storeId Store's id.
   * @param leadId Lead's id.
   * @returns Promise that resolves when Lead has been deleted successfully.
   */
  const deleteLead = (storeId: string, leadId: string): Promise<boolean> =>
    getFirestorePipe().deleteDocument(getLeadsPath(storeId), leadId);

  /**
   * Tryout Lead.
   *
   * @param data Lead's data.
   * @returns Promise that resolves when Lead tryout has been successful.
   */
  const tryoutLead = (data: Lead): Promise<HttpsCallableResult> => {
    const functions = getFirebaseFunctionsPipe();

    let name: string;

    switch (getType(data)) {
      case LeadTypes.INSTANT:
        name = 'admin-callableSendInstantLeadActivationEmails';
        break;
      case LeadTypes.SCHEDULED:
        name = 'admin-callableSendScheduledLeadActivationEmails';
        break;
      case LeadTypes.RECURRING:
        name = 'admin-callableSendRecurringLeadActivationEmails';
        break;
      default:
        throw new Error('Invalid lead type!');
    }

    return functions.callCloudFunction(name);
  };

  return {
    getLeads,
    getLead,
    createLead,
    updateLead,
    deleteLead,
    tryoutLead,
  };
};

export default getLeadService;
