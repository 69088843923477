import { TableColumn } from '../components/Table/types';
import {
  fields,
  getAmount,
  getCreatedAt,
  getInvoiceNumber,
  getDownloadLink,
  getPaidAt,
  Invoice,
} from '../models/invoiceModel';

export const invoiceColumns: TableColumn<Invoice>[] = [
  {
    key: fields.INVOICE_NR,
    label: 'Invoice number',
    sort: true,
    getValue: getInvoiceNumber,
  },
  {
    key: fields.CREATED_AT,
    label: 'Date',
    sort: true,
    getValue: getCreatedAt,
  },
  {
    key: fields.PAID_AT,
    label: 'Paid',
    sort: true,
    getValue: getPaidAt,
  },
  {
    key: fields.AMOUNT,
    label: 'Amount',
    sort: true,
    getValue: getAmount,
  },
  {
    key: fields.DOWNLOAD_LINK,
    label: 'Download',
    flex: 0,
    getValue: getDownloadLink,
  },
];
