import { FC, ReactNode, useRef } from 'react';
import { isEmpty } from 'ramda';
import styled from 'styled-components';
import { createFilterOptions, useAutocomplete } from '@mui/base';

import { alphaToHex } from '../utils/colorUtils';

import Icon from './Icon';

export interface Option {
  adornment?: ReactNode;
  inputValue?: string;
  disabled?: boolean;
  key: string | number;
  label: string;
  value: any;
}

const filter = createFilterOptions<Option>();

interface WrapperProps {
  $disabled?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  display: flex;
  flex-direction: column;
`;

interface InputBaseProps {
  $disabled?: boolean;
  $focused?: boolean;
}

const InputBase = styled.div<InputBaseProps>`
  ${({ theme }) => theme.typography.body2};
  ${({ theme }) => theme.components.input};
  border: 1px solid
    ${({ $focused, theme }) =>
      $focused ? theme.colors.primary : theme.colors.grayOpaque};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
`;

interface AdornmentProps {
  $start?: boolean;
}

const Adornment = styled.span<AdornmentProps>`
  display: flex;
  ${({ $start }) => `margin-${$start ? 'right' : 'left'}: 6px;`}
`;

const StyledInput = styled.input`
  ${({ theme }) => theme.typography.body2};
  ${({ theme }) => theme.components.input};
  background-color: transparent;
  border: none;
  caret-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex: 1;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  width: 100%;

  &:focus {
    outline: none;
  }
`;

interface IconProps {
  $disabled?: boolean;
  $open?: boolean;
}

export const DropDownIcon = styled(Icon)<IconProps>`
  font-size: 8px;
  transition: transform ease 0.2s;
  ${({ $open }) => $open && 'transform: rotate(-180deg)'};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`;

interface ListboxProps {
  $offset?: number;
}

const Listbox = styled.ul<ListboxProps>`
  background-color: ${({ theme }) => theme.colors.surfaceOne};
  border: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  border-radius: 6px;
  position: absolute;
  top: ${({ $offset }) => `${$offset || 0}px`};
  list-style: none;
  outline: none;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  width: 100%;
  padding: 8px 0px;
  z-index: 1;
`;

interface ListItemProps {
  $disabled?: boolean;
}

const ListboxItem = styled.li<ListItemProps>`
  ${({ theme }) => theme.typography.body2};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

  &:active,
  &[aria-selected='true'] {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    background-color: ${({ $disabled, theme }) =>
      !$disabled ? theme.colors.primary + alphaToHex(0.5) : 'transparent'};
  }
`;

const AddIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
`;

export interface SelectProps {
  allowCreate?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  options: Option[];
  value?: any;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  onChange?: (option: Option | null) => void;
}

const Select: FC<SelectProps> = ({
  allowCreate,
  className,
  disabled,
  id,
  options,
  value,
  startAdornment,
  endAdornment,
  onChange,
}) => {
  const inputBaseRef = useRef<HTMLDivElement | null>(null);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    focused,
    groupedOptions,
    popupOpen,
  } = useAutocomplete({
    // Default Select props
    id,
    openOnFocus: true,
    options,
    value: options.find((option) => option.value === value) || null,
    getOptionLabel: (option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }

      // Create "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }

      // Regular option
      return option.label;
    },
    getOptionDisabled: (option) => option.disabled || false,
    onChange: (_, option) => {
      if (!onChange) {
        return;
      }

      if (typeof option === 'string') {
        onChange({
          key: 'unknown',
          label: option,
          value: 'unknown',
        });
      } else if (option?.inputValue) {
        // Create a new value from the user input
        onChange({
          key: 'new',
          label: option.inputValue,
          value: 'new',
        });
      } else {
        onChange(option);
      }
    },
    // Create new value props
    freeSolo: allowCreate, // allow textbox contain any value
    selectOnFocus: allowCreate, // help user clear selected value
    clearOnBlur: allowCreate, // helps user enter new value
    handleHomeEndKeys: allowCreate, // move focus inside popup with Home and End keys
    filterOptions: (options, params) => {
      const filtered = filter(options, params);

      if (allowCreate) {
        const { inputValue, getOptionLabel } = params;

        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === getOptionLabel(option),
        );

        if (inputValue !== '' && !isExisting) {
          filtered.push({
            key: 'create-new-option',
            adornment: <AddIcon name="add-circle" />,
            inputValue,
            label: `Create "${inputValue}"`,
            value: 'new',
          });
        }
      }

      if (filtered.length === 0) {
        let label = 'No options found';

        if (allowCreate) {
          label += ', start typing to add new option';
        }

        filtered.push({
          key: 'no-options',
          disabled: true,
          label,
          value: 'no-options',
        });
      }

      return filtered;
    },
  });

  const handleClick = () => inputBaseRef.current?.focus();

  const listboxOffset = (inputBaseRef.current?.clientHeight || 0) + 2;

  return (
    <Wrapper className={className} $disabled={disabled} onClick={handleClick}>
      <InputBase
        ref={inputBaseRef}
        $disabled={disabled}
        $focused={focused}
        {...getRootProps()}
      >
        {startAdornment && <Adornment $start>{startAdornment}</Adornment>}
        <StyledInput disabled={disabled} {...getInputProps()} />
        {endAdornment && <Adornment>{endAdornment}</Adornment>}
        <Adornment>
          <DropDownIcon
            $disabled={disabled}
            $open={popupOpen}
            name="arrow-down-dropdown"
          />
        </Adornment>
      </InputBase>
      {!isEmpty(groupedOptions) && (
        <Listbox $offset={listboxOffset} {...getListboxProps()}>
          {(groupedOptions as Option[]).map((option, index) => {
            const props = getOptionProps({ option, index });

            const { key, adornment, disabled, label } = option;

            return (
              <ListboxItem
                {...props}
                $disabled={disabled}
                key={key}
                onClick={!disabled ? props.onClick : undefined}
              >
                {adornment && <Adornment $start>{adornment}</Adornment>}
                {label}
              </ListboxItem>
            );
          })}
        </Listbox>
      )}
    </Wrapper>
  );
};

export default Select;
