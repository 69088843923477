import { getFirestorePipe } from '@atogear/ato-broker';

import { fields, Invoice, serialize } from '../models/invoiceModel';

const STORE_COLL_PATH = 'stores';
const INVOICE_COLL_PATH = 'invoices';

const getInvoiceService = () => {
  /**
   * Get Store's Invoices.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Store's Invoices.
   */
  const getInvoices = async (storeId: string): Promise<Invoice[]> => {
    const invoices = await getFirestorePipe().getDocuments(
      `${STORE_COLL_PATH}/${storeId}/${INVOICE_COLL_PATH}`,
      {
        orderBy: {
          fieldName: fields.UPDATED_AT,
          sortOrder: 'desc',
        },
      },
    );

    return (invoices as Invoice[]).map(serialize);
  };

  return {
    getInvoices,
  };
};

export default getInvoiceService;
