import { FontWeights } from '../enums';
import { MediaOption, MediaOptions } from '../models/themeModel';

import { TypographyStylesType } from '../theme/types';

import { translatedFontWeights } from '../translations';

export const MIN_LETTER_SPACING = -4;
export const MAX_LETTER_SPACING = 8;

export const mediaOptions: MediaOptions = {
  fullLogoURL: {
    preferredSize: {
      height: 100,
      width: 400,
    },
    aspectRatio: {
      height: 1,
      width: 4,
    },
    type: 'image',
    maxFileSize: 10,
  },
  miniLogoURL: {
    preferredSize: {
      height: 160,
      width: 160,
    },
    aspectRatio: {
      height: 1,
      width: 1,
    },
    type: 'image',
    maxFileSize: 10,
  },
  appBackgroundURL: {
    preferredSize: {
      height: 2160,
      width: 1620,
    },
    aspectRatio: {
      height: 4,
      width: 3,
    },
    type: 'image',
    maxFileSize: 10,
  },
  settingsHeaderURL: {
    preferredSize: {
      height: 864,
      width: 1620,
    },
    aspectRatio: {
      height: 1.6,
      width: 3,
    },
    type: 'image',
    maxFileSize: 10,
  },
  emailHeaderURL: {
    preferredSize: {
      height: 150,
      width: 600,
    },
    aspectRatio: {
      height: 1,
      width: 3,
    },
    type: 'image',
    maxFileSize: 10,
  },
  flyerHeaderURL: {
    preferredSize: {
      height: 1080,
      width: 1920,
    },
    aspectRatio: {
      height: 9,
      width: 16,
    },
    type: 'image',
    maxFileSize: 10,
  },
  kioskVideoURL: {
    preferredSize: {
      height: 1080,
      width: 1920,
    },
    aspectRatio: {
      height: 9,
      width: 16,
    },
    type: 'video',
    maxFileSize: 10,
  },
};

const getFontPresetLabel = (fontWeight: string) =>
  `${translatedFontWeights[fontWeight]} ${fontWeight}`.trim();

interface PresetOption {
  key: number;
  label: string;
  value: TypographyStylesType;
}

export const getFontPresetOptions = () => {
  const options: PresetOption[] = [];

  Object.keys(FontWeights).forEach((fontWeight) =>
    options.push({
      key: options.length,
      label: getFontPresetLabel(fontWeight),
      value: {
        fontWeight,
      },
    }),
  );

  return options;
};

export const getMediaAspectRatio = (mediaOption: MediaOption) => {
  const { aspectRatio } = mediaOption;

  return `${aspectRatio.width} / ${aspectRatio.height}`;
};
