import { UserRoles } from '@atogear/arion-utils';

import About from '../../views/Prospects/About/About';
import Payments from '../../views/Prospects/Payments/Payments';
import Subscriptions from '../../views/Prospects/Subscriptions/Subscriptions';
import Activity from '../../views/Prospects/Activity/Activity';
import Stores from '../../views/Prospects/Stores/Stores';

const prospectPath = `/prospects/:prospectId`;

export const prospectRoutes = {
  about: {
    key: 'about',
    label: 'About',
    path: `${prospectPath}/about`,
    accessibleBy: [],
    component: About,
  },
  stores: {
    key: 'stores',
    label: 'Stores',
    path: `${prospectPath}/stores`,
    accessibleBy: [],
    component: Stores,
  },
  subscription: {
    key: 'subscriptions',
    label: 'Subscription',
    path: `${prospectPath}/subscription`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
    ],
    component: Subscriptions,
  },
  payments: {
    key: 'payments',
    label: 'Payments',
    path: `${prospectPath}/payments`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
    ],
    component: Payments,
  },
  activity: {
    key: 'activity',
    label: 'Recent activity',
    path: `${prospectPath}/activity`,
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
    ],
    component: Activity,
  },
};
