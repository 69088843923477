import { FC } from 'react';
import styled from 'styled-components';

import { useSelector } from '../../../../store';
import { ThemeSelectors } from '../../../../store/selectors';

import { Heading, Text } from '../../../../components';
import AppSection from './AppSection';
import EmailSection from './EmailSection';
import FlyerSection from './FlyerSection';
import KioskSection from './KioskSection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const Images: FC = () => {
  const lightTheme = useSelector(ThemeSelectors.selectLightTheme);
  const darkTheme = useSelector(ThemeSelectors.selectDarkTheme);

  return (
    <Wrapper>
      <StyledHeading variant="h2">Images</StyledHeading>
      <Text variant="body2">
        Preview the custom images applied to your selected light and dark
        themes!
      </Text>
      <AppSection lightTheme={lightTheme} darkTheme={darkTheme} />
      <EmailSection lightTheme={lightTheme} darkTheme={darkTheme} />
      <FlyerSection lightTheme={lightTheme} darkTheme={darkTheme} />
      <KioskSection lightTheme={lightTheme} darkTheme={darkTheme} />
    </Wrapper>
  );
};

export default Images;
