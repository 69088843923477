import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { mergeRight } from 'ramda';
import { AppSettings, defaults } from '../../models/appSettingsModel';
import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
} from '../utils';
import { reset } from './actions';
import { getAppSettings } from './thunks';

interface State {
  appSettings: {
    data: AppSettings;
    fetching: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  appSettings: {
    data: defaults,
    fetching: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get App Settings
    .addCase(getAppSettings.pending, setFetchingState('appSettings'))
    .addCase(getAppSettings.fulfilled, (state, { payload }) => {
      state.appSettings.data = mergeRight(state.appSettings.data, payload);
      setFulfilledState('appSettings')(state);
    })
    .addCase(getAppSettings.rejected, setRejectedState('appSettings'))
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
