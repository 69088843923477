export const tabOptions = [
  {
    label: 'Logos',
  },
  {
    label: 'Themes',
  },
  {
    label: 'Images',
  },
];
