import { createAsyncThunk } from '@reduxjs/toolkit';
import { Group, Store } from '@atogear/arion-utils';

import { getGroupService } from '../../services';

export const getGroups = createAsyncThunk(
  'groups/getGroups',
  /**
   * Get Groups.
   *
   * @returns Promise that resolves to Groups.
   */
  async (): Promise<Group[]> => {
    const groups = await getGroupService().getGroups();

    return groups;
  },
);

export const getGroup = createAsyncThunk(
  'groups/getGroup',
  /**
   * Get Group.
   *
   * @param groupId Group's id.
   * @returns Promise that resolves to Group.
   */
  async (groupId: string): Promise<Group> => {
    const group = await getGroupService().getGroup(groupId);

    return group;
  },
);

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  /**
   * Create Group.
   *
   * @param data Group's data.
   * @returns Promise that resolves to created Group.
   */
  async (data: Group): Promise<Group> => {
    const group = await getGroupService().createGroup(data);

    return group;
  },
);

interface UpdateGroupParams {
  groupId: string;
  data: Partial<Group>;
}

export const updateGroup = createAsyncThunk(
  'groups/updateGroup',
  /**
   * Update Group.
   *
   * @param params Group's id and data.
   * @returns Promise that resolves to updated Group.
   */
  async (params: UpdateGroupParams): Promise<Group> => {
    const { groupId, data } = params;

    const group = await getGroupService().updateGroup(groupId, data);

    return group;
  },
);

export const deleteGroup = createAsyncThunk(
  'groups/deleteGroup',
  /**
   * Delete Group.
   *
   * @param groupId Group's id.
   * @returns Promise that resolves when Group has been successfully deleted.
   */
  async (groupId: string): Promise<boolean> => {
    const result = await getGroupService().deleteGroup(groupId);

    return result;
  },
);

export const getGroupStores = createAsyncThunk(
  'groups/getGroupStores',
  /**
   * Get Group's Stores.
   *
   * @param groupId Group's id.
   * @returns Promise that resolves to Group's Stores.
   */
  async (groupId: string): Promise<Store[]> => {
    const groupStores = await getGroupService().getGroupStores(groupId);

    return groupStores;
  },
);

export const getAccessibleGroups = createAsyncThunk(
  'groups/getAccessibleGroups',
  /**
   * Get accessible Groups.
   *
   * @returns Promise that resolves to accessible Groups.
   */
  async (): Promise<Group[]> => {
    const groups = await getGroupService().getAccessibleGroups();

    return groups;
  },
);
