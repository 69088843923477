import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { UploadedShoe } from '../../models/shoeModel';
import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
} from '../utils';
import { reset } from './actions';
import { uploadFailedFile, uploadShoes } from './thunks';

interface State {
  shoes: {
    data: UploadedShoe[];
    fetching: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  shoes: {
    data: [],
    fetching: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Upload Shoes
    .addCase(uploadShoes.pending, setFetchingState('shoes'))
    .addCase(uploadShoes.fulfilled, (state, { payload }) => {
      state.shoes.data = payload;
      setFulfilledState('shoes')(state);
    })
    .addCase(uploadShoes.rejected, setRejectedState('shoes'))
    .addCase(uploadFailedFile.pending, setFetchingState('shoes'))
    .addCase(uploadFailedFile.fulfilled, setFulfilledState('shoes'))
    .addCase(uploadFailedFile.rejected, setRejectedState('shoes'))
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
