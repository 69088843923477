import { FC } from 'react';
import styled from 'styled-components';
import { Theme } from '../../../../theme/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface ColorProps {
  $color?: string;
}

const Color = styled.div<ColorProps>`
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.colors.grayOpaque};
  border-radius: 4px;
  background-color: ${(props) => props.$color || 'transparent'};
  margin-right: 4px;
`;

interface FontProps {
  $font?: string;
}

const Font = styled.div<FontProps>`
  font-family: ${(props) => props.$font};
  color: ${(props) => props.theme.colors.text};
  font-size: '14px';
  font-weight: '400';
  margin-right: 4px;
`;

interface Props {
  theme: Theme;
}

const ColorPreview: FC<Props> = ({ theme }) => {
  return (
    <Wrapper>
      <Color $color={theme.colors.primary} />
      <Color $color={theme.colors.secondary} />
      <Color $color={theme.colors.text} />
      <Color $color={theme.colors.textTwo} />
      <Color $color={theme.colors.background} />
      <Color $color={theme.colors.surfaceOne} />
      <Color $color={theme.colors.surfaceTwo} />
      <Font $font={theme.fonts.primary}>Aa</Font>
      <Font $font={theme.fonts.secondary}>Aa</Font>
    </Wrapper>
  );
};

export default ColorPreview;
