import {
  FC,
  FormEventHandler,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apply, formatDate, StoreModule } from '@atogear/arion-utils';
import { addYears, endOfDay, startOfDay } from 'date-fns';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ProspectActions } from '../../../store/actions';
import { ProspectSelectors, SettingsSelectors } from '../../../store/selectors';

import { getPath } from '../../../routing';

import { formatYears } from '../../../utils/formatters';

import { getMaxSubDuration } from '../../../models/appSettingsModel';

import { translatedModules } from '../../../translations';

import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  Icon,
  ModuleChip,
  Option,
} from '../../../components';

import { ProspectRouteParams } from '../types';
import { prospectModel } from '../../../models';
import { prospectRoutes } from '../../../routing/routes/prospectRoutes';
import { Prospect } from '../../../models/prospectModel';
import { Product } from '../../../enums';

const StyledDialog = styled(Dialog)`
  width: clamp(500px, 35%, 50%);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

interface GroupProps {
  $row?: boolean;
}

const Group = styled.div<GroupProps>`
  display: flex;
  flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
  justify-content: ${({ $row }) => ($row ? 'flex-start' : 'center')};
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

interface FieldProps {
  $right?: boolean;
}

const Field = styled.div<FieldProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ $right }) => `margin-${$right ? 'left' : 'right'}: 4px;`}
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const ModulesContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
`;

const SecondaryButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
`;

const PrimaryButton = styled(Button)`
  margin-left: 8px;
`;

interface Props {
  open?: boolean;
  prospect?: Prospect;
  onClose?: () => void;
}

const SubscriptionDialog: FC<Props> = ({ prospect, open, onClose }) => {
  const dispatch = useDispatch();

  const appSettings = useSelector(SettingsSelectors.selectAppSettings);
  const loading = useSelector(ProspectSelectors.selectProspectUpdating);

  const { prospectId } = useParams<ProspectRouteParams>();

  const history = useHistory();

  const [modules, setModules] = useState(
    apply(
      prospectModel.getModules,
      prospect,
      prospectModel.getModules(prospectModel.defaults),
    ),
  );
  const [subStartDate, setSubStartDate] = useState(
    apply(prospectModel.getSubscriptionStartDate, prospect, Date.now()),
  );
  const [subDuration, setSubDuration] = useState(
    apply(prospectModel.getSubscriptionPeriod, prospect, 2),
  );

  const subEndDate = useMemo(() => {
    return formatDate(endOfDay(addYears(subStartDate, subDuration)));
  }, [subDuration, subStartDate]);

  const maxSubDuration = getMaxSubDuration(appSettings);

  const durationOptions = useMemo(() => {
    return [...new Array(maxSubDuration)].map((_, i) => ({
      key: `${i + 1}-year`,
      label: formatYears(i + 1),
      value: i + 1,
    }));
  }, [maxSubDuration]);

  const isDistributorSubscription = apply(
    prospectModel.isDistributorSubscription,
    prospect,
    false,
  );

  const products = apply(
    prospectModel.getProducts,
    prospect,
    prospectModel.getProducts(prospectModel.defaults),
  );

  const isHUB = products.includes(Product.HUB);

  useEffect(() => {
    if (open && prospect) {
      setModules(prospectModel.getModules(prospect));
      setSubStartDate(
        (prev) => prospectModel.getSubscriptionStartDate(prospect) ?? prev,
      );
      setSubDuration(
        (prev) => prospectModel.getSubscriptionPeriod(prospect) ?? prev,
      );
    }
  }, [open, prospect]);

  const handleUpdate = async () => {
    try {
      if (!prospectId) {
        return;
      }

      await dispatch(
        ProspectActions.updateProspect({
          prospectId,
          data: {
            activeModules: modules,
            subscriptionCreatedAt: Date.now(),
            subscriptionStartDate: subStartDate,
            subscriptionEndDate: endOfDay(
              addYears(subStartDate, subDuration),
            ).getTime(),
            subscriptionPeriod: subDuration,
          },
        }),
      ).unwrap();

      toast('Successfully updated prospect subscription!', {
        type: 'success',
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem updating the prospect subscription settings. ${
        (error as Error)?.message || ''
      }`;

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      if (!prospect) {
        return;
      }

      e.preventDefault();

      await handleUpdate();

      history.push(
        getPath(prospectRoutes.payments.path, {
          prospectId: prospectModel.getId(prospect),
        }),
      );
    } catch {}
  };

  const handleDurationChange = (option: Option | null) =>
    setSubDuration(option?.value || subDuration);

  const handleStartDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    setSubStartDate(startOfDay(date).getTime());
  };

  const handleToggleModule = (module: StoreModule) => () => {
    if (!prospect) {
      return;
    }

    setModules(
      prospectModel.getModules(
        prospectModel.toggleModule(module, {
          ...prospect,
          activeModules: modules,
        }),
      ),
    );
  };

  const id = useId();
  const durationId = `${id}-duration`;
  const startDateId = `${id}-start-date`;
  const endDateId = `${id}-end-date`;

  return (
    <StyledDialog
      open={open}
      loading={loading}
      title="Subscription settings"
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <Group>
            <StyledInputLabel htmlFor={durationId}>Duration</StyledInputLabel>
            <DialogSelect
              id={durationId}
              value={subDuration}
              options={durationOptions}
              onChange={handleDurationChange}
            />
          </Group>
          <Group $row>
            <Field>
              <StyledInputLabel htmlFor={startDateId}>
                Start date
              </StyledInputLabel>
              <DatePicker
                customInput={<DialogInput />}
                id={startDateId}
                selected={new Date(subStartDate)}
                onChange={handleStartDateChange}
              />
            </Field>
            <Field $right>
              <StyledInputLabel htmlFor={endDateId}>End date</StyledInputLabel>
              <DialogInput id={endDateId} disabled value={subEndDate} />
            </Field>
          </Group>
          {isHUB && (
            <Group>
              <StyledInputLabel>Modules</StyledInputLabel>
              <ModulesContainer>
                {Object.keys(modules).map((key) => {
                  const name = key as StoreModule;

                  const { title, icon } = translatedModules[name];

                  return (
                    <ModuleChip
                      key={`module-${key}`}
                      avatar={<Icon name={icon} />}
                      dark
                      label={title}
                      selected={modules[name]}
                      onClick={handleToggleModule(name)}
                    />
                  );
                })}
              </ModulesContainer>
            </Group>
          )}
        </Content>
        <DialogActions>
          <StyledActions>
            <SecondaryButton
              size="small"
              type="button"
              variant={isDistributorSubscription ? 'contained' : 'outlined'}
              onClick={handleUpdate}
            >
              Save
            </SecondaryButton>
            {!isDistributorSubscription && isHUB && (
              <PrimaryButton
                color="primary"
                size="small"
                type="submit"
                variant="contained"
              >
                Proceed to payments
              </PrimaryButton>
            )}
          </StyledActions>
        </DialogActions>
      </Form>
    </StyledDialog>
  );
};

export default SubscriptionDialog;
