export interface AppSettings {
  baseMonthlySubscriptionPrice: number;
  defaultThemeGroupId: string;
  enableLeads: boolean;
  enableStats: boolean;
  enableProspects: boolean;
  maxSubscriptionDuration: number;
  useWoocommerce: boolean;
  warningMessage?: string;
}

export const fields = {
  BASE_MONTHLY_SUB_PRICE: 'baseMonthlySubscriptionPrice',
  DEFAULT_THEME_GROUP_ID: 'defaultThemeGroupId',
  ENABLE_LEADS: 'enableLeads',
  ENABLE_STATS: 'enableStats',
  ENABLE_PROSPECTS: 'enableProspects',
  MAX_SUB_DURATION: 'maxSubscriptionDuration',
  USE_WOOCOMMERCE: 'useWoocommerce',
  WARNING_MESSAGE: 'warningMessage',
};

export const defaults: AppSettings = {
  baseMonthlySubscriptionPrice: 400,
  defaultThemeGroupId: 'b31c0a50-ac3b-11ec-ae7b-eb77564471d8',
  enableLeads: false,
  enableStats: false,
  enableProspects: false,
  maxSubscriptionDuration: 4,
  useWoocommerce: false,
};

export const getBaseMonthlySubPrice = (appSettings?: AppSettings) =>
  (appSettings || defaults).baseMonthlySubscriptionPrice;

export const getDefaultThemeGroupId = (appSettings?: AppSettings) =>
  (appSettings || defaults).defaultThemeGroupId;

export const withLeads = (appSettings?: AppSettings) =>
  (appSettings || defaults).enableLeads;

export const withStats = (appSettings?: AppSettings) =>
  (appSettings || defaults).enableStats;

export const withProspects = (appSettings?: AppSettings) =>
  (appSettings || defaults).enableProspects;

export const getMaxSubDuration = (appSettings?: AppSettings) =>
  (appSettings || defaults).maxSubscriptionDuration;

export const withWooCommerce = (appSettings?: AppSettings) =>
  (appSettings || defaults).useWoocommerce;

export const getWarningMessage = (appSettings?: AppSettings) =>
  (appSettings || defaults).warningMessage;

export const hasWarningMessage = (appSettings?: AppSettings) =>
  !!getWarningMessage(appSettings);
