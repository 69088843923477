import Compressor from 'compressorjs';
import { is } from 'ramda';

interface CompressorOptions {
  quality?: number;
  resize?: 'cover' | 'contain' | 'none';
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
}

export const compressImage = (
  file: File,
  options: CompressorOptions,
): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      const defaultOptions = {
        quality: 1,
      };

      new Compressor(file, {
        ...defaultOptions,
        ...options,
        success: (result) => {
          const originalSize = file.size || 0;

          const compressedSize = result.size || 0;

          console.log(
            `saved: ${originalSize - compressedSize}B(${(
              (1 - compressedSize / originalSize) *
              100
            ).toFixed(2)}%)`,
          );

          if (is(Blob, result)) {
            const resultFile = new File([result], file.name);
            resolve(resultFile);
          }
          if (is(File, result)) {
            resolve(result);
          }
        },
        error: (err) => {
          reject(err.message);
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

export interface VideoProps {
  duration: number;
  height: number;
  width: number;
  ratio: number;
}

export const getVideoProps = async (file: File): Promise<VideoProps | null> => {
  return new Promise((resolve, reject) => {
    try {
      if (!file) {
        return resolve(null);
      }

      const URL = window.URL || window.webkitURL;

      var video = document.createElement('video');

      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        URL.revokeObjectURL(video.src);

        resolve({
          duration: video.duration,
          height: video.videoHeight,
          width: video.videoWidth,
          ratio: video.videoWidth / video.videoHeight,
        });
      };

      video.src = URL.createObjectURL(file);
    } catch (error) {
      reject(error);
    }
  });
};

interface ImageProps {
  height: number;
  width: number;
}

export const getImageProps = (file: File): Promise<ImageProps> => {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();

      image.src = URL.createObjectURL(file);

      image.addEventListener('load', () => {
        resolve({
          width: image.width,
          height: image.height,
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};
