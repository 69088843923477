import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isNil } from 'ramda';
import styled, { useTheme } from 'styled-components';
import tinycolor from 'tinycolor2';

import { useSelector } from '../../store';
import { AuthSelectors } from '../../store/selectors';

import { Footer, Heading, LoadingMask } from '../../components';

import background from '../../assets/images/auth-image.jpg';
import appBarLogo from '../../assets/images/logo-light.png';
import logoDark from '../../assets/images/small-logo-dark.svg';
import logoLight from '../../assets/images/small-logo-light.svg';

interface LocationState {
  from?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;

const Promo = styled.div`
  background: url(${background});
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const AppBar = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  padding: 0 24px;
`;

const AppBarLogo = styled.img`
  height: 60%;
`;

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 16px 108px;
`;

const Logo = styled.img`
  width: 60px;
  margin-top: 40%;
  margin-bottom: 50px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

interface Props {
  children?: ReactNode;
  loading?: boolean;
  title?: string;
}

const Auth: FC<Props> = ({ children, loading, title }) => {
  const theme = useTheme();

  const authUser = useSelector(AuthSelectors.selectAuthUser);

  const history = useHistory();

  const location = useLocation<LocationState>();

  const logo = useMemo(() => {
    return tinycolor(theme.colors.surfaceTwo).isDark() ? logoLight : logoDark;
  }, [theme.colors.surfaceTwo]);

  useEffect(() => {
    if (!isNil(authUser)) {
      history.push(location.state.from || '/');
    }
  }, [authUser, history, location.state]);

  return (
    <Wrapper>
      <LoadingMask loading={loading} />
      <Promo>
        <AppBar>
          <AppBarLogo src={appBarLogo} alt="ARIONHUB" />
        </AppBar>
      </Promo>
      <Content>
        <Logo alt="ARIONHUB Admin Dashboard" src={logo} />
        <StyledHeading variant="h1">{title}</StyledHeading>
        {children}
      </Content>
      <Footer dark />
    </Wrapper>
  );
};

export default Auth;
