import { FC } from 'react';
import { StoreModule } from '@atogear/arion-utils';
import styled from 'styled-components';

import { translatedModules } from '../../../translations';

import { Heading, Text, Switch } from '../../../components';

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  user-select: none;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  padding: 24px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  margin-top: 16px;
  min-width: 700px;
  transition: background-color 0.1s ease-in-out;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  :active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  width: 10%;
  min-width: 80px;
`;

const SwitchLabel = styled(Heading)`
  margin-left: 8px;
`;

const Name = styled(Heading)`
  width: 20%;
`;

const Description = styled(Text)`
  width: 70%;
`;

interface Props {
  name?: StoreModule;
  value?: boolean;
  onClick?: () => void;
}

const Module: FC<Props> = ({ name, value, onClick }) => {
  const translation = !!name ? translatedModules[name] : undefined;

  return (
    <Container onClick={onClick}>
      <SwitchContainer>
        <Switch checked={value} />
        <SwitchLabel variant="h4">{value ? 'On' : 'Off'}</SwitchLabel>
      </SwitchContainer>
      {!!translation && (
        <>
          <Name variant="h3">{translation.title}</Name>
          <Description variant="body2">{translation.description}</Description>
        </>
      )}
    </Container>
  );
};

export default Module;
