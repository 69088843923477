import { DefaultThemeIds } from '../enums';
import colors from './colors';
import components from './components';
import fonts from './fonts';
import media from './media';
import { Theme } from './types';
import typography from './typography';

export const defaultTheme: Theme = {
  TEMP_isNewTheme: true,
  applyCount: 0,
  id: DefaultThemeIds.ARION_LIGHT,
  isDefault: true,
  isDark: false,
  themeName: 'ARIONHUB Light Theme',
  colors,
  components,
  fonts,
  media,
  typography,
};
