import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { Invite } from '../../models/inviteModel';
import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';
import { reset } from './actions';
import {
  createStoreAccount,
  getInvite,
  sendInvite,
  sendOwnerInvite,
} from './thunks';

interface State {
  invite: {
    data?: Invite;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  invite: {
    data: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Invite
    .addCase(getInvite.pending, setFetchingState('invite'))
    .addCase(getInvite.fulfilled, (state, { payload }) => {
      state.invite.data = payload;
      setFulfilledState('invite')(state);
    })
    .addCase(getInvite.rejected, setRejectedState('invite'))
    // Send Invite
    .addCase(sendInvite.pending, setFetchingState('invite'))
    .addCase(sendInvite.fulfilled, setFulfilledState('invite'))
    .addCase(sendInvite.rejected, setRejectedState('invite'))
    // Send Owner Invite
    .addCase(sendOwnerInvite.pending, setFetchingState('invite'))
    .addCase(sendOwnerInvite.fulfilled, setFulfilledState('invite'))
    .addCase(sendOwnerInvite.rejected, setRejectedState('invite'))
    // Create Store Account
    .addCase(createStoreAccount.pending, setUpdatingState('invite'))
    .addCase(createStoreAccount.fulfilled, setFulfilledState('invite'))
    .addCase(createStoreAccount.rejected, setRejectedState('invite'))
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
