import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Heading from './Heading';
import Icon from './Icon';

const Wrapper = styled.div`
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  padding: 0 12px 0 8px;
  transition: background-color ease 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
  }
`;

const BackIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTwo};
  font-size: 16px;
  margin-right: 8px;
`;

interface Props {
  label?: string;
  onClick?: () => void;
  to?: string;
}

const BackButton: FC<Props> = ({ label, to, onClick }) => {
  const history = useHistory();

  const handleClick = () => {
    if (to) {
      history.push(to);
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <Wrapper onClick={handleClick}>
      <BackIcon name="chevron-back" />
      <Heading variant="h3">{label}</Heading>
    </Wrapper>
  );
};

export default BackButton;
