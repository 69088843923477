import { formatFullName, formatInitials } from '@atogear/arion-utils';
import countries from '../assets/data/countries.json';

export interface Contact {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  callingCode: string;
  phoneNumber: string;
  role: string;
}

export const fields = {
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  CALLING_CODE: 'callingCode',
  PHONE_NUMBER: 'phoneNumber',
  ROLE: 'role',
};

export const defaults: Contact = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  callingCode: '',
  phoneNumber: '',
  role: '',
};

export const getId = (contact?: Contact) => (contact || defaults).id;

// User Info
export const getFirstName = (contact?: Contact) =>
  (contact || defaults).firstName;

export const getLastName = (contact?: Contact) =>
  (contact || defaults).lastName;

export const getFullName = (contact?: Contact) =>
  formatFullName(getFirstName(contact), getLastName(contact));

export const getInitials = (contact?: Contact) =>
  formatInitials(getFirstName(contact), getLastName(contact));

export const getEmail = (contact?: Contact) => (contact || defaults).email;

export const getCallingCode = (contact?: Contact) =>
  (contact || defaults).callingCode;

export const getFormattedCallingCode = (contact?: Contact) =>
  countries.find(({ alpha2 }) => alpha2 === getCallingCode(contact))
    ?.callingCode || '';

export const getPhoneNumber = (contact?: Contact) =>
  (contact || defaults).phoneNumber;

export const getFormattedPhoneNumber = (contact?: Contact) =>
  `${getFormattedCallingCode(contact)} ${getPhoneNumber(contact)}`;

// Role
export const getRole = (contact?: Contact) => (contact || defaults).role;

// Utils
export const create = (contact?: Contact): Contact => ({
  firstName: getFirstName(contact),
  lastName: getLastName(contact),
  email: getEmail(contact),
  callingCode: getCallingCode(contact),
  phoneNumber: getPhoneNumber(contact),
  role: getRole(contact),
});
