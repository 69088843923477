import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { RouteConfig } from '../routing';
import Heading from './Heading';
import Icon from './Icon';
import MouseTooltip from './MouseTooltip';

interface ActiveProps {
  $active?: boolean;
}

const Wrapper = styled(NavLink)<ActiveProps>`
  background-color: ${(props) =>
    props.$active ? props.theme.colors.highlight : 'transparent'};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
  margin-top: 2px;
  transition: background-color 0.2s ease-in-out;
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.text};
`;

interface Props {
  active?: boolean;
  route: RouteConfig;
  adminOnly?: boolean;
}

const NavigationListItem: FC<Props> = ({
  active,
  adminOnly = false,
  route,
}) => (
  <Wrapper $active={active} to={route.path}>
    <Heading variant="h3">{route.label}</Heading>
    {adminOnly && (
      <MouseTooltip tooltip="Admin only">
        <StyledIcon name="power-user-admin" />
      </MouseTooltip>
    )}
  </Wrapper>
);

export default NavigationListItem;
