import { ButtonHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.textTwo};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  padding: 12px;
  margin-left: 16px;
`;

const Icon = styled.span`
  color: ${({ theme }) => theme.colors.textTwo};
  font-size: 18px;
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const ViewColumnsButton = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <Button ref={ref} {...props}>
    <Icon className="icon icon-columns" />
  </Button>
));

export default ViewColumnsButton;
