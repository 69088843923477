import { FC, useState } from 'react';
import { apply, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useSelector } from '../../../store';
import { AuthSelectors, ProspectSelectors } from '../../../store/selectors';

import { Button, Heading, Icon, Text } from '../../../components';
import PaymentDialog from './PaymentDialog';
import PaymentInfo from './PaymentInfo';

import { prospectModel } from '../../../models';
import { ProspectStatus } from '../../../enums';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`;

const ErrorMessage = styled(Heading)`
  color: ${(props) => props.theme.colors.danger};
  text-decoration: underline;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const ButtonIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 17px;
  margin-right: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Payment: FC = () => {
  const user = useSelector(AuthSelectors.selectUser);
  const prospect = useSelector(ProspectSelectors.selectProspect);

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);
  const status = apply(
    prospectModel.getStatus,
    prospect,
    prospectModel.defaults.prospectStatus,
  );

  const hasBillingInfo = apply(
    prospectModel.hasBillingInfo,
    prospect,
    prospectModel.hasBillingInfo(prospectModel.defaults),
  );

  const isDistributorSubscription = apply(
    prospectModel.isDistributorSubscription,
    prospect,
    false,
  );

  const isTransformed = status === ProspectStatus.WON;

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const openEditDialog = () => setEditDialogOpen(true);

  const closeEditDialog = () => setEditDialogOpen(false);

  const canAddPaymentInfo = (isAdmin || isSales) && !isTransformed;

  const canEditPaymentInfo =
    canAddPaymentInfo && hasBillingInfo && !isDistributorSubscription;

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Payments</ContentHeading>
          <Text variant="body2">
            Preview and edit the prospect's payment details and agreement.
          </Text>
        </Info>
        {canEditPaymentInfo && (
          <EditButton>
            <Button variant="outlined" onClick={openEditDialog}>
              <ButtonIcon name="edit" />
              Edit payment plan
            </Button>
          </EditButton>
        )}
      </Header>
      <Content>
        {isDistributorSubscription ? (
          <ErrorMessage variant="h3">
            {isAdmin
              ? 'This store was created by a distributor and cannot be assigned payment information!'
              : ''}
          </ErrorMessage>
        ) : !hasBillingInfo ? (
          canAddPaymentInfo && (
            <div>
              <Button
                variant="outlined"
                onClick={openEditDialog}
                disabled={isDistributorSubscription}
              >
                <ButtonIcon name="edit" />
                Create payment plan
              </Button>
            </div>
          )
        ) : (
          <PaymentInfo open />
        )}
      </Content>
      {(canAddPaymentInfo || canEditPaymentInfo) && (
        <PaymentDialog
          prospect={prospect}
          open={editDialogOpen}
          onClose={closeEditDialog}
        />
      )}
    </Wrapper>
  );
};

export default Payment;
