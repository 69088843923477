import { TableColumn } from '../components/Table/types';
import {
  fields,
  getEventGroup,
  getUserName,
  Event,
  getDate,
  getId,
} from '../models/eventModel';

export const activityColumns: TableColumn<Event>[] = [
  {
    key: fields.ID,
    label: 'Activity',
    sort: true,
    flex: 4,
    getValue: getId,
  },
  {
    key: fields.TYPE,
    label: 'Group',
    filter: true,
    sort: true,
    flex: 3,
    getLabel: getEventGroup,
    getValue: getEventGroup,
  },
  {
    key: fields.DATE,
    label: 'Date',
    sort: true,
    flex: 3,
    getValue: getDate,
  },
  {
    key: fields.USER_NAME,
    label: 'Author',
    sort: true,
    flex: 2,
    getValue: getUserName,
  },
];

export const prospectActivityColumns: TableColumn<Event>[] = [
  {
    key: fields.ID,
    label: 'Activity',
    sort: true,
    flex: 4,
    getValue: getId,
  },
  {
    key: fields.DATE,
    label: 'Date',
    sort: true,
    flex: 3,
    getValue: getDate,
  },
  {
    key: fields.USER_NAME,
    label: 'Author',
    sort: true,
    flex: 2,
    getValue: getUserName,
  },
];
