import * as syncActions from './actions';
import * as thunks from './thunks';
import reducer from './reducer';

const actions = {
  ...syncActions,
  ...thunks,
};

export { actions, reducer };
