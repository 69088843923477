import { createAction } from '@reduxjs/toolkit';
import { AuthUser } from '@atogear/arion-utils';

export const authUserChanged = createAction<AuthUser>('auth/authUserChanged');

export const authUserReset = createAction('auth/authUserReset');

export const emailUpdated = createAction<string>('auth/emailUpdated');

export const reset = createAction('auth/reset');
