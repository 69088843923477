import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apply, isNilOrWhitespace, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import { AuthSelectors } from '../../../../store/selectors';

import {
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  ModuleChip,
  Option,
} from '../../../../components';

import { GeneralProspectData } from '../../types';
import { prospectModel } from '../../../../models';

import {
  translatedGroupType,
  translatedProduct,
} from '../../../../translations';
import { Prospect, toggleProduct } from '../../../../models/prospectModel';
import { Product } from '../../../../enums';

const TYPE_OPTIONS = Object.entries(translatedGroupType).map((type) => ({
  key: type[0],
  label: type[1],
  value: type[0],
}));

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 32px;
  margin-top: 16px;
`;

const ProductsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;

  & section {
    margin: 0;
  }

  & section > div {
    margin-right: 0;
    padding: 6px;
  }
`;

const StyledNameField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledTypeField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const Placeholder = styled.div`
  display: flex;
  flex: 1;
  margin-left: 32px;
`;

interface Props {
  prospect: Prospect;
  onChange: (value: GeneralProspectData) => void;
}

const General: FC<Props> = ({ prospect, onChange }) => {
  const user = useSelector(AuthSelectors.selectUser);

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    setName(prospectModel.getName(prospect));
    setType(prospectModel.getType(prospect));
    setProducts(prospectModel.getProducts(prospect));
  }, [prospect]);

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setName(value);

    debouncedOnChange({
      name: value,
    });
  };

  const handleTypeChange = (option: Option | null) => {
    const value = option?.value || type;

    setType(value);

    // NOTE: Not debounced since it is selected from dropdown
    onChange({
      type: value,
    });
  };

  const handleProductChange = (product: Product) => {
    const value = toggleProduct(product, products);

    setProducts(value);

    onChange({
      products: value,
    });
  };

  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const canSetType = isAdmin || isSales;

  return (
    <Wrapper>
      <StyledNameField>
        <StyledInputLabel htmlFor="name">Name</StyledInputLabel>
        <DialogInput
          id="name"
          placeholder="Name"
          value={name}
          validate={handleValidate}
          onChange={handleChange}
          required
        />
      </StyledNameField>
      <Row>
        <StyledTypeField>
          <StyledInputLabel id="type-label" htmlFor="type">
            Type
          </StyledInputLabel>
          {canSetType ? (
            <DialogSelect
              id="type"
              options={TYPE_OPTIONS}
              value={type}
              onChange={handleTypeChange}
            />
          ) : (
            <Placeholder />
          )}
        </StyledTypeField>
        <StyledTypeField>
          <StyledInputLabel id="product-label" htmlFor="product">
            Products
          </StyledInputLabel>
          {canSetType ? (
            <ProductsRow>
              {Object.values(Product).map((product) => (
                <ModuleChip
                  label={translatedProduct[product]}
                  key={`product-${product}`}
                  dark
                  selected={products.includes(product)}
                  onClick={() => handleProductChange(product)}
                />
              ))}
            </ProductsRow>
          ) : (
            <Placeholder />
          )}
        </StyledTypeField>
      </Row>
    </Wrapper>
  );
};

export default General;
