import { FC, HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 34px;
`;

interface SegmentProps {
  $disabled?: boolean;
  $first?: boolean;
  $last?: boolean;
  $selected?: boolean;
}

const Segment = styled.div<SegmentProps>`
  ${({ $selected, theme }) =>
    $selected ? theme.typography.h3 : theme.typography.h4};
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.colors.dark : theme.colors.light};
  border-left: ${({ $first, $last, theme }) =>
    $first || $last ? 0 : `1px solid ${theme.colors.grayOpaque}`};
  border-right: ${({ $first, $last, theme }) =>
    $first || $last ? 0 : `1px solid ${theme.colors.grayOpaque}`};
  color: ${({ $selected, theme }) =>
    $selected ? theme.colors.white : theme.colors.text};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  width: fit-content;
  white-space: nowrap;
`;

interface Option {
  disabled?: boolean;
  label: string;
  value: any;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  initialIndex?: number;
  options: Option[];
  onChange?: (value: any) => void;
}

const SegmentedControl: FC<Props> = ({
  disabled,
  initialIndex,
  options,
  onChange,
  ...rest
}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex || 0);

  useEffect(() => {
    if (initialIndex) {
      setSelectedIndex(Math.max(initialIndex, 0));
    }
  }, [initialIndex]);

  useEffect(() => {
    if (options[selectedIndex].disabled) {
      const firstNotDisabledIndex = options.findIndex(
        (option) => !option.disabled,
      );

      if (firstNotDisabledIndex >= 0) {
        const { value } = options[firstNotDisabledIndex];

        setSelectedIndex(firstNotDisabledIndex);
        onChange && onChange(value);
      }
    }
  }, [options, onChange, selectedIndex]);

  const handleSegmentClick = (index: number, value: any) => () => {
    setSelectedIndex(index);

    onChange && onChange(value);
  };

  return (
    <Wrapper {...rest}>
      {options.map(({ disabled: itemDisabled, label, value }, index) => (
        <Segment
          $disabled={disabled || itemDisabled}
          $first={index === 0}
          $last={index === options.length - 1}
          $selected={index === selectedIndex}
          key={index}
          onClick={
            !(disabled || itemDisabled)
              ? handleSegmentClick(index, value)
              : undefined
          }
        >
          {label}
        </Segment>
      ))}
    </Wrapper>
  );
};

export default SegmentedControl;
