import { lazy } from 'react';
import { UserRoles } from '@atogear/arion-utils';
import CreateProspect from '../../views/Prospects/CreateProspect/CreateProspect';
import ProspectAgreement from '../../components/Documents/ProspectDocuments/Agreement';
import Quote from '../../components/Documents/ProspectDocuments/Quote';
import Surveys from '../../views/Surveys/Surveys';

// Stats
const Dashboard = lazy(() => import('../../views/Dashboard/Dashboard'));

// Themes
const Themes = lazy(() => import('../../views/Themes/Themes'));
const Theme = lazy(() => import('../../views/Themes/Theme/Theme'));

// Groups
const Groups = lazy(() => import('../../views/Groups/Groups'));
const Group = lazy(() => import('../../views/Groups/Group'));

// Stores
const Stores = lazy(() => import('../../views/Stores/Stores'));
const Store = lazy(() => import('../../views/Stores/Store/Store'));
const CreateStore = lazy(
  () => import('../../views/Stores/CreateStore/CreateStore'),
);
const LeadEditor = lazy(
  () => import('../../views/Stores/Leads/LeadEditor/LeadEditor'),
);
const StoreAgreement = lazy(
  () => import('../../components/Documents/StoreDocuments/Agreement'),
);

// Accounts
const Accounts = lazy(() => import('../../views/Accounts/Accounts'));
const FirstLogin = lazy(() => import('../../views/Auth/FirstLogin'));
const User = lazy(() => import('../../views/User/User'));

//Prospects
const Prospects = lazy(() => import('../../views/Prospects/Prospects'));
const Prospect = lazy(() => import('../../views/Prospects/Prospect/Prospect'));

const themesPath = '/themes';
const themePath = `${themesPath}/:themeId`;
const groupsPath = '/groups';
const groupPath = `${groupsPath}/:groupId`;
const storesPath = '/stores';
const storePath = `${storesPath}/:storeId`;
const accountsPath = '/accounts';
const prospectsPath = '/prospects';
const prospectPath = `${prospectsPath}/:prospectId`;
const surveyPaths = '/surveys';
const userPath = '/user';

// ==================================================================
// == Order in these routes matters, be careful when moving routes ==
// ==================================================================
export const appRoutes = {
  // Stats
  dashboard: {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_DESIGNER,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    component: Dashboard,
  },
  // Themes
  themes: {
    key: 'themes',
    label: 'Themes',
    path: themesPath,
    icon: 'theme',
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_DESIGNER,
    ],
    exact: true,
    component: Themes,
  },
  theme: {
    key: 'theme',
    label: 'Theme',
    path: themePath,
    icon: 'theme',
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_DESIGNER,
    ],
    exact: true,
    component: Theme,
  },
  // Groups
  groups: {
    key: 'groups',
    label: 'Groups',
    path: groupsPath,
    icon: 'group',
    accessibleBy: [UserRoles.DATA_VIEWER, UserRoles.BACK_OFFICE],
    exact: true,
    component: Groups,
  },
  group: {
    key: 'group',
    label: 'Group',
    path: groupPath,
    accessibleBy: [UserRoles.DATA_VIEWER, UserRoles.BACK_OFFICE],
    exact: true,
    component: Group,
  },
  // Stores
  stores: {
    key: 'stores',
    label: 'Stores',
    path: storesPath,
    icon: 'store',
    accessibleBy: [],
    exact: true,
    component: Stores,
  },
  // TODO: Handle createStore, agreement and leadEditor routes in a nicer way
  createStore: {
    key: 'create-store',
    label: 'Create store',
    path: `${storesPath}/new`,
    accessibleBy: [
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.DISTRIBUTOR,
    ],
    exact: true,
    component: CreateStore,
  },
  leadEditor: {
    key: 'lead-editor',
    label: 'Lead activation',
    path: `${storePath}/leads/:leadId`,
    accessibleBy: [
      UserRoles.BACK_OFFICE,
      UserRoles.OWNER,
      UserRoles.GROUP_MANAGER,
    ],
    exact: true,
    component: LeadEditor,
  },
  agreement: {
    key: 'agreement',
    label: 'Agreement',
    path: `${storePath}/sign`,
    accessibleBy: [
      UserRoles.BACK_OFFICE,
      UserRoles.OWNER,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.GROUP_MANAGER,
    ],
    exact: true,
    component: StoreAgreement,
  },
  store: {
    key: 'store',
    label: 'Store',
    path: storePath,
    accessibleBy: [],
    component: Store,
  },
  // Accounts
  accounts: {
    key: 'accounts',
    label: 'Accounts',
    path: accountsPath,
    icon: 'participants',
    accessibleBy: [UserRoles.DATA_VIEWER, UserRoles.BACK_OFFICE],
    exact: true,
    component: Accounts,
  },
  firstLogin: {
    key: 'first-login',
    label: 'First login',
    path: '/first-login',
    accessibleBy: [],
    exact: true,
    component: FirstLogin,
  },
  user: {
    key: 'user',
    label: 'User',
    path: userPath,
    accessibleBy: [],
    component: User,
  },
  // Prospects
  prospects: {
    key: 'prospects',
    label: 'Prospects',
    path: prospectsPath,
    icon: 'prospects',
    accessibleBy: [
      UserRoles.DATA_VIEWER,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
    ],
    exact: true,
    component: Prospects,
  },
  createProspect: {
    key: 'create-prospect',
    label: 'Create prospect',
    path: `${prospectsPath}/new`,
    accessibleBy: [UserRoles.INTERNAL_SALES, UserRoles.EXTERNAL_SALES],
    exact: true,
    component: CreateProspect,
  },
  prospectAgreement: {
    key: 'prospect-agreement',
    label: 'Agreement',
    path: `${prospectPath}/sign`,
    accessibleBy: [UserRoles.INTERNAL_SALES, UserRoles.EXTERNAL_SALES],
    exact: true,
    component: ProspectAgreement,
  },
  prospectQuote: {
    key: 'quote',
    label: 'Quote',
    path: `${prospectPath}/quote`,
    accessibleBy: [UserRoles.INTERNAL_SALES, UserRoles.EXTERNAL_SALES],
    exact: true,
    component: Quote,
  },
  prospect: {
    key: 'prospect',
    label: 'Prospect',
    path: prospectPath,
    accessibleBy: [UserRoles.INTERNAL_SALES, UserRoles.EXTERNAL_SALES],
    component: Prospect,
  },
  // Stores
  surveys: {
    key: 'surveys',
    label: 'Surveys',
    path: surveyPaths,
    icon: 'assignment',
    accessibleBy: [UserRoles.BACK_OFFICE, UserRoles.INTERNAL_SALES],
    exact: true,
    component: Surveys,
  },
  // No Access
  noAccess: {
    key: 'no-access',
    label: 'No access',
    path: '*',
    accessibleBy: [UserRoles.MANAGER, UserRoles.EMPLOYEE],
    component: null,
  },
};
