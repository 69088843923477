import { FC, useCallback, useEffect, useState } from 'react';
import {
  apply,
  storeModel,
  User,
  userModel,
  UserRoles,
} from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreAccountActions } from '../../../store/actions';
import {
  AuthSelectors,
  StoreAccountSelectors,
  StoreSelectors,
} from '../../../store/selectors';

import { ATOContactRoles } from '../../../enums';
import { create } from '../../../models/atoContactModel';

import { Heading, Text } from '../../../components';
import Contacts from './Components/Contacts';
import Members from './Components/Members';
import DeleteDialog from './Dialogs/DeleteDialog';
import EditMemberDialog from './Dialogs/EditMemberDialog';
import InviteDialog from './Dialogs/InviteDialog';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const ContactHeader = styled(StyledHeading)`
  margin-top: 38px;
`;

export type InviteMemberType = 'owner' | 'member';

interface InviteDialogState {
  open: boolean;
  type: InviteMemberType;
}

const Team: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const store = useSelector(StoreSelectors.selectStore);

  const selectedStoreAccount = useSelector(
    StoreAccountSelectors.selectStoreAccount,
  );
  const updating = useSelector(
    StoreAccountSelectors.selectStoreAccountUpdating,
  );

  const salesRep = create({
    firstName: apply(storeModel.getSalesRepFirstName, store, ''),
    lastName: apply(storeModel.getSalesRepLastName, store, ''),
    role: ATOContactRoles.SALES,
    email: apply(storeModel.getSalesRepEmail, store, ''),
    phoneNumber: apply(storeModel.getSalesRepPhoneNumber, store, ''),
  });

  const userSlots = apply(
    storeModel.getUserSlots,
    store,
    storeModel.getUserSlots(storeModel.defaults),
  );

  const isStoreSigned = apply(
    storeModel.isSigned,
    store,
    storeModel.isSigned(storeModel.defaults),
  );

  const [inviteDialog, setInviteDialog] = useState<InviteDialogState>({
    open: false,
    type: 'member',
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const getTeam = useCallback(async () => {
    try {
      if (store) {
        await dispatch(StoreAccountActions.getStoreAccounts(store)).unwrap();
      }
    } catch {
      toast('Failed to retrieve team members!', {
        type: 'error',
      });
    }
  }, [dispatch, store]);

  const getInvites = useCallback(async () => {
    try {
      if (store) {
        await dispatch(
          StoreAccountActions.getInvites(storeModel.getId(store)),
        ).unwrap();
      }
    } catch {
      toast('Failed to retrieve invites!', {
        type: 'error',
      });
    }
  }, [dispatch, store]);

  useEffect(() => {
    getTeam();
  }, [getTeam]);

  useEffect(() => {
    getInvites();
  }, [getInvites]);

  const openInviteDialog = (type: InviteMemberType) => () =>
    setInviteDialog({ open: true, type: type });
  const closeInviteDialog = () =>
    setInviteDialog({ open: false, type: 'member' });

  const openEditDialog = () => setEditDialogOpen(true);
  const closeEditDialog = () => setEditDialogOpen(false);

  const openDeleteDialog = () => setDeleteDialogOpen(true);
  const closeDeleteDialog = () => setDeleteDialogOpen(false);

  const handleMemberClick = (member: User) => {
    dispatch(StoreAccountActions.setStoreAccount(member));

    if (user && userModel.hasHigherOrEqualRole(member, user)) {
      return openEditDialog();
    }
    toast(`You do not have permission to edit this members information`, {
      type: 'warning',
    });
  };

  const handleInviteClick = () => {
    // TODO: resend invite?
    console.log('onInvitedMemberClick');
  };

  const handleDeleteClick = async () => {
    if (!selectedStoreAccount || !store) {
      return;
    }

    const isRemovingOwner = userModel.isOwner(selectedStoreAccount);

    try {
      if (isRemovingOwner) {
        await dispatch(
          StoreAccountActions.removeStoreOwnerAccount(store.storeId),
        ).unwrap();
      } else {
        await dispatch(
          StoreAccountActions.removeStoreAccount(
            userModel.getId(selectedStoreAccount),
          ),
        ).unwrap();
      }

      toast(`Successfully removed ${isRemovingOwner ? 'owner' : 'member'}!`, {
        type: 'success',
      });

      closeDeleteDialog();

      closeEditDialog();
    } catch (error) {
      const message = `Sorry for the inconvenience! There was a problem removing the ${
        isRemovingOwner ? 'owner' : 'member'
      }. ${(error as Error).message || ''}`;

      toast(message, {
        type: 'error',
      });
    }
  };

  if (!user) return <></>;

  // permissions
  // invite -> admin, owner, back office
  const canInviteMemberSigned = userModel.hasRole(
    [
      UserRoles.ADMIN,
      UserRoles.BACK_OFFICE,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    user,
  );

  const canInviteMemberNotSigned = userModel.hasRole(
    [
      UserRoles.ADMIN,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    user,
  );

  const canInviteMember = isStoreSigned
    ? canInviteMemberSigned
    : canInviteMemberNotSigned;

  const canUpdateMemberSigned = userModel.hasRole(
    [UserRoles.ADMIN, UserRoles.GROUP_MANAGER, UserRoles.OWNER],
    user,
  );

  const canUpdateMemberNotSigned = userModel.hasRole(
    [
      UserRoles.ADMIN,
      UserRoles.BACK_OFFICE,
      UserRoles.INTERNAL_SALES,
      UserRoles.EXTERNAL_SALES,
      UserRoles.GROUP_MANAGER,
      UserRoles.OWNER,
    ],
    user,
  );

  const canUpdateMember = isStoreSigned
    ? canUpdateMemberSigned
    : canUpdateMemberNotSigned;

  return (
    <Wrapper>
      <StyledHeading variant="h1">Team</StyledHeading>
      <Text variant="body2">
        Manage the store team and roles they have in ARIONHUB. Keep in mind that
        slots are limited to {userSlots} members including the owner account.
      </Text>
      <Members
        slots={userSlots}
        onMemberClick={canUpdateMember ? handleMemberClick : undefined}
        onInvitedMemberClick={canInviteMember ? handleInviteClick : undefined}
        onInviteClick={canInviteMember ? openInviteDialog : undefined}
      />
      <ContactHeader variant="h1">Contacts</ContactHeader>
      <Text variant="body2">
        If you have troubles with subscriptions, invoices and payment history of
        the store please contact the following representatives.
      </Text>
      <Contacts
        contacts={[
          salesRep,
          create({
            firstName: 'Support',
            lastName: 'office',
            role: ATOContactRoles.SUPPORT,
            email: 'support@ato-gear.com',
            phoneNumber: '+31 (0)40 2405245',
          }),
        ]}
      />
      {canInviteMember && (
        <InviteDialog
          open={inviteDialog.open}
          type={inviteDialog.type}
          onClose={closeInviteDialog}
          onCancel={closeInviteDialog}
        />
      )}
      {canUpdateMember && (
        <EditMemberDialog
          loading={updating}
          open={editDialogOpen}
          member={selectedStoreAccount}
          isUser={
            selectedStoreAccount
              ? userModel.getId(user) === userModel.getId(selectedStoreAccount)
              : false
          }
          onClose={closeEditDialog}
          onDelete={openDeleteDialog}
        />
      )}
      {canUpdateMember && !!selectedStoreAccount && (
        <DeleteDialog
          loading={updating}
          open={deleteDialogOpen}
          member={selectedStoreAccount}
          onClose={closeDeleteDialog}
          onConfirm={handleDeleteClick}
        />
      )}
    </Wrapper>
  );
};

export default Team;
