import { FC } from 'react';

import { Theme } from '../../../../theme/types';

import { ImagePreview } from '../../../../components';
import { MediaPreview, MediaTile } from '../Components';

interface Props {
  lightTheme?: Theme;
  darkTheme?: Theme;
}

const EmailSection: FC<Props> = ({ lightTheme, darkTheme }) => (
  <MediaTile>
    <MediaPreview
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      mediaKey="emailHeaderURL"
      title="Service email header"
      description="An image displayed in all service emails, digital flyer and lead activations."
      component={ImagePreview}
      componentProps={{
        height: 165,
        width: 475,
      }}
    />
  </MediaTile>
);

export default EmailSection;
