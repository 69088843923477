import { FC, HTMLAttributes, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({ theme }) => `${theme.colors.dark}94`};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
}

const Modal: FC<Props> = ({ open, ...rest }) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return createPortal(<Wrapper role="presentation" {...rest} />, document.body);
};

export default Modal;
