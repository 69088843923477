import { FC, useState } from 'react';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Heading from '../Heading';

const Wrapper = styled.div`
  position: relative;
  width: 9vw;
  min-width: 180px;
  height: calc(100vh - 70px);
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.shadow};
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledDocument = styled(Document)`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
`;

const StyledPage = styled(Page)`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  margin-bottom: 12px;
`;
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
interface Props {
  onPageClick?: (index: number) => void;
  file: Blob | Uint8Array | ArrayBuffer;
}

const DocumentPagePreview: FC<Props> = ({ onPageClick, file, ...rest }) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error: any) {
    console.log(error);
  }

  const handlePageClick = (index: number) => {
    if (!onPageClick) return;

    if (index + 1 === numPages) return onPageClick(index + 0.5);

    onPageClick(index);
  };

  return (
    <Wrapper {...rest}>
      <StyledDocument
        renderMode={'svg'}
        file={file}
        onLoadError={onDocumentLoadError}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <PageWrapper key={`page_${index + 1}`}>
            <StyledPage
              pageNumber={index + 1}
              width={150}
              onClick={() => handlePageClick(index)}
            />
            <Heading variant="h3">{index + 1}</Heading>
          </PageWrapper>
        ))}
      </StyledDocument>
    </Wrapper>
  );
};

export default DocumentPagePreview;
