import { FC, FormEventHandler } from 'react';
import styled from 'styled-components';

import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogText,
} from '../../../../components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 450px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

interface Props extends DialogProps {
  onConfirm: () => void;
}

const CancelDialog: FC<Props> = ({ loading, onClose, onConfirm, ...rest }) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    onConfirm();
  };

  return (
    <Dialog
      loading={loading}
      title="Cancel subscription"
      onClose={onClose}
      {...rest}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <DialogText variant="body2">
            Canceling the subscription will stop the automatic renewal of the
            subscription when it ends. Users will remain to have access to
            ARIONHUB products until the subscription ends.
          </DialogText>
        </Content>
        <DialogActions>
          <CancelButton
            disabled={loading}
            size="small"
            type="button"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </CancelButton>
          <Button
            color="primary"
            disabled={loading}
            size="small"
            type="submit"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default CancelDialog;
