import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreActions } from '../../../store/actions';
import { StoreSelectors } from '../../../store/selectors';

import {
  fields,
  getDownloadLink,
  getFormattedAmount,
  getFormattedCreatedAt,
  getFormattedPaidAt,
  getInvoiceNumber,
  getPaymentLink,
  Invoice,
  isPaid,
} from '../../../models/invoiceModel';

import { invoiceColumns } from '../../../tables/invoiceTable';
import { composeColumns } from '../../../utils/tableUtils';

import { Heading, Icon, Link, Table, Text } from '../../../components';

import { TableCellData } from '../../../components/Table/types';
import { StoreRouteParams } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const PaidCell = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

interface PaidIconProps {
  $paid?: boolean;
}

const PaidIcon = styled(Icon)<PaidIconProps>`
  color: ${(props) =>
    props.$paid ? props.theme.colors.success : props.theme.colors.textTwo};
  margin-right: 8px;
`;

const DownloadIcon = styled(Icon)`
  font-size: 16px;
`;

const HeaderDownloadIcon = styled(DownloadIcon)`
  color: ${(props) => props.theme.colors.white};
`;

const BodyDownloadIcon = styled(DownloadIcon)`
  color: ${(props) => props.theme.colors.textTwo};
`;

const Placeholder = styled.div`
  width: 16px;
`;

const cells: TableCellData<Invoice>[] = [
  {
    key: fields.INVOICE_NR,
    renderBody: (invoice) => (
      <Heading variant="h3">{getInvoiceNumber(invoice)}</Heading>
    ),
  },
  {
    key: fields.CREATED_AT,
    renderBody: (invoice) => (
      <Text variant="body2">{getFormattedCreatedAt(invoice)}</Text>
    ),
  },
  {
    key: fields.PAID_AT,
    renderBody: (invoice) => {
      const paid = isPaid(invoice);

      return (
        <PaidCell>
          <PaidIcon
            $paid={paid}
            name={paid ? 'checkmark-circle' : 'close-circle'}
          />
          {paid ? (
            <Text variant="body2">{getFormattedPaidAt(invoice)}</Text>
          ) : (
            // TODO: Set payment link in invoices
            <Link href={getPaymentLink(invoice)}>Make payment</Link>
          )}
        </PaidCell>
      );
    },
  },
  {
    key: fields.AMOUNT,
    renderBody: (invoice) => (
      <Text variant="body2">{getFormattedAmount(invoice)}</Text>
    ),
  },
  {
    key: fields.DOWNLOAD_LINK,
    renderBody: (invoice) =>
      isPaid(invoice) ? (
        <Link href={getDownloadLink(invoice)}>
          <BodyDownloadIcon name="download" />
        </Link>
      ) : (
        <Placeholder />
      ),
  },
];

const downloadColumn = invoiceColumns.find(
  ({ key }) => key === fields.DOWNLOAD_LINK,
);

if (downloadColumn) {
  downloadColumn.label = <HeaderDownloadIcon name="download" />;
}

const columns = composeColumns(invoiceColumns, cells);

const Invoices: FC = () => {
  const dispatch = useDispatch();

  const invoices = useSelector(StoreSelectors.selectInvoices);
  const fetching = useSelector(StoreSelectors.selectInvoicesFetching);

  const { storeId } = useParams<StoreRouteParams>();

  useEffect(() => {
    if (storeId) {
      dispatch(StoreActions.getInvoices(storeId));
    }
  }, [dispatch, storeId]);

  // TODO: Implement
  const handleInvoiceClick = () => console.log('clicked invoice...');

  return (
    <Wrapper>
      <Info>
        <ContentHeading variant="h1">Invoices</ContentHeading>
        <Text variant="body2">
          The subscription has to be paid up for the next billing cycle and the
          team will be informed if there are outstanding invoices. Make sure
          that invoice information for the store is accessible to the team
          members with certain permissions.
        </Text>
      </Info>
      <Table
        columns={columns}
        data={invoices}
        loading={fetching}
        initialSortBy={{
          key: fields.CREATED_AT,
          desc: true,
        }}
        onRowClick={handleInvoiceClick}
      />
    </Wrapper>
  );
};

export default Invoices;
