import {
  formatUrl,
  isNilOrEmpty,
  isNilOrWhitespace,
} from '@atogear/arion-utils';
import { clone, mergeRight } from 'ramda';

import { PromotionItem } from './promotionItemModel';

export interface Promotion {
  id?: string;
  isDefault: boolean;
  imageUrl: string;
  imagePath: string;
  defaultImageUrl: string;
  title: string;
  description: string;
  buttonText: string;
  buttonUrl: string;
  promotions: PromotionItem[];
}

export const fields = {
  ID: 'id',
  IS_DEFAULT: 'isDefault',
  IMAGE_URL: 'imageUrl',
  IMAGE_PATH: 'imagePath',
  DEFAULT_IMAGE_URL: 'defaultImageUrl',
  TITLE: 'title',
  DESCRIPTION: 'description',
  BUTTON_TEXT: 'buttonText',
  BUTTON_URL: 'buttonUrl',
  PROMOTIONS: 'promotions',
};

export const defaults: Promotion = {
  id: '',
  isDefault: false,
  imageUrl: '',
  imagePath: '',
  defaultImageUrl: 'defaultImageUrl',
  title: 'Promotion title',
  description: 'Promotion description',
  buttonText: '',
  buttonUrl: '',
  promotions: [],
};

export const getId = (promotion?: Promotion) => (promotion || defaults).id;

export const getIsDefault = (promotion?: Promotion) =>
  (promotion || defaults).isDefault;

export const setIsDefault = (value: boolean, promotion?: Promotion) =>
  mergeRight(promotion || defaults, {
    isDefault: value,
  });

export const getImageUrl = (promotion?: Promotion) =>
  (promotion || defaults).imageUrl;

export const setImageUrl = (imageUrl: string, promotion?: Promotion) =>
  mergeRight(promotion || defaults, {
    imageUrl,
  });

export const getImagePath = (promotion?: Promotion) =>
  (promotion || defaults).imageUrl;

export const setImagePath = (imagePath: string, promotion?: Promotion) =>
  mergeRight(promotion || defaults, {
    imagePath,
  });

export const setMedia = (
  imageUrl: string,
  imagePath: string,
  promotion?: Promotion,
) =>
  mergeRight(promotion || defaults, {
    imageUrl,
    imagePath,
  });

export const getDefaultImageUrl = (promotion?: Promotion) =>
  (promotion || defaults).defaultImageUrl;

export const setDefaultImageUrl = (
  defaultImageUrl: string,
  promotion?: Promotion,
) =>
  mergeRight(promotion || defaults, {
    defaultImageUrl,
  });

export const getTitle = (promotion?: Promotion) =>
  (promotion || defaults).title;

export const setTitle = (title: string, promotion?: Promotion) =>
  mergeRight(promotion || defaults, {
    title,
  });

export const getDescription = (promotion?: Promotion) =>
  (promotion || defaults).description;

export const setDescription = (description: string, promotion?: Promotion) =>
  mergeRight(promotion || defaults, {
    description,
  });

export const getButtonText = (promotion?: Promotion) =>
  (promotion || defaults).buttonText;

export const setButtonText = (buttonText: string, promotion?: Promotion) =>
  mergeRight(promotion || defaults, {
    buttonText,
  });

export const getButtonUrl = (promotion?: Promotion) =>
  (promotion || defaults).buttonUrl;

export const setButtonUrl = (buttonUrl: string, promotion?: Promotion) =>
  mergeRight(promotion || defaults, {
    buttonUrl,
  });

export const getFormattedButtonUrl = (promotion?: Promotion) => {
  const url = getButtonUrl(promotion);

  return !isNilOrWhitespace(url) ? formatUrl(url) : url;
};

export const hasPromotionTile = (promotion?: Promotion) =>
  !isNilOrEmpty(getImageUrl(promotion)) &&
  !isNilOrWhitespace(getTitle(promotion)) &&
  !isNilOrWhitespace(getDescription(promotion));

export const hasPromotionButton = (promotion?: Promotion) =>
  !isNilOrWhitespace(getButtonText(promotion)) &&
  !isNilOrWhitespace(getButtonUrl(promotion));

export const getPromotionItems = (promotion?: Promotion) =>
  (promotion || defaults).promotions;

export const hasPromotionItems = (promotion?: Promotion) =>
  !isNilOrEmpty(getPromotionItems(promotion));

export const setPromotionItems = (
  promotionItems: PromotionItem[],
  promotion?: Promotion,
) =>
  mergeRight(promotion || defaults, {
    promotions: promotionItems,
  });

export const addPromotionItem = (
  promotionItem: PromotionItem,
  promotion?: Promotion,
) => {
  const newItems = [...getPromotionItems(promotion)];

  newItems.push(promotionItem);

  return mergeRight(promotion || defaults, {
    promotions: newItems,
  });
};

export const updatePromotionItem = (
  promotionIndex: number,
  promotionItem: PromotionItem,
  promotion?: Promotion,
) => {
  const newItems = [...getPromotionItems(promotion)];

  newItems[promotionIndex] = promotionItem;

  return mergeRight(promotion || defaults, {
    promotions: newItems,
  });
};

export const removePromotionItem = (
  promotionIndex: number,
  promotion?: Promotion,
) => {
  const newItems = [...getPromotionItems(promotion)];

  newItems.splice(promotionIndex, 1);

  return mergeRight(promotion || defaults, {
    promotions: newItems,
  });
};

// Utils
export const create = () => prepare(defaults);

export const prepare = (promotion?: Promotion): Promotion => {
  const promo = clone(promotion || defaults);

  delete promo.id;

  return promo;
};

export const compare = (oldPromo: Promotion, newPromo: Promotion) => {
  const _oldPromo = { ...oldPromo };

  const _newPromo = { ...newPromo };

  // @ts-ignore
  delete _oldPromo.isDefault;

  // @ts-ignore
  delete _newPromo.isDefault;

  return JSON.stringify(_oldPromo) !== JSON.stringify(_newPromo);
};
