import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  display: inline-block;
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  padding: 1px 6px;
  text-transform: uppercase;
  border-radius: ${(props) =>
    props.theme.components.tiles.default.borderRadius}px;
`;

interface Props {
  label: string;
}

const Tag: FC<Props> = ({ label, ...rest }) => (
  <Wrapper {...rest}>{label}</Wrapper>
);

export default Tag;
