import { FC } from 'react';
import { isNilOrWhitespace } from '@atogear/arion-utils';
import styled from 'styled-components';

import {
  fields,
  getDescription,
  getImageUrl,
  getPriceText,
  getRedirectUrl,
  PromotionItem,
  setDescription,
  setImageUrl,
  setPriceText,
  setRedirectUrl,
} from '../../../../models/promotionItemModel';

import { Button, Icon, Input, InputLabel } from '../../../../components';

import {
  getMediaAspectRatio,
  promoMediaOptions,
} from '../../../../utils/promoUtils';
import MediaInput from '../../../Themes/Theme/Editor/Media/MediaInput';

const Container = styled.div`
  display: flex;
  margin-top: 22px;
  margin-bottom: 36px;
`;

const InputContainer = styled.div`
  width: 350px;
  margin-left: 16px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

const ActionContainer = styled.div`
  margin-left: 16px;
  margin-top: 27px;
`;

const RemoveIcon = styled(Icon)`
  margin-right: 8px;
  color: ${(props) => props.theme.colors.danger};
`;

interface Props {
  id: string;
  disabled: boolean;
  promotionItem: PromotionItem;
  onChange: (change: any) => void;
  onError: (change: any) => void;
  onRemove: () => void;
}

const PromotionItemTile: FC<Props> = (props) => {
  const {
    id,
    promotionItem,
    disabled = false,
    onChange,
    onError,
    onRemove,
  } = props;

  const handleValidate = (key: string) => (value: string) => {
    const valid = !isNilOrWhitespace(value);

    onError({ [`${id}-${key}`]: !valid });

    return valid;
  };

  return (
    <Container>
      <div
        style={{
          height: 210,
          width: 210 * getMediaAspectRatio(promoMediaOptions.miniPromo),
        }}
      >
        <MediaInput
          src={getImageUrl(promotionItem)}
          mediaOption={promoMediaOptions.miniPromo}
          InputProps={{
            readOnly: disabled,
          }}
          onFileChange={(file) => onChange(setImageUrl(file, promotionItem))}
          onDelete={() => onChange(setImageUrl('', promotionItem))}
          onRevert={(file) => onChange(setImageUrl(file, promotionItem))}
        />
      </div>
      <InputContainer>
        <Group>
          <StyledInputLabel htmlFor={fields.DESCRIPTION}>
            Tile name
          </StyledInputLabel>
          <Input
            id={fields.DESCRIPTION}
            disabled={disabled}
            placeholder="Tile name"
            value={getDescription(promotionItem)}
            validate={handleValidate(fields.DESCRIPTION)}
            onChange={(e) =>
              onChange(setDescription(e.target.value, promotionItem))
            }
          />
        </Group>
        <Group>
          <StyledInputLabel htmlFor={fields.PRICE_TEXT}>Price</StyledInputLabel>
          <Input
            id={fields.PRICE_TEXT}
            disabled={disabled}
            placeholder="Price"
            value={getPriceText(promotionItem)}
            validate={handleValidate(fields.PRICE_TEXT)}
            onChange={(e) =>
              onChange(setPriceText(e.target.value, promotionItem))
            }
          />
        </Group>
        <Group>
          <StyledInputLabel htmlFor={fields.REDIRECT_URL}>
            Product link address
          </StyledInputLabel>
          <Input
            id={fields.REDIRECT_URL}
            disabled={disabled}
            placeholder="link address"
            value={getRedirectUrl(promotionItem)}
            validate={handleValidate(fields.REDIRECT_URL)}
            onChange={(e) =>
              onChange(setRedirectUrl(e.target.value, promotionItem))
            }
          />
        </Group>
      </InputContainer>

      <ActionContainer>
        {!disabled ? (
          <Button size="small" variant="outlined" onClick={onRemove}>
            <RemoveIcon name="delete" />
            Remove tile
          </Button>
        ) : null}
      </ActionContainer>
    </Container>
  );
};

export default PromotionItemTile;
