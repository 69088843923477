import { FC } from 'react';
import styled from 'styled-components';
import { Icon, Text, Heading } from '../../../components';
import {
  Event,
  getDescription,
  getEventIcon,
  getName,
} from '../../../models/eventModel';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.text};
  margin-right: 4px;
`;

interface Props {
  event: Event;
}

const EventCell: FC<Props> = ({ event }) => {
  const eventIcon = getEventIcon(event);

  // We split the string of the description to reduce the bulk of text in this table cell. Since the data is already available in the other columns
  const description = getDescription(event).split(' by ')[0].split(' on ')[0];

  return (
    <Wrapper>
      <StyledIcon name={eventIcon} />
      <TextContainer>
        <Heading variant="h3">{getName(event)}</Heading>
        <Text variant="body2">{description}</Text>
      </TextContainer>
    </Wrapper>
  );
};

export default EventCell;
