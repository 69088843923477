import { createAction } from '@reduxjs/toolkit';
import { Store } from '@atogear/arion-utils';

export const storePreviewUpdated = createAction<Partial<Store>>(
  'stores/storePreviewUpdated',
);

export const storePreviewReset = createAction('stores/storePreviewReset');

export const reset = createAction('stores/reset');
