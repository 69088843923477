import { FC } from 'react';
import styled from 'styled-components';
import { Heading, MouseTooltip } from '../../../../../components';

const Wrapper = styled.div`
  width: 400px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Name = styled(Heading)``;

const Value = styled.div`
  ${(props) => props.theme.typography.body3};
  width: 37px;
  height: 32px;
  border: 1px solid ${(props) => props.theme.colors.grayOpaque};
  background-color: ${(props) => props.theme.colors.light};
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  cursor: not-allowed;
`;

interface Props {
  name: string;
  value: string | number | undefined;
}

const ComponentTile: FC<Props> = ({ name, value }) => {
  return (
    <MouseTooltip tooltip="Preview only">
      <Wrapper>
        <Name variant="h3">{name}</Name>
        <Value>{value}</Value>
      </Wrapper>
    </MouseTooltip>
  );
};

export default ComponentTile;
