import {
  RequiredAny,
  validateOptionalString,
  validateRequiredArray,
  validateRequiredNumber,
  validateRequiredObject,
  validateRequiredString,
} from '@atogear/arion-utils';

import { TimeFrameId } from '../types/stats';

import { validateTimeFrameId } from '../utils/apiUtils';

export interface ApiPerformance {
  storeId: string;
  storeName: string;
  storeCountry: string;
  numberOfSessions: number;
  runnerType: {
    rearFoot: number;
    midFoot: number;
    foreFoot: number;
    unknown: number;
  };
  sessionType: {
    running: number;
    walking: number;
    unknown: number;
  };
  cadence: {
    low: number;
    mid: number;
    high: number;
    unknown: number;
  };
  runnerEfficiency: {
    recreational: number;
    experienced: number;
    elite: number;
  };
}

export interface PerformanceData {
  _id: TimeFrameId;
  sessions: ApiPerformance[];
}

// Validation

const validateSessionPerformance = (data: RequiredAny): ApiPerformance => {
  validateRequiredObject(data, 'runnerType');
  validateRequiredObject(data, 'sessionType');
  validateRequiredObject(data, 'cadence');
  validateRequiredObject(data, 'runnerEfficiency');

  const { runnerType, sessionType, cadence, runnerEfficiency } = data;

  return {
    storeId: validateRequiredString(data.storeId, 'storeId'),
    storeName: validateRequiredString(data.storeName, 'storeName'),
    // TODO: Check undefined
    storeCountry:
      validateOptionalString(data.storeCountry, 'storeCountry') || 'unknown',
    numberOfSessions: validateRequiredNumber(
      data.numberOfSessions,
      'numberOfSessions',
    ),
    runnerType: {
      rearFoot: validateRequiredNumber(runnerType['rearFoot'], 'rearFoot'),
      midFoot: validateRequiredNumber(runnerType['midFoot'], 'midFoot'),
      foreFoot: validateRequiredNumber(runnerType['foreFoot'], 'foreFoot'),
      unknown: validateRequiredNumber(runnerType['unknown'], 'unknown'),
    },
    sessionType: {
      running: validateRequiredNumber(sessionType['running'], 'running'),
      walking: validateRequiredNumber(sessionType['walking'], 'walking'),
      unknown: validateRequiredNumber(sessionType['unknown'], 'unknown'),
    },
    cadence: {
      low: validateRequiredNumber(cadence['low'], 'low'),
      mid: validateRequiredNumber(cadence['mid'], 'mid'),
      high: validateRequiredNumber(cadence['high'], 'high'),
      unknown: validateRequiredNumber(cadence['unknown'], 'unknown'),
    },
    runnerEfficiency: {
      recreational: validateRequiredNumber(
        runnerEfficiency['recreational'],
        'recreational',
      ),
      experienced: validateRequiredNumber(
        runnerEfficiency['experienced'],
        'experienced',
      ),
      elite: validateRequiredNumber(runnerEfficiency['elite'], 'elite'),
    },
  };
};

// Serialization
export const serialize = (data: RequiredAny): PerformanceData => {
  validateRequiredObject(data, 'data');

  return {
    _id: validateTimeFrameId(data._id),
    sessions: validateRequiredArray(data.sessions, 'sessions').map(
      validateSessionPerformance,
    ),
  };
};
