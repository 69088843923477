import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 32px;
`;

interface Props {
  children?: ReactNode;
}

const DialogActions: FC<Props> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default DialogActions;
