import { FC } from 'react';
import styled from 'styled-components';
import { Collapsible, Heading, Text } from '../../../../../components';
import { ColorsType, TypographyType } from '../../../../../theme/types';
import AdvancedFontTile from './AdvancedFontTile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCollapsible = styled(Collapsible)`
  box-shadow: none;
  padding: 16px 0px;
`;

const StyledHeading = styled(Heading)`
  margin: 16px 0px;
`;
interface Props {
  colors: ColorsType;
  typography: TypographyType;
}

const AdvancedFonts: FC<Props> = ({ typography, colors, ...rest }) => {
  return (
    <StyledCollapsible anchorHeader="bottom">
      <Text variant="body2">Advanced fonts</Text>
      <Wrapper {...rest}>
        <StyledHeading variant="h3">Advanced Font Styles</StyledHeading>
        <AdvancedFontTile
          name="Heading 1"
          typography={typography.h1}
          colors={colors}
        />
        <AdvancedFontTile
          name="Heading 2"
          typography={typography.h2}
          colors={colors}
        />
        <AdvancedFontTile
          name="Heading 3"
          typography={typography.h3}
          colors={colors}
        />
        <AdvancedFontTile
          name="Heading 4"
          typography={typography.h4}
          colors={colors}
        />
        <AdvancedFontTile
          name="Body 1"
          typography={typography.body1}
          colors={colors}
        />
        <AdvancedFontTile
          name="Body 2"
          typography={typography.body2}
          colors={colors}
        />
        <AdvancedFontTile
          name="Body 3"
          typography={typography.body3}
          colors={colors}
        />
        <AdvancedFontTile
          name="Caption"
          typography={typography.caption}
          colors={colors}
        />
      </Wrapper>
    </StyledCollapsible>
  );
};

export default AdvancedFonts;
