import * as syncActions from './actions';
import * as thunks from './thunks';
import reducer from './reducer';
import * as selectors from './selectors';

const actions = {
  ...syncActions,
  ...thunks,
};

export { actions, reducer, selectors };
