import { forwardRef } from 'react';
import styled from 'styled-components';

import Input, { InputProps } from '../Input';

const StyledInput = styled(Input)`
  & input {
    color: ${({ theme }) => theme.colors.white};
    color-scheme: dark;
  }
`;

const DialogInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <StyledInput ref={ref} {...props} />
));

export default DialogInput;
