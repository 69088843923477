import { FC } from 'react';
import styled from 'styled-components';
import { Icon, Link, Text } from '../../../components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 32px;
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.text};
  margin-right: 4px;
`;

interface Props {
  icon?: string;
  handle?: string;
  url?: string;
}

const Social: FC<Props> = ({ icon, handle, url }) => (
  <Wrapper>
    {!!icon && <StyledIcon name={icon} />}
    {handle ? (
      <Link href={url}>@{handle}</Link>
    ) : (
      <Text variant="body2">-</Text>
    )}
  </Wrapper>
);

export default Social;
