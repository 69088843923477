import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { User, userModel } from '@atogear/arion-utils';

import { Invite } from '../../models/inviteModel';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import {
  reset,
  resetInvite,
  resetStoreAccount,
  setInvite,
  setStoreAccount,
} from './actions';
import {
  getInvites,
  getStoreAccount,
  getStoreAccounts,
  removeStoreAccount,
  removeStoreOwnerAccount,
  updateStoreAccount,
} from './thunks';

interface State {
  storeAccounts: {
    data?: User[];
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  storeAccount: {
    data?: User;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
  invites: {
    data?: Invite[];
    fetching: boolean;
    error?: SerializedError;
  };
  invite: {
    data?: Invite;
    fetching: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  storeAccounts: {
    data: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
  storeAccount: {
    data: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
  invites: {
    data: undefined,
    fetching: false,
    error: undefined,
  },
  invite: {
    data: undefined,
    fetching: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Store Accounts
    .addCase(getStoreAccounts.pending, setFetchingState('storeAccounts'))
    .addCase(getStoreAccounts.fulfilled, (state, { payload }) => {
      state.storeAccounts.data = payload;
      setFulfilledState('storeAccounts')(state);
    })
    .addCase(getStoreAccounts.rejected, setRejectedState('storeAccounts'))
    // Get Store Account
    .addCase(getStoreAccount.pending, setFetchingState('storeAccount'))
    .addCase(getStoreAccount.fulfilled, (state, { payload }) => {
      state.storeAccount.data = payload;
      setFulfilledState('storeAccount')(state);
    })
    // Set Store Account
    .addCase(setStoreAccount, (state, { payload }) => {
      state.storeAccount.data = payload;
      setFulfilledState('storeAccount')(state);
    })
    // Update Store Account
    .addCase(updateStoreAccount.pending, setUpdatingState('storeAccount'))
    .addCase(updateStoreAccount.fulfilled, (state, action) => {
      state.storeAccount.data = action.payload;

      if (state.storeAccounts.data) {
        const itemIndex = state.storeAccounts.data.findIndex(
          (user) => userModel.getId(user) === userModel.getId(action.payload),
        );

        if (itemIndex > -1) {
          state.storeAccounts.data[itemIndex] = {
            ...state.storeAccounts.data[itemIndex],
            ...action.payload,
          };
        }
      }

      setFulfilledState('storeAccount')(state);
    })
    .addCase(updateStoreAccount.rejected, setRejectedState('storeAccount'))
    // Remove Store Account
    .addCase(removeStoreAccount.pending, setUpdatingState('storeAccount'))
    .addCase(removeStoreAccount.fulfilled, (state, action) => {
      state.storeAccount.data = undefined;

      if (state.storeAccounts.data) {
        const itemIndex = state.storeAccounts.data.findIndex(
          (user) => userModel.getId(user) === action.payload.id,
        );

        if (itemIndex > -1) {
          state.storeAccounts.data.splice(itemIndex, 1);
        }
      }

      setFulfilledState('storeAccount')(state);
    })
    .addCase(removeStoreAccount.rejected, setRejectedState('storeAccount'))
    // remove store owner
    .addCase(removeStoreOwnerAccount.pending, setUpdatingState('storeAccount'))
    .addCase(removeStoreOwnerAccount.fulfilled, (state, { payload }) => {
      state.storeAccount.data = undefined;

      if (state.storeAccounts.data) {
        const itemIndex = state.storeAccounts.data.findIndex(
          (user) => userModel.getId(user) === payload.userId,
        );

        if (itemIndex > -1) {
          state.storeAccounts.data.splice(itemIndex, 1);
        }
      }

      setFulfilledState('storeAccount')(state);
    })
    .addCase(removeStoreOwnerAccount.rejected, setRejectedState('storeAccount'))
    // Get Invites
    .addCase(getInvites.pending, setFetchingState('invites'))
    .addCase(getInvites.fulfilled, (state, { payload }) => {
      state.invites.data = payload;
      setFulfilledState('invites')(state);
    })
    .addCase(getInvites.rejected, setRejectedState('invites'))
    .addCase(setInvite, (state, { payload }) => {
      state.invite.data = payload;
      setFulfilledState('invite')(state);
    })
    // Reset
    .addCase(resetStoreAccount, (state) => {
      state.storeAccount = initialState.storeAccount;
    })
    .addCase(resetInvite, (state) => {
      state.invite = initialState.invite;
    })
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
