import { FC, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import Heading from './Heading';

interface CountProps {
  type?: 'primary' | 'secondary';
}
const Wrapper = styled.div<CountProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 8px;
  min-width: 32px;
  background-color: ${({ type, theme }) =>
    type === 'secondary' ? theme.colors.grayOpaque : theme.colors.primary};
`;

const Text = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  type?: 'primary' | 'secondary';
}

const Tag: FC<Props> = ({ type, children, ...rest }) => (
  <Wrapper type={type} {...rest}>
    <Text variant="h2">{children}</Text>
  </Wrapper>
);

export default Tag;
