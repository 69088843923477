import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

export type Variant = 'h1' | 'h2' | 'h3' | 'h4';

interface WrapperProps {
  $variant: Variant;
}

const Wrapper = styled.p<WrapperProps>`
  ${({ $variant, theme }) => theme.typography[$variant]};
`;

export interface HeadingProps extends HTMLAttributes<HTMLSpanElement> {
  variant?: Variant;
}

const Heading: FC<HeadingProps> = ({ variant, ...rest }) => (
  <Wrapper $variant={variant || 'h4'} {...rest} />
);

export default Heading;
