import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSettingsService } from '../../services';
import { AppSettings } from '../../models/appSettingsModel';

export const getAppSettings = createAsyncThunk(
  'settings/getAppSettings',
  /**
   * Get App Settings.
   *
   * @returns Promise that resolves to App Settings.
   */
  async (): Promise<AppSettings> => {
    const appSettings = await getSettingsService().getAppSettings();

    return appSettings;
  },
);
