import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import * as reducers from './reducers';

const sentryReduxEnhancer = createReduxEnhancer({
  // TODO: Add options to ignore sensitive data in actions and/or state
});

const store = configureStore({
  reducer: combineReducers(reducers),
  enhancers: [sentryReduxEnhancer],
});

export default store;
