import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { storeModel } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch } from '../../../store';
import { EventActions } from '../../../store/actions';
import { EventSelectors, StoreSelectors } from '../../../store/selectors';

import {
  Event,
  fields,
  getFormattedDateString,
  getUserName,
  getEventGroup,
} from '../../../models/eventModel';

import { activityColumns } from '../../../tables/activityTable';
import { composeColumns } from '../../../utils/tableUtils';

import { Heading, Table, Text } from '../../../components';
import { TableCellData } from '../../../components/Table/types';
import EventCell from './EventCell';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const cells: TableCellData<Event>[] = [
  {
    key: fields.ID,
    renderBody: (event) => <EventCell event={event} />,
  },
  {
    key: fields.TYPE,
    renderBody: (event) => <Text variant="body2">{getEventGroup(event)}</Text>,
  },
  {
    key: fields.DATE,
    renderBody: (event) => (
      <Text variant="body2">{getFormattedDateString(event)}</Text>
    ),
  },
  {
    key: fields.USER_NAME,
    renderBody: (event) => <Text variant="body2">{getUserName(event)}</Text>,
  },
];

const columns = composeColumns(activityColumns, cells);

const Activity: FC = () => {
  const dispatch = useDispatch();

  const store = useSelector(StoreSelectors.selectStore);
  const events = useSelector(EventSelectors.selectEvents);
  const fetching = useSelector(EventSelectors.selectEventsFetching);

  const getEvents = useCallback(async () => {
    try {
      if (store) {
        await dispatch(
          EventActions.getEvents({ parentId: storeModel.getId(store) }),
        ).unwrap();
      }
    } catch {
      toast('Failed to retrieve recent activity!', {
        type: 'error',
      });
    }
  }, [dispatch, store]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Recent activity</ContentHeading>
          <Text variant="body2">
            Track changes made to your store, subscription or payments.
          </Text>
        </Info>
      </Header>
      <Content>
        <Table
          columns={columns}
          data={events}
          loading={fetching}
          initialSortBy={{
            key: fields.DATE,
            desc: true,
          }}
        />
      </Content>
    </Wrapper>
  );
};

export default Activity;
