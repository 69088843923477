import { FC } from 'react';
import styled from 'styled-components';

import {
  ATOContact,
  getEmail,
  getFormattedRole,
  getFullName,
  getPhoneNumber,
  isSales,
} from '../../../../models/atoContactModel';

import { Heading, Icon, Skeleton, Text } from '../../../../components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  cursor: pointer;
  padding: 24px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  margin-right: 16px;
  min-width: 240px;
  transition: background-color 0.1s ease-in-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  :active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const RoleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RoleIcon = styled(Icon)`
  font-size: 16px;
  margin-right: 8px;

  ::before {
    color: ${({ theme }) => theme.colors.dark};
  }
`;

const ContactName = styled.div`
  margin-top: 16px;
`;

const ContactEmail = styled.div`
  margin-top: 4px;
`;

const ContactPhone = styled.div`
  margin-top: 4px;
`;

interface Props {
  contact?: ATOContact;
}

const ContactTile: FC<Props> = ({ contact }) => {
  if (!contact) {
    return (
      <Container>
        <RoleContainer>
          <Skeleton height={20} width={64} />
        </RoleContainer>
        <ContactName>
          <Skeleton height={20} width={120} />
        </ContactName>
        <ContactEmail>
          <Skeleton height={20} width={180} />
        </ContactEmail>
        <ContactEmail>
          <Skeleton height={20} width={180} />
        </ContactEmail>
      </Container>
    );
  }

  const handleClick = () => {
    window.location.href = `mailto:${getEmail(contact)}`;
  };

  return (
    <Container onClick={handleClick}>
      <RoleContainer>
        <RoleIcon name={isSales(contact) ? 'sales' : 'question-circle'} />
        <Text variant="body2">{getFormattedRole(contact)}</Text>
      </RoleContainer>
      <ContactName>
        <Heading variant="h3">{getFullName(contact)}</Heading>
      </ContactName>
      <ContactEmail>
        <Text variant="body2">{getEmail(contact)}</Text>
      </ContactEmail>
      <ContactPhone>
        <Text variant="body2">{getPhoneNumber(contact)}</Text>
      </ContactPhone>
    </Container>
  );
};

export default ContactTile;
