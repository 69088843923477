import { FC, useState } from 'react';
import styled from 'styled-components';

import { useSelector } from '../../store';
import { SettingsSelectors } from '../../store/selectors';

import {
  getWarningMessage,
  hasWarningMessage,
} from '../../models/appSettingsModel';

import { alphaToHex } from '../../utils/colorUtils';

import { Heading, Icon } from '../../components';

interface WrapperProps {
  $visible?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${'#FFCF4A' + alphaToHex(0.75)};
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${({ $visible }) => ($visible ? '40px' : '0')};
  transition: height 0.2s ease;
  overflow: hidden;
  z-index: 5;
`;

const Message = styled(Heading)`
  margin-left: 50px;
`;

const CloseIcon = styled(Icon)`
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  font-size: 12px;
  margin-left: 24px;
  padding: 14px;
`;

const Banner: FC = () => {
  const appSettings = useSelector(SettingsSelectors.selectAppSettings);

  const [visible, setVisible] = useState(true);

  const handleClick = () => setVisible(false);

  return hasWarningMessage(appSettings) ? (
    <Wrapper $visible={visible}>
      <Message variant="h3">{getWarningMessage(appSettings)}</Message>
      <CloseIcon name="close" onClick={handleClick} />
    </Wrapper>
  ) : null;
};

export default Banner;
