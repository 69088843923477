import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import { apply, formatDate, roundTo, StoreModule } from '@atogear/arion-utils';
import { addDays } from 'date-fns';
import styled from 'styled-components';

import { useSelector } from '../../../store';
import { SettingsSelectors } from '../../../store/selectors';

import { formatYears } from '../../../utils/formatters';

import {
  DialogHeading,
  DialogInput,
  DialogText,
  Icon,
  Text,
} from '../../../components';

import { prospectModel } from '../../../models';
import { getBaseMonthlySubPrice } from '../../../models/appSettingsModel';

import { translatedModules } from '../../../translations';
import { Prospect } from '../../../models/prospectModel';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 0 32px 24px;
`;

const StyledDialogHeading = styled(DialogHeading)`
  margin-bottom: 16px;
`;

interface GroupProps {
  $row?: boolean;
  $marginTop?: boolean;
}

const Group = styled.div<GroupProps>`
  display: flex;
  flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
  justify-content: ${({ $row }) => ($row ? 'space-between' : 'center')};
  margin-top: ${({ $marginTop }) => ($marginTop ? 32 : 16)}px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledText = styled(DialogText)`
  margin-bottom: 16px;
`;

const ActiveText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const EditIcon = styled(Icon)`
  margin-right: 8px;
`;

const StyledDialogInput = styled(DialogInput)`
  width: 100px;
`;

const Divider = styled.hr`
  border-color: ${({ theme }) => theme.colors.grayOpaque};
  margin-top: 16px;
`;

interface Props {
  prospect: Prospect;
  onChange: (price: number) => void;
}

const PaymentDialogSummary: FC<Props> = ({ prospect, onChange }) => {
  const appSettings = useSelector(SettingsSelectors.selectAppSettings);

  const [perCycle, setPerCycle] = useState(0);
  const [total, setTotal] = useState(0);

  const [editing, setEditing] = useState(false);

  const activeModules = useMemo(() => {
    const modules = prospectModel.getModules(prospect);

    return Object.keys(modules)
      .filter((key) => modules[key as StoreModule])
      .sort() as StoreModule[];
  }, [prospect]);

  const {
    monthly,
    subPeriod,
    subStartDate,
    subEndDate,
    billingName,
    billingEmail,
    billingPaymentTerm,
  } = {
    monthly: apply(prospectModel.isMonthly, prospect, true),
    subPeriod: apply(prospectModel.getSubscriptionPeriod, prospect, 2),
    subStartDate: apply(
      prospectModel.getSubscriptionStartDate,
      prospect,
      Date.now(),
    ),
    subEndDate: apply(
      prospectModel.getSubscriptionEndDate,
      prospect,
      Date.now(),
    ),
    billingName: apply(prospectModel.getBillingName, prospect, ''),
    billingEmail: apply(prospectModel.getBillingEmail, prospect, ''),
    billingPaymentTerm: apply(
      prospectModel.getBillingPaymentTerm,
      prospect,
      30,
    ),
  };

  const formattedSubStartDate = formatDate(subStartDate);
  const formattedSubEndDate = formatDate(subEndDate);
  const formattedSubPeriod = formatYears(subPeriod);

  const paymentDate = useMemo(() => {
    return formatDate(addDays(subStartDate, billingPaymentTerm));
  }, [billingPaymentTerm, subStartDate]);

  const formattedModules = useMemo(() => {
    if (!activeModules.length) {
      return 'None';
    }

    return activeModules.map((key) => translatedModules[key].title).join(', ');
  }, [activeModules]);

  useEffect(() => {
    const { perCycle: startPerCycle, total: startTotal } =
      prospectModel.calcSubscriptionPrice(
        prospect,
        getBaseMonthlySubPrice(appSettings),
      );

    setPerCycle(roundTo(2, startPerCycle));
    setTotal(roundTo(2, startTotal));
  }, [appSettings, prospect]);

  const toggleEditing = () => setEditing((prev) => !prev);

  const handleCyclePriceBlur = () => {
    onChange(monthly ? perCycle : perCycle / 12);
  };

  const handleCyclePriceChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setPerCycle(Math.max(Number.parseFloat(e.target.value), 0));

  const handleTotalPriceBlur = () => onChange(total / (subPeriod * 12));

  const handleTotalPriceChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setTotal(Math.max(Number.parseFloat(e.target.value), 0));

  return (
    <Wrapper>
      <StyledDialogHeading variant="h3">
        {prospectModel.getName(prospect)}
      </StyledDialogHeading>
      <StyledText variant="body2">
        {formattedSubStartDate} - {formattedSubEndDate} ({formattedSubPeriod})
      </StyledText>
      <StyledText variant="body2">{formattedModules}</StyledText>
      <StyledText variant="body2">Billing contact: {billingName}</StyledText>
      <StyledText variant="body2">Billing email: {billingEmail}</StyledText>
      <Group $row $marginTop>
        <DialogHeading variant="h3">Summary</DialogHeading>
        <ActiveText onClick={toggleEditing}>
          {editing ? (
            'Reset'
          ) : (
            <>
              <EditIcon name="edit" />
              Set custom price
            </>
          )}
        </ActiveText>
      </Group>
      <Group $row>
        <StyledText variant="body1">1st payment ({paymentDate})</StyledText>
        <StyledText variant="body1">{perCycle}</StyledText>
      </Group>
      <Group $row>
        <StyledText variant="body1">
          Then every {monthly ? 'month' : 'year'}
        </StyledText>
        {editing ? (
          <StyledDialogInput
            id="monthly-price"
            required
            type="number"
            value={perCycle}
            onBlur={handleCyclePriceBlur}
            onChange={handleCyclePriceChange}
          />
        ) : (
          <StyledText variant="body1">{perCycle}</StyledText>
        )}
      </Group>
      <Divider />
      <Group $row>
        <StyledText variant="body1">Total ({formattedSubPeriod})</StyledText>
        {editing ? (
          <StyledDialogInput
            id="yearly-price"
            required
            type="number"
            value={total}
            onBlur={handleTotalPriceBlur}
            onChange={handleTotalPriceChange}
          />
        ) : (
          <StyledText variant="body1">{total}</StyledText>
        )}
      </Group>
    </Wrapper>
  );
};

export default PaymentDialogSummary;
