import { SessionDemographics, ApiSession } from '../models/apiSessionModel';
import { ApiVisit } from '../models/apiVisitModel';

export const sumSessionToDemographic = (
  prev: SessionDemographics,
  current: ApiSession,
) => ({
  age: {
    '0to20': prev.age['0to20'] + current.demographics.age['0to20'],
    '20to25': prev.age['20to25'] + current.demographics.age['20to25'],
    '25to30': prev.age['25to30'] + current.demographics.age['25to30'],
    '30to35': prev.age['30to35'] + current.demographics.age['30to35'],
    '35to40': prev.age['35to40'] + current.demographics.age['35to40'],
    '40to50': prev.age['40to50'] + current.demographics.age['40to50'],
    '50to100': prev.age['50to100'] + current.demographics.age['50to100'],
    unknown: prev.age.unknown + current.demographics.age.unknown,
  },
  gender: {
    female: prev.gender.female + current.demographics.gender.female,
    male: prev.gender.male + current.demographics.gender.male,
    other: prev.gender.other + current.demographics.gender.other,
    unknown: prev.gender.unknown + current.demographics.gender.unknown,
  },
});

export const sumVisitToDemographic = (
  prev: SessionDemographics,
  current: ApiVisit,
) => ({
  age: {
    '0to20': prev.age['0to20'] + current.demographics.age['0to20'],
    '20to25': prev.age['20to25'] + current.demographics.age['20to25'],
    '25to30': prev.age['25to30'] + current.demographics.age['25to30'],
    '30to35': prev.age['30to35'] + current.demographics.age['30to35'],
    '35to40': prev.age['35to40'] + current.demographics.age['35to40'],
    '40to50': prev.age['40to50'] + current.demographics.age['40to50'],
    '50to100': prev.age['50to100'] + current.demographics.age['50to100'],
    unknown: prev.age.unknown + current.demographics.age.unknown,
  },
  gender: {
    female: prev.gender.female + current.demographics.gender.female,
    male: prev.gender.male + current.demographics.gender.male,
    other: prev.gender.other + current.demographics.gender.other,
    unknown: prev.gender.unknown + current.demographics.gender.unknown,
  },
});

export const defaultDemographics: SessionDemographics = {
  age: {
    '0to20': 0,
    '20to25': 0,
    '25to30': 0,
    '30to35': 0,
    '35to40': 0,
    '40to50': 0,
    '50to100': 0,
    unknown: 0,
  },
  gender: {
    female: 0,
    male: 0,
    other: 0,
    unknown: 0,
  },
};
