import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import {
  initFirebaseAuthPipe,
  initFirebaseDatabasePipe,
  initFirebaseFunctionsPipe,
  initFirebaseStoragePipe,
  initFirestorePipe,
} from '@atogear/ato-broker';
import config from './config';

const initBrokerPipes = async () => {
  const app = await firebase.initializeApp(
    config.firebase,
    config.firebase.projectId,
  );

  const auth = app.auth();
  const firestore = app.firestore();
  const database = app.database();
  const functions = app.functions('europe-west1');
  // Enable the next line if you are using functions running on a local emulator
  // await functions.useEmulator('127.0.0.1', 5001);
  const storage = app.storage();

  const authPipe = initFirebaseAuthPipe(auth);
  const firestorePipe = initFirestorePipe(firestore);
  const functionsPipe = initFirebaseFunctionsPipe(functions);
  const databasePipe = initFirebaseDatabasePipe(database);
  const storagePipe = initFirebaseStoragePipe(storage);

  return {
    authPipe,
    firestorePipe,
    databasePipe,
    functionsPipe,
    storagePipe,
  };
};

export default initBrokerPipes;
