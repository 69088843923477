import { FC } from 'react';
import styled from 'styled-components';
import Select, { SelectProps } from '../Select';

const StyledSelect = styled(Select)`
  & input,
  li,
  span {
    color: ${({ theme }) => theme.colors.white};
  }

  & ul {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

const DialogSelect: FC<SelectProps> = (props) => <StyledSelect {...props} />;

export default DialogSelect;
