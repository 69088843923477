import { createAsyncThunk } from '@reduxjs/toolkit';

import { getContactService } from '../../services';

import { Contact } from '../../models/contactModel';
import { ContactOptions } from '../../services/contacts';

interface GetContactsParams {
  parentId: string;
  options?: ContactOptions;
}

export const getContacts = createAsyncThunk(
  /**
   * Get parent's Contacts.
   *
   * @param params parent id and contact options.
   * @returns Promise that resolves to parent's Contacts.
   */
  'contacts/getContacts',
  async (params: GetContactsParams): Promise<Contact[]> => {
    const { parentId, options } = params;
    const contacts = await getContactService().getContacts(parentId, options);

    return contacts;
  },
);

interface GetContactParams {
  parentId: string;
  contactId: string;
  options?: ContactOptions;
}

export const getContact = createAsyncThunk(
  'contacts/getContact',
  /**
   * Get parent's Contact.
   *
   * @param params parent's id, Contact's id and contact options.
   * @returns Promise that resolves to parent's Contact.
   */
  async (params: GetContactParams): Promise<Contact> => {
    const { parentId, contactId, options } = params;

    const contact = await getContactService().getContact(
      parentId,
      contactId,
      options,
    );

    return contact;
  },
);

interface CreateContactParams {
  parentId: string;
  contact: Contact;
  options?: ContactOptions;
}

export const createContact = createAsyncThunk(
  'contacts/createContact',
  /**
   * Create parent's Contact.
   *
   * @param params parent's Contact's data and contact options.
   * @returns Promise that resolves to created parent's Contact's id.
   */
  async (params: CreateContactParams): Promise<string> => {
    const { parentId, contact, options } = params;

    const contactId = await getContactService().createContact(
      parentId,
      contact,
      options,
    );

    return contactId;
  },
);

interface UpdateContactParams extends GetContactParams {
  contact: Partial<Contact>;
  options?: ContactOptions;
}

export const updateContact = createAsyncThunk(
  'contacts/updateContact',
  /**
   * Update parent's Contact.
   *
   * @param params parent's Contact's data and contact options.
   * @returns Promise that resolves to updated parent's Contact.
   */
  async (params: UpdateContactParams): Promise<Contact> => {
    const { parentId, contact, contactId, options } = params;

    const updatedContact = await getContactService().updateContact(
      parentId,
      contactId,
      contact,
      options,
    );

    return updatedContact;
  },
);

export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  /**
   * Delete parent's Contact.
   *
   * @param params parent's id and Contact's id and contact options.
   * @returns Promise that resolves when parent's Contact has been successfully deleted.
   */
  async (params: GetContactParams): Promise<boolean> => {
    const { parentId, contactId, options } = params;

    const result = await getContactService().deleteContact(
      parentId,
      contactId,
      options,
    );

    return result;
  },
);
