import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLeadService } from '../../services';

import { isReadonly, Lead } from '../../models/leadModel';

export const getLeads = createAsyncThunk(
  'leads/getLeads',
  /**
   * Get Leads.
   *
   * @param storeId Store's id.
   * @returns Promise that resolves to Leads.
   */
  async (storeId: string): Promise<Lead[]> => {
    const leads = await getLeadService().getLeads(storeId);

    return leads;
  },
);

interface GetLeadParams {
  storeId: string;
  leadId: string;
}

export const getLead = createAsyncThunk(
  'leads/getLead',
  /**
   * Get Lead.
   *
   * @param params Store's and Lead's id.
   * @returns Promise that resolves to Lead.
   */
  async (params: GetLeadParams): Promise<Lead> => {
    const { storeId, leadId } = params;

    const lead = await getLeadService().getLead(storeId, leadId);

    return lead;
  },
);

interface CreateLeadParams {
  storeId: string;
  data: Lead;
}

export const createLead = createAsyncThunk(
  'leads/createLead',
  /**
   * Create Lead.
   *
   * @param params Store's id and Lead's data.
   * @returns Promise that resolves to created Lead.
   */
  async (params: CreateLeadParams): Promise<void> => {
    const { storeId, data } = params;

    return getLeadService().createLead(storeId, data);
  },
);

interface UpdateLeadParams {
  storeId: string;
  leadId: string;
  data: Lead;
}

export const updateLead = createAsyncThunk(
  'leads/updateLead',
  /**
   * Update Lead.
   *
   * @param params Store's id and Lead's id and data.
   * @returns Promise that resolves to updated Lead.
   */
  async (params: UpdateLeadParams): Promise<Lead> => {
    const { storeId, leadId, data } = params;

    if (isReadonly(data)) {
      throw new Error('Lead is readonly and can not be updated anymore!');
    }

    return getLeadService().updateLead(storeId, leadId, data);
  },
);

interface DeleteLeadParams {
  storeId: string;
  leadId: string;
}

export const deleteLead = createAsyncThunk(
  'leads/deleteLead',
  /**
   * Delete Lead.
   *
   * @param params Store's and Lead's id.
   * @returns Promise that resolves when Lead has been deleted successfully.
   */
  (params: DeleteLeadParams): Promise<boolean> => {
    const { storeId, leadId } = params;

    return getLeadService().deleteLead(storeId, leadId);
  },
);

export const tryoutLead = createAsyncThunk(
  'leads/tryoutLead',
  /**
   * Tryout Lead.
   *
   * @param data Lead's data.
   * @returns Promise that resolves when Lead tryout has been successful.
   */
  async (data: Lead): Promise<void> => {
    const result = await getLeadService().tryoutLead(data);

    if (!result.data.success) {
      throw result.data.error;
    }
  },
);
