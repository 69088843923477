import { createReducer, SerializedError } from '@reduxjs/toolkit';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import {
  reminderPreviewReset,
  reminderPreviewUpdated,
  reset,
  resetReminder,
} from './actions';
import {
  createReminder,
  deleteReminder,
  getReminder,
  getReminders,
  getUserReminders,
  updateReminder,
} from './thunks';
import { Reminder } from '../../models/reminderModel';
import { reminderModel } from '../../models';

interface State {
  reminders: {
    data: Reminder[];
    fetching: boolean;
    error?: SerializedError;
  };
  reminder: {
    data?: Reminder;
    preview?: Reminder;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  reminders: {
    data: [],
    fetching: false,
    error: undefined,
  },
  reminder: {
    data: undefined,
    preview: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get reminders
    .addCase(getReminders.pending, setFetchingState('reminders'))
    .addCase(getReminders.fulfilled, (state, { payload }) => {
      state.reminders.data = payload;
      setFulfilledState('reminders')(state);
    })
    .addCase(getReminders.rejected, setRejectedState('reminders'))
    // Get user's reminders
    .addCase(getUserReminders.pending, setFetchingState('reminders'))
    .addCase(getUserReminders.fulfilled, (state, { payload }) => {
      state.reminders.data = payload;
      setFulfilledState('reminders')(state);
    })
    .addCase(getUserReminders.rejected, setRejectedState('reminders'))
    // Get reminder
    .addCase(getReminder.pending, setFetchingState('reminder'))
    .addCase(getReminder.fulfilled, (state, { payload }) => {
      state.reminder.data = payload;
      state.reminder.preview = payload;
      setFulfilledState('reminder')(state);
    })
    .addCase(getReminder.rejected, setRejectedState('reminder'))
    // Create reminder
    .addCase(createReminder.pending, setUpdatingState('reminder'))
    .addCase(createReminder.fulfilled, (state, { payload }) => {
      state.reminder.data = payload;
      state.reminders.data.unshift(payload);
      setFulfilledState('reminder')(state);
    })
    .addCase(createReminder.rejected, setRejectedState('reminder'))
    // Update reminder
    .addCase(updateReminder.pending, setUpdatingState('reminder'))
    .addCase(updateReminder.fulfilled, (state, { payload }) => {
      state.reminder.data = payload;

      state.reminder.preview = payload;

      const index = state.reminders.data.findIndex(
        (reminder) => payload.reminderId === reminder.reminderId,
      );

      if (index > -1) {
        state.reminders.data[index] = payload;
      }

      setFulfilledState('reminder')(state);
    })
    .addCase(updateReminder.rejected, setRejectedState('reminder'))
    // Delete reminder
    .addCase(deleteReminder.pending, setUpdatingState('reminder'))
    .addCase(deleteReminder.fulfilled, (state, { payload }) => {
      state.reminder.data = undefined;
      state.reminder.preview = undefined;

      const index = state.reminders.data.findIndex(
        (reminder) => payload === reminder.reminderId,
      );

      if (index > -1) {
        state.reminders.data.splice(index, 1);
      }

      setFulfilledState('reminder')(state);
    })
    .addCase(deleteReminder.rejected, setRejectedState('reminder'))
    // Local
    .addCase(reminderPreviewUpdated, ({ reminder }, { payload }) => {
      reminder.preview = reminderModel.createReminder({
        ...reminder.preview,
        ...payload,
      });
    })
    .addCase(reminderPreviewReset, ({ reminder }) => {
      reminder.preview = undefined;
    })
    // Reset
    .addCase(resetReminder, ({ reminder }) => {
      reminder.data = undefined;
    })
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
