import { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

// TODO: Get from theme?
const AVATAR_COLORS = ['#1DAD77', '#FF6C00', '#8D5764'];

type Size = 'small' | 'normal' | 'large';

const getSize = (size?: Size) => {
  switch (size) {
    case 'small':
      return '24px';
    case 'large':
      return '40px';
    default:
      return '32px';
  }
};

interface WrapperProps {
  $index?: number;
  $size?: Size;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ $index, theme }) => {
    if ($index === undefined) {
      return theme.colors.primary;
    }

    return AVATAR_COLORS[$index % AVATAR_COLORS.length];
  }};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $size }) => getSize($size)};
  width: ${({ $size }) => getSize($size)};
  min-width: ${({ $size }) => getSize($size)};
  padding: 2px;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  index?: number;
  size?: Size;
}

const Avatar = forwardRef<HTMLDivElement, Props>(
  ({ index, size, ...rest }, ref) => (
    <Wrapper ref={ref} $index={index} $size={size} {...rest} />
  ),
);

export default Avatar;
