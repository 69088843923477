import { FC } from 'react';
import styled from 'styled-components';
import Heading, { HeadingProps } from '../Heading';

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
`;

const DialogHeading: FC<HeadingProps> = (props) => <StyledHeading {...props} />;

export default DialogHeading;
