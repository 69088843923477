import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactNode,
  useId,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch } from '../../../store';
import { LeadActions } from '../../../store/actions';

import { LeadTypes } from '../../../enums';
import {
  copy,
  getId,
  getName,
  getType,
  isEnabled,
  isReadonly,
  Lead,
  setEnabled,
} from '../../../models/leadModel';

import { appRoutes, getPath } from '../../../routing';

import { Collapsible, Heading, Icon, Switch, Text } from '../../../components';
import Preview from './LeadEditor/Preview';

import { StoreRouteParams } from '../types';

const SWITCH_WIDTH = 120;

const Wrapper = styled(Collapsible)`
  margin: 8px 0;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 720px;
`;

interface ReadonlyProps {
  $readonly?: boolean;
}

const SwitchContainer = styled.label<ReadonlyProps>`
  cursor: ${({ $readonly }) => ($readonly ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${SWITCH_WIDTH}px;
`;

const SwitchLabel = styled(Heading)<ReadonlyProps>`
  margin-left: 8px;
  opacity: ${({ $readonly }) => ($readonly ? 0.5 : 1)};
`;

const Title = styled(Heading)<ReadonlyProps>`
  margin-right: 24px;
  opacity: ${({ $readonly }) => ($readonly ? 0.5 : 1)};
`;

const Description = styled(Text)<ReadonlyProps>`
  display: flex;
  flex: 1;
  opacity: ${({ $readonly }) => ($readonly ? 0.5 : 1)};
`;

const Actions = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTwo};
  cursor: pointer;
  padding: 0 12px;
`;

const DeleteIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.colors.danger};
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 16px 0;
  margin-left: ${SWITCH_WIDTH}px;
`;

const PreviewContainer = styled.div`
  cursor: pointer;
`;

interface Props {
  lead: Lead;
  children: ReactNode;
}

const LeadListItem: FC<Props> = ({ lead, children }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { storeId } = useParams<StoreRouteParams>();

  const [open, setOpen] = useState(false);

  const leadId = getId(lead);
  const enabled = isEnabled(lead);
  const readonly = isReadonly(lead);

  const canUpdate = getType(lead) !== LeadTypes.INSTANT;

  const id = `${useId()}-enabled`;

  const handleSwitchClick: MouseEventHandler<HTMLLabelElement> = (e) =>
    e.stopPropagation();

  const handleEnabledChange: ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    if (!storeId || !leadId) {
      return;
    }

    await toast.promise(
      dispatch(
        LeadActions.updateLead({
          storeId,
          leadId,
          data: setEnabled(e.target.checked, lead),
        }),
      ).unwrap(),
      {
        pending: 'Updating lead activation...',
        success: 'Lead activation updated successfully!',
        error: 'Failed to update lead activation!',
      },
    );

    await dispatch(LeadActions.getLeads(storeId)).unwrap();
  };

  const handleDeleteClick: MouseEventHandler<HTMLSpanElement> = async (e) => {
    e.stopPropagation();

    if (!storeId || !leadId) {
      return;
    }

    await toast.promise(
      dispatch(
        LeadActions.deleteLead({
          storeId,
          leadId,
        }),
      ).unwrap(),
      {
        pending: 'Deleting lead activation...',
        success: 'Lead activation deleted successfully!',
        error: 'Failed to delete lead activation!',
      },
    );

    await dispatch(LeadActions.getLeads(storeId)).unwrap();
  };

  const handleCopyClick: MouseEventHandler<HTMLSpanElement> = async (e) => {
    e.stopPropagation();

    if (!storeId) {
      return;
    }

    dispatch(LeadActions.leadPreviewUpdated(copy(lead)));

    history.push(
      getPath(appRoutes.leadEditor.path, {
        storeId,
        leadId: 'new',
      }),
    );
  };

  const handleEditClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();

    if (!storeId || !leadId) {
      return;
    }

    dispatch(LeadActions.leadPreviewUpdated(lead));

    history.push(
      getPath(appRoutes.leadEditor.path, {
        storeId,
        leadId,
      }),
    );
  };

  return (
    <Wrapper onStateChange={setOpen}>
      <Header>
        <Info>
          {canUpdate && (
            <SwitchContainer
              $readonly={readonly}
              htmlFor={id}
              onClick={handleSwitchClick}
            >
              <Switch
                componentsProps={{
                  input: {
                    id,
                  },
                }}
                checked={enabled}
                disabled={readonly}
                onChange={handleEnabledChange}
              />
              <SwitchLabel $readonly={readonly} variant="h3">
                {enabled ? 'On' : 'Off'}
              </SwitchLabel>
            </SwitchContainer>
          )}
          <Title $readonly={readonly} variant="h3">
            {getName(lead)}
          </Title>
        </Info>
        <Description $readonly={readonly} variant="body2">
          {children}
        </Description>
        {open && (
          <Actions>
            <DeleteIcon name="delete" onClick={handleDeleteClick} />
            <StyledIcon name="copy" onClick={handleCopyClick} />
            {!readonly && <StyledIcon name="edit" onClick={handleEditClick} />}
          </Actions>
        )}
      </Header>
      <Content>
        <PreviewContainer onClick={handleEditClick}>
          <Preview lead={lead} />
        </PreviewContainer>
      </Content>
    </Wrapper>
  );
};

export default LeadListItem;
