import { FC, HTMLAttributes, ReactNode, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';

import Icon from './Icon';

interface WrapperProps {
  $reversedOrder?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  border-radius: 6px;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow};
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

interface HeaderProps {
  $disabled?: boolean;
}

const Header = styled.div<HeaderProps>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface CollapsibleProps {
  $open?: boolean;
}

const CollapseIcon = styled(Icon)<CollapsibleProps>`
  color: ${({ theme }) => theme.colors.text};
  padding: 8px;
  transform: ${({ $open }) => $open && 'rotate(-180deg)'};
  transition: transform 0.2s ease;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode[];
  disabled?: boolean;
  anchorHeader?: 'top' | 'bottom';
  open?: boolean;
  onStateChange?: (state: boolean) => void;
}

const Collapsible: FC<Props> = ({
  children,
  disabled = false,
  open: initialOpen = false,
  anchorHeader = 'top',
  onStateChange,
  ...rest
}) => {
  const [open, setOpen] = useState(initialOpen);

  const headerOnBottom = anchorHeader === 'bottom';

  const toggleOpen = () => {
    if (disabled) {
      return;
    }

    setOpen((prev) => {
      const newOpen = !prev;

      if (onStateChange) {
        onStateChange(newOpen);
      }

      return newOpen;
    });
  };

  return (
    <Wrapper $reversedOrder={headerOnBottom} {...rest}>
      {!headerOnBottom || !open ? (
        <Header $disabled={disabled} onClick={toggleOpen}>
          {children[0]}
          {!disabled ? <CollapseIcon $open={open} name="chevron-down" /> : null}
        </Header>
      ) : undefined}
      <AnimateHeight duration={250} height={open && !disabled ? 'auto' : 0}>
        {children.slice(1)}
        {headerOnBottom && (
          <Header $disabled={disabled} onClick={toggleOpen}>
            {children[0]}
            {!disabled ? (
              <CollapseIcon $open={open} name="chevron-down" />
            ) : null}
          </Header>
        )}
      </AnimateHeight>
    </Wrapper>
  );
};

export default Collapsible;
