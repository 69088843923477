import {
  Children,
  cloneElement,
  FC,
  HTMLAttributes,
  MouseEventHandler,
  useState,
} from 'react';
import styled from 'styled-components';
import { PopperUnstyled } from '@mui/base';

const Wrapper = styled.div`
  ${(props) => props.theme.typography.body3};
  width: wrap-content;
  height: wrap-content;
  background-color: ${(props) => props.theme.colors.light};
  color: ${(props) => props.theme.colors.dark};
  border: 1px solid ${(props) => props.theme.colors.grayOpaque};
  padding: 3px;
  border-radius: 6px;
  box-shadow: '0px 3px 6px #00000029';
  max-width: 250px;
  pointer-events: none;
`;

const Popper = styled(PopperUnstyled)`
  z-index: 1501;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  placement?: 'top' | 'left' | 'right' | 'bottom';
}

const Tooltip: FC<Props> = (props) => {
  const { children, title, placement = 'bottom', ...rest } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleVisibility: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();

    if (!title) {
      return null;
    }

    const update = event.type === 'mouseenter' ? event.currentTarget : null;

    if (update !== anchorEl) {
      setAnchorEl(update);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {/* Attach the mouse events to the first child */}
      {Children.map(children, (child: any, index) =>
        cloneElement(
          child,
          index === 0
            ? {
                onMouseEnter: handleVisibility,
                onMouseLeave: handleVisibility,
              }
            : {},
        ),
      )}
      {/* The content of the tooltip */}
      <Popper open={open} anchorEl={anchorEl} placement={placement}>
        <Wrapper {...rest}>{title}</Wrapper>
      </Popper>
    </>
  );
};

export default Tooltip;
