import { RootState } from '../types';

export const selectAccounts = (state: RootState) =>
  state.accounts.accounts.data;

export const selectAccountsFetching = (state: RootState) =>
  state.accounts.accounts.fetching;

export const selectAccount = (state: RootState) => state.accounts.account.data;

export const selectAccountFetching = (state: RootState) =>
  state.accounts.account.fetching;
