import { RootState } from '../types';

export const selectStoreAccounts = (state: RootState) =>
  state.storeAccounts.storeAccounts.data;

export const selectStoreAccountsFetching = (state: RootState) =>
  state.storeAccounts.storeAccounts.fetching;

export const selectStoreAccount = (state: RootState) =>
  state.storeAccounts.storeAccount.data;

export const selectStoreAccountUpdating = (state: RootState) =>
  state.storeAccounts.storeAccount.updating;

export const selectInvites = (state: RootState) =>
  state.storeAccounts.invites.data;

export const selectInvitesFetching = (state: RootState) =>
  state.storeAccounts.invites.fetching;
