import {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import useWindowKeyListener from '../../hooks/useWindowKeyListener';

import DialogHeading from './DialogHeading';
import Icon from '../Icon';
import LoadingMask from '../LoadingMask';
import Modal from '../Modal';

const StyledDialog = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  border: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  border-radius: 4px;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow};
  margin: 32px;
`;

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px;
`;

const StyledHeading = styled(DialogHeading)`
  display: flex;
  flex: 1;
`;

const CloseIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export interface DialogProps extends HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
  open?: boolean;
  title?: string;
  onClose?: () => void;
}

const Dialog: FC<DialogProps> = ({
  children,
  loading,
  open,
  title,
  onClose,
  ...rest
}) => {
  const [downClickEl, setDownClickEl] = useState<EventTarget | null>(null);

  useEffect(() => {
    if (open === true) {
      setDownClickEl(null);
    }
  }, [open]);

  useWindowKeyListener('Escape', onClose);

  const handleDialogClick: MouseEventHandler<HTMLDivElement> = (e) =>
    e.stopPropagation();

  return (
    <Modal
      open={open}
      onMouseDown={(e) => setDownClickEl(e.target)}
      onClick={(e) => (downClickEl === e.target && onClose ? onClose() : null)}
    >
      <StyledDialog onClick={handleDialogClick} {...rest}>
        <DialogHeader>
          {!!title && <StyledHeading variant="h3">{title}</StyledHeading>}
          {onClose && <CloseIcon name="close" onClick={onClose} />}
        </DialogHeader>
        {children}
        <LoadingMask loading={loading} />
      </StyledDialog>
    </Modal>
  );
};

export default Dialog;
