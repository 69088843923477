import { FC, useRef, useState } from 'react';
import { storeModel } from '@atogear/arion-utils';
import { ParseStepResult } from 'papaparse';
import { isEmpty, isNil } from 'ramda';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ShoeActions } from '../../../store/actions';
import { StoreSelectors } from '../../../store/selectors';

import { UploadedShoe } from '../../../models/shoeModel';

import {
  ParserError,
  parseShoeCsvToJson,
  readFile,
} from '../../../utils/csvUtils';
import { formatFileSize } from '../../../utils/formatters';

import { Button, Heading, Icon, Loader, Text } from '../../../components';

const Wrapper = styled.div`
  margin-top: 16px;
`;

const StyledText = styled(Text)`
  margin-right: 32px;
`;

const CSVIcon = styled(Icon)`
  margin-right: 8px;
`;

interface UploadContainerProps {
  $over: boolean;
}

const UploadContainer = styled.div<UploadContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${(props) => props.theme.colors.grayOpaque};
  padding: 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
  background-color: ${(props) =>
    props.$over ? props.theme.colors.highlight : 'unset'};
  &:hover {
    background-color: ${(props) => props.theme.colors.highlight};
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 18px;
  margin-right: 8px;
  margin-top: -2px;
  ::before {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const ParserContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
`;

const ParserContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const ParserActions = styled.div`
  display: flex;
  align-items: center;
`;

interface ParserStopButtonProps {
  $visible: boolean;
}

const ParserStopButton = styled(Icon)<ParserStopButtonProps>`
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  color: ${(props) => props.theme.colors.danger};
  cursor: pointer;
  margin-left: 12px;
`;

interface ProgressProps {
  $error: boolean;
  $success: boolean;
}

const ParserProgress = styled.div<ProgressProps>`
  position: absolute;
  height: 8px;
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.highlight};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: width 0.1s ease;
  background-color: ${(props) =>
    props.$error
      ? props.theme.colors.danger
      : props.$success
      ? props.theme.colors.success
      : props.theme.colors.primary};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: ${(props) => (props.$success ? 4 : 0)}px;
`;

const ParserProgressPlaceholder = styled.div`
  height: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.highlight};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 12px;
`;

const CancelButton = styled(Button)`
  margin-right: 8px;
`;

const StyledLoader = styled(Loader)`
  border-top-color: ${(props) => props.theme.colors.textTwo};
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

const StyledInput = styled.input`
  display: none;
`;

const ErrorsContainer = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.grayOpaque};
  padding: 16px;
  margin-top: 32px;
`;

const Error = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ErrorLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 110px;
`;

const ErrorIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.danger};
  margin-right: 4px;
`;

const ErrorText = styled(Text)`
  width: calc(50% - 55px);
  padding-right: 4px;
  padding-left: 4px;
`;

const Upload: FC = () => {
  const dispatch = useDispatch();

  const store = useSelector(StoreSelectors.selectStore);

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [json, setJSON] = useState<UploadedShoe[] | null>(null);
  const [uploading, setUploading] = useState(false);

  const abortParserRef = useRef<any | null>(null);
  const [stats, setStats] = useState({
    progress: 0,
    total: 0,
    current: 0,
  });

  const done = stats.progress === 100;

  const [errors, setErrors] = useState<ParserError[]>([]);
  const [error, setError] = useState(false);
  const [hover, setHover] = useState(false);

  const openFileExplorer = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const reset = () => {
    setFile(null);

    setJSON(null);

    setErrors([]);

    setStats({
      progress: 0,
      total: 0,
      current: 0,
    });

    if (abortParserRef.current) {
      abortParserRef.current();
    }

    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  const handleChange = async (files: FileList | null) => {
    if (isNil(files) || isEmpty(files)) {
      return;
    }

    const file = files[0];

    const acceptedFileTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
    ];

    if (!acceptedFileTypes.includes(file.type)) {
      toast('File must be an .xls, .xlsx or .csv file', {
        type: 'error',
      });

      reset();

      return;
    }

    setError(false);

    setFile(file);

    const content = await readFile(file);

    if (!content) {
      toast('Could not read the file.', {
        type: 'error',
      });

      return;
    }

    try {
      const { json, stats: resultStats } = await parseShoeCsvToJson(
        content,
        (
          _row: ParseStepResult<Partial<UploadedShoe>>,
          cancel,
          stats: {
            progress: number;
            total: number;
            current: number;
          },
        ) => {
          if (!abortParserRef.current) {
            abortParserRef.current = cancel;
          }
          console.log(stats);
          setStats(stats);
        },
        (error) => setErrors((prev) => [...prev, error]),
      );

      abortParserRef.current = null;

      setJSON(json);

      if (resultStats.skipped > 0) {
        toast(
          `CSV reading finished. ${resultStats.skipped} shoes won't be added due to file errors`,
          {
            type: 'info',
          },
        );
      }
    } catch (error) {
      // TODO: Add error handling when we think the parser is flexible enough
      // setError(true);
      const numberOfLines = content.match(/\n|\r/g)?.length || 1;
      setStats({
        progress: 100,
        total: numberOfLines,
        current: numberOfLines,
      });
    }
  };

  const upload = async () => {
    if (!store) {
      return toast(
        'Sorry for the inconvenience! There was a problem uploading the shoes.',
        {
          type: 'error',
        },
      );
    }

    if (!storeModel.hasShoeManagement(store)) {
      return toast(
        'To upload shoes, you need to enable the Shoe management module. Contact your sales representative to request an upgrade.',
        {
          type: 'error',
        },
      );
    }

    if (!file) {
      toast('There is not file to upload.', {
        type: 'error',
      });

      return;
    }

    if (!json) {
      dispatch(ShoeActions.uploadFailedFile({ file, store }))
        .unwrap()
        .then(() => {
          toast(
            `New shoes has been successfully uploaded! Shoes will be available in the ARIONHUB app within 72 hours.`,
            {
              type: 'success',
            },
          );

          reset();
        });

      return;
    }

    setUploading(true);

    dispatch(ShoeActions.uploadShoes(json))
      .unwrap()
      .then((res) => {
        let message = `New shoes has been successfully uploaded!`;

        if (res.nUpserted > 0) {
          message += `\n Added ${res.nUpserted} shoes.`;
        }
        if (res.duplicates > 0) {
          message += `\n Skipped ${res.duplicates} (duplicates).`;
        }
        if (res.invalidEan > 0) {
          message += `\n Skipped ${res.invalidEan} (invalid EANs).`;
        }

        toast(message, {
          type: 'success',
        });

        reset();
      })
      .catch((error: Error) => {
        let errorMessage = error.message || 'Failed to upload shoes.';

        // duplicate key error
        if (errorMessage.indexOf('E11000') > -1) {
          const eanKey = errorMessage.split('"')[1];

          errorMessage = `Shoe with the ean:"${eanKey}" already exists.`;
        }

        toast(errorMessage, {
          type: 'error',
        });

        setError(true);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <Wrapper>
      {file !== null ? (
        <>
          <ParserContainer>
            <ParserContent>
              <Heading variant="h3">
                <CSVIcon name="csv" />
                {file.name} ({formatFileSize(file.size)})
              </Heading>
              <ParserActions>
                {!error ? (
                  <StyledText variant="body2">
                    {!done ? 'Reading file...' : 'Ready to be added'}
                  </StyledText>
                ) : null}
                <Text variant="body2">
                  {stats.current}/{stats.total}
                </Text>
                <ParserStopButton
                  $visible={!done}
                  name="delete"
                  onClick={reset}
                />
              </ParserActions>
            </ParserContent>
            <ParserProgressPlaceholder />
            <ParserProgress
              style={{ width: `${stats.progress || 0}%` }}
              $error={error !== false}
              $success={done}
            />
          </ParserContainer>
          {done ? (
            <ActionsContainer>
              <CancelButton size="small" variant="outlined" onClick={reset}>
                Cancel
              </CancelButton>
              <Button
                color="primary"
                disabled={uploading || error}
                size="small"
                variant="contained"
                onClick={upload}
              >
                {uploading && <StyledLoader />}
                {`Add ${stats.current} shoes`}
              </Button>
            </ActionsContainer>
          ) : null}
          {errors.length > 0 ? (
            <ErrorsContainer>
              <Heading variant="h3">Errors({errors.length})</Heading>
              {errors
                .sort((a, b) => a.line - b.line)
                .map((error, index) => (
                  <Error key={index}>
                    <ErrorLineContainer>
                      <ErrorIcon name="alert" />
                      <Text>Line {error.line}</Text>
                    </ErrorLineContainer>
                    <ErrorText variant="body2">
                      {JSON.stringify(error.data)}
                    </ErrorText>
                    <ErrorText variant="body2">{error.message}</ErrorText>
                  </Error>
                ))}
            </ErrorsContainer>
          ) : null}
        </>
      ) : (
        <UploadContainer
          $over={hover}
          onClick={openFileExplorer}
          onDrop={(e) => {
            e.preventDefault();

            handleChange(e.dataTransfer.files);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setHover(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setHover(false);
          }}
        >
          <StyledIcon name="add-circle"></StyledIcon>
          <Heading variant="h3">Choose a file or drag it here</Heading>
        </UploadContainer>
      )}
      <StyledInput
        accept=".xls,.xlsx,.csv"
        ref={inputFileRef}
        type="file"
        onChange={(e) => handleChange(e.target.files)}
      />
    </Wrapper>
  );
};

export default Upload;
