import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { User } from '@atogear/arion-utils';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import { reset } from './actions';
import {
  createAccount,
  deleteAccount,
  getAccount,
  getAccounts,
  updateAccount,
} from './thunks';

interface State {
  accounts: {
    data: User[];
    fetching: boolean;
    error?: SerializedError;
  };
  account: {
    data?: User;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  accounts: {
    data: [],
    fetching: false,
    error: undefined,
  },
  account: {
    data: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Accounts
    .addCase(getAccounts.pending, setFetchingState('accounts'))
    .addCase(getAccounts.fulfilled, (state, { payload }) => {
      state.accounts.data = payload;
      setFulfilledState('accounts')(state);
    })
    .addCase(getAccounts.rejected, setRejectedState('accounts'))
    // Get Account
    .addCase(getAccount.pending, setFetchingState('account'))
    .addCase(getAccount.fulfilled, (state, { payload }) => {
      state.account.data = payload;
      setFulfilledState('account')(state);
    })
    .addCase(getAccount.rejected, setRejectedState('account'))
    // Create Account
    .addCase(createAccount.pending, setUpdatingState('account'))
    .addCase(createAccount.fulfilled, (state, { payload }) => {
      state.account.data = payload;
      setFulfilledState('account')(state);
    })
    .addCase(createAccount.rejected, setRejectedState('account'))
    // Update Account
    .addCase(updateAccount.pending, setUpdatingState('account'))
    .addCase(updateAccount.fulfilled, (state, { payload }) => {
      state.account.data = payload;
      setFulfilledState('account')(state);
    })
    .addCase(updateAccount.rejected, setRejectedState('account'))
    // Delete Account
    .addCase(deleteAccount.pending, setUpdatingState('account'))
    .addCase(deleteAccount.fulfilled, (state) => {
      state.account.data = undefined;
      setFulfilledState('account')(state);
    })
    .addCase(deleteAccount.rejected, setRejectedState('account'))
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
