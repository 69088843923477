import { FC } from 'react';
import styled from 'styled-components';
import { Button, Heading, Text } from '../../../components';
import logo from '../../../assets/images/logo-dark.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding: 16px 24px;
`;

const Logo = styled.img`
  width: 512px;
`;

const ErrorText = styled(Text)`
  color: #e57373;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
`;

const StyledLink = styled.a`
  ${(props) => props.theme.typography.h4};
`;

interface Props {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError: () => void;
}

const Fallback: FC<Props> = ({ error, resetError }) => (
  <Wrapper>
    <Content>
      <Logo alt="ARIONHUB Digital Flyer" src={logo} />
      <Heading variant="h4">Seems like we ran into an error.</Heading>
      <ErrorText>{error.message}</ErrorText>
      <TextContainer>
        <Text>
          We are sorry for the inconvenience! Please reload the page to try
          again.
        </Text>
        <Text>
          If this issue persists please contact us via our email:{' '}
          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            href="mailto:support@ato-gear.com"
          >
            support@ato-gear.com
          </StyledLink>
          <br />
          or on our website{' '}
          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.arion.run/"
          >
            arion.run
          </StyledLink>
        </Text>
      </TextContainer>
      <Button onClick={resetError}>Reload page</Button>
    </Content>
  </Wrapper>
);

export default Fallback;
