import { createReducer } from '@reduxjs/toolkit';
import { reset } from './actions';
import { getGlobalTheme, updateGlobalTheme } from './thunks';

const initialState = {
  globalTheme: {
    data: [],
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Global Theme
    .addCase(getGlobalTheme.pending, ({ globalTheme }) => {
      globalTheme.fetching = true;
      globalTheme.error = undefined;
    })
    .addCase(getGlobalTheme.fulfilled, ({ globalTheme }, { payload }) => {
      globalTheme.data = payload;
      globalTheme.fetching = false;
      globalTheme.error = undefined;
    })
    .addCase(getGlobalTheme.rejected, ({ globalTheme }, { error }) => {
      globalTheme.fetching = false;
      globalTheme.error = error;
    })
    // Update Global Theme
    .addCase(updateGlobalTheme.pending, ({ globalTheme }) => {
      globalTheme.updating = true;
      globalTheme.error = undefined;
    })
    .addCase(updateGlobalTheme.fulfilled, ({ globalTheme }, { payload }) => {
      globalTheme.data = payload;
      globalTheme.updating = false;
      globalTheme.error = undefined;
    })
    .addCase(updateGlobalTheme.rejected, ({ globalTheme }, { error }) => {
      globalTheme.updating = false;
      globalTheme.error = error;
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
