import { FC } from 'react';
import styled from 'styled-components';

import Button from '../Button';
import DocumentSignature from './DocumentSignature';

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.surfaceTwo};
  width: 100%;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.shadow};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SignatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledHideButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 45px;
  @media (max-width: 1700px) {
    height: 36px;
    padding: 0 8px;
  }
`;

const Actions = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 16px;
  right: 45px;
  @media (max-width: 1700px) {
    position: relative;
    width: 100%;
    bottom: unset;
    justify-content: flex-end;
    margin-top: 16px;
  }
`;

const StyledSaveButton = styled(Button)`
  margin-right: 40px;
  @media (max-width: 1700px) {
    height: 36px;
    padding: 0 8px;
  }
`;

const StyledSendButton = styled(Button)`
  @media (max-width: 1700px) {
    height: 36px;
    padding: 0 8px;
  }
`;

interface Props {
  onChange?: (key: string, data: string) => void;
  onClose: () => void;
  onSave: () => void;
  onSend: () => void;
}

const DocumentSignatures: FC<Props> = ({
  onChange,
  onClose,
  onSave,
  onSend,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <SignatureContainer>
        <DocumentSignature
          title="ATOGEAR"
          identifier="ato"
          onChange={onChange}
        />
        <DocumentSignature
          title="Customer"
          identifier="customer"
          onChange={onChange}
        />
      </SignatureContainer>
      <StyledHideButton variant="outlined" onClick={onClose}>
        Hide
      </StyledHideButton>

      <Actions>
        <StyledSaveButton variant="outlined" onClick={onSave}>
          Save
        </StyledSaveButton>
        <StyledSendButton variant="contained" onClick={onSend}>
          Send Document
        </StyledSendButton>
      </Actions>
    </Wrapper>
  );
};

export default DocumentSignatures;
