import {
  FC,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Store, storeModel, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useSelector } from '../../../../store';
import { AuthSelectors, StoreSelectors } from '../../../../store/selectors';

import { Button, Dialog, DialogActions } from '../../../../components';
import Address from './Address';
import Contact from './Contact';
import Extra from './Extra';
import General from './General';
import Group from './Group';
import Socials from './Socials';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 900px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
  overflow-y: auto;
  max-height: 70vh;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Placeholder = styled.div`
  display: flex;
  flex: 1;
  margin-left: 32px;
`;

const Divider = styled.span`
  background-color: ${({ theme }) => theme.colors.grayOpaque};
  height: 1px;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

interface Props {
  open?: boolean;
  store?: Store;
  onClose?: () => void;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: (store: Partial<Store>) => void;
}

const StoreDialog: FC<Props> = ({
  open,
  store,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const user = useSelector(AuthSelectors.selectUser);
  const loading = useSelector(StoreSelectors.selectStoreLoading);

  const [data, setData] = useState(storeModel.create(store));

  useEffect(() => {
    if (store) {
      setData(store);
    }
  }, [store]);

  const handleChange = (value: Partial<Store>) =>
    setData((prev) => ({
      ...prev,
      ...value,
    }));

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      onConfirm && onConfirm(data);
    },
    [data, onConfirm],
  );

  const canSetGroup = !!user && userModel.isPowerUser(user);

  return (
    <Dialog
      loading={loading}
      open={open}
      title="Store profile"
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <Row>
            <General store={data} onChange={handleChange} />
            {canSetGroup ? (
              <Group store={data} onChange={handleChange} />
            ) : (
              <Placeholder />
            )}
          </Row>
          <Divider />
          <Row>
            <Address store={data} onChange={handleChange} />
            <Contact store={data} onChange={handleChange} />
          </Row>
          <Divider />
          <Row>
            <Extra store={data} onChange={handleChange} />
            <Socials store={data} onChange={handleChange} />
          </Row>
        </Content>
        <DialogActions>
          <CancelButton
            disabled={loading}
            size="small"
            type="button"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </CancelButton>
          <Button
            color="primary"
            disabled={loading}
            size="small"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default StoreDialog;
