import { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';
import Icon from './Icon';

interface WrapperProps {
  $open?: boolean;
  $error?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props) =>
    props.$error ? props.theme.colors.danger : props.theme.colors.success};
  font-size: 20px;
  pointer-events: ${(props) => (props.$open ? 'all' : 'none')};
  opacity: ${(props) => (props.$open ? 1 : 0)};
  position: absolute;
  left: 24px;
  bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  transition: opacity ease 100ms;
  z-index: 1400;
  border-radius: 4px;
  max-width: 400px;
`;

const Message = styled.div`
  ${(props) => props.theme.typography.body1}
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const CloseButton = styled.div`
  cursor: pointer;
  margin-left: 16px;
`;

interface Props {
  open?: boolean;
  error?: boolean;
  message?: string;
  onClose?: MouseEventHandler<HTMLDivElement>;
}

const Toast: FC<Props> = ({ message, open, error, onClose }) => (
  <Wrapper $error={error} $open={open}>
    <Message>{message}</Message>
    <CloseButton onClick={onClose}>
      <Icon name="close" />
    </CloseButton>
  </Wrapper>
);

export default Toast;
