import { createReducer, SerializedError } from '@reduxjs/toolkit';
import { mergeDeepRight } from 'ramda';
import { Lead } from '../../models/leadModel';
import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';
import { leadPreviewUpdated, reset } from './actions';
import {
  createLead,
  deleteLead,
  getLead,
  getLeads,
  updateLead,
} from './thunks';

interface State {
  leads: {
    data: Lead[];
    fetching: boolean;
    error?: SerializedError;
  };
  lead: {
    data?: Lead;
    preview?: Lead;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  leads: {
    data: [],
    fetching: false,
    error: undefined,
  },
  lead: {
    data: undefined,
    preview: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    // Get Leads
    .addCase(getLeads.pending, setFetchingState('leads'))
    .addCase(getLeads.fulfilled, (state, { payload }) => {
      state.leads.data = payload;
      setFulfilledState('leads')(state);
    })
    .addCase(getLeads.rejected, setRejectedState('leads'))
    // Get Lead
    .addCase(getLead.pending, setFetchingState('lead'))
    .addCase(getLead.fulfilled, (state, { payload }) => {
      state.lead.data = payload;
      state.lead.preview = payload;
      setFulfilledState('lead')(state);
    })
    .addCase(getLead.rejected, setRejectedState('lead'))
    // Create Lead
    .addCase(createLead.pending, setUpdatingState('lead'))
    .addCase(createLead.fulfilled, setFulfilledState('lead'))
    .addCase(createLead.rejected, setRejectedState('lead'))
    // Update Lead
    .addCase(updateLead.pending, setUpdatingState('lead'))
    .addCase(updateLead.fulfilled, (state, { payload }) => {
      state.lead.data = payload;
      setFulfilledState('lead')(state);
    })
    .addCase(updateLead.rejected, setRejectedState('lead'))
    // Delete Lead
    .addCase(deleteLead.pending, setUpdatingState('lead'))
    .addCase(deleteLead.fulfilled, (state) => {
      state.lead.data = undefined;
      setFulfilledState('lead')(state);
    })
    .addCase(deleteLead.rejected, setRejectedState('lead'))
    // Local
    .addCase(leadPreviewUpdated, ({ lead }, { payload }) => {
      lead.preview = mergeDeepRight(lead.preview || {}, payload);
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
