import {
  createReducer,
  Draft,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { AuthUser, User } from '@atogear/arion-utils';

import { formatError } from '../../utils/formatters';

import {
  setFetchingState,
  setFulfilledState,
  setRejectedState,
  setUpdatingState,
} from '../utils';

import { authUserChanged, authUserReset, emailUpdated, reset } from './actions';
import {
  changePassword,
  getUser,
  init,
  resetPassword,
  setPassword,
  signIn,
  signOut,
} from './thunks';

interface State {
  initializing: boolean;
  email?: string;
  loading: boolean;
  error?: SerializedError;
  authUser: {
    data?: AuthUser;
    fetching: boolean;
    error?: SerializedError;
  };
  user: {
    data?: User;
    fetching: boolean;
    updating: boolean;
    error?: SerializedError;
  };
}

const initialState: State = {
  initializing: true,
  email: '',
  loading: false,
  error: undefined,
  authUser: {
    data: undefined,
    fetching: false,
    error: undefined,
  },
  user: {
    data: undefined,
    fetching: false,
    updating: false,
    error: undefined,
  },
};

const setAuthRejectedState =
  (prop: string) =>
  (state: Draft<any>, action: PayloadAction<any, any, any, SerializedError>) =>
    setRejectedState(prop)(state, {
      ...action,
      error: formatError(action.error),
    });

const reducer = createReducer(initialState, (builder) =>
  builder
    // Init
    .addCase(init.pending, setFetchingState('authUser'))
    .addCase(init.rejected, setAuthRejectedState('authUser'))
    // Sign In
    .addCase(signIn.pending, setFetchingState('authUser'))
    .addCase(signIn.fulfilled, (state, { payload }) => {
      state.authUser.data = payload;
      setFulfilledState('authUser')(state);
    })
    .addCase(signIn.rejected, setAuthRejectedState('authUser'))
    // Sign Out
    .addCase(signOut.pending, setFetchingState('authUser'))
    .addCase(signOut.fulfilled, (state) => {
      state.authUser.data = undefined;
      state.user.data = undefined;
      setFulfilledState('authUser')(state);
    })
    .addCase(signOut.rejected, setAuthRejectedState('authUser'))
    // Get Current User
    .addCase(getUser.pending, setUpdatingState('user'))
    .addCase(getUser.fulfilled, (state, { payload }) => {
      state.user.data = payload;
      setFulfilledState('user')(state);
    })
    .addCase(getUser.rejected, setRejectedState('user'))
    // Reset Password
    .addCase(resetPassword.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
    })
    .addCase(resetPassword.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    })
    // Set Password
    .addCase(setPassword.pending, setUpdatingState('user'))
    .addCase(setPassword.fulfilled, (state, { payload }) => {
      state.user.data = payload;
      setFulfilledState('user')(state);
    })
    .addCase(setPassword.rejected, setRejectedState('user'))
    // Change Password
    .addCase(changePassword.pending, setUpdatingState('user'))
    .addCase(changePassword.fulfilled, (state) =>
      setFulfilledState('user')(state),
    )
    .addCase(changePassword.rejected, setRejectedState('user'))
    // Local
    .addCase(authUserChanged, (state, { payload }) => {
      state.initializing = false;
      state.authUser.data = payload;
      setFulfilledState('authUser')(state);
    })
    .addCase(authUserReset, (state) => {
      state.initializing = false;
      state.authUser.data = undefined;
      setFulfilledState('authUser')(state);
    })
    .addCase(emailUpdated, (state, { payload }) => {
      state.email = payload;
    })
    // Reset
    .addCase(reset, () => initialState)
    .addDefaultCase((state) => state),
);

export default reducer;
