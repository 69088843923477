import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import {
  apply,
  isNilOrWhitespace,
  Store,
  storeModel,
} from '@atogear/arion-utils';
import styled from 'styled-components';

import {
  DialogHeading,
  DialogInput,
  DialogInputLabel,
  Icon,
} from '../../../../components';

import { SocialsStoreData } from '../../types';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
`;

const StyledHeading = styled(DialogHeading)`
  margin-bottom: 24px;
`;

const Toggles = styled.div`
  display: flex;
  flex-direction: row;
`;

interface ActiveProps {
  $active?: boolean;
}

const Toggle = styled.div<ActiveProps>`
  background-color: ${({ theme }) => theme.colors.dark};
  border: 1px solid ${({ theme }) => theme.colors.grayOpaque};
  border-radius: 4px;
  color: ${({ $active, theme }) =>
    $active ? theme.colors.white : theme.colors.textTwo};
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin-right: 12px;
`;

const Handles = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const Handle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const AtSignIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTwo};
  font-size: 12px;
`;

interface Props {
  store: Store;
  onChange: (value: SocialsStoreData) => void;
}

const Socials: FC<Props> = ({ store, onChange }) => {
  const initialValues = useMemo(
    () => ({
      facebookHandle: apply(storeModel.getFacebookHandle, store, ''),
      instagramHandle: apply(storeModel.getInstagramHandle, store, ''),
      linkedInHandle: apply(storeModel.getLinkedInHandle, store, ''),
    }),
    [store],
  );

  const [facebookHandle, setFacebookHandle] = useState(
    initialValues.facebookHandle,
  );
  const [instagramHandle, setInstagramHandle] = useState(
    initialValues.instagramHandle,
  );
  const [linkedInHandle, setLinkedInHandle] = useState(
    initialValues.linkedInHandle,
  );

  const [facebookActive, setFacebookActive] = useState(!!facebookHandle);
  const [instagramActive, setInstagramActive] = useState(!!instagramHandle);
  const [linkedInActive, setLinkedInActive] = useState(!!linkedInHandle);

  useEffect(() => {
    const { facebookHandle, instagramHandle, linkedInHandle } = initialValues;

    setFacebookHandle(facebookHandle);
    setInstagramHandle(instagramHandle);
    setLinkedInHandle(linkedInHandle);

    setFacebookActive(!!facebookHandle);
    setInstagramActive(!!instagramHandle);
    setLinkedInActive(!!linkedInHandle);
  }, [initialValues]);

  const handleFacebookToggle = () =>
    setFacebookActive((prev) => {
      const newValue = !prev;

      if (!newValue) {
        onChange({
          facebookHandle: '',
        });
      }

      return newValue;
    });

  const handleInstagramToggle = () =>
    setInstagramActive((prev) => {
      const newValue = !prev;

      if (!newValue) {
        onChange({
          instagramHandle: '',
        });
      }

      return newValue;
    });

  const handleLinkedInToggle = () =>
    setLinkedInActive((prev) => {
      const newValue = !prev;

      if (!newValue) {
        onChange({
          linkedInHandle: '',
        });
      }

      return newValue;
    });

  const handleFacebookChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setFacebookHandle(value);

    onChange({
      facebookHandle: value,
    });
  };

  const handleInstagramChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setInstagramHandle(value);

    onChange({
      instagramHandle: value,
    });
  };

  const handleLinkedInChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    setLinkedInHandle(value);

    onChange({
      linkedInHandle: value,
    });
  };

  // TODO: Validate on more than just empty strings
  const handleValidate = (value: string) => !isNilOrWhitespace(value);

  const startAdornment = <AtSignIcon name="at-sign" />;

  return (
    <Wrapper>
      <StyledHeading variant="h3">Social media (optional)</StyledHeading>
      <Toggles>
        <Toggle $active={facebookActive} onClick={handleFacebookToggle}>
          <Icon name="facebook" />
        </Toggle>
        <Toggle $active={instagramActive} onClick={handleInstagramToggle}>
          <Icon name="instagram" />
        </Toggle>
        <Toggle $active={linkedInActive} onClick={handleLinkedInToggle}>
          <Icon name="linkedin" />
        </Toggle>
      </Toggles>
      <Handles>
        <Handle>
          <StyledInputLabel htmlFor="facebook-handle">
            Facebook
          </StyledInputLabel>
          <DialogInput
            disabled={!facebookActive}
            id="facebook-handle"
            placeholder="Facebook"
            startAdornment={startAdornment}
            value={facebookHandle}
            validate={handleValidate}
            onChange={handleFacebookChange}
          />
        </Handle>
        <Handle>
          <StyledInputLabel htmlFor="instagram-handle">
            Instagram
          </StyledInputLabel>
          <DialogInput
            disabled={!instagramActive}
            id="instagram-handle"
            placeholder="Instagram"
            startAdornment={startAdornment}
            value={instagramHandle}
            validate={handleValidate}
            onChange={handleInstagramChange}
          />
        </Handle>
        <Handle>
          <StyledInputLabel htmlFor="linked-in-handle">
            LinkedIn
          </StyledInputLabel>
          <DialogInput
            disabled={!linkedInActive}
            id="linked-in-handle"
            placeholder="LinkedIn"
            startAdornment={startAdornment}
            value={linkedInHandle}
            validate={handleValidate}
            onChange={handleLinkedInChange}
          />
        </Handle>
      </Handles>
    </Wrapper>
  );
};

export default Socials;
