import { capitalizeEveryWord } from '@atogear/arion-utils';

import { TableColumn } from '../components/Table/types';
import { ApiSurvey } from '../models/apiSurveyModel';

export const surveyColumns: TableColumn<ApiSurvey>[] = [
  {
    key: 'type',
    label: 'Type',
    sort: true,
    flex: 2,
    getValue: (s) => capitalizeEveryWord(s.type),
  },
  {
    key: 'numberOfResponses',
    label: 'Number of responses',
    sort: true,
    getValue: (s) => s.numberOfResponses,
  },
  {
    key: 'startAt',
    label: 'Starts At',
    sort: true,
    getValue: (s) => s.startAt,
  },
  {
    key: 'endAt',
    label: 'Ends At',
    sort: true,
    getValue: (s) => s.endAt,
  },
  {
    key: 'createdAt',
    label: 'Created at',
    sort: true,
    getValue: (s) => s.createdAt,
  },
  {
    key: 'export',
    label: '',
    sort: false,
    getValue: (s) => '',
  },
];

export const tabOptions = [
  {
    key: 'active',
  },
  {
    key: 'ended',
  },
];
