import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apply, Store, storeModel, userModel } from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { ContactActions, StoreActions } from '../../../store/actions';
import {
  AuthSelectors,
  ContactSelectors,
  StoreSelectors,
} from '../../../store/selectors';

import {
  Contact,
  defaults,
  getId as getContactId,
} from '../../../models/contactModel';

// TODO: Uncomment when access to group is available for store owner
// import { appRoutes, getPath } from '../../../routing';

import { translatedSubscriptionStatus } from '../../../translations';

import { formatEquipment } from '../../../utils/formatters';
import { getLanguageCountryCode } from '../../../utils/i18nUtils';

import {
  Button,
  ContactChip,
  ContactDialog,
  FlagIcon,
  Heading,
  Icon,
  Link,
  // TODO: Uncomment when access to group is available for store owner
  // NavLink,
  Text,
} from '../../../components';
import Notes from './Notes';
import StoreDialog from './StoreDialog/StoreDialog';
import Socials from './Socials';

import { StoreRouteParams } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const EditIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 17px;
  margin-right: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  padding: 0 40px;
  margin-top: 2px;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  flex: 2;
`;

interface ValueProps {
  scrollable?: boolean;
}

const Value = styled.div<ValueProps>`
  display: flex;
  flex: 3;
  flex-direction: row;
  overflow-x: ${(props) => (props.scrollable ? 'auto' : 'unset')};
`;

const GroupNameText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
`;

const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`;

const PhoneNumber = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const StyledFlagIcon = styled(FlagIcon)`
  margin-right: 8px;
`;

const AddContact = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px 0 8px;
  border-radius: 24px;
  transition: background-color ease 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
  }
`;

const AddContactIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  margin-right: 8px;
`;

const AddContactText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
`;

const About: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const store = useSelector(StoreSelectors.selectStore);
  const contacts = useSelector(ContactSelectors.selectContacts);
  const updatingContact = useSelector(ContactSelectors.selectContactUpdating);

  const { storeId } = useParams<StoreRouteParams>();

  const name = apply(storeModel.getName, store, '');
  // TODO: Uncomment when access to group is available for store owner
  // const groupId = apply(storeModel.getGroupId, store, '');
  const groupName = apply(storeModel.getGroupName, store, '');
  const subStatus = apply(
    storeModel.getSubscriptionStatus,
    store,
    storeModel.getSubscriptionStatus(storeModel.defaults),
  );
  const address = apply(storeModel.getAddress, store, '');
  const language = apply(
    storeModel.getPreferredLanguage,
    store,
    storeModel.getPreferredLanguage(storeModel.defaults),
  );
  const email = apply(storeModel.getEmail, store, '');
  const callingCode = apply(storeModel.getCallingCode, store, '');
  const phoneNumber = apply(storeModel.getFormattedPhoneNumber, store, '');
  const websiteUrl = apply(storeModel.getFormattedWebsite, store, '');
  const website = apply(storeModel.getWebsite, store, '');
  const track = apply(
    storeModel.hasTrack,
    store,
    storeModel.hasTrack(storeModel.defaults),
  );
  const treadmill = apply(
    storeModel.hasTreadmill,
    store,
    storeModel.hasTreadmill(storeModel.defaults),
  );

  const equipment = formatEquipment(track, treadmill);

  const isOwner = apply(userModel.isOwner, user, true);
  const isGroupManager = apply(userModel.isGroupManager, user, true);

  const [storeDialogOpen, setStoreDialogOpen] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const [contact, setContact] = useState<Contact | undefined>(undefined);

  const getStoreContacts = useCallback(() => {
    if (storeId) {
      dispatch(ContactActions.getContacts({ parentId: storeId }));
    }
  }, [dispatch, storeId]);

  useEffect(() => {
    getStoreContacts();
  }, [getStoreContacts]);

  const openStoreDialog = () => setStoreDialogOpen(true);

  const closeStoreDialog = () => setStoreDialogOpen(false);

  const openContactDialog = () => setContactDialogOpen(true);

  const closeContactDialog = () => setContactDialogOpen(false);

  const handleStoreConfirm = async (data: Partial<Store>) => {
    try {
      if (!storeId) {
        return;
      }

      await dispatch(
        StoreActions.updateStore({
          storeId,
          data,
        }),
      ).unwrap();

      closeStoreDialog();

      toast('Store updated successfully!', {
        type: 'success',
      });
    } catch (error: any) {
      if (error.message === 'Permission denied') {
        toast('You do not have permissions to update this store!', {
          type: 'error',
        });
      } else {
        toast('Failed to update Store!', {
          type: 'error',
        });
      }
    }
  };

  const handleContactAdd = () => {
    setContact(defaults);

    openContactDialog();
  };

  const handleContactEdit = (_: number, contact: Contact) => {
    setContact(contact);

    openContactDialog();
  };

  const handleContactDismiss = () => {
    setContact(undefined);

    closeContactDialog();
  };

  const refreshContacts = () => {
    handleContactDismiss();

    getStoreContacts();
  };

  const handleContactDelete = async (_: number, contact: Contact) => {
    try {
      const contactId = getContactId(contact);

      if (!storeId || !contactId) {
        return;
      }

      await dispatch(
        ContactActions.deleteContact({
          parentId: storeId,
          contactId,
        }),
      ).unwrap();

      refreshContacts();

      toast('Successfully deleted Contact!', {
        type: 'success',
      });
    } catch {
      toast('Failed to delete Contact!', {
        type: 'error',
      });
    }
  };

  const handleContactCreate = async (storeId: string, data: Contact) => {
    try {
      await dispatch(
        ContactActions.createContact({
          parentId: storeId,
          contact: data,
        }),
      ).unwrap();

      refreshContacts();

      toast('Successfully created Contact!', {
        type: 'success',
      });
    } catch {
      toast('Failed to create Contact!', {
        type: 'error',
      });
    }
  };

  const handleContactUpdate = async (
    storeId: string,
    contactId: string,
    data: Partial<Contact>,
  ) => {
    try {
      await dispatch(
        ContactActions.updateContact({
          parentId: storeId,
          contactId,
          contact: data,
        }),
      ).unwrap();

      refreshContacts();

      toast('Successfully updated Contact!', {
        type: 'success',
      });
    } catch {
      toast('Failed to update Contact!', {
        type: 'error',
      });
    }
  };

  const handleContactConfirm = async (data: Contact) => {
    if (!storeId) {
      return;
    }

    const contactId = getContactId(data);

    if (contactId) {
      handleContactUpdate(storeId, contactId, data);
    } else {
      handleContactCreate(storeId, data);
    }
  };

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">About</ContentHeading>
          <Text variant="body2">
            Update your public store profile. This information will be available
            to your customer in the ARIONHUB app, Digital flyer and promotional
            emails.
          </Text>
        </Info>
        <Button variant="outlined" onClick={openStoreDialog}>
          <EditIcon name="edit" />
          Edit store profile
        </Button>
      </Header>
      <Content>
        {!isOwner && !isGroupManager ? (
          <Notes store={store} confirmEdit={handleStoreConfirm} />
        ) : null}
        <Row>
          <StyledHeading variant="h3">Name</StyledHeading>
          <Value>
            <Text variant="body2">{name}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Group</StyledHeading>
          <Value>
            {/* // TODO: Uncomment when access to group is available for store owner */}
            {/* <NavLink
              to={getPath(appRoutes.group.path, {
                groupId,
              })}
            > */}
            <GroupNameText variant="body1">{groupName}</GroupNameText>
            {/* </NavLink> */}
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Status</StyledHeading>
          <Value>
            <Text variant="body2">
              {translatedSubscriptionStatus[subStatus]}
            </Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Address</StyledHeading>
          <Value>
            <Text variant="body2">{address}</Text>
          </Value>
        </Row>
        {!isOwner && !isGroupManager ? (
          <Row>
            <StyledHeading variant="h3">Contacts</StyledHeading>
            <Value scrollable>
              {contacts.map((contact, index) => (
                <ContactChip
                  key={getContactId(contact)}
                  contact={contact}
                  index={index}
                  onDismiss={handleContactDismiss}
                  onEdit={handleContactEdit}
                  onDelete={handleContactDelete}
                />
              ))}
              <AddContact onClick={handleContactAdd}>
                <AddContactIcon name="add-circle" />
                <AddContactText variant="body2">Add contact</AddContactText>
              </AddContact>
            </Value>
          </Row>
        ) : null}
        <Row>
          <StyledHeading variant="h3">Store Email</StyledHeading>
          <Value>
            <Text variant="body2">{email}</Text>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Phone</StyledHeading>
          <Value>
            <PhoneNumber>
              <StyledFlagIcon country={callingCode} />
              <Text variant="body2">{phoneNumber}</Text>
            </PhoneNumber>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Website</StyledHeading>
          <Value>
            <Link href={websiteUrl}>{website}</Link>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Preferred language</StyledHeading>
          <Value>
            <StyledFlagIcon country={getLanguageCountryCode(language)} />
            <CapitalizedText variant="body2">{language}</CapitalizedText>
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Social media</StyledHeading>
          <Value>
            <Socials store={store} />
          </Value>
        </Row>
        <Row>
          <StyledHeading variant="h3">Equipment</StyledHeading>
          <Value>
            <Text variant="body2">{equipment}</Text>
          </Value>
        </Row>
      </Content>
      <StoreDialog
        open={storeDialogOpen}
        store={store}
        onClose={closeStoreDialog}
        onCancel={closeStoreDialog}
        onConfirm={handleStoreConfirm}
      />
      <ContactDialog
        contact={contact}
        loading={updatingContact}
        open={contactDialogOpen}
        onCancel={closeContactDialog}
        onConfirm={handleContactConfirm}
      />
    </Wrapper>
  );
};

export default About;
