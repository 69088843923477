import { FC, MouseEventHandler, useState } from 'react';
import { apply } from '@atogear/arion-utils';
import styled from 'styled-components';

import { Collapsible, Heading, Icon, Text } from '../../../components';
import { Reminder } from '../../../models/reminderModel';
import { reminderModel } from '../../../models';

const reminderInfo = [
  {
    key: reminderModel.fields.CONTENT,
    label: 'Content',
    getValue: (reminder?: Reminder) =>
      apply(reminderModel.getContent, reminder, ''),
  },
  {
    key: reminderModel.fields.TRIGGER_ON,
    label: 'Trigger on',
    getValue: (reminder?: Reminder) =>
      apply(reminderModel.getFormattedTriggerOn, reminder, ''),
  },
  {
    key: reminderModel.fields.CREATED_AT,
    label: 'Created at',
    getValue: (reminder?: Reminder) =>
      apply(reminderModel.getFormattedCreatedAt, reminder, ''),
  },
];

const Wrapper = styled(Collapsible)`
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const SubType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-right: 24px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const Label = styled(Heading)`
  display: flex;
  flex: 1;
  margin-right: 18px;
`;

const Value = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
`;

const Actions = styled.div`
  display: flex;
  position: absolute;
  right: 0;
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  padding: 8px;
  cursor: pointer;
  border-radius: ${(props) =>
    props.theme.components.buttons.primary.borderRadius || 6}px;
  transition: 0.15s ease-in-out background-color;
  &:hover {
    background-color: ${(props) => props.theme.colors.grayOpaque};
  }
  margin-left: 16px;
  &:last-child {
    margin-right: 8px;
  }
`;

interface ItemProps {
  $first?: boolean;
  $last?: boolean;
}

const Row = styled(Header)<ItemProps>`
  align-items: center;
  justify-content: ${({ $last }) => ($last ? 'flex-end' : 'flex-start')};
  min-height: 56px;
  margin-top: ${({ $first }) => ($first ? '16px' : 0)};
  margin-right: 32px;
`;

interface Props {
  reminder: Reminder;
  onEdit?: (reminder: Reminder) => void;
  onDelete?: (reminder: Reminder) => void;
}

const ReminderInfo: FC<Props> = (props) => {
  const { reminder, onEdit, onDelete } = props;
  const [open, setOpen] = useState(false);

  const triggerOn = apply(
    reminderModel.getTriggerOn,
    reminder,
    reminderModel.defaults.triggerOn,
  );

  const handleEdit: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (onEdit) onEdit(reminder);
  };

  const handleDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (onDelete) onDelete(reminder);
  };

  return (
    <div>
      <Wrapper onStateChange={setOpen}>
        <Header>
          <SubType>
            <StyledIcon name="store" />
            <Text variant="body2">Reminder</Text>
          </SubType>
          <Label variant="h3">Title</Label>
          <Value>
            <Text variant="body2">{reminder?.title}</Text>
          </Value>
          <Actions>
            {open ? (
              <>
                {triggerOn > new Date().getTime() ? (
                  <IconButton onClick={handleEdit}>
                    <Icon name="edit" />
                  </IconButton>
                ) : null}
                <IconButton onClick={handleDelete}>
                  <Icon name="delete" />
                </IconButton>
              </>
            ) : null}
          </Actions>
        </Header>
        {reminderInfo.map(({ key, label, getValue }, index) => (
          <Row key={`${key}-${index}`} $first={index === 0}>
            <SubType />
            <Label variant="h3">{label}</Label>
            <Value>
              <Text variant="body2">{getValue(reminder)}</Text>
            </Value>
          </Row>
        ))}
      </Wrapper>
    </div>
  );
};

export default ReminderInfo;
