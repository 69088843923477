import { FC } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../../../components';
import { Theme } from '../../../../../theme/types';
import ComponentButton from './ComponentButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 24px;
`;

interface Props {
  theme: Theme;
}

const ButtonsPreview: FC<Props> = ({ theme, ...rest }) => {
  const { components, colors, fonts } = theme;
  return (
    <Wrapper {...rest}>
      <StyledHeading variant="h3">Buttons</StyledHeading>
      <ButtonWrapper>
        <ComponentButton
          name="Primary"
          fallbackColor={colors.primary}
          font={fonts.primary}
          buttonStyles={components.buttons.primary}
        />
        <ComponentButton
          name="Secondary"
          fallbackColor={colors.secondary}
          font={fonts.secondary}
          buttonStyles={components.buttons.secondary}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ButtonsPreview;
