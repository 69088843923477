import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

type Variant = 'body1' | 'body2' | 'body3' | 'caption' | 'a';

interface WrapperProps {
  $variant: Variant;
}

const Wrapper = styled.p<WrapperProps>`
  ${({ $variant, theme }) => theme.typography[$variant]};
`;

export interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  variant?: Variant;
}

const Text: FC<TextProps> = ({ variant, ...rest }) => (
  <Wrapper $variant={variant || 'body1'} {...rest} />
);

export default Text;
