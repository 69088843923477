import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface ImageProps {
  $selected: boolean;
  $error: boolean;
}

const PreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Preview = styled.img<ImageProps>`
  color: ${(props) => props.theme.colors.text};
  transition: box-shadow 0.2s ease-in-out;
  border-radius: 4px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: ${(props) =>
    `0px 0px 0px 1px ${
      props.$error ? props.theme.colors.danger : props.theme.colors.grayOpaque
    }`};
  outline-offset: 3px;
  outline: ${({ theme, $selected }) =>
    $selected ? `2px solid ${theme.colors.primary}` : 'unset'};
`;

const PreviewVideo = styled.video<ImageProps>`
  color: ${(props) => props.theme.colors.text};
  transition: box-shadow 0.2s ease-in-out;
  border-radius: 4px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: ${(props) =>
    `0px 0px 0px 1px ${
      props.$error ? props.theme.colors.danger : props.theme.colors.grayOpaque
    }`};
  outline-offset: 3px;
  outline: ${({ theme, $selected }) =>
    $selected ? `2px solid ${theme.colors.primary}` : 'unset'};
`;

interface MediaPreviewProps extends HTMLAttributes<HTMLDivElement> {
  type: string;
  alt?: string;
  error?: boolean;
  loading?: boolean;
  selected?: boolean;
  src?: string;
}

const MediaPreview: FC<MediaPreviewProps> = (props) => {
  const { src, error, type, alt, selected } = props;

  const getSource = () => {
    if (!src || src === '') {
      return '';
    }

    if (typeof src === 'string') {
      return src;
    }

    if (typeof src === 'object') {
      return URL.createObjectURL(src);
    }
  };

  return (
    <PreviewWrapper>
      {type && type === 'video' ? (
        <PreviewVideo
          onClick={(e) => e.preventDefault()}
          controls
          $error={error || false}
          $selected={selected || false}
          src={getSource()}
        />
      ) : (
        <Preview
          $error={error || false}
          $selected={selected || false}
          src={getSource()}
          alt={alt}
        />
      )}
    </PreviewWrapper>
  );
};

export default MediaPreview;
