import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  isNilOrWhitespace,
  User,
  userModel,
  UserRoles,
} from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../../store';
import {
  OnboardingActions,
  StoreAccountActions,
} from '../../../../store/actions';

import { isValidEmail } from '../../../../utils/validators';

import {
  Button,
  Dialog,
  DialogActions,
  DialogInput,
  DialogInputLabel,
  DialogSelect,
  Option,
} from '../../../../components';

import { StoreRouteParams } from '../../types';
import { InviteMemberType } from '../Team';
import { AuthSelectors, StoreSelectors } from '../../../../store/selectors';
import { translatedUserRoles } from '../../../../translations';

const getRoleOptions = (user?: User) => {
  const roleOptions = [
    {
      key: UserRoles.MANAGER,
      label: translatedUserRoles[UserRoles.MANAGER],
      value: UserRoles.MANAGER,
    },
    {
      key: UserRoles.EMPLOYEE,
      label: translatedUserRoles[UserRoles.EMPLOYEE],
      value: UserRoles.EMPLOYEE,
    },
  ];

  if (
    user &&
    userModel.hasRole([UserRoles.ADMIN, UserRoles.BACK_OFFICE], user)
  ) {
    roleOptions.unshift({
      key: UserRoles.GROUP_MANAGER,
      label: translatedUserRoles[UserRoles.GROUP_MANAGER],
      value: UserRoles.GROUP_MANAGER,
    });
  }

  return roleOptions;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledInputLabel = styled(DialogInputLabel)`
  margin-bottom: 10px;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

const StyledDialog = styled(Dialog)`
  min-width: 400px;
`;

interface Props {
  open?: boolean;
  type?: InviteMemberType;
  onClose?: () => void;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
}

const InviteDialog: FC<Props> = ({
  open,
  type = 'member',
  onClose,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const store = useSelector(StoreSelectors.selectStore);

  const user = useSelector(AuthSelectors.selectUser);

  const { storeId } = useParams<StoreRouteParams>();

  const [member, setMember] = useState(userModel.create());

  const [errors, setErrors] = useState({
    email: true,
    firstName: true,
    lastName: true,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setMember(userModel.create());

      setErrors({
        email: true,
        firstName: true,
        lastName: true,
      });
    }
  }, [open]);

  const handleSubmit =
    (type: InviteMemberType): FormEventHandler<HTMLFormElement> =>
    async (e) => {
      try {
        e.preventDefault();

        if (!storeId || !store) {
          return;
        }

        setLoading(true);

        if (type === 'owner') {
          const { ownerId } = await dispatch(
            OnboardingActions.sendOwnerInvite({
              email: userModel.getEmail(member),
              firstName: userModel.getFirstName(member),
              lastName: userModel.getLastName(member),
              storeId,
            }),
          ).unwrap();

          dispatch(StoreAccountActions.getStoreAccounts({ ...store, ownerId }));
        } else {
          await dispatch(
            OnboardingActions.sendInvite({
              email: userModel.getEmail(member),
              firstName: userModel.getFirstName(member),
              lastName: userModel.getLastName(member),
              role: userModel.getRole(member),
              storeId,
            }),
          ).unwrap();

          dispatch(StoreAccountActions.getInvites(storeId));
        }

        toast('Invitation email sent successfully!', {
          type: 'success',
        });

        if (onClose) {
          onClose();
        }
      } catch (error) {
        const message = `Sorry for the inconvenience! There was a problem sending the invite. ${
          (error as Error)?.message || ''
        }`;

        toast(message, {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

  const handleChangeEmail: ChangeEventHandler<HTMLInputElement> = (e) =>
    setMember((prev) => ({
      ...prev,
      email: e.target.value.toLowerCase(),
    }));

  const validateEmail = (value: string) => {
    const valid = isValidEmail(value);

    setErrors((prev) => ({
      ...prev,
      email: !valid,
    }));

    return valid;
  };

  const validateFirstName = (value: string) => {
    const valid = !isNilOrWhitespace(value);

    setErrors((prev) => ({
      ...prev,
      firstName: !valid,
    }));

    return valid;
  };

  const handleChangeFirstName: ChangeEventHandler<HTMLInputElement> = (e) =>
    setMember((prev) => ({
      ...prev,
      firstName: e.target.value,
    }));

  const validateLastName = (value: string) => {
    const valid = !isNilOrWhitespace(value);

    setErrors((prev) => ({
      ...prev,
      lastName: !valid,
    }));

    return valid;
  };

  const handleChangeLastName: ChangeEventHandler<HTMLInputElement> = (e) =>
    setMember((prev) => ({
      ...prev,
      lastName: e.target.value,
    }));

  const handleChangeRole = (option: Option | null) =>
    setMember((prev) => ({
      ...prev,
      role: option?.value || UserRoles.EMPLOYEE,
    }));

  return (
    <StyledDialog
      open={open}
      loading={loading}
      title={type === 'owner' ? 'Invite store owner' : 'Invite team member'}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit(type)}>
        <Wrapper>
          <Group>
            <StyledInputLabel htmlFor="email">Email</StyledInputLabel>
            <DialogInput
              id="email"
              placeholder="Email"
              value={userModel.getEmail(member)}
              validate={validateEmail}
              onChange={handleChangeEmail}
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor="first-name">First name</StyledInputLabel>
            <DialogInput
              id="first-name"
              placeholder="First name"
              value={userModel.getFirstName(member)}
              validate={validateFirstName}
              onChange={handleChangeFirstName}
            />
          </Group>
          <Group>
            <StyledInputLabel htmlFor="last-name">Last name</StyledInputLabel>
            <DialogInput
              id="last-name"
              placeholder="Last name"
              value={userModel.getLastName(member)}
              validate={validateLastName}
              onChange={handleChangeLastName}
            />
          </Group>
          {type !== 'owner' ? (
            <Group>
              <StyledInputLabel htmlFor="role">Role</StyledInputLabel>
              <DialogSelect
                id="role"
                options={getRoleOptions(user)}
                value={userModel.getRole(member)}
                onChange={handleChangeRole}
              />
            </Group>
          ) : null}
        </Wrapper>
        <DialogActions>
          <CancelButton
            size="small"
            type="button"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </CancelButton>
          <Button
            color="primary"
            disabled={Object.values(errors).some((value) => value === true)}
            size="small"
            type="submit"
            variant="contained"
          >
            Invite
          </Button>
        </DialogActions>
      </Form>
    </StyledDialog>
  );
};

export default InviteDialog;
