import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../store';
import { AuthActions } from '../../store/actions';
import { AuthSelectors } from '../../store/selectors';

import { unauthenticatedRoutes } from '../../routing';

import { Button, Input, InputLabel, NavLink, Text } from '../../components';
import Auth from './Auth';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 24px;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 32px;
`;

const Actions = styled.div`
  margin-bottom: 24px;
`;

const ForgotPassword: FC = () => {
  const dispatch = useDispatch();

  const initialEmail = useSelector(AuthSelectors.selectEmail);
  const loading = useSelector(AuthSelectors.selectLoading);

  const history = useHistory();

  const [email, setEmail] = useState(initialEmail);

  useEffect(() => {
    setEmail(initialEmail);
  }, [initialEmail]);

  const goBack = () => history.push(unauthenticatedRoutes.login.path);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      if (!email) {
        throw new Error('Invalid email');
      }

      await dispatch(AuthActions.resetPassword(email)).unwrap();

      toast('Successfully sent reset password email!', {
        type: 'success',
      });

      goBack();
    } catch {
      toast('Failed to send reset password email!', {
        type: 'error',
      });
    }
  };

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setEmail(e.target.value);

  return (
    <Auth loading={loading} title="Reset password">
      <Text variant="body2">Please input your account email.</Text>
      <StyledForm onSubmit={handleSubmit}>
        <StyledInputLabel htmlFor="email" variant="h3">
          Email
        </StyledInputLabel>
        <StyledInput
          id="email"
          placeholder="Email"
          name="email"
          autoFocus
          autoComplete="email"
          type="email"
          value={email}
          required
          maxLength={50}
          onChange={handleEmailChange}
        />
        <Actions>
          <Button color="primary" type="submit">
            Reset password
          </Button>
        </Actions>
        <NavLink to={unauthenticatedRoutes.login.path}>Back to login</NavLink>
      </StyledForm>
    </Auth>
  );
};

export default ForgotPassword;
