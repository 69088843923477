import { getFirestorePipe } from '@atogear/ato-broker';

import { Contact } from '../models/contactModel';

const getStoreCollPath = (id: string) => `stores/${id}/contacts`;
const getProspectCollPath = (id: string) => `prospects/${id}/contacts`;

export interface ContactOptions {
  isProspect: boolean;
}

const getContactService = () => {
  /**
   * Get object's Contacts.
   *
   * @param id object's id.
   * @returns Promise that resolves to object's Contacts.
   */
  const getContacts = async (
    id: string,
    options?: ContactOptions,
  ): Promise<Contact[]> => {
    const contacts = await getFirestorePipe().getDocuments(
      options?.isProspect ? getProspectCollPath(id) : getStoreCollPath(id),
      {},
    );

    return contacts as Contact[];
  };

  /**
   * Get object's Contact.
   *
   * @param id object's id.
   * @param contactId object's Contact's id.
   * @returns Promise that resolves to object's Contact.
   */
  const getContact = async (
    id: string,
    contactId: string,
    options?: ContactOptions,
  ): Promise<Contact> => {
    const contact = await getFirestorePipe().getDocument(
      options?.isProspect ? getProspectCollPath(id) : getStoreCollPath(id),
      contactId,
    );

    return contact as Contact;
  };

  /**
   * Create object's Contact.
   *
   * @param id object's id.
   * @param contact object's Contact's data.
   * @returns Promise that resolves to created object's Contact's id.
   */
  const createContact = async (
    id: string,
    contact: Contact,
    options?: ContactOptions,
  ): Promise<string> => {
    const contactId = await getFirestorePipe().addDocument(
      options?.isProspect ? getProspectCollPath(id) : getStoreCollPath(id),
      contact,
    );

    return contactId;
  };

  /**
   * Update object's Contact.
   *
   * @param id object's id.
   * @param contactId object's Contact's id.
   * @param contact object's Contact's data.
   * @returns Promise that resolves to updated object's Contact.
   */
  const updateContact = async (
    id: string,
    contactId: string,
    contact: Partial<Contact>,
    options?: ContactOptions,
  ): Promise<Contact> => {
    const updatedContact = await getFirestorePipe().editDocument(
      options?.isProspect ? getProspectCollPath(id) : getStoreCollPath(id),
      contactId,
      contact,
    );

    return updatedContact as Contact;
  };

  /**
   * Delete object's Contact.
   *
   * @param id object's id.
   * @param contactId object's Contact's id.
   * @returns Promise that resolves when object's Contact has been successfully deleted.
   */
  const deleteContact = async (
    id: string,
    contactId: string,
    options?: ContactOptions,
  ): Promise<boolean> => {
    const result = await getFirestorePipe().deleteDocument(
      options?.isProspect ? getProspectCollPath(id) : getStoreCollPath(id),
      contactId,
    );

    return result;
  };

  return {
    getContacts,
    getContact,
    createContact,
    updateContact,
    deleteContact,
  };
};

export default getContactService;
