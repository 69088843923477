import { FC, useState } from 'react';
import { apply, userModel } from '@atogear/arion-utils';
import styled from 'styled-components';

import { useSelector } from '../../../store';
import { AuthSelectors, ProspectSelectors } from '../../../store/selectors';

import { Button, Heading, Icon, Text } from '../../../components';
import SubscriptionDialog from './SubscriptionDialog';
import SubscriptionInfo from './SubscriptionInfo';

import { prospectModel } from '../../../models';
import { ProspectStatus } from '../../../enums';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`;

const ContentHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const ButtonIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 17px;
  margin-right: 8px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Subscriptions: FC = () => {
  const user = useSelector(AuthSelectors.selectUser);
  const prospect = useSelector(ProspectSelectors.selectProspect);

  const isAdmin = apply(userModel.isAdmin, user, false);
  const isSales = apply(userModel.isSales, user, false);
  const status = apply(
    prospectModel.getStatus,
    prospect,
    prospectModel.defaults.prospectStatus,
  );

  const hasSubscriptionInfo = apply(
    prospectModel.hasSubscriptionInfo,
    prospect,
    prospectModel.hasSubscriptionInfo(prospectModel.defaults),
  );

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const openEditDialog = () => setEditDialogOpen(true);

  const closeEditDialog = () => setEditDialogOpen(false);

  const canAddSubInfo = isAdmin || isSales;

  const canEditSubInfo = canAddSubInfo && hasSubscriptionInfo;

  const isTransformed = status === ProspectStatus.WON;

  return (
    <Wrapper>
      <Header>
        <Info>
          <ContentHeading variant="h1">Subscription</ContentHeading>
          <Text variant="body2">
            Preview and update the subscription details.
          </Text>
        </Info>
        {canEditSubInfo && !isTransformed && (
          <EditButton>
            <Button variant="outlined" onClick={openEditDialog}>
              <ButtonIcon name="edit" />
              Edit subscription
            </Button>
          </EditButton>
        )}
      </Header>
      <Content>
        {!hasSubscriptionInfo ? (
          canAddSubInfo &&
          !isTransformed && (
            <div>
              <Button variant="outlined" onClick={openEditDialog}>
                <ButtonIcon name="edit" />
                Add subscription
              </Button>
            </div>
          )
        ) : (
          <>
            <SubscriptionInfo open={true} />
          </>
        )}
      </Content>
      {(canAddSubInfo || canEditSubInfo) && !isTransformed && (
        <SubscriptionDialog
          prospect={prospect}
          open={editDialogOpen}
          onClose={closeEditDialog}
        />
      )}
    </Wrapper>
  );
};

export default Subscriptions;
