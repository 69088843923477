import { FC } from 'react';

import { getFrequency, Lead } from '../../../models/leadModel';

import LeadWrapper from './Lead';

interface Props {
  lead: Lead;
}

const Recurring: FC<Props> = ({ lead }) => {
  const frequency = getFrequency(lead);

  return (
    <LeadWrapper lead={lead}>
      {frequency === 1 ? 'Every month' : `Every ${frequency} months`}
    </LeadWrapper>
  );
};

export default Recurring;
