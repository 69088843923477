import { FC } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../../../components';
import {
  ColorsType,
  FontsType,
  TypographyType,
} from '../../../../../theme/types';
import AdvancedFonts from './AdvancedFonts';
import FontTile from './FontTile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 24px;
`;

const FontWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
interface Props {
  fonts: FontsType;
  colors: ColorsType;
  typography: TypographyType;
}

const FontsPreview: FC<Props> = ({ typography, fonts, colors, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <StyledHeading variant="h3">Fonts</StyledHeading>
      <FontWrapper>
        <FontTile
          name="Font Family 1"
          fontFamily={fonts.primary}
          textColor={colors.text}
          background={colors.background}
        />
        <FontTile
          name="Font Family 2"
          fontFamily={fonts.secondary}
          textColor={colors.text}
          background={colors.background}
        />
      </FontWrapper>
      <AdvancedFonts typography={typography} colors={colors} />
    </Wrapper>
  );
};

export default FontsPreview;
