import { createAction } from '@reduxjs/toolkit';
import { Reminder } from '../../models/reminderModel';

export const reminderPreviewUpdated = createAction<Partial<Reminder>>(
  'reminders/reminderPreviewUpdated',
);

export const reminderPreviewReset = createAction(
  'reminders/reminderPreviewReset',
);

export const resetReminder = createAction('reminders/resetReminder');

export const reset = createAction('reminders/reset');
