import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  apply,
  Store,
  storeModel,
  StoreModule,
  userModel,
} from '@atogear/arion-utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../../store';
import { StoreActions } from '../../../store/actions';
import { AuthSelectors, StoreSelectors } from '../../../store/selectors';

import { translatedModules } from '../../../translations';

import { formatYears } from '../../../utils/formatters';

import {
  Button,
  Collapsible,
  Heading,
  Icon,
  ModuleChip,
  Text,
} from '../../../components';
import CancelDialog from './Dialogs/CancelDialog';
import ReactivateDialog from './Dialogs/ReactivateDialog';

import { StoreRouteParams } from '../types';

const Wrapper = styled(Collapsible)`
  margin-bottom: 56px;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

interface ItemProps {
  $first?: boolean;
  $last?: boolean;
}

const Row = styled(Header)<ItemProps>`
  align-items: center;
  justify-content: ${({ $last }) => ($last ? 'flex-end' : 'flex-start')};
  min-height: 56px;
  margin-top: ${({ $first }) => ($first ? '16px' : 0)};
  margin-right: 32px;
`;

const SubType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-right: 24px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const Label = styled(Heading)`
  display: flex;
  flex: 1;
  margin-right: 18px;
`;

const Value = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
`;

const ButtonIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 16px;
`;

const ModulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const subInfo = [
  {
    key: storeModel.fields.SUBSCRIPTION_START_DATE,
    label: 'Start date',
    getValue: (store?: Store) =>
      apply(storeModel.getFormattedSubscriptionStartDate, store, '-'),
  },
  {
    key: storeModel.fields.SUBSCRIPTION_END_DATE,
    label: 'End date',
    getValue: (store?: Store) =>
      apply(storeModel.getFormattedSubscriptionEndDate, store, '-'),
  },
  {
    key: storeModel.fields.USER_SLOTS,
    label: 'Team size',
    getValue: (store?: Store) =>
      `${apply(
        storeModel.getUserSlots,
        store,
        storeModel.getUserSlots(storeModel.defaults),
      )} members`,
  },
  {
    key: storeModel.fields.MODULES,
    label: 'Modules',
    renderValue: (store?: Store) => {
      const modules = apply(
        storeModel.getModules,
        store,
        storeModel.getModules(storeModel.defaults),
      );

      const activeModules = Object.keys(modules)
        .filter((key) => modules[key as StoreModule])
        .sort() as StoreModule[];

      return (
        <ModulesContainer>
          {activeModules.length > 0 ? (
            activeModules.map((key) => {
              const { title, icon } = translatedModules[key];

              return (
                <ModuleChip
                  key={`module-${key}`}
                  avatar={<Icon name={icon} />}
                  label={title}
                />
              );
            })
          ) : (
            <Text variant="body2">None</Text>
          )}
        </ModulesContainer>
      );
    },
  },
  {
    key: storeModel.fields.SUBSCRIPTION_CREATED_AT,
    label: 'Subscription created',
    getValue: (store?: Store) =>
      apply(storeModel.getFormattedSubscriptionCreatedAt, store, '-'),
  },
];

interface Props {
  open?: boolean;
}

const SubscriptionInfo: FC<Props> = ({ open }) => {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.selectUser);
  const store = useSelector(StoreSelectors.selectStore);

  const { storeId } = useParams<StoreRouteParams>();

  const { subPeriod, canBeCanceled, canBeReactivated } = useMemo(
    () => ({
      subPeriod: apply(storeModel.getSubscriptionPeriod, store, 2),
      canBeCanceled: apply(storeModel.canBeCanceled, store, true),
      canBeReactivated: apply(storeModel.canBeReactivated, store, false),
    }),
    [store],
  );

  const isAdmin = useMemo(() => {
    return apply(userModel.isAdmin, user, false);
  }, [user]);

  const isSales = useMemo(() => {
    return apply(userModel.isSales, user, false);
  }, [user]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleCancel = async () => {
    try {
      if (!storeId) {
        return;
      }

      await dispatch(StoreActions.cancelSubscription(storeId));

      closeDialog();

      toast('Successfully canceled subscription!', {
        type: 'success',
      });
    } catch (error) {
      toast((error as Error)?.message || 'Failed to cancel subscription!', {
        type: 'error',
      });
    }
  };

  const handleReactivate = async () => {
    try {
      if (!storeId) {
        return;
      }

      await dispatch(StoreActions.reactivateSubscription(storeId)).unwrap();

      closeDialog();

      toast('Successfully reactivated subscription!', {
        type: 'success',
      });
    } catch (error) {
      toast((error as Error)?.message || 'Failed to reactivate subscription!', {
        type: 'error',
      });
    }
  };

  const hasPermission = isAdmin || isSales;

  return (
    <Wrapper open={open}>
      <Header>
        <SubType>
          <StyledIcon name="star" />
          <Text variant="body2">Subscription</Text>
        </SubType>
        <Label variant="h3">Duration</Label>
        <Value>
          <Text variant="body2">{formatYears(subPeriod)}</Text>
        </Value>
      </Header>
      {subInfo.map(({ key, label, getValue, renderValue }, index) => (
        <Row key={key} $first={index === 0}>
          <SubType />
          <Label variant="h3">{label}</Label>
          <Value>
            {renderValue ? (
              renderValue(store)
            ) : (
              <Text variant="body2">{getValue(store)}</Text>
            )}
          </Value>
        </Row>
      ))}
      {hasPermission && canBeCanceled && (
        <Row $last>
          <Button size="small" variant="outlined" onClick={openDialog}>
            <ButtonIcon name="close-circle" />
            Cancel subscription
          </Button>
          <CancelDialog
            open={dialogOpen}
            onClose={closeDialog}
            onConfirm={handleCancel}
          />
        </Row>
      )}
      {isAdmin && canBeReactivated && (
        <Row $last>
          <Button size="small" variant="outlined" onClick={openDialog}>
            <ButtonIcon name="renew-subscription" />
            Reactivate subscription
          </Button>
          <ReactivateDialog
            open={dialogOpen}
            onClose={closeDialog}
            onConfirm={handleReactivate}
          />
        </Row>
      )}
    </Wrapper>
  );
};

export default SubscriptionInfo;
