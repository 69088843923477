import { RootState } from '../types';

export const selectInvite = (state: RootState) => state.onboarding.invite.data;

export const selectInviteFetching = (state: RootState) =>
  state.onboarding.invite.fetching;

export const selectInviteUpdating = (state: RootState) =>
  state.onboarding.invite.updating;

export const selectInviteError = (state: RootState) =>
  state.onboarding.invite.error;
