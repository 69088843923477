import {
  formatDate,
  formatDateTime,
  formatUrl,
  isNilOrWhitespace,
  toDate,
  toMillis,
} from '@atogear/arion-utils';
import {
  addDays,
  addMinutes,
  addYears,
  endOfDay,
  endOfYesterday,
  isAfter,
  isBefore,
  roundToNearestMinutes,
  setHours,
  startOfDay,
  subYears,
} from 'date-fns';
import { allPass, clamp, gte, is, lte, mergeRight } from 'ramda';

import { LeadTemplates, LeadTypes } from '../enums';

import { translatedLeadTypes } from '../translations';

export interface Range<T> {
  from: T;
  to: T;
}

export interface GenderFilter {
  female: boolean;
  male: boolean;
  other: boolean;
}

export interface MetricFilter {
  low: boolean;
  medium: boolean;
  high: boolean;
}

export interface Lead {
  // General Info
  id?: string;
  name: string;
  createdAt: number;
  updatedAt: number;
  type: LeadTypes;
  // Scheduling
  date: number;
  interval?: number; // scheduled type only
  enabled?: boolean; // scheduled and recurring type only
  frequency?: number; // recurring type only
  startDate?: number; // recurring type only
  endDate?: number; // recurring type only
  // Email Customization
  template: LeadTemplates;
  sender: string;
  subject: string;
  hasCoverImage: boolean;
  header: string;
  content: string;
  redirectButtonText: string;
  redirectButtonUrl: string;
  hasFlyer: boolean;
  hasPromo: boolean;
  signature: string;
  // Recipient Filters
  hasFilters: boolean;
  ageRange?: Range<number>;
  genders?: GenderFilter;
  dateRange?: Range<number>;
  cadence?: MetricFilter;
  footstrikeY?: MetricFilter;
  stabilityX?: MetricFilter;
}

// Created / updated at
const now = Date.now();

// Date
export const getInitialDate = () =>
  roundToNearestMinutes(addMinutes(new Date(), 30), {
    nearestTo: 30,
  }).getTime();

// Interval (scheduled type only)
export const INTERVAL_MIN = 1;
export const INTERVAL_MAX = 12;

// Start and end dates (recurring type only)
const getInitialStartDate = () =>
  // Next day @ 10:00
  setHours(addDays(new Date(), 1), 10).getTime();

const getInitialEndDate = () =>
  // End of day after 1 year
  addYears(endOfDay(getInitialStartDate()), 1).getTime();

// Age range
export const AGE_MIN = 0;
export const AGE_MAX = 100;

// Date range
const getInitialToDate = () => endOfYesterday().getTime();

const getInitialFromDate = () =>
  subYears(startOfDay(getInitialToDate()), 1).getTime();

export const fields = {
  // General Info
  ID: 'id',
  NAME: 'name',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  TYPE: 'type',
  // Scheduling
  DATE: 'date',
  INTERVAL: 'interval', // scheduled type only
  ENABLED: 'enabled', // scheduled and recurring type only
  FREQUENCY: 'frequency', // recurring type only
  START_DATE: 'startDate', // recurring type only
  END_DATE: 'endDate', // recurring type only
  // Email Customization
  TEMPLATE: 'template',
  SENDER: 'sender',
  SUBJECT: 'subject',
  HAS_COVER_IMG: 'hasCoverImage',
  HEADER: 'header',
  CONTENT: 'content',
  REDIRECT_BUTTON_TEXT: 'redirectButtonText',
  REDIRECT_BUTTON_URL: 'redirectButtonUrl',
  HAS_FLYER: 'hasFlyer',
  HAS_PROMO: 'hasPromo',
  SIGNATURE: 'signature',
  // Recipient Filters
  HAS_FILTERS: 'hasFilters',
  AGE_RANGE: 'ageRange',
  GENDERS: 'genders',
  DATE_RANGE: 'dateRange',
  CADENCE: 'cadence',
  FOOTSTRIKE_Y: 'footstrikeY',
  STABILITY_X: 'stabilityX',
};

export const defaults: Lead = {
  // General Info
  id: '',
  name: '',
  createdAt: now,
  updatedAt: now,
  type: LeadTypes.SCHEDULED,
  // Scheduling
  date: getInitialDate(),
  interval: INTERVAL_MIN, // scheduled type only
  enabled: true, // scheduled and recurring type only
  frequency: INTERVAL_MIN, // recurring type only
  startDate: getInitialStartDate(), // recurring type only
  endDate: getInitialEndDate(), // recurring type only
  // Email Customization
  template: LeadTemplates.BASIC,
  sender: '',
  subject: '',
  hasCoverImage: true,
  header: '',
  content: '',
  redirectButtonText: '',
  redirectButtonUrl: '',
  hasFlyer: false,
  hasPromo: false,
  signature: '',
  // Recipient Filters
  hasFilters: false,
  ageRange: {
    from: AGE_MIN,
    to: AGE_MAX,
  },
  genders: {
    female: false,
    male: false,
    other: false,
  },
  dateRange: {
    from: getInitialFromDate(),
    to: getInitialToDate(),
  },
  cadence: {
    low: false,
    medium: false,
    high: false,
  },
  footstrikeY: {
    low: false,
    medium: false,
    high: false,
  },
  stabilityX: {
    low: false,
    medium: false,
    high: false,
  },
};

export interface FirestoreLead
  extends Omit<
    Lead,
    'createdAt' | 'updatedAt' | 'date' | 'startDate' | 'endDate' | 'dateRange'
  > {
  createdAt: Date;
  updatedAt: Date;
  date: Date;
  startDate?: Date;
  endDate?: Date;
  dateRange?: Range<Date>;
}

// General Info
export const getId = (lead?: Lead) => (lead || defaults).id;

export const getName = (lead?: Lead) => (lead || defaults).name;

export const setName = (name: string, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    name,
  });

export const getCreatedAt = (lead?: Lead) => (lead || defaults).createdAt;

export const setCreatedAt = (date: number, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    createdAt: date,
    updatedAt: date,
  });

export const getUpdatedAt = (lead?: Lead) => (lead || defaults).updatedAt;

export const setUpdatedAt = (updatedAt: number, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    updatedAt,
  });

export const getType = (lead?: Lead) => (lead || defaults).type;

export const getFormattedType = (lead?: Lead) =>
  translatedLeadTypes[getType(lead)];

export const setType = (type: LeadTypes, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    type,
  });

export const isValidType = (type: LeadTypes) =>
  Object.values(LeadTypes).includes(type);

// Scheduling
export const getDate = (lead?: Lead) => (lead || defaults).date;

export const getFormattedDate = (lead?: Lead) => formatDateTime(getDate(lead));

export const setDate = (date: number, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    date,
  });

export const isValidDate = (date: number) =>
  Number.isFinite(date) &&
  // Date should be after initial date (time rounded to nearest 30 mins in future)
  !isBefore(date, getInitialDate());

export const getInterval = (lead?: Lead) => (lead || defaults).interval;

export const setInterval = (interval: number, lead?: Lead): Lead => ({
  ...defaults,
  ...lead,
  interval: clamp(INTERVAL_MIN, INTERVAL_MAX, interval || INTERVAL_MIN),
});

export const isValidInterval = allPass([
  lte(INTERVAL_MIN), // min should be less than or equal to interval
  gte(INTERVAL_MAX), // max should be greater than or equal to interval
]);

export const isEnabled = (lead?: Lead) => (lead || defaults).enabled;

export const setEnabled = (enabled: boolean, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    enabled,
  });

export const getFrequency = (lead?: Lead) => (lead || defaults).frequency;

export const setFrequency = (frequency: number, lead?: Lead): Lead => ({
  ...defaults,
  ...lead,
  frequency: clamp(INTERVAL_MIN, INTERVAL_MAX, frequency || INTERVAL_MIN),
});

export const isValidFrequency = allPass([
  lte(INTERVAL_MIN), // min should be less than or equal to interval
  gte(INTERVAL_MAX), // max should be greater than or equal to interval
]);

export const getStartDate = (lead?: Lead) => (lead || defaults).startDate;

export const getFormattedStartDate = (lead?: Lead) =>
  formatDate(getStartDate(lead));

export const setStartDate = (startDate: number, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    startDate: Math.min(startDate, getEndDate(lead)!),
  });

export const getEndDate = (lead?: Lead) => (lead || defaults).endDate;

export const getFormattedEndDate = (lead?: Lead) =>
  formatDate(getEndDate(lead));

export const setEndDate = (endDate: number, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    endDate,
  });

export const isValidPeriod = (startDate: number, endDate: number) => {
  const initialStartDate = getInitialStartDate();

  return (
    Number.isFinite(startDate) &&
    Number.isFinite(endDate) &&
    // Start date must be on or after initial start date (next day)
    !isBefore(startDate, Math.min(initialStartDate, startDate)) &&
    // End date must be after initial start date (next day)
    isAfter(endDate, initialStartDate) &&
    // End date must be after start date
    isAfter(endDate, startDate)
  );
};

export const isReadonly = (lead?: Lead) => {
  switch (getType(lead)) {
    case LeadTypes.SCHEDULED:
      // 'scheduled' is never read only (unless user updates/deletes it)
      return false;
    case LeadTypes.INSTANT:
      // 'instant' is read only once 'date' has passed
      return isBefore(getDate(lead), new Date());
    case LeadTypes.RECURRING:
      // 'recurring' is read only once 'startDate' has passed
      return false;
    default:
      return false;
  }
};

// Email Customization
export const getTemplate = (lead?: Lead) => (lead || defaults).template;

export const setTemplate = (template: LeadTemplates, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    template,
  });

export const isValidTemplate = (template = LeadTemplates.BASIC) =>
  Object.values(LeadTemplates).includes(template);

export const getSender = (lead?: Lead) => (lead || defaults).sender;

export const setSender = (sender: string, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    sender,
  });

export const getSubject = (lead?: Lead) => (lead || defaults).subject;

export const setSubject = (subject: string, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    subject,
  });

export const hasCoverImage = (lead?: Lead) => (lead || defaults).hasCoverImage;

export const toggleCoverImage = (lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    hasCoverImage: !hasCoverImage(lead),
  });

export const getHeader = (lead?: Lead) => (lead || defaults).header;

export const setHeader = (header: string, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    header,
  });

export const getContent = (lead?: Lead) => (lead || defaults).content;

export const setContent = (content: string, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    content,
  });

export const getRedirectButtonText = (lead?: Lead) =>
  (lead || defaults).redirectButtonText;

export const setRedirectButtonText = (
  redirectButtonText: string,
  lead?: Lead,
): Lead =>
  mergeRight(lead || defaults, {
    redirectButtonText,
  });

export const getRedirectButtonUrl = (lead?: Lead) =>
  (lead || defaults).redirectButtonUrl;

export const setRedirectButtonUrl = (
  redirectButtonUrl: string,
  lead?: Lead,
): Lead =>
  mergeRight(lead || defaults, {
    redirectButtonUrl,
  });

export const getFormattedRedirectButtonUrl = (lead?: Lead) => {
  const url = getRedirectButtonUrl(lead);

  return !isNilOrWhitespace(url) ? formatUrl(url) : url;
};

export const hasFlyer = (lead?: Lead) => (lead || defaults).hasFlyer;

export const toggleFlyer = (lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    hasFlyer: !hasFlyer(lead),
  });

export const hasPromo = (lead?: Lead) => (lead || defaults).hasPromo;

export const togglePromo = (lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    hasPromo: !hasPromo(lead),
  });

export const getSignature = (lead?: Lead) => (lead || defaults).signature;

export const setSignature = (signature: string, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    signature,
  });

// Recipient Filters
export const hasFilters = (lead?: Lead) => (lead || defaults).hasFilters;

export const setFilters = (hasFilters: boolean, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    hasFilters,
  });

export const getAgeRange = (lead?: Lead) => (lead || defaults).ageRange;

export const setAgeRange = (ageRange: Range<number>, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    ageRange,
  });

export const setAgeRangeFrom = (from: number, lead?: Lead): Lead => {
  const { to } = getAgeRange(lead || defaults)!;

  return setAgeRange(
    {
      from: clamp(AGE_MIN, to, from), // MIN <= from <= to
      to,
    },
    lead,
  );
};

export const setAgeRangeTo = (to: number, lead?: Lead): Lead => {
  const { from } = getAgeRange(lead || defaults)!;

  return setAgeRange(
    {
      from,
      to: clamp(from, AGE_MAX, to), // from <= to <= MAX
    },
    lead,
  );
};

export const isValidAgeRange = (ageRange: Range<number>) => {
  const { from, to } = ageRange;

  return (
    [from, to].every(
      (x) => Number.isInteger(x) && x >= AGE_MIN && x <= AGE_MAX,
    ) && from <= to
  );
};

export const getGenders = (lead?: Lead) => (lead || defaults).genders;

export const setGenders = (genders: GenderFilter, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    genders,
  });

export const toggleOtherGenderFilter = (lead?: Lead): Lead => {
  const filter = getGenders(lead || defaults)!;

  return setGenders(
    {
      ...filter,
      other: !filter.other,
    },
    lead,
  );
};

export const toggleMaleGenderFilter = (lead?: Lead): Lead => {
  const filter = getGenders(lead || defaults)!;

  return setGenders(
    {
      ...filter,
      male: !filter.male,
    },
    lead,
  );
};

export const toggleFemaleGenderFilter = (lead?: Lead): Lead => {
  const filter = getGenders(lead || defaults)!;

  return setGenders(
    {
      ...filter,
      female: !filter.female,
    },
    lead,
  );
};

export const isValidGenderFilter = (genders: GenderFilter) => {
  if (!genders || !is(Object, genders)) {
    return false;
  }

  const { male, female, other } = genders;

  return [male, female, other].every(is(Boolean));
};

export const getDateRange = (lead?: Lead) => (lead || defaults).dateRange;

export const setDateRange = (dateRange: Range<number>, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    dateRange,
  });

export const setDateRangeFrom = (from: number, lead?: Lead): Lead => {
  const { to } = getDateRange(lead || defaults)!;

  return setDateRange(
    {
      from,
      to,
    },
    lead,
  );
};

export const setDateRangeTo = (to: number, lead?: Lead): Lead => {
  const { from } = getDateRange(lead || defaults)!;

  return setDateRange(
    {
      from,
      to: Math.min(endOfYesterday().getTime(), to), // to <= end of yesterday
    },
    lead,
  );
};

export const isValidDateRange = (dateRange: Range<number> | undefined) => {
  if (!dateRange) {
    return false;
  }

  const { from, to } = dateRange;

  if (typeof from === 'number' && typeof to === 'number') {
    return from <= to;
  }

  return false;
};

const toggleLowMetricFilter = (filter: MetricFilter): MetricFilter => ({
  ...filter,
  low: !filter.low,
});

const toggleMediumMetricFilter = (filter: MetricFilter): MetricFilter => ({
  ...filter,
  medium: !filter.medium,
});

const toggleHighMetricFilter = (filter: MetricFilter): MetricFilter => ({
  ...filter,
  high: !filter.high,
});

const isValidMetricFilter = (filter: MetricFilter) => {
  const { low, medium, high } = filter;

  return [low, medium, high].every(is(Boolean));
};

export const getCadence = (lead?: Lead) => (lead || defaults).cadence;

export const setCadence = (cadence: MetricFilter, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    cadence,
  });

export const toggleCadenceLow = (lead?: Lead): Lead =>
  setCadence(toggleLowMetricFilter(getCadence(lead || defaults)!), lead);

export const toggleCadenceMedium = (lead?: Lead): Lead =>
  setCadence(toggleMediumMetricFilter(getCadence(lead || defaults)!), lead);

export const toggleCadenceHigh = (lead?: Lead): Lead =>
  setCadence(toggleHighMetricFilter(getCadence(lead || defaults)!), lead);

export const getFootstrikeY = (lead?: Lead) => (lead || defaults).footstrikeY;

export const setFootstrikeY = (footstrikeY: MetricFilter, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    footstrikeY,
  });

export const toggleFootstrikeYLow = (lead?: Lead): Lead =>
  setFootstrikeY(
    toggleLowMetricFilter(getFootstrikeY(lead || defaults)!),
    lead,
  );

export const toggleFootstrikeYMedium = (lead?: Lead): Lead =>
  setFootstrikeY(
    toggleMediumMetricFilter(getFootstrikeY(lead || defaults)!),
    lead,
  );

export const toggleFootstrikeYHigh = (lead?: Lead): Lead =>
  setFootstrikeY(
    toggleHighMetricFilter(getFootstrikeY(lead || defaults)!),
    lead,
  );

export const getStabilityX = (lead?: Lead) => (lead || defaults).stabilityX;

export const setStabilityX = (stabilityX: MetricFilter, lead?: Lead): Lead =>
  mergeRight(lead || defaults, {
    stabilityX,
  });

export const toggleStabilityXLow = (lead?: Lead): Lead =>
  setStabilityX(toggleLowMetricFilter(getStabilityX(lead || defaults)!), lead);

export const toggleStabilityXMedium = (lead?: Lead): Lead =>
  setStabilityX(
    toggleMediumMetricFilter(getStabilityX(lead || defaults)!),
    lead,
  );

export const toggleStabilityXHigh = (lead?: Lead): Lead =>
  setStabilityX(toggleHighMetricFilter(getStabilityX(lead || defaults)!), lead);

const parseDateRange = (dateRange?: Range<number>): Range<Date> | undefined => {
  const dateFrom = toDate(dateRange?.from);
  const dateTo = toDate(dateRange?.to);

  if (!dateFrom || !dateTo) return undefined;

  return {
    from: dateFrom,
    to: dateTo,
  };
};

export const areValidFilters = (lead?: Lead) =>
  isValidAgeRange(getAgeRange(lead)!) &&
  isValidGenderFilter(getGenders(lead)!) &&
  isValidDateRange(getDateRange(lead)) &&
  isValidMetricFilter(getCadence(lead)!) &&
  isValidMetricFilter(getFootstrikeY(lead)!) &&
  isValidMetricFilter(getStabilityX(lead)!);

// Utils
export const toFirestore = (data: Lead): FirestoreLead => {
  const type = getType(data);
  const withFilters = hasFilters(data);

  const lead: FirestoreLead = {
    // General Info
    name: getName(data),
    createdAt: toDate(getCreatedAt(data)) || new Date(),
    updatedAt: toDate(getUpdatedAt(data)) || new Date(),
    type,
    // Scheduling
    date: toDate(getDate(data)) || new Date(),
    // Email Customization
    template: getTemplate(data),
    sender: getSender(data),
    subject: getSubject(data),
    hasCoverImage: hasCoverImage(data),
    header: getHeader(data),
    content: getContent(data),
    redirectButtonText: getRedirectButtonText(data),
    redirectButtonUrl: getRedirectButtonUrl(data),
    hasFlyer: hasFlyer(data),
    hasPromo: hasPromo(data),
    signature: getSignature(data),
    // Recipient Filters
    hasFilters: withFilters,
  };

  // Only set fields relevant for type
  switch (type) {
    case LeadTypes.SCHEDULED:
      return {
        ...lead,
        // Scheduling
        enabled: isEnabled(data),
        interval: getInterval(data),
      };
    case LeadTypes.INSTANT:
      const instant = {
        ...lead,
        // Scheduling
        endDate: toDate(getDate(data)),
      };

      if (withFilters) {
        // Recipient Filters
        instant.ageRange = getAgeRange(data);
        instant.genders = getGenders(data);
        instant.dateRange = parseDateRange(getDateRange(data));
        instant.cadence = getCadence(data);
        instant.footstrikeY = getFootstrikeY(data);
        instant.stabilityX = getStabilityX(data);
      }

      return instant;
    case LeadTypes.RECURRING:
      const recurring = {
        ...lead,
        // Scheduling
        enabled: isEnabled(data),
        frequency: getFrequency(data),
        startDate: toDate(getStartDate(data)),
        endDate: toDate(getEndDate(data)),
      };

      if (withFilters) {
        // Recipient Filters
        recurring.ageRange = getAgeRange(data);
        recurring.genders = getGenders(data);
        recurring.dateRange = parseDateRange(getDateRange(data));
        recurring.cadence = getCadence(data);
        recurring.footstrikeY = getFootstrikeY(data);
        recurring.stabilityX = getStabilityX(data);
      }

      return recurring;
    default:
      return lead;
  }
};

export const serialize = (data?: Lead): Lead => {
  const lead = mergeRight(defaults, data || {});

  lead.createdAt = toMillis(getCreatedAt(lead));
  lead.updatedAt = toMillis(getUpdatedAt(lead));

  if (lead.date) {
    lead.date = toMillis(getDate(lead));
  }

  if (lead.startDate) {
    lead.startDate = toMillis(getStartDate(lead));
  }

  if (lead.endDate) {
    lead.endDate = toMillis(getEndDate(lead));
  }

  if (lead.dateRange) {
    lead.dateRange = {
      from: toMillis(lead.dateRange.from),
      to: toMillis(lead.dateRange.to),
    };
  }

  return lead;
};

export const copy = (original: Lead): Lead => {
  const lead = setCreatedAt(Date.now(), {
    ...original,
    id: '',
    name: `${getName(original)} (copy)`,
  });

  if (lead.date) {
    lead.date = getInitialDate();
  }

  if (lead.startDate) {
    lead.startDate = getInitialStartDate();
  }

  if (lead.endDate) {
    lead.endDate = getInitialEndDate();
  }

  if (lead.dateRange) {
    lead.dateRange = {
      from: toMillis(lead.dateRange.from),
      to: toMillis(lead.dateRange.to),
    };
  }

  return lead;
};

export const validate = (lead?: Lead) => {
  if (!lead) {
    return false;
  }

  const type = getType(lead);

  const validInfo =
    !isNilOrWhitespace(getName(lead)) &&
    isValidType(type) &&
    isValidDate(getDate(lead)) &&
    isValidTemplate(getTemplate(lead)) &&
    !isNilOrWhitespace(getSender(lead)) &&
    !isNilOrWhitespace(getSubject(lead)) &&
    !isNilOrWhitespace(getHeader(lead)) &&
    !isNilOrWhitespace(getContent(lead)) &&
    !isNilOrWhitespace(getRedirectButtonText(lead)) &&
    !isNilOrWhitespace(getRedirectButtonUrl(lead)) &&
    !isNilOrWhitespace(getSignature(lead));

  // Only validate fields relevant for type
  switch (type) {
    case LeadTypes.SCHEDULED:
      return validInfo && isValidInterval(getInterval(lead)!);
    case LeadTypes.INSTANT:
      const validInstant = isValidPeriod(
        getStartDate(lead)!,
        getEndDate(lead)!,
      );

      return hasFilters(lead)
        ? validInfo && validInstant && areValidFilters(lead)
        : validInfo && validInstant;
    case LeadTypes.RECURRING:
      const validRecurring =
        isValidDate(getStartDate(lead)!) &&
        isValidFrequency(getFrequency(lead)!) &&
        isValidPeriod(getStartDate(lead)!, getEndDate(lead)!);

      return hasFilters(lead)
        ? validInfo && validRecurring && areValidFilters(lead)
        : validInfo && validRecurring;
    default:
      return false;
  }
};
