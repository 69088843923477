import { ColorsType } from './types';

// Available in all apps
const lightColors: ColorsType = {
  primary: '#FF6C00',
  secondary: '#1C2228',
  text: '#11171d',
  textTwo: '#919297', // === gray
  background: '#EBEBEB',
  surfaceOne: '#F4F4F4',
  surfaceTwo: '#FFFFFF',
  dark: '#0c1115', // === backgroundDark
  success: '#1DAD77',
  danger: '#F23C3C',
  white: '#FFFFFF',
  light: '#FAFAFA', // === backgroundLight
};

// Available in all apps
const darkColors: ColorsType = {
  primary: '#FF6C00',
  secondary: '#000000',
  text: '#FFFFFF',
  textTwo: '#919297', // === gray
  background: '#0C1115',
  surfaceOne: '#11171D',
  surfaceTwo: '#1C2228',
  dark: '#0c1115', // === backgroundDark
  success: '#1DAD77',
  danger: '#F23C3C',
  white: '#FFFFFF',
  light: '#FAFAFA', // === backgroundLight
};

// Added as helpers to the Admin Dashboard specifically
const colorsExtendedLight = {
  shadow: '#00000029',
  grayLight: '#E6E6E7',
  grayOpaque: '#75767B4C',
  highlight: '#E6E6E7',
};

// Added as helpers to the Admin Dashboard specifically
const colorsExtendedDark = {
  shadow: '#00000029',
  grayOpaque: '#75767B4C',
  highlight: '#75767B',
};

// Combination of the default properties + the app specific properties
export const dark = {
  ...darkColors,
  ...colorsExtendedDark,
};

export const light = {
  ...lightColors,
  ...colorsExtendedLight,
};

export default light;
