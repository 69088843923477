import {
  FC,
  FocusEventHandler,
  InputHTMLAttributes,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import clear from '../assets/icons/close-circle.svg';

type Margin = 'normal' | 'dense';

interface WrapperProps {
  $error?: boolean;
  $focused?: boolean;
  $margin?: Margin;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ theme }) => theme.colors.surfaceTwo};
  border: 1px solid
    ${({ $focused, theme }) =>
      $focused ? theme.colors.textTwo : theme.colors.grayOpaque};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 400px;
  padding: 14px 12px;
`;

const StyledInput = styled.input`
  ${({ theme }) => theme.typography.body2};
  caret-color: ${({ theme }) => theme.colors.textTwo};
  background-color: transparent;
  border: none;
  display: flex;
  flex: 1;

  &:focus {
    outline: none;
  }

  // Customize search cancel button
  &::-webkit-search-cancel-button {
    // Hide default
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    // Use custom icon svg
    background: url(${clear});
    background-size: contain;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    width: 20px;
    opacity: 0;
    pointer-events: none;
  }

  &:focus::-webkit-search-cancel-button {
    opacity: 1;
    pointer-events: all;
  }
`;

const SearchIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTwo};
  margin-right: 6px;
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {}

const SearchInput: FC<Props> = ({ onBlur, onFocus, ...rest }) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);

  const handleClick = () => inputEl.current && inputEl.current.focus();

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setFocused(false);

    onBlur && onBlur(e);
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    setFocused((prev) => !prev);

    onFocus && onFocus(e);
  };

  return (
    <Wrapper $focused={focused} onClick={handleClick}>
      <SearchIcon name="search" />
      <StyledInput
        {...rest}
        ref={inputEl}
        placeholder="Search by any keyword..."
        type="search"
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </Wrapper>
  );
};

export default SearchInput;
