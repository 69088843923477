import { formatUrl, isNilOrWhitespace } from '@atogear/arion-utils';
import { mergeRight } from 'ramda';

export interface PromotionItem {
  imageUrl: string;
  imagePath: string;
  description: string;
  priceText: string;
  redirectUrl: string;
}

export const fields = {
  IMAGE_URL: 'imageUrl',
  IMAGE_PATH: 'imagePath',
  DESCRIPTION: 'description',
  PRICE_TEXT: 'priceText',
  REDIRECT_URL: 'redirectUrl',
};

export const defaults: PromotionItem = {
  imageUrl: '',
  imagePath: '',
  description: '',
  priceText: '',
  redirectUrl: '',
};

export const getImageUrl = (promotionItem: PromotionItem) =>
  (promotionItem || defaults).imageUrl;

export const setImageUrl = (imageUrl: string, promotionItem: PromotionItem) =>
  mergeRight(promotionItem, {
    imageUrl,
  });

export const getImagePath = (promotionItem: PromotionItem) =>
  (promotionItem || defaults).imageUrl;

export const setImagePath = (imagePath: string, promotionItem: PromotionItem) =>
  mergeRight(promotionItem, {
    imagePath,
  });

export const setMedia = (
  imageUrl: string,
  imagePath: string,
  promotionItem: PromotionItem,
) =>
  mergeRight(promotionItem, {
    imageUrl,
    imagePath,
  });

export const getDescription = (promotionItem: PromotionItem) =>
  (promotionItem || defaults).description;

export const setDescription = (
  description: string,
  promotionItem: PromotionItem,
) =>
  mergeRight(promotionItem, {
    description,
  });

export const getPriceText = (promotionItem: PromotionItem) =>
  (promotionItem || defaults).priceText;

export const setPriceText = (priceText: string, promotionItem: PromotionItem) =>
  mergeRight(promotionItem, {
    priceText,
  });

export const getRedirectUrl = (promotionItem: PromotionItem) =>
  (promotionItem || defaults).redirectUrl;

export const setRedirectUrl = (
  redirectUrl: string,
  promotionItem: PromotionItem,
) =>
  mergeRight(promotionItem, {
    redirectUrl,
  });

export const getFormattedRedirectUrl = (promotionItem: PromotionItem) => {
  const url = getRedirectUrl(promotionItem);

  return !isNilOrWhitespace(url) ? formatUrl(url) : url;
};

// Utils
export const create = () => {
  return defaults;
};
