import { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

type Color = 'primary' | 'secondary' | string;
type Variant = 'contained' | 'outlined';
type Size = 'medium' | 'small';

interface WrapperProps {
  $color: Color;
  $variant: Variant;
  $size: Size;
}

const Wrapper = styled.button<WrapperProps>`
  ${({ theme }) => theme.typography.h3};
  ${({ $color, theme }) => {
    const { buttons } = theme.components;

    if ($color === 'primary' || $color === 'secondary') {
      return buttons[$color];
    }

    return buttons.primary;
  }};
  background-color: ${({ $color, $variant, theme }) => {
    if ($variant === 'outlined') {
      return 'transparent';
    }

    if ($color === 'primary' || $color === 'secondary') {
      return theme.colors[$color];
    }

    return $color;
  }};
  border: ${({ $variant, theme }) =>
    $variant === 'outlined' ? `1px solid ${theme.colors.grayOpaque}` : 'none'};
  color: ${({ $variant, theme }) => {
    if ($variant === 'outlined') {
      return theme.colors.text;
    }
  }};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${({ $size }) => ($size === 'small' ? '34px' : '48px')};
  padding: ${({ $size }) => ($size === 'small' ? '0 12px' : '0 20px')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  white-space: nowrap;
  transition: all ease 0.2s;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
`;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: Color;
  variant?: Variant;
  size?: Size;
}

const Button: FC<ButtonProps> = ({
  children,
  color,
  variant,
  size,
  ...rest
}) => (
  <Wrapper
    $color={color || 'primary'}
    $variant={variant || 'contained'}
    $size={size || 'medium'}
    {...rest}
  >
    {children}
  </Wrapper>
);

export default Button;
