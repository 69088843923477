import { FC, MouseEventHandler, WheelEventHandler } from 'react';
import {
  apply,
  isNilOrEmpty,
  storeModel,
  User,
  userModel,
} from '@atogear/arion-utils';
import styled from 'styled-components';

import { Invite } from '../../../../models/inviteModel';

import InviteTile from './InviteTile';
import MemberTile from './MemberTile';
import PendingMemberTile from './PendingMemberTile';
import { useSelector } from '../../../../store';
import {
  StoreAccountSelectors,
  StoreSelectors,
} from '../../../../store/selectors';
import { InviteMemberType } from '../Team';

const PLACEHOLDERS = Array.from({
  length: 3,
});

const Container = styled.div`
  display: flex;
  margin-top: 22px;
  overflow-x: auto;
  /* we add padding because otherwise the box-shadow of the tile is cut-off */
  padding-bottom: 4px;
  ::-webkit-scrollbar-thumb {
    /* Handle */
    background: ${(props) => props.theme.colors.grayOpaque};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track-piece {
    /* not handle on */
    background: ${(props) => props.theme.colors.highlight};
  }
`;

interface Props {
  slots: number;
  onMemberClick?: (user: User) => void;
  onInvitedMemberClick?: (invite: Invite) => void;
  onInviteClick?: (type: InviteMemberType) => MouseEventHandler<HTMLDivElement>;
}

const Members: FC<Props> = ({
  slots,
  onMemberClick,
  onInvitedMemberClick,
  onInviteClick,
}) => {
  const store = useSelector(StoreSelectors.selectStore);

  const storeAccounts = useSelector(StoreAccountSelectors.selectStoreAccounts);
  const invites = useSelector(StoreAccountSelectors.selectInvites);

  const ownerId = apply(storeModel.getOwnerId, store);

  const hasOwner = !isNilOrEmpty(ownerId);

  const onWheel: WheelEventHandler<HTMLDivElement> = (e) => {
    if (e.deltaY === 0) {
      return;
    }

    e.currentTarget.scrollTo({
      left: e.currentTarget.scrollLeft + e.deltaY,
    });
  };

  const numberOfInviteSlots =
    storeAccounts && invites
      ? slots - storeAccounts.length - invites.length - (!hasOwner ? 1 : 0)
      : 0;

  return (
    <Container onWheel={onWheel}>
      {storeAccounts && invites && !hasOwner && onInviteClick ? (
        <InviteTile owner onClick={onInviteClick('owner')} />
      ) : null}
      {storeAccounts
        ? storeAccounts.map((account) => (
            <MemberTile
              key={userModel.getId(account)}
              account={account}
              onClick={onMemberClick}
            />
          ))
        : PLACEHOLDERS.map((_, index) => <MemberTile key={index} />)}
      {invites
        ? invites.map((invite) => (
            <PendingMemberTile
              key={invite.id}
              invite={invite}
              onClick={onInvitedMemberClick}
            />
          ))
        : PLACEHOLDERS.map((_, index) => <PendingMemberTile key={index} />)}
      {onInviteClick
        ? Array.from({
            length: numberOfInviteSlots,
          }).map((_, index) => (
            <InviteTile key={index} onClick={onInviteClick('member')} />
          ))
        : null}
    </Container>
  );
};

export default Members;
