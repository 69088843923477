import { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import 'react-datepicker/dist/react-datepicker.css';

const Wrapper = styled.div`
  .react-datepicker {
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.grayOpaque};
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }

  .react-datepicker-popper[data-placement^='top'] {
    padding-bottom: 0;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 0;
  }

  .react-datepicker-time__header {
    color: ${({ theme }) =>
      tinycolor(theme.colors.secondary).getBrightness() < 200
        ? theme.colors.white
        : theme.colors.text};
  }

  .react-datepicker__navigation,
  .react-datepicker__current-month {
    ${({ theme }) => theme.typography.h4};
    color: ${({ theme }) =>
      tinycolor(theme.colors.secondary).getBrightness() < 200
        ? theme.colors.white
        : theme.colors.text};
  }

  .react-datepicker__navigation {
    margin: 10px;
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker__current-month {
    margin: 12px 0;
  }

  .react-datepicker__day-name {
    ${({ theme }) => theme.typography.body2};
    color: ${({ theme }) =>
      tinycolor(theme.colors.secondary).getBrightness() < 200
        ? theme.colors.white
        : theme.colors.text};
  }

  .react-datepicker__day {
    ${({ theme }) => theme.typography.h4};
  }

  .react-datepicker__day {
    border-radius: 50%;
  }

  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.textTwo};
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: ${({ theme }) => theme.colors.highlight};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--range-end {
    ${({ theme }) => theme.typography.h3};
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) =>
      tinycolor(theme.colors.primary).getBrightness() < 200
        ? theme.colors.white
        : theme.colors.text};
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    line-height: 1.7rem;
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker__day--disabled:hover {
    cursor: not-allowed;
  }

  .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
    background-color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    color: ${({ theme }) =>
      tinycolor(theme.colors.primary).getBrightness() < 200
        ? theme.colors.white
        : theme.colors.text};
  }
`;

const DatePicker: FC<ReactDatePickerProps> = (props) => (
  <Wrapper className={props.className}>
    <ReactDatePicker
      calendarStartDay={1}
      dateFormat="P"
      disabledKeyboardNavigation
      popperPlacement="bottom"
      showPopperArrow={false}
      {...props}
    />
  </Wrapper>
);

export default DatePicker;
