import { apply, storeModel, userModel } from '@atogear/arion-utils';
import { FC, useCallback, useEffect } from 'react';
import { toast, ToastItem } from 'react-toastify';
import { createSelector } from '@reduxjs/toolkit';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import { useDispatch, useSelector } from '../../store';
import { ReminderActions, SettingsActions } from '../../store/actions';
import {
  AuthSelectors,
  SettingsSelectors,
  StoreSelectors,
} from '../../store/selectors';

import Header from './Header/Header';
import Router from './AppRouter';
import Banner from './Banner';
import SplashScreen from './SplashScreen';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.surfaceOne};
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  // Shows vertical scrollbar only when needed
  overflow-y: auto;
  // Dont shift content to side when vertical scrollbar shows/hides
  margin-right: calc(-1 * (100vw - 100%)) !important;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 70px;
`;

const App: FC = () => {
  const dispatch = useDispatch();

  const store = useSelector(StoreSelectors.selectStore);
  const user = useSelector(AuthSelectors.selectUser);

  const fetching = useSelector(
    createSelector(
      [
        AuthSelectors.selectAuthUserFetching,
        AuthSelectors.selectUserFetching,
        SettingsSelectors.selectAppSettingsFetching,
      ],
      (authUserFetching, userFetching, appSettingsFetching) =>
        authUserFetching || userFetching || appSettingsFetching,
    ),
  );

  useEffect(() => {
    dispatch(SettingsActions.getAppSettings());
  }, [dispatch]);

  useEffect(() => {
    const userId = apply(userModel.getId, user, 'unknown');
    const userEmail = apply(userModel.getEmail, user, 'unknown');
    const userRole = apply(userModel.getRole, user);

    const isAdmin = apply(userModel.isAdmin, user, false);
    const isSales = apply(userModel.isSales, user, false);
    const isBackOffice = apply(userModel.isBackOffice, user, false);

    Sentry.setUser({
      id: userId,
      email: userEmail,
      role: userRole,
    });

    if (userId !== 'unknown' && (isAdmin || isSales || isBackOffice)) {
      dispatch(ReminderActions.getUserReminders({ userId }));
    }
  }, [user, dispatch]);

  const onToast = useCallback(
    (payload: ToastItem) => {
      if (payload.status === 'added' && payload.type === toast.TYPE.ERROR) {
        const { content } = payload;

        Sentry.withScope((scope) => {
          scope.setContext('store', {
            storeId: apply(storeModel.getId, store, 'unknown'),
            storeName: apply(storeModel.getName, store, 'unknown'),
          });

          scope.setTransactionName('Handled Error Toast');
          scope.setLevel('error');

          if (content && typeof content === 'string') {
            Sentry.captureMessage(content);
          } else {
            Sentry.captureMessage(
              'The toast did not have a content of type string!',
            );
          }
        });
      }
    },
    [store],
  );

  useEffect(() => {
    const unsubscribe = toast.onChange(onToast);

    return unsubscribe;
  }, [onToast]);

  return fetching ? (
    <SplashScreen />
  ) : (
    <Wrapper>
      <Header />
      <Content>
        <Banner />
        <Router />
      </Content>
    </Wrapper>
  );
};

export default App;
