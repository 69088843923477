import { AnchorHTMLAttributes, FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface WrapperProps {
  $caseSensitive: boolean;
}

const Wrapper = styled(Link)<WrapperProps>`
  ${(props) => props.theme.typography.a};
  text-transform: ${(props) =>
    props.$caseSensitive ? 'none' : props.theme.typography.a.textTransform};
`;

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  $caseSensitive?: boolean;
}

const NavLink: FC<Props> = ({ children, $caseSensitive = true, ...rest }) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) =>
    e.stopPropagation();

  return (
    <Wrapper onClick={handleClick} $caseSensitive={$caseSensitive} {...rest}>
      {children}
    </Wrapper>
  );
};

export default NavLink;
